import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del formato de fecha de moment.
 */
const InformacionMomentFechaFormato = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Formato con el que se mostrarán las fechas en la aplicación.')}</p>
			<p>
				<b>{texto('Ejemplo:')}</b> &quot;DD/MM/YYYY&quot;
			</p>
			<p>
				<b>{texto('Resultado:')}</b> &quot;27/07/1985&quot;
			</p>
			<ul>
				<li>
					<span className="badge bg-info text-wrap">D</span> {texto('Día del mes')} <span className="fst-italic">{texto('1-31')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">Do</span> {texto('Día del mes con sufijo ordinal')} <span className="fst-italic">{texto('1º-31º')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">DD</span> {texto('Día del mes a 2 dígitos')} <span className="fst-italic">{texto('01-31')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">DDD</span> {texto('Día del año')} <span className="fst-italic">{texto('1-365')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">DDDo</span> {texto('Día del año con sufijo ordinal')} <span className="fst-italic">{texto('1º-365º')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">DDDD</span> {texto('Día del año a 3 dígitos')} <span className="fst-italic">{texto('001-365')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">M</span> {texto('Mes')} <span className="fst-italic">{texto('1-12')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">Mo</span> {texto('Mes con sufijo ordinal')} <span className="fst-italic">{texto('1st, 2nd... 12th')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">MM</span> {texto('Mes a 2 dígitos')} <span className="fst-italic">{texto('01-12')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">MMM</span> {texto('Nombre del mes abreviado')} <span className="fst-italic">{texto('Ene-Dic')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">MMMM</span> {texto('Nombre del mes')} <span className="fst-italic">{texto('Enero-Diciembre')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">YY</span> {texto('Año a 2 dígitos')} <span className="fst-italic">{texto('99 o 03')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">YYYY</span> {texto('Año a 4 dígitos')} <span className="fst-italic">{texto('1999 o 2003')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">d</span> {texto('Día de la semana')} <span className="fst-italic">{texto('0 (Domingo) - 6 (Sábado)')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">do</span> {texto('Día de la semana con sufijo ordinal')} <span className="fst-italic">{texto('0º - 6º')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">dd</span> {texto('Nombre del día de la semana abreviado a dos letras')} <span className="fst-italic">{texto('Lu-Do')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">ddd</span> {texto('Nombre del día de la semana abreviado')} <span className="fst-italic">{texto('Lun-Dom')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">dddd</span> {texto('Nombre del día de la semana')} <span className="fst-italic">{texto('Lunes-Domingo')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">E</span> {texto('Día de la semana ISO')} <span className="fst-italic">{texto('1 (Domingo) - 7 (Sábado)')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">w</span> {texto('Semana del año')} <span className="fst-italic">{texto('1-52')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">wo</span> {texto('Semana del año con sufijo ordinal')} <span className="fst-italic">{texto('1º-52º')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">ww</span> {texto('Semana del año a 2 dígitos')} <span className="fst-italic">{texto('1-52')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">Q</span> {texto('Número del cuarto del año')} <span className="fst-italic">{texto('1, 2, 3 ó 4')}</span>
				</li>
				<li>
					<span className="badge bg-info text-wrap">Qo</span> {texto('Número del cuarto del año y con sufijo ordinal')}{' '}
					<span className="fst-italic">{texto('1st 2nd 3rd ó 4th')}</span>
				</li>
			</ul>
		</div>
	</Fragment>
);

export default InformacionMomentFechaFormato;
