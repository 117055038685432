import * as Sentry from '@sentry/browser';

let instancia: McLoggerSentry;

/**
 * Clase para generar los registros de log del servicio Sentry.
 */
class McLoggerSentry {
	loggerSentry: any;

	constructor(dns: string, paquete: string, version: string, entorno: string, usuarioId: string, usuarioNombre: string, usuarioCorreoElectronico: string) {
		if (!instancia) {
			Sentry.init({
				dsn: dns,
				environment: entorno,
				release: `${paquete}@${version}`,
				tracesSampleRate: 1.0
			});
			Sentry.setUser({
				email: usuarioCorreoElectronico,
				id: usuarioId,
				username: usuarioNombre
			});
			instancia = this;
		}
	}

	/**
	 * Crea un registro en la consola en la consola de nivel API. Utilizado para logs de llamadas a API's.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	api({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		Sentry.withScope((scope: any) => {
			scope.setLevel('info');
			Sentry.configureScope((scope: any) => scope.setTransactionName(`[${nombreArchivo}] ${nombreMetodo}`));
			Sentry.captureMessage(mensaje);
			Sentry.captureException(objetoExtra);
		});
	}

	/**
	 * Crea un registro en la consola de nivel DEV. Utilizado para logs de desarrollo.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	dev({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		Sentry.withScope((scope: any) => {
			scope.setLevel('debug');
			Sentry.configureScope((scope: any) => scope.setTransactionName(`[${nombreArchivo}] ${nombreMetodo}`));
			Sentry.captureMessage(mensaje);
			Sentry.captureException(objetoExtra);
		});
	}

	/**
	 * Crea un registro en la consola de nivel ERROR. Utilizado para logs de error.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	error({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		Sentry.withScope((scope: any) => {
			scope.setLevel('error');
			Sentry.configureScope((scope: any) => scope.setTransactionName(`[${nombreArchivo}] ${nombreMetodo}`));
			Sentry.captureMessage(mensaje);
			Sentry.captureException(objetoExtra);
		});
	}

	/**
	 * Crea un registro en la consola de nivel LOCAL STORAGE. Utilizado para logs de cambios en el localStorage.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	localStorage(nombreArchivo: string, nombreMetodo: string, mensaje: string, objetoExtra?: any): void {
		Sentry.withScope((scope: any) => {
			scope.setLevel('debug');
			Sentry.configureScope((scope: any) => scope.setTransactionName(`[${nombreArchivo}] ${nombreMetodo}`));
			Sentry.captureMessage(mensaje);
			Sentry.captureException(objetoExtra);
		});
	}

	/**
	 * Crea un registro en la consola de nivel LOG. Utilizado para logs normales.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	log({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		Sentry.withScope((scope: any) => {
			scope.setLevel('log');
			Sentry.configureScope((scope: any) => scope.setTransactionName(`[${nombreArchivo}] ${nombreMetodo}`));
			Sentry.captureMessage(mensaje);
			Sentry.captureException(objetoExtra);
		});
	}

	/**
	 * Crea un registro en la consola de nivel NODE MODULE. Utilizado para logs dentro de librerias externas.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	nodeModule(nombreArchivo: string, nombreMetodo: string, mensaje: string, objetoExtra?: any): void {
		Sentry.withScope((scope: any) => {
			scope.setLevel('debug');
			Sentry.configureScope((scope: any) => scope.setTransactionName(`[${nombreArchivo}] ${nombreMetodo}`));
			Sentry.captureMessage(mensaje);
			Sentry.captureException(objetoExtra);
		});
	}

	/**
	 * Crea un registro en la consola de nivel REACT. Utilizado para logs de los ciclos de vida de React.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	react({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		Sentry.withScope((scope: any) => {
			scope.setLevel('debug');
			Sentry.configureScope((scope: any) => scope.setTransactionName(`[${nombreArchivo}] ${nombreMetodo}`));
			Sentry.captureMessage(mensaje);
			Sentry.captureException(objetoExtra);
		});
	}

	/**
	 * Crea un registro en la consola de nivel REDUX. Utilizado para logs de cambios en el state de Redux.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	redux({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		Sentry.withScope((scope: any) => {
			scope.setLevel('debug');
			Sentry.configureScope((scope: any) => scope.setTransactionName(`[${nombreArchivo}] ${nombreMetodo}`));
			Sentry.captureMessage(mensaje);
			Sentry.captureException(objetoExtra);
		});
	}

	/**
	 * Crea un registro en la consola de nivel SESSION STORAGE. Utilizado para logs de cambios en el sessionStorage.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	sessionStorage(nombreArchivo: string, nombreMetodo: string, mensaje: string, objetoExtra?: any): void {
		Sentry.withScope((scope: any) => {
			scope.setLevel('debug');
			Sentry.configureScope((scope: any) => scope.setTransactionName(`[${nombreArchivo}] ${nombreMetodo}`));
			Sentry.captureMessage(mensaje);
			Sentry.captureException(objetoExtra);
		});
	}
}

export default McLoggerSentry;
