import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { formatearListaOpcionesMcCampoSelector, obtenerOpcionDeLaLista } from 'util/modelo/listasOpciones';
import { McCampoFecha, McCampoSelector, McCampoSelectorMultiple, McCampoTexto, obtenerValorMcCampoSelector, obtenerValorMcCampoTexto } from '@mcsoft/formulario';
import { useForm, useFormState } from 'react-final-form';
import Avaluo from 'modelo/Avaluo';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import Estado from 'modelo/Estado';
import { formatearListaEstadosMcCampoSelectorMultiple } from 'util/modelo/estado';
import { formatearListaMunicipiosMcCampoSelectorMultiple } from 'util/modelo/municipio';
import { formatearListaPaisesMcCampoSelectorMultiple } from 'util/modelo/pais';
import { formatearListaPeritosMcCampoSelectorMultiple } from 'util/modelo/peritos';
import ListaOpcion from 'modelo/ListaOpcion';
import Municipio from 'modelo/Municipio';
import { obtenerEstadosTodosPorPais } from 'servicios/api/estados';
import { obtenerMunicipiosTodosPorEstado } from 'servicios/api/municipios';
import { obtenerPaisesTodos } from 'servicios/api/paises';
import Pais from 'modelo/Pais';
import Perito from 'modelo/Perito';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';

interface AvaluoFormularioAntecedentesProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
	listaPeritos: Array<Perito>;
	listaRegimenPropiedad: Array<ListaOpcion> | undefined;
	listaTipoCalle: Array<ListaOpcion> | undefined;
	listaTipoInmueble: Array<ListaOpcion> | undefined;
}

/**
 * Formulario para la sección antecedentes del avalúo.
 */
const AvaluoFormularioAntecedentes = (props: AvaluoFormularioAntecedentesProps) => {
	const formulario = useForm();
	const formularioValores = useFormState();
	const { antecedentes, domicilio } = formularioValores.values;
	const { fechaAvaluo, tipoInmuebleId } = antecedentes;
	const { calle, calleTipoId, colonia, condominio, estadoId, fraccionamiento, lote, manzana, municipioId, numeroExterior, numeroInterior, paisId, unidadPrivativa } = domicilio;
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { selectorFechaFormato } = useSelector((state: StateType) => state.configuracionAplicacion);
	const { auth0AccessToken } = auth0;
	const { eventoDeshacer, eventoGuardar, eventoSalir, hayCambiosSinGuardar, listaPeritos, listaRegimenPropiedad, listaTipoCalle, listaTipoInmueble } = props;
	const [ubicacionPredioEditable, setUbicacionPredioEditable] = useState<boolean>(false);
	const [listaEstados, setListaEstados] = useState<Array<Estado>>([]);
	const [listaMunicipios, setListaMunicipios] = useState<Array<Municipio>>([]);
	const [listaPaises, setListaPaises] = useState<Array<Pais>>([]);

	useEffect(() => {
		obtenerListaPaises();
	}, []);

	useEffect(() => {
		obtenerListaEstados(paisId);
		obtenerListaMunicipios(estadoId);
	}, [estadoId, paisId]);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonSalir" onClick={eventoSalir} type="button">
				<i className={constantes.icono.atras}></i> {texto('Salir')}
			</button>
			<button className="btn btn-warning" disabled={!hayCambiosSinGuardar} id="botonDeshacer" onClick={eventoDeshacer} type="button">
				<i className={constantes.icono.deshacer}></i> {texto('Deshacer')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardar} id="botonGuardar" onClick={eventoGuardarFormulario} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardar}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	/**
	 * Evento que se ejecuta cuando cambia algun campo del domicilio.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioDomicilio = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		let ubicacionPredio = '';
		if (campo === 'tipoInmuebleId' && valor) {
			ubicacionPredio += `${obtenerOpcionDeLaLista(listaTipoInmueble, valor)?.nombre}`;
		} else if (campo !== 'tipoInmuebleId' && tipoInmuebleId) {
			ubicacionPredio += `${obtenerOpcionDeLaLista(listaTipoInmueble, tipoInmuebleId)?.nombre}`;
		}
		if (campo === 'calleTipoId' && valor) {
			ubicacionPredio += ` ubicado en la ${obtenerOpcionDeLaLista(listaTipoCalle, valor)?.nombre}`;
		} else if (campo !== 'calleTipoId' && calleTipoId) {
			ubicacionPredio += ` ubicado en la ${obtenerOpcionDeLaLista(listaTipoCalle, calleTipoId)?.nombre}`;
		}
		if (campo === 'calle' && valor) {
			ubicacionPredio += ` ${valor}`;
		} else if (campo !== 'calle' && calle) {
			ubicacionPredio += ` ${calle}`;
		}
		if (campo === 'numeroExterior' && valor) {
			ubicacionPredio += ` ${valor}`;
		} else if (campo !== 'numeroExterior' && numeroExterior) {
			ubicacionPredio += ` ${numeroExterior}`;
		}
		if (campo === 'numeroInterior' && valor) {
			ubicacionPredio += ` interior ${valor}`;
		} else if (campo !== 'numeroInterior' && numeroInterior) {
			ubicacionPredio += ` interior ${numeroInterior}`;
		}
		if (campo === 'lote' && valor) {
			ubicacionPredio += ` lote ${valor}`;
		} else if (campo !== 'lote' && lote) {
			ubicacionPredio += ` lote ${lote}`;
		}
		if (campo === 'unidadPrivativa' && valor) {
			ubicacionPredio += ` unidad privativa ${valor}`;
		} else if (campo !== 'unidadPrivativa' && unidadPrivativa) {
			ubicacionPredio += ` unidad privativa ${unidadPrivativa}`;
		}
		if (campo === 'manzana' && valor) {
			ubicacionPredio += ` manzana ${valor}`;
		} else if (campo !== 'manzana' && manzana) {
			ubicacionPredio += ` manzana ${manzana}`;
		}
		if (campo === 'condominio' && valor) {
			ubicacionPredio += ` del condominio ${valor}`;
		} else if (campo !== 'condominio' && condominio) {
			ubicacionPredio += ` del condominio ${condominio}`;
		}
		if (campo === 'fraccionamiento' && valor) {
			ubicacionPredio += `, en el fraccionamiento ${valor}`;
		} else if (campo !== 'fraccionamiento' && fraccionamiento) {
			ubicacionPredio += `, en el fraccionamiento ${fraccionamiento}`;
		}
		if (campo === 'colonia' && valor) {
			ubicacionPredio += `, en la colonia ${valor}`;
		} else if (campo !== 'colonia' && colonia) {
			ubicacionPredio += `, en la colonia ${colonia}`;
		}
		if (campo === 'municipioSeleccion' && valor) {
			ubicacionPredio += `, en el municipio de ${obtenerOpcionDeLaLista(listaMunicipios, valor)?.nombre}`;
		} else if (campo !== 'municipioSeleccion' && municipioId) {
			if (campo === 'paisSeleccion' || campo === 'estadoSeleccion') {
				ubicacionPredio += ``;
			} else {
				ubicacionPredio += `, en el municipio de ${obtenerOpcionDeLaLista(listaMunicipios, municipioId)?.nombre}`;
			}
		}
		if (campo === 'estadoSeleccion' && valor) {
			ubicacionPredio += `, ${obtenerOpcionDeLaLista(listaEstados, valor)?.nombre}.`;
		} else if (campo !== 'estadoSeleccion' && estadoId) {
			if (campo === 'paisSeleccion') {
				ubicacionPredio += ``;
			} else {
				ubicacionPredio += `, ${obtenerOpcionDeLaLista(listaEstados, estadoId)?.nombre}.`;
			}
		}
		cambiarValorCampo('antecedentes.ubicacionPredio', ubicacionPredio);
	};

	/**
	 * Evento que se ejecuta cuando cambia el estado seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioEstado = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const { cambiarValorCampo, limpiarValorCampo } = formulario.mutators;
		const valor = obtenerValorMcCampoSelector(evento);
		obtenerListaMunicipios(valor);
		cambiarValorCampo(`domicilio.estadoId`, valor);
		limpiarValorCampo(`domicilio.municipioId`);
		limpiarValorCampo(`domicilio.municipioSeleccion`);
		eventoCambioDomicilio({ campo: 'estadoSeleccion', valor });
	};

	/**
	 * Evento que se ejecuta cuando cambia el municipio seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioMunicipio = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const { cambiarValorCampo } = formulario.mutators;
		const valor = obtenerValorMcCampoSelector(evento);
		cambiarValorCampo(`domicilio.municipioId`, valor);
		eventoCambioDomicilio({ campo: 'municipioSeleccion', valor });
	};

	/**
	 * Evento que se ejecuta cuando cambia el país seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioPais = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const { cambiarValorCampo, limpiarValorCampo } = formulario.mutators;
		const valor = obtenerValorMcCampoSelector(evento);
		obtenerListaEstados(valor);
		cambiarValorCampo(`domicilio.paisId`, valor);
		limpiarValorCampo(`domicilio.estadoId`);
		limpiarValorCampo(`domicilio.estadoSeleccion`);
		limpiarValorCampo(`domicilio.municipioId`);
		limpiarValorCampo(`domicilio.municipioSeleccion`);
		eventoCambioDomicilio({ campo: 'paisSeleccion', valor });
	};

	/**
	 * Evento que se ejecuta cuando cambia el perito seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioPerito = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const { cambiarValorCampo } = formulario.mutators;
		const valor = obtenerValorMcCampoSelector(evento);
		cambiarValorCampo('antecedentes.peritoId', valor);
	};

	/**
	 * Copia el valor del nombre del propietario en el campo nombre del solicitante.
	 */
	const eventoCopiarSolicitante = () => {
		const { copiarValorCampo } = formulario.mutators;
		copiarValorCampo('antecedentes.nombrePropietario', 'antecedentes.nombreSolicitante');
	};

	/**
	 * Habilita la edición manual del campo ubicación del predio.
	 */
	const eventoEditarUbicacionPredio = () => {
		setUbicacionPredioEditable(!ubicacionPredioEditable);
	};

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardarFormulario = () => {
		const avaluo = formularioValores.values as Avaluo;
		eventoGuardar(avaluo);
	};

	/**
	 * Obtiene la lista de estados relacionados al país seleccionado.
	 */
	const obtenerListaEstados = async (paisId: string) => {
		if (paisId) {
			const respuesta = await obtenerEstadosTodosPorPais({ auth0AccessToken, paisId });
			setListaEstados(respuesta.datos);
		} else {
			setListaEstados([]);
		}
	};

	/**
	 * Obtiene la lista de municipios relacionados al estado seleccionado.
	 */
	const obtenerListaMunicipios = async (estadoId: string) => {
		if (estadoId) {
			const respuesta = await obtenerMunicipiosTodosPorEstado({ auth0AccessToken, estadoId });
			setListaMunicipios(respuesta.datos);
		} else {
			setListaMunicipios([]);
		}
	};

	/**
	 * Obtiene la lista de paises.
	 */
	const obtenerListaPaises = async () => {
		const respuesta = await obtenerPaisesTodos(auth0AccessToken);
		setListaPaises(respuesta.datos);
	};

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.antecedentes}></i> {texto('Datos Antecedentes')}
					</h3>
					<p>{texto('En esta sección del formulario se captura la información de los datos antecedentes del avalúo.')}</p>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoFecha
						campo="antecedentes.fechaAvaluo"
						etiqueta={texto('Fecha del avalúo')}
						fechaInicial={fechaAvaluo}
						formatoFecha={selectorFechaFormato}
						id="antecedentes.fechaAvaluo"
						obligatorio
					/>
				</Col>
				<Col lg="4">
					<McCampoSelectorMultiple
						campo="antecedentes.peritoSeleccion"
						etiqueta={texto('Perito valuador')}
						eventoCambio={eventoCambioPerito}
						id="antecedentes.peritoSeleccion"
						obligatorio
						opciones={formatearListaPeritosMcCampoSelectorMultiple(listaPeritos)}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoTexto campo="antecedentes.cuentaPredial" etiqueta={texto('Cuenta predial (CURT)')} id="antecedentes.cuentaPredial" longitudMaxima={50} obligatorio />
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="antecedentes.numeroRecaudadora"
						etiqueta={texto('Recaudadora No.')}
						id="antecedentes.numeroRecaudadora"
						numeroMinimo={0}
						tipo="numeroEnteroPequeno"
					/>
				</Col>
				<Col lg="4">
					<McCampoTexto campo="antecedentes.claveCatastral" etiqueta={texto('Clave catastral')} id="antecedentes.claveCatastral" longitudMaxima={50} />
				</Col>
			</Row>
			<Row>
				<Col lg="6">
					<McCampoTexto campo="antecedentes.nombrePropietario" etiqueta={texto('Nombre del propietario')} id="antecedentes.nombrePropietario" longitudMaxima={100} obligatorio />
				</Col>
				<Col lg="6">
					<McCampoTexto
						campo="antecedentes.nombreSolicitante"
						etiqueta={texto('Nombre del solicitante')}
						eventoBotonDerecha={eventoCopiarSolicitante}
						id="antecedentes.nombreSolicitante"
						longitudMaxima={100}
						obligatorio
						textoDerecha={texto('Copiar del propietario')}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoSelector
						campo="antecedentes.tipoInmuebleId"
						etiqueta={texto('Inmueble que se valúa')}
						eventoCambio={(evento) => eventoCambioDomicilio({ campo: 'tipoInmuebleId', valor: obtenerValorMcCampoSelector(evento) })}
						id="antecedentes.tipoInmuebleId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaTipoInmueble)}
					/>
				</Col>
				<Col lg="4">
					<McCampoSelector
						campo="antecedentes.regimenPropiedadId"
						etiqueta={texto('Régimen de propiedad')}
						id="antecedentes.regimenPropiedadId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaRegimenPropiedad)}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<hr />
					<h5>
						<i className={constantes.icono.domicilio}></i> {texto('Domicilio del Predio')}
					</h5>
				</Col>
				<Col lg="6">
					<McCampoTexto
						campo="domicilio.calle"
						etiqueta={texto('Calle')}
						eventoCambio={(evento) => eventoCambioDomicilio({ campo: 'calle', valor: obtenerValorMcCampoTexto(evento) })}
						id="domicilio.calle"
						longitudMaxima={100}
					/>
				</Col>
				<Col lg="3" md="6">
					<McCampoTexto
						campo={`domicilio.numeroExterior`}
						etiqueta={texto('No. Ext.')}
						eventoCambio={(evento) => eventoCambioDomicilio({ campo: 'numeroExterior', valor: obtenerValorMcCampoTexto(evento) })}
						iconoIzquierda={constantes.icono.numero}
						id="domicilio.numeroExterior"
						longitudMaxima={10}
					/>
				</Col>
				<Col lg="3" md="6">
					<McCampoTexto
						campo="domicilio.numeroInterior"
						etiqueta={texto('No. Int.')}
						eventoCambio={(evento) => eventoCambioDomicilio({ campo: 'numeroInterior', valor: obtenerValorMcCampoTexto(evento) })}
						iconoIzquierda={constantes.icono.numero}
						id="domicilio.numeroInterior"
						longitudMaxima={10}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="3">
					<McCampoSelector
						campo="domicilio.calleTipoId"
						etiqueta={texto('Tipo de Calle')}
						eventoCambio={(evento) => eventoCambioDomicilio({ campo: 'calleTipoId', valor: obtenerValorMcCampoSelector(evento) })}
						id="domicilio.calleTipoId"
						opciones={formatearListaOpcionesMcCampoSelector(listaTipoCalle)}
					/>
				</Col>
				<Col lg="3">
					<McCampoTexto
						campo="domicilio.lote"
						etiqueta={texto('Lote')}
						eventoCambio={(evento) => eventoCambioDomicilio({ campo: 'lote', valor: obtenerValorMcCampoTexto(evento) })}
						id="domicilio.lote"
						longitudMaxima={10}
					/>
				</Col>
				<Col lg="3">
					<McCampoTexto
						campo={`domicilio.manzana`}
						etiqueta={texto('Manzana')}
						eventoCambio={(evento) => eventoCambioDomicilio({ campo: 'manzana', valor: obtenerValorMcCampoTexto(evento) })}
						id="domicilio.manzana"
						longitudMaxima={10}
					/>
				</Col>
				<Col lg="3">
					<McCampoTexto
						campo="domicilio.unidadPrivativa"
						etiqueta={texto('Unidad privativa')}
						eventoCambio={(evento) => eventoCambioDomicilio({ campo: 'unidadPrivativa', valor: obtenerValorMcCampoTexto(evento) })}
						id="domicilio.unidadPrivativa"
						longitudMaxima={10}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="6">
					<McCampoTexto
						campo="domicilio.condominio"
						etiqueta={texto('Condominio')}
						eventoCambio={(evento) => eventoCambioDomicilio({ campo: 'condominio', valor: obtenerValorMcCampoTexto(evento) })}
						id="domicilio.condominio"
						longitudMaxima={100}
					/>
				</Col>
				<Col lg="6">
					<McCampoTexto
						campo="domicilio.fraccionamiento"
						etiqueta={texto('Fraccionamiento')}
						eventoCambio={(evento) => eventoCambioDomicilio({ campo: 'fraccionamiento', valor: obtenerValorMcCampoTexto(evento) })}
						id="domicilio.fraccionamiento"
						longitudMaxima={100}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="6">
					<McCampoTexto campo="domicilio.colonia" etiqueta={texto('Colonia')} id="domicilio.colonia" longitudMaxima={100} />
				</Col>
				<Col lg="3" md="6">
					<McCampoTexto
						campo="domicilio.codigoPostal"
						etiqueta={texto('Código Postal')}
						iconoIzquierda={constantes.icono.correo}
						id="domicilio.codigoPostal"
						longitudMaxima={5}
						tipo="telefono"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoSelectorMultiple
						campo="domicilio.paisSeleccion"
						etiqueta={texto('País')}
						eventoCambio={eventoCambioPais}
						id="domicilio.paisSeleccion"
						obligatorio
						opciones={formatearListaPaisesMcCampoSelectorMultiple(listaPaises)}
					/>
				</Col>
				<Col lg="4">
					<McCampoSelectorMultiple
						campo="domicilio.estadoSeleccion"
						etiqueta={texto('Estado')}
						eventoCambio={eventoCambioEstado}
						id="domicilio.estadoSeleccion"
						obligatorio
						opciones={formatearListaEstadosMcCampoSelectorMultiple(listaEstados)}
					/>
				</Col>
				<Col lg="4">
					<McCampoSelectorMultiple
						campo="domicilio.municipioSeleccion"
						etiqueta={texto('Municipio')}
						eventoCambio={eventoCambioMunicipio}
						id="domicilio.municipioSeleccion"
						obligatorio
						opciones={formatearListaMunicipiosMcCampoSelectorMultiple(listaMunicipios)}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<span className="avaluos-formulario__botton-editar">
						<button className="btn btn-sm btn-primary" onClick={eventoEditarUbicacionPredio} title={texto('Editar Manualmente')} type="button">
							<i className={constantes.icono.editar}></i>
						</button>
					</span>
					<McCampoTexto
						campo="antecedentes.ubicacionPredio"
						etiqueta={texto('Ubicación del predio')}
						id="antecedentes.ubicacionPredio"
						longitudMaxima={500}
						obligatorio
						renglones={3}
						soloLectura={!ubicacionPredioEditable}
						tipo="areaTexto"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
		</Fragment>
	);
};

export default AvaluoFormularioAntecedentes;
