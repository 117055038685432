import { actualizarListaOpcion, crearListaOpcion, eliminarListaOpcion, obtenerListaOpcionPorId, validarListaOpcionesEliminacion } from 'servicios/api/listaOpciones';
import { Card, CardBody, CardFooter, CardHeader, Col, Modal, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEsSeccionCreacion, useEsSeccionEdicion } from 'hooks/useSeccion';
import { useHistory, useParams } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import ListaOpcion from 'modelo/ListaOpcion';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { procesarError } from '@mcsoft/api';
import RegimenPropiedadFormulario from 'componentes/catalogos/regimenesPropiedad/RegimenPropiedadFormulario';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { v4 as uuidv4 } from 'uuid';

const NOMBRE_CLASE = 'paginas/app/catalogos/RegimenPropiedadDetallesEditar';

/**
 * En esta página se pueden editar los detalles de un régimen de propiedad.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const esSeccionCreacion = useEsSeccionCreacion();
	const esSeccionEdicion = useEsSeccionEdicion();
	const { location } = history;
	const { id } = useParams<any>();
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.regimenesPropiedad);
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [formulario, setFormulario] = useState<any>();
	const [sePuedeEliminar, setSePuedeEliminar] = useState<boolean>(false);
	const [modalEliminar, setModalEliminar] = useState<boolean>(false);
	const RUTA_SALIR = `${constantes.ruta.appAdministracionCatalogosRegimenesPropiedadLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, []);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, [location.pathname]);

	/**
	 * Dibuja el modal de eliminación.
	 */
	const dibujarModalEliminar = () => (
		<Modal
			centered={true}
			isOpen={modalEliminar}
			toggle={() => {
				setModalEliminar(modalEliminar);
			}}
		>
			<div className="modal-header border-danger bg-danger">
				<h5 className="modal-title mt-0 text-white">{texto('Eliminar Régimen de Propiedad')}</h5>
			</div>
			<div className="modal-body border-danger">
				<p>
					{texto('Se eliminará el régimen de propiedad, esta operación ')}
					<b>{texto('no puede deshacerse.')}</b>
				</p>
				<p>{texto('¿Estas seguro de continuar con la eliminación del régimen de propiedad?')}</p>
			</div>
			<BarraHerramientas>
				<button
					className="btn btn-secondary"
					id="botonCancelar"
					onClick={() => {
						setModalEliminar(false);
					}}
					type="button"
				>
					<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
				</button>
				<button className="btn btn-danger" id="botonEliminar" onClick={eventoEliminar} type="button">
					<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
				</button>
			</BarraHerramientas>
		</Modal>
	);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: RUTA_SALIR });
		history.push(RUTA_SALIR);
	};

	/**
	 * Elimina el registro.
	 */
	const eventoEliminar = async () => {
		const nombreMetodo = 'eventoEliminar';
		mcLogger.log({ mensaje: `Eliminando el régimen de propiedad.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		dispatch(setPantallaCargaMostrarAction(true));
		setModalEliminar(false);
		try {
			await eliminarListaOpcion({ auth0AccessToken, id });
			mcNotificaciones.exito({ mensaje: texto('Régimen de propiedad eliminado con exito.') });
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: RUTA_SALIR });
			history.push(RUTA_SALIR);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Guarda la información del registro.
	 * - ***valores*** - Valores a guardar.
	 */
	const eventoGuardar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoGuardar';
		try {
			mcLogger.log({ mensaje: `Guardando la información del régimen de propiedad...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const registro = valores as ListaOpcion;
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				await crearListaOpcion({ auth0AccessToken, valores: registro });
				mcLogger.log({ mensaje: `Régimen de propiedad creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Régimen de propiedad creado con éxito.`) });
			}
			if (esSeccionEdicion()) {
				await actualizarListaOpcion({ auth0AccessToken, id: registro.id, valores: registro });
				mcLogger.log({ mensaje: `Régimen de propiedad actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Régimen de propiedad actualizado con éxito.`) });
			}
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: RUTA_SALIR });
			history.push(RUTA_SALIR);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la información del régimen de propiedad:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Inicializando formulario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				setFormulario({
					id: uuidv4(),
					tipo: 'regimenPropiedad'
				});
			}
			if (esSeccionEdicion()) {
				const respuesta = await obtenerListaOpcionPorId({ auth0AccessToken, id });
				const formulario = { ...respuesta.datos };
				const sePuedeEliminar = await validarListaOpcionesEliminacion({ auth0AccessToken, id: formulario.id, tipo: formulario.tipo });
				setSePuedeEliminar(sePuedeEliminar);
				setFormulario(formulario);
			}
			mcLogger.log({ mensaje: `Formulario inicializado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al inicializar formulario:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los valores de las variables que cambian en función de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionCreacion()) {
			setTituloSeccion(texto('Crear régimen de propiedad'));
		}
		if (esSeccionEdicion()) {
			setTituloSeccion(texto('Editar régimen de propiedad'));
		}
	};

	return (
		<Contenedor>
			<Breadcrumb
				enlaces={[
					{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
					{ ruta: RUTA_SALIR, titulo: texto('Lista de regímenes de propiedad') }
				]}
				icono={constantes.icono.regimenesPropiedad}
				titulo={tituloSeccion}
			/>
			{formulario && (
				<Form
					initialValues={formulario}
					onSubmit={eventoGuardar}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Card>
								<CardHeader>
									<BarraHerramientas>
										{tienePermiso({ permiso: constantes.permiso.appAdministracionCatalogosRegimenesPropiedadEliminar, usuario }) && sePuedeEliminar && (
											<button
												className="btn btn-danger"
												id="botonEliminarRegimenPropiedad"
												onClick={() => {
													setModalEliminar(true);
												}}
												type="button"
											>
												<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
											</button>
										)}
										{dibujarModalEliminar()}
									</BarraHerramientas>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md="12">
											<RegimenPropiedadFormulario />
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<BarraHerramientas>
										<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
											<i className={constantes.icono.atras}></i> {texto('Cancelar')}
										</button>
										<button className="btn btn-success" id="botonGuardar" type="submit">
											<i className={constantes.icono.guardar}></i> {texto('Guardar')}
										</button>
									</BarraHerramientas>
								</CardFooter>
							</Card>
						</form>
					)}
					validateOnBlur
				/>
			)}
		</Contenedor>
	);
};

export default Pagina;
