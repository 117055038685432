import { Col, Row } from 'reactstrap';
import { formatearListaOpcionesMcCampoSelector, obtenerListaTipoConstruccion } from 'util/modelo/listasOpciones';
import { McCampoSelectorOpcion, McCampoTexto, McCheckbox } from '@mcsoft/formulario';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import constantes from 'configuracion/constantes';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { validarListaOpcionesDisponibilidad } from 'servicios/api/listaOpciones';

/**
 * Formulario para una calidad de construcción.
 */
const CalidadConstruccionFormulario = () => {
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const dispatch = useDispatch();
	const [listaTipoConstruccion, setListaTipoConstruccion] = useState<Array<McCampoSelectorOpcion>>([]);

	useEffect(() => {
		obtenerListas();
	}, []);

	const obtenerListas = async () => {
		dispatch(setPantallaCargaMostrarAction(true));
		const lista = await obtenerListaTipoConstruccion(auth0AccessToken);
		setListaTipoConstruccion(formatearListaOpcionesMcCampoSelector(lista));
		dispatch(setPantallaCargaMostrarAction(false));
	};

	/**
	 * Válida la abreviatura de la calidad de construcción.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioAbreviatura = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarListaOpcionesDisponibilidad({ auth0AccessToken, campo: 'abreviatura', id: valores.id, tipo: valores.tipo, valor });
		if (!disponible) {
			return 'Ya existe una calidad de construcción con esa abreviatura';
		}
	};

	/**
	 * Válida el código de la calidad de construcción.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioCodigo = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarListaOpcionesDisponibilidad({ auth0AccessToken, campo: 'codigo', id: valores.id, tipo: valores.tipo, valor });
		if (!disponible) {
			return 'Ya existe una calidad de construcción con ese código';
		}
	};

	/**
	 * Válida el nombre de la calidad de construcción.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioNombre = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarListaOpcionesDisponibilidad({ auth0AccessToken, campo: 'nombre', id: valores.id, tipo: valores.tipo, valor });
		if (!disponible) {
			return 'Ya existe una calidad de construcción con ese nombre';
		}
	};

	return (
		<Row>
			<Col md="12">
				<Row>
					<Col md="6">
						<McCampoTexto campo="nombre" etiqueta={texto('Nombre')} funcionValidacionAsincrona={validarFormularioNombre} id="campoNombre" longitudMaxima={100} obligatorio />
					</Col>
					<Col md="3">
						<McCampoTexto
							campo="abreviatura"
							etiqueta={texto('Abreviatura')}
							funcionValidacionAsincrona={validarFormularioAbreviatura}
							id="campoAbreviatura"
							longitudMaxima={2}
							obligatorio
						/>
					</Col>
					<Col md="3">
						<McCampoTexto campo="codigo" etiqueta={texto('Código')} funcionValidacionAsincrona={validarFormularioCodigo} id="campoCodigo" longitudMaxima={2} obligatorio />
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							<i className={constantes.icono.tiposConstruccion}></i> {texto('Tipo de construcción')}
						</h5>
						<p>{texto('Tipos de construcción en los que será elegible esta calidad de construcción.')}</p>
					</Col>
				</Row>
				<Row>
					{listaTipoConstruccion.map((opcion) => (
						<Col key={opcion.valor} lg="4">
							<McCheckbox campo={`tiposConstruccion.${opcion.valor}`} etiqueta={opcion.nombre} id={opcion.valor} />
						</Col>
					))}
				</Row>
			</Col>
		</Row>
	);
};

export default CalidadConstruccionFormulario;
