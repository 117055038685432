import {
	SET_IMAGENES_TEMPORALES_FOTO_AVALUO,
	SET_IMAGENES_TEMPORALES_FOTO_CROQUIS,
	SET_IMAGENES_TEMPORALES_FOTO_MAPA,
	SET_IMAGENES_TEMPORALES_FOTO_MAPA_PERSONALIZADO,
	SET_IMAGENES_TEMPORALES_FOTO_PERFIL,
	SET_IMAGENES_TEMPORALES_FOTO_PERITO_LICENCIA
} from './actionTypes';
import { ReduxAccion } from 'store/actions';

/**
 * Asigna una foto del avalúo temporal en el store de *Redux*.
 * - ***fotoId*** - Id del avalúo.
 * - ***imagenBase64*** - Imagen en base64.
 */
export const setImagenesTemporalesFotoAvaluoAction = ({ fotoId, imagenBase64 }: { fotoId: string; imagenBase64: string }): ReduxAccion => ({
	payload: { fotoId, imagenBase64 },
	type: SET_IMAGENES_TEMPORALES_FOTO_AVALUO
});

/**
 * Asigna una foto del croquis temporal en el store de *Redux*.
 * - ***avaluoId*** - Id del avalúo.
 * - ***imagenBase64*** - Imagen en base64.
 */
export const setImagenesTemporalesFotoCroquisAction = ({ avaluoId, imagenBase64 }: { avaluoId: string; imagenBase64: string }): ReduxAccion => ({
	payload: { avaluoId, imagenBase64 },
	type: SET_IMAGENES_TEMPORALES_FOTO_CROQUIS
});

/**
 * Asigna una foto del mapa temporal en el store de *Redux*.
 * - ***avaluoId*** - Id del avalúo.
 * - ***imagenBase64*** - Imagen en base64.
 */
export const setImagenesTemporalesFotoMapaAction = ({ avaluoId, imagenBase64 }: { avaluoId: string; imagenBase64: string }): ReduxAccion => ({
	payload: { avaluoId, imagenBase64 },
	type: SET_IMAGENES_TEMPORALES_FOTO_MAPA
});

/**
 * Asigna una foto del mapa personalizado temporal en el store de *Redux*.
 * - ***avaluoId*** - Id del avalúo.
 * - ***imagenBase64*** - Imagen en base64.
 */
export const setImagenesTemporalesFotoMapaPersonalizadoAction = ({ avaluoId, imagenBase64 }: { avaluoId: string; imagenBase64: string }): ReduxAccion => ({
	payload: { avaluoId, imagenBase64 },
	type: SET_IMAGENES_TEMPORALES_FOTO_MAPA_PERSONALIZADO
});

/**
 * Asigna una foto de perfil temporal en el store de *Redux*.
 * - ***imagenBase64*** - Imagen en base64.
 * - ***usuarioId*** - Id del usuario.
 */
export const setImagenesTemporalesFotoPerfilAction = ({ imagenBase64, usuarioId }: { imagenBase64: string; usuarioId: string }): ReduxAccion => ({
	payload: { imagenBase64, usuarioId },
	type: SET_IMAGENES_TEMPORALES_FOTO_PERFIL
});

/**
 * Asigna una foto de licencia de perito temporal en el store de *Redux*.
 * - ***imagenBase64*** - Imagen en base64.
 * - ***peritoId*** - Id del perito.
 */
export const setImagenesTemporalesFotoPeritoLicenciaAction = ({ imagenBase64, peritoId }: { imagenBase64: string; peritoId: string }): ReduxAccion => ({
	payload: { imagenBase64, peritoId },
	type: SET_IMAGENES_TEMPORALES_FOTO_PERITO_LICENCIA
});
