import { Card, CardBody, Col, Row } from 'reactstrap';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { McCampoSelector, obtenerValorMcCampoSelector } from '@mcsoft/formulario';
import constantes from 'configuracion/constantes';
import { Form } from 'react-final-form';
import mcLogger from '@mcsoft/logger';
import { obtenerCantidadAvaluos } from 'servicios/api/widgets';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';

const NOMBRE_CLASE = 'componentes/widget/cantidadAvaluos/CantidadAvaluos';

interface CantidadAvaluosProps {
	/**
	 * Indica si los resultados solo mostrarán la información del usuario en sesión.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	filtrarPorUsuario?: boolean;
}

/**
 * Muestra una tarjeta con la información de la cantidad de avalúos.
 */
const CantidadAvaluos = (props: CantidadAvaluosProps) => {
	const { filtrarPorUsuario = false } = props;
	const { sesion } = useSelector((state: StateType) => state);
	const [titulo, setTitulo] = useState<string>('');
	const [informacion, setInformacion] = useState<any>({});
	const [periodo, setPeriodo] = useState<string>('mes');
	const { auth0, usuario } = sesion;
	const { auth0AccessToken } = auth0;

	useEffect(() => {
		obtenerInformacion();
	}, [periodo]);

	/**
	 * Setea la imagen en el estado de Redux para ser usada en el visor de imágenes.
	 */
	const eventoCambioPeriodo = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const valor = obtenerValorMcCampoSelector(evento);
		setPeriodo(valor);
	};

	/**
	 * Setea la imagen en el estado de Redux para ser usada en el visor de imágenes.
	 */
	const obtenerInformacion = async () => {
		const nombreMetodo = 'obtenerInformacion';
		const parametros: any = { auth0AccessToken, periodo };
		setTitulo(texto('Avalúos'));
		if (filtrarPorUsuario) {
			setTitulo(texto('Mis avalúos'));
			parametros.id = usuario.id;
		}
		if (filtrarPorUsuario) {
			parametros.id = usuario.id;
		}
		const respuesta = await obtenerCantidadAvaluos(parametros);
		mcLogger.log({ mensaje: `Información obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const informacion = respuesta.datos;
		setInformacion(informacion);
	};

	return (
		<Fragment>
			<Form
				initialValues={{ seleccion: 'mes' }}
				onSubmit={() => {}}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Card>
							<CardBody>
								<Row>
									<Col lg="12">
										<div className="float-start">
											<h4 className="card-title mb-4">{titulo}</h4>
										</div>
										<div className="float-end">
											<McCampoSelector
												campo="seleccion"
												eventoCambio={eventoCambioPeriodo}
												iconoIzquierda={constantes.icono.fecha}
												id="seleccion"
												opciones={[
													{ nombre: 'Este mes', valor: 'mes' },
													{ nombre: 'Este año', valor: 'año' },
													{ nombre: 'Todos', valor: 'siempre' }
												]}
												sinOpcionEnBlanco
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col md={4}>
										<Card className="text-white-50 text-center" color="warning">
											<CardBody>
												<h5 className="mt-0 text-white">{texto('Incompletos')}</h5>
												<p className="mt-0 text-white" style={{ fontSize: '50px' }}>
													{informacion.incompletos}
												</p>
											</CardBody>
										</Card>
									</Col>
									<Col md={4}>
										<Card className="text-white-50 text-center" color="success">
											<CardBody>
												<h5 className="mt-0 text-white">{texto('Completos')}</h5>
												<p className="mt-0 text-white" style={{ fontSize: '50px' }}>
													{informacion.completos}
												</p>
											</CardBody>
										</Card>
									</Col>
									<Col md={4}>
										<Card className="text-white-50 text-center" color="primary">
											<CardBody>
												<h5 className="mt-0 text-white">{texto('Total')}</h5>
												<p className="mt-0 text-white" style={{ fontSize: '50px' }}>
													{informacion.total}
												</p>
											</CardBody>
										</Card>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</form>
				)}
			/>
		</Fragment>
	);
};

export default CantidadAvaluos;
