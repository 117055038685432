import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import aplicacion from 'configuracion/aplicacion';
import IdiomaMenuDesplegable from 'componentes/tema-comun/barra-superior/IdiomaMenuDesplegable';
import { Link } from 'react-router-dom';
import NotificacionesMenuDesplegable from 'componentes/tema-comun/barra-superior/NotificacionesMenuDesplegable';
import PerfilMenuDesplegable from 'componentes/tema-comun/barra-superior/PerfilMenuDesplegable';
import { setInterfazBarraNavegacionLateralMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';

/**
 * Encabezado de la página que contiene la barra de búsqueda y varios menús desplegables.
 */
const Encabezado = () => {
	const dispatch = useDispatch();
	const { configuracionAplicacion, configuracionSistema, interfaz } = useSelector((state: StateType) => state);
	const [mostrarBuscador, setMostrarBuscador] = useState(false); // TODO Arreglar cuando se agregue la funcionalidad de buscar
	const [logoClaro1080x202, setLogoClaro1080x202] = useState(aplicacion.imagenes.logoClaro1080x202);
	const [logoClaro202x202, setLogoClaro202x202] = useState(aplicacion.imagenes.logoClaro202x202);
	const [logoOscuro1080x202, setLogoOscuro1080x202] = useState(aplicacion.imagenes.logoOscuro1080x202);
	const [logoOscuro202x202, setLogoOscuro202x202] = useState(aplicacion.imagenes.logoOscuro202x202);
	const { busquedaHabilitada, multilenguaje } = configuracionSistema;
	const { imagenLogoClaro202x202, imagenLogoClaro1080x202, imagenLogoOscuro202x202, imagenLogoOscuro1080x202 } = configuracionAplicacion;
	const { barraNavegacionLateralMostrar } = interfaz;

	useEffect(() => {
		setLogoClaro1080x202(imagenLogoClaro1080x202 ? imagenLogoClaro1080x202 : aplicacion.imagenes.logoClaro1080x202);
		setLogoClaro202x202(imagenLogoClaro202x202 ? imagenLogoClaro202x202 : aplicacion.imagenes.logoClaro202x202);
		setLogoOscuro1080x202(imagenLogoOscuro1080x202 ? imagenLogoOscuro1080x202 : aplicacion.imagenes.logoOscuro1080x202);
		setLogoOscuro202x202(imagenLogoOscuro202x202 ? imagenLogoOscuro202x202 : aplicacion.imagenes.logoOscuro202x202);
	}, [imagenLogoClaro202x202, imagenLogoClaro1080x202, imagenLogoOscuro1080x202, imagenLogoOscuro202x202]);

	/**
	 * Intercambia entre el modo ventana y el modo pantalla completa de la aplicación.
	 */
	function eventoCambiarPantallaCompleta() {
		if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen();
				// Parametro: Element.ALLOW_KEYBOARD_INPUT // TODO No he encontrado solucion para que TypeScript lo acepte
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	}

	return (
		<Fragment>
			<div className="navbar-header">
				<div className="d-flex">
					<div className="navbar-brand-box">
						<Link className="logo logo-dark" to="/">
							<span className="logo-sm">
								<img alt="" height="35" src={logoOscuro202x202} />
							</span>
							<span className="logo-lg">
								<img alt="" height="30" src={logoOscuro1080x202} />
							</span>
						</Link>
						<Link className="logo logo-light" to="/">
							<span className="logo-sm">
								<img alt="" height="35" src={logoClaro202x202} />
							</span>
							<span className="logo-lg">
								<img alt="" height="30" src={logoClaro1080x202} />
							</span>
						</Link>
					</div>
					<button
						className="btn btn-sm px-3 font-size-16 d-lg-none header-item waves-effect waves-light"
						data-target="#topnav-menu-content"
						data-toggle="collapse"
						onClick={() => {
							dispatch(setInterfazBarraNavegacionLateralMostrarAction(!barraNavegacionLateralMostrar));
						}}
						type="button"
					>
						<i className="fa fa-fw fa-bars" />
					</button>
					{busquedaHabilitada && (
						<form className="app-search d-none d-lg-block">
							<div className="position-relative">
								<input className="form-control" placeholder={`${texto('Buscar')}...`} type="text" />
								<span className="uil-search"></span>
							</div>
						</form>
					)}
				</div>
				<div className="d-flex">
					{busquedaHabilitada && (
						<div className="dropdown d-inline-block d-lg-none ms-2">
							<button className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" onClick={() => setMostrarBuscador(!mostrarBuscador)} type="button">
								<i className="uil-search"></i>
							</button>
							<div
								aria-labelledby="page-header-search-dropdown"
								className={mostrarBuscador ? 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show' : 'dropdown-menu dropdown-menu-lg dropdown-menu-right p-0'}
							>
								<form className="p-3">
									<div className="form-group m-0">
										<div className="input-group">
											<input aria-label="Recipient's username" className="form-control" placeholder={`${texto('Buscar')}...`} type="text" />
											<div className="input-group-append">
												<button className="btn btn-primary" id="botonEncabezadoBuscar" type="submit">
													<i className="mdi mdi-magnify" />
												</button>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					)}
					{multilenguaje && <IdiomaMenuDesplegable />}
					<div className="dropdown d-none d-lg-inline-block ms-1">
						<button className="btn header-item noti-icon waves-effect" data-toggle="fullscreen" onClick={eventoCambiarPantallaCompleta} type="button">
							<i className="uil-minus-path"></i>
						</button>
					</div>
					<NotificacionesMenuDesplegable />
					<PerfilMenuDesplegable />
				</div>
			</div>
		</Fragment>
	);
};

export default Encabezado;
