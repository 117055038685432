import './BarraHerramientas.scss';
import { Container } from 'reactstrap';
import { ReactNode } from 'react';

interface ContenedorProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
}

/**
 * Componente contenedor de la página.
 */
const Contenedor = (props: ContenedorProps) => {
	const { children } = props;

	return (
		<div className="page-content">
			<Container fluid>{children}</Container>
		</div>
	);
};

export default Contenedor;
