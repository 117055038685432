import './style.scss';
import { Fragment, useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

const ALTURA = 350;
const TAMANO_ETIQUETA_EJE_Y = 10;

interface McGraficaLinealProps {
	/**
	 * Arreglo con los colores de los datos a mostrar.
	 */
	colores: Array<string>;
	/**
	 * Altura de la gráfica en pixeles.
	 *
	 * > ***Predeterminado:*** *350*
	 */
	altura?: number;
	/**
	 * Arreglo con las etiquetas de los datos a mostrar.
	 */
	etiquetas: Array<string>;
	/**
	 * Formato del las etiquetas autogeneradas del eje Y.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Ejemplo:** '{value} ml'.
	 */
	formatoEtiquetaEjeY?: string;
	/**
	 * Nombre del eje X.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	nombreEjeX?: string;
	/**
	 * Nombre del eje Y.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	nombreEjeY?: string;
	/**
	 * Indica si se ocultara el puntero del eje X.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	ocultarPunteroEjeX?: boolean;
	/**
	 * Indica si se ocultara el puntero del eje Y.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	ocultarPunteroEjeY?: boolean;
	/**
	 * Arreglo con las series de datos a mostrar.
	 */
	series: Array<McGraficaLinealSerie>;
	/**
	 * Tamaño de la fuente de las etiquetas autogeneradas del eje Y.
	 *
	 * > ***Predeterminado:*** *10*
	 */
	tamanoEtiquetaEjeY?: string;
}

export interface McGraficaLinealSerie {
	/**
	 * Arreglo con los datos a mostrar.
	 */
	datos: Array<number>;
	/**
	 * Nombre de la serie.
	 */
	nombre: string;
	/**
	 * Tipo de gráfica.
	 *
	 * > ***Predeterminado:*** *'linea'*
	 */
	tipo?: 'barra' | 'linea';
}

/**
 * Muestra una gráfica lineal.
 */
const McGraficaLineal = (props: McGraficaLinealProps) => {
	const [opciones, setOpciones] = useState({});
	const {
		altura = ALTURA,
		colores,
		etiquetas,
		formatoEtiquetaEjeY,
		nombreEjeX,
		nombreEjeY,
		ocultarPunteroEjeX = false,
		ocultarPunteroEjeY = false,
		series,
		tamanoEtiquetaEjeY = TAMANO_ETIQUETA_EJE_Y
	} = props;

	useEffect(() => {
		const arregloSeries = obtenerSeries(series);
		const arregloEtiquetas = obtenerEtiquetas(series);
		setOpciones({
			color: colores,
			grid: {
				borderWidth: 0,
				top: 100,
				x: 50,
				x2: 50,
				y: 30,
				y2: 30,
				zlevel: 0
			},
			legend: {
				data: arregloEtiquetas,
				textStyle: {
					color: ['#74788d']
				}
			},
			series: arregloSeries,
			textStyle: {
				color: ['#74788d']
			},
			tooltip: {
				axisPointer: {
					crossStyle: {
						color: '#999'
					},
					type: ocultarPunteroEjeY ? 'none' : 'cross'
				},
				trigger: 'axis'
			},
			xAxis: {
				axisLabel: {
					interval: 0
				},
				axisLine: {
					lineStyle: {
						color: '#74788d'
					}
				},
				axisPointer: {
					show: !ocultarPunteroEjeX,
					type: 'shadow'
				},
				data: etiquetas,
				name: nombreEjeX,
				type: 'category'
			},
			yAxis: {
				axisLabel: {
					fontSize: tamanoEtiquetaEjeY,
					formatter: formatoEtiquetaEjeY
				},
				axisLine: {
					lineStyle: {
						color: '#74788d'
					}
				},
				name: nombreEjeY,
				type: 'value'
			}
		});
	}, [series]);

	/**
	 * Obtiene las etiquetas de la series.
	 * - ***series*** - Arreglo con las series de la gráfica.
	 */
	const obtenerEtiquetas = (series: Array<McGraficaLinealSerie>): Array<any> => {
		const arregloEtiquetas: Array<any> = [];
		series.forEach((serie) => {
			const { nombre } = serie;
			arregloEtiquetas.push(nombre);
		});
		return arregloEtiquetas;
	};

	/**
	 * Obtiene las series y las formatea para ser usadas en la gráfica.
	 * - ***series*** - Arreglo con las series de la gráfica.
	 */
	const obtenerSeries = (series: Array<McGraficaLinealSerie>): Array<any> => {
		const arregloSeries: Array<any> = [];
		series.forEach((serie) => {
			const { datos, nombre, tipo } = serie;
			arregloSeries.push({
				data: datos,
				name: nombre,
				type: obtenerTipo(tipo)
			});
		});
		return arregloSeries;
	};

	/**
	 * Obtiene el tipo de gráfica.
	 * - ***tipo*** - Tipo de gráfica.
	 */
	const obtenerTipo = (tipo?: string): string => {
		switch (tipo) {
			case 'barra':
				return 'bar';
			case 'linea':
				return 'line';
			default:
				return 'line';
		}
	};

	return (
		<Fragment>
			<ReactEcharts option={opciones} style={{ height: `${altura}px` }} />
		</Fragment>
	);
};

export default McGraficaLineal;
