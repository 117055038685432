import { ReactNode } from 'react';

interface MenuContenedorProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
}

/**
 * Contenedor del menú.
 */
const MenuContenedor = (props: MenuContenedorProps) => {
	const { children } = props;

	return <ul className="navbar-nav">{children}</ul>;
};

export default MenuContenedor;
