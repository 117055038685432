import { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { McCampoSelector, McCampoSelectorMultiple, McCampoSelectorMultipleOpcion, McCampoTexto, obtenerValorMcCampoSelector } from '@mcsoft/formulario';
import { useDispatch, useSelector } from 'react-redux';
import { formatearListaRolesMcCampoSelectorMultiple } from 'util/modelo/roles';
import { formatearListaUsuariosMcCampoSelectorMultiple } from 'util/modelo/usuarios';
import NotificacionTarjeta from 'componentes/tema-comun/barra-superior/NotificacionTarjeta';
import { obtenerUsuarioRolesTodos } from 'servicios/api/usuariosRoles';
import { obtenerUsuariosTodos } from 'servicios/api/usuarios';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useFormState } from 'react-final-form';
import UsuarioNotificacionDetalle from 'modelo/UsuarioNotificacionDetalle';

interface NotificacionFormularioProps {
	destinatarioSeleccionable?: boolean;
}

/**
 * Formulario para una notificación.
 */
const NotificacionFormulario = (props: NotificacionFormularioProps) => {
	const { destinatarioSeleccionable = true } = props;
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const dispatch = useDispatch();
	const formularioValores = useFormState();
	const [destinatariosTipo, setDestinatariosTipo] = useState<string>('');
	const [listaRoles, setListaRoles] = useState<Array<McCampoSelectorMultipleOpcion>>([]);
	const [listaUsuarios, setListaUsuarios] = useState<Array<McCampoSelectorMultipleOpcion>>([]);

	useEffect(() => {
		obtenerListas();
	}, []);

	/**
	 * Obtiene todas las listas requeridas por el formulario.
	 */
	const eventoCambioDestinatarioTipo = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const valor = obtenerValorMcCampoSelector(evento);
		setDestinatariosTipo(valor);
	};

	/**
	 * Obtiene todas las listas requeridas por el formulario.
	 */
	const obtenerListas = async () => {
		dispatch(setPantallaCargaMostrarAction(true));
		const listaUsuarios = await obtenerUsuariosTodos(auth0AccessToken);
		setListaUsuarios(formatearListaUsuariosMcCampoSelectorMultiple(listaUsuarios.datos));
		const listaRoles = await obtenerUsuarioRolesTodos(auth0AccessToken);
		setListaRoles(formatearListaRolesMcCampoSelectorMultiple(listaRoles.datos));
		dispatch(setPantallaCargaMostrarAction(false));
	};

	return (
		<Row>
			<Col md="12">
				<Row>
					<Col md="6">
						<McCampoTexto campo="notificacion.titulo" etiqueta={texto('Título')} id="titulo" longitudMaxima={100} obligatorio />
					</Col>
					<Col md="3">
						<McCampoTexto campo="notificacion.icono" etiqueta={texto('Icono')} id="icono" longitudMaxima={50} />
					</Col>
					<Col md="3">
						<McCampoTexto campo="notificacion.iconoColorFondo" etiqueta={texto('Color de fondo')} id="iconoColorFondo" obligatorio tipo="color" />
					</Col>
					<Col md="12">
						<McCampoTexto campo="notificacion.enlace" etiqueta={texto('Enlace')} id="enlace" longitudMaxima={1000} obligatorio />
					</Col>
					<Col md="12">
						<McCampoTexto campo="notificacion.descripcion" etiqueta={texto('Descripción')} id="descripcion" longitudMaxima={1000} obligatorio renglones={3} tipo="areaTexto" />
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<hr />
						<h5>Vista previa</h5>
						<NotificacionTarjeta esMiniatura={false} usuarioNotificacion={formularioValores.values as UsuarioNotificacionDetalle} />
						<hr />
					</Col>
				</Row>
				{destinatarioSeleccionable && (
					<Row>
						<Col lg="3">
							<McCampoSelector
								campo="destinatarioTipo"
								etiqueta={texto('A quien va dirijida')}
								eventoCambio={eventoCambioDestinatarioTipo}
								id="destinatarioTipo"
								obligatorio
								opciones={[
									{ nombre: 'Todos los usuarios', valor: 'todos' },
									{ nombre: 'Roles específico', valor: 'roles' },
									{ nombre: 'Usuarios específico', valor: 'usuarios' }
								]}
							/>
						</Col>
						<Col lg="9">
							{destinatariosTipo === 'usuarios' && (
								<McCampoSelectorMultiple campo="destinatarioUsuarios" etiqueta={'Destinatarios'} id="destinatarioUsuarios" opciones={listaUsuarios} seleccionMultiple={true} />
							)}
							{destinatariosTipo === 'roles' && (
								<McCampoSelectorMultiple campo="destinatarioRoles" etiqueta={'Destinatarios'} id="destinatarioRoles" opciones={listaRoles} seleccionMultiple={true} />
							)}
						</Col>
					</Row>
				)}
			</Col>
		</Row>
	);
};

export default NotificacionFormulario;
