import { Col, Row } from 'reactstrap';
import { domicilioCompletoPerito, nombreCompletoPerito } from 'util/modelo/peritos';
import Avaluo from 'modelo/Avaluo';
import { Fragment } from 'react';
import { rellenarConCaracteresInicio } from '@mcsoft/cadenas';
import { texto } from 'idiomas';
import useFecha from 'hooks/useFecha';
import Usuario from 'modelo/Usuario';

interface AvaluoDetallesGeneralProps {
	/**
	 * Objeto con la información del avalúo.
	 */
	avaluo: Avaluo;
	/**
	 * Objeto con la información del usuario.
	 */
	usuario: Usuario;
}

/**
 * Detalles para la sección general del avalúo.
 */
const AvaluoDetallesGeneral = (props: AvaluoDetallesGeneralProps) => {
	const fecha = useFecha();
	const { avaluo, usuario } = props;
	if (avaluo && avaluo.antecedentes && avaluo.caracteristicas && avaluo.construccionesDominantes && avaluo.serviciosMunicipales) {
		const { caracteristicas, construccionesDominantes, estatus, folio, serviciosMunicipales } = avaluo;
		const { claveCatastral, cuentaPredial, fechaAvaluo, nombrePropietario, nombreSolicitante, numeroRecaudadora, perito, regimenPropiedad, tipoInmueble, ubicacionPredio } =
			avaluo.antecedentes;
		const { clasificacionZona, coeficienteOcupacion, coeficienteUtilizacion, densidadConstruccion } = caracteristicas;
		const { descripcion: construccionesDominantesDescripcion } = construccionesDominantes;
		const { descripcion: serviciosMunicipalesDescripcion } = serviciosMunicipales;

		const obtenerEstatus = () => {
			let color = '';
			let estatusNombre = '';
			switch (estatus) {
				case 'completo':
					color = 'success';
					estatusNombre = 'COMPLETO';
					break;
				case 'incompleto':
					color = 'warning';
					estatusNombre = 'INCOMPLETO';
					break;
				default:
					color = 'warning';
					estatusNombre = 'INCOMPLETO';
					break;
			}
			return <span className={`badge rounded-pill bg-${color} float-end`}>{estatusNombre}</span>;
		};

		const obtenerLocalidad = () => {
			let ciudadCompleta = '';
			if (perito && perito.domicilio) {
				const { codigoPostal, estado, municipio } = perito.domicilio;

				if (municipio) {
					ciudadCompleta += `${municipio.nombre}`;
				}
				if (estado) {
					ciudadCompleta += `, ${estado.nombre}`;
				}
				if (codigoPostal) {
					ciudadCompleta += ` C.P. ${codigoPostal}`;
				}
			}
			return ciudadCompleta;
		};

		return (
			<Fragment>
				<Row>
					<Col lg="12">
						<span className="avaluo-detalles__estatus">{obtenerEstatus()}</span>
					</Col>
				</Row>
				<Row>
					<Col lg="8">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Nombre del perito')}:</span>
							<span className="avaluo-detalles__valor">{perito ? `${perito.tratamientoProtocolario} ${nombreCompletoPerito(perito)}` : ''}</span>
						</p>
						<Row>
							<Col lg="6">
								<p>
									<span className="avaluo-detalles__etiqueta">{texto('Vigencia')}:</span>
									<span className="avaluo-detalles__valor">{perito ? fecha(perito.licenciaVigencia, 'DD [de] MMMM [de] YYYY') : ''}</span>
								</p>
							</Col>
							<Col lg="6">
								<p>
									<span className="avaluo-detalles__etiqueta">{texto('Registro No')}:</span>
									<span className="avaluo-detalles__valor">{perito ? perito.licenciaNumeroRegistro : ''}</span>
								</p>
							</Col>
						</Row>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Correo electrónico')}:</span>
							<span className="avaluo-detalles__valor">{perito ? perito.correoElectronico : ''}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Teléfono')}:</span>
							<span className="avaluo-detalles__valor">{perito ? perito.telefono : ''}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Domicilio')}:</span>
							<span className="avaluo-detalles__valor">{perito ? domicilioCompletoPerito(perito.domicilio) : ''}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Ciudad')}:</span>
							<span className="avaluo-detalles__valor">{obtenerLocalidad()}</span>
						</p>
					</Col>
					<Col lg="4">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Folio')}:</span>
							<span className="avaluo-detalles__valor">{rellenarConCaracteresInicio({ cadena: folio.toString(), longitud: 6 })}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Código de valuador')}:</span>
							<span className="avaluo-detalles__valor">{usuario.preferencias.avaluosCodigoValuador}</span>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h3>{texto('General')}</h3>
					</Col>
				</Row>
				<Row>
					<Col lg="4">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Cuenta Predial / CURT')}:</span>
							<span className="avaluo-detalles__valor">{cuentaPredial}</span>
						</p>
					</Col>
					<Col lg="4">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Recaudadora No')}:</span>
							<span className="avaluo-detalles__valor">{numeroRecaudadora}</span>
						</p>
					</Col>
					<Col lg="4">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Clave Catastral')}:</span>
							<span className="avaluo-detalles__valor">{claveCatastral}</span>
						</p>
					</Col>
					<Col lg="12">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Nombre del propietario')}:</span>
							<span className="avaluo-detalles__valor">{nombrePropietario}</span>
						</p>
						<br />
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Nombre del solicitante')}:</span>
							<span className="avaluo-detalles__valor">{nombreSolicitante}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Fecha del avalúo')}:</span>
							<span className="avaluo-detalles__valor">{fecha(fechaAvaluo, 'DD [de] MMMM [de] YYYY')}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Inmueble que se valúa')}:</span>
							<span className="avaluo-detalles__valor">{tipoInmueble?.nombre}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Régimen de propiedad')}:</span>
							<span className="avaluo-detalles__valor">{regimenPropiedad?.nombre}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Ubicación del predio')}:</span>
							<span className="avaluo-detalles__valor">{ubicacionPredio}</span>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h3>{texto('Características Urbanas')}</h3>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Clasificación de la zona')}:</span>
							<span className="avaluo-detalles__valor">{clasificacionZona?.nombre}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Densidad de construcción')}:</span>
							<span className="avaluo-detalles__valor">{`${densidadConstruccion} %`}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Tipos de construcción dominante')}:</span>
							<span className="avaluo-detalles__valor">{construccionesDominantesDescripcion}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Servicios municipales')}:</span>
							<span className="avaluo-detalles__valor">{serviciosMunicipalesDescripcion}</span>
						</p>
						<br />
					</Col>
					<Col lg="6">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Coeficiente de utilización (C.U.S.)')}:</span>
							<span className="avaluo-detalles__valor">{coeficienteUtilizacion ? `${coeficienteUtilizacion}%` : ''}</span>
						</p>
					</Col>
					<Col lg="6">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Coeficiente de ocupación (C.O.S.)')}:</span>
							<span className="avaluo-detalles__valor">{coeficienteOcupacion ? `${coeficienteOcupacion}%` : ''}</span>
						</p>
					</Col>
				</Row>
			</Fragment>
		);
	} else {
		return null;
	}
};

export default AvaluoDetallesGeneral;
