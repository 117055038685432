import { Card, CardHeader, Col, Modal, Row } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import { setPantallaCargaMostrarAction, setVisorImagenImagenesAction, setVisorImagenMostrarAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEliminarAvaluoCroquis, useEliminarAvaluoMapaPersonalizado, useObtenerAvaluoCroquis, useObtenerAvaluoMapa, useObtenerAvaluoMapaPersonalizado } from 'hooks/useImagen';
import { useForm, useFormState } from 'react-final-form';
import { useHistory, useLocation } from 'react-router-dom';
import aplicacion from 'configuracion/aplicacion';
import Avaluo from 'modelo/Avaluo';
import AvaluoFormularioUbicacionCroquis from './AvaluoFormularioUbicacionCroquis';
import AvaluoFormularioUbicacionMapaGoogle from './AvaluoFormularioUbicacionMapaGoogle';
import AvaluoFormularioUbicacionMapaPersonalizado from './AvaluoFormularioUbicacionMapaPersonalizado';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import constantes from 'configuracion/constantes';
import { McCampoTexto } from '@mcsoft/formulario';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { McVisorImagenElemento } from '@mcsoft/visor-imagen';
import { procesarError } from '@mcsoft/api';
import { StateType } from 'store';
import { texto } from 'idiomas';

const NOMBRE_CLASE = 'componentes/avaluos/AvaluoFormularioUbicacion';
const CROQUIS_IMAGEN_INICIAL = aplicacion.imagenes.predeterminadaAvaluoCroquis;
const MAPA_IMAGEN_INICIAL = aplicacion.imagenes.predeterminadaAvaluoMapa;
const MAPA_PERSONALIZADO_IMAGEN_INICIAL = aplicacion.imagenes.predeterminadaAvaluoMapaPersonalizado;

const SeccionTipo = {
	CROQUIS: 'croquis',
	INICIO: 'inicio',
	MAPA: 'mapa',
	MAPA_PERSONALIZADO: 'mapaPersonalizado'
};

interface AvaluoFormularioUbicacionProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
}

/**
 * Formulario para la sección ubicación del avalúo.
 */
const AvaluoFormularioUbicacion = (props: AvaluoFormularioUbicacionProps) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const formulario = useForm();
	const eliminarAvaluoCroquis = useEliminarAvaluoCroquis();
	const eliminarAvaluoMapaPersonalizado = useEliminarAvaluoMapaPersonalizado();
	const obtenerAvaluoCroquis = useObtenerAvaluoCroquis();
	const obtenerAvaluoMapa = useObtenerAvaluoMapa();
	const obtenerAvaluoMapaPersonalizado = useObtenerAvaluoMapaPersonalizado();
	const { limpiarValorCampo } = formulario.mutators;
	const formularioValores = useFormState();
	const [pestanaActiva, setPestanaActiva] = useState<string | null>();
	const [seccionActiva, setSeccionActiva] = useState(SeccionTipo.INICIO);
	const { id: avaluoId, ubicacion } = formularioValores.values;
	const { eventoDeshacer, eventoGuardar, eventoSalir, hayCambiosSinGuardar } = props;
	const { croquisImagenNombre, mapaImagenNombre, mapaPersonalizadoImagenNombre } = ubicacion;
	const { fotosCroquis, fotosMapas, fotosMapasPersonalizados } = useSelector((state: StateType) => state.imagenesTemporales);
	const [croquisImagenUrl, setCroquisImagenUrl] = useState<string>(CROQUIS_IMAGEN_INICIAL);
	const [mapaImagenUrl, setMapaImagenUrl] = useState<string>(MAPA_IMAGEN_INICIAL);
	const [mapaPersonalizadoImagenUrl, setMapaPersonalizadoImagenUrl] = useState<string>(MAPA_PERSONALIZADO_IMAGEN_INICIAL);
	const [modalEliminarCroquis, setModalEliminarCroquis] = useState<boolean>(false);
	const [modalEliminarMapaPersonalizado, setModalEliminarMapaPersonalizado] = useState<boolean>(false);

	useEffect(() => {
		const pestana = new URLSearchParams(location.search).get('pestana');
		setPestanaActiva(pestana);
		const seccion = new URLSearchParams(location.search).get('seccion');
		setSeccionActiva(seccion ? seccion : SeccionTipo.INICIO);
	}, [location]);

	useEffect(() => {
		setCroquisImagenUrl(obtenerAvaluoCroquis(formularioValores.values as Avaluo));
	}, [croquisImagenNombre, fotosCroquis]);

	useEffect(() => {
		setMapaImagenUrl(obtenerAvaluoMapa(formularioValores.values as Avaluo));
	}, [mapaImagenNombre, fotosMapas]);

	useEffect(() => {
		setMapaPersonalizadoImagenUrl(obtenerAvaluoMapaPersonalizado(formularioValores.values as Avaluo));
	}, [mapaPersonalizadoImagenNombre, fotosMapasPersonalizados]);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonSalir" onClick={eventoSalir} type="button">
				<i className={constantes.icono.atras}></i> {texto('Salir')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja el modal de eliminación.
	 */
	const dibujarModalEliminarCroquis = () => (
		<Modal
			centered={true}
			isOpen={modalEliminarCroquis}
			toggle={() => {
				setModalEliminarCroquis(modalEliminarCroquis);
			}}
		>
			<div className="modal-header border-danger bg-danger">
				<h5 className="modal-title mt-0 text-white">{texto('Eliminar Croquis')}</h5>
			</div>
			<div className="modal-body border-danger">
				<p>
					{texto('Se eliminará el croquis del avalúo, esta operación ')}
					<b>{texto('no puede deshacerse.')}</b>
				</p>
				<p>{texto('¿Estas seguro de continuar con la eliminación del croquis del avalúo?')}</p>
			</div>
			<BarraHerramientas>
				<button
					className="btn btn-secondary"
					id="botonCancelar"
					onClick={() => {
						setModalEliminarCroquis(false);
					}}
					type="button"
				>
					<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
				</button>
				<button className="btn btn-danger" id="botonEliminar" onClick={eventoEliminarCroquis} type="button">
					<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
				</button>
			</BarraHerramientas>
		</Modal>
	);

	/**
	 * Dibuja el modal de eliminación.
	 */
	const dibujarModalEliminarMapaPersonalizado = () => (
		<Modal
			centered={true}
			isOpen={modalEliminarMapaPersonalizado}
			toggle={() => {
				setModalEliminarMapaPersonalizado(modalEliminarMapaPersonalizado);
			}}
		>
			<div className="modal-header border-danger bg-danger">
				<h5 className="modal-title mt-0 text-white">{texto('Eliminar Mapa Personalizado')}</h5>
			</div>
			<div className="modal-body border-danger">
				<p>
					{texto('Se eliminará el mapa personalizado del avalúo, esta operación ')}
					<b>{texto('no puede deshacerse.')}</b>
				</p>
				<p>{texto('¿Estas seguro de continuar con la eliminación del mapa personalizado del avalúo?')}</p>
			</div>
			<BarraHerramientas>
				<button
					className="btn btn-secondary"
					id="botonCancelar"
					onClick={() => {
						setModalEliminarMapaPersonalizado(false);
					}}
					type="button"
				>
					<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
				</button>
				<button className="btn btn-danger" id="botonEliminar" onClick={eventoEliminarMapaPersonalizado} type="button">
					<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
				</button>
			</BarraHerramientas>
		</Modal>
	);

	/**
	 * Muestra el Modal de eliminación del croquis.
	 */
	const eventoEliminarCroquis = async () => {
		const nombreMetodo = 'eventoEliminarCroquis';
		try {
			dispatch(setPantallaCargaMostrarAction(true));
			await eliminarAvaluoCroquis(croquisImagenNombre);
			const avaluo = formularioValores.values as Avaluo;
			limpiarValorCampo('croquisImagenNombre');
			if (avaluo && avaluo.ubicacion) {
				avaluo.ubicacion.croquisImagenNombre = null;
			}
			setModalEliminarCroquis(false);
			dispatch(setPantallaCargaMostrarAction(false));
			eventoGuardar(avaluo);
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar el avalúo:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Muestra el Modal de eliminación del mapa personalizado.
	 */
	const eventoEliminarMapaPersonalizado = async () => {
		const nombreMetodo = 'eventoEliminarMapaPersonalizado';
		try {
			dispatch(setPantallaCargaMostrarAction(true));
			await eliminarAvaluoMapaPersonalizado(mapaPersonalizadoImagenNombre);
			const avaluo = formularioValores.values as Avaluo;
			limpiarValorCampo('mapaPersonalizadoImagenNombre');
			if (avaluo && avaluo.ubicacion) {
				avaluo.ubicacion.mapaPersonalizadoImagenNombre = null;
			}
			setModalEliminarMapaPersonalizado(false);
			dispatch(setPantallaCargaMostrarAction(false));
			eventoGuardar(avaluo);
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar el avalúo:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Redirige a la sección de inicio de la ubicación.
	 */
	const eventoIrInicio = () => {
		history.push(`${location.pathname}?pestana=${pestanaActiva}&seccion=${SeccionTipo.INICIO}`);
	};

	/**
	 * Setea la imagen del croquis en el estado de Redux para ser visto en el visor de imágenes.
	 */
	const eventoVerImagenCroquis = () => {
		const imagen: McVisorImagenElemento = {
			id: `${avaluoId}_croquis`,
			titulo: texto('Croquis del predio'),
			url: croquisImagenUrl
		};
		dispatch(setVisorImagenImagenesAction([imagen]));
		dispatch(setVisorImagenMostrarAction(true));
	};

	/**
	 * Setea la imagen del mapa de Google en el estado de Redux para ser visto en el visor de imágenes.
	 */
	const eventoVerImagenMapaGoogle = () => {
		const imagen: McVisorImagenElemento = {
			id: `${avaluoId}_mapa`,
			titulo: texto('Mapa del predio'),
			url: mapaImagenUrl
		};
		dispatch(setVisorImagenImagenesAction([imagen]));
		dispatch(setVisorImagenMostrarAction(true));
	};

	/**
	 * Setea la imagen del mapa personalizado en el estado de Redux para ser visto en el visor de imágenes.
	 */
	const eventoVerImagenMapaPersonalizado = () => {
		const imagen: McVisorImagenElemento = {
			id: `${avaluoId}_mapa-personalizado`,
			titulo: texto('Mapa personalizado del predio'),
			url: mapaPersonalizadoImagenUrl
		};
		dispatch(setVisorImagenImagenesAction([imagen]));
		dispatch(setVisorImagenMostrarAction(true));
	};

	/**
	 * Muestra el mapa de Google.
	 */
	const eventoVerMapa = () => {
		history.push(`${location.pathname}?pestana=${pestanaActiva}&seccion=${SeccionTipo.MAPA}`);
	};

	/**
	 * Muestra el selector de archivos para el croquis.
	 */
	const eventoVerSelectorArchivoCroquis = () => {
		history.push(`${location.pathname}?pestana=${pestanaActiva}&seccion=${SeccionTipo.CROQUIS}`);
	};

	/**
	 * Muestra el selector de archivos para el mapa personalizado.
	 */
	const eventoVerSelectorArchivoMapa = () => {
		history.push(`${location.pathname}?pestana=${pestanaActiva}&seccion=${SeccionTipo.MAPA_PERSONALIZADO}`);
	};

	return (
		<Fragment>
			{seccionActiva === SeccionTipo.INICIO && (
				<Fragment>
					<Row>
						<Col lg="12">
							<h3>
								<i className={constantes.icono.mapa}></i> {texto('Ubicación del Predio')}
							</h3>
							<p>{texto('En esta sección del formulario se captura la información de la ubicación del predio del avalúo.')}</p>
							<hr />
						</Col>
					</Row>
					<Row>
						<Col lg="12">{dibujarBarraHerramientas()}</Col>
					</Row>
					<Row>
						<Col lg="4">
							<Card className="border border-primary text-center" color="outline-primary">
								<CardHeader>
									<h5 className="my-0 text-primary text-center">{texto('Ubicación de Google Maps')}</h5>
								</CardHeader>
								{!mapaImagenNombre && <img alt="Mapa del predio" className="mc-soft__imagen--cuadrado avaluos-formulario__mapa-imagen" src={mapaImagenUrl} width="50%" />}
								{mapaImagenNombre && (
									<img alt="Mapa del predio" className="mc-soft__imagen--cuadrado avaluos-formulario__mapa-imagen__link" onClick={eventoVerImagenMapaGoogle} src={mapaImagenUrl} />
								)}
								<div className="btn-group" role="group">
									<button className="btn btn-primary" id="botonSeleccionarUbicacion" onClick={eventoVerMapa} type="button">
										<i className={constantes.icono.marcador}></i> {texto('Seleccionar Ubicación')}
									</button>
								</div>
							</Card>
						</Col>
						<Col lg="4">
							<Card className="border border-primary text-center" color="outline-primary">
								<CardHeader>
									<h5 className="my-0 text-primary text-center">{texto('Mapa Personalizado')}</h5>
								</CardHeader>

								{!mapaPersonalizadoImagenNombre && (
									<img alt="Mapa personalizado del predio" className="mc-soft__imagen--cuadrado avaluos-formulario__mapa-imagen" src={mapaPersonalizadoImagenUrl} width="50%" />
								)}
								{mapaPersonalizadoImagenNombre && (
									<img
										alt="Mapa personalizado del predio"
										className="mc-soft__imagen--cuadrado avaluos-formulario__mapa-imagen__link"
										onClick={eventoVerImagenMapaPersonalizado}
										src={mapaPersonalizadoImagenUrl}
									/>
								)}
								<div className="btn-group" role="group">
									<button className="btn btn-primary" id="botonSubirMapa" onClick={eventoVerSelectorArchivoMapa} type="button">
										<i className={constantes.icono.subir}></i> {texto('Subir Mapa Personalizado')}
									</button>
									{mapaPersonalizadoImagenNombre && (
										<button
											className="btn btn-danger"
											id="botonEliminarMapaPersonalizado"
											onClick={() => {
												setModalEliminarMapaPersonalizado(true);
											}}
											type="button"
										>
											<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
										</button>
									)}
								</div>
							</Card>
						</Col>
						<Col lg="4">
							<Card className="border border-primary text-center" color="outline-primary">
								<CardHeader>
									<h5 className="my-0 text-primary text-center">{texto('Croquis')}</h5>
								</CardHeader>
								{!croquisImagenNombre && <img alt="Croquis del predio" className="mc-soft__imagen--cuadrado avaluos-formulario__mapa-imagen" src={croquisImagenUrl} width="50%" />}
								{croquisImagenNombre && (
									<img
										alt="Croquis del predio"
										className="mc-soft__imagen--cuadrado avaluos-formulario__mapa-imagen__link"
										onClick={eventoVerImagenCroquis}
										src={croquisImagenUrl}
									/>
								)}
								<div className="btn-group" role="group">
									<button className="btn btn-primary" id="botonSubirCroquis" onClick={eventoVerSelectorArchivoCroquis} type="button">
										<i className={constantes.icono.subir}></i> {texto('Subir Croquis')}
									</button>
									{croquisImagenNombre && (
										<button
											className="btn btn-danger"
											id="botonEliminarCroquis"
											onClick={() => {
												setModalEliminarCroquis(true);
											}}
											type="button"
										>
											<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
										</button>
									)}
								</div>
							</Card>
						</Col>
					</Row>
					<Row>
						<Col lg="4">
							<McCampoTexto
								campo="ubicacion.distanciaEsquina"
								etiqueta={texto('Distancia a la esquina')}
								id="ubicacion.distanciaEsquina"
								numeroMinimo={0}
								obligatorio
								soloLectura
								textoDerecha={texto('mts.')}
								tipo="numeroNumerico"
							/>
						</Col>
						<Col lg="8">
							<McCampoTexto campo="ubicacion.distanciaEsquinaCalle" etiqueta={texto('Calle')} id="ubicacion.distanciaEsquinaCalle" longitudMaxima={60} obligatorio soloLectura />
						</Col>
					</Row>
					<Row>
						<Col lg="12">
							<h5>{texto('Coordenadas DD')}</h5>
						</Col>
					</Row>
					<Row>
						<Col lg="6">
							<McCampoTexto
								campo="ubicacion.coordenadasDdLatitud"
								etiqueta={texto('Latitud')}
								id="ubicacion.coordenadasDdLatitud"
								numeroMaximo={90}
								numeroMinimo={-90}
								obligatorio
								soloLectura
								tipo="numeroNumerico"
							/>
						</Col>
						<Col lg="6">
							<McCampoTexto
								campo="ubicacion.coordenadasDdLongitud"
								etiqueta={texto('Longitud')}
								id="ubicacion.coordenadasDdLongitud"
								numeroMaximo={180}
								numeroMinimo={-180}
								obligatorio
								soloLectura
								tipo="numeroNumerico"
							/>
						</Col>
					</Row>
					<Row>
						<Col lg="12">
							<h5>{texto('Coordenadas DMS')}</h5>
						</Col>
					</Row>
					<Row>
						<Col lg="12" xl="6">
							<Row>
								<Col lg="12">
									<p>
										<b>{texto('Latitud')}</b>
									</p>
								</Col>
							</Row>
							<Row>
								<Col lg="3">
									<McCampoTexto
										campo="ubicacion.coordenadasDmsLatitudGrados"
										etiqueta={texto('Grados')}
										id="ubicacion.coordenadasDmsLatitudGrados"
										numeroMaximo={90}
										numeroMinimo={-90}
										obligatorio
										soloLectura
										textoDerecha={texto('°')}
										tipo="numeroEntero"
									/>
								</Col>
								<Col lg="3">
									<McCampoTexto
										campo="ubicacion.coordenadasDmsLatitudMinutos"
										etiqueta={texto('Minutos')}
										id="ubicacion.coordenadasDmsLatitudMinutos"
										numeroMaximo={60}
										numeroMinimo={0}
										obligatorio
										soloLectura
										textoDerecha={texto("'")}
										tipo="numeroEntero"
									/>
								</Col>
								<Col lg="3">
									<McCampoTexto
										campo="ubicacion.coordenadasDmsLatitudSegundos"
										etiqueta={texto('Segundos')}
										id="ubicacion.coordenadasDmsLatitudSegundos"
										numeroMaximo={60}
										numeroMinimo={0}
										obligatorio
										soloLectura
										textoDerecha={texto('"')}
										tipo="numeroNumerico"
									/>
								</Col>
								<Col lg="2">
									<McCampoTexto
										campo="ubicacion.coordenadasDmsLatitudCardinal"
										etiqueta={texto('Cardinal')}
										id="ubicacion.coordenadasDmsLatitudCardinal"
										longitudMaxima={1}
										longitudNoMostrar
										obligatorio
										soloLectura
									/>
								</Col>
							</Row>
						</Col>
						<Col lg="12" xl="6">
							<Row>
								<Col lg="12">
									<p>
										<b>{texto('Longitud')}</b>
									</p>
								</Col>
							</Row>
							<Row>
								<Col lg="3">
									<McCampoTexto
										campo="ubicacion.coordenadasDmsLongitudGrados"
										etiqueta={texto('Grados')}
										id="ubicacion.coordenadasDmsLongitudGrados"
										numeroMaximo={180}
										numeroMinimo={-180}
										obligatorio
										soloLectura
										textoDerecha={texto('°')}
										tipo="numeroEntero"
									/>
								</Col>
								<Col lg="3">
									<McCampoTexto
										campo="ubicacion.coordenadasDmsLongitudMinutos"
										etiqueta={texto('Minutos')}
										id="ubicacion.coordenadasDmsLongitudMinutos"
										numeroMaximo={60}
										numeroMinimo={0}
										obligatorio
										soloLectura
										textoDerecha={texto("'")}
										tipo="numeroEntero"
									/>
								</Col>
								<Col lg="3">
									<McCampoTexto
										campo="ubicacion.coordenadasDmsLongitudSegundos"
										etiqueta={texto('Segundos')}
										id="ubicacion.coordenadasDmsLongitudSegundos"
										numeroMaximo={60}
										numeroMinimo={0}
										obligatorio
										soloLectura
										textoDerecha={texto('"')}
										tipo="numeroNumerico"
									/>
								</Col>
								<Col lg="2">
									<McCampoTexto
										campo="ubicacion.coordenadasDmsLongitudCardinal"
										etiqueta={texto('Cardinal')}
										id="ubicacion.coordenadasDmsLongitudCardinal"
										longitudMaxima={1}
										longitudNoMostrar
										obligatorio
										soloLectura
									/>
								</Col>
							</Row>
						</Col>
					</Row>
					<Row>
						<Col lg="12">
							<h5>{texto('Coordenadas UTM')}</h5>
						</Col>
					</Row>
					<Row>
						<Col lg="2" md="44" sm="4" xl="1">
							<McCampoTexto
								campo="ubicacion.coordenadasUtmZona"
								etiqueta={texto('Zona')}
								id="ubicacion.coordenadasUtmZona"
								longitudMaxima={1}
								longitudNoMostrar
								obligatorio
								soloLectura
							/>
						</Col>
						<Col lg="2" md="4" sm="4" xl="1">
							<McCampoTexto
								campo="ubicacion.coordenadasUtmHuso"
								etiqueta={texto('Huso')}
								id="ubicacion.coordenadasUtmHuso"
								numeroMaximo={60}
								numeroMinimo={1}
								obligatorio
								soloLectura
								tipo="numeroEnteroPequeno"
							/>
						</Col>
						<Col lg="4" md="6" sm="6" xl="3">
							<McCampoTexto
								campo="ubicacion.coordenadasUtmEsteX"
								etiqueta={texto('Este X')}
								id="ubicacion.coordenadasUtmEsteX"
								numeroMinimo={0}
								obligatorio
								soloLectura
								textoDerecha={texto('E')}
								tipo="numeroNumerico"
							/>
						</Col>
						<Col lg="4" md="6" sm="6" xl="3">
							<McCampoTexto
								campo="ubicacion.coordenadasUtmNorteY"
								etiqueta={texto('Norte Y')}
								id="ubicacion.coordenadasUtmNorteY"
								numeroMinimo={0}
								obligatorio
								soloLectura
								textoDerecha={texto('N')}
								tipo="numeroNumerico"
							/>
						</Col>
					</Row>
					<Row>
						<Col lg="12">{dibujarBarraHerramientas()}</Col>
					</Row>
				</Fragment>
			)}
			{seccionActiva === SeccionTipo.MAPA && (
				<Row>
					<Col lg="12">
						<AvaluoFormularioUbicacionMapaGoogle
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardar}
							eventoIrInicio={eventoIrInicio}
							eventoSalir={eventoSalir}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
						/>
					</Col>
				</Row>
			)}
			{seccionActiva === SeccionTipo.MAPA_PERSONALIZADO && (
				<Row>
					<Col lg="12">
						<AvaluoFormularioUbicacionMapaPersonalizado
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardar}
							eventoIrInicio={eventoIrInicio}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
						/>
					</Col>
				</Row>
			)}
			{seccionActiva === SeccionTipo.CROQUIS && (
				<Row>
					<Col lg="12">
						<AvaluoFormularioUbicacionCroquis
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardar}
							eventoIrInicio={eventoIrInicio}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
						/>
					</Col>
				</Row>
			)}
			{dibujarModalEliminarCroquis()}
			{dibujarModalEliminarMapaPersonalizado()}
		</Fragment>
	);
};

export default AvaluoFormularioUbicacion;
