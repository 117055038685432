const marcoPagina = (paginaContenido: any) => {
	const contenido = [
		{
			layout: 'noBorders',
			margin: [0, 0, 0, 50],
			table: {
				body: [[paginaContenido]],
				border: [0, 0, 0, 0],
				heights: [700],
				widths: ['*']
			}
		}
	];
	return contenido;
};

export default marcoPagina;
