/**
 * Ordena un arreglo de cadenas en orden alfabetico.
 * - ***arreglo*** - Arreglo a ordenar.
 * - ***orden?*** - Orden.
 * > - 'asc'
 * > - 'desc'
 *
 * > ***Predeterminado:*** *'asc'*
 */
export const ordenarArregloCadenasAlfabeticamente = ({ arreglo, orden = 'asc' }: { arreglo: Array<string>; orden?: string }): void => {
	arreglo.sort((a: string, b: string) => a.localeCompare(b, 'es', { sensitivity: 'base' }));
	if (orden === 'desc') {
		arreglo.reverse();
	}
};
