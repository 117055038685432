import Avaluo from 'modelo/Avaluo';
import disenos from './disenos';

const seccion = (avaluo: Avaluo) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.distribucion) {
			const { descripcion: descripcionDistribucion } = avaluo.distribucion;
			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									style: 'seccionTitulo',
									text: 'DISTRIBUCIÓN DEL INMUEBLE'
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									layout: { ...disenos.sinBordes, ...disenos.sinPaddingArribaAbajo },
									margin: [0, 0, 0, 0],
									style: 'seccion',
									table: {
										body: [
											[
												{
													style: 'seccionEtiquetaNormal',
													text: 'Consta de:'
												},
												{
													style: { bold: true },
													text: descripcionDistribucion
												}
											]
										],
										heights: [30],
										widths: [50, '*']
									}
								}
							]
						],
						widths: ['*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;
