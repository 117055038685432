/* eslint-disable no-console */
// Niveles de logueo
const TITULO_API = 'API';
const TITULO_DEV = 'DEV';
const TITULO_ERROR = 'ERROR';
const TITULO_LOCAL_STORAGE = 'LOCAL STORAGE';
const TITULO_LOG = 'LOG';
const TITULO_NODE_MODULE = 'NODE_MODULE';
const TITULO_REACT = 'REACT';
const TITULO_REDUX = 'REDUX';
const TITULO_SESSION_STORAGE = 'SESSION STORAGE';
// Colores
const COLOR_FONDO = '#FFFFFF';
const COLOR_MENSAJE_API = '#006600';
const COLOR_MENSAJE_DEV = '#996600';
const COLOR_MENSAJE_ERROR = '#FF0000';
const COLOR_MENSAJE_NODE_MODULE = '#ff6600';
const COLOR_MENSAJE_LOCAL_STORAGE = '#ff00ff';
const COLOR_MENSAJE_LOG = '#000000';
const COLOR_MENSAJE_REACT = '#0000FF';
const COLOR_MENSAJE_REDUX = '#6600FF';
const COLOR_MENSAJE_SESSION_STORAGE = '#ff3399';
// Estilos CSS
const CSS_MENSAJE_API = `background: ${COLOR_FONDO}; color: ${COLOR_MENSAJE_API}`;
const CSS_MENSAJE_DEV = `background: ${COLOR_FONDO}; color: ${COLOR_MENSAJE_DEV}`;
const CSS_MENSAJE_ERROR = `background: ${COLOR_FONDO}; color: ${COLOR_MENSAJE_ERROR}`;
const CSS_MENSAJE_NODE_MODULE = `background: ${COLOR_FONDO}; color: ${COLOR_MENSAJE_NODE_MODULE}`;
const CSS_MENSAJE_LOCAL_STORAGE = `background: ${COLOR_FONDO}; color: ${COLOR_MENSAJE_LOCAL_STORAGE}`;
const CSS_MENSAJE_LOG = `background: ${COLOR_FONDO}; color: ${COLOR_MENSAJE_LOG}`;
const CSS_MENSAJE_REACT = `background: ${COLOR_FONDO}; color: ${COLOR_MENSAJE_REACT}`;
const CSS_MENSAJE_REDUX = `background: ${COLOR_FONDO}; color: ${COLOR_MENSAJE_REDUX}`;
const CSS_MENSAJE_SESSION_STORAGE = `background: ${COLOR_FONDO}; color: ${COLOR_MENSAJE_SESSION_STORAGE}`;

/**
 * Clase para generar los registros de log en la consola del navegador.
 */
class McLoggerConsola {
	/**
	 * Crea un registro en la consola en la consola de nivel API. Utilizado para logs de llamadas a API's.
	 * - ***forzarLog*** Indica si se debe forzar el log (Utilizado para desarrollo).
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	api({
		forzarLog,
		mensaje,
		nombreArchivo,
		nombreMetodo,
		objetoExtra
	}: {
		forzarLog: boolean;
		mensaje: string;
		nombreArchivo: string;
		nombreMetodo: string;
		objetoExtra?: any;
	}): void {
		const parametros = [CSS_MENSAJE_API, CSS_MENSAJE_API, CSS_MENSAJE_API, CSS_MENSAJE_API, CSS_MENSAJE_API, CSS_MENSAJE_API, CSS_MENSAJE_API];
		if ((typeof window !== 'undefined' && sessionStorage.getItem('loggerApi') === 'true') || forzarLog) {
			if (objetoExtra) {
				parametros.push(objetoExtra);
			}
			console.log(`%c[%c${TITULO_API}%c]%c ${nombreArchivo}%c (%c${nombreMetodo}%c) ${mensaje}`, ...parametros);
		}
	}

	/**
	 * Crea un registro en la consola de nivel DEV. Utilizado para logs de desarrollo.
	 * - ***forzarLog*** Indica si se debe forzar el log (Utilizado para desarrollo).
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	dev({
		forzarLog,
		mensaje,
		nombreArchivo,
		nombreMetodo,
		objetoExtra
	}: {
		forzarLog: boolean;
		mensaje: string;
		nombreArchivo: string;
		nombreMetodo: string;
		objetoExtra?: any;
	}): void {
		const parametros = [CSS_MENSAJE_DEV, CSS_MENSAJE_DEV, CSS_MENSAJE_DEV, CSS_MENSAJE_DEV, CSS_MENSAJE_DEV, CSS_MENSAJE_DEV, CSS_MENSAJE_DEV];
		if ((typeof window !== 'undefined' && sessionStorage.getItem('loggerDev') === 'true') || forzarLog) {
			if (objetoExtra) {
				parametros.push(objetoExtra);
			}
			console.log(`%c[%c${TITULO_DEV}%c]%c ${nombreArchivo}%c (%c${nombreMetodo}%c) ${mensaje}`, ...parametros);
		}
	}

	/**
	 * Crea un registro en la consola de nivel ERROR. Utilizado para logs de error (Funciona en node).
	 * - ***forzarLog*** Indica si se debe forzar el log (Utilizado para desarrollo).
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	error({
		forzarLog,
		mensaje,
		nombreArchivo,
		nombreMetodo,
		objetoExtra
	}: {
		forzarLog: boolean;
		mensaje: string;
		nombreArchivo: string;
		nombreMetodo: string;
		objetoExtra?: any;
	}): void {
		const parametros = [CSS_MENSAJE_ERROR, CSS_MENSAJE_ERROR, CSS_MENSAJE_ERROR, CSS_MENSAJE_ERROR, CSS_MENSAJE_ERROR, CSS_MENSAJE_ERROR, CSS_MENSAJE_ERROR];
		if ((typeof window !== 'undefined' && sessionStorage.getItem('loggerError') === 'true') || forzarLog) {
			if (objetoExtra) {
				parametros.push(objetoExtra);
			}
			console.error(`%c[%c${TITULO_ERROR}%c]%c ${nombreArchivo}%c (%c${nombreMetodo}%c) ${mensaje}`, ...parametros);
		}
		if (typeof window === 'undefined' && process.env.NODE_ENV !== 'test') {
			if (objetoExtra) {
				console.log(`[${TITULO_ERROR}] ${nombreArchivo} (${nombreMetodo}) ${mensaje}`, objetoExtra);
			} else {
				console.log(`[${TITULO_ERROR}] ${nombreArchivo} (${nombreMetodo}) ${mensaje}`);
			}
		}
	}

	/**
	 * Crea un registro en la consola de nivel LOCAL STORAGE. Utilizado para logs de cambios en el localStorage.
	 * - ***forzarLog*** Indica si se debe forzar el log (Utilizado para desarrollo).
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	localStorage({
		forzarLog,
		mensaje,
		nombreArchivo,
		nombreMetodo,
		objetoExtra
	}: {
		forzarLog: boolean;
		mensaje: string;
		nombreArchivo: string;
		nombreMetodo: string;
		objetoExtra?: any;
	}): void {
		const parametros = [
			CSS_MENSAJE_LOCAL_STORAGE,
			CSS_MENSAJE_LOCAL_STORAGE,
			CSS_MENSAJE_LOCAL_STORAGE,
			CSS_MENSAJE_LOCAL_STORAGE,
			CSS_MENSAJE_LOCAL_STORAGE,
			CSS_MENSAJE_LOCAL_STORAGE,
			CSS_MENSAJE_LOCAL_STORAGE
		];
		if ((typeof window !== 'undefined' && sessionStorage.getItem('loggerLocalStorage') === 'true') || forzarLog) {
			if (objetoExtra) {
				parametros.push(objetoExtra);
			}
			console.log(`%c[%c${TITULO_LOCAL_STORAGE}%c]%c ${nombreArchivo}%c (%c${nombreMetodo}%c) ${mensaje}`, ...parametros);
		}
	}

	/**
	 * Crea un registro en la consola de nivel LOG. Utilizado para logs normales (Funciona en node).
	 * - ***forzarLog*** Indica si se debe forzar el log (Utilizado para desarrollo).
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	log({
		forzarLog,
		mensaje,
		nombreArchivo,
		nombreMetodo,
		objetoExtra
	}: {
		forzarLog: boolean;
		mensaje: string;
		nombreArchivo: string;
		nombreMetodo: string;
		objetoExtra?: any;
	}): void {
		const parametros = [CSS_MENSAJE_LOG, CSS_MENSAJE_LOG, CSS_MENSAJE_LOG, CSS_MENSAJE_LOG, CSS_MENSAJE_LOG, CSS_MENSAJE_LOG, CSS_MENSAJE_LOG];
		if ((typeof window !== 'undefined' && sessionStorage.getItem('loggerLog') === 'true') || forzarLog) {
			if (objetoExtra) {
				parametros.push(objetoExtra);
			}
			console.log(`%c[%c${TITULO_LOG}%c]%c ${nombreArchivo}%c (%c${nombreMetodo}%c) ${mensaje}`, ...parametros);
		}
		if (typeof window === 'undefined' && process.env.NODE_ENV !== 'test') {
			if (objetoExtra) {
				console.log(`[${TITULO_LOG}] ${nombreArchivo} (${nombreMetodo}) ${mensaje}`, objetoExtra);
			} else {
				console.log(`[${TITULO_LOG}] ${nombreArchivo} (${nombreMetodo}) ${mensaje}`);
			}
		}
	}

	/**
	 * Crea un registro en la consola de nivel NODE MODULE. Utilizado para logs dentro de librerias externas.
	 * - ***forzarLog*** Indica si se debe forzar el log (Utilizado para desarrollo).
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	nodeModule({
		forzarLog,
		mensaje,
		nombreArchivo,
		nombreMetodo,
		objetoExtra
	}: {
		forzarLog: boolean;
		mensaje: string;
		nombreArchivo: string;
		nombreMetodo: string;
		objetoExtra?: any;
	}): void {
		const parametros = [
			CSS_MENSAJE_NODE_MODULE,
			CSS_MENSAJE_NODE_MODULE,
			CSS_MENSAJE_NODE_MODULE,
			CSS_MENSAJE_NODE_MODULE,
			CSS_MENSAJE_NODE_MODULE,
			CSS_MENSAJE_NODE_MODULE,
			CSS_MENSAJE_NODE_MODULE
		];
		if ((typeof window !== 'undefined' && sessionStorage.getItem('loggerNodeModule') === 'true') || forzarLog) {
			if (objetoExtra) {
				parametros.push(objetoExtra);
			}
			console.log(`%c[%c${TITULO_NODE_MODULE}%c]%c ${nombreArchivo}%c (%c${nombreMetodo}%c) ${mensaje}`, ...parametros);
		}
	}

	/**
	 * Crea un registro en la consola de nivel REACT. Utilizado para logs de los ciclos de vida de React.
	 * - ***forzarLog*** Indica si se debe forzar el log (Utilizado para desarrollo).
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	react({
		forzarLog,
		mensaje,
		nombreArchivo,
		nombreMetodo,
		objetoExtra
	}: {
		forzarLog: boolean;
		mensaje: string;
		nombreArchivo: string;
		nombreMetodo: string;
		objetoExtra?: any;
	}): void {
		const parametros = [CSS_MENSAJE_REACT, CSS_MENSAJE_REACT, CSS_MENSAJE_REACT, CSS_MENSAJE_REACT, CSS_MENSAJE_REACT, CSS_MENSAJE_REACT, CSS_MENSAJE_REACT];
		if ((typeof window !== 'undefined' && sessionStorage.getItem('loggerReact') === 'true') || forzarLog) {
			if (objetoExtra) {
				parametros.push(objetoExtra);
			}
			console.log(`%c[%c${TITULO_REACT}%c]%c ${nombreArchivo}%c (%c${nombreMetodo}%c) ${mensaje}`, ...parametros);
		}
	}

	/**
	 * Crea un registro en la consola de nivel REDUX. Utilizado para logs de cambios en el state de Redux.
	 * - ***forzarLog*** Indica si se debe forzar el log (Utilizado para desarrollo).
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	redux({
		forzarLog,
		mensaje,
		nombreArchivo,
		nombreMetodo,
		objetoExtra
	}: {
		forzarLog: boolean;
		mensaje: string;
		nombreArchivo: string;
		nombreMetodo: string;
		objetoExtra?: any;
	}): void {
		const parametros = [CSS_MENSAJE_REDUX, CSS_MENSAJE_REDUX, CSS_MENSAJE_REDUX, CSS_MENSAJE_REDUX, CSS_MENSAJE_REDUX, CSS_MENSAJE_REDUX, CSS_MENSAJE_REDUX];
		if ((typeof window !== 'undefined' && sessionStorage.getItem('loggerRedux') === 'true') || forzarLog) {
			if (objetoExtra) {
				parametros.push(objetoExtra);
			}
			console.log(`%c[%c${TITULO_REDUX}%c]%c ${nombreArchivo}%c (%c${nombreMetodo}%c) ${mensaje}`, ...parametros);
		}
	}

	/**
	 * Crea un registro en la consola de nivel SESSION STORAGE. Utilizado para logs de cambios en el sessionStorage.
	 * - ***forzarLog*** Indica si se debe forzar el log (Utilizado para desarrollo).
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	sessionStorage({
		forzarLog,
		mensaje,
		nombreArchivo,
		nombreMetodo,
		objetoExtra
	}: {
		forzarLog: boolean;
		mensaje: string;
		nombreArchivo: string;
		nombreMetodo: string;
		objetoExtra?: any;
	}): void {
		const parametros = [
			CSS_MENSAJE_SESSION_STORAGE,
			CSS_MENSAJE_SESSION_STORAGE,
			CSS_MENSAJE_SESSION_STORAGE,
			CSS_MENSAJE_SESSION_STORAGE,
			CSS_MENSAJE_SESSION_STORAGE,
			CSS_MENSAJE_SESSION_STORAGE,
			CSS_MENSAJE_SESSION_STORAGE
		];
		if ((typeof window !== 'undefined' && sessionStorage.getItem('loggerSessionStorage') === 'true') || forzarLog) {
			if (objetoExtra) {
				parametros.push(objetoExtra);
			}
			console.log(`%c[%c${TITULO_SESSION_STORAGE}%c]%c ${nombreArchivo}%c (%c${nombreMetodo}%c) ${mensaje}`, ...parametros);
		}
	}
}

export default McLoggerConsola;
