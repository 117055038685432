import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { filtrarListaPorTipoConstruccion, formatearListaOpcionesMcCampoSelector, obtenerConstruccionCodigoAbreviatura, obtenerOpcionDeLaLista } from 'util/modelo/listasOpciones';
import { McCampoSelector, McCampoTexto } from '@mcsoft/formulario';
import { useForm, useFormState } from 'react-final-form';
import Avaluo from 'modelo/Avaluo';
import AvaluoInstalacionesEspecialesDetalle from 'modelo/AvaluoInstalacionesEspecialesDetalle';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import { convertirNumeroEnteroANumeroRomano } from '@mcsoft/numeros';
import ListaOpcion from 'modelo/ListaOpcion';
import { texto } from 'idiomas';
import { useEsSeccionPreferencias } from 'hooks/useSeccion';
import useMoneda from 'hooks/useMoneda';

interface AvaluoFormularioInstalacionesEspecialesProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
	listaCalidadConstruccion: Array<ListaOpcion> | undefined;
	listaEstadoConservacion: Array<ListaOpcion> | undefined;
	listaMotivo: Array<ListaOpcion> | undefined;
	listaTipoConstruccion: Array<ListaOpcion> | undefined;
	listaUnidadSuperficie: Array<ListaOpcion> | undefined;
}

/**
 * Formulario para la sección instalaciones especiales del avalúo.
 */
const AvaluoFormularioEspeciales = (props: AvaluoFormularioInstalacionesEspecialesProps) => {
	const moneda = useMoneda();
	const esSeccionPreferencias = useEsSeccionPreferencias();
	const formulario = useForm();
	const formularioValores = useFormState();
	const {
		eventoDeshacer,
		eventoGuardar,
		eventoSalir,
		hayCambiosSinGuardar,
		listaCalidadConstruccion,
		listaEstadoConservacion,
		listaMotivo,
		listaTipoConstruccion,
		listaUnidadSuperficie
	} = props;
	const { detalles, formularioDetalle, superficieTotal, superficieUnidadId, valor, valorTotal } = formularioValores.values.instalacionesEspeciales;
	const {
		detalleAjusteFactor,
		detalleAjusteMotivoId,
		detalleBloque,
		detalleCalidadId,
		detalleCodigo,
		detalleCodigoClasificación,
		detalleEdad,
		detalleEstadoConservacionId,
		detalleIndiviso,
		detalleSuperficie,
		detalleSuperficieUnidadId,
		detalleTipoId,
		detalleValorUnitario
	} = formularioDetalle;
	const [construccionBloqueEditable, setConstruccionBloqueEditable] = useState<boolean>(false);
	const [construccionCodigoEditable, setConstruccionCodigoEditable] = useState<boolean>(false);
	const [construccionCodigoClasificaciónEditable, setConstruccionCodigoClasificaciónEditable] = useState<boolean>(false);
	const [detallesTemporales, setDetallesTemporales] = useState<Array<AvaluoInstalacionesEspecialesDetalle>>([]);
	const [listaCalidadConstruccionDetalle, setListaCalidadConstruccionDetalle] = useState<Array<ListaOpcion>>([]);
	const [listaEstadoConservacionDetalle, setListaEstadoConservacionDetalle] = useState<Array<ListaOpcion>>([]);

	useEffect(() => {
		setDetallesTemporales(detalles);
	}, [detalles]);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonSalir" onClick={eventoSalir} type="button">
				<i className={constantes.icono.atras}></i> {texto('Salir')}
			</button>
			<button className="btn btn-warning" disabled={!hayCambiosSinGuardar} id="botonDeshacer" onClick={eventoDeshacer} type="button">
				<i className={constantes.icono.deshacer}></i> {texto('Deshacer')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardar} id="botonGuardar" onClick={eventoGuardarFormulario} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardar}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	/**
	 * Agrega un detalle de instalación especial.
	 */
	const eventoAgregarDetalle = () => {
		const { cambiarValorCampo, limpiarValorCampo } = formulario.mutators;
		cambiarValorCampo('instalacionesEspeciales.indiviso', detalleIndiviso);
		cambiarValorCampo('instalacionesEspeciales.superficieUnidadId', detalleSuperficieUnidadId);
		const valorResultante = Number(detalleValorUnitario) * Number(detalleAjusteFactor) * Number(detalleSuperficie);
		const detalleNuevo: AvaluoInstalacionesEspecialesDetalle = {
			ajusteFactor: detalleAjusteFactor,
			ajusteMotivoId: detalleAjusteMotivoId,
			avaluoInstalacionesEspecialesId: '',
			bloque: detalleBloque,
			calidadId: detalleCalidadId,
			codigo: detalleCodigo,
			codigoClasificación: detalleCodigoClasificación,
			edad: detalleEdad,
			estadoConservacionId: detalleEstadoConservacionId,
			id: '',
			superficie: detalleSuperficie,
			superficieUnidadId: detalleSuperficieUnidadId,
			tipoConstruccionId: detalleTipoId,
			valorResultante: valorResultante,
			valorUnitario: detalleValorUnitario
		};
		obtenerValorDeLasInstalacionesEspeciales({ detalles: [...detallesTemporales, detalleNuevo], indiviso: detalleIndiviso });
		setDetallesTemporales([...detallesTemporales, detalleNuevo]);
		// Limpiar formulario
		cambiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleBloque', Number(detalleBloque) + 1);
		limpiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleCodigo');
		limpiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleCodigoClasificación');
		limpiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleTipoId');
		limpiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleCalidadId');
		limpiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleEstadoConservacionId');
		limpiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleSuperficie');
		limpiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleValorUnitario');
		limpiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleEdad');
	};

	/**
	 * Evento que se ejecuta cuando cambia la calidad seleccionada.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioConstruccionCalidad = (evento: ChangeEvent<HTMLSelectElement>) => {
		const calidadIdSeleccion = evento.target.value;
		obtenerCodigoBloque({ calidadId: calidadIdSeleccion, estadoConservacionId: detalleEstadoConservacionId, tipoId: detalleTipoId });
	};

	/**
	 * Evento que se ejecuta cuando cambia el estado de conservación seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioConstruccionEstadoConservacion = (evento: ChangeEvent<HTMLSelectElement>) => {
		const estadoConservacionIdSeleccion = evento.target.value;
		obtenerCodigoBloque({ calidadId: detalleCalidadId, estadoConservacionId: estadoConservacionIdSeleccion, tipoId: detalleTipoId });
	};

	/**
	 * Evento que se ejecuta cuando cambia el tipo de construcción seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioConstruccionTipo = (evento: ChangeEvent<HTMLSelectElement>) => {
		const { limpiarValorCampo } = formulario.mutators;
		const tipoIdSeleccion = evento.target.value;
		obtenerCodigoBloque({ calidadId: detalleCalidadId, estadoConservacionId: detalleEstadoConservacionId, tipoId: tipoIdSeleccion });
		setListaCalidadConstruccionDetalle(filtrarListaPorTipoConstruccion({ lista: listaCalidadConstruccion, tipoConstruccionId: evento.target.value }));
		setListaEstadoConservacionDetalle(filtrarListaPorTipoConstruccion({ lista: listaEstadoConservacion, tipoConstruccionId: evento.target.value }));
		limpiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleCalidadId');
		limpiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleEstadoConservacionId');
	};

	/**
	 * Habilita la edición manual del campo bloque.
	 */
	const eventoEditarConstruccionBloque = () => {
		setConstruccionBloqueEditable(!construccionBloqueEditable);
	};

	/**
	 * Habilita la edición manual del campo código.
	 */
	const eventoEditarConstruccionCodigo = () => {
		setConstruccionCodigoEditable(!construccionCodigoEditable);
	};

	/**
	 * Habilita la edición manual del campo código clasificación.
	 */
	const eventoEditarConstruccionCodigoClasificación = () => {
		setConstruccionCodigoClasificaciónEditable(!construccionCodigoClasificaciónEditable);
	};

	/**
	 * Evento que se ejecuta cuando se elimina un detalle de la lista.
	 * - ***indice*** - Indice del detalle eliminada.
	 */
	const eventoEliminarDetalle = (indice: number) => {
		const listaTemporal = [...detallesTemporales];
		listaTemporal.splice(indice, 1);
		setDetallesTemporales(listaTemporal);
		obtenerValorDeLasInstalacionesEspeciales({ detalles: listaTemporal, indiviso: detalleIndiviso });
	};

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardarFormulario = () => {
		const { cambiarValorCampo } = formulario.mutators;
		const avaluo = formularioValores.values as Avaluo;
		cambiarValorCampo('instalacionesEspeciales.detalles', detallesTemporales);
		if (avaluo.instalacionesEspeciales) {
			avaluo.instalacionesEspeciales.detalles = detallesTemporales;
		}
		if (detallesTemporales.length === 0) {
			if (avaluo.instalacionesEspeciales) {
				avaluo.instalacionesEspeciales.superficieUnidadId = null;
			}
		}
		eventoGuardar(avaluo);
	};

	/**
	 * Indica si el formulario cumple con todos los campos requeridos para agregar un detalle.
	 */
	const formularioValido = () => {
		const { instalacionesEspeciales } = formularioValores.errors as any;
		return instalacionesEspeciales === undefined;
	};

	/**
	 * Obtiene el codigo y la abreviatura de el bloque de construcción.
	 * - ***calidadId*** - Id de la calidad de construcción.
	 * - ***estadoConservacionId*** - Id del estado de conservación.
	 * - ***tipoId*** - Id del tipo de construcción.
	 */
	const obtenerCodigoBloque = ({ calidadId, estadoConservacionId, tipoId }: { calidadId: string; estadoConservacionId: string; tipoId: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const codigoAbreviatura = obtenerConstruccionCodigoAbreviatura({
			calidadValor: calidadId,
			estadoConservacionValor: estadoConservacionId,
			listaCalidades: listaCalidadConstruccion,
			listaEstadosConservacion: listaEstadoConservacion,
			listaTiposConstruccion: listaTipoConstruccion,
			tipoValor: tipoId
		});
		cambiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleCodigo', codigoAbreviatura.codigo);
		cambiarValorCampo('instalacionesEspeciales.formularioDetalle.detalleCodigoClasificación', codigoAbreviatura.abreviatura);
	};

	/**
	 * Calcula el valor total de las instalaciones especiales.
	 * - ***detalles*** - Arreglo con los detalles de las construcciones.
	 * - ***indiviso*** - Indiviso de las constucciones.
	 */
	const obtenerValorDeLasInstalacionesEspeciales = ({ detalles, indiviso }: { detalles: Array<AvaluoInstalacionesEspecialesDetalle>; indiviso: number }) => {
		const { cambiarValorCampo } = formulario.mutators;
		let superficieTotal = 0;
		let valor = 0;
		detalles.forEach((detalle: AvaluoInstalacionesEspecialesDetalle) => {
			superficieTotal = superficieTotal + Number(detalle.superficie);
			valor = valor + Number(detalle.valorResultante);
		});
		const valorTotal = (valor * indiviso) / 100;
		cambiarValorCampo('instalacionesEspeciales.superficieTotal', superficieTotal);
		cambiarValorCampo('instalacionesEspeciales.valor', valor);
		cambiarValorCampo('instalacionesEspeciales.valorTotal', valorTotal);
	};

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.alberca}></i> {texto('Instalaciones Especiales')}
					</h3>
					<p>{texto('En esta sección del formulario se captura la información de las instalaciones especiales del avalúo.')}</p>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoSelector
						campo="instalacionesEspeciales.formularioDetalle.detalleTipoId"
						etiqueta={texto('Tipo de construcción')}
						eventoCambio={eventoCambioConstruccionTipo}
						id="instalacionesEspeciales.formularioDetalle.detalleTipoId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaTipoConstruccion)}
					/>
				</Col>
				<Col lg="4">
					<McCampoSelector
						campo="instalacionesEspeciales.formularioDetalle.detalleCalidadId"
						etiqueta={texto('Calidad')}
						eventoCambio={eventoCambioConstruccionCalidad}
						id="instalacionesEspeciales.formularioDetalle.detalleCalidadId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaCalidadConstruccionDetalle)}
					/>
				</Col>
				<Col lg="4">
					<McCampoSelector
						campo="instalacionesEspeciales.formularioDetalle.detalleEstadoConservacionId"
						etiqueta={texto('Estado de conservación')}
						eventoCambio={eventoCambioConstruccionEstadoConservacion}
						id="instalacionesEspeciales.formularioDetalle.detalleEstadoConservacionId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaEstadoConservacionDetalle)}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					{!esSeccionPreferencias() && (
						<span className="avaluos-formulario__botton-editar">
							<button className="btn btn-sm btn-primary" onClick={eventoEditarConstruccionBloque} title={texto('Editar Bloque')} type="button">
								<i className={constantes.icono.editar}></i>
							</button>
						</span>
					)}
					<McCampoTexto
						campo="instalacionesEspeciales.formularioDetalle.detalleBloque"
						etiqueta={texto('Bloque')}
						id="instalacionesEspeciales.formularioDetalle.detalleBloque"
						numeroMinimo={0}
						obligatorio
						soloLectura={!esSeccionPreferencias() && !construccionBloqueEditable}
						tipo="numeroEnteroPequeno"
					/>
				</Col>
				<Col lg="4">
					{!esSeccionPreferencias() && (
						<span className="avaluos-formulario__botton-editar">
							<button className="btn btn-sm btn-primary" onClick={eventoEditarConstruccionCodigo} title={texto('Editar Código')} type="button">
								<i className={constantes.icono.editar}></i>
							</button>
						</span>
					)}
					<McCampoTexto
						campo="instalacionesEspeciales.formularioDetalle.detalleCodigo"
						etiqueta={texto('Código')}
						id="instalacionesEspeciales.formularioDetalle.detalleCodigo"
						longitudMaxima={20}
						obligatorio
						soloLectura={!construccionCodigoEditable}
					/>
				</Col>
				<Col lg="4">
					{!esSeccionPreferencias() && (
						<span className="avaluos-formulario__botton-editar">
							<button className="btn btn-sm btn-primary" onClick={eventoEditarConstruccionCodigoClasificación} title={texto('Editar Clasificación')} type="button">
								<i className={constantes.icono.editar}></i>
							</button>
						</span>
					)}
					<McCampoTexto
						campo="instalacionesEspeciales.formularioDetalle.detalleCodigoClasificación"
						etiqueta={texto('Clasificación')}
						id="instalacionesEspeciales.formularioDetalle.detalleCodigoClasificación"
						longitudMaxima={20}
						obligatorio
						soloLectura={!construccionCodigoClasificaciónEditable}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="3">
					<McCampoSelector
						campo="instalacionesEspeciales.formularioDetalle.detalleSuperficieUnidadId"
						etiqueta={texto('Unidad')}
						id="instalacionesEspeciales.formularioDetalle.detalleSuperficieUnidadId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaUnidadSuperficie)}
						soloLectura={detallesTemporales.length > 0}
					/>
				</Col>
				<Col lg="3">
					<McCampoTexto
						campo="instalacionesEspeciales.formularioDetalle.detalleSuperficie"
						etiqueta={texto('Superficie')}
						id="instalacionesEspeciales.formularioDetalle.detalleSuperficie"
						numeroMinimo={0}
						obligatorio
						tipo="numeroNumerico"
					/>
				</Col>
				<Col lg="3">
					<McCampoTexto
						campo="instalacionesEspeciales.formularioDetalle.detalleValorUnitario"
						etiqueta={texto('Valor unitario')}
						id="instalacionesEspeciales.formularioDetalle.detalleValorUnitario"
						numeroMinimo={0}
						obligatorio
						tipo="numeroNumerico"
					/>
				</Col>
				<Col lg="3">
					<McCampoTexto
						campo="instalacionesEspeciales.formularioDetalle.detalleEdad"
						etiqueta={texto('Edad')}
						id="instalacionesEspeciales.formularioDetalle.detalleEdad"
						longitudMaxima={10}
						obligatorio
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoTexto
						campo="instalacionesEspeciales.formularioDetalle.detalleAjusteFactor"
						etiqueta={texto('Factor de ajuste')}
						id="instalacionesEspeciales.formularioDetalle.detalleAjusteFactor"
						numeroMinimo={0}
						obligatorio
						tipo="numeroNumerico"
					/>
				</Col>
				<Col lg="4">
					<McCampoSelector
						campo="instalacionesEspeciales.formularioDetalle.detalleAjusteMotivoId"
						etiqueta={texto('Motivo de ajuste')}
						id="instalacionesEspeciales.formularioDetalle.detalleAjusteMotivoId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaMotivo)}
					/>
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="instalacionesEspeciales.formularioDetalle.detalleIndiviso"
						etiqueta={texto('Indiviso en su caso')}
						id="instalacionesEspeciales.formularioDetalle.detalleIndiviso"
						numeroMaximo={100}
						numeroMinimo={0}
						obligatorio
						soloLectura={detallesTemporales.length > 0}
						textoDerecha="%"
						tipo="numeroNumerico"
					/>
				</Col>
			</Row>
			{!esSeccionPreferencias() && (
				<Row>
					<Col lg="12">
						<BarraHerramientas>
							<button
								className="btn btn-success"
								disabled={!formularioValido()}
								id="botonAgregarDetalle"
								onClick={eventoAgregarDetalle}
								title={texto('Agregar Detalle')}
								type="button"
							>
								<i className={constantes.icono.agregar}></i> {texto('Agregar')}
							</button>
						</BarraHerramientas>
					</Col>
				</Row>
			)}
			{!esSeccionPreferencias() && (
				<Row>
					<Col lg="12">
						<div className="table-responsive">
							<table className="table mc-tabla-paginada table-hover table-striped" id="tabla">
								<thead>
									<tr style={{ padding: '10px 0px', textAlign: 'center' }}>
										<th style={{ width: '50px' }}>{texto('Bloque')}</th>
										<th style={{ width: '50px' }}>{texto('Código')}</th>
										<th style={{ width: '87px' }}>{texto('Clasificación')}</th>
										<th style={{ width: '100px' }}>{texto('Calidad')}</th>
										<th style={{ width: '185px' }}>{texto('Estado de conservación')}</th>
										<th>{texto('Superficie')}</th>
										<th>{texto('Valor unitario')}</th>
										<th style={{ width: '40px' }}>{texto('Edad')}</th>
										<th style={{ width: '50px' }}>{texto('Factor')}</th>
										<th style={{ width: '200px' }}>{texto('Motivo')}</th>
										<th>{texto('Valor resultante')}</th>
										<th style={{ width: '64px' }}></th>
									</tr>
								</thead>
								<tbody>
									{detallesTemporales.map((detalle, indice) => (
										<tr key={`bloque_${indice}`} style={{ padding: '10px 0px', textAlign: 'center' }}>
											<td>{convertirNumeroEnteroANumeroRomano(Number(detalle.bloque))}</td>
											<td>{detalle.codigo}</td>
											<td>{detalle.codigoClasificación}</td>
											<td>{obtenerOpcionDeLaLista(listaCalidadConstruccion, detalle.calidadId)?.nombre}</td>
											<td>{obtenerOpcionDeLaLista(listaEstadoConservacion, detalle.estadoConservacionId)?.nombre}</td>
											<td style={{ textAlign: 'right' }}>{`${Number(detalle.superficie).toLocaleString('es-mx')} ${
												obtenerOpcionDeLaLista(listaUnidadSuperficie, detalle.superficieUnidadId)?.simbolo || ''
											}`}</td>
											<td style={{ textAlign: 'right' }}>{moneda(Number(detalle.valorUnitario))}</td>
											<td>{detalle.edad}</td>
											<td>{detalle.ajusteFactor}</td>
											<td>{obtenerOpcionDeLaLista(listaMotivo, detalle.ajusteMotivoId)?.nombre}</td>
											<td style={{ textAlign: 'right' }}>{moneda(Number(detalle.valorResultante))}</td>
											<td>
												<button
													className="btn btn-danger"
													id={`botonEliminarDetalle_${detalle.id}`}
													onClick={() => eventoEliminarDetalle(indice)}
													title={texto('Eliminar Detalle')}
													type="button"
												>
													<i className={constantes.icono.eliminar}></i>
												</button>
											</td>
										</tr>
									))}
									{detallesTemporales.length === 0 && (
										<tr>
											<td className="avaluos-formulario__tabla__sin-detalles" colSpan={12}>
												{texto('Sin detalles.')}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			)}
			{detallesTemporales.length > 0 && (
				<Row>
					<Col lg="12">
						<table className="table-nowrap table-centered mb-0 table">
							<tbody>
								<tr>
									<th className="text-end">{texto('Superficie total')}:</th>
									<td className="text-end" style={{ width: '200px' }}>
										{`${Number(superficieTotal).toLocaleString('es-MX')} ${obtenerOpcionDeLaLista(listaUnidadSuperficie, superficieUnidadId)?.simbolo || ''}`}
									</td>
								</tr>
								<tr>
									<th className="border-0 text-end">{texto('Valor de las instalaciones especiales')}:</th>
									<td className="border-0 text-end">{moneda(Number(valor))}</td>
								</tr>
								<tr>
									<th className="border-0 text-end">{texto('Valor total')}:</th>
									<td className="border-0 text-end">
										<h4 className="m-0">{moneda(Number(valorTotal))}</h4>
									</td>
								</tr>
							</tbody>
						</table>
					</Col>
				</Row>
			)}
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
		</Fragment>
	);
};

export default AvaluoFormularioEspeciales;
