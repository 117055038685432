import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del correo de verificación.
 */
const InformacionCorreoVerificacion = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Te enviamos un correo de verificación, revisa tu bandeja de spam.')}</p>
		</div>
	</Fragment>
);

export default InformacionCorreoVerificacion;
