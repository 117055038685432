import {
	GET_SESION,
	GET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD,
	SAVE_SESION_PREFERENCIAS,
	SAVE_SESION_USUARIO_NOTIFICACIONES_CANTIDAD,
	SET_SESION_AUTH0,
	SET_SESION_IDIOMA,
	SET_SESION_PREFERENCIAS_ARIBUTOS,
	SET_SESION_USUARIO,
	SET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD
} from './actionTypes';
import Auth0Sesion from 'modelo/Auth0Sesion';
import { ReduxAccion } from 'store/actions';
import Sesion from 'modelo/Sesion';
import Usuario from 'modelo/Usuario';
import UsuarioNotificacionDetalle from 'modelo/UsuarioNotificacionDetalle';
import UsuarioPreferencias from 'modelo/UsuarioPreferencias';

/**
 * Obtiene el usuario de la base de datos y asigna la sesion en el store de *Redux*. Si no existe el usuario lo crea.
 * - ***auth0*** - Sesión de Auth0.
 */
export const getSesionAction = (auth0: Auth0Sesion): ReduxAccion => ({
	payload: {
		auth0
	},
	type: GET_SESION
});

/**
 * Obtiene la cantidad de notificaciones del usuario de la base de datos y las asigna a la sesion en el store de *Redux*.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***usuario*** - Usuario en sesión.
 */
export const getSesionUsuarioNotificacionesCantidadAction = ({ auth0AccessToken, usuario }: { auth0AccessToken: string; usuario: Usuario }): ReduxAccion => ({
	payload: {
		auth0AccessToken,
		usuario
	},
	type: GET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD
});

/**
 * Actualiza un atributo de las preferencias de interfaz del usuario en la base de datos, aplica el cambio a la interfaz y la asigna en el store de *Redux*.
 * - ***atributo*** - Nombre del atributo a actualizar.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***preferencias*** - Preferencias del usuario en sesión.
 */
export const saveSesionPreferenciasAction = ({
	atributo,
	auth0AccessToken,
	preferencias
}: {
	atributo: string;
	auth0AccessToken: string;
	preferencias: UsuarioPreferencias;
}): ReduxAccion => ({
	payload: {
		atributo,
		auth0AccessToken,
		preferencias
	},
	type: SAVE_SESION_PREFERENCIAS
});

/**
 * Actualiza una notificacion del usuario en la base de datos, obtiene la cantidad de notificaciones nuevas del usuario y las asigna en el store de *Redux*.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***notificacion*** - Notificacion a actualizar.
 * - ***usuario*** - Usuario en sesión.
 */
export const saveSesionUsuarioNotificacinesCantidadAction = ({
	auth0AccessToken,
	notificacion,
	usuario
}: {
	auth0AccessToken: string;
	notificacion: UsuarioNotificacionDetalle;
	usuario: Usuario;
}): ReduxAccion => ({
	payload: {
		auth0AccessToken,
		notificacion,
		usuario
	},
	type: SAVE_SESION_USUARIO_NOTIFICACIONES_CANTIDAD
});

/**
 * Asigna la sesión de Auth0 en el store de *Redux*.
 * - ***auth0*** - Sesión de Auth0.
 */
export const setSesionAuth0Action = (auth0: Auth0Sesion): ReduxAccion => ({
	payload: {
		auth0
	},
	type: SET_SESION_AUTH0
});

/**
 * Actualiza el idioma de la aplicación en la base de datos, cambia el idioma de la aplicación y lo asigna en el store de *Redux*.
 * - ***idioma*** - Código del idioma.
 * - ***sesion*** - Sesión del usuario.
 */
export const setSesionIdiomaAction = ({ idioma, sesion }: { idioma: string; sesion: Sesion }): ReduxAccion => ({
	payload: {
		idioma,
		sesion
	},
	type: SET_SESION_IDIOMA
});

/**
 * Asigna un atributo de las preferencias de interfaz del usuario en el store de *Redux*.
 * - ***atributo*** - Nombre del atributo a asignar.
 * - ***preferencias*** - Preferencias del usuario en sesión.
 */
export const setSesionPreferenciasAtributosAction = ({ atributo, preferencias }: { atributo: string; preferencias: UsuarioPreferencias }): ReduxAccion => ({
	payload: {
		atributo,
		preferencias
	},
	type: SET_SESION_PREFERENCIAS_ARIBUTOS
});

/**
 * Asigna el usuario en el store de *Redux*.
 * - ***usuario*** - Usuario en sesión.
 */
export const setSesionUsuarioAction = (usuario: Usuario): ReduxAccion => ({
	payload: {
		usuario
	},
	type: SET_SESION_USUARIO
});

/**
 * Asigna la cantidad de notificaciones del usuario en el store de *Redux*.
 * - ***cantidadNotificaciones*** - Cantidad de notificaciones del usuario en sesión.
 */
export const setSesionUsuarioNotificacionesCantidadAction = (cantidadNotificaciones: number): ReduxAccion => ({
	payload: {
		cantidadNotificaciones
	},
	type: SET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD
});
