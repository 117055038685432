import { Fragment, useEffect } from 'react';
import BarraNavegacionSuperiorContenido from 'componentes/tema-horizontal/BarraNavegacionSuperiorContenido';
import { Collapse } from 'reactstrap';
import { StateType } from 'store';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

/**
 * Barra de navegación superior.
 */
const BarraNavegacionSuperior = () => {
	const location = useLocation();
	const { barraNavegacionLateralMostrar } = useSelector((state: StateType) => state.interfaz);

	useEffect(() => {
		let itemMenuActivo = null;
		const ul = document.getElementById('navigation');
		const items = ul && ul.getElementsByTagName('a');
		if (items) {
			for (let i = 0; i < items.length; ++i) {
				if (location.pathname === items[i].pathname) {
					itemMenuActivo = items[i];
				} else {
					desactivarMenuPadre(items[i]);
				}
			}
		}
		if (itemMenuActivo) {
			activarMenuPadre(itemMenuActivo);
		}
	}, [location.pathname]);

	/**
	 * Activa grupo el recibido.
	 * - ***item*** - Grupo activo.
	 */
	const activarGrupo = (item: HTMLElement) => {
		if (item.classList.contains('nav-link')) {
			item.classList.add('active');
		}
		if (item.classList.contains('nav-item')) {
			const contenedor = item.firstElementChild;
			if (contenedor) {
				contenedor.classList.add('active');
			}
		}
	};

	/**
	 * Marca como activo los botones padre del botón actualmente activo.
	 * - ***item*** - Botón activo (Elemento HTML).
	 */
	const activarMenuPadre = (item: HTMLAnchorElement) => {
		item.classList.add('active');
		const itemPadre = item.parentElement?.previousSibling;
		if (itemPadre) {
			activarGrupo(itemPadre as any);
			const itemPadreNivel2 = itemPadre.parentElement;
			if (itemPadreNivel2) {
				activarGrupo(itemPadreNivel2);
				const itemPadreNivel3 = itemPadreNivel2.parentElement;
				if (itemPadreNivel3) {
					activarGrupo(itemPadreNivel3);
					const itemPadreNivel4 = itemPadreNivel3.parentElement;
					if (itemPadreNivel4) {
						activarGrupo(itemPadreNivel4);
						const itemPadreNivel5 = itemPadreNivel4.parentElement;
						if (itemPadreNivel5) {
							activarGrupo(itemPadreNivel5);
							const itemPadreNivel6 = itemPadreNivel5.parentElement;
							if (itemPadreNivel6) {
								activarGrupo(itemPadreNivel6);
							}
						}
					}
				}
			}
		}
		return false;
	};

	/**
	 * Desactiva grupo el recibido.
	 * - ***item*** - Grupo activo.
	 */
	const desactivarGrupo = (item: HTMLElement | ChildNode) => {
		if (item.classList.contains('nav-link')) {
			item.classList.remove('active');
		}
		if (item.classList.contains('navbar-nav')) {
			const hijos = (item as any).children;
			for (let i = 0; i < hijos.length; i++) {
				const contenedor = hijos[i].lastElementChild;
				if (contenedor) {
					contenedor.classList.remove('active');
				}
			}
		}
	};

	/**
	 * Marca como inactivo los botones padre del botón actualmente inactivo.
	 * - ***item*** - Botón activo.
	 */
	const desactivarMenuPadre = (item: HTMLAnchorElement) => {
		item.classList.remove('active');
		const itemPadre = item.parentElement?.previousSibling;
		if (itemPadre) {
			desactivarGrupo(itemPadre);
			const itemPadreNivel2 = itemPadre.parentElement;
			if (itemPadreNivel2) {
				desactivarGrupo(itemPadre);
				const itemPadreNivel3 = itemPadreNivel2.parentElement;
				if (itemPadreNivel3) {
					desactivarGrupo(itemPadre);
					const itemPadreNivel4 = itemPadreNivel3.parentElement;
					if (itemPadreNivel4) {
						desactivarGrupo(itemPadre);
						const itemPadreNivel5 = itemPadreNivel4.parentElement;
						if (itemPadreNivel5) {
							desactivarGrupo(itemPadre);
							const itemPadreNivel6 = itemPadreNivel5.parentElement;
							if (itemPadreNivel6) {
								desactivarGrupo(itemPadre);
							}
						}
					}
				}
			}
		}
		return false;
	};

	return (
		<Fragment>
			<div className="container-fluid">
				<div className="topnav">
					<nav className="navbar navbar-light navbar-expand-lg topnav-menu" id="navigation">
						<Collapse className="navbar-collapse" id="topnav-menu-content" isOpen={barraNavegacionLateralMostrar}>
							<BarraNavegacionSuperiorContenido />
						</Collapse>
					</nav>
				</div>
			</div>
		</Fragment>
	);
};

export default BarraNavegacionSuperior;
