import { Col, Row } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import { McCampoSelector, McCampoSelectorOpcion, McCampoTexto } from '@mcsoft/formulario';
import { useForm, useFormState } from 'react-final-form';
import Avaluo from 'modelo/Avaluo';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import { texto } from 'idiomas';
import { tieneValor } from '@mcsoft/validaciones';

interface AvaluoFormularioObservacionesProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
}

/**
 * Formulario para la sección observaciones del avalúo.
 */
const AvaluoFormularioObservaciones = (props: AvaluoFormularioObservacionesProps) => {
	const formulario = useForm();
	const formularioValores = useFormState();
	const { valorReferido, valoresReferidos } = formularioValores.values.observaciones;
	const { eventoDeshacer, eventoGuardar, eventoSalir, hayCambiosSinGuardar } = props;
	const [detallesTemporales, setDetallesTemporales] = useState<Array<string>>([]);
	const [listaAnos, setListaAnos] = useState<Array<McCampoSelectorOpcion>>([]);

	useEffect(() => {
		setListaAnos(obtenerListaAnos());
	}, []);

	useEffect(() => {
		setDetallesTemporales(valoresReferidos);
	}, [valoresReferidos]);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonSalir" onClick={eventoSalir} type="button">
				<i className={constantes.icono.atras}></i> {texto('Salir')}
			</button>
			<button className="btn btn-warning" disabled={!hayCambiosSinGuardar} id="botonDeshacer" onClick={eventoDeshacer} type="button">
				<i className={constantes.icono.deshacer}></i> {texto('Deshacer')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardar} id="botonGuardar" onClick={eventoGuardarFormulario} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardar}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	const eventoAgregarValorReferido = () => {
		if (formularioValido()) {
			const { cambiarValorCampo, limpiarValorCampo } = formulario.mutators;
			setDetallesTemporales([...detallesTemporales, valorReferido]);
			cambiarValorCampo('observaciones.valoresReferidos', [...detallesTemporales, valorReferido]);
			limpiarValorCampo('observaciones.valorReferido');
		}
	};

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardarFormulario = () => {
		const { cambiarValorCampo } = formulario.mutators;
		const avaluo = formularioValores.values as Avaluo;
		cambiarValorCampo('observaciones.valoresReferidos', detallesTemporales);
		if (avaluo.observaciones) {
			avaluo.observaciones.valoresReferidos = detallesTemporales;
		}
		eventoGuardar(avaluo);
	};

	/**
	 * Evento que se ejecuta cuando se elimina un valor referido de la lista.
	 * - ***indice*** - Indice del detalle eliminada.
	 */
	const eventoEliminarValorReferido = (indice: number) => {
		const { cambiarValorCampo } = formulario.mutators;
		const listaTemporal = [...detallesTemporales];
		listaTemporal.splice(indice, 1);
		setDetallesTemporales(listaTemporal);
		cambiarValorCampo('observaciones.valoresReferidos', listaTemporal);
	};

	/**
	 * Indica si el formulario es valido.
	 */
	const formularioValido = () => tieneValor(valorReferido);

	/**
	 * Obtiene la lista de años.
	 */
	const obtenerListaAnos = () => {
		const anoActual = new Date().getFullYear();
		const primerAno = 1949;
		const listaAnos: Array<McCampoSelectorOpcion> = [];
		for (let i = anoActual; i > primerAno; i--) {
			listaAnos.push({
				nombre: i.toString(),
				valor: i.toString()
			});
		}
		return listaAnos;
	};

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.observaciones}></i> {texto('Observaciones')}
					</h3>
					<p>{texto('En esta sección del formulario se capturan las observaciones del avalúo.')}</p>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoSelector
						campo="observaciones.valorReferido"
						etiqueta={texto('Valores referidos')}
						eventoBotonDerecha={eventoAgregarValorReferido}
						id="observaciones.valorReferido"
						opciones={listaAnos}
						textoDerecha={texto('Agregar')}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<div className="table-responsive">
						<table className="table mc-tabla-paginada table-hover table-striped" id="tabla">
							<thead>
								<tr>
									<th>{texto('Valor')}</th>
									<th style={{ width: '64px' }}></th>
								</tr>
							</thead>
							<tbody>
								{detallesTemporales.map((valorReferido: string, indice: number) => (
									<tr key={`valorReferido_${indice}`}>
										<td>{valorReferido}</td>
										<td>
											<button
												className="btn btn-danger"
												id={`botonEliminarDetalle_${indice}`}
												onClick={() => eventoEliminarValorReferido(indice)}
												title={texto('Eliminar Valor Referido')}
												type="button"
											>
												<i className={constantes.icono.eliminar}></i>
											</button>
										</td>
									</tr>
								))}
								{detallesTemporales.length === 0 && (
									<tr>
										<td className="avaluos-formulario__tabla__sin-detalles" colSpan={11}>
											{texto('Sin valores referidos.')}
										</td>
									</tr>
								)}
							</tbody>
						</table>
					</div>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<McCampoTexto
						campo="observaciones.descripcion"
						etiqueta={texto('Observaciones generales')}
						id="observaciones.descripcion"
						longitudMaxima={360}
						renglones={3}
						tipo="areaTexto"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
		</Fragment>
	);
};

export default AvaluoFormularioObservaciones;
