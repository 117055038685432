import { SET_VISOR_IMAGEN_IMAGENES, SET_VISOR_IMAGEN_MOSTRAR } from './actionTypes';
import mcLogger from '@mcsoft/logger';
import { ReduxAccion } from 'store/actions';
import VisorImagen from 'modelo/VisorImagen';

const NOMBRE_CLASE = 'store/visorImagen/reducer';

const stateInicial: VisorImagen = {
	imagenes: [],
	mostrar: false
};

export default (state = stateInicial, action: ReduxAccion) => {
	switch (action.type) {
		case SET_VISOR_IMAGEN_IMAGENES:
			const { imagenes } = action.payload;
			state = {
				...state,
				imagenes: imagenes
			};
			mcLogger.redux({
				mensaje: 'Seteando state "visorImagen":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_VISOR_IMAGEN_IMAGENES,
				objetoExtra: state
			});
			break;
		case SET_VISOR_IMAGEN_MOSTRAR:
			const { mostrar } = action.payload;
			state = {
				...state,
				mostrar: mostrar
			};
			mcLogger.redux({
				mensaje: 'Seteando state "visorImagen":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_VISOR_IMAGEN_MOSTRAR,
				objetoExtra: state
			});
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
