import {
	GET_CONFIGURACION_APLICACION,
	SET_CONFIGURACION_APLICACION
} from './actionTypes';
import ConfiguracionAplicacion from 'modelo/ConfiguracionAplicacion';
import { ReduxAccion } from 'store/actions';

/**
 * Obtiene la configuración de la aplicación de la base de datos y la asigna en el store de *Redux*.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const getConfiguracionAplicacionAction = (auth0AccessToken: string): ReduxAccion => ({
	payload: {
		auth0AccessToken
	},
	type: GET_CONFIGURACION_APLICACION
});

/**
 * Asigna la configuración de la aplicación en el store de *Redux*.
 * - ***configuracionAplicacion*** - Token de Auth0.
 */
export const setConfiguracionAplicacionAction = (configuracionAplicacion: ConfiguracionAplicacion): ReduxAccion => ({
	payload: {
		configuracionAplicacion
	},
	type: SET_CONFIGURACION_APLICACION
});