import './SistemaFormulario.scss';
import { Col, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { pestanaConErrores } from '@mcsoft/formulario';
import SistemaFormularioAws from './SistemaFormularioAws';
import SistemaFormularioDatos from './SistemaFormularioDatos';
import SistemaFormularioGeneral from './SistemaFormularioGeneral';
import { texto } from 'idiomas';
import { useFormState } from 'react-final-form';

const PestanaTipo = {
	AWS: 'aws',
	DATOS: 'datos',
	GENERAL: 'general'
};

/**
 * Formulario para la configuración del sistema.
 */
const SistemaFormulario = () => {
	const history = useHistory();
	const location = useLocation();
	const formularioValores = useFormState();
	const [pestanaActiva, setPestanaActiva] = useState(PestanaTipo.GENERAL);
	const [erroresPestanaGeneral, setErroresPestanaGeneral] = useState(false);
	const [erroresPestanaAws, setErroresPestanaAws] = useState(false);
	const [erroresPestanaDatos, setErroresPestanaDatos] = useState(false);

	useEffect(() => {
		const pestana = new URLSearchParams(location.search).get('pestana');
		setPestanaActiva(pestana ? pestana : PestanaTipo.GENERAL);
	}, [location]);

	useEffect(() => {
		setErroresPestanaGeneral(
			pestanaConErrores({
				camposPestana: ['nombreAplicacion', 'copyright', 'multilenguaje', 'busquedaHabilitada'],
				errores: formularioValores.errors
			})
		);
		setErroresPestanaAws(
			pestanaConErrores({
				camposPestana: [
					'awsS3BucketAvaluoFotosMiniaturas',
					'awsS3BucketAvaluoFotos',
					'awsS3BucketAvaluoMapas',
					'awsS3BucketPeritoLicencia',
					'awsS3BucketPeritoLicenciaMiniatura',
					'awsS3BucketUsuarioFotos',
					'awsS3BucketUsuarioFotosMiniaturas'
				],
				errores: formularioValores.errors
			})
		);
		setErroresPestanaDatos(
			pestanaConErrores({
				camposPestana: ['datosUsuarioDomicilioEntrega', 'datosUsuarioDomicilioFacturacion'],
				errores: formularioValores.errors
			})
		);
	}, [formularioValores.errors]);

	return (
		<Row>
			<Col lg="12">
				<div className="d-flex align-items-start">
					<div className="nav flex-column nav-pills me-3">
						<button
							className={`nav-link ${pestanaActiva === PestanaTipo.GENERAL ? ' active' : ''} ${erroresPestanaGeneral ? 'link-danger' : ''}`}
							id="pestanaGeneral"
							onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.GENERAL}`)}
							type="button"
						>
							{texto('General')}
						</button>
						<button
							className={`nav-link ${pestanaActiva === PestanaTipo.AWS ? ' active' : ''} ${erroresPestanaAws ? 'link-danger' : ''}`}
							id="pestanaAWS"
							onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.AWS}`)}
							type="button"
						>
							{texto('AWS')}
						</button>
						<button
							className={`nav-link ${pestanaActiva === PestanaTipo.DATOS ? ' active' : ''} ${erroresPestanaDatos ? 'link-danger' : ''}`}
							id="pestanaDatos"
							onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.DATOS}`)}
							type="button"
						>
							{texto('Datos')}
						</button>
					</div>
					<div className="tab-content sistema-formulario">
						<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.GENERAL ? ' show active' : ''}`} id="contenidoGeneral">
							<SistemaFormularioGeneral />
						</div>
						<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.AWS ? ' show active' : ''}`} id="contenidoAWS">
							<SistemaFormularioAws />
						</div>
						<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.DATOS ? ' show active' : ''}`} id="contenidoDatos">
							<SistemaFormularioDatos />
						</div>
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default SistemaFormulario;
