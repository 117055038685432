import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

interface RolFormularioPermisosRolesProps {
	/**
	 * Indica si el formulario será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Formulario para los permisos relacionados a los roles.
 */
const RolFormularioPermisosRoles = (props: RolFormularioPermisosRolesProps) => {
	const { soloLectura = false } = props;

	return (
		<Row>
			<Col lg="12">
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesVerLista}
					etiqueta={texto('Ver la lista de los roles.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesVerLista}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesVerDetalles}
					etiqueta={texto('Ver los detalles de los roles.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesVerDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesEditarDetalles}
					etiqueta={texto('Editar los detalles de los roles.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesEditarPermisosAplicacion}
					etiqueta={texto('Editar los permisos relacionados a la aplicación.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesEditarPermisosAplicacion}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesEditarPermisosAvaluos}
					etiqueta={texto('Editar los permisos relacionados a los avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesEditarPermisosAvaluos}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesEditarPermisosCatalogos}
					etiqueta={texto('Editar los permisos relacionados a los catálogos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesEditarPermisosCatalogos}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesEditarPermisosPeritos}
					etiqueta={texto('Editar los permisos relacionados a los peritos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesEditarPermisosPeritos}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesEditarPermisosRoles}
					etiqueta={texto('Editar los permisos relacionados a los roles.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesEditarPermisosRoles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesEditarPermisosUsuarios}
					etiqueta={texto('Editar los permisos relacionados a los usuarios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesEditarPermisosUsuarios}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesCrear}
					etiqueta={texto('Crear roles nuevos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesCrear}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesAsignar}
					etiqueta={texto('Asignar un rol a los usuarios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesAsignar}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionRolesEliminar}
					etiqueta={texto('Eliminar roles.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionRolesEliminar}
					soloLectura={soloLectura}
				/>
			</Col>
		</Row>
	);
};

export default RolFormularioPermisosRoles;
