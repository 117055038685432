import { tieneAlMenosUnPermiso, tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { setInterfazBarraNavegacionLateralMostrarAction } from 'store/actions';
import { StateType } from 'store';

interface MenuBotonProps {
	/**
	 * Etiqueta que se mostrará en el botón.
	 */
	etiqueta: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	icono?: string;
	/**
	 * Identificador único del botón.
	 */
	id: string;
	/**
	 * Permiso necesario para ver el componente. Si se recibe un arreglo de permisos, el componente será visible si el usuario tiene al menos un permiso.
	 */
	permiso?: string | Array<string>;
	/**
	 * Ruta a la que se redirigirá al presionar el botón.
	 */
	ruta: string;
}

/**
 * Botón del menú.
 */
const MenuBoton = (props: MenuBotonProps) => {
	const dispatch = useDispatch();
	const { interfaz, sesion } = useSelector((state: StateType) => state);
	const { barraNavegacionLateralMostrar } = interfaz;
	const { usuario } = sesion;
	const [botonOculto, setBotonOculto] = useState(true);
	const { etiqueta, icono, id, permiso, ruta } = props;

	useEffect(() => {
		if (usuario && permiso) {
			if (Array.isArray(permiso)) {
				setBotonOculto(!tieneAlMenosUnPermiso({ permisos: permiso, usuario }));
			} else {
				setBotonOculto(!tienePermiso({ permiso, usuario }));
			}
		} else {
			setBotonOculto(false);
		}
	}, [permiso, usuario]);

	/**
	 * Oculta la barra de navegación.
	 */
	const ocultarBarraNavegacion = () => {
		dispatch(setInterfazBarraNavegacionLateralMostrarAction(!barraNavegacionLateralMostrar));
	};

	return (
		<Link className="dropdown-item" data-oculto={botonOculto} hidden={botonOculto} id={id} onClick={ocultarBarraNavegacion} to={ruta}>
			<i className={icono}></i> {etiqueta}
		</Link>
	);
};

export default MenuBoton;
