import './PreferenciasFormulario.scss';
import { Col, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { pestanaConErrores } from '@mcsoft/formulario';
import PreferenciasFormularioAvaluos from './PreferenciasFormularioAvaluos';
import PreferenciasFormularioInterfaz from './PreferenciasFormularioInterfaz';
import { texto } from 'idiomas';
import { useFormState } from 'react-final-form';

const PestanaTipo = {
	AVALUOS: 'avaluos',
	INTERFAZ: 'interfaz'
};

/**
 * Formulario para las preferencias del usuario.
 */
const PreferenciasFormulario = () => {
	const history = useHistory();
	const location = useLocation();
	const formularioValores = useFormState();
	const [pestanaActiva, setPestanaActiva] = useState(PestanaTipo.AVALUOS);
	const [erroresPestanaAvaluos, setErroresPestanaAvaluos] = useState(false);
	const [erroresPestanaInterfaz, setErroresPestanaInterfaz] = useState(false);

	useEffect(() => {
		const pestana = new URLSearchParams(location.search).get('pestana');
		setPestanaActiva(pestana ? pestana : PestanaTipo.AVALUOS);
	}, [location]);

	useEffect(() => {
		setErroresPestanaAvaluos(
			pestanaConErrores({
				camposPestana: ['clonarFotos', 'clonarDetallesTerrenos', 'clonarDetallesConstrucciones', 'clonarDetallesInstalacionesEspeciales'],
				errores: formularioValores.errors
			})
		);
		setErroresPestanaInterfaz(
			pestanaConErrores({
				camposPestana: [],
				errores: formularioValores.errors
			})
		);
	}, [formularioValores.errors]);

	return (
		<Row>
			<Col lg="12">
				<div className="d-flex align-items-start">
					<div className="nav flex-column nav-pills me-3">
						<button
							className={`nav-link ${pestanaActiva === PestanaTipo.AVALUOS ? ' active' : ''} ${erroresPestanaAvaluos ? 'link-danger' : ''}`}
							id="pestanaAvaluos"
							onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.AVALUOS}`)}
							type="button"
						>
							{texto('Avalúos')}
						</button>
						<button
							className={`nav-link ${pestanaActiva === PestanaTipo.INTERFAZ ? ' active' : ''} ${erroresPestanaInterfaz ? 'link-danger' : ''}`}
							id="pestanaInterfaz"
							onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.INTERFAZ}`)}
							type="button"
						>
							{texto('Interfaz')}
						</button>
					</div>
					<div className="tab-content preferencias-formulario">
						<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.AVALUOS ? ' show active' : ''}`} id="contenidoAvaluos">
							<PreferenciasFormularioAvaluos />
						</div>
						<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.INTERFAZ ? ' show active' : ''}`} id="contenidoInterfaz">
							<PreferenciasFormularioInterfaz />
						</div>
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default PreferenciasFormulario;
