import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { Fragment } from 'react';
import { McCampoTexto } from '@mcsoft/formulario';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';
import { validarCorreoElectronico } from '@mcsoft/validaciones';
import { validarUsuarioDisponibilidad } from 'servicios/api/usuarios';

interface UsuarioFormularioContactoProps {
	/**
	 * Indica si el correo electrónico será editable o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	correoElectronicoEditable?: boolean;
	/**
	 * Indica si los detalles serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	detallesEditables?: boolean;
	/**
	 * Indica si los detalles serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	usuario?: boolean;
}

/**
 * Formulario para los datos de contacto de un usuario.
 */
const UsuarioFormularioContacto = (props: UsuarioFormularioContactoProps) => {
	const { datosUsuarioFacebook, datosUsuarioInstagram, datosUsuarioLinkedIn, datosUsuarioTwitter, datosUsuarioYouTube } = useSelector(
		(state: StateType) => state.configuracionAplicacion
	);
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { correoElectronicoEditable = false, detallesEditables = false } = props;

	/**
	 * Válida el correo electrónico.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioCorreoElectronico = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const valido = validarCorreoElectronico(valor);
		if (!valido) {
			return 'El correo electrónico es inválido.';
		}
		const disponible = await validarUsuarioDisponibilidad({ auth0AccessToken, campo: 'correoElectronico', id: valores.id, valor });
		if (!disponible) {
			return 'Ya existe un usuario con ese correo electrónico.';
		}
	};

	/**
	 * Válida el correo electrónico de confirmación.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioCorreoElectronicoConfirmacion = async ({ valor, valores }: { valor: string; valores?: any }) => {
		if (valor !== valores.correoElectronico) {
			return 'El correo electrónico de confirmación es diferente.';
		}
	};

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<div className="alert alert-warning" role="alert">
						<i className="fa-solid fa-triangle-exclamation"></i>{' '}
						{texto(
							'Debido a que tu correo es utilizado para iniciar sesión, si lo modificas la próxima vez que inicies sesión tendrás que utilizar el nuevo correo para ingresar.'
						)}
					</div>
				</Col>
				<Col lg="6">
					<McCampoTexto
						campo="correoElectronico"
						etiqueta={texto('Correo electrónico')}
						funcionValidacionAsincrona={validarFormularioCorreoElectronico}
						iconoIzquierda={constantes.icono.correoElectronico}
						id="correoElectronico"
						longitudMaxima={100}
						obligatorio
						soloLectura={!correoElectronicoEditable}
					/>
				</Col>
				<Col lg="6">
					<McCampoTexto
						campo="correoElectronicoConfirmacion"
						etiqueta={texto('Confirma tu Correo electrónico')}
						funcionValidacionAsincrona={validarFormularioCorreoElectronicoConfirmacion}
						iconoIzquierda={constantes.icono.correoElectronicoConfirmacion}
						id="correoElectronicoConfirmacion"
						longitudMaxima={100}
						obligatorio
						soloLectura={!correoElectronicoEditable}
					/>
				</Col>
				<Col lg="6">
					<McCampoTexto
						campo="telefono"
						etiqueta={texto('Teléfono')}
						iconoIzquierda={constantes.icono.telefono}
						id="telefono"
						longitudMaxima={10}
						longitudMinima={10}
						longitudNoMostrar
						soloLectura={!detallesEditables}
						tipo="telefono"
					/>
				</Col>
				{datosUsuarioFacebook && (
					<Col lg="12">
						<McCampoTexto
							campo="urlFacebook"
							etiqueta={texto('Facebook')}
							iconoIzquierda={constantes.icono.facebook}
							id="urlFacebook"
							longitudMaxima={1000}
							soloLectura={!detallesEditables}
						/>
					</Col>
				)}
				{datosUsuarioInstagram && (
					<Col lg="12">
						<McCampoTexto
							campo="urlInstagram"
							etiqueta={texto('Instagram')}
							iconoIzquierda={constantes.icono.instagram}
							id="urlInstagram"
							longitudMaxima={1000}
							soloLectura={!detallesEditables}
						/>
					</Col>
				)}
				{datosUsuarioLinkedIn && (
					<Col lg="12">
						<McCampoTexto
							campo="urlLinkedIn"
							etiqueta={texto('LinkedIn')}
							iconoIzquierda={constantes.icono.linkedIn}
							id="urlLinkedIn"
							longitudMaxima={1000}
							soloLectura={!detallesEditables}
						/>
					</Col>
				)}
				{datosUsuarioTwitter && (
					<Col lg="12">
						<McCampoTexto
							campo="urlTwitter"
							etiqueta={texto('Twitter')}
							iconoIzquierda={constantes.icono.twitter}
							id="urlTwitter"
							longitudMaxima={1000}
							soloLectura={!detallesEditables}
						/>
					</Col>
				)}
				{datosUsuarioYouTube && (
					<Col lg="12">
						<McCampoTexto
							campo="urlYouTube"
							etiqueta={texto('YouTube')}
							iconoIzquierda={constantes.icono.youTube}
							id="urlYouTube"
							longitudMaxima={1000}
							soloLectura={!detallesEditables}
						/>
					</Col>
				)}
			</Row>
		</Fragment>
	);
};

export default UsuarioFormularioContacto;
