import mcLogger from '@mcsoft/logger';

/**
 * Guarda el valor de un campo en el localStorage y realiza el log.
 * - ***campo*** - Campo en el que se guardara el valor.
 * - ***nombreClase*** - Nombre del archivo desde donde se invoca la función.
 * - ***nombreMetodo*** - Nombre del método que invoca la función.
 * - ***valor*** - Valor a guardar.
 */
export const guardarLocalStorage = ({ campo, nombreClase, nombreMetodo, valor }: { campo: string; nombreClase: string; nombreMetodo: string; valor: string }): void => {
	mcLogger.localStorage({
		mensaje: `Seteando "${campo}":`,
		nombreArchivo: nombreClase,
		nombreMetodo,
		objetoExtra: valor
	});
	localStorage.setItem(campo, valor);
};

/**
 * Guarda el valor de un campo en el sessionStorage y realiza el log.
 * - ***campo*** - Campo en el que se guardara el valor.
 * - ***nombreClase*** - Nombre del archivo desde donde se invoca la función.
 * - ***nombreMetodo*** - Nombre del método que invoca la función.
 * - ***valor*** - Valor a guardar.
 */
export const guardarSessionStorage = ({ campo, nombreClase, nombreMetodo, valor }: { campo: string; nombreClase: string; nombreMetodo: string; valor: string }): void => {
	mcLogger.sessionStorage({
		mensaje: `Seteando "${campo}":`,
		nombreArchivo: nombreClase,
		nombreMetodo,
		objetoExtra: valor
	});
	sessionStorage.setItem(campo, valor);
};

/**
 * Obtiene el valor de un campo en el localStorage y realiza el log.
 * - ***campo*** - Campo a obtener.
 * - ***nombreClase*** - Nombre del archivo desde donde se invoca la función.
 * - ***nombreMetodo*** - Nombre del método que invoca la función.
 */
export const obtenerLocalStorage = ({ campo, nombreClase, nombreMetodo }: { campo: string; nombreClase: string; nombreMetodo: string }): string | null => {
	const valor = localStorage.getItem(campo);
	mcLogger.localStorage({
		mensaje: `Obteniendo "${campo}":`,
		nombreArchivo: nombreClase,
		nombreMetodo,
		objetoExtra: valor
	});
	return valor;
};

/**
 * Obtiene el valor de un campo en el sessionStorage y realiza el log.
 * - ***campo*** - Campo a obtener.
 * - ***nombreClase*** - Nombre del archivo desde donde se invoca la función.
 * - ***nombreMetodo*** - Nombre del método que invoca la función.
 */
export const obtenerSessionStorage = ({ campo, nombreClase, nombreMetodo }: { campo: string; nombreClase: string; nombreMetodo: string }): string | null => {
	const valor = sessionStorage.getItem(campo);
	mcLogger.sessionStorage({
		mensaje: `Obteniendo "${campo}":`,
		nombreArchivo: nombreClase,
		nombreMetodo,
		objetoExtra: valor
	});
	return valor;
};
