import { Col, Row } from 'reactstrap';
import { formatearListaOpcionesMcCampoSelector, obtenerOpcionDeLaLista } from 'util/modelo/listasOpciones';
import { Fragment, useState } from 'react';
import { McCampoSelector, McCampoTexto, McCheckbox, obtenerValorMcCheckbox } from '@mcsoft/formulario';
import { useForm, useFormState } from 'react-final-form';
import Avaluo from 'modelo/Avaluo';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import ListaOpcion from 'modelo/ListaOpcion';
import { ordenarArregloCadenasAlfabeticamente } from '@mcsoft/arreglos';
import { texto } from 'idiomas';

interface AvaluoFormularioCaracteristicasProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
	listaClasificacionZona: Array<ListaOpcion> | undefined;
	listaConstruccionDominante: Array<ListaOpcion> | undefined;
	listaServicioMunicipal: Array<ListaOpcion> | undefined;
}

/**
 * Formulario para la configuración general del sistema.
 */
const AvaluoFormularioCaracteristicas = (props: AvaluoFormularioCaracteristicasProps) => {
	const formulario = useForm();
	const formularioValores = useFormState();
	const { eventoDeshacer, eventoGuardar, eventoSalir, hayCambiosSinGuardar, listaClasificacionZona, listaConstruccionDominante, listaServicioMunicipal } = props;
	const [tiposConstruccionesDominantesEditable, setTiposConstruccionesDominantesEditable] = useState<boolean>(false);
	const [serviciosMunicipalesEditable, setServiciosMunicipalesEditable] = useState<boolean>(false);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonSalir" onClick={eventoSalir} type="button">
				<i className={constantes.icono.atras}></i> {texto('Salir')}
			</button>
			<button className="btn btn-warning" disabled={!hayCambiosSinGuardar} id="botonDeshacer" onClick={eventoDeshacer} type="button">
				<i className={constantes.icono.deshacer}></i> {texto('Deshacer')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardar} id="botonGuardar" onClick={eventoGuardarFormulario} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardar}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	/**
	 * Evento que se ejecuta cuando cambia algun campo del las construcciones dominantes.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioConstruccionDominante = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const { construccionesDominantes = {} } = formularioValores.values;
		const { detalles } = construccionesDominantes;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaConstruccionDominante, campo)?.nombre);
		}
		for (const propiedad in detalles) {
			if (campo !== propiedad && detalles[propiedad]) {
				arreglo.push(obtenerOpcionDeLaLista(listaConstruccionDominante, propiedad)?.nombre);
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('construccionesDominantes.descripcion', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia algun campo del los servicios municipales.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioServiciosMunicipales = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const { serviciosMunicipales = {} } = formularioValores.values;
		const { detalles } = serviciosMunicipales;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaServicioMunicipal, campo)?.nombre);
		}
		for (const propiedad in detalles) {
			if (campo !== propiedad && detalles[propiedad]) {
				arreglo.push(obtenerOpcionDeLaLista(listaServicioMunicipal, propiedad)?.nombre);
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('serviciosMunicipales.descripcion', arreglo.join(', '));
	};

	/**
	 * Habilita la edicion de la descripción de los servicios municipales.
	 */
	const eventoEditarServiciosMunicipales = () => {
		setServiciosMunicipalesEditable(!serviciosMunicipalesEditable);
	};

	/**
	 * Habilita la edicion de la descripción de las construcciones dominantes.
	 */
	const eventoEditarTiposConstruccionesDominantes = () => {
		setTiposConstruccionesDominantesEditable(!tiposConstruccionesDominantesEditable);
	};

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardarFormulario = () => {
		const avaluo = formularioValores.values as Avaluo;
		eventoGuardar(avaluo);
	};

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.caracteristicasUrbanas}></i> {texto('Características Urbanas')}
					</h3>
					<p>{texto('En esta sección del formulario se captura la información de las características urbanas del avalúo.')}</p>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoSelector
						campo="caracteristicas.clasificacionZonaId"
						etiqueta={texto('Clasificación de la zona')}
						id="caracteristicas.clasificacionZonaId"
						opciones={formatearListaOpcionesMcCampoSelector(listaClasificacionZona)}
					/>
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="caracteristicas.densidadConstruccion"
						etiqueta={texto('Densidad de construcción')}
						id="caracteristicas.densidadConstruccion"
						numeroMaximo={100}
						numeroMinimo={0}
						textoDerecha="%"
						tipo="numeroNumerico"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoTexto
						campo="caracteristicas.coeficienteUtilizacion"
						etiqueta={texto('Coeficiente de utilización')}
						id="caracteristicas.coeficienteUtilizacion"
						numeroMaximo={100}
						numeroMinimo={0}
						textoDerecha="%"
						textoIzquierda={texto('C.U.S.')}
						tipo="numeroNumerico"
					/>
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="caracteristicas.coeficienteOcupacion"
						etiqueta={texto('Coeficiente de ocupación')}
						id="caracteristicas.coeficienteOcupacion"
						numeroMaximo={100}
						numeroMinimo={0}
						textoDerecha="%"
						textoIzquierda={texto('C.O.S.')}
						tipo="numeroNumerico"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<hr />
					<h5>
						<i className={constantes.icono.construccionesDominantes}></i> {texto('Tipo de construcción dominante')}
					</h5>
				</Col>
			</Row>
			<Row>
				{listaConstruccionDominante?.map((opcion) => (
					<Col key={opcion.id} lg="4">
						<McCheckbox
							campo={`construccionesDominantes.detalles.${opcion.id}`}
							etiqueta={opcion.nombre}
							eventoCambio={(evento) => eventoCambioConstruccionDominante({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
							id={opcion.id}
						/>
					</Col>
				))}
			</Row>
			<Row>
				<Col lg="12">
					<span className="avaluos-formulario__botton-editar">
						<button className="btn btn-sm btn-primary" onClick={eventoEditarTiposConstruccionesDominantes} title={texto('Editar Manualmente')} type="button">
							<i className={constantes.icono.editar}></i>
						</button>
					</span>
					<McCampoTexto
						campo="construccionesDominantes.descripcion"
						id="construccionesDominantes.descripcion"
						longitudMaxima={200}
						obligatorio
						renglones={3}
						soloLectura={!tiposConstruccionesDominantesEditable}
						tipo="areaTexto"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<hr />
					<h5>
						<i className={constantes.icono.serviciosMunicipales}></i> {texto('Servicios municipales')}
					</h5>
				</Col>
			</Row>
			<Row>
				{listaServicioMunicipal?.map((opcion) => (
					<Col key={opcion.id} lg="4">
						<McCheckbox
							campo={`serviciosMunicipales.detalles.${opcion.id}`}
							etiqueta={opcion.nombre}
							eventoCambio={(evento) => eventoCambioServiciosMunicipales({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
							id={opcion.id}
						/>
					</Col>
				))}
			</Row>
			<Row>
				<Col lg="12">
					<span className="avaluos-formulario__botton-editar">
						<button className="btn btn-sm btn-primary" onClick={eventoEditarServiciosMunicipales} title={texto('Editar Manualmente')} type="button">
							<i className={constantes.icono.editar}></i>
						</button>
					</span>
					<McCampoTexto
						campo="serviciosMunicipales.descripcion"
						id="serviciosMunicipales.descripcion"
						longitudMaxima={300}
						obligatorio
						renglones={3}
						soloLectura={!serviciosMunicipalesEditable}
						tipo="areaTexto"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
		</Fragment>
	);
};

export default AvaluoFormularioCaracteristicas;
