import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import MCTablaPaginada, {
	McTablaPaginadaEncabezado,
	McTablaPaginadaPaginacion,
	tablaPaginadaEventoAgregar,
	tablaPaginadaEventoCambiarOrdenamiento,
	tablaPaginadaEventoCambiarPaginaActual,
	tablaPaginadaEventoCambiarParametrosPaginacion,
	tablaPaginadaEventoCambiarRegistrosPorPagina,
	tablaPaginadaEventoCancelar,
	tablaPaginadaEventoSeleccionarRegistro
} from '@mcsoft/tabla-paginada';
import { procesarError, RegistrosPaginados } from '@mcsoft/api';
import { setPantallaCargaMostrarAction, setParametrosPaginacionConstruccionesDominantesAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { obtenerListaOpcionesPaginadosPorTipo } from 'servicios/api/listaOpciones';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';

const NOMBRE_CLASE = 'paginas/app/catalogos/ConstruccionesDominantesLista';

/**
 * En esta página se muestra la lista de construcciones dominantes.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const urlCriterio = new URLSearchParams(location.search).get('criterio');
	const urlOrden = new URLSearchParams(location.search).get('orden');
	const urlOrdenamiento = new URLSearchParams(location.search).get('ordenamiento');
	const urlPagina = new URLSearchParams(location.search).get('pagina');
	const urlRegistrosPorPagina = new URLSearchParams(location.search).get('registrosPorPagina');
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.construccionesDominantes);
	const [registrosLista, setRegistrosLista] = useState<Array<any>>([]);
	const [registrosMostrandoFinal, setRegistrosMostrandoFinal] = useState<number>(0);
	const [registrosMostrandoInicial, setRegistrosMostrandoInicial] = useState<number>(0);
	const [registrosMostrandoPorPagina, setRegistrosMostrandoPorPagina] = useState<number>(20);
	const [registrosMostrandoPorPaginaOpciones] = useState<Array<string>>();
	const [registrosPaginaFinal, setRegistrosPaginaFinal] = useState<number>(1);
	const [registrosTotal, setRegistrosTotal] = useState<number>(0);
	const [tablaEncabezados, setTablaEncabezados] = useState<Array<McTablaPaginadaEncabezado>>([]);
	const PERMISO_EDITAR = constantes.permiso.appAdministracionCatalogosConstruccionesDominantesEditarDetalles;
	const RUTA_CREAR = constantes.ruta.appAdministracionCatalogosConstruccionesDominantesNuevo;
	const RUTA_EDITAR = constantes.ruta.appAdministracionCatalogosConstruccionesDominantesDetallesEditar;
	const RUTA_SALIR = constantes.ruta.appInicio;

	useEffect(() => {
		setTablaEncabezados([{ atributo: 'nombre', campo: 'nombre', ordenamiento: [['nombre']], titulo: texto('Nombre') }]);
	}, []);

	useEffect(() => {
		dispatch(
			setParametrosPaginacionConstruccionesDominantesAction({
				criterio: urlCriterio !== null ? urlCriterio : criterio,
				orden: urlOrden ? urlOrden : orden,
				ordenamiento: urlOrdenamiento ? urlOrdenamiento : ordenamiento,
				pagina: urlPagina ? Number(urlPagina) : pagina,
				registrosPorPagina: urlRegistrosPorPagina ? Number(urlRegistrosPorPagina) : registrosPorPagina
			})
		);
	}, [urlCriterio, urlOrden, urlOrdenamiento, urlPagina, urlRegistrosPorPagina]);

	useEffect(() => {
		const parametrosPaginacion: McTablaPaginadaPaginacion = {
			criterio: criterio,
			orden: orden,
			ordenamiento: ordenamiento,
			pagina: pagina,
			registrosPorPagina: registrosPorPagina
		};
		eventoObtenerRegistros(parametrosPaginacion);
	}, [criterio, orden, ordenamiento, pagina, registrosPorPagina]);

	/**
	 * Obtiene los registros.
	 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
	 */
	const eventoObtenerRegistros = async ({
		criterio,
		orden,
		ordenamiento,
		pagina,
		registrosPorPagina
	}: {
		criterio?: string;
		orden?: string;
		ordenamiento?: string;
		pagina?: number;
		registrosPorPagina?: number;
	}) => {
		const nombreMetodo = 'eventoObtenerRegistros';
		try {
			mcLogger.log({ mensaje: `Obteniendo lista de construcciones dominantes...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			const respuesta = await obtenerListaOpcionesPaginadosPorTipo({
				auth0AccessToken,
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina,
				tipo: 'construccionDominante'
			});
			mcLogger.log({ mensaje: `Lista de construcciones dominantes obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const registrosPaginados = respuesta.datos as RegistrosPaginados;
			setRegistrosLista(registrosPaginados.lista);
			setRegistrosPaginaFinal(registrosPaginados.paginaFinal);
			setRegistrosMostrandoFinal(registrosPaginados.registroFinal);
			setRegistrosMostrandoInicial(registrosPaginados.registroInicial);
			setRegistrosMostrandoPorPagina(registrosPaginados.registrosPorPagina);
			setRegistrosTotal(registrosPaginados.total);
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener la lista de construcciones dominantes: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	return (
		<Contenedor>
			<Breadcrumb
				enlaces={[{ ruta: RUTA_SALIR, titulo: texto('Inicio') }]}
				icono={constantes.icono.construccionesDominantes}
				titulo={texto('Lista de construcciones dominantes')}
			/>
			<Card>
				<CardHeader>
					<BarraHerramientas>
						{tienePermiso({ permiso: constantes.permiso.appAdministracionCatalogosConstruccionesDominantesCrear, usuario }) && (
							<button
								className="btn btn-success"
								id="botonAgregar"
								onClick={() => tablaPaginadaEventoAgregar({ history, nombreClase: NOMBRE_CLASE, rutaCreacion: RUTA_CREAR })}
								type="button"
							>
								<i className={constantes.icono.agregar}></i> {texto('Agregar')}
							</button>
						)}
						<button
							className="btn btn-danger"
							id="botonCancelar"
							onClick={() => tablaPaginadaEventoCancelar({ history, nombreClase: NOMBRE_CLASE, rutaSalir: RUTA_SALIR })}
							type="button"
						>
							<i className={constantes.icono.atras}></i> {texto('Salir')}
						</button>
					</BarraHerramientas>
				</CardHeader>
				<CardBody>
					<Row>
						<Col lg="12">
							<MCTablaPaginada
								eventoCambiarOrdenamiento={({ orden, ordenamiento }) =>
									tablaPaginadaEventoCambiarOrdenamiento({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, pagina, registrosPorPagina })
								}
								eventoCambiarPaginaActual={(pagina) =>
									tablaPaginadaEventoCambiarPaginaActual({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, pagina, registrosPorPagina })
								}
								eventoCambiarParametrosPaginacion={({ criterio, orden, ordenamiento, pagina, registrosPorPagina }) =>
									tablaPaginadaEventoCambiarParametrosPaginacion({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, pagina, registrosPorPagina })
								}
								eventoCambiarRegistrosPorPagina={(registrosPorPagina) =>
									tablaPaginadaEventoCambiarRegistrosPorPagina({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, registrosPorPagina })
								}
								eventoSeleccionarRegistro={({ registroId }) =>
									tablaPaginadaEventoSeleccionarRegistro({ history, nombreClase: NOMBRE_CLASE, permiso: PERMISO_EDITAR, registroId, ruta: RUTA_EDITAR, usuario })
								}
								registrosCriterio={criterio}
								registrosEncabezados={tablaEncabezados}
								registrosLista={registrosLista}
								registrosMostrandoFinal={registrosMostrandoFinal}
								registrosMostrandoInicial={registrosMostrandoInicial}
								registrosMostrandoPorPagina={registrosMostrandoPorPagina}
								registrosMostrandoPorPaginaOpciones={registrosMostrandoPorPaginaOpciones}
								registrosOrden={orden}
								registrosOrdenamiento={ordenamiento}
								registrosPaginaActual={pagina}
								registrosPaginaFinal={registrosPaginaFinal}
								registrosTotal={registrosTotal}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Contenedor>
	);
};

export default Pagina;
