import './Breadcrumb.scss';
import { BreadcrumbItem, Col, Row } from 'reactstrap';
import mcLogger from '@mcsoft/logger';
import { useHistory } from 'react-router';

const NOMBRE_CLASE = 'Breadcrumb';

export interface BreadcrumbEnlace {
	/**
	 * Ruta del enlace.
	 */
	ruta: string;
	/**
	 * Título del enlace.
	 */
	titulo: string;
}

interface BreadcrumbProps {
	/**
	 * Arreglo de enlaces para crear el breadcrumb.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	enlaces?: Array<BreadcrumbEnlace>;
	/**
	 * Icono *FontAwesome* que se mostrará junto al título.
	 *
	 * > ***Predeterminado:*** *undefined*.
	 */
	icono?: string;
	/**
	 * Título a mostrar en el encabezado.
	 */
	titulo: string;
}

/**
 * Muestra el título de la página actual y un breadcrumb (migajas de pan) de la ruta actual.
 */
const Breadcrumb = (props: BreadcrumbProps) => {
	const history = useHistory();
	const { enlaces, icono, titulo } = props;

	/**
	 * Redirecciona a la ruta especificada.
	 * - ***ruta*** - Ruta a la que se redireccionará.
	 */
	const eventoRedireccionar = (ruta: string): any => {
		const nombreMetodo = 'eventoRedireccionar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	};

	return (
		<Row>
			<Col className="col-12">
				<div className="page-title-box d-flex align-items-center justify-content-between">
					<h4 className="mb-0">
						<i className={icono}></i>&nbsp;{titulo}
					</h4>
					<div className="page-title-right">
						<ol className="breadcrumb m-0">
							{enlaces &&
								enlaces.map((enlace, indice) => (
									<BreadcrumbItem key={`enlace_${indice}`}>
										<span className="breadcrumb__enlace" onClick={() => eventoRedireccionar(enlace.ruta)}>
											{enlace.titulo}
										</span>
									</BreadcrumbItem>
								))}
							<BreadcrumbItem active>
								<b>{titulo}</b>
							</BreadcrumbItem>
						</ol>
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default Breadcrumb;
