import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del locale de la moneda.
 */
const InformacionMonedaLocale = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Utilizado para definir el formato de moneda.')}</p>
			<ul>
				<li>
					<span className="badge bg-info text-wrap">es-MX</span> {texto('Español de México')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">es-ES</span> {texto('Español de España')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">en-US</span> {texto('Inglés de Estados Unidos')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">en-GB</span> {texto('Inglés de Inglaterra')}{' '}
				</li>
			</ul>
		</div>
	</Fragment>
);

export default InformacionMonedaLocale;
