import aplicacion from 'configuracion/aplicacion';
import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del PDF del avalúo relacionado an número de página.
 */
const InformacionAvaluoPdfNumeroPagina = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Se mostrará en el PDF el número de página.')}</p>
		</div>
		<div className="text-center mt-2 mb-2">
			<img alt="Ejemplo" src={aplicacion.tutoriales.ejemploAvaluoPdfNumeroPagina} width="480px" />
		</div>
	</Fragment>
);

export default InformacionAvaluoPdfNumeroPagina;
