import { McCampoSelectorMultipleOpcion } from '@mcsoft/formulario';
import Perito from 'modelo/Perito';
import PeritoDomicilio from 'modelo/PeritoDomicilio';
import { texto } from 'idiomas';
import { tieneValor } from '@mcsoft/validaciones';

/**
 * Regresa el domicilio completo del perito.
 * - ***domicilio*** - Objeto con la información del perito.
 */
export const domicilioCompletoPerito = (domicilio: PeritoDomicilio): string => {
	let domicilioCompleto = '';
	if (domicilio) {
		const { calle, colonia, numeroExterior, numeroInterior } = domicilio;
		domicilioCompleto += calle ? calle : '';
		domicilioCompleto += numeroExterior ? ` No. ${numeroExterior}` : '';
		domicilioCompleto += numeroInterior ? ` Int. ${numeroInterior}` : '';
		domicilioCompleto += colonia ? ` Col. ${colonia}` : '';
	}
	return domicilioCompleto;
};

/**
 * Formatea una lista de peritos para utilizarse como lista de opciones en un McCampoSelectorMultiple.
 * - ***lista*** - Arreglo con la lista de peritos.
 */
export const formatearListaPeritosMcCampoSelectorMultiple = (lista?: Array<Perito>): Array<McCampoSelectorMultipleOpcion> => {
	const listaOpciones: Array<McCampoSelectorMultipleOpcion> = [];
	if (lista) {
		lista.forEach((elemento: Perito) => {
			listaOpciones.push({ nombre: nombreCompletoPeritoPorApellido(elemento), valor: elemento.id });
		});
	}
	return listaOpciones;
};

/**
 * Formatea un perito para ser mostrado en el formulario.
 * - ***perito*** - Objeto con la información del perito.
 */
export const formatearPeritoParaFormulario = (perito: Perito) => {
	const formulario: any = perito;
	if (perito && perito.domicilio) {
		if (perito.domicilio.pais) {
			formulario.domicilio.paisSeleccion = {
				label: perito.domicilio.pais.nombre,
				value: perito.domicilio.pais.id
			};
		}
		if (perito.domicilio.estado) {
			formulario.domicilio.estadoSeleccion = {
				label: perito.domicilio.estado.nombre,
				value: perito.domicilio.estado.id
			};
		}
		if (perito.domicilio.municipio) {
			formulario.domicilio.municipioSeleccion = {
				label: perito.domicilio.municipio.nombre,
				value: perito.domicilio.municipio.id
			};
		}
	} else {
		formulario.domicilio = {};
	}
	return formulario;
};

/**
 * Regresa el nombre completo del perito (Nombre(s), Apellido paterno, Apellido materno).
 * - ***perito*** - Objeto con la información del perito.
 */
export const nombreCompletoPerito = (perito: Perito): string => {
	let nombreCompleto = '';
	if (perito) {
		const { apellidoMaterno, apellidoPaterno, nombre } = perito;
		nombreCompleto += nombre ? `${nombre}` : '';
		nombreCompleto += apellidoPaterno ? ` ${apellidoPaterno}` : '';
		nombreCompleto += apellidoMaterno ? ` ${apellidoMaterno}` : '';
	}
	return nombreCompleto;
};

/**
 * Regresa el nombre completo del perito (Apellido paterno, Apellido materno, Nombre(s)).
 * - ***perito*** - Objeto con la información del perito.
 */
export const nombreCompletoPeritoPorApellido = (perito?: Perito): string => {
	let nombreCompleto = '';
	if (perito) {
		const { apellidoMaterno, apellidoPaterno, nombre } = perito;
		if (!tieneValor(apellidoMaterno) && !tieneValor(apellidoMaterno) && !tieneValor(apellidoMaterno)) {
			nombreCompleto = texto('Sin Perito');
		} else {
			nombreCompleto += apellidoPaterno ? `${apellidoPaterno}` : '';
			nombreCompleto += apellidoMaterno ? ` ${apellidoMaterno}` : '';
			nombreCompleto += nombre ? ` ${nombre}` : '';
		}
	}
	return nombreCompleto;
};
