import {
	SET_PANTALLA_CARGA,
	SET_PANTALLA_CARGA_MOSTRAR
} from './actionTypes';
import { McPantallaCargaProps } from '@mcsoft/pantalla-carga';
import { ReduxAccion } from 'store/actions';

/**
 * Configura las propiedades de la pantalla de carga.
 * - ***pantallaCarga*** - Objeto con las propiedades de la pantalla de carga.
 */
export const setPantallaCargaAction = (pantallaCarga: McPantallaCargaProps): ReduxAccion => ({
	payload: { pantallaCarga },
	type: SET_PANTALLA_CARGA
});

/**
 * Muestra/Oculta la pantalla de carga.
 * - ***mostrar*** - Indica si se mostrará la pantalla de carga.
 */
export const setPantallaCargaMostrarAction = (mostrar: boolean): ReduxAccion => ({
	payload: { mostrar },
	type: SET_PANTALLA_CARGA_MOSTRAR
});