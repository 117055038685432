import { Col, Modal, Row } from 'reactstrap';
import { crearListaOpcion, validarListaOpcionesDisponibilidad } from 'servicios/api/listaOpciones';
import { Form, useForm, useFormState } from 'react-final-form';
import { formatearListaOpcionTipoTexto, obtenerOpcionDeLaLista } from 'util/modelo/listasOpciones';
import { Fragment, MouseEventHandler, useState } from 'react';
import { McCampoTexto, McCheckbox, obtenerValorMcCheckbox } from '@mcsoft/formulario';
import Avaluo from 'modelo/Avaluo';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import ListaOpcion from 'modelo/ListaOpcion';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { ordenarArregloCadenasAlfabeticamente } from '@mcsoft/arreglos';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { tieneValor } from '@mcsoft/validaciones';
import { useDispatch } from 'react-redux';
import Usuario from 'modelo/Usuario';
import { v4 as uuidv4 } from 'uuid';
import { ValidationErrors } from 'final-form';

const NOMBRE_CLASE = 'paginas/app/componentes/avaluos/AvaluoFormularioElementosConstruccion';

interface AvaluoFormularioElementosConstruccionProps {
	auth0AccessToken: string;
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoRefrescarListas: () => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
	listaElementosConstruccionAplanado: Array<ListaOpcion> | undefined;
	listaElementosConstruccionAzotea: Array<ListaOpcion> | undefined;
	listaElementosConstruccionBarda: Array<ListaOpcion> | undefined;
	listaElementosConstruccionCarpinteria: Array<ListaOpcion> | undefined;
	listaElementosConstruccionCerrajeria: Array<ListaOpcion> | undefined;
	listaElementosConstruccionCimientos: Array<ListaOpcion> | undefined;
	listaElementosConstruccionEntrepiso: Array<ListaOpcion> | undefined;
	listaElementosConstruccionEscalera: Array<ListaOpcion> | undefined;
	listaElementosConstruccionEstructura: Array<ListaOpcion> | undefined;
	listaElementosConstruccionFachada: Array<ListaOpcion> | undefined;
	listaElementosConstruccionHerreria: Array<ListaOpcion> | undefined;
	listaElementosConstruccionInstalacionElectrica: Array<ListaOpcion> | undefined;
	listaElementosConstruccionInstalacionEspecial: Array<ListaOpcion> | undefined;
	listaElementosConstruccionInstalacionSanitaria: Array<ListaOpcion> | undefined;
	listaElementosConstruccionLambrin: Array<ListaOpcion> | undefined;
	listaElementosConstruccionMuebleBano: Array<ListaOpcion> | undefined;
	listaElementosConstruccionMuebleCocina: Array<ListaOpcion> | undefined;
	listaElementosConstruccionMuro: Array<ListaOpcion> | undefined;
	listaElementosConstruccionPavimentoPetreo: Array<ListaOpcion> | undefined;
	listaElementosConstruccionPintura: Array<ListaOpcion> | undefined;
	listaElementosConstruccionPlafon: Array<ListaOpcion> | undefined;
	listaElementosConstruccionRecubrimientoEspecial: Array<ListaOpcion> | undefined;
	listaElementosConstruccionTecho: Array<ListaOpcion> | undefined;
	listaElementosConstruccionVidrieria: Array<ListaOpcion> | undefined;
	listaElementosConstruccionZoclo: Array<ListaOpcion> | undefined;
	usuario: Usuario;
}

/**
 * Formulario para la sección elementos de construcción del avalúo.
 */
const AvaluoFormularioElementosConstruccion = (props: AvaluoFormularioElementosConstruccionProps) => {
	const formulario = useForm();
	const formularioValores = useFormState();
	const dispatch = useDispatch();
	const {
		auth0AccessToken,
		eventoDeshacer,
		eventoGuardar,
		eventoRefrescarListas,
		eventoSalir,
		hayCambiosSinGuardar,
		listaElementosConstruccionAplanado,
		listaElementosConstruccionAzotea,
		listaElementosConstruccionBarda,
		listaElementosConstruccionCarpinteria,
		listaElementosConstruccionCerrajeria,
		listaElementosConstruccionCimientos,
		listaElementosConstruccionEntrepiso,
		listaElementosConstruccionEscalera,
		listaElementosConstruccionEstructura,
		listaElementosConstruccionFachada,
		listaElementosConstruccionHerreria,
		listaElementosConstruccionInstalacionElectrica,
		listaElementosConstruccionInstalacionEspecial,
		listaElementosConstruccionInstalacionSanitaria,
		listaElementosConstruccionLambrin,
		listaElementosConstruccionMuebleBano,
		listaElementosConstruccionMuebleCocina,
		listaElementosConstruccionMuro,
		listaElementosConstruccionPavimentoPetreo,
		listaElementosConstruccionPintura,
		listaElementosConstruccionPlafon,
		listaElementosConstruccionRecubrimientoEspecial,
		listaElementosConstruccionTecho,
		listaElementosConstruccionVidrieria,
		listaElementosConstruccionZoclo,
		usuario
	} = props;
	const { elementosConstruccion } = formularioValores.values;
	const [elementosConstruccionAplanadoEditable, setElementosConstruccionAplanadoEditable] = useState<boolean>(false);
	const [elementosConstruccionAzoteaEditable, setElementosConstruccionAzoteaEditable] = useState<boolean>(false);
	const [elementosConstruccionBardaEditable, setElementosConstruccionBardaEditable] = useState<boolean>(false);
	const [elementosConstruccionCarpinteriaEditable, setElementosConstruccionCarpinteriaEditable] = useState<boolean>(false);
	const [elementosConstruccionCerrajeriaEditable, setElementosConstruccionCerrajeriaEditable] = useState<boolean>(false);
	const [elementosConstruccionCimientosEditable, setElementosConstruccionCimientosEditable] = useState<boolean>(false);
	const [elementosConstruccionEntrepisoEditable, setElementosConstruccionEntrepisoEditable] = useState<boolean>(false);
	const [elementosConstruccionEscaleraEditable, setElementosConstruccionEscaleraEditable] = useState<boolean>(false);
	const [elementosConstruccionEstructuraEditable, setElementosConstruccionEstructuraEditable] = useState<boolean>(false);
	const [elementosConstruccionFachadaEditable, setElementosConstruccionFachadaEditable] = useState<boolean>(false);
	const [elementosConstruccionHerreriaEditable, setElementosConstruccionHerreriaEditable] = useState<boolean>(false);
	const [elementosConstruccionInstalacionElectricaEditable, setElementosConstruccionInstalacionElectricaEditable] = useState<boolean>(false);
	const [elementosConstruccionInstalacionEspecialEditable, setElementosConstruccionInstalacionEspecialEditable] = useState<boolean>(false);
	const [elementosConstruccionInstalacionSanitariaEditable, setElementosConstruccionInstalacionSanitariaEditable] = useState<boolean>(false);
	const [elementosConstruccionLambrinEditable, setElementosConstruccionLambrinEditable] = useState<boolean>(false);
	const [elementosConstruccionMuebleBanoEditable, setElementosConstruccionMuebleBanoEditable] = useState<boolean>(false);
	const [elementosConstruccionMuebleCocinaEditable, setElementosConstruccionMuebleCocinaEditable] = useState<boolean>(false);
	const [elementosConstruccionMuroEditable, setElementosConstruccionMuroEditable] = useState<boolean>(false);
	const [elementosConstruccionPavimentoPetreoEditable, setElementosConstruccionPavimentoPetreoEditable] = useState<boolean>(false);
	const [elementosConstruccionPinturaEditable, setElementosConstruccionPinturaEditable] = useState<boolean>(false);
	const [elementosConstruccionPlafonEditable, setElementosConstruccionPlafonEditable] = useState<boolean>(false);
	const [elementosConstruccionRecubrimientoEspecialEditable, setElementosConstruccionRecubrimientoEspecialEditable] = useState<boolean>(false);
	const [elementosConstruccionTechoEditable, setElementosConstruccionTechoEditable] = useState<boolean>(false);
	const [elementosConstruccionVidrieriaEditable, setElementosConstruccionVidrieriaEditable] = useState<boolean>(false);
	const [elementosConstruccionZocloEditable, setElementosConstruccionZocloEditable] = useState<boolean>(false);
	const [modalTipo, setModalTipo] = useState<string>('');
	const [modalAgregar, setModalAgregar] = useState<boolean>(false);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonSalir" onClick={eventoSalir} type="button">
				<i className={constantes.icono.atras}></i> {texto('Salir')}
			</button>
			<button className="btn btn-warning" disabled={!hayCambiosSinGuardar} id="botonDeshacer" onClick={eventoDeshacer} type="button">
				<i className={constantes.icono.deshacer}></i> {texto('Deshacer')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardar} id="botonGuardar" onClick={eventoGuardarFormulario} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardar}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	/**
	 * Dibuja el botón para agregar el elemento de construcción.
	 * - ***tipo*** - Tipo de elemento de construcción a agregar.
	 */
	const dibujarBotonAgregarElementoConstruccion = (tipo: string) => {
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionCatalogosElementosConstruccionCrear, usuario })) {
			return (
				<Fragment>
					<button
						className="btn btn-success btn-sm ms-2"
						id={`botonAgregar${tipo}`}
						onClick={() => {
							setModalAgregar(true);
							setModalTipo(tipo);
						}}
						title={texto(`Agregar ${formatearListaOpcionTipoTexto(tipo)}`)}
						type="button"
					>
						<i className={constantes.icono.agregar}></i>
					</button>
				</Fragment>
			);
		}
	};

	/**
	 * Dibuja el botón para editar la descripción del elemento de construcción.
	 * - ***tipo*** - Tipo de elemento de construcción a agregar.
	 */
	const dibujarBotonEditarDescripcion = (evento: MouseEventHandler<HTMLButtonElement>) => (
		<Fragment>
			<span className="avaluos-formulario__botton-editar">
				<button className="btn btn-sm btn-primary" onClick={evento} title={texto('Editar Manualmente')} type="button">
					<i className={constantes.icono.editar}></i>
				</button>
			</span>
		</Fragment>
	);

	/**
	 * Dibuja el modal de eliminación.
	 */
	const dibujarModalAgregar = () => (
		<Modal
			centered={true}
			id={`modalAgregar${modalTipo}`}
			isOpen={modalAgregar}
			toggle={() => {
				setModalAgregar(modalAgregar);
			}}
		>
			<Form
				initialValues={{
					id: uuidv4(),
					tipo: modalTipo
				}}
				onSubmit={eventoAgregar}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<div className="modal-header border-success bg-success">
							<h5 className="modal-title mt-0 text-white">{texto(`Agregar ${formatearListaOpcionTipoTexto(modalTipo)}`)}</h5>
						</div>
						<div className="modal-body border-success">
							<Row>
								<Col md="12">
									<McCampoTexto campo="nombre" etiqueta={texto('Nombre')} funcionValidacionAsincrona={validarFormularioNombre} id="campoNombre" longitudMaxima={100} />
								</Col>
							</Row>
						</div>
						<BarraHerramientas>
							<button
								className="btn btn-secondary"
								id="botonCancelar"
								onClick={() => {
									setModalAgregar(false);
								}}
								type="button"
							>
								<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
							</button>
							<button className="btn btn-success" id="botonAgregar" type="submit">
								<i className={constantes.icono.agregar}></i> {texto('Agregar')}
							</button>
						</BarraHerramientas>
					</form>
				)}
				validate={eventoValidarFormulario}
				validateOnBlur
			/>
		</Modal>
	);

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo aplanado.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionAplanado = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionAplanado, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { aplanado } = elementosConstruccion.detalles;
			for (const propiedad in aplanado) {
				if (campo !== propiedad && aplanado[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionAplanado, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.aplanado', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo azotea.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionAzotea = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionAzotea, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { azotea } = elementosConstruccion.detalles;
			for (const propiedad in azotea) {
				if (campo !== propiedad && azotea[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionAzotea, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.azotea', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo barda.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionBarda = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionBarda, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { barda } = elementosConstruccion.detalles;
			for (const propiedad in barda) {
				if (campo !== propiedad && barda[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionBarda, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.barda', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo carpintería.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionCarpinteria = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionCarpinteria, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { carpinteria } = elementosConstruccion.detalles;
			for (const propiedad in carpinteria) {
				if (campo !== propiedad && carpinteria[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionCarpinteria, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.carpinteria', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo cerrajería.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionCerrajeria = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionCerrajeria, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { cerrajeria } = elementosConstruccion.detalles;
			for (const propiedad in cerrajeria) {
				if (campo !== propiedad && cerrajeria[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionCerrajeria, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.cerrajeria', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo cimientos.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionCimientos = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionCimientos, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { cimientos } = elementosConstruccion.detalles;
			for (const propiedad in cimientos) {
				if (campo !== propiedad && cimientos[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionCimientos, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.cimientos', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo entrepiso.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionEntrepiso = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionEntrepiso, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { entrepiso } = elementosConstruccion.detalles;
			for (const propiedad in entrepiso) {
				if (campo !== propiedad && entrepiso[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionEntrepiso, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.entrepiso', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo escalera.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionEscalera = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionEscalera, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { escalera } = elementosConstruccion.detalles;
			for (const propiedad in escalera) {
				if (campo !== propiedad && escalera[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionEscalera, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.escalera', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo estructura.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionEstructura = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionEstructura, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { estructura } = elementosConstruccion.detalles;
			for (const propiedad in estructura) {
				if (campo !== propiedad && estructura[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionEstructura, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.estructura', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo fachada.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionFachada = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionFachada, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { fachada } = elementosConstruccion.detalles;
			for (const propiedad in fachada) {
				if (campo !== propiedad && fachada[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionFachada, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.fachada', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo herrería.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionHerreria = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionHerreria, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { herreria } = elementosConstruccion.detalles;
			for (const propiedad in herreria) {
				if (campo !== propiedad && herreria[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionHerreria, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.herreria', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo instalació eléctrica.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionInstalacionElectrica = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionInstalacionElectrica, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { instalacionElectrica } = elementosConstruccion.detalles;
			for (const propiedad in instalacionElectrica) {
				if (campo !== propiedad && instalacionElectrica[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionInstalacionElectrica, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.instalacionElectrica', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo instalación especial.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionInstalacionEspecial = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionInstalacionEspecial, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { instalacionEspecial } = elementosConstruccion.detalles;
			for (const propiedad in instalacionEspecial) {
				if (campo !== propiedad && instalacionEspecial[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionInstalacionEspecial, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.instalacionEspecial', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo instalación sanitaria.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionInstalacionSanitaria = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionInstalacionSanitaria, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { instalacionSanitaria } = elementosConstruccion.detalles;
			for (const propiedad in instalacionSanitaria) {
				if (campo !== propiedad && instalacionSanitaria[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionInstalacionSanitaria, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.instalacionSanitaria', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo lambrin.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionLambrin = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionLambrin, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { lambrin } = elementosConstruccion.detalles;
			for (const propiedad in lambrin) {
				if (campo !== propiedad && lambrin[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionLambrin, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.lambrin', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo mueble de baño.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionMuebleBano = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionMuebleBano, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { muebleBano } = elementosConstruccion.detalles;
			for (const propiedad in muebleBano) {
				if (campo !== propiedad && muebleBano[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionMuebleBano, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.muebleBano', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo mueble de cocina.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionMuebleCocina = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionMuebleCocina, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { muebleCocina } = elementosConstruccion.detalles;
			for (const propiedad in muebleCocina) {
				if (campo !== propiedad && muebleCocina[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionMuebleCocina, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.muebleCocina', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo muro.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionMuro = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionMuro, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { muro } = elementosConstruccion.detalles;
			for (const propiedad in muro) {
				if (campo !== propiedad && muro[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionMuro, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.muro', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo pavimento petreo.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionPavimentoPetreo = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionPavimentoPetreo, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { pavimentoPetreo } = elementosConstruccion.detalles;
			for (const propiedad in pavimentoPetreo) {
				if (campo !== propiedad && pavimentoPetreo[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionPavimentoPetreo, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.pavimentoPetreo', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo pintura.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionPintura = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionPintura, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { pintura } = elementosConstruccion.detalles;
			for (const propiedad in pintura) {
				if (campo !== propiedad && pintura[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionPintura, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.pintura', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo plafón.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionPlafon = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionPlafon, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { plafon } = elementosConstruccion.detalles;
			for (const propiedad in plafon) {
				if (campo !== propiedad && plafon[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionPlafon, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.plafon', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo recubrimiento especial.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionRecubrimientoEspecial = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionRecubrimientoEspecial, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { recubrimientoEspecial } = elementosConstruccion.detalles;
			for (const propiedad in recubrimientoEspecial) {
				if (campo !== propiedad && recubrimientoEspecial[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionRecubrimientoEspecial, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.recubrimientoEspecial', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo techo.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionTecho = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionTecho, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { techo } = elementosConstruccion.detalles;
			for (const propiedad in techo) {
				if (campo !== propiedad && techo[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionTecho, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.techo', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo vidriería.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionVidrieria = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionVidrieria, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { vidrieria } = elementosConstruccion.detalles;
			for (const propiedad in vidrieria) {
				if (campo !== propiedad && vidrieria[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionVidrieria, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.vidrieria', arreglo.join(', '));
	};

	/**
	 * Evento que se ejecuta cuando cambia un elemento de construcción del tipo zoclo.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioElementosConstruccionZoclo = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const arreglo: Array<string> = [];
		if (valor) {
			arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionZoclo, campo)?.nombre);
		}
		if (elementosConstruccion) {
			const { zoclo } = elementosConstruccion.detalles;
			for (const propiedad in zoclo) {
				if (campo !== propiedad && zoclo[propiedad]) {
					arreglo.push(obtenerOpcionDeLaLista(listaElementosConstruccionZoclo, propiedad)?.nombre);
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('elementosConstruccion.zoclo', arreglo.join(', '));
	};

	/**
	 * Crea un nuevo elemento de construcción.
	 */
	const eventoAgregar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoAgregar';
		try {
			mcLogger.log({ mensaje: `Guardando la información del elemento de construcción...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const registro = valores as ListaOpcion;
			dispatch(setPantallaCargaMostrarAction(true));
			await crearListaOpcion({ auth0AccessToken, valores: registro });
			mcLogger.log({ mensaje: `Elemento de construcción creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			mcNotificaciones.exito({ mensaje: texto(`Elemento de construcción creado con éxito.`) });
			setModalAgregar(false);
			eventoRefrescarListas();
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la información del elemento de construcción:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Habilita la edición manual del campo aplanado.
	 */
	const eventoEditarElementosConstruccionAplanado = () => {
		setElementosConstruccionAplanadoEditable(!elementosConstruccionAplanadoEditable);
	};

	/**
	 * Habilita la edición manual del campo azotea.
	 */
	const eventoEditarElementosConstruccionAzotea = () => {
		setElementosConstruccionAzoteaEditable(!elementosConstruccionAzoteaEditable);
	};

	/**
	 * Habilita la edición manual del campo barda.
	 */
	const eventoEditarElementosConstruccionBarda = () => {
		setElementosConstruccionBardaEditable(!elementosConstruccionBardaEditable);
	};

	/**
	 * Habilita la edición manual del campo carpintería.
	 */
	const eventoEditarElementosConstruccionCarpinteria = () => {
		setElementosConstruccionCarpinteriaEditable(!elementosConstruccionCarpinteriaEditable);
	};

	/**
	 * Habilita la edición manual del campo cerrajería.
	 */
	const eventoEditarElementosConstruccionCerrajeria = () => {
		setElementosConstruccionCerrajeriaEditable(!elementosConstruccionCerrajeriaEditable);
	};

	/**
	 * Habilita la edición manual del campo cimientos.
	 */
	const eventoEditarElementosConstruccionCimientos = () => {
		setElementosConstruccionCimientosEditable(!elementosConstruccionCimientosEditable);
	};

	/**
	 * Habilita la edición manual del campo entrepiso.
	 */
	const eventoEditarElementosConstruccionEntrepiso = () => {
		setElementosConstruccionEntrepisoEditable(!elementosConstruccionEntrepisoEditable);
	};

	/**
	 * Habilita la edición manual del campo escalera.
	 */
	const eventoEditarElementosConstruccionEscalera = () => {
		setElementosConstruccionEscaleraEditable(!elementosConstruccionEscaleraEditable);
	};

	/**
	 * Habilita la edición manual del campo estructura.
	 */
	const eventoEditarElementosConstruccionEstructura = () => {
		setElementosConstruccionEstructuraEditable(!elementosConstruccionEstructuraEditable);
	};

	/**
	 * Habilita la edición manual del campo fachada.
	 */
	const eventoEditarElementosConstruccionFachada = () => {
		setElementosConstruccionFachadaEditable(!elementosConstruccionFachadaEditable);
	};

	/**
	 * Habilita la edición manual del campo herrería.
	 */
	const eventoEditarElementosConstruccionHerreria = () => {
		setElementosConstruccionHerreriaEditable(!elementosConstruccionHerreriaEditable);
	};

	/**
	 * Habilita la edición manual del campo instalación eléctrica.
	 */
	const eventoEditarElementosConstruccionInstalacionElectrica = () => {
		setElementosConstruccionInstalacionElectricaEditable(!elementosConstruccionInstalacionElectricaEditable);
	};

	/**
	 * Habilita la edición manual del campo instalación especial.
	 */
	const eventoEditarElementosConstruccionInstalacionEspecial = () => {
		setElementosConstruccionInstalacionEspecialEditable(!elementosConstruccionInstalacionEspecialEditable);
	};

	/**
	 * Habilita la edición manual del campo instalación sanitaria.
	 */
	const eventoEditarElementosConstruccionInstalacionSanitaria = () => {
		setElementosConstruccionInstalacionSanitariaEditable(!elementosConstruccionInstalacionSanitariaEditable);
	};

	/**
	 * Habilita la edición manual del campo lambrin.
	 */
	const eventoEditarElementosConstruccionLambrin = () => {
		setElementosConstruccionLambrinEditable(!elementosConstruccionLambrinEditable);
	};

	/**
	 * Habilita la edición manual del campo mueble de baño.
	 */
	const eventoEditarElementosConstruccionMuebleBano = () => {
		setElementosConstruccionMuebleBanoEditable(!elementosConstruccionMuebleBanoEditable);
	};

	/**
	 * Habilita la edición manual del campo mueble de cocina.
	 */
	const eventoEditarElementosConstruccionMuebleCocina = () => {
		setElementosConstruccionMuebleCocinaEditable(!elementosConstruccionMuebleCocinaEditable);
	};

	/**
	 * Habilita la edición manual del campo muro.
	 */
	const eventoEditarElementosConstruccionMuro = () => {
		setElementosConstruccionMuroEditable(!elementosConstruccionMuroEditable);
	};

	/**
	 * Habilita la edición manual del campo pavimento petreo.
	 */
	const eventoEditarElementosConstruccionPavimentoPetreo = () => {
		setElementosConstruccionPavimentoPetreoEditable(!elementosConstruccionPavimentoPetreoEditable);
	};

	/**
	 * Habilita la edición manual del campo pintura.
	 */
	const eventoEditarElementosConstruccionPintura = () => {
		setElementosConstruccionPinturaEditable(!elementosConstruccionPinturaEditable);
	};

	/**
	 * Habilita la edición manual del campo plafón.
	 */
	const eventoEditarElementosConstruccionPlafon = () => {
		setElementosConstruccionPlafonEditable(!elementosConstruccionPlafonEditable);
	};

	/**
	 * Habilita la edición manual del campo recubrimiento especial.
	 */
	const eventoEditarElementosConstruccionRecubrimientoEspecial = () => {
		setElementosConstruccionRecubrimientoEspecialEditable(!elementosConstruccionRecubrimientoEspecialEditable);
	};

	/**
	 * Habilita la edición manual del campo techo.
	 */
	const eventoEditarElementosConstruccionTecho = () => {
		setElementosConstruccionTechoEditable(!elementosConstruccionTechoEditable);
	};

	/**
	 * Habilita la edición manual del campo vidriería.
	 */
	const eventoEditarElementosConstruccionVidrieria = () => {
		setElementosConstruccionVidrieriaEditable(!elementosConstruccionVidrieriaEditable);
	};

	/**
	 * Habilita la edición manual del campo zoclo.
	 */
	const eventoEditarElementosConstruccionZoclo = () => {
		setElementosConstruccionZocloEditable(!elementosConstruccionZocloEditable);
	};

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardarFormulario = () => {
		const avaluo = formularioValores.values as Avaluo;
		eventoGuardar(avaluo);
	};

	/**
	 * Válida el formulario de manera síncrona.
	 * - ***valores*** - Valores de los campos a validar.
	 */
	const eventoValidarFormulario = (valores: Record<string, any>): ValidationErrors => {
		const errores: ValidationErrors = {};
		if (!tieneValor(valores.nombre)) {
			errores.nombre = texto('Este campo es requerido');
		}
		return errores;
	};

	/**
	 * Válida el nombre del elemento de construcción.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioNombre = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarListaOpcionesDisponibilidad({ auth0AccessToken, campo: 'nombre', id: valores.id, tipo: valores.tipo, valor });
		if (!disponible) {
			return 'Ya existe un elemento de construcción con ese nombre';
		}
	};

	return (
		<Fragment>
			<div className="avaluos-formulario__grupo-checkbox">
				<Row>
					<Col lg="12">
						<h3>
							<i className={constantes.icono.elementosConstruccion}></i> {texto('Elementos de Construcción')}
						</h3>
						<p>{texto('En esta sección del formulario se captura la información de los elementos de contrucción del avalúo.')}</p>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col lg="12">{dibujarBarraHerramientas()}</Col>
				</Row>
				<Row>
					<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
				</Row>
				<Row>
					<Col lg="12">
						<h5>{texto('A) Obra Negra o Gruesa')}</h5>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<h5>
							{texto('Cimientos')}
							{dibujarBotonAgregarElementoConstruccion('cimientos')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionCimientos?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionCimientos({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionCimientos)}
						<McCampoTexto
							campo="elementosConstruccion.cimientos"
							id="elementosConstruccion.cimientos"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionCimientosEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Estructura')}
							{dibujarBotonAgregarElementoConstruccion('estructura')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionEstructura?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionEstructura({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionEstructura)}
						<McCampoTexto
							campo="elementosConstruccion.estructura"
							id="elementosConstruccion.estructura"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionEstructuraEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Muros')}
							{dibujarBotonAgregarElementoConstruccion('muro')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionMuro?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionMuro({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionMuro)}
						<McCampoTexto
							campo="elementosConstruccion.muro"
							id="elementosConstruccion.muro"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionMuroEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Bardas')}
							{dibujarBotonAgregarElementoConstruccion('barda')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionBarda?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionBarda({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionBarda)}
						<McCampoTexto
							campo="elementosConstruccion.barda"
							id="elementosConstruccion.barda"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionBardaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Entrepisos')}
							{dibujarBotonAgregarElementoConstruccion('entrepiso')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionEntrepiso?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionEntrepiso({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionEntrepiso)}
						<McCampoTexto
							campo="elementosConstruccion.entrepiso"
							id="elementosConstruccion.entrepiso"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionEntrepisoEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Techos')}
							{dibujarBotonAgregarElementoConstruccion('techo')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionTecho?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionTecho({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionTecho)}
						<McCampoTexto
							campo="elementosConstruccion.techo"
							id="elementosConstruccion.techo"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionTechoEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Azoteas')}
							{dibujarBotonAgregarElementoConstruccion('azotea')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionAzotea?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionAzotea({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionAzotea)}
						<McCampoTexto
							campo="elementosConstruccion.azotea"
							id="elementosConstruccion.azotea"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionAzoteaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>{texto('B) Revestimientos y Acabados')}</h5>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<h5>
							{texto('Aplanados')}
							{dibujarBotonAgregarElementoConstruccion('aplanado')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionAplanado?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionAplanado({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionAplanado)}
						<McCampoTexto
							campo="elementosConstruccion.aplanado"
							id="elementosConstruccion.aplanado"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionAplanadoEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Plafones')}
							{dibujarBotonAgregarElementoConstruccion('plafon')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionPlafon?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionPlafon({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionPlafon)}
						<McCampoTexto
							campo="elementosConstruccion.plafon"
							id="elementosConstruccion.plafon"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionPlafonEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Lambrines')}
							{dibujarBotonAgregarElementoConstruccion('lambrin')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionLambrin?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionLambrin({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionLambrin)}
						<McCampoTexto
							campo="elementosConstruccion.lambrin"
							id="elementosConstruccion.lambrin"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionLambrinEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Pavimentos Pétreos')}
							{dibujarBotonAgregarElementoConstruccion('pavimentoPetreo')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionPavimentoPetreo?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionPavimentoPetreo({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionPavimentoPetreo)}
						<McCampoTexto
							campo="elementosConstruccion.pavimentoPetreo"
							id="elementosConstruccion.pavimentoPetreo"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionPavimentoPetreoEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Zoclo')}
							{dibujarBotonAgregarElementoConstruccion('zoclo')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionZoclo?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionZoclo({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionZoclo)}
						<McCampoTexto
							campo="elementosConstruccion.zoclo"
							id="elementosConstruccion.zoclo"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionZocloEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Pintura')}
							{dibujarBotonAgregarElementoConstruccion('pintura')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionPintura?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionPintura({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionPintura)}
						<McCampoTexto
							campo="elementosConstruccion.pintura"
							id="elementosConstruccion.pintura"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionPinturaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Recubrimientos Especiales')}
							{dibujarBotonAgregarElementoConstruccion('recubrimientoEspecial')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionRecubrimientoEspecial?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionRecubrimientoEspecial({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionRecubrimientoEspecial)}
						<McCampoTexto
							campo="elementosConstruccion.recubrimientoEspecial"
							id="elementosConstruccion.recubrimientoEspecial"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionRecubrimientoEspecialEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Escaleras')}
							{dibujarBotonAgregarElementoConstruccion('escalera')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionEscalera?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionEscalera({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionEscalera)}
						<McCampoTexto
							campo="elementosConstruccion.escalera"
							id="elementosConstruccion.escalera"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionEscaleraEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('C) Carpintería')}
							{dibujarBotonAgregarElementoConstruccion('carpinteria')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionCarpinteria?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionCarpinteria({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionCarpinteria)}
						<McCampoTexto
							campo="elementosConstruccion.carpinteria"
							id="elementosConstruccion.carpinteria"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionCarpinteriaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('D) Instalación Sanitaria')}
							{dibujarBotonAgregarElementoConstruccion('instalacionSanitaria')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionInstalacionSanitaria?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionInstalacionSanitaria({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionInstalacionSanitaria)}
						<McCampoTexto
							campo="elementosConstruccion.instalacionSanitaria"
							id="elementosConstruccion.instalacionSanitaria"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionInstalacionSanitariaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('E) Muebles de Baño')}
							{dibujarBotonAgregarElementoConstruccion('muebleBano')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionMuebleBano?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionMuebleBano({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionMuebleBano)}
						<McCampoTexto
							campo="elementosConstruccion.muebleBano"
							id="elementosConstruccion.muebleBano"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionMuebleBanoEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('Muebles de Cocina')}
							{dibujarBotonAgregarElementoConstruccion('muebleCocina')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionMuebleCocina?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionMuebleCocina({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionMuebleCocina)}
						<McCampoTexto
							campo="elementosConstruccion.muebleCocina"
							id="elementosConstruccion.muebleCocina"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionMuebleCocinaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('F) Instalación Eléctrica')}
							{dibujarBotonAgregarElementoConstruccion('instalacionElectrica')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionInstalacionElectrica?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionInstalacionElectrica({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionInstalacionElectrica)}
						<McCampoTexto
							campo="elementosConstruccion.instalacionElectrica"
							id="elementosConstruccion.instalacionElectrica"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionInstalacionElectricaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('G) Herrería')}
							{dibujarBotonAgregarElementoConstruccion('herreria')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionHerreria?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionHerreria({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionHerreria)}
						<McCampoTexto
							campo="elementosConstruccion.herreria"
							id="elementosConstruccion.herreria"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionHerreriaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('H) Vidrería')}
							{dibujarBotonAgregarElementoConstruccion('vidrieria')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionVidrieria?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionVidrieria({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionVidrieria)}
						<McCampoTexto
							campo="elementosConstruccion.vidrieria"
							id="elementosConstruccion.vidrieria"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionVidrieriaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('I) Cerrajería')}
							{dibujarBotonAgregarElementoConstruccion('cerrajeria')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionCerrajeria?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionCerrajeria({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionCerrajeria)}
						<McCampoTexto
							campo="elementosConstruccion.cerrajeria"
							id="elementosConstruccion.cerrajeria"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionCerrajeriaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('J) Fachada')}
							{dibujarBotonAgregarElementoConstruccion('fachada')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionFachada?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionFachada({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionFachada)}
						<McCampoTexto
							campo="elementosConstruccion.fachada"
							id="elementosConstruccion.fachada"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionFachadaEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							{texto('K) Instalaciones Especiales')}
							{dibujarBotonAgregarElementoConstruccion('instalacionEspecial')}
						</h5>
					</Col>
				</Row>
				<Row>
					{listaElementosConstruccionInstalacionEspecial?.map((opcion) => (
						<Col key={opcion.id} lg="4">
							<McCheckbox
								campo={`elementosConstruccion.detalles.${opcion.tipo}.${opcion.id}`}
								etiqueta={opcion.nombre}
								eventoCambio={(evento) => eventoCambioElementosConstruccionInstalacionEspecial({ campo: opcion.id, valor: obtenerValorMcCheckbox(evento) })}
								id={opcion.id}
								key={opcion.id}
							/>
						</Col>
					))}
				</Row>
				<Row>
					<Col lg="12">
						{dibujarBotonEditarDescripcion(eventoEditarElementosConstruccionInstalacionEspecial)}
						<McCampoTexto
							campo="elementosConstruccion.instalacionEspecial"
							id="elementosConstruccion.instalacionEspecial"
							longitudMaxima={200}
							obligatorio
							renglones={2}
							soloLectura={!elementosConstruccionInstalacionEspecialEditable}
							tipo="areaTexto"
						/>
					</Col>
				</Row>
				<Row>
					<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
				</Row>
				<Row>
					<Col lg="12">{dibujarBarraHerramientas()}</Col>
				</Row>
				{dibujarModalAgregar()}
			</div>
		</Fragment>
	);
};

export default AvaluoFormularioElementosConstruccion;
