import { useEffect, useState } from 'react';

interface MenuSeparadorProps {
	/**
	 * Etiqueta que se mostrará en el botón.
	 */
	etiqueta: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	icono?: string;
	/**
	 * Identificador único del grupo.
	 */
	id: string;
}

/**
 * Agrupa un conjunto de botones.
 */
const MenuSeparador = (props: MenuSeparadorProps) => {
	const [separadorOculto, setSeparadorOculto] = useState(true);
	const { etiqueta, icono, id } = props;

	useEffect(() => {
		setTimeout(() => {
			let buscando = true;
			const grupo = document.getElementById(id);
			let siguienteElemento = grupo?.nextElementSibling;
			do {
				if (siguienteElemento) {
					if (siguienteElemento?.classList.contains('menu-title')) {
						buscando = false;
					} else {
						if (siguienteElemento.getAttribute('data-oculto') === 'false') {
							setSeparadorOculto(false);
							buscando = false;
						}
						siguienteElemento = siguienteElemento?.nextElementSibling;
					}
				} else {
					buscando = false;
				}
			} while (buscando);
		}, 200);
	}, []);

	return (
		<li className="menu-title" hidden={separadorOculto} id={id}>
			<i className={icono}></i>
			<span className="ms-1">{etiqueta}</span>
		</li>
	);
};

export default MenuSeparador;
