import { actualizarPerito, crearPerito, obtenerPeritoPorId } from 'servicios/api/peritos';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { mutatorCambiarValorCampo, mutatorCopiarValorCampo, mutatorLimpiarValorCampo } from '@mcsoft/formulario';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEsSeccionAdministracion, useEsSeccionCreacion, useEsSeccionEdicion } from 'hooks/useSeccion';
import { useHistory, useParams } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import DomicilioFormulario from 'componentes/domicilio/DomicilioFormulario';
import { Form } from 'react-final-form';
import { formatearPeritoParaFormulario } from 'util/modelo/peritos';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import Perito from 'modelo/Perito';
import PeritoFormulario from 'componentes/peritos/PeritoFormulario';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { validarFormularioDomicilio } from '@mcsoft/validaciones';
import { ValidationErrors } from 'final-form';

const NOMBRE_CLASE = 'paginas/app/peritos/PeritoDetallesEditar';

/**
 * En esta página se pueden editar los detalles de un perito.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const esSeccionCreacion = useEsSeccionCreacion();
	const esSeccionEdicion = useEsSeccionEdicion();
	const { id } = useParams<any>();
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.peritos);
	const {
		criterio: criterioAdmin,
		orden: ordenAdmin,
		ordenamiento: ordenamientoAdmin,
		pagina: paginaAdmin,
		registrosPorPagina: registrosPorPaginaAdmin
	} = useSelector((state: StateType) => state.parametrosPaginacion.peritosAdmin);
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [rutaSalida, setRutaSalida] = useState<string>(`${constantes.ruta.appPeritosDetalles}/${id}`);
	const [enlaces, setEnlaces] = useState<any>([]);
	const [formulario, setFormulario] = useState<any>({});

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, []);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, [location.pathname]);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Guarda la información del registro.
	 * - ***valores*** - Valores a guardar.
	 */
	const eventoGuardar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoGuardar';
		try {
			mcLogger.log({ mensaje: `Guardando la información del perito...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				const perito = valores as Perito;
				perito.usuarioId = usuario.id;
				await crearPerito({ auth0AccessToken, valores: perito });
				mcLogger.log({ mensaje: `Perito creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Perito creado con éxito.`) });
			}
			if (esSeccionEdicion()) {
				const perito = valores as Perito;
				await actualizarPerito({ auth0AccessToken, id: valores.id, valores: perito });
				mcLogger.log({ mensaje: `Perito actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Perito actualizado con éxito.`) });
			}
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
			history.push(rutaSalida);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la información del perito:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Válida el formulario de manera síncrona.
	 * - ***valores*** - Valores de los campos a validar.
	 */
	const eventoValidarFormulario = (valores: Record<string, any>): ValidationErrors => {
		const errores: ValidationErrors = {};
		errores.domicilio = validarFormularioDomicilio({ domicilio: valores.domicilio, obligatorio: true, texto });
		return errores;
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Inicializando formulario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				const registro = formatearPeritoParaFormulario({} as Perito);
				setFormulario(registro);
			}
			if (esSeccionEdicion()) {
				const respuesta = await obtenerPeritoPorId({ auth0AccessToken, id });
				const { datos } = respuesta;
				const registro = formatearPeritoParaFormulario(datos);
				setFormulario(registro);
			}
			mcLogger.log({ mensaje: `Formulario inicializado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al inicializar formulario:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los valores de las variables que cambian en función de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionCreacion()) {
			setTituloSeccion(texto('Crear perito'));
			const rutaSalida = `${constantes.ruta.appPeritosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Lista de peritos') }
			]);
		}
		if (esSeccionEdicion()) {
			if (esSeccionAdministracion()) {
				setTituloSeccion(texto('Editar perito'));
				const rutaSalida = `${constantes.ruta.appAdministracionPeritosDetalles}/${id}`;
				const rutaSalidaLista = `${constantes.ruta.appAdministracionPeritosLista}?pagina=${paginaAdmin}&registrosPorPagina=${registrosPorPaginaAdmin}&ordenamiento=${ordenamientoAdmin}&orden=${ordenAdmin}&criterio=${criterioAdmin}`;
				setRutaSalida(rutaSalida);
				setEnlaces([
					{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
					{ ruta: rutaSalidaLista, titulo: texto('Lista de peritos') },
					{ ruta: rutaSalida, titulo: texto('Detalles del perito') }
				]);
			} else {
				setTituloSeccion(texto('Editar mi perito'));
				const rutaSalida = `${constantes.ruta.appPeritosDetalles}/${id}`;
				const rutaSalidaLista = `${constantes.ruta.appPeritosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
				setRutaSalida(rutaSalida);
				setEnlaces([
					{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
					{ ruta: rutaSalidaLista, titulo: texto('Mis peritos') },
					{ ruta: rutaSalida, titulo: texto('Detalles de mi perito') }
				]);
			}
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={constantes.icono.perito} titulo={tituloSeccion} />
			{formulario && (
				<Form
					initialValues={formulario}
					mutators={{
						cambiarValorCampo: mutatorCambiarValorCampo,
						copiarValorCampo: mutatorCopiarValorCampo,
						limpiarValorCampo: mutatorLimpiarValorCampo
					}}
					onSubmit={eventoGuardar}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Card>
								<CardBody>
									<Row>
										<Col md="12">
											<PeritoFormulario registro={formulario} />
											<hr />
											<h3>{texto('Domicilio')}</h3>
											<DomicilioFormulario detallesEditables nombreFormulario="domicilio" />
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<BarraHerramientas>
										<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
											<i className={constantes.icono.atras}></i> {texto('Cancelar')}
										</button>
										<button className="btn btn-success" id="botonGuardar" type="submit">
											<i className={constantes.icono.guardar}></i> {texto('Guardar')}
										</button>
									</BarraHerramientas>
								</CardFooter>
							</Card>
						</form>
					)}
					validate={eventoValidarFormulario}
					validateOnBlur
				/>
			)}
		</Contenedor>
	);
};

export default Pagina;
