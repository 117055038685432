import { Col, Row } from 'reactstrap';
import { McCampoTexto } from '@mcsoft/formulario';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';
import { validarListaOpcionesDisponibilidad } from 'servicios/api/listaOpciones';

/**
 * Formulario para un tipo de calle.
 */
const TipoCalleFormulario = () => {
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;

	/**
	 * Válida el nombre del tipo de calle.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioNombre = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarListaOpcionesDisponibilidad({ auth0AccessToken, campo: 'nombre', id: valores.id, tipo: valores.tipo, valor });
		if (!disponible) {
			return 'Ya existe un tipo de calle con ese nombre';
		}
	};

	return (
		<Row>
			<Col md="12">
				<Row>
					<Col md="6">
						<McCampoTexto campo="nombre" etiqueta={texto('Nombre')} funcionValidacionAsincrona={validarFormularioNombre} id="campoNombre" longitudMaxima={100} obligatorio />
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default TipoCalleFormulario;
