/* eslint-disable no-unused-vars */
import './McRadioBoton.scss';
import { ChangeEvent, Fragment, ReactNode, useState } from 'react';
import { Field } from 'react-final-form';
import { Tooltip } from 'reactstrap';

interface McRadioBotonGrupoProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
	/**
	 * Etiqueta que se mostrará sobre el input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	etiqueta?: string;
	/**
	 * Icono *FontAwesome* que se mostrará junto a la etiqueta si hay información para mostrar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-info-circle'*
	 */
	iconoInformacion?: string;
	/**
	 * Identificador único del grupo de botones.
	 */
	id: string;
	/**
	 * Contenido que se mostrará al colocar el cursor en el icono de información.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	informacion?: any;
	/**
	 * Tipo de botones.
	 *
	 * > ***Predeterminado:*** *'radio'*
	 *
	 * **Valores Admitidos**
	 * - **'boton':** Botones tipo ***Bootstrap***.
	 * - **'radio':** Clasico botón de radio HTML.
	 */
	tipo?: 'boton' | 'radio';
}

interface McRadioBotonProps {
	/**
	 * Nombre del campo del formulario donde se guardará el valor del input.
	 *
	 * > **Nota:** Si el nombre del campo se repite en otros McRadioBoton, solo uno de ellos podrá estar activo y será el valor que tome el campo.
	 */
	campo: string;
	/**
	 * Etiqueta que se mostrará en el botón de radio.
	 */
	etiqueta: string;
	/**
	 * Evento que se ejecutara al seleccionar el botón.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * - ***evento*** - Evento que ejecuta la función.
	 */
	eventoCambio?: (evento: ChangeEvent<HTMLInputElement>) => void;
	/**
	 * Icono *FontAwesome* que se mostrará junto a la etiqueta si hay información para mostrar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-info-circle'*
	 */
	iconoInformacion?: string;
	/**
	 * Identificador único del botón.
	 */
	id: string;
	/**
	 * Contenido que se mostrará al colocar el cursor en el icono de información.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	informacion?: any;
	/**
	 * Indica si el campo debe ser resaltado cuando es obligatorio.
	 *
	 * > ***Predeterminado:*** *true*
	 *
	 * > **Nota:** Se resaltará cuando la validación de ***React Final Form*** regrese un error para el campo del input.
	 */
	resaltarObligatorio?: boolean;
	/**
	 * Indica si el input será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
	/**
	 * Valor que tomara el campo al seleccionar este botón de radio.
	 */
	valor: string;
}

/**
 * Input que permite elegir una única opción entre una lista de botones de radio.
 */
export const McRadioBoton = (props: McRadioBotonProps): JSX.Element => {
	const [mostrarTooltip, setMostrarTooltip] = useState(false);
	const { campo, etiqueta, eventoCambio, iconoInformacion = 'fa-solid fa-info-circle', id, informacion, resaltarObligatorio = true, soloLectura = false, valor } = props;

	const claseElementoPadre = document?.getElementById(id)?.parentElement?.getAttribute('class');
	const claseElementoAbuelo = document?.getElementById(id)?.parentElement?.parentElement?.getAttribute('class');

	return (
		<Field name={campo}>
			{({ input, meta }) => {
				const obligatorio = meta.error && resaltarObligatorio;
				return (
					<Fragment>
						{(claseElementoPadre === 'radio-toolbar' || claseElementoAbuelo === 'radio-toolbar') && (
							<Fragment>
								{informacion && (
									<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
										{informacion}
									</Tooltip>
								)}
								<input
									checked={input.value === valor}
									className="form-check-input"
									disabled={soloLectura}
									id={id}
									onChange={(evento: ChangeEvent<HTMLInputElement>) => {
										input.onChange(evento.target.value);
										if (eventoCambio) {
											eventoCambio(evento);
										}
									}}
									style={obligatorio ? { borderColor: '#b1860c' } : {}}
									type="radio"
									value={valor}
								/>
								<label className="form-check-label" htmlFor={id} style={obligatorio ? { color: '#b1860c' } : {}}>
									{etiqueta}
								</label>
							</Fragment>
						)}
						{claseElementoPadre !== 'radio-toolbar' && claseElementoAbuelo !== 'radio-toolbar' && (
							<Fragment>
								{informacion && (
									<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
										{informacion}
									</Tooltip>
								)}
								<div className="form-check">
									<input
										checked={input.value === valor}
										className="form-check-input"
										disabled={soloLectura}
										id={id}
										onChange={(evento: ChangeEvent<HTMLInputElement>) => {
											input.onChange(evento.target.value);
											if (eventoCambio) {
												eventoCambio(evento);
											}
										}}
										style={obligatorio ? { borderColor: '#b1860c' } : {}}
										type="radio"
										value={valor}
									/>
									<label className="form-check-label" htmlFor={id} style={obligatorio ? { color: '#b1860c' } : {}}>
										{etiqueta}
									</label>
									{informacion && (
										<span className="text-primary">
											{' '}
											<i className={iconoInformacion} id={`${id}_tooltip`}></i>
										</span>
									)}
								</div>
							</Fragment>
						)}
					</Fragment>
				);
			}}
		</Field>
	);
};

/**
 * Contenedor para los botones de radio McRadioBoton.
 */
export const McRadioBotonGrupo = (props: McRadioBotonGrupoProps): JSX.Element => {
	const [mostrarTooltip, setMostrarTooltip] = useState(false);
	const { children, etiqueta, iconoInformacion = 'fa-solid fa-info-circle', id, informacion, tipo = 'radio' } = props;

	if (tipo === 'boton') {
		return (
			<Fragment>
				{informacion && (
					<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
						{informacion}
					</Tooltip>
				)}
				{etiqueta && (
					<label className="form-label" htmlFor={id}>
						{etiqueta}
					</label>
				)}
				{informacion && (
					<span className="text-primary">
						{' '}
						<i className={iconoInformacion} id={`${id}_tooltip`}></i>
					</span>
				)}
				<div className="radio-toolbar">{children}</div>
			</Fragment>
		);
	}

	return (
		<Fragment>
			{informacion && (
				<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
					{informacion}
				</Tooltip>
			)}
			{etiqueta && (
				<label className="form-label" htmlFor={id}>
					{etiqueta}
				</label>
			)}
			{informacion && (
				<span className="text-primary">
					{' '}
					<i className={iconoInformacion} id={`${id}_tooltip`}></i>
				</span>
			)}
			{children}
		</Fragment>
	);
};
