import mcLogger from '@mcsoft/logger';

const NOMBRE_CLASE = '@mcsoft/api';

/**
 * Interfaz para las respuestas de la API.
 */
export interface McRespuesta {
	codigo: number;
	datos?: any;
	descripcion: string | Array<any>;
	descripcionOriginal: string | Array<any>;
	icono: string;
	nombre: string;
	nombreOriginal: string;
}

/**
 * Interfaz para los parámetros de paginación de registros de la API.
 */
export interface ParametrosPaginacion {
	criterio?: string;
	offset: number;
	orden: string;
	ordenamiento: Array<Array<string>>;
	pagina: number;
	registrosPorPagina: number;
}

/**
 * Interfaz para los registros paginados de la API.
 */
export interface RegistrosPaginados {
	lista: Array<any>;
	paginaActual: number;
	paginaFinal: number;
	registroFinal: number;
	registroInicial: number;
	registrosPorPagina: number;
	total: number;
}

/**
 * Calcula la paginación de registros.
 * - ***lista*** Lista solo con los registros de la página requerida.
 * - ***pagina*** Número de página obtenida.
 * - ***registrosPorPagina*** Cantidad de registros en los que se divide cada página.
 * - ***total*** Total de registros existentes.
 */
export const calcularPaginacion = ({
	lista,
	pagina,
	registrosPorPagina,
	total
}: {
	lista: Array<any>;
	pagina: number;
	registrosPorPagina: number;
	total: number;
}): RegistrosPaginados => {
	let mostrandoFinal = registrosPorPagina * pagina;
	const mostrandoInicial = mostrandoFinal - registrosPorPagina + 1;
	let paginaFinal = Math.trunc(total / registrosPorPagina);
	if (total % registrosPorPagina != 0) {
		paginaFinal = paginaFinal + 1;
	}
	if (pagina == paginaFinal) {
		mostrandoFinal = total;
	}
	const registrosPaginados = {
		lista,
		paginaActual: pagina,
		paginaFinal: paginaFinal,
		registroFinal: mostrandoFinal,
		registroInicial: mostrandoInicial,
		registrosPorPagina: registrosPorPagina,
		total: total
	};
	return registrosPaginados;
};

/**
 * Crea los parámetros de paginación a partir de los parámetros de una consulta.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***orden*** - Orden de la consulta SQL ['*asc*'|'*desc*'].
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página a obtener.
 * - ***registrosPorPagina*** - Número de registros en los que se dividirá cada página.
 */
export const crearParametrosPaginacion = ({
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio: string;
	orden: string | null;
	ordenamiento: string | null;
	pagina: number | string | null;
	registrosPorPagina: number | string | null;
}): ParametrosPaginacion => {
	if (criterio === 'undefined' || criterio === 'null') {
		criterio = '';
	}
	if (ordenamiento === 'undefined' || ordenamiento === 'null') {
		ordenamiento = null;
	}
	if (orden === 'undefined' || orden === 'null') {
		orden = 'asc';
	}
	if (pagina === 'undefined' || pagina === 'null') {
		pagina = 1;
	}
	if (registrosPorPagina === 'undefined' || registrosPorPagina === 'null') {
		registrosPorPagina = null;
	}
	let offset = 0;
	if (!isNaN(pagina as number) && !isNaN(registrosPorPagina as number)) {
		offset = (pagina as number) * (registrosPorPagina as number) - (registrosPorPagina as number);
	}
	let ordenamientoSequelize = [];
	if (ordenamiento) {
		ordenamientoSequelize = JSON.parse(ordenamiento);
		if (Array.isArray(ordenamientoSequelize)) {
			ordenamientoSequelize.forEach((elementoOrdenamiento) => {
				elementoOrdenamiento.push(orden);
			});
		}
	}
	const parametros = {
		criterio: criterio as string,
		offset: offset as number,
		orden: orden as string,
		ordenamiento: ordenamientoSequelize,
		pagina: pagina as number,
		registrosPorPagina: registrosPorPagina as number
	};
	return parametros;
};

/**
 * Contiene los códigos de estatus del protocolo HTTP.
 */
export const estatusHttp = {
	/**
	 * Esta respuesta provisional indica que todo hasta ahora está bien y que el cliente debe continuar con la solicitud o ignorarla si ya está terminada.
	 */
	estatus100: {
		codigo: 100,
		descripcion: 'El cliente debe continuar con su solicitud.',
		descripcionOriginal: 'The client should continue with its request.',
		icono: 'fas fa-info-circle',
		nombre: 'Continuar',
		nombreOriginal: 'Continue'
	},
	/**
	 * Este código se envía en respuesta a un encabezado de solicitud Upgrade por el cliente e indica que el servidor acepta el cambio de protocolo propuesto por el agente de usuario.
	 */
	estatus101: {
		codigo: 101,
		descripcion: 'El servidor comprende y está dispuesto a cumplir con la solicitud del cliente.',
		descripcionOriginal: "The server understands and is willing to comply with the client's request.",
		icono: 'fas fa-info-circle',
		nombre: 'Protocolo de conmutación',
		nombreOriginal: 'Switching Protocol'
	},
	/**
	 * Este código indica que el servidor ha recibido la solicitud y aún se encuentra procesándola, por lo que no hay respuesta disponible.
	 */
	estatus102: {
		codigo: 102,
		descripcion: 'El servidor ha aceptado la solicitud, pero aún no la ha completado.',
		descripcionOriginal: 'The server has accepted the complete request, but has not yet completed it.',
		icono: 'fas fa-info-circle',
		nombre: 'Procesando (WebDAV)',
		nombreOriginal: 'Processing (WebDAV)'
	},
	/**
	 * La solicitud ha tenido éxito. El significado de un éxito varía dependiendo del método HTTP:
	 * - ***GET*** El recurso se ha obtenido y se transmite en el cuerpo del mensaje.
	 * - ***HEAD*** Los encabezados de entidad están en el cuerpo del mensaje.
	 * - ***PUT | POST*** El recurso que describe el resultado de la acción se transmite en el cuerpo del mensaje.
	 * - ***TRACE*** El cuerpo del mensaje contiene el mensaje de solicitud recibido por el servidor.
	 */
	estatus200: {
		codigo: 200,
		descripcion: 'La solicitud se ha realizado con éxito.',
		descripcionOriginal: 'The request has succeeded.',
		icono: 'far fa-check-circle',
		nombre: 'Éxito',
		nombreOriginal: 'Ok'
	},
	/**
	 * La solicitud ha tenido éxito y se ha creado un nuevo recurso como resultado de ello. Ésta es típicamente la respuesta enviada después de una petición PUT.
	 */
	estatus201: {
		codigo: 201,
		descripcion: 'La solicitud se ha cumplido y ha dado lugar a la creación de un nuevo recurso.',
		descripcionOriginal: 'The request has been fulfilled and resulted in a new resource being created.',
		icono: 'far fa-check-circle',
		nombre: 'Creado',
		nombreOriginal: 'Created'
	},
	/**
	 * La solicitud se ha recibido, pero aún no se ha actuado.
	 * Es una petición "Sin compromiso", lo que significa que no hay manera en HTTP que permita enviar una respuesta asíncrona que indique el resultado del procesamiento de la solicitud.
	 * Está pensado para los casos en que otro proceso o servidor maneja la solicitud, o para el procesamiento por lotes.
	 */
	estatus202: {
		codigo: 202,
		descripcion: 'La solicitud ha sido aceptada para su procesamiento, pero el procesamiento no se ha completado.',
		descripcionOriginal: 'The request has been accepted for processing, but the processing has not been completed.',
		icono: 'far fa-check-circle',
		nombre: 'Aceptado',
		nombreOriginal: 'Accepted'
	},
	/**
	 * La petición se ha completado con éxito, pero su contenido no se ha obtenido de la fuente originalmente solicitada, sino que se recoge de una copia local o de un tercero.
	 * Excepto esta condición, se debe preferir una respuesta de 200 OK en lugar de esta respuesta.
	 */
	estatus203: {
		codigo: 203,
		descripcion: 'El servidor procesó con éxito la solicitud, pero está devolviendo información que puede provenir de otra fuente.',
		descripcionOriginal: 'The server successfully processed the request, but is returning information that may be from another source.',
		icono: 'far fa-check-circle',
		nombre: 'Información no autorizada',
		nombreOriginal: 'Non-Authoritative Information'
	},
	/**
	 * La petición se ha completado con éxito pero su respuesta no tiene ningún contenido, aunque los encabezados pueden ser útiles.
	 * El agente de usuario puede actualizar sus encabezados en caché para este recurso con los nuevos valores.
	 */
	estatus204: {
		codigo: 204,
		descripcion: 'El servidor procesó con éxito la solicitud, pero no devuelve ningún contenido.',
		descripcionOriginal: 'The server successfully processed the request, but is not returning any content.',
		icono: 'far fa-check-circle',
		nombre: 'Sin contenido',
		nombreOriginal: 'No Content'
	},
	/**
	 * La petición se ha completado con éxito, pero su respuesta no tiene contenidos y además, el agente de usuario tiene que inicializar la página desde la que se realizó la petición.
	 * Este código es útil por ejemplo para páginas con formularios cuyo contenido debe borrarse después de que el usuario lo envíe.
	 */
	estatus205: {
		codigo: 205,
		descripcion: 'El servidor procesó con éxito la solicitud, pero no devuelve ningún contenido.',
		descripcionOriginal: 'The server successfully processed the request, but is not returning any content.',
		icono: 'far fa-check-circle',
		nombre: 'Restablecer contenido',
		nombreOriginal: 'Reset Content'
	},
	/**
	 * Pendiente
	 */
	estatus206: {
		codigo: 206,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'far fa-check-circle',
		nombre: 'Contenido parcial',
		nombreOriginal: 'Partial Content'
	},
	/**
	 * Pendiente
	 */
	estatus207: {
		codigo: 207,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'far fa-check-circle',
		nombre: 'Varios estados (WebDAV)',
		nombreOriginal: 'Multi-Status (WebDAV)'
	},
	/**
	 * Pendiente
	 */
	estatus208: {
		codigo: 208,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'far fa-check-circle',
		nombre: 'Ya reportado (WebDAV)',
		nombreOriginal: 'Already Reported (WebDAV)'
	},
	/**
	 * Pendiente
	 */
	estatus226: {
		codigo: 226,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'far fa-check-circle',
		nombre: 'Estoy usado',
		nombreOriginal: 'IM Used'
	},
	/**
	 * Pendiente
	 */
	estatus300: {
		codigo: 300,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-directions',
		nombre: 'Múltiples Opciones',
		nombreOriginal: 'Multiple Choices'
	},
	/**
	 * Pendiente
	 */
	estatus301: {
		codigo: 301,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-directions',
		nombre: 'Movido Permanentemente',
		nombreOriginal: 'Moved Permanently'
	},
	/**
	 * Pendiente
	 */
	estatus302: {
		codigo: 302,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-directions',
		nombre: 'Encontrado',
		nombreOriginal: 'Found'
	},
	/**
	 * Pendiente
	 */
	estatus303: {
		codigo: 303,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-directions',
		nombre: 'Ver otros',
		nombreOriginal: 'See Other'
	},
	/**
	 * Pendiente
	 */
	estatus304: {
		codigo: 304,
		descripcion: 'El recurso no se ha modificado desde la última vez que se solicitó.',
		descripcionOriginal: 'The resource has not been modified since last requested.',
		icono: 'fas fa-directions',
		nombre: 'No modificado',
		nombreOriginal: 'Not Modified'
	},
	/**
	 * Pendiente
	 */
	estatus305: {
		codigo: 305,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-directions',
		nombre: 'Usa proxy',
		nombreOriginal: 'Use Proxy'
	},
	/**
	 * Pendiente
	 */
	estatus306: {
		codigo: 306,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-directions',
		nombre: 'No usado',
		nombreOriginal: 'Unused'
	},
	/**
	 * Pendiente
	 */
	estatus307: {
		codigo: 307,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-directions',
		nombre: 'Redireccionamiento temporal',
		nombreOriginal: 'Temporary Redirect'
	},
	/**
	 * Pendiente
	 */
	estatus308: {
		codigo: 308,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-directions',
		nombre: 'Redireccionamiento permanente (experimental)',
		nombreOriginal: 'Permanent Redirect (experimental)'
	},
	/**
	 * Esta respuesta significa que el servidor no pudo interpretar la solicitud dada una sintaxis inválida.
	 */
	estatus400: {
		codigo: 400,
		descripcion: 'Hay un error en la petición.',
		descripcionOriginal: 'There is an error in the request.',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Petición Incorrecta.',
		nombreOriginal: 'Bad Request'
	},
	/**
	 * Es necesario autenticar para obtener la respuesta solicitada. Esta es similar a 403, pero en este caso, autenticación es posible.
	 */
	estatus401: {
		codigo: 401,
		descripcion: 'La solicitud requiere autenticación de usuario.',
		descripcionOriginal: 'The request requires user authentication.',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'No autorizado',
		nombreOriginal: 'Unauthorized'
	},
	/**
	 * This code is reserved for future use.
	 */
	estatus402: {
		codigo: 402,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Pago requerido',
		nombreOriginal: 'Payment Required'
	},
	/**
	 * El cliente no posee los permisos necesarios para cierto contenido, por lo que el servidor está rechazando otorgar una respuesta apropiada.
	 */
	estatus403: {
		codigo: 403,
		descripcion: 'Acceso no autorizado.',
		descripcionOriginal: 'Unauthorized access.',
		icono: 'fas fa-ban',
		nombre: 'Prohibido',
		nombreOriginal: 'Forbidden'
	},
	/**
	 * El servidor no pudo encontrar el contenido solicitado. Este código de respuesta es uno de los más famosos dada su alta ocurrencia en la web.
	 */
	estatus404: {
		codigo: 404,
		descripcion: 'No se encontró el recurso requerido.',
		descripcionOriginal: 'The required resource was not found.',
		icono: 'far fa-times-circle',
		nombre: 'No Encontrado',
		nombreOriginal: 'Not Found'
	},
	/**
	 * El método solicitado es conocido por el servidor pero ha sido deshabilitado y no puede ser utilizado.
	 * Los dos métodos obligatorios, GET y HEAD, nunca deben ser deshabilitados y no debiesen retornar este código de error.
	 */
	estatus405: {
		codigo: 405,
		descripcion: 'El método requerido no está permitido.',
		descripcionOriginal: 'The required method is not allowed.',
		icono: 'fas fa-ban',
		nombre: 'Método no Permitido',
		nombreOriginal: 'Method Not Allowed'
	},
	/**
	 * Pendiente
	 */
	estatus406: {
		codigo: 406,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Inaceptable',
		nombreOriginal: 'Not Acceptable'
	},
	/**
	 * Pendiente
	 */
	estatus407: {
		codigo: 407,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Se requiere autenticación proxy',
		nombreOriginal: 'Proxy Authentication Required'
	},
	/**
	 * El cliente no produjo una solicitud dentro del tiempo en que el servidor estaba preparado para esperar.
	 */
	estatus408: {
		codigo: 408,
		descripcion: 'El servidor agotó el tiempo de espera de la solicitud.',
		descripcionOriginal: 'The server timed out waiting for the request.',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Se agotó el tiempo de espera',
		nombreOriginal: 'Request Timeout'
	},
	/**
	 * Esta respuesta puede ser enviada cuando una petición tiene conflicto con el estado actual del servidor.
	 */
	estatus409: {
		codigo: 409,
		descripcion: 'La solicitud no se pudo completar debido a un conflicto con el estado actual del recurso.',
		descripcionOriginal: 'The request could not be completed due to a conflict with the current state of the resource.',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Conflicto',
		nombreOriginal: 'Conflict'
	},
	/**
	 * Esta respuesta puede ser enviada cuando el contenido solicitado ha sido borrado del servidor.
	 */
	estatus410: {
		codigo: 410,
		descripcion: 'El recurso solicitado ya no está disponible en el servidor y no se conoce ninguna dirección de reenvío.',
		descripcionOriginal: 'The requested resource is no longer available at the server and no forwarding address is known.',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Desaparecido',
		nombreOriginal: 'Gone'
	},
	/**
	 * El servidor se niega a aceptar la solicitud sin una longitud de contenido definida.
	 */
	estatus411: {
		codigo: 411,
		descripcion: 'La solicitud no especificó la longitud de su contenido, que es requerida por el recurso solicitado.',
		descripcionOriginal: 'The request did not specify the length of its content, which is required by the requested resource.',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Longitud requerida',
		nombreOriginal: 'Length Required'
	},
	/**
	 * Pendiente
	 */
	estatus412: {
		codigo: 412,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Condición previa falló',
		nombreOriginal: 'Precondition Failed'
	},
	/**
	 * Pendiente
	 */
	estatus413: {
		codigo: 413,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Entidad de solicitud demasiado grande',
		nombreOriginal: 'Request Entity Too Large'
	},
	/**
	 * Pendiente
	 */
	estatus414: {
		codigo: 414,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Solicitud-URI demasiado larga',
		nombreOriginal: 'Request-URI Too Long'
	},
	/**
	 * Pendiente
	 */
	estatus415: {
		codigo: 415,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Tipo de medio no admitido',
		nombreOriginal: 'Unsupported Media Type'
	},
	/**
	 * Pendiente
	 */
	estatus416: {
		codigo: 416,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Rango solicitado no satisfactorio',
		nombreOriginal: 'Requested Range Not Satisfiable'
	},
	/**
	 * Pendiente
	 */
	estatus417: {
		codigo: 417,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: 'Expectativa fallida',
		nombreOriginal: 'Expectation Failed'
	},
	/**
	 * Pendiente
	 */
	estatus418: {
		codigo: 418,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus420: {
		codigo: 420,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus421: {
		codigo: 421,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus422: {
		codigo: 422,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus423: {
		codigo: 423,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus424: {
		codigo: 424,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus425: {
		codigo: 425,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus426: {
		codigo: 426,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus428: {
		codigo: 428,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus429: {
		codigo: 429,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus431: {
		codigo: 431,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus444: {
		codigo: 444,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus449: {
		codigo: 449,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus450: {
		codigo: 450,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus451: {
		codigo: 451,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus499: {
		codigo: 499,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus500: {
		codigo: 500,
		descripcion: 'Ocurrió un error al procesar la solicitud.',
		descripcionOriginal: 'An error occurred while processing the request.',
		icono: 'fas fa-server',
		nombre: 'Error Interno del Servidor',
		nombreOriginal: 'Internal Server Error'
	},
	/**
	 * Pendiente
	 */
	estatus501: {
		codigo: 501,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus502: {
		codigo: 502,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus503: {
		codigo: 503,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus504: {
		codigo: 504,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus505: {
		codigo: 505,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus506: {
		codigo: 506,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus507: {
		codigo: 507,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus508: {
		codigo: 508,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus509: {
		codigo: 509,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus510: {
		codigo: 510,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus511: {
		codigo: 511,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus598: {
		codigo: 598,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	},
	/**
	 * Pendiente
	 */
	estatus599: {
		codigo: 599,
		descripcion: '',
		descripcionOriginal: '',
		icono: 'fas fa-exclamation-triangle',
		nombre: '',
		nombreOriginal: ''
	}
};

/**
 * Valida que el objeto recibido sea del tipo McRespuesta.
 * - ***objeto*** Objeto a validar.
 */
const esMcRespuesta = (objeto: any) =>
	Object.prototype.hasOwnProperty.call(objeto, 'descripcion') &&
	Object.prototype.hasOwnProperty.call(objeto, 'descripcionOriginal') &&
	Object.prototype.hasOwnProperty.call(objeto, 'icono') &&
	Object.prototype.hasOwnProperty.call(objeto, 'nombre') &&
	Object.prototype.hasOwnProperty.call(objeto, 'nombreOriginal');

/**
 * Procesa un error y regresa una respuesta personalizada.
 * - ***error*** Objeto de tipo Error a procesar.
 */
export const procesarError = (error: any): McRespuesta => {
	const nombreMetodo = 'procesarError';
	mcLogger.nodeModule({
		mensaje: 'Error.name:',
		nombreArchivo: NOMBRE_CLASE,
		nombreMetodo,
		objetoExtra: error.name
	});
	mcLogger.nodeModule({
		mensaje: 'Error.message:',
		nombreArchivo: NOMBRE_CLASE,
		nombreMetodo,
		objetoExtra: error.message
	});
	mcLogger.nodeModule({
		mensaje: 'Error.response:',
		nombreArchivo: NOMBRE_CLASE,
		nombreMetodo,
		objetoExtra: error.response
	});
	if (esMcRespuesta(error)) {
		return error;
	}
	let mcRespuesta: McRespuesta;
	mcRespuesta = procesarErrorAuth0(error);
	mcRespuesta = procesarErrorAws(mcRespuesta);
	mcRespuesta = procesarErrorHttp(mcRespuesta);
	mcRespuesta = procesarErrorSequelize(mcRespuesta);
	if (esMcRespuesta(mcRespuesta)) {
		return mcRespuesta;
	} else {
		mcRespuesta = {
			codigo: estatusHttp.estatus500.codigo,
			descripcion: error.message,
			descripcionOriginal: estatusHttp.estatus500.descripcionOriginal,
			icono: estatusHttp.estatus500.icono,
			nombre: error.name,
			nombreOriginal: estatusHttp.estatus500.nombreOriginal
		};
		return mcRespuesta;
	}
};

/**
 * Procesa un error de Auth0 y regresa una respuesta personalizada.
 * - ***error*** Error de Auth0.
 */
const procesarErrorAuth0 = (error: any): McRespuesta => {
	if (esMcRespuesta(error)) {
		return error;
	}
	let mcRespuesta: McRespuesta;
	const { message, response } = error;
	if (response && response.data) {
		const { error: errorData, error_description, message } = response.data;
		if (message) {
			switch (message) {
				case 'The specified new email already exists':
					mcRespuesta = {
						codigo: estatusHttp.estatus400.codigo,
						descripcion: estatusHttp.estatus400.descripcion,
						descripcionOriginal: message,
						icono: estatusHttp.estatus400.icono,
						nombre: estatusHttp.estatus400.nombre,
						nombreOriginal: error.name
					};
					return mcRespuesta;
				case 'The user does not exist.':
					mcRespuesta = {
						codigo: estatusHttp.estatus404.codigo,
						descripcion: estatusHttp.estatus404.descripcion,
						descripcionOriginal: message,
						icono: estatusHttp.estatus404.icono,
						nombre: estatusHttp.estatus404.nombre,
						nombreOriginal: error.name
					};
					return mcRespuesta;
				case 'Invalid token':
					mcRespuesta = {
						codigo: estatusHttp.estatus401.codigo,
						descripcion: estatusHttp.estatus401.descripcion,
						descripcionOriginal: message,
						icono: estatusHttp.estatus401.icono,
						nombre: estatusHttp.estatus401.nombre,
						nombreOriginal: error
					};
					return mcRespuesta;
				default:
					return error;
			}
		} else {
			switch (error_description) {
				case 'Unauthorized':
					mcRespuesta = {
						codigo: estatusHttp.estatus403.codigo,
						descripcion: estatusHttp.estatus403.descripcion,
						descripcionOriginal: message,
						icono: estatusHttp.estatus403.icono,
						nombre: estatusHttp.estatus403.nombre,
						nombreOriginal: errorData
					};
					return mcRespuesta;
				default:
					return error;
			}
		}
	} else {
		switch (message) {
			default:
				return error;
		}
	}
};

/**
 * Procesa un error de Amazon Web Services y regresa una respuesta personalizada.
 * - ***error*** Error de AWS.
 */
const procesarErrorAws = (error: any): McRespuesta => {
	if (esMcRespuesta(error)) {
		return error;
	}
	let mcRespuesta: McRespuesta;
	switch (error.name) {
		case 'UriParameterError':
			mcRespuesta = {
				codigo: estatusHttp.estatus400.codigo,
				descripcion: estatusHttp.estatus400.descripcion,
				descripcionOriginal: error.message,
				icono: estatusHttp.estatus400.icono,
				nombre: estatusHttp.estatus400.nombre,
				nombreOriginal: error.name
			};
			return mcRespuesta;
		default:
			return error;
	}
};

/**
 * Procesa un error HTTP y regresa una respuesta personalizada.
 * - ***error*** Error de HTTP.
 */
const procesarErrorHttp = (error: any): McRespuesta => {
	if (esMcRespuesta(error)) {
		return error;
	}
	let mcRespuesta: McRespuesta;
	switch (error.response?.status) {
		case estatusHttp.estatus400.codigo:
			mcRespuesta = {
				codigo: estatusHttp.estatus400.codigo,
				descripcion: error.response?.data?.message
					? error.response?.data?.message
					: error.response?.data?.error_description
					? error.response?.data?.error_description
					: error.response?.data,
				descripcionOriginal: error.message,
				icono: estatusHttp.estatus400.icono,
				nombre: estatusHttp.estatus400.nombre,
				nombreOriginal: error.name
			};
			return mcRespuesta;
		case estatusHttp.estatus401.codigo:
			mcRespuesta = {
				codigo: estatusHttp.estatus401.codigo,
				descripcion: error.response?.data?.message
					? error.response?.data?.message
					: error.response?.data?.error_description
					? error.response?.data?.error_description
					: error.response?.data,
				descripcionOriginal: error.message,
				icono: estatusHttp.estatus401.icono,
				nombre: estatusHttp.estatus401.nombre,
				nombreOriginal: error.name
			};
			return mcRespuesta;
		case estatusHttp.estatus403.codigo:
			mcRespuesta = {
				codigo: estatusHttp.estatus403.codigo,
				descripcion: error.response?.data?.message
					? error.response?.data?.message
					: error.response?.data?.error_description
					? error.response?.data?.error_description
					: error.response?.data,
				descripcionOriginal: error.message,
				icono: estatusHttp.estatus403.icono,
				nombre: estatusHttp.estatus403.nombre,
				nombreOriginal: error.name
			};
			return mcRespuesta;
		case estatusHttp.estatus404.codigo:
			mcRespuesta = {
				codigo: estatusHttp.estatus404.codigo,
				descripcion: error.response?.data?.message
					? error.response?.data?.message
					: error.response?.data?.error_description
					? error.response?.data?.error_description
					: error.response?.data,
				descripcionOriginal: error.message,
				icono: estatusHttp.estatus404.icono,
				nombre: estatusHttp.estatus404.nombre,
				nombreOriginal: error.name
			};
			return mcRespuesta;
		case estatusHttp.estatus406.codigo:
			mcRespuesta = {
				codigo: estatusHttp.estatus406.codigo,
				descripcion: error.response?.data?.message
					? error.response?.data?.message
					: error.response?.data?.error_description
					? error.response?.data?.error_description
					: error.response?.data,
				descripcionOriginal: error.message,
				icono: estatusHttp.estatus406.icono,
				nombre: estatusHttp.estatus406.nombre,
				nombreOriginal: error.name
			};
			return mcRespuesta;
		default:
			return error;
	}
};

/**
 * Procesa un error de Sequelize y regresa una respuesta personalizada.
 * - ***error*** Error de Sequelize.
 */
const procesarErrorSequelize = (error: any): McRespuesta => {
	if (esMcRespuesta(error)) {
		return error;
	}
	let mcRespuesta: McRespuesta;
	switch (error.name) {
		case 'SequelizeValidationError':
			const descripcionArreglo: Array<string> = [];
			error.errors.forEach((errorValidacion: any) => {
				descripcionArreglo.push(errorValidacion.message);
			});
			mcRespuesta = {
				codigo: estatusHttp.estatus400.codigo,
				descripcion: descripcionArreglo,
				descripcionOriginal: error.message,
				icono: estatusHttp.estatus400.icono,
				nombre: estatusHttp.estatus400.nombre,
				nombreOriginal: error.name
			};
			return mcRespuesta;
		case 'SequelizeUniqueConstraintError':
			mcRespuesta = {
				codigo: estatusHttp.estatus400.codigo,
				descripcion: error.message,
				descripcionOriginal: error.message,
				icono: estatusHttp.estatus400.icono,
				nombre: estatusHttp.estatus400.nombre,
				nombreOriginal: error.name
			};
			return mcRespuesta;
		case 'SequelizeForeignKeyConstraintError':
			mcRespuesta = {
				codigo: estatusHttp.estatus400.codigo,
				descripcion: error.message,
				descripcionOriginal: error.message,
				icono: estatusHttp.estatus400.icono,
				nombre: estatusHttp.estatus400.nombre,
				nombreOriginal: error.name
			};
			return mcRespuesta;
		case 'SequelizeEmptyResultError':
			mcRespuesta = {
				codigo: estatusHttp.estatus404.codigo,
				descripcion: error.message, // TODO Probar
				descripcionOriginal: error.message,
				icono: estatusHttp.estatus404.icono,
				nombre: estatusHttp.estatus404.nombre,
				nombreOriginal: error.name
			};
			return mcRespuesta;
		default:
			return error;
	}
};

/**
 * Procesa él response de la API y regresa la respuesta o un error en caso necesario.
 * - ***respuesta*** Response HTTP.
 */
export const procesarRespuesta = (respuesta: any): McRespuesta => {
	const nombreMetodo = 'procesarRespuesta';
	mcLogger.nodeModule({
		mensaje: 'Respuesta:',
		nombreArchivo: NOMBRE_CLASE,
		nombreMetodo,
		objetoExtra: respuesta
	});
	mcLogger.nodeModule({
		mensaje: 'Respuesta.status:',
		nombreArchivo: NOMBRE_CLASE,
		nombreMetodo,
		objetoExtra: respuesta.status
	});
	mcLogger.nodeModule({
		mensaje: 'Respuesta.data:',
		nombreArchivo: NOMBRE_CLASE,
		nombreMetodo,
		objetoExtra: respuesta.data
	});
	if (esMcRespuesta(respuesta)) {
		return respuesta;
	}
	let mcRespuesta: McRespuesta;
	mcRespuesta = procesarRespuestaAws(respuesta);
	mcRespuesta = procesarRespuestaHttp(mcRespuesta);
	if (esMcRespuesta(mcRespuesta)) {
		return mcRespuesta;
	} else {
		mcRespuesta = {
			codigo: estatusHttp.estatus200.codigo,
			descripcion: respuesta.data ? respuesta.data : respuesta.body ? respuesta.body : estatusHttp.estatus200.descripcion,
			descripcionOriginal: estatusHttp.estatus200.descripcionOriginal,
			icono: estatusHttp.estatus200.icono,
			nombre: estatusHttp.estatus200.nombre,
			nombreOriginal: estatusHttp.estatus200.nombreOriginal
		};
		return mcRespuesta;
	}
};

/**
 * Procesa él response de la API y regresa la respuesta o un error en caso necesario.
 * - ***respuesta*** Response HTTP.
 */
export const procesarRespuestaAws = (respuesta: any): McRespuesta => {
	const { Body } = respuesta;
	let mcRespuesta: McRespuesta;
	if (Body) {
		mcRespuesta = {
			codigo: estatusHttp.estatus200.codigo,
			datos: respuesta,
			descripcion: estatusHttp.estatus200.descripcion,
			descripcionOriginal: estatusHttp.estatus200.descripcionOriginal,
			icono: estatusHttp.estatus200.icono,
			nombre: estatusHttp.estatus200.nombre,
			nombreOriginal: estatusHttp.estatus200.nombreOriginal
		};
		return mcRespuesta;
	}
	return respuesta;
};

/**
 * Procesa él response HTTP y regresa la respuesta o un error en caso necesario.
 * - ***respuesta*** Response HTTP.
 */
export const procesarRespuestaHttp = (respuesta: any): McRespuesta => {
	const { data, status } = respuesta;
	let mcRespuesta: McRespuesta;
	switch (status) {
		case estatusHttp.estatus200.codigo:
			mcRespuesta = {
				codigo: estatusHttp.estatus200.codigo,
				datos: data,
				descripcion: estatusHttp.estatus200.descripcion,
				descripcionOriginal: estatusHttp.estatus200.descripcionOriginal,
				icono: estatusHttp.estatus200.icono,
				nombre: estatusHttp.estatus200.nombre,
				nombreOriginal: estatusHttp.estatus200.nombreOriginal
			};
			return mcRespuesta;
		case estatusHttp.estatus201.codigo:
			mcRespuesta = {
				codigo: estatusHttp.estatus201.codigo,
				datos: data,
				descripcion: estatusHttp.estatus201.descripcion,
				descripcionOriginal: estatusHttp.estatus201.descripcionOriginal,
				icono: estatusHttp.estatus201.icono,
				nombre: estatusHttp.estatus201.nombre,
				nombreOriginal: estatusHttp.estatus201.nombreOriginal
			};
			return mcRespuesta;
		case estatusHttp.estatus204.codigo:
			mcRespuesta = {
				codigo: estatusHttp.estatus204.codigo,
				datos: data,
				descripcion: estatusHttp.estatus204.descripcion,
				descripcionOriginal: estatusHttp.estatus204.descripcionOriginal,
				icono: estatusHttp.estatus204.icono,
				nombre: estatusHttp.estatus204.nombre,
				nombreOriginal: estatusHttp.estatus204.nombreOriginal
			};
			return mcRespuesta;
		default:
			return respuesta;
	}
};
