import Estado from 'modelo/Estado';
import { McCampoSelectorMultipleOpcion } from '@mcsoft/formulario';

/**
 * Formatea una lista de estados para utilizarse como lista de opciones en un McCampoSelectorMultiple.
 * - ***lista*** - Arreglo con la lista de estados.
 */
export const formatearListaEstadosMcCampoSelectorMultiple = (lista?: Array<Estado>): Array<McCampoSelectorMultipleOpcion> => {
	const listaOpciones: Array<McCampoSelectorMultipleOpcion> = [];
	if (lista) {
		lista.forEach((elemento: Estado) => {
			listaOpciones.push({ nombre: elemento.nombre, valor: elemento.id });
		});
	}
	return listaOpciones;
};
