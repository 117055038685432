import { Fragment, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BarraNavegacionLateral from 'componentes/tema-vertical/BarraNavegacionLateral';
import Encabezado from 'componentes/tema-vertical/Encabezado';
import PiePagina from 'componentes/tema-vertical/PiePagina';
import { setSesionPreferenciasAtributosAction } from 'store/actions';
import { StateType } from 'store';

interface TemaVerticalProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
}

/**
 * Componente contenedor del tema vertical.
 */
const TemaVertical = (props: TemaVerticalProps) => {
	const dispatch = useDispatch();
	const { sesion } = useSelector((state: StateType) => state);
	const { usuario } = sesion;
	const { preferencias } = usuario;
	const { interfazAncho, interfazBarraNavegacionLateralAncho, interfazBarraNavegacionLateralTema, interfazBarraNavegacionSuperiorTema } = preferencias;
	const { children } = props;

	useEffect(() => {
		window.scrollTo(0, 0);
		if (interfazBarraNavegacionLateralTema) {
			const nuevasPreferencias = {
				...preferencias,
				interfazBarraNavegacionLateralTema: interfazBarraNavegacionLateralTema
			};
			dispatch(setSesionPreferenciasAtributosAction({ atributo: 'interfazBarraNavegacionLateralTema', preferencias: nuevasPreferencias }));
		}
		if (interfazAncho) {
			const nuevasPreferencias = {
				...preferencias,
				interfazAncho: interfazAncho
			};
			dispatch(setSesionPreferenciasAtributosAction({ atributo: 'interfazAncho', preferencias: nuevasPreferencias }));
		}
		if (interfazBarraNavegacionLateralAncho) {
			const nuevasPreferencias = {
				...preferencias,
				interfazBarraNavegacionLateralAncho: interfazBarraNavegacionLateralAncho
			};
			dispatch(setSesionPreferenciasAtributosAction({ atributo: 'interfazBarraNavegacionLateralAncho', preferencias: nuevasPreferencias }));
		}
		if (interfazBarraNavegacionSuperiorTema) {
			const nuevasPreferencias = {
				...preferencias,
				interfazBarraNavegacionSuperiorTema: interfazBarraNavegacionSuperiorTema
			};
			dispatch(setSesionPreferenciasAtributosAction({ atributo: 'interfazBarraNavegacionSuperiorTema', preferencias: nuevasPreferencias }));
		}
	}, []);

	return (
		<Fragment>
			<div id="layout-wrapper">
				<Encabezado />
				<BarraNavegacionLateral />
				<div className="main-content">{children}</div>
				<PiePagina />
			</div>
		</Fragment>
	);
};

export default TemaVertical;
