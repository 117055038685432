/* eslint-disable no-unused-vars */
import './NotificacionTarjeta.scss';
import constantes from 'configuracion/constantes';
import { Fragment } from 'react';
import moment from 'moment-timezone';
import UsuarioNotificacionDetalle from 'modelo/UsuarioNotificacionDetalle';

interface NotificacionTarjetaProps {
	/**
	 * Indica si la notificación se mostrara en su version reducida.
	 *
	 * > ***Predeterminado:*** *true*
	 */
	esMiniatura?: boolean;
	/**
	 * Evento que se ejecuta cuando se hace clic sobre la notificación.
	 * > - ***notificacion*** - Objeto con la información de la notificación.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	eventoVerNotificacion?: (notificacion: UsuarioNotificacionDetalle) => void;
	/**
	 * Objeto con la información de la notificación.
	 */
	usuarioNotificacion: UsuarioNotificacionDetalle;
}

/**
 * Muestra una tarjeta con la información de uns notificación.
 */
const NotificacionTarjeta = (props: NotificacionTarjetaProps) => {
	const { esMiniatura = true, eventoVerNotificacion = () => {}, usuarioNotificacion } = props;
	const { estatus, fechaCreacion, notificacion } = usuarioNotificacion;
	const { descripcion, icono, iconoColorFondo, titulo } = notificacion;

	if (usuarioNotificacion && notificacion) {
		const contenido = (
			<div className="d-flex align-items-start">
				<div className="avatar-xs me-3">
					<span
						className="avatar-title rounded-circle font-size-16"
						style={{
							backgroundColor: `${iconoColorFondo ? iconoColorFondo : constantes.color.notificacionColorPredeterminado}`
						}}
					>
						<i className={icono ? icono : constantes.icono.notificacion}></i>
					</span>
				</div>
				<div className="flex-1">
					<h6 className="mt-0 mb-1">{esMiniatura && titulo.length > 27 ? `${titulo.substring(0, 24)}...` : titulo}</h6>
					<div className="font-size-12 text-muted">
						<p className="mb-1">{esMiniatura && descripcion.length > 60 ? `${descripcion.substring(0, 57)}...` : descripcion}</p>
						<p className="mb-0">
							<i className="mdi mdi-clock-outline"></i> {moment(fechaCreacion).tz('America/Bahia_Banderas').fromNow()}
						</p>
					</div>
				</div>
			</div>
		);

		return (
			<Fragment>
				<span
					className={`text-reset ${estatus === 'nueva' ? 'notification-tarjeta__elemento-nuevo' : 'notification-tarjeta__elemento'}`}
					onClick={() => eventoVerNotificacion(usuarioNotificacion)}
				>
					{contenido}
				</span>
			</Fragment>
		);
	}
	return null;
};

export default NotificacionTarjeta;
