import { Col, Container, Row } from 'reactstrap';
import fechaDeploy from '../../fechaDeploy';
import { Fragment } from 'react';
import { StateType } from 'store';
import { useSelector } from 'react-redux';

/**
 * Pie de página que muestra los derechos de autor.
 */
const PiePagina = () => {
	const { copyright, nombreAplicacion, versionAplicacion } = useSelector((state: StateType) => state.configuracionSistema);

	const obtenerEntorno = () => {
		let color = '';
		let estatusNombre = '';
		switch (process.env.REACT_APP_ENV) {
			case 'production':
				color = 'danger';
				estatusNombre = 'PRODUCCIÓN';
				break;
			case 'development':
				color = 'warning';
				estatusNombre = 'DESARROLLO';
				break;
			default:
				color = 'primary';
				estatusNombre = 'DESCONOCIDO';
				break;
		}
		return <span className={`badge rounded-pill bg-${color}`}>{estatusNombre}</span>;
	};

	return (
		<Fragment>
			<footer className="footer">
				<Container fluid={true}>
					<Row>
						<Col md="6">{copyright}</Col>
						<Col md="6">
							<div className="text-sm-end d-none d-sm-block">
								<div className="text-sm-end d-none d-sm-block">
									{`${nombreAplicacion} v${versionAplicacion}.${fechaDeploy.fechaDeploy}`}&nbsp;{obtenerEntorno()}
								</div>
							</div>
						</Col>
					</Row>
				</Container>
			</footer>
		</Fragment>
	);
};

export default PiePagina;
