import { combineReducers } from 'redux';
import configuracionAplicacion from 'store/configuracionAplicacion/reducer';
import configuracionSistema from 'store/configuracionSistema/reducer';
import imagenesTemporales from 'store/imagenesTemporales/reducer';
import interfaz from 'store/interfaz/reducer';
import pantallaCarga from 'store/pantallaCarga/reducer';
import parametrosPaginacion from 'store/parametrosPaginacion/reducer';
import sesion from 'store/sesion/reducer';
import visorImagen from 'store/visorImagen/reducer';

const rootReducer = combineReducers({
	configuracionAplicacion,
	configuracionSistema,
	imagenesTemporales,
	interfaz,
	pantallaCarga,
	parametrosPaginacion,
	sesion,
	visorImagen
});

export default rootReducer;