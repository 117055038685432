import { StateType } from 'store';
import { useSelector } from 'react-redux';

/**
 * Formatea un numero decimal con comas y el simbolo de moneda predeterminado por el sistema.
 */
const useMoneda = () => {
	const { monedaCodigo, monedaLocale } = useSelector((state: StateType) => state.configuracionAplicacion);
	const formateador = new Intl.NumberFormat(monedaLocale, {
		currency: monedaCodigo,
		style: 'currency'
	});
	return (cantidad: number | string) => formateador.format(Number(cantidad));
};

export default useMoneda;
