import { Col, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import aplicacion from 'configuracion/aplicacion';
import constantes from 'configuracion/constantes';
import InformacionImagenPredeterminada from 'componentes/informacion/InformacionImagenPredeterminada';
import { McCampoTexto } from '@mcsoft/formulario';
import { texto } from 'idiomas';
import { useFormState } from 'react-final-form';

/**
 * Formulario para la configuración de imágenes de la aplicación.
 */
const AplicacionFormularioImagenes = () => {
	const formularioValores = useFormState();
	const {
		imagenLogoClaro202x202,
		imagenLogoClaro1080x202,
		imagenLogoOscuro202x202,
		imagenLogoOscuro1080x202,
		imagenPredeterminadaPeritoLicencia,
		imagenPredeterminadaUsuarioFotoPerfil50x50,
		imagenPredeterminadaUsuarioFotoPerfil100x100,
		imagenPredeterminadaUsuarioFotoPerfil700x700
	} = formularioValores.values;
	const [logoClaro1080x202, setLogoClaro1080x202] = useState(aplicacion.imagenes.logoClaro1080x202);
	const [logoClaro202x202, setLogoClaro202x202] = useState(aplicacion.imagenes.logoClaro202x202);
	const [logoOscuro1080x202, setLogoOscuro1080x202] = useState(aplicacion.imagenes.logoOscuro1080x202);
	const [logoOscuro202x202, setLogoOscuro202x202] = useState(aplicacion.imagenes.logoOscuro202x202);
	const [usuarioFotoPerfil100x100, setUsuarioFotoPerfil100x100] = useState(aplicacion.imagenes.predeterminadaFotoPerfil100x100);
	const [usuarioFotoPerfil50x50, setUsuarioFotoPerfil50x50] = useState(aplicacion.imagenes.predeterminadaFotoPerfil50x50);
	const [usuarioFotoPerfil700x700, setUsuarioFotoPerfil700x700] = useState(aplicacion.imagenes.predeterminadaFotoPerfil700x700);
	const [peritoFotoLicencia400x260, setPeritoFotoLicencia400x260] = useState(aplicacion.imagenes.predeterminadaFotoPeritoLicencia400);

	useEffect(() => {
		setLogoClaro1080x202(imagenLogoClaro1080x202 ? imagenLogoClaro1080x202 : aplicacion.imagenes.logoClaro1080x202);
		setLogoClaro202x202(imagenLogoClaro202x202 ? imagenLogoClaro202x202 : aplicacion.imagenes.logoClaro202x202);
		setLogoOscuro1080x202(imagenLogoOscuro1080x202 ? imagenLogoOscuro1080x202 : aplicacion.imagenes.logoOscuro1080x202);
		setLogoOscuro202x202(imagenLogoOscuro202x202 ? imagenLogoOscuro202x202 : aplicacion.imagenes.logoOscuro202x202);
		setUsuarioFotoPerfil50x50(imagenPredeterminadaUsuarioFotoPerfil50x50 ? imagenPredeterminadaUsuarioFotoPerfil50x50 : aplicacion.imagenes.predeterminadaFotoPerfil50x50);
		setUsuarioFotoPerfil100x100(imagenPredeterminadaUsuarioFotoPerfil100x100 ? imagenPredeterminadaUsuarioFotoPerfil100x100 : aplicacion.imagenes.predeterminadaFotoPerfil100x100);
		setUsuarioFotoPerfil700x700(imagenPredeterminadaUsuarioFotoPerfil700x700 ? imagenPredeterminadaUsuarioFotoPerfil700x700 : aplicacion.imagenes.predeterminadaFotoPerfil700x700);
		setPeritoFotoLicencia400x260(imagenPredeterminadaPeritoLicencia ? imagenPredeterminadaPeritoLicencia : aplicacion.imagenes.predeterminadaFotoPeritoLicencia400);
	}, [
		imagenLogoClaro202x202,
		imagenLogoOscuro202x202,
		imagenLogoClaro1080x202,
		imagenLogoOscuro1080x202,
		imagenPredeterminadaPeritoLicencia,
		imagenPredeterminadaUsuarioFotoPerfil50x50,
		imagenPredeterminadaUsuarioFotoPerfil100x100,
		imagenPredeterminadaUsuarioFotoPerfil700x700
	]);

	return (
		<Row>
			<Col lg="12">
				<Row>
					<Col lg="12">
						<h3>
							<i className={constantes.icono.imagenes}></i> {texto('Imágenes')}
						</h3>
						<p>{texto('Aquí se configuran las imágenes de la aplicación.')}</p>
					</Col>
				</Row>
				<hr />
				<Row>
					<h4>
						<i className={constantes.icono.foto}></i> {texto('Logos')}
					</h4>
					<Col lg="4">
						<div className="aplicacion-formulario__vista-previa-logo">
							<img alt="Header Avatar" src={logoOscuro1080x202} width="150px" />
						</div>
					</Col>
					<Col lg="8">
						<McCampoTexto
							campo="imagenLogoOscuro1080x202"
							etiqueta={texto('Oscuro 1080x202 pixeles')}
							id="imagenLogoOscuro1080x202"
							informacion={<InformacionImagenPredeterminada />}
							longitudMaxima={1000}
						/>
					</Col>
					<Col lg="4">
						<div className="aplicacion-formulario__vista-previa-logo--claro">
							<img alt="Header Avatar" src={logoClaro1080x202} width="150px" />
						</div>
					</Col>
					<Col lg="8">
						<McCampoTexto
							campo="imagenLogoClaro1080x202"
							etiqueta={texto('Claro 1080x202 pixeles')}
							id="imagenLogoClaro1080x202"
							informacion={<InformacionImagenPredeterminada />}
							longitudMaxima={1000}
						/>
					</Col>
					<Col lg="4">
						<div className="aplicacion-formulario__vista-previa-logo-cuadrado">
							<img alt="Header Avatar" src={logoOscuro202x202} width="150px" />
						</div>
					</Col>
					<Col lg="8">
						<McCampoTexto
							campo="imagenLogoOscuro202x202"
							etiqueta={texto('Oscuro 202x202 pixeles')}
							id="imagenLogoOscuro202x202"
							informacion={<InformacionImagenPredeterminada />}
							longitudMaxima={1000}
						/>
					</Col>
					<Col lg="4">
						<div className="aplicacion-formulario__vista-previa-logo-cuadrado--claro">
							<img alt="Header Avatar" src={logoClaro202x202} width="150px" />
						</div>
					</Col>
					<Col lg="8">
						<McCampoTexto
							campo="imagenLogoClaro202x202"
							etiqueta={texto('Claro 202x202 pixeles')}
							id="imagenLogoClaro202x202"
							informacion={<InformacionImagenPredeterminada />}
							longitudMaxima={1000}
						/>
					</Col>
				</Row>
				<hr />
				<Row>
					<h4>
						<i className={constantes.icono.foto}></i> {texto('Fotos de perfil predeterminadas')}
					</h4>
					<Col lg="4">
						<div className="aplicacion-formulario__vista-previa">
							<img alt="Header Avatar" className="mc-soft__imagen--circulo" src={usuarioFotoPerfil50x50} width="150px" />
						</div>
					</Col>
					<Col lg="8">
						<McCampoTexto
							campo="imagenPredeterminadaUsuarioFotoPerfil50x50"
							etiqueta={texto('50x50 pixeles')}
							id="imagenPredeterminadaUsuarioFotoPerfil50x50"
							informacion={<InformacionImagenPredeterminada />}
							longitudMaxima={1000}
						/>
					</Col>
					<Col lg="4">
						<div className="aplicacion-formulario__vista-previa">
							<img alt="Header Avatar" className="mc-soft__imagen--circulo" src={usuarioFotoPerfil100x100} width="150px" />
						</div>
					</Col>
					<Col lg="8">
						<McCampoTexto
							campo="imagenPredeterminadaUsuarioFotoPerfil100x100"
							etiqueta={texto('100x100 pixeles')}
							id="imagenPredeterminadaUsuarioFotoPerfil100x100"
							informacion={<InformacionImagenPredeterminada />}
							longitudMaxima={1000}
						/>
					</Col>
					<Col lg="4">
						<div className="aplicacion-formulario__vista-previa">
							<img alt="Header Avatar" className="mc-soft__imagen--circulo" src={usuarioFotoPerfil700x700} width="150px" />
						</div>
					</Col>
					<Col lg="8">
						<McCampoTexto
							campo="imagenPredeterminadaUsuarioFotoPerfil700x700"
							etiqueta={texto('700x700 pixeles')}
							id="imagenPredeterminadaUsuarioFotoPerfil700x700"
							informacion={<InformacionImagenPredeterminada />}
							longitudMaxima={1000}
						/>
					</Col>
				</Row>
				<hr />
				<Row>
					<h4>
						<i className={constantes.icono.licencia}></i> {texto('Licencia de perito predeterminada')}
					</h4>
					<Col lg="4">
						<div className="aplicacion-formulario__vista-previa">
							<img alt="Header Avatar" className="mc-soft__imagen--cuadrado" src={peritoFotoLicencia400x260} width="150px" />
						</div>
					</Col>
					<Col lg="8">
						<McCampoTexto
							campo="imagenPredeterminadaPeritoLicencia"
							etiqueta={texto('400x260 pixeles')}
							id="imagenPredeterminadaPeritoLicencia"
							informacion={<InformacionImagenPredeterminada />}
							longitudMaxima={1000}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default AplicacionFormularioImagenes;
