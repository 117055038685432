import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

/**
 * Formulario para la configuración de datos de la aplicación.
 */
const AplicacionFormularioDatos = () => (
	<Row>
		<Col lg="12">
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.datos}></i> {texto('Recolección de datos')}
					</h3>
					<p>{texto('Aquí se configura la recolección de datos de la aplicación.')}</p>
				</Col>
			</Row>
			<hr />
			<Row>
				<h4>
					<i className={constantes.icono.usuarios}></i> {texto('Usuarios')}
				</h4>
				<h5>{texto('Contacto')}</h5>
				<Col sm="6">
					<McSwitchRedondo
						campo="datosUsuarioTelefonoObligatorio"
						etiqueta={texto('Teléfono obligatorio')}
						etiquetaLineal
						id="datosUsuarioTelefonoObligatorio"
						informacion={texto('Si se activa el teléfono será un campo obligatorio en el formulario del usuario.')}
					/>
				</Col>
				<Col sm="6">
					<McSwitchRedondo
						campo="datosUsuarioDomicilioObligatorio"
						etiqueta={texto('Domicilio obligatorio')}
						etiquetaLineal
						id="datosUsuarioDomicilioObligatorio"
						informacion={texto('Si se activa el llenar el domicilio será obligatorio en el formulario del usuario.')}
					/>
				</Col>
				<Col sm="6">
					<McSwitchRedondo
						campo="datosUsuarioCorreoEditable"
						etiqueta={texto('Correo electrónico editable')}
						etiquetaLineal
						id="datosUsuarioCorreoEditable"
						informacion={texto('Si se activa los usuarios podran cambiar su correo electrónico.')}
					/>
				</Col>
				<h5>{texto('Redes sociales')}</h5>
				<Col md="4" sm="6">
					<McSwitchRedondo
						campo="datosUsuarioFacebook"
						etiqueta={texto('Facebook')}
						etiquetaLineal
						iconoApagado={constantes.icono.facebook}
						iconoEncendido={constantes.icono.facebook}
						id="datosUsuarioFacebook"
						informacion={texto('Si se activa el usuario podrá guardar información de su dirección de Facebook.')}
					/>
				</Col>
				<Col md="4" sm="6">
					<McSwitchRedondo
						campo="datosUsuarioInstagram"
						etiqueta={texto('Instagram')}
						etiquetaLineal
						iconoApagado={constantes.icono.instagram}
						iconoEncendido={constantes.icono.instagram}
						id="datosUsuarioInstagram"
						informacion={texto('Si se activa el usuario podrá guardar información de su dirección de Instagram.')}
					/>
				</Col>
				<Col md="4" sm="6">
					<McSwitchRedondo
						campo="datosUsuarioLinkedIn"
						etiqueta={texto('LinkedIn')}
						etiquetaLineal
						iconoApagado={constantes.icono.linkedIn}
						iconoEncendido={constantes.icono.linkedIn}
						id="datosUsuarioLinkedIn"
						informacion={texto('Si se activa el usuario podrá guardar información de su dirección de LinkedIn.')}
					/>
				</Col>
				<Col md="4" sm="6">
					<McSwitchRedondo
						campo="datosUsuarioTwitter"
						etiqueta={texto('Twitter')}
						etiquetaLineal
						iconoApagado={constantes.icono.twitter}
						iconoEncendido={constantes.icono.twitter}
						id="datosUsuarioTwitter"
						informacion={texto('Si se activa el usuario podrá guardar información de su dirección de Twitter.')}
					/>
				</Col>
				<Col md="4" sm="6">
					<McSwitchRedondo
						campo="datosUsuarioYouTube"
						etiqueta={texto('YouTube')}
						etiquetaLineal
						iconoApagado={constantes.icono.youTube}
						iconoEncendido={constantes.icono.youTube}
						id="datosUsuarioYouTube"
						informacion={texto('Si se activa el usuario podrá guardar información de su dirección de YouTube.')}
					/>
				</Col>
			</Row>
		</Col>
	</Row>
);

export default AplicacionFormularioDatos;
