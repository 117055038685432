import { McRespuesta, procesarError, procesarRespuesta } from '@mcsoft/api';
import aplicacion from 'configuracion/aplicacion';
import axios from 'axios';
import mcLogger from '@mcsoft/logger';
import UsuarioRol from 'modelo/UsuarioRol';

const NOMBRE_CLASE = 'servicios/api/usuariosRoles';

/**
 * Actualiza un rol existente en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del rol a actualizar.
 * - ***valores*** - Objeto con los valores a actualizar.
 */
export const actualizarUsuarioRol = ({ auth0AccessToken, id, valores }: { auth0AccessToken: string; id: string; valores: UsuarioRol }): Promise<McRespuesta> => {
	const nombreMetodo = 'actualizarUsuarioRol';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Actualizando el registro con el id '${id}' en la base de datos con los siguientes valores:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: valores
		});
		axios
			.put(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.usuariosRoles}/${id}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al actualizar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Crea un rol en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***valores*** - Objeto con los valores del rol a crear.
 */
export const crearUsuarioRol = ({ auth0AccessToken, valores }: { auth0AccessToken: string; valores: UsuarioRol }): Promise<McRespuesta> => {
	const nombreMetodo = 'crearUsuarioRol';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Creando registro en la base de datos con los siguientes valores:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: valores });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosRoles}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear el registro:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Elimina un rol de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del rol a eliminar.
 */
export const eliminarUsuarioRol = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'eliminarUsuarioRol';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Eliminando el registro con el id '${id}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.delete(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.usuariosRoles}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' eliminado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al eliminar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene un rol específico de la base de datos a través de su id.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del rol a obtener.
 */
export const obtenerUsuarioRolPorId = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioRolPorId';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo registro con el id '${id}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosRoles}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' obtenido con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Filtra y ordena los roles y los separa en páginas según los parámetros recibidos y obtiene la lista de roles de la página especificada.
 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerUsuarioRolesPaginados = ({
	auth0AccessToken,
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	auth0AccessToken: string;
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioRolesPaginados';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo registros de la base de datos con los siguientes parámetros:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: {
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			}
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosRoles}/paginados
			?pagina=${pagina}
			&registrosPorPagina=${registrosPorPagina}
			&ordenamiento=${ordenamiento}
			&orden=${orden}
			&criterio=${criterio}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene la lista de todos los roles de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerUsuarioRolesTodos = (auth0AccessToken: string): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioRolesTodos';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo todos los registros de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosRoles}/todos
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener todos los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Válida si el valor de un campo único está disponible.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***campo*** - Nombre del campo a validar.
 * - ***id*** - Id del rol para permitir actualización.
 * - ***valor*** - Valor del campo a validar.
 */
export const validarUsuarioRolDisponibilidad = ({ auth0AccessToken, campo, id, valor }: { auth0AccessToken: string; campo: string; id: string; valor: any }): Promise<boolean> => {
	const nombreMetodo = 'validarUsuarioRolDisponibilidad';
	return new Promise((resolve, reject) => {
		const valores = { campo, id, valor };
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Validando disponibilidad del valor '${valor}' para el campo '${campo}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosRoles}/validarDisponibilidad
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				if (respuesta.data) {
					mcLogger.api({ mensaje: `El valor '${valor}' para el campo '${campo}' esta disponible.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				} else {
					mcLogger.api({ mensaje: `El valor '${valor}' para el campo '${campo}' no esta disponible.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				}
				resolve(respuesta.data);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al validar el registro:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Válida si el rol puede eliminarse.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del rol a eliminar.
 */
export const validarUsuarioRolEliminacion = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<boolean> => {
	const nombreMetodo = 'validarUsuarioRolEliminacion';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Validando si es posible eliminar el rol '${id}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosRoles}/validarEliminacion/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				if (respuesta.data) {
					mcLogger.api({ mensaje: `El rol '${id}' puede ser eliminado.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				} else {
					mcLogger.api({ mensaje: `El rol '${id}' no puede ser eliminado.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				}
				resolve(respuesta.data);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al validar la eliminación del rol '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};
