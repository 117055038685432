import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

interface RolFormularioPermisosAplicacionProps {
	/**
	 * Indica si el formulario será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Formulario para los permisos relacionados a los peritos.
 */
const RolFormularioPermisosPeritos = (props: RolFormularioPermisosAplicacionProps) => {
	const { soloLectura = false } = props;

	return (
		<Row>
			<Col lg="12">
				<McSwitchRedondo
					campo={constantes.permiso.appPeritosVerLista}
					etiqueta={texto('Ver la lista de sus peritos.')}
					etiquetaLineal
					id={constantes.permiso.appPeritosVerLista}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionPeritosVerLista}
					etiqueta={texto('Ver la lista de todos los peritos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionPeritosVerLista}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appPeritosVerDetalles}
					etiqueta={texto('Ver los detalles de sus peritos.')}
					etiquetaLineal
					id={constantes.permiso.appPeritosVerDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionPeritosVerDetalles}
					etiqueta={texto('Ver los detalles de todos los peritos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionPeritosVerDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appPeritosEditarDetalles}
					etiqueta={texto('Editar los detalles de sus peritos.')}
					etiquetaLineal
					id={constantes.permiso.appPeritosEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionPeritosEditarDetalles}
					etiqueta={texto('Editar los detalles de todos los peritos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionPeritosEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appPeritosEditarLicencia}
					etiqueta={texto('Editar la licencia de sus peritos.')}
					etiquetaLineal
					id={constantes.permiso.appPeritosEditarLicencia}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionPeritosEditarLicencia}
					etiqueta={texto('Editar la licencia de todos los peritos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionPeritosEditarLicencia}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appPeritosCrear}
					etiqueta={texto('Crear peritos nuevos.')}
					etiquetaLineal
					id={constantes.permiso.appPeritosCrear}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appPeritosEliminar}
					etiqueta={texto('Eliminar sus peritos.')}
					etiquetaLineal
					id={constantes.permiso.appPeritosEliminar}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionPeritosEliminar}
					etiqueta={texto('Eliminar todos los peritos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionPeritosEliminar}
					soloLectura={soloLectura}
				/>
			</Col>
		</Row>
	);
};

export default RolFormularioPermisosPeritos;
