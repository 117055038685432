import { Redirect, Switch } from 'react-router-dom';
import aplicacion from 'configuracion/aplicacion';
import constantes from 'configuracion/constantes';
import InicioWeb from 'paginas/web/InicioWeb';
import ManejadorErrores from 'componentes/comun/ManejadorErrores';
import mcLogger from '@mcsoft/logger';
import { RutaPublica } from 'util/mc-utils/mc-autenticacion';
import { StateType } from 'store';
import TemaWeb from 'componentes/TemaWeb';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const NOMBRE_CLASE = 'RutasWeb';

/**
 * Contiene la estructura principal de la página web, la cual será utilizada por todo los usuarios.
 */
const RutasWeb = () => {
	const { nombreAplicacion } = useSelector((state: StateType) => state.configuracionSistema);

	useEffect(() => {
		const nombreMetodo = 'useEffect';
		const nombreApp = `${nombreAplicacion} | ${aplicacion.nombreSeccionWeb}`;
		mcLogger.dev({ mensaje: `Cambiando nombre de la aplicación [${nombreAplicacion}].`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		document.title = nombreApp;
	}, [nombreAplicacion]);

	return (
		<ManejadorErrores>
			<TemaWeb>
				<Switch>
					<RutaPublica component={InicioWeb} path={constantes.ruta.webInicio} />
					<Redirect from="/*" to={constantes.ruta.webInicio} />
				</Switch>
			</TemaWeb>
		</ManejadorErrores>
	);
};

export default RutasWeb;
