import { Col, Row } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import McGaleriaOrdenable, { McGaleriaOrdenableElemento } from '@mcsoft/galeria-ordenable';
import { setVisorImagenImagenesAction, setVisorImagenMostrarAction } from 'store/actions';
import Avaluo from 'modelo/Avaluo';
import { texto } from 'idiomas';
import { useDispatch } from 'react-redux';
import { useObtenerAvaluoFoto } from 'hooks/useImagen';

interface AvaluoDetallesFotosProps {
	/**
	 * Objeto con la información del avalúo.
	 */
	avaluo: Avaluo;
}

/**
 * Detalles para la sección de fotos del avalúo.
 */
const AvaluoDetallesFotos = (props: AvaluoDetallesFotosProps) => {
	const { avaluo } = props;
	const dispatch = useDispatch();
	const obtenerAvaluoFoto = useObtenerAvaluoFoto();
	const [listaGaleria, setListaGaleria] = useState<Array<McGaleriaOrdenableElemento>>([]);

	useEffect(() => {
		const listaFotos: Array<McGaleriaOrdenableElemento> = [];
		avaluo &&
			avaluo.fotos &&
			avaluo.fotos.forEach((foto) => {
				listaFotos.push({
					id: foto.id,
					url: obtenerAvaluoFoto({ foto }),
					urlMiniatura: obtenerAvaluoFoto({ foto, miniatura: true })
				});
			});
		setListaGaleria(listaFotos);
	}, [avaluo]);

	if (avaluo && avaluo.fotos) {
		/**
		 * Setea la foto en el state de Redux para ser visible en el visor de imágenes.
		 * - ***indice*** - Indice de la imagen.
		 */
		const eventoVerImagen = (indice: number) => {
			const lista = [...listaGaleria];
			const listaParte1 = lista.slice(indice, lista.length);
			const listaParte2 = lista.slice(0, indice);
			const listaNueva = [...listaParte1, ...listaParte2];
			dispatch(setVisorImagenImagenesAction(listaNueva));
			dispatch(setVisorImagenMostrarAction(true));
		};

		return (
			<Fragment>
				<Row>
					<Col lg="12">
						<h3>{texto('Reporte Fotográfico')}</h3>
						<hr />
					</Col>
				</Row>
				{listaGaleria.length > 0 && (
					<Row>
						<Col lg="12">
							<McGaleriaOrdenable eventoVerImagen={eventoVerImagen} lista={listaGaleria} />
						</Col>
					</Row>
				)}
				{listaGaleria.length === 0 && (
					<Row>
						<Col lg="12">
							<div className="alert alert-warning" role="alert">
								<div className="avaluos-formulario__contenedor-fotos-icono">
									<i className="fa-regular fa-image-slash"></i>
								</div>
								<div className="avaluos-formulario__contenedor-fotos-texto">{texto('Sin fotos')}</div>
							</div>
						</Col>
					</Row>
				)}
			</Fragment>
		);
	} else {
		return null;
	}
};

export default AvaluoDetallesFotos;
