import {
	SET_PARAMETROS_PAGINACION_AVALUOS,
	SET_PARAMETROS_PAGINACION_AVALUOS_ADMIN,
	SET_PARAMETROS_PAGINACION_CALIDADES_CONSTRUCCION,
	SET_PARAMETROS_PAGINACION_CLASIFICACIONES_ZONA,
	SET_PARAMETROS_PAGINACION_CONSTRUCCIONES_DOMINANTES,
	SET_PARAMETROS_PAGINACION_DESTINOS_ESPECIFICOS,
	SET_PARAMETROS_PAGINACION_ELEMENTOS_CONSTRUCCION,
	SET_PARAMETROS_PAGINACION_ESTADOS_CONSERVACION,
	SET_PARAMETROS_PAGINACION_MOTIVOS_AJUSTE,
	SET_PARAMETROS_PAGINACION_NOTIFICACIONES,
	SET_PARAMETROS_PAGINACION_PERITOS,
	SET_PARAMETROS_PAGINACION_PERITOS_ADMIN,
	SET_PARAMETROS_PAGINACION_REGIMENES_PROPIEDAD,
	SET_PARAMETROS_PAGINACION_SERVICIOS_MUNICIPALES,
	SET_PARAMETROS_PAGINACION_TIPOS_CALLE,
	SET_PARAMETROS_PAGINACION_TIPOS_CONSTRUCCION,
	SET_PARAMETROS_PAGINACION_TIPOS_INMUEBLE,
	SET_PARAMETROS_PAGINACION_UNIDADES_SUPERFICIE,
	SET_PARAMETROS_PAGINACION_USOS_ESPECIFICOS,
	SET_PARAMETROS_PAGINACION_USUARIO_NOTIFICACIONES,
	SET_PARAMETROS_PAGINACION_USUARIO_ROLES,
	SET_PARAMETROS_PAGINACION_USUARIOS,
	SET_PARAMETROS_PAGINACION_VIENTOS_CARDINALES
} from './actionTypes';
import mcLogger from '@mcsoft/logger';
import ParametrosPaginacionStore from 'modelo/ParametrosPaginacionStore';
import { ReduxAccion } from 'store/actions';

const NOMBRE_CLASE = 'store/parametrosPaginacion/reducer';

const stateInicial: ParametrosPaginacionStore = {
	avaluos: {
		criterio: '',
		orden: 'desc',
		ordenamiento: '[["folio"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	avaluosAdmin: {
		criterio: '',
		orden: 'desc',
		ordenamiento: '[["folio"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	calidadesConstruccion: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	clasificacionesZona: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	construccionesDominantes: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	destinosEspecificos: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	elementosConstruccion: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	estadosConservacion: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	motivosAjuste: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	notificaciones: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["titulo"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	peritos: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 5
	},
	peritosAdmin: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 5
	},
	regimenesPropiedad: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	serviciosMunicipales: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	tiposCalle: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	tiposConstruccion: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	tiposInmueble: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	unidadesSuperficie: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	usosEspecificos: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	usuarioNotificaciones: {
		criterio: '',
		orden: 'desc',
		ordenamiento: '[["fechaCreacion"]]',
		pagina: 1,
		registrosPorPagina: 10
	},
	usuarioRoles: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	},
	usuarios: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombreUsuario"]]',
		pagina: 1,
		registrosPorPagina: 10
	},
	vientosCardinales: {
		criterio: '',
		orden: 'asc',
		ordenamiento: '[["nombre"]]',
		pagina: 1,
		registrosPorPagina: 20
	}
};

export default (state = stateInicial, action: ReduxAccion) => {
	switch (action.type) {
		case SET_PARAMETROS_PAGINACION_AVALUOS:
			const { parametrosPaginacion: parametrosPaginacionAvaluos } = action.payload;
			state = {
				...state,
				avaluos: parametrosPaginacionAvaluos
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.avaluos":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_AVALUOS,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_AVALUOS_ADMIN:
			const { parametrosPaginacion: parametrosPaginacionAvaluosAdmin } = action.payload;
			state = {
				...state,
				avaluosAdmin: parametrosPaginacionAvaluosAdmin
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.avaluosAdmin":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_AVALUOS_ADMIN,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_CALIDADES_CONSTRUCCION:
			const { parametrosPaginacion: parametrosPaginacionCalidadesConstruccion } = action.payload;
			state = {
				...state,
				calidadesConstruccion: parametrosPaginacionCalidadesConstruccion
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.calidadesConstruccion":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_CALIDADES_CONSTRUCCION,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_CLASIFICACIONES_ZONA:
			const { parametrosPaginacion: parametrosPaginacionClasificacionesZona } = action.payload;
			state = {
				...state,
				clasificacionesZona: parametrosPaginacionClasificacionesZona
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.clasificacionesZona":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_CLASIFICACIONES_ZONA,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_CONSTRUCCIONES_DOMINANTES:
			const { parametrosPaginacion: parametrosPaginacionConstruccionesDominantes } = action.payload;
			state = {
				...state,
				construccionesDominantes: parametrosPaginacionConstruccionesDominantes
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.construccionesDominantes":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_CONSTRUCCIONES_DOMINANTES,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_DESTINOS_ESPECIFICOS:
			const { parametrosPaginacion: parametrosPaginacionDestinosEspecificos } = action.payload;
			state = {
				...state,
				destinosEspecificos: parametrosPaginacionDestinosEspecificos
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.destinosEspecificos":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_DESTINOS_ESPECIFICOS,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_ELEMENTOS_CONSTRUCCION:
			const { parametrosPaginacion: parametrosPaginacionElementosConstruccion } = action.payload;
			state = {
				...state,
				elementosConstruccion: parametrosPaginacionElementosConstruccion
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.elementosConstruccion":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_ELEMENTOS_CONSTRUCCION,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_ESTADOS_CONSERVACION:
			const { parametrosPaginacion: parametrosPaginacionEstadosConservacion } = action.payload;
			state = {
				...state,
				estadosConservacion: parametrosPaginacionEstadosConservacion
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.estadosConservacion":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_ESTADOS_CONSERVACION,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_MOTIVOS_AJUSTE:
			const { parametrosPaginacion: parametrosPaginacionMotivosAjuste } = action.payload;
			state = {
				...state,
				motivosAjuste: parametrosPaginacionMotivosAjuste
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.motivosAjuste":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_MOTIVOS_AJUSTE,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_NOTIFICACIONES:
			const { parametrosPaginacion: parametrosPaginacionNotificaciones } = action.payload;
			state = {
				...state,
				notificaciones: parametrosPaginacionNotificaciones
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.notificaciones":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_NOTIFICACIONES,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_PERITOS:
			const { parametrosPaginacion: parametrosPaginacionPeritos } = action.payload;
			state = {
				...state,
				peritos: parametrosPaginacionPeritos
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.peritos":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_PERITOS,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_PERITOS_ADMIN:
			const { parametrosPaginacion: parametrosPaginacionPeritosAdmin } = action.payload;
			state = {
				...state,
				peritos: parametrosPaginacionPeritosAdmin
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.peritosAdmin":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_PERITOS_ADMIN,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_REGIMENES_PROPIEDAD:
			const { parametrosPaginacion: parametrosPaginacionRegimenesPropiedad } = action.payload;
			state = {
				...state,
				regimenesPropiedad: parametrosPaginacionRegimenesPropiedad
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.regimenesPropiedad":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_REGIMENES_PROPIEDAD,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_SERVICIOS_MUNICIPALES:
			const { parametrosPaginacion: parametrosPaginacionServiciosMunicipales } = action.payload;
			state = {
				...state,
				serviciosMunicipales: parametrosPaginacionServiciosMunicipales
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.serviciosMunicipales":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_SERVICIOS_MUNICIPALES,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_TIPOS_CALLE:
			const { parametrosPaginacion: parametrosPaginacionTiposCalle } = action.payload;
			state = {
				...state,
				tiposCalle: parametrosPaginacionTiposCalle
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.tiposCalle":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_TIPOS_CALLE,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_TIPOS_CONSTRUCCION:
			const { parametrosPaginacion: parametrosPaginacionTiposConstruccion } = action.payload;
			state = {
				...state,
				tiposConstruccion: parametrosPaginacionTiposConstruccion
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.tiposConstruccion":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_TIPOS_CONSTRUCCION,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_TIPOS_INMUEBLE:
			const { parametrosPaginacion: parametrosPaginacionTipoInmueble } = action.payload;
			state = {
				...state,
				tiposInmueble: parametrosPaginacionTipoInmueble
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.tipoInmueble":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_TIPOS_INMUEBLE,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_UNIDADES_SUPERFICIE:
			const { parametrosPaginacion: parametrosPaginacionUnidadesSuperficie } = action.payload;
			state = {
				...state,
				unidadesSuperficie: parametrosPaginacionUnidadesSuperficie
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.unidadesSuperficie":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_UNIDADES_SUPERFICIE,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_USOS_ESPECIFICOS:
			const { parametrosPaginacion: parametrosPaginacionUsosEspecificos } = action.payload;
			state = {
				...state,
				usosEspecificos: parametrosPaginacionUsosEspecificos
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.usosEspecificos":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_USOS_ESPECIFICOS,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_USUARIO_NOTIFICACIONES:
			const { parametrosPaginacion: parametrosPaginacionUsuarioNotificaciones } = action.payload;
			state = {
				...state,
				usuarioNotificaciones: parametrosPaginacionUsuarioNotificaciones
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.usuarioNotificaciones":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_USUARIO_NOTIFICACIONES,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_USUARIO_ROLES:
			const { parametrosPaginacion: parametrosPaginacionUsuarioRoles } = action.payload;
			state = {
				...state,
				usuarioRoles: parametrosPaginacionUsuarioRoles
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.usuarioRoles":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_USUARIO_ROLES,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_USUARIOS:
			const { parametrosPaginacion: parametrosPaginacionUsuarios } = action.payload;
			state = {
				...state,
				usuarios: parametrosPaginacionUsuarios
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.usuarios":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_USUARIOS,
				objetoExtra: state
			});
			break;
		case SET_PARAMETROS_PAGINACION_VIENTOS_CARDINALES:
			const { parametrosPaginacion: parametrosPaginacionVientosCardinales } = action.payload;
			state = {
				...state,
				vientosCardinales: parametrosPaginacionVientosCardinales
			};
			mcLogger.redux({
				mensaje: 'Seteando state "listaRegistros.vientosCardinales":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PARAMETROS_PAGINACION_VIENTOS_CARDINALES,
				objetoExtra: state
			});
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
