import './UsuarioTarjeta.scss';
import { Col, Row, Tooltip } from 'reactstrap';
import { Fragment, useState } from 'react';
import { setVisorImagenImagenesAction, setVisorImagenMostrarAction } from 'store/actions';
import constantes from 'configuracion/constantes';
import DomicilioTarjeta from 'componentes/domicilio/DomicilioTarjeta';
import InformacionCorreoVerificacion from 'componentes/informacion/InformacionCorreoVerificacion';
import { McVisorImagenElemento } from '@mcsoft/visor-imagen';
import { nombreCompletoUsuario } from 'util/modelo/usuarios';
import { texto } from 'idiomas';
import { useDispatch } from 'react-redux';
import { useObtenerUsuarioFoto } from 'hooks/useImagen';
import Usuario from 'modelo/Usuario';

interface UsuarioTarjetaProps {
	/**
	 * Objeto con la información del registro a mostrar.
	 */
	registro: Usuario;
	/**
	 * Objeto con la información del usuario en sesión.
	 */
	usuario: Usuario;
}

/**
 * Muestra una tarjeta con la información de un usuario.
 */
const UsuarioTarjeta = (props: UsuarioTarjetaProps) => {
	const dispatch = useDispatch();
	const obtenerFotoPerfil = useObtenerUsuarioFoto();
	const [mostrarTooltip, setMostrarTooltip] = useState(false);
	const { registro, usuario } = props;
	const {
		correoElectronico,
		correoElectronicoVerificado,
		domicilio,
		domicilioEntrega,
		domicilioFacturacion,
		nombreUsuario,
		rol,
		telefono,
		urlFacebook,
		urlInstagram,
		urlLinkedIn,
		urlTwitter,
		urlYouTube
	} = registro;

	/**
	 * Setea la imagen en el estado de Redux para ser usada en el visor de imágenes.
	 */
	const eventoVerImagen = () => {
		const imagen: McVisorImagenElemento = {
			descripcion: texto('Foto de perfil'),
			id: `${registro.id}_foto-perfil`,
			titulo: nombreCompletoUsuario(registro),
			url: obtenerFotoPerfil({ usuario: registro })
		};
		dispatch(setVisorImagenImagenesAction([imagen]));
		dispatch(setVisorImagenMostrarAction(true));
	};

	if (registro) {
		return (
			<Fragment>
				{!correoElectronicoVerificado && usuario.id === registro.id && (
					<Tooltip isOpen={mostrarTooltip} placement="right" target="correo_tooltip" toggle={() => setMostrarTooltip(!mostrarTooltip)}>
						{InformacionCorreoVerificacion}
					</Tooltip>
				)}
				<Row>
					<Col lg="4" md="3" sm="12" xl="3">
						<div className="usuario-tarjeta__foto-perfil-contenedor">
							<img
								alt="Foto de perfil"
								className="mc-soft__imagen--circulo usuario-tarjeta__foto-perfil"
								onClick={eventoVerImagen}
								src={obtenerFotoPerfil({ ancho: '700', usuario: registro })}
							/>
						</div>
					</Col>
					<Col lg="8" md="9" sm="12" xl="9">
						<div className="usuario-tarjeta__nombre">
							<h3>{nombreUsuario}</h3>
						</div>
						<div className="usuario-tarjeta__informacion">
							<p>{nombreCompletoUsuario(registro)}</p>
							<p className={correoElectronicoVerificado ? 'text-success' : 'text-danger'}>
								{' '}
								<i className={constantes.icono.correoElectronico}></i> {correoElectronico}&nbsp;
								<span
									className={`badge rounded-pill ${correoElectronicoVerificado ? 'bg-success' : 'bg-danger'}`}
									id={`${!correoElectronicoVerificado && usuario.id === registro.id ? 'correo_tooltip' : ''}`}
								>
									{correoElectronicoVerificado ? texto('Verificado') : texto('Sin verificar')}
								</span>
							</p>
							{telefono && (
								<p>
									<i className={constantes.icono.telefono}></i> {telefono}
								</p>
							)}
							{rol && (
								<p>
									<i className={constantes.icono.rol}></i> {rol.nombre}
								</p>
							)}
						</div>
						<div className="usuario-tarjeta__red-social">
							<p>
								{urlFacebook && (
									<a className="btn btn-outline-facebook usuario-tarjeta__red-social-boton" href={urlFacebook} title="Facebook" type="button">
										<i className={constantes.icono.facebook}></i>
									</a>
								)}
								{urlInstagram && (
									<a className="btn btn-outline-instagram usuario-tarjeta__red-social-boton" href={urlInstagram} title="Instagram" type="button">
										<i className={constantes.icono.instagram}></i>
									</a>
								)}
								{urlLinkedIn && (
									<a className="btn btn-outline-linkedin usuario-tarjeta__red-social-boton" href={urlLinkedIn} title="LinkedIn" type="button">
										<i className={constantes.icono.linkedIn}></i>
									</a>
								)}
								{urlTwitter && (
									<a className="btn btn-outline-twitter usuario-tarjeta__red-social-boton" href={urlTwitter} title="Twitter" type="button">
										<i className={constantes.icono.twitter}></i>
									</a>
								)}
								{urlYouTube && (
									<a className="btn btn-outline-youtube usuario-tarjeta__red-social-boton" href={urlYouTube} title="YouTube" type="button">
										<i className={constantes.icono.youTube}></i>
									</a>
								)}
							</p>
						</div>
						{domicilio && (
							<div className="usuario-tarjeta__domicilio">
								<hr />
								<DomicilioTarjeta domicilio={domicilio} />
							</div>
						)}
						{domicilioFacturacion && (
							<div className="usuario-tarjeta__domicilio">
								<DomicilioTarjeta domicilio={domicilioFacturacion} />
							</div>
						)}
						{domicilioEntrega && (
							<div className="usuario-tarjeta__domicilio">
								<DomicilioTarjeta domicilio={domicilioEntrega} />
							</div>
						)}
					</Col>
				</Row>
			</Fragment>
		);
	}
	return null;
};

export default UsuarioTarjeta;
