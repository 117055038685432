import './AplicacionFormulario.scss';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import AplicacionFormularioDatos from './AplicacionFormularioDatos';
import AplicacionFormularioGeneral from './AplicacionFormularioGeneral';
import AplicacionFormularioImagenes from './AplicacionFormularioImagenes';
import constantes from 'configuracion/constantes';
import { pestanaConErrores } from '@mcsoft/formulario';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { useFormState } from 'react-final-form';
import Usuario from 'modelo/Usuario';

const PestanaTipo = {
	DATOS: 'datos',
	GENERAL: 'general',
	IMAGENES: 'imagenes'
};

interface AplicacionFormularioProps {
	/**
	 * Objeto con la informacion del usuario.
	 */
	usuario: Usuario;
}

/**
 * Formulario para la configurtación de la aplicación.
 */
const AplicacionFormulario = (props: AplicacionFormularioProps) => {
	const { usuario } = props;
	const history = useHistory();
	const location = useLocation();
	const formularioValores = useFormState();
	const [pestanaActiva, setPestanaActiva] = useState(PestanaTipo.GENERAL);
	const [erroresPestanaGeneral, setErroresPestanaGeneral] = useState(false);
	const [erroresPestanaDatos, setErroresPestanaDatos] = useState(false);
	const [erroresPestanaImagenes, setErroresPestanaImagenes] = useState(false);

	useEffect(() => {
		const pestana = new URLSearchParams(location.search).get('pestana');
		let pestanaTipoInicial = '';
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionConfiguracionImagenes, usuario })) {
			pestanaTipoInicial = PestanaTipo.IMAGENES;
		}
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionConfiguracionDatos, usuario })) {
			pestanaTipoInicial = PestanaTipo.DATOS;
		}
		if (tienePermiso({ permiso: constantes.permiso.appAdministracionConfiguracionGeneral, usuario })) {
			pestanaTipoInicial = PestanaTipo.GENERAL;
		}
		setPestanaActiva(pestana ? pestana : pestanaTipoInicial);
	}, [location]);

	useEffect(() => {
		setErroresPestanaGeneral(
			pestanaConErrores({
				camposPestana: ['decimalLocale', 'momentFechaFormato', 'momentFechaLocale', 'momentFechaZona', 'monedaCodigo', 'monedaLocale', 'selectorFechaFormato'],
				errores: formularioValores.errors
			})
		);
		setErroresPestanaDatos(
			pestanaConErrores({
				camposPestana: [
					'datosUsuarioTelefonoObligatorio',
					'datosUsuarioDomicilioObligatorio',
					'datosUsuarioFacebook',
					'datosUsuarioInstagram',
					'datosUsuarioLinkedIn',
					'datosUsuarioTwitter',
					'datosUsuarioYouTube'
				],
				errores: formularioValores.errors
			})
		);
		setErroresPestanaImagenes(
			pestanaConErrores({
				camposPestana: [
					'imagenLogoOscuro1080x202',
					'imagenLogoClaro1080x202',
					'imagenLogoOscuro202x202',
					'imagenLogoClaro202x202',
					'imagenPredeterminadaUsuarioFotoPerfil50x50',
					'imagenPredeterminadaUsuarioFotoPerfil100x100',
					'imagenPredeterminadaUsuarioFotoPerfil700x700',
					'imagenPredeterminadaPeritoLicencia'
				],
				errores: formularioValores.errors
			})
		);
	}, [formularioValores.errors]);

	return (
		<div className="d-flex align-items-start">
			<div className="nav flex-column nav-pills me-3">
				{tienePermiso({ permiso: constantes.permiso.appAdministracionConfiguracionGeneral, usuario }) && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.GENERAL ? ' active' : ''} ${erroresPestanaGeneral ? 'link-danger' : ''}`}
						id="pestanaGeneral"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.GENERAL}`)}
						type="button"
					>
						{texto('General')}
					</button>
				)}
				{tienePermiso({ permiso: constantes.permiso.appAdministracionConfiguracionDatos, usuario }) && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.DATOS ? ' active' : ''} ${erroresPestanaDatos ? 'link-danger' : ''}`}
						id="pestanaDatos"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.DATOS}`)}
						type="button"
					>
						{texto('Datos')}
					</button>
				)}
				{tienePermiso({ permiso: constantes.permiso.appAdministracionConfiguracionImagenes, usuario }) && (
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.IMAGENES ? ' active' : ''} ${erroresPestanaImagenes ? 'link-danger' : ''}`}
						id="pestanaImagenes"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.IMAGENES}`)}
						type="button"
					>
						{texto('Imágenes')}
					</button>
				)}
			</div>
			<div className="tab-content aplicacion-formulario">
				{tienePermiso({ permiso: constantes.permiso.appAdministracionConfiguracionGeneral, usuario }) && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.GENERAL ? ' show active' : ''}`} id="contenidoGeneral">
						<AplicacionFormularioGeneral />
					</div>
				)}
				{tienePermiso({ permiso: constantes.permiso.appAdministracionConfiguracionDatos, usuario }) && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.DATOS ? ' show active' : ''}`} id="contenidoDatos">
						<AplicacionFormularioDatos />
					</div>
				)}
				{tienePermiso({ permiso: constantes.permiso.appAdministracionConfiguracionImagenes, usuario }) && (
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.IMAGENES ? ' show active' : ''}`} id="contenidoImagenes">
						<AplicacionFormularioImagenes />
					</div>
				)}
			</div>
		</div>
	);
};

export default AplicacionFormulario;
