import { actualizarUsuario, obtenerUsuarioPorId } from 'servicios/api/usuarios';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { esSysAdmin, tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { mutatorCambiarValorCampo, mutatorCopiarValorCampo, mutatorLimpiarValorCampo } from '@mcsoft/formulario';
import { tieneValor, validarFormularioDomicilio } from '@mcsoft/validaciones';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEsSeccionAdministracion, useEsSeccionPerfil } from 'hooks/useSeccion';
import { useHistory, useParams } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import { formatearUsuarioParaFormulario } from 'util/modelo/usuarios';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import Usuario from 'modelo/Usuario';
import UsuarioFormulario from 'componentes/usuarios/UsuarioFormulario';
import { ValidationErrors } from 'final-form';

const NOMBRE_CLASE = 'paginas/app/usuarios/UsuarioDetallesEditar';

/**
 * En esta página se pueden editar los detalles de un usuario.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const esSeccionPerfil = useEsSeccionPerfil();
	const { id } = useParams<any>();
	const { datosUsuarioCorreoEditable, datosUsuarioDomicilioObligatorio, datosUsuarioTelefonoObligatorio } = useSelector((state: StateType) => state.configuracionAplicacion);
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const [registro, setRegistro] = useState<any>();
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.usuarios);
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [rutaSalida, setRutaSalida] = useState<string>(`${constantes.ruta.appAdministracionUsuariosDetalles}/${id}`);
	const [enlaces, setEnlaces] = useState<any>([]);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, []);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, [location.pathname]);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Guarda la información del registro.
	 * - ***valores*** - Valores a guardar.
	 */
	const eventoGuardar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoGuardar';
		try {
			mcLogger.log({ mensaje: `Guardando la información del usuario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			const usuario = valores as Usuario;
			await actualizarUsuario({ auth0AccessToken, id: valores.id, valores: usuario });
			mcLogger.log({ mensaje: `Información del usuario guardada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
			mcNotificaciones.exito({ mensaje: texto(`Información del usuario guardada con éxito.`) });
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
			history.push(rutaSalida);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la información del usuario:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Válida el formulario de manera síncrona.
	 * - ***valores*** - Valores de los campos a validar.
	 */
	const eventoValidarFormulario = (valores: Record<string, any>): ValidationErrors => {
		const errores: ValidationErrors = {};
		if (!tieneValor(valores.telefono) && datosUsuarioTelefonoObligatorio) {
			errores.telefono = texto('Obligatorio');
		}
		errores.domicilio = validarFormularioDomicilio({ domicilio: valores.domicilio, obligatorio: datosUsuarioDomicilioObligatorio, texto });
		errores.domicilioFacturacion = validarFormularioDomicilio({ domicilio: valores.domicilioFacturacion, texto });
		errores.domicilioEntrega = validarFormularioDomicilio({ domicilio: valores.domicilioEntrega, texto });
		return errores;
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Obteniendo información del usuario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionAdministracion()) {
				const respuesta = await obtenerUsuarioPorId({ auth0AccessToken, id });
				const { datos } = respuesta;
				const registro = formatearUsuarioParaFormulario(datos);
				setRegistro(registro);
			}
			if (esSeccionPerfil()) {
				const respuesta = await obtenerUsuarioPorId({ auth0AccessToken, id: usuario.id });
				const { datos } = respuesta;
				const registro = formatearUsuarioParaFormulario(datos);
				setRegistro(registro);
			}
			mcLogger.log({ mensaje: `Información del usuario obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener el usuario: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los valores de las variables que cambian en función de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionAdministracion()) {
			setTituloSeccion(texto('Editar detalles del usuario'));
			const rutaSalida = `${constantes.ruta.appAdministracionUsuariosDetalles}/${id}`;
			const rutaSalidaLista = `${constantes.ruta.appAdministracionUsuariosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{
					ruta: rutaSalidaLista,
					titulo: texto('Lista de Usuarios')
				},
				{ ruta: rutaSalida, titulo: texto('Detalles del usuario') }
			]);
		}
		if (esSeccionPerfil()) {
			setTituloSeccion(texto('Editar mi perfil'));
			const rutaSalida = constantes.ruta.appUsuarioPerfil;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Mi perfil') }
			]);
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={constantes.icono.editarUsuario} titulo={tituloSeccion} />
			{registro && (
				<Form
					initialValues={registro}
					mutators={{
						cambiarValorCampo: mutatorCambiarValorCampo,
						copiarValorCampo: mutatorCopiarValorCampo,
						limpiarValorCampo: mutatorLimpiarValorCampo
					}}
					onSubmit={eventoGuardar}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Card>
								<CardBody>
									<Row>
										<Col md="12">
											<UsuarioFormulario
												correoElectronicoEditable={datosUsuarioCorreoEditable}
												detallesEditables={
													esSeccionPerfil() ||
													(esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionUsuariosEditarDetalles, usuario }) && !esSysAdmin(registro))
												}
												esSysAdmin={esSysAdmin(registro)}
												rolEditable={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesAsignar, usuario }) && !esSysAdmin(registro)}
											/>
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<BarraHerramientas>
										<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
											<i className={constantes.icono.atras}></i> {texto('Cancelar')}
										</button>
										<button className="btn btn-success" id="botonGuardar" type="submit">
											<i className={constantes.icono.guardar}></i> {texto('Guardar')}
										</button>
									</BarraHerramientas>
								</CardFooter>
							</Card>
						</form>
					)}
					validate={eventoValidarFormulario}
					validateOnBlur
				/>
			)}
		</Contenedor>
	);
};

export default Pagina;
