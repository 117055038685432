import Avaluo from 'modelo/Avaluo';
import disenos from './disenos';

// eslint-disable-next-line no-unused-vars
const seccion = ({ avaluo, paginaActual, paginas }: { avaluo?: Avaluo; paginaActual?: number; paginas?: number }) => [
	{
		layout: disenos.sinPaddingArribaAbajo,
		margin: [0, 0, 0, 0],
		style: 'piePagina',
		table: {
			body: [
				[
					{
						border: [0, 0, 0, 0],
						text: ''
					},
					{
						border: [0, 0, 0, 1],
						margin: [0, 0, 0, 0],
						style: { alignment: 'center', bold: true },
						text: ''
					},
					{
						border: [0, 0, 0, 0],
						text: ''
					},
					{
						border: [0, 0, 0, 0],
						margin: [0, 0, 0, 0],
						style: { alignment: 'center', bold: true },
						text: ''
					},
					{
						border: [0, 0, 0, 0],
						text: ''
					}
				],
				[
					{
						border: [0, 0, 0, 0],
						text: ''
					},
					{
						border: [0, 0, 0, 0],
						margin: [0, 0, 0, 0],
						style: { alignment: 'center', bold: true },
						text: 'Firma del Perito'
					},
					{
						border: [0, 0, 0, 0],
						text: ''
					},
					{
						border: [0, 0, 0, 0],
						margin: [0, 0, 0, 0],
						style: { alignment: 'center', bold: true },
						text: paginaActual === paginas ? 'Firma y Sello de Autorización' : ''
					},
					{
						border: [0, 0, 0, 0],
						text: ''
					}
				]
			],
			widths: [40, 200, '*', 200, 40]
		}
	}
];

export default seccion;
