/**
 * Convierte el código hexadecimal de un color a rgba y lo regresa como una cadena con el formato *'rgba(#,#,#,#)'*.
 * - ***alfa*** - Valor del alfa que se aplicara al color rgba.
 * - ***hexadecimal*** - Código hexadecimal del color.
 *
 * > ***Predeterminado:*** *1*
 * > - 0-1
 */
export const convertirHexadecimalRgba = ({ alfa = 1, hexadecimal }: { alfa?: number; hexadecimal: string }): string => {
	let color;
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hexadecimal)) {
		color = hexadecimal.substring(1).split('');
		if (color.length == 3) {
			color = [color[0], color[0], color[1], color[1], color[2], color[2]];
		}
		color = `0x${color.join('')}`;
		return `rgba(${[((color as any) >> 16) & 255, ((color as any) >> 8) & 255, (color as any) & 255].join(',')},${alfa})`;
	}
	return 'rgba(0,0,0,1)';
};

/**
 * Elimina acentos y signos diacríticos de una cadena de texto.
 * - ***cadena*** - Cadena de texto original.
 */
export const eliminarDiacriticos = (cadena?: string): string => {
	if (cadena) {
		const cadenaFinal = cadena.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
		return cadenaFinal;
	} else {
		return '';
	}
};

/**
 * Escapa los apostrofos (comilla simple) de una cadena de texto con la barra invertida.
 * - ***cadena*** - Cadena de texto original.
 */
export const escaparApostrofo = (cadena?: string): string => {
	if (cadena) {
		const cadenaFinal = cadena.replace(/'/g, "\\'");
		return cadenaFinal;
	} else {
		return '';
	}
};

/**
 * Formatea un telefono con guiones.
 * - ***telefono*** - Cadena de texto original.
 */
export const formatearTelefono = (telefono?: string): string => {
	if (telefono) {
		const digitos123 = telefono.slice(0, 3);
		const digitos456 = telefono.slice(3, 6);
		const digitos78 = telefono.slice(6, 8);
		const digitos90 = telefono.slice(8);
		return `${digitos123}-${digitos456}-${digitos78}-${digitos90}`;
	} else {
		return '';
	}
};

/**
 * Convierte las posibles variantes de los estatus de requerimiento de un campo y la regresa normalizada.
 * - ***cadena*** - Cadena de texto original.
 *
 * > **No:** Resultado de recibir.
 * > - 'NO'
 * > - 'No'
 * > - 'no'
 *
 * > **Obligatorio:** Resultado de recibir.
 * > - 'OBLIGATORIO'
 * > - 'Obligatorio'
 * > - 'obligatorio'
 *
 * > **Opcional:** Resultado de recibir.
 * > - 'OPCIONAL'
 * > - 'Opcional'
 * > - 'opcional'
 *
 * > **Requerido:** Resultado de recibir.
 * > - 'REQUERIDO'
 * > - 'Requerido'
 * > - 'requerido'
 *
 * > **Sí:** Resultado de recibir.
 * > - 'SI'
 * > - 'SÍ'
 * > - 'Si'
 * > - 'Sí'
 * > - 'si'
 * > - 'sí'
 */
export const normalizarRequerido = (cadena: string): string => {
	if (cadena) {
		cadena = cadena
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.toLowerCase();
		const cadenaFinal =
			cadena === 'no'
				? 'No'
				: cadena === 'obligatorio'
				? 'Obligatorio'
				: cadena === 'opcional'
				? 'Opcional'
				: cadena === 'requerido'
				? 'Requerido'
				: cadena === 'si'
				? 'Sí'
				: cadena;
		return cadenaFinal;
	} else {
		return cadena;
	}
};

/**
 * Rellena una cadena con caracteres al final hasta alcanzar longitud requerida.
 * - ***cadena*** - Cadena de texto original.
 * - ***caracter?*** - Carácter con el que se rellenara la cadena.
 * - ***longitud*** - Longitud final de la cadena.
 *
 * > ***Predeterminado:*** *0*
 */
export const rellenarConCaracteresFinal = ({ cadena, caracter = '0', longitud }: { cadena: string; caracter?: string; longitud: number }): string => {
	cadena = `${cadena}`;
	const cadenaFinal = cadena.length >= longitud + 1 ? cadena : cadena + new Array(longitud + 1 - cadena.length).join(caracter);
	return cadenaFinal;
};

/**
 * Rellena una cadena con caracteres al inicio hasta alcanzar longitud requerida.
 * - ***cadena*** - Cadena de texto original.
 * - ***caracter?*** - Carácter con el que se rellenara la cadena.
 * - ***longitud*** - Longitud final de la cadena.
 *
 * > ***Predeterminado:*** *0*
 */
export const rellenarConCaracteresInicio = ({ cadena, caracter = '0', longitud }: { cadena: string; caracter?: string; longitud: number }): string => {
	cadena = `${cadena}`;
	const cadenaFinal = cadena.length >= longitud + 1 ? cadena : new Array(longitud + 1 - cadena.length).join(caracter) + cadena;
	return cadenaFinal;
};

/**
 * Formatea el valor con la cantidad de decimales requerida.
 * - ***decimales*** - Cantidad de decimales a rellenar.
 * - ***valor*** - Valor a formatear.
 */
export const rellenarDecimalesConCeros = ({ decimales, valor }: { decimales: number; valor: string }): string => {
	const valorArreglo = valor.split('.');
	let valorFinal = '';
	if (valorArreglo.length > 1) {
		valorFinal = valorArreglo[0].concat('.').concat(rellenarConCaracteresFinal({ cadena: valorArreglo[1], longitud: decimales }));
	} else {
		valorFinal = valorArreglo[1].concat('.').concat(rellenarConCaracteresFinal({ cadena: '', longitud: decimales }));
	}
	return valorFinal;
};

/**
 * Compara dos cadenas y regresa verdadero si son diferentes.
 * - ***cadena1*** - Primer cadena a comparar.
 * - ***cadena2*** - Segunda cadena a comparar.
 */
export const sonCadenasDiferentes = (cadena1: string | null | undefined, cadena2: string | null | undefined): boolean => {
	let cadenaFinal1 = cadena1;
	let cadenaFinal2 = cadena2;
	if (!tieneValor(cadena1)) {
		cadenaFinal1 = '';
	}
	if (!tieneValor(cadena2)) {
		cadenaFinal2 = '';
	}
	if (cadenaFinal1 !== cadenaFinal2) return true;
	return false;
};

/**
 * Compara dos fechas y regresa verdadero si son diferentes.
 * - ***fecha1*** - Primer fecha a comparar.
 * - ***fecha2*** - Segunda fecha a comparar.
 */
export const sonFechasDiferentes = (fecha1: Object | string | null | undefined, fecha2: Object | string | null | undefined): boolean => {
	let fechaFinal1;
	let fechaFinal2;
	if (!tieneValor(fecha1)) {
		fechaFinal1 = '';
	}
	if (!tieneValor(fecha2)) {
		fechaFinal2 = '';
	}
	if (typeof fecha1 === 'object') {
		if (Array.isArray(fecha1) && fecha1.length === 1) {
			fechaFinal1 = new Date(fecha1[0]).toString();
		}
	}
	if (typeof fecha1 === 'string') {
		fechaFinal1 = new Date(fecha1).toString();
	}
	if (typeof fecha2 === 'object') {
		if (Array.isArray(fecha2) && fecha2.length === 1) {
			fechaFinal2 = new Date(fecha2[0]).toString();
		}
	}
	if (typeof fecha2 === 'string') {
		fechaFinal2 = new Date(fecha2).toString();
	}
	if (fechaFinal1 !== fechaFinal2) return true;
	return false;
};

/**
 * Compara dos números y regresa verdadero si son diferentes.
 * - ***numero1*** - Primer número a comparar.
 * - ***numero2*** - Segunda número a comparar.
 */
export const sonNumerosDiferentes = (numero1: number | null | undefined, numero2: number | null | undefined): boolean => {
	let numeroFinal1 = numero1;
	let numeroFinal2 = numero2;
	if (!tieneValor(numero1)) {
		numeroFinal1 = null;
	}
	if (!tieneValor(numero2)) {
		numeroFinal2 = null;
	}
	if (numeroFinal1 !== numeroFinal2) return true;
	return false;
};

/**
 * Indica si la variable recibida tiene un valor (No es *null*, *undefined* ni cadena vacía).
 * - ***valor*** - Valor a analizar.
 */
const tieneValor = (valor: any): boolean => {
	if (valor === undefined) {
		return false;
	}
	if (valor === null) {
		return false;
	}
	if (valor === '') {
		return false;
	}
	return true;
};
