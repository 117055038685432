import { useHistory } from 'react-router-dom';

/**
 * Regresa verdadero si la sección es de administración.
 */
export const useEsSeccionAdministracion = () => {
	const history = useHistory();
	const { location } = history;
	return () => location.pathname.includes('administracion');
};

/**
 * Regresa verdadero si la sección es de creación.
 */
export const useEsSeccionCreacion = () => {
	const history = useHistory();
	const { location } = history;
	return () => location.pathname.includes('nuevo');
};

/**
 * Regresa verdadero si la sección es de edición.
 */
export const useEsSeccionEdicion = () => {
	const history = useHistory();
	const { location } = history;
	return () => location.pathname.includes('editar');
};

/**
 * Regresa verdadero si la sección es de perfil.
 */
export const useEsSeccionPerfil = () => {
	const history = useHistory();
	const { location } = history;
	return () => location.pathname.includes('perfil');
};

/**
 * Regresa verdadero si la sección es de preferencias.
 */
export const useEsSeccionPreferencias = () => {
	const history = useHistory();
	const { location } = history;
	return () => location.pathname.includes('preferencias');
};
