import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

interface RolFormularioPermisosUsuariosProps {
	/**
	 * Indica si el formulario será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Formulario para los permisos relacionados a los usuarios.
 */
const RolFormularioPermisosUsuarios = (props: RolFormularioPermisosUsuariosProps) => {
	const { soloLectura = false } = props;

	return (
		<Row>
			<Col lg="12">
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionUsuariosVerLista}
					etiqueta={texto('Ver la lista de los usuarios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionUsuariosVerLista}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionUsuariosVerDetalles}
					etiqueta={texto('Ver los detalles de los usuarios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionUsuariosVerDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionUsuariosEditarDetalles}
					etiqueta={texto('Editar los detalles de los usuarios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionUsuariosEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionUsuariosEditarCorreoElectronico}
					etiqueta={texto('Editar el correo electrónico de los usuarios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionUsuariosEditarCorreoElectronico}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionUsuariosEditarFoto}
					etiqueta={texto('Editar la foto de perfil de los usuarios.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionUsuariosEditarFoto}
					soloLectura={soloLectura}
				/>
			</Col>
		</Row>
	);
};

export default RolFormularioPermisosUsuarios;
