import Avaluo from 'modelo/Avaluo';
import disenos from './disenos';
import ListaOpcion from 'modelo/ListaOpcion';

const seccion = ({
	avaluo,
	mostrarCoordenadasDD,
	mostrarCoordenadasDMS,
	mostrarCoordenadasUTM
}: {
	avaluo: Avaluo;
	mostrarCoordenadasDD: boolean;
	mostrarCoordenadasDMS: boolean;
	mostrarCoordenadasUTM: boolean;
}) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.medidas && avaluo.escritura && avaluo.ubicacion) {
			const {
				colindancia1Descripcion,
				colindancia1Viento,
				colindancia2Descripcion,
				colindancia2Viento,
				colindancia3Descripcion,
				colindancia3Viento,
				colindancia4Descripcion,
				colindancia4Viento,
				colindancia5Descripcion,
				colindancia5Viento,
				colindancia6Descripcion,
				colindancia6Viento,
				colindancia7Descripcion,
				colindancia7Viento,
				colindancia8Descripcion,
				colindancia8Viento,
				colindanciaSeparador,
				destinoEspecifico,
				destinoEspecifico2,
				usoEspecifico,
				usoEspecifico2
			} = avaluo.medidas;

			const colindancias: Array<any> = [];
			crearColindancia(colindancias, colindancia1Descripcion, colindancia1Viento);
			crearColindancia(colindancias, colindancia2Descripcion, colindancia2Viento);
			crearColindancia(colindancias, colindancia3Descripcion, colindancia3Viento);
			crearColindancia(colindancias, colindancia4Descripcion, colindancia4Viento);
			if (colindanciaSeparador) {
				colindancias.push([
					{
						border: [1, 0, 1, 0],
						colSpan: 6,
						margin: [0, 0, 0, 0],
						style: { bold: true },
						text: colindanciaSeparador
					},
					{},
					{},
					{},
					{},
					{}
				]);
			}
			crearColindancia(colindancias, colindancia5Descripcion, colindancia5Viento);
			crearColindancia(colindancias, colindancia6Descripcion, colindancia6Viento);
			crearColindancia(colindancias, colindancia7Descripcion, colindancia7Viento);
			crearColindancia(colindancias, colindancia8Descripcion, colindancia8Viento);

			let usoEspecificoCompleto = '';
			if (usoEspecifico) {
				usoEspecificoCompleto += usoEspecifico?.nombre;
			}
			if (usoEspecifico2) {
				usoEspecificoCompleto += ` / ${usoEspecifico2?.nombre}`;
			}

			let destinoEspecificoCompleto = '';
			if (destinoEspecifico) {
				destinoEspecificoCompleto += destinoEspecifico?.nombre;
			}
			if (destinoEspecifico2) {
				destinoEspecificoCompleto += ` / ${destinoEspecifico2?.nombre}`;
			}

			const contenido = [
				{
					layout: disenos.sinPadding,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: obtenerBody({ avaluo, colindancias, destinoEspecificoCompleto, mostrarCoordenadasDD, mostrarCoordenadasDMS, mostrarCoordenadasUTM, usoEspecificoCompleto }),
						widths: [100, 60, '*', 100, 50, '*']
					}
				}
			];
			resolve(contenido);
		}
	});

/**
 * Genera el body del encabezado.
 * - ***arreglo*** - Arreglo en el que se guardará la colindancia.
 * - ***descripcion*** - Descripcion de la colindancia.
 * - ***viento*** - Objeto con la información del viento cardinal.
 */
const crearColindancia = (arreglo: Array<any>, descripcion?: string | null, viento?: ListaOpcion | null) => {
	if (descripcion && viento) {
		arreglo.push([
			{
				border: [1, 0, 0, 0],
				margin: [0, 0, 0, 0],
				style: 'seccionEtiqueta',
				text: viento && viento?.nombre ? `Al ${viento?.nombre}:` : ''
			},
			{
				border: [0, 0, 1, 0],
				colSpan: 5,
				text: descripcion
			},
			{},
			{},
			{},
			{}
		]);
	}
};

/**
 * Genera el body de la descripción general.
 * - ***avaluo*** - Objeto con la infomación del avalúo.
 * - ***colindancias*** - Arreglo con las colindancias del predio.
 * - ***destinoEspecificoCompleto*** - Texto que se mostrará como destino específico.
 * - ***mostrarCoordenadasDD*** - Indica si se mostrarán las coordenadas DD.
 * - ***mostrarCoordenadasDMS*** - Indica si se mostrarán las coordenadas DMS.
 * - ***mostrarCoordenadasUTM*** - Indica si se mostrarán las coordenadas UTM.
 * - ***usoEspecificoCompleto*** - Texto que se mostrará como uso específico.
 */
const obtenerBody = ({
	avaluo,
	colindancias,
	destinoEspecificoCompleto,
	mostrarCoordenadasDD,
	mostrarCoordenadasDMS,
	mostrarCoordenadasUTM,
	usoEspecificoCompleto
}: {
	avaluo: Avaluo;
	colindancias: Array<any>;
	destinoEspecificoCompleto: string;
	mostrarCoordenadasDD: boolean;
	mostrarCoordenadasDMS: boolean;
	mostrarCoordenadasUTM: boolean;
	usoEspecificoCompleto: string;
}) => {
	if (avaluo && avaluo.medidas && avaluo.escritura && avaluo.ubicacion) {
		const { descripcion: descripcionEscritura } = avaluo.escritura;
		const {
			coordenadasDdLatitud,
			coordenadasDdLongitud,
			coordenadasDmsLatitudCardinal,
			coordenadasDmsLatitudGrados,
			coordenadasDmsLatitudMinutos,
			coordenadasDmsLatitudSegundos,
			coordenadasDmsLongitudCardinal,
			coordenadasDmsLongitudGrados,
			coordenadasDmsLongitudMinutos,
			coordenadasDmsLongitudSegundos,
			coordenadasUtmEsteX,
			coordenadasUtmNorteY
		} = avaluo.ubicacion;
		const { calidadProyecto, edad, indiviso, superficieCatastro, superficieEscritura, superficieUnidad, unidadesRentables, vidaRemanenteProbale } = avaluo.medidas;
		const body = [
			[
				{
					colSpan: 6,
					style: 'seccionTitulo',
					text: 'DESCRIPCIÓN GENERAL DEL PREDIO'
				},
				{},
				{},
				{},
				{},
				{}
			],
			[
				{
					border: [1, 0, 1, 0],
					colSpan: 6,
					margin: [0, 5, 0, 0],
					text: ''
				},
				{},
				{},
				{},
				{},
				{}
			],
			[
				{
					border: [1, 0, 1, 0],
					colSpan: 2,
					style: 'seccionEtiqueta',
					text: 'Medidas y Colindancias del Predio según:'
				},
				{},
				{
					border: [1, 1, 1, 1],
					colSpan: 4,
					margin: [0, 0, 0, 0],
					text: descripcionEscritura
				},
				{},
				{},
				{}
			],
			...colindancias,
			[
				{
					border: [1, 0, 0, 0],
					style: 'seccionEtiquetaNormal',
					text: 'Superficie según escrituras:'
				},
				{
					border: [0, 0, 0, 0],
					style: { alignment: 'right', bold: true },
					text: superficieEscritura ? superficieEscritura.toLocaleString('es-MX') : ''
				},
				{
					border: [0, 0, 0, 0],
					style: { bold: true },
					text: superficieUnidad?.simbolo
				},
				{
					border: [0, 0, 0, 0],
					style: 'seccionEtiquetaNormal',
					text: 'Superficie según catastro:'
				},
				{
					border: [0, 0, 0, 0],
					style: { alignment: 'right', bold: true },
					text: superficieCatastro ? superficieCatastro.toLocaleString('es-MX') : ''
				},
				{
					border: [0, 0, 1, 0],
					style: { bold: true },
					text: superficieUnidad?.simbolo
				}
			],
			[
				{
					border: [1, 0, 0, 0],
					text: ''
				},
				{
					border: [0, 0, 0, 0],
					text: ''
				},
				{
					border: [0, 0, 0, 0],
					text: ''
				},
				{
					border: [0, 0, 0, 0],
					style: 'seccionEtiquetaNormal',
					text: 'Indiviso:'
				},
				{
					border: [0, 0, 0, 0],
					style: { alignment: 'right', bold: true },
					text: indiviso
				},
				{
					border: [0, 0, 1, 0],
					style: { bold: true },
					text: '%'
				}
			],
			[
				{
					border: [1, 0, 0, 0],
					style: 'seccionEtiquetaNormal',
					text: 'Uso Especifico:'
				},
				{
					border: [0, 0, 0, 0],
					colSpan: 2,
					style: { bold: true },
					text: usoEspecificoCompleto
				},
				{},
				{
					border: [0, 0, 0, 0],
					style: 'seccionEtiquetaNormal',
					text: 'Edad:'
				},
				{
					border: [0, 0, 0, 0],
					style: { alignment: 'center', bold: true },
					text: edad
				},
				{
					border: [0, 0, 1, 0],
					style: { bold: true },
					text: 'años.'
				}
			],
			[
				{
					border: [1, 0, 0, 0],
					style: 'seccionEtiquetaNormal',
					text: 'Unidades Rentables:'
				},
				{
					border: [0, 0, 0, 0],
					style: { bold: true },
					text: unidadesRentables
				},
				{
					border: [0, 0, 0, 0],
					style: { bold: true },
					text: ''
				},
				{
					border: [0, 0, 0, 0],
					style: 'seccionEtiquetaNormal',
					text: 'Vida Remanente Probable:'
				},
				{
					border: [0, 0, 0, 0],
					style: { alignment: 'center', bold: true },
					text: vidaRemanenteProbale
				},
				{
					border: [0, 0, 1, 0],
					style: { bold: true },
					text: 'años o más.'
				}
			],
			[
				{
					border: [1, 0, 0, 0],
					style: 'seccionEtiquetaNormal',
					text: 'Destino Especifico de'
				},
				{
					border: [0, 0, 0, 0],
					style: { bold: true },
					text: ''
				},
				{
					border: [0, 0, 0, 0],
					style: { bold: true },
					text: ''
				},
				{
					border: [0, 0, 0, 0],
					style: 'seccionEtiquetaNormal',
					text: 'Calidad de Proyecto:'
				},
				{
					border: [0, 0, 1, 0],
					colSpan: 2,
					rowSpan: 2,
					text: calidadProyecto
				},
				{}
			],
			[
				{
					border: [1, 0, 0, 1],
					margin: [0, 0, 0, 0],
					style: 'seccionEtiquetaNormal',
					text: 'las Construcciones:'
				},
				{
					border: [0, 0, 1, 1],
					colSpan: 5,
					style: { bold: true },
					text: destinoEspecificoCompleto
				},
				{},
				{},
				{},
				{}
			]
		];
		if (mostrarCoordenadasDD) {
			body.push([
				{
					border: [1, 1, 1, 0],
					colSpan: 6,
					style: 'seccionTitulo',
					text: 'COORDENADAS GEOGRAFICAS GRADOS DECIMALES PREDIOS URBANOS'
				},
				{},
				{},
				{},
				{},
				{}
			]);
			body.push([
				{
					border: [1, 0, 0, 1],
					style: 'seccionEtiqueta',
					text: 'Longitud:'
				},
				{
					border: [0, 0, 0, 1],
					colSpan: 2,
					style: { alignment: 'center', bold: true },
					text: coordenadasDdLongitud ? coordenadasDdLongitud : ''
				},
				{},
				{
					border: [0, 0, 0, 1],
					style: 'seccionEtiqueta',
					text: 'Latitud:'
				},
				{
					border: [0, 0, 1, 1],
					colSpan: 2,
					style: { alignment: 'center', bold: true },
					text: coordenadasDdLatitud ? coordenadasDdLatitud : ''
				},
				{}
			]);
		}
		if (mostrarCoordenadasDMS) {
			body.push([
				{
					border: [1, 1, 1, 0],
					colSpan: 6,
					style: 'seccionTitulo',
					text: 'COORDENADAS GEOGRAFICAS DMS PREDIOS URBANOS'
				},
				{},
				{},
				{},
				{},
				{}
			]);
			body.push([
				{
					border: [1, 0, 0, 1],
					style: 'seccionEtiqueta',
					text: 'Longitud:'
				},
				{
					border: [0, 0, 0, 1],
					colSpan: 2,
					style: { alignment: 'center', bold: true },
					text:
						coordenadasDmsLongitudGrados && coordenadasDmsLongitudMinutos && coordenadasDmsLongitudSegundos && coordenadasDmsLongitudCardinal
							? `${coordenadasDmsLongitudGrados}°${coordenadasDmsLongitudMinutos}'${coordenadasDmsLongitudSegundos}"${coordenadasDmsLongitudCardinal}`
							: ''
				},
				{},
				{
					border: [0, 0, 0, 1],
					style: 'seccionEtiqueta',
					text: 'Latitud:'
				},
				{
					border: [0, 0, 1, 1],
					colSpan: 2,
					style: { alignment: 'center', bold: true },
					text:
						coordenadasDmsLatitudGrados && coordenadasDmsLatitudMinutos && coordenadasDmsLatitudSegundos && coordenadasDmsLatitudCardinal
							? `${coordenadasDmsLatitudGrados}°${coordenadasDmsLatitudMinutos}'${coordenadasDmsLatitudSegundos}"${coordenadasDmsLatitudCardinal}`
							: ''
				},
				{}
			]);
		}
		if (mostrarCoordenadasUTM) {
			body.push([
				{
					border: [1, 1, 1, 0],
					colSpan: 6,
					style: 'seccionTitulo',
					text: 'COORDENADAS UTM DATUM ZONA 13 NORTE'
				},
				{},
				{},
				{},
				{},
				{}
			]);
			body.push([
				{
					border: [1, 0, 0, 1],
					style: { alignment: 'right', bold: true },
					text: 'X'
				},
				{
					border: [0, 0, 0, 1],
					style: { alignment: 'left', bold: true },
					text: coordenadasUtmEsteX ? coordenadasUtmEsteX : ''
				},
				{
					border: [0, 0, 0, 1],
					text: ''
				},
				{
					border: [0, 0, 0, 1],
					style: { alignment: 'right', bold: true },
					text: 'Y'
				},
				{
					border: [0, 0, 1, 1],
					colSpan: 2,
					style: { alignment: 'left', bold: true },
					text: coordenadasUtmNorteY ? coordenadasUtmNorteY : ''
				},
				{}
			]);
		}
		return body;
	}
};

export default seccion;
