import aplicacion from 'configuracion/aplicacion';
import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del PDF del avalúo relacionado a la etiqueta del código/clasificación de la construcción.
 */
const InformacionAvaluoPdfEtiquetaConstruccion = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Etiqueta que se mostrará en el PDF para el código/clasificación de la construcción.')}</p>
		</div>
		<div className="text-center mt-2 mb-2">
			<h5>Clasificación</h5>
			<img alt="Ejemplo" src={aplicacion.tutoriales.ejemploAvaluoPdfEtiquetaConstruccionClasificacion} width="480px" />
		</div>
		<div className="text-center mt-2 mb-2">
			<h5>Código</h5>
			<img alt="Ejemplo" src={aplicacion.tutoriales.ejemploAvaluoPdfEtiquetaConstruccionCodigo} width="480px" />
		</div>
	</Fragment>
);

export default InformacionAvaluoPdfEtiquetaConstruccion;
