import { guardarLocalStorage, obtenerLocalStorage } from 'util/mc-utils/mc-storage';
import detector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from 'idiomas/en/translation.json';
import translationES from 'idiomas/es/translation.json';

const NOMBRE_CLASE = 'idiomas/index';

const resources = {
	en: {
		translation: translationEN
	},
	es: {
		translation: translationES
	}
};

const lenguage = obtenerLocalStorage({ campo: 'idiomaSeleccionado', nombreClase: NOMBRE_CLASE, nombreMetodo: 'InicializarIdioma' });
if (!lenguage) {
	guardarLocalStorage({ campo: 'idiomaSeleccionado', nombreClase: NOMBRE_CLASE, nombreMetodo: 'InicializarIdioma', valor: 'es' });
}

i18n
	.use(detector)
	.use(initReactI18next) // Passes i18n down to react-i18next
	.init({
		fallbackLng: 'es', // Use en if detected lng is not available
		interpolation: {
			escapeValue: false // React already safes from xss
		},
		keySeparator: false, // We do not use keys in form messages.welcome
		lng: obtenerLocalStorage({ campo: 'idiomaSeleccionado', nombreClase: NOMBRE_CLASE, nombreMetodo: 'InicializarIdioma' }) || 'es',
		resources
	});

/**
 * Recibe una cadena de texto y si existe una traducción en el idioma seleccionado la muestra, de lo contrario muestra la cadena recibida.
 * - ***cadena*** - Cadena de texto a traducir.
 */
export const texto = (cadena: string) => i18n.t(cadena);

export default i18n;
