import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

interface RolFormularioPermisosAplicacionProps {
	/**
	 * Indica si el formulario será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Formulario para los permisos relacionados a la configuración de la aplicación.
 */
const RolFormularioPermisosAplicacion = (props: RolFormularioPermisosAplicacionProps) => {
	const { soloLectura = false } = props;

	return (
		<Row>
			<Col lg="12">
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionConfiguracionGeneral}
					etiqueta={texto('Editar la configuración general de la aplicación.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionConfiguracionGeneral}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionConfiguracionDatos}
					etiqueta={texto('Editar la configuración de datos de la aplicación.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionConfiguracionDatos}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionConfiguracionImagenes}
					etiqueta={texto('Editar la configuración imágenes de la aplicación.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionConfiguracionImagenes}
					soloLectura={soloLectura}
				/>
			</Col>
		</Row>
	);
};

export default RolFormularioPermisosAplicacion;
