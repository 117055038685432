import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

/**
 * Formulario para la configuración de datos del sistema.
 */
const SistemaFormularioDatos = () => (
	<Row>
		<Col lg="12">
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.datos}></i> {texto('Recolección de datos')}
					</h3>
					<p>{texto('Aquí se configura la recolección de datos del sistema.')}</p>
				</Col>
			</Row>
			<hr />
			<Row>
				<h4>
					<i className={constantes.icono.usuarios}></i> {texto('Usuarios')}
				</h4>
				<Col lg="6">
					<McSwitchRedondo
						campo="datosUsuarioDomicilioEntrega"
						etiqueta={texto('Domicilio de entrega')}
						id="datosUsuarioDomicilioEntrega"
						informacion={texto('Si se activa el usuario podrá guardar información de su domicilio de entrega.')}
					/>
				</Col>
				<Col lg="6">
					<McSwitchRedondo
						campo="datosUsuarioDomicilioFacturacion"
						etiqueta={texto('Domicilio de facturación')}
						id="datosUsuarioDomicilioFacturacion"
						informacion={texto('Si se activa el usuario podrá guardar información de su domicilio de facturación.')}
					/>
				</Col>
			</Row>
		</Col>
	</Row>
);

export default SistemaFormularioDatos;
