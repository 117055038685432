import disenos from './disenos';

const seccion = () =>
	new Promise((resolve) => {
		const body = [];
		body.push([
			{
				border: [0, 0, 0, 0],
				height: 100,
				margin: [0, 0, 0, 0],
				style: { alignment: 'center' },
				text: '',
				width: 160
			},
			{
				border: [0, 0, 1, 0],
				text: ''
			},
			{
				border: [1, 1, 1, 1],
				margin: [0, 100, 0, 0],
				text: ''
			}
		]);
		const contenido = [
			{
				absolutePosition: { x: 30, y: 630 },
				layout: disenos.sinPaddingArribaAbajo,
				margin: [0, 0, 0, 0],
				style: 'piePagina',
				table: {
					body: body,
					widths: [220, '*', 220]
				}
			}
		];
		resolve(contenido);
	});

export default seccion;
