/* eslint-disable prefer-destructuring */
import './MenuGrupo.scss';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';

const ICONO_COLAPSAR = 'fa-solid fa-chevron-up';
const ICONO_COLAPSAR_COLAPSADO = 'fa-solid fa-chevron-down';

interface MenuGrupoProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: React.ReactNode;
	/**
	 * Etiqueta que se mostrará en el botón.
	 */
	etiqueta: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	icono?: string;
	/**
	 * Identificador único del botón.
	 */
	id: string;
}

/**
 * Botón del menú.
 */
const MenuGrupo = (props: MenuGrupoProps) => {
	const location = useLocation();
	const [grupoOculto, setGrupoOculto] = useState(true);
	const [iconoColapsar, setIconoColapsar] = useState(ICONO_COLAPSAR);
	const { children, etiqueta, icono, id } = props;

	useEffect(() => {
		setTimeout(() => {
			const li = document.getElementById(id);
			const ulArreglo = li?.getElementsByTagName('ul');
			li?.classList.remove('mm-active');
			if (ulArreglo) {
				expandirGrupoActivo(ulArreglo);
				mostrarOcultarGrupo(ulArreglo);
			}
		}, 100);
	}, [location.pathname]);

	useEffect(() => {
		setTimeout(() => {
			const grupoLi = document.getElementById(id);
			const grupoLiArregloA = grupoLi?.getElementsByTagName('a');
			if (grupoLiArregloA) {
				const grupoLiArregloI = grupoLi?.getElementsByTagName('i');
				if (grupoLiArregloI) {
					const grupoIconoColapsar = grupoLiArregloI[0];
					if (!grupoIconoColapsar.classList.contains(iconoColapsar.split(' ')[1])) {
						grupoIconoColapsar.classList.remove('fa-chevron-up');
						grupoIconoColapsar.classList.remove('fa-chevron-down');
						grupoIconoColapsar.classList.add(iconoColapsar.split(' ')[1]);
					}
				}
			}
		}, 50);
	});

	/**
	 * Evento que se ejecuta cuando se presiona el nombre del grupo.
	 */
	const eventoClick = () => {
		const grupoLi = document.getElementById(id);
		const grupoUlArreglo = grupoLi?.getElementsByTagName('ul');
		const menuContenedor = grupoLi?.parentElement;
		const menuBotones = menuContenedor?.getElementsByTagName('li');
		if (menuBotones) {
			colapsarLosDemasGrupos(menuBotones);
		}
		if (grupoUlArreglo) {
			const grupoContenedor = grupoUlArreglo[0];
			expandirColapsarGrupo(grupoContenedor);
		}
	};

	/**
	 * Colapsa todos los demas grupos del menú.
	 * - ***menuBotones*** - Botones y grupos del menu.
	 */
	const colapsarLosDemasGrupos = (menuBotones: HTMLCollectionOf<HTMLLIElement>) => {
		for (let i = 0; i < menuBotones?.length; i++) {
			const botonUlArreglo = menuBotones[i]?.getElementsByTagName('ul');
			if (botonUlArreglo.length) {
				const grupoContenedor = botonUlArreglo[0];
				const grupoLi = grupoContenedor?.parentElement;
				if (grupoLi?.getAttribute('id') !== id) {
					grupoContenedor.classList.add('mm-collapse');
					grupoContenedor.classList.remove('mm-show');
					const grupoLiArregloA = grupoLi?.getElementsByTagName('a');
					if (grupoLiArregloA) {
						const grupoLiArregloI = grupoLi?.getElementsByTagName('i');
						if (grupoLiArregloI) {
							const grupoIconoColapsar = grupoLiArregloI[0];
							setTimeout(() => {
								grupoIconoColapsar.classList.remove('fa-chevron-up');
								grupoIconoColapsar.classList.add('fa-chevron-down');
							}, 100);
						}
					}
				}
			}
		}
	};

	/**
	 * Expande el grupo.
	 * - ***ulArreglo*** - Contenedor de botones del grupo.
	 */
	const expandirGrupoActivo = (ulArreglo: HTMLCollectionOf<HTMLUListElement>) => {
		const li = document.getElementById(id);
		ulArreglo[0]?.classList.add('mm-collapse');
		ulArreglo[0]?.classList.remove('mm-show');
		setIconoColapsar(ICONO_COLAPSAR_COLAPSADO);
		const liArreglo = ulArreglo[0].getElementsByTagName('li');
		for (let i = 0; i < liArreglo.length; i++) {
			if (liArreglo[i].classList.contains('mm-active')) {
				li?.classList.add('mm-active');
				ulArreglo[0]?.classList.add('mm-collapse');
				ulArreglo[0]?.classList.add('mm-show');
				setIconoColapsar(ICONO_COLAPSAR);
				break;
			}
		}
	};

	/**
	 * Expande/Colapsa el contenedor de botones del grupo.
	 * - ***grupoContenedor*** - Contenedor de botones del grupo.
	 */
	const expandirColapsarGrupo = (grupoContenedor: HTMLUListElement) => {
		if (!grupoContenedor.classList.contains('mm-show') && !grupoContenedor.classList.contains('mm-collapse')) {
			grupoContenedor.classList.remove('mm-collapsing');
			grupoContenedor.classList.add('mm-collapse');
			setIconoColapsar(ICONO_COLAPSAR_COLAPSADO);
		} else if (grupoContenedor.classList.contains('mm-show') && grupoContenedor.classList.contains('mm-collapsing')) {
			grupoContenedor.classList.remove('mm-collapsing');
			grupoContenedor.classList.add('mm-collapse');
			setIconoColapsar(ICONO_COLAPSAR);
		} else if (grupoContenedor.classList.contains('mm-show') && !grupoContenedor.classList.contains('mm-collapsing')) {
			grupoContenedor.classList.remove('mm-show');
			grupoContenedor.classList.add('mm-collapse');
			setIconoColapsar(ICONO_COLAPSAR_COLAPSADO);
		} else {
			grupoContenedor.classList.add('mm-show');
			grupoContenedor.classList.add('mm-collapse');
			setIconoColapsar(ICONO_COLAPSAR);
		}
	};

	/**
	 * Muestra/Oculta el grupo.
	 * - ***grupoContenedor*** - Contenedor de botones del grupo.
	 */
	const mostrarOcultarGrupo = (ulArreglo: HTMLCollectionOf<HTMLUListElement>) => {
		const liArreglo = ulArreglo[0].getElementsByTagName('li');
		for (let i = 0; i < liArreglo.length; i++) {
			if (liArreglo[i].getAttribute('data-oculto') === 'false') {
				setGrupoOculto(false);
				break;
			}
		}
	};

	return (
		<li data-oculto={grupoOculto} hidden={grupoOculto} id={id}>
			<Link className="waves-effect" onClick={eventoClick} to="#">
				<div className="mc-menu-grupo__icono-colapsar">
					<i className={iconoColapsar}></i>
				</div>
				<i className={icono}></i>
				<span className="ms-1">{etiqueta}</span>
			</Link>
			<ul className="sub-menu">{children}</ul>
		</li>
	);
};

export default MenuGrupo;
