import { Fragment, ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BarraNavegacionSuperior from 'componentes/tema-horizontal/BarraNavegacionSuperior';
import Encabezado from 'componentes/tema-horizontal/Encabezado';
import PiePagina from 'componentes/tema-horizontal/PiePagina';
import { setSesionPreferenciasAtributosAction } from 'store/actions';
import { StateType } from 'store';

interface TemaHorizontalProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
}

/**
 * Componente contenedor del tema horizontal.
 */
const TemaHorizontal = (props: TemaHorizontalProps) => {
	const dispatch = useDispatch();
	const { sesion } = useSelector((state: StateType) => state);
	const { usuario } = sesion;
	const { preferencias } = usuario;
	const { interfazAncho, interfazBarraNavegacionSuperiorTema } = preferencias;
	const { children } = props;

	useEffect(() => {
		window.scrollTo(0, 0);
		const nuevasPreferencias = {
			...preferencias,
			interfazTema: 'horizontal'
		};
		dispatch(setSesionPreferenciasAtributosAction({ atributo: 'interfazTema', preferencias: nuevasPreferencias }));
		if (interfazBarraNavegacionSuperiorTema) {
			const nuevasPreferencias = {
				...preferencias,
				interfazBarraNavegacionSuperiorTema: interfazBarraNavegacionSuperiorTema
			};
			dispatch(setSesionPreferenciasAtributosAction({ atributo: 'interfazBarraNavegacionSuperiorTema', preferencias: nuevasPreferencias }));
		}
		if (interfazAncho) {
			const nuevasPreferencias = {
				...preferencias,
				interfazAncho: interfazAncho
			};
			dispatch(setSesionPreferenciasAtributosAction({ atributo: 'interfazAncho', preferencias: nuevasPreferencias }));
		}
	}, []);

	return (
		<Fragment>
			<div id="layout-wrapper">
				<header id="page-topbar">
					<Encabezado />
					<BarraNavegacionSuperior />
				</header>
				<div className="main-content">{children}</div>
				<PiePagina />
			</div>
		</Fragment>
	);
};

export default TemaHorizontal;
