import './NotificacionesMenuDesplegable.scss';
import { Col, Dropdown, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import { marcarUsuarioNotificacionesLeidasPorUsuario, obtenerUsuarioNotificacionesUltimasPorUsuario } from 'servicios/api/usuariosNotificaciones';
import { useDispatch, useSelector } from 'react-redux';
import constantes from 'configuracion/constantes';
import { getSesionUsuarioNotificacionesCantidadAction } from 'store/actions';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import NotificacionTarjeta from 'componentes/tema-comun/barra-superior/NotificacionTarjeta';
import { procesarError } from '@mcsoft/api';
import SimpleBar from 'simplebar-react';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useHistory } from 'react-router';
import UsuarioNotificacionDetalle from 'modelo/UsuarioNotificacionDetalle';

const NOMBRE_CLASE = 'NotificacionesMenuDesplegable';

/**
 * Menú desplegable que muestra una lista de notificaciones.
 */
const NotificacionesMenuDesplegable = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const sesion = useSelector((state: StateType) => state.sesion);
	const { auth0, notificacionesNuevas, usuario } = sesion;
	const { auth0AccessToken } = auth0;
	const [mostrarMenuDesplegable, setMostrarMenuDesplegable] = useState(false);
	const [notificaciones, setNotificaciones] = useState<Array<UsuarioNotificacionDetalle>>([]);

	useEffect(() => {
		eventoObtenerNotificaciones();
	}, [notificacionesNuevas]);

	/**
	 * Muestra/oculta el menú desplegable.
	 */
	const eventoMostrarOcultarMenuDesplegable = () => {
		const nombreMetodo = 'eventoMostrarOcultarMenuDesplegable';
		mcLogger.react({ mensaje: `Seteando state "mostrarMenuDesplegable":`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: !mostrarMenuDesplegable });
		setMostrarMenuDesplegable(!mostrarMenuDesplegable);
	};

	/**
	 * Redirecciona al usuario a la seccion de notificaciones.
	 */
	const eventoVerNotificacion = () => {
		const nombreMetodo = 'eventoVerNotificacion';
		const ruta = constantes.ruta.appUsuarioNotificaciones;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		setMostrarMenuDesplegable(false);
		history.push(ruta);
	};

	/**
	 * Redirecciona al usuario al enlace de la notificación.
	 * > - ***notificacion*** - Objeto con la información de la notificación.
	 */
	const eventoMarcarTodoLeido = async () => {
		const nombreMetodo = 'eventoMarcarTodoLeido';
		try {
			await marcarUsuarioNotificacionesLeidasPorUsuario({ auth0AccessToken, usuarioId: usuario.id });
			dispatch(getSesionUsuarioNotificacionesCantidadAction({ auth0AccessToken, usuario }));
			notificaciones?.forEach((notificacion) => (notificacion.estatus = 'leida'));
			setMostrarMenuDesplegable(false);
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al marcar como leidas las notificaciones:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.datos });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	/**
	 * Redirecciona al usuario a la lista de notificaciones.
	 */
	const eventoVerTodo = () => {
		const nombreMetodo = 'eventoVerTodo';
		const ruta = constantes.ruta.appUsuarioNotificaciones;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		setMostrarMenuDesplegable(false);
		history.push(ruta);
	};

	/**
	 * Obtiene las notificaciones.
	 */
	const eventoObtenerNotificaciones = async () => {
		const nombreMetodo = 'eventoObtenerNotificaciones';
		try {
			mcLogger.log({ mensaje: `Obteniendo notificaciones del usuarios...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const respuesta = await obtenerUsuarioNotificacionesUltimasPorUsuario({ auth0AccessToken, usuarioId: usuario.id });
			mcLogger.log({ mensaje: `Notificaciones del usuarios obtenidas con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			setNotificaciones(respuesta.datos);
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener las notificaciones del usuarios: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	return (
		<Fragment>
			<Dropdown className="dropdown d-inline-block" isOpen={mostrarMenuDesplegable} tag="li" toggle={eventoMostrarOcultarMenuDesplegable}>
				<DropdownToggle className="btn header-item noti-icon waves-effect" id="page-header-notifications-dropdown" tag="button">
					<i className="uil-bell"></i>
					{notificacionesNuevas > 0 && <span className="badge bg-danger rounded-pill">{notificacionesNuevas}</span>}
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
					<div className="p-3">
						<Row className="align-items-center">
							<Col>
								<h6 className="m-0 font-size-16"> {texto('Notificaciones')} </h6>
							</Col>
							<div className="col-auto">
								<span className="small notificationes-menu-desplegable__boton" onClick={eventoMarcarTodoLeido}>
									{texto('Marcar todo como leído')}
								</span>
							</div>
						</Row>
					</div>
					<SimpleBar style={{ height: '230px' }}>
						{notificaciones?.map((notificacion: UsuarioNotificacionDetalle) => (
							<NotificacionTarjeta eventoVerNotificacion={eventoVerNotificacion} key={notificacion.id} usuarioNotificacion={notificacion} />
						))}
					</SimpleBar>
					<div className="p-2 border-top d-grid">
						<span className="btn btn-sm btn-link font-size-14 text-center" onClick={eventoVerTodo}>
							<i className="uil-arrow-circle-right me-1"></i>
							{texto('Ver todo')}
						</span>
					</div>
				</DropdownMenu>
			</Dropdown>
		</Fragment>
	);
};

export default NotificacionesMenuDesplegable;
