import { Button, Dropdown, DropdownMenu, DropdownToggle, Form, Input } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import { setInterfazBarraNavegacionLateralMostrarAction, setSesionPreferenciasAtributosAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import aplicacion from 'configuracion/aplicacion';
import IdiomaMenuDesplegable from 'componentes/tema-comun/barra-superior/IdiomaMenuDesplegable';
import { Link } from 'react-router-dom';
import NotificacionesMenuDesplegable from 'componentes/tema-comun/barra-superior/NotificacionesMenuDesplegable';
import PerfilMenuDesplegable from 'componentes/tema-comun/barra-superior/PerfilMenuDesplegable';
import { StateType } from 'store';
import { texto } from 'idiomas';

/**
 * Encabezado de la página que contiene la barra de búsqueda y varios menús desplegables.
 */
const Encabezado = () => {
	const dispatch = useDispatch();
	const { configuracionAplicacion, configuracionSistema, interfaz, sesion } = useSelector((state: StateType) => state);
	const [mostrarBuscador, setMostrarBuscador] = useState(false); // TODO Arreglar cuando se agregue la funcionalidad de buscar
	const [logoClaro1080x202, setLogoClaro1080x202] = useState(aplicacion.imagenes.logoClaro1080x202);
	const [logoClaro202x202, setLogoClaro202x202] = useState(aplicacion.imagenes.logoClaro202x202);
	const [logoOscuro1080x202, setLogoOscuro1080x202] = useState(aplicacion.imagenes.logoOscuro1080x202);
	const [logoOscuro202x202, setLogoOscuro202x202] = useState(aplicacion.imagenes.logoOscuro202x202);
	const { imagenLogoClaro202x202, imagenLogoClaro1080x202, imagenLogoOscuro202x202, imagenLogoOscuro1080x202 } = configuracionAplicacion;
	const { usuario } = sesion;
	const { preferencias } = usuario;
	const { busquedaHabilitada, multilenguaje } = configuracionSistema;
	const { barraNavegacionLateralMostrar } = interfaz;
	const { interfazBarraNavegacionLateralAncho } = preferencias;

	useEffect(() => {
		setLogoClaro1080x202(imagenLogoClaro1080x202 ? imagenLogoClaro1080x202 : aplicacion.imagenes.logoClaro1080x202);
		setLogoClaro202x202(imagenLogoClaro202x202 ? imagenLogoClaro202x202 : aplicacion.imagenes.logoClaro202x202);
		setLogoOscuro1080x202(imagenLogoOscuro1080x202 ? imagenLogoOscuro1080x202 : aplicacion.imagenes.logoOscuro1080x202);
		setLogoOscuro202x202(imagenLogoOscuro202x202 ? imagenLogoOscuro202x202 : aplicacion.imagenes.logoOscuro202x202);
	}, [imagenLogoClaro202x202, imagenLogoClaro1080x202, imagenLogoOscuro1080x202, imagenLogoOscuro202x202]);

	/**
	 * Intercambia entre el modo ventana y el modo pantalla completa de la aplicación.
	 */
	const eventoCambiarPantallaCompleta = () => {
		if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
			if (document.documentElement.requestFullscreen) {
				document.documentElement.requestFullscreen();
			} else if (document.documentElement.mozRequestFullScreen) {
				document.documentElement.mozRequestFullScreen();
			} else if (document.documentElement.webkitRequestFullscreen) {
				document.documentElement.webkitRequestFullscreen();
				// Parametro: Element.ALLOW_KEYBOARD_INPUT // TODO No he encontrado solucion para que TypeScript lo acepte
			}
		} else {
			if (document.cancelFullScreen) {
				document.cancelFullScreen();
			} else if (document.mozCancelFullScreen) {
				document.mozCancelFullScreen();
			} else if (document.webkitCancelFullScreen) {
				document.webkitCancelFullScreen();
			}
		}
	};

	/**
	 * Muestra/oculta la barra de navegación lateral.
	 */
	const eventoMostrarOcultarBarraNavegacionLateral = () => {
		dispatch(setInterfazBarraNavegacionLateralMostrarAction(!barraNavegacionLateralMostrar));
		if (interfazBarraNavegacionLateralAncho === 'default') {
			const nuevasPreferencias = {
				...preferencias,
				interfazBarraNavegacionLateralAncho: 'condensed'
			};
			dispatch(setSesionPreferenciasAtributosAction({ atributo: 'interfazBarraNavegacionLateralAncho', preferencias: nuevasPreferencias }));
		} else if (interfazBarraNavegacionLateralAncho === 'condensed') {
			const nuevasPreferencias = {
				...preferencias,
				interfazBarraNavegacionLateralAncho: 'default'
			};
			dispatch(setSesionPreferenciasAtributosAction({ atributo: 'interfazBarraNavegacionLateralAncho', preferencias: nuevasPreferencias }));
		}
	};

	return (
		<Fragment>
			<header id="page-topbar">
				<div className="navbar-header">
					<div className="d-flex">
						<div className="navbar-brand-box">
							<Link className="logo logo-dark" to="/">
								<span className="logo-sm">
									<img alt="" height="35" src={logoOscuro202x202} />
								</span>
								<span className="logo-lg">
									<img alt="" height="30" src={logoOscuro1080x202} />
								</span>
							</Link>

							<Link className="logo logo-light" to="/">
								<span className="logo-sm">
									<img alt="" height="35" src={logoClaro202x202} />
								</span>
								<span className="logo-lg">
									<img alt="" height="30" src={logoClaro1080x202} />
								</span>
							</Link>
						</div>
						<button
							className="btn btn-sm px-3 font-size-16 header-item waves-effect vertical-menu-btn"
							id="vertical-menu-btn"
							onClick={eventoMostrarOcultarBarraNavegacionLateral}
							type="button"
						>
							<i className="fa fa-fw fa-bars" />
						</button>
						{busquedaHabilitada && (
							<Form className="app-search d-none d-lg-block">
								<div className="position-relative">
									<input className="form-control" placeholder={`${texto('Buscar')}...`} type="text" />
									<span className="uil-search"></span>
								</div>
							</Form>
						)}
					</div>
					<div className="d-flex">
						{busquedaHabilitada && (
							<Dropdown
								className="d-inline-block d-lg-none ms-2"
								isOpen={mostrarBuscador}
								toggle={() => {
									setMostrarBuscador(!mostrarBuscador);
								}}
								type="button"
							>
								<DropdownToggle className="btn header-item noti-icon waves-effect" id="page-header-search-dropdown" tag="button">
									{' '}
									<i className="uil-search" />
								</DropdownToggle>
								<DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
									<Form className="p-3">
										<div className="form-group m-0">
											<div className="input-group">
												<Input aria-label="Recipient's username" className="form-control" placeholder={`${texto('Buscar')}...`} type="text" />
												<div className="input-group-append">
													<Button className="btn btn-primary" type="submit">
														<i className="mdi mdi-magnify"></i>
													</Button>
												</div>
											</div>
										</div>
									</Form>
								</DropdownMenu>
							</Dropdown>
						)}
						{multilenguaje && <IdiomaMenuDesplegable />}
						<Dropdown className="d-none d-lg-inline-block ms-1">
							<button className="btn header-item noti-icon waves-effect" data-toggle="fullscreen" onClick={eventoCambiarPantallaCompleta} type="button">
								<i className="uil-minus-path"></i>
							</button>
						</Dropdown>
						<NotificacionesMenuDesplegable />
						<PerfilMenuDesplegable />
					</div>
				</div>
			</header>
		</Fragment>
	);
};

export default Encabezado;
