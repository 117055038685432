import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

interface RolFormularioPermisosAplicacionProps {
	/**
	 * Indica si el formulario será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Formulario para los permisos relacionados a los avalúos.
 */
const RolFormularioPermisosAvaluos = (props: RolFormularioPermisosAplicacionProps) => {
	const { soloLectura = false } = props;

	return (
		<Row>
			<Col lg="12">
				<McSwitchRedondo
					campo={constantes.permiso.appAvaluosVerLista}
					etiqueta={texto('Ver la lista de sus avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAvaluosVerLista}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionAvaluosVerLista}
					etiqueta={texto('Ver la lista de todos los avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionAvaluosVerLista}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAvaluosVerDetalles}
					etiqueta={texto('Ver los detalles de sus avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAvaluosVerDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionAvaluosVerDetalles}
					etiqueta={texto('Ver los detalles de todos los avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionAvaluosVerDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAvaluosEditarDetalles}
					etiqueta={texto('Editar los detalles de sus avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAvaluosEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionAvaluosEditarDetalles}
					etiqueta={texto('Editar los detalles de todos los avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionAvaluosEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAvaluosCrear}
					etiqueta={texto('Crear avalúos nuevos.')}
					etiquetaLineal
					id={constantes.permiso.appAvaluosCrear}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAvaluosGenerarPdf}
					etiqueta={texto('Generar el PDF de sus avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAvaluosGenerarPdf}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionAvaluosGenerarPdf}
					etiqueta={texto('Generar el PDF de todos los avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionAvaluosGenerarPdf}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAvaluosClonar}
					etiqueta={texto('Clonar sus avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAvaluosClonar}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionAvaluosClonar}
					etiqueta={texto('Clonar todos los avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionAvaluosClonar}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAvaluosEliminar}
					etiqueta={texto('Eliminar sus avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAvaluosEliminar}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionAvaluosEliminar}
					etiqueta={texto('Eliminar todos los avalúos.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionAvaluosEliminar}
					soloLectura={soloLectura}
				/>
			</Col>
		</Row>
	);
};

export default RolFormularioPermisosAvaluos;
