import { actualizarAvaluo, crearAvaluo, eliminarAvaluo } from 'servicios/api/avaluos';
import { copiarArchivoS3, eliminarArchivoS3, obtenerArchivoS3Base64 } from 'servicios/aws/s3';
import Avaluo from 'modelo/Avaluo';
import AvaluoFoto from 'modelo/AvaluoFoto';
import ConfiguracionSistema from 'modelo/ConfiguracionSistema';
import generarContenidoPdfGeneral from 'util/pdf/avaluo/general';
import generarContenidoPdfPersonalizado from 'util/pdf/avaluo/personalizado';
import generarContenidoPdfZapopan from 'util/pdf/avaluo/zapopan';
import { McRespuesta } from '@mcsoft/api';
import { obtenerExtensionArchivo } from '@mcsoft/archivos';
import { obtenerPeritoPorId } from 'servicios/api/peritos';
import Perito from 'modelo/Perito';
import { StateType } from 'store';
import { tieneValor } from '@mcsoft/validaciones';
import { useSelector } from 'react-redux';
import Usuario from 'modelo/Usuario';
import UsuarioPreferencias from 'modelo/UsuarioPreferencias';
import { v4 as uuidv4 } from 'uuid';

const PDF_FORMATOS = {
	general: 'General',
	personalizado: 'Personalizado',
	zapopan: 'Zapopan'
};

/**
 * Actualiza un avalúo.
 */
export const useActualizarAvaluo = () => {
	const { sesion } = useSelector((state: StateType) => state);
	const { auth0 } = sesion;
	const { auth0AccessToken } = auth0;
	return async (avaluo?: Avaluo) => {
		if (avaluo) {
			avaluo = formatearAvaluoParaBD(avaluo);
			await actualizarAvaluo({ auth0AccessToken, id: avaluo.id, valores: avaluo });
		} else {
			const errorMensaje = `El avaluo no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	};
};

/**
 * Clona un avalúo.
 */
export const useClonarAvaluo = () => {
	const { configuracionSistema, sesion } = useSelector((state: StateType) => state);
	const { auth0 } = sesion;
	const { auth0AccessToken } = auth0;
	const { awsS3BucketAvaluoFotos, awsS3BucketAvaluoMapas } = configuracionSistema;
	return async ({ avaluo, opciones, usuario }: { avaluo?: Avaluo; opciones?: UsuarioPreferencias; usuario?: Usuario }) => {
		if (auth0AccessToken) {
			if (avaluo) {
				if (awsS3BucketAvaluoFotos) {
					if (awsS3BucketAvaluoMapas) {
						if (opciones) {
							if (usuario) {
								const {
									avaluosClonarAntecedentes,
									avaluosClonarCaracteristicas,
									avaluosClonarDetallesConstrucciones,
									avaluosClonarDetallesInstalacionesEspeciales,
									avaluosClonarDetallesTerrenos,
									avaluosClonarDistribucion,
									avaluosClonarElementosConstruccion,
									avaluosClonarFotos,
									avaluosClonarGeneral,
									avaluosClonarObservaciones,
									avaluosClonarUbicacion
								} = opciones;
								let avaluoClonado: Avaluo = { ...avaluo };
								avaluoClonado.id = uuidv4();
								avaluoClonado.usuarioId = usuario.id;
								if (!avaluosClonarAntecedentes) {
									avaluoClonado.antecedentes = { avaluoId: avaluoClonado.id, id: '' };
									avaluoClonado.domicilio = { avaluoId: avaluoClonado.id, id: '' };
								} else {
									if (avaluoClonado.antecedentes && avaluoClonado.antecedentes.peritoId) {
										const resultadoPerito = await obtenerPeritoPorId({ auth0AccessToken, id: avaluoClonado.antecedentes.peritoId });
										const perito: Perito = resultadoPerito.datos;
										if (perito.usuarioId !== usuario.id) {
											avaluoClonado.antecedentes.perito = undefined;
											avaluoClonado.antecedentes.peritoId = '';
										}
									}
								}
								if (!avaluosClonarCaracteristicas) {
									avaluoClonado.caracteristicas = { avaluoId: avaluoClonado.id, id: '' };
									avaluoClonado.construccionesDominantes = { avaluoId: avaluoClonado.id, id: '' };
									avaluoClonado.serviciosMunicipales = { avaluoId: avaluoClonado.id, id: '' };
								}
								if (!avaluosClonarDetallesConstrucciones) {
									avaluoClonado.construcciones = { avaluoId: avaluoClonado.id, detalles: [], id: '' };
								}
								if (!avaluosClonarDetallesInstalacionesEspeciales) {
									avaluoClonado.instalacionesEspeciales = { avaluoId: avaluoClonado.id, detalles: [], id: '' };
								}
								if (!avaluosClonarDetallesTerrenos) {
									avaluoClonado.terreno = { avaluoId: avaluoClonado.id, detalles: [], id: '' };
								}
								if (!avaluosClonarDistribucion) {
									avaluoClonado.distribucion = { avaluoId: avaluoClonado.id, descripcion: '', detalles: {}, id: '' };
								}
								if (!avaluosClonarElementosConstruccion) {
									avaluoClonado.elementosConstruccion = { avaluoId: avaluoClonado.id, id: '' };
								}
								if (!avaluosClonarFotos) {
									avaluoClonado.fotos = [];
								} else {
									avaluoClonado.fotos = await copiarFotos({
										avaluoDestino: avaluoClonado,
										avaluoOriginal: avaluo,
										awsS3BucketAvaluoFotos
									});
								}
								if (!avaluosClonarGeneral) {
									avaluoClonado.escritura = { avaluoId: avaluoClonado.id, id: '' };
									avaluoClonado.medidas = { avaluoId: avaluoClonado.id, id: '' };
								}
								if (!avaluosClonarObservaciones) {
									avaluoClonado.observaciones = { avaluoId: avaluoClonado.id, id: '' };
								}
								if (!avaluosClonarUbicacion) {
									avaluoClonado.ubicacion = { avaluoId: avaluoClonado.id, id: '' };
								} else {
									if (avaluoClonado.ubicacion && avaluoClonado.ubicacion.croquisImagenNombre) {
										const nombreCroquisOriginal = avaluoClonado.ubicacion.croquisImagenNombre;
										// eslint-disable-next-line no-unused-vars
										const [avaluoOriginalId, nombreCroquis] = nombreCroquisOriginal.split('_');
										const nombreCroquisNuevo = `${avaluoClonado.id}_${nombreCroquis}`;
										await copiarArchivoS3({
											archivoDestinoNombre: nombreCroquisNuevo,
											archivoOrigenNombre: nombreCroquisOriginal,
											bucketDestinoNombre: awsS3BucketAvaluoMapas,
											bucketOrigenNombre: awsS3BucketAvaluoMapas
										});
										avaluoClonado.ubicacion.croquisImagenNombre = nombreCroquisNuevo;
									}
									if (avaluoClonado.ubicacion && avaluoClonado.ubicacion.mapaImagenNombre) {
										const nombreMapaOriginal = avaluoClonado.ubicacion.mapaImagenNombre;
										// eslint-disable-next-line no-unused-vars
										const [avaluoOriginalId, nombreMapa] = nombreMapaOriginal.split('_');
										const nombreMapaNuevo = `${avaluoClonado.id}_${nombreMapa}`;
										await copiarArchivoS3({
											archivoDestinoNombre: nombreMapaNuevo,
											archivoOrigenNombre: nombreMapaOriginal,
											bucketDestinoNombre: awsS3BucketAvaluoMapas,
											bucketOrigenNombre: awsS3BucketAvaluoMapas
										});
										avaluoClonado.ubicacion.mapaImagenNombre = nombreMapaNuevo;
									}
									if (avaluoClonado.ubicacion && avaluoClonado.ubicacion.mapaPersonalizadoImagenNombre) {
										const nombreMapaPersonalizadoOriginal = avaluoClonado.ubicacion.mapaPersonalizadoImagenNombre;
										// eslint-disable-next-line no-unused-vars
										const [avaluoOriginalId, nombreMapaPersonalizado] = nombreMapaPersonalizadoOriginal.split('_');
										const nombreMapaPersonalizadoNuevo = `${avaluoClonado.id}_${nombreMapaPersonalizado}`;
										await copiarArchivoS3({
											archivoDestinoNombre: nombreMapaPersonalizadoNuevo,
											archivoOrigenNombre: nombreMapaPersonalizadoOriginal,
											bucketDestinoNombre: awsS3BucketAvaluoMapas,
											bucketOrigenNombre: awsS3BucketAvaluoMapas
										});
										avaluoClonado.ubicacion.mapaPersonalizadoImagenNombre = nombreMapaPersonalizadoNuevo;
									}
								}
								avaluoClonado = formatearAvaluoParaBD(avaluoClonado);
								await crearAvaluo({ auth0AccessToken, valores: avaluoClonado });
								return avaluoClonado;
							} else {
								const errorMensaje = `El usuario no puede estar vacío`;
								throw new Error(errorMensaje);
							}
						} else {
							const errorMensaje = `Las opciones no pueden estar vacías`;
							throw new Error(errorMensaje);
						}
					} else {
						const errorMensaje = `El bucket de los mapas no puede estar vacío`;
						throw new Error(errorMensaje);
					}
				} else {
					const errorMensaje = `El bucket de las fotos no puede estar vacío`;
					throw new Error(errorMensaje);
				}
			} else {
				const errorMensaje = `El avaluo no puede estar vacío`;
				throw new Error(errorMensaje);
			}
		} else {
			const errorMensaje = `El token de auth0 no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	};
};

/**
 * Elimina un avalúo.
 */
export const useEliminarAvaluo = () => {
	const { configuracionSistema, sesion } = useSelector((state: StateType) => state);
	const { auth0 } = sesion;
	const { auth0AccessToken } = auth0;
	const { awsS3BucketAvaluoFotos, awsS3BucketAvaluoMapas } = configuracionSistema;
	return (avaluo?: Avaluo) => {
		if (avaluo) {
			const promesasEliminar: Array<Promise<void>> = [];
			const { fotos, id, ubicacion } = avaluo;
			if (ubicacion) {
				const { croquisImagenNombre, mapaImagenNombre, mapaPersonalizadoImagenNombre } = ubicacion;
				if (croquisImagenNombre) {
					promesasEliminar.push(eliminarArchivoS3({ archivoNombre: croquisImagenNombre, bucketNombre: awsS3BucketAvaluoMapas }));
				}
				if (mapaImagenNombre) {
					promesasEliminar.push(eliminarArchivoS3({ archivoNombre: mapaImagenNombre, bucketNombre: awsS3BucketAvaluoMapas }));
				}
				if (mapaPersonalizadoImagenNombre) {
					promesasEliminar.push(eliminarArchivoS3({ archivoNombre: mapaPersonalizadoImagenNombre, bucketNombre: awsS3BucketAvaluoMapas }));
				}
			}
			if (fotos) {
				fotos.forEach((foto: AvaluoFoto) => {
					promesasEliminar.push(eliminarArchivoS3({ archivoNombre: foto.nombre, bucketNombre: awsS3BucketAvaluoFotos }));
				});
			}
			return Promise.all(promesasEliminar).then(async () => eliminarAvaluo({ auth0AccessToken, id }));
		} else {
			const errorMensaje = `El avaluo no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	};
};

/**
 * Formatea un avalúo para generar el PDF.
 */
export const useGenerarAvaluoPdfContenido = () => {
	const { configuracionSistema } = useSelector((state: StateType) => state);
	return async ({ avaluo, formato, opciones, usuario }: { avaluo?: Avaluo; formato: 'general' | 'personalizado' | 'zapopan'; opciones: UsuarioPreferencias; usuario: Usuario }) => {
		if (avaluo) {
			const avaluoFormateadoPdf = await formatearAvaluoParaPdf({ avaluo, configuracionSistema });
			let avaluoPdf = null;
			let opcionesPdf: any = {};
			if (opciones) {
				const {
					avaluosPdfEtiquetaConstruccionCodigo,
					avaluosPdfMostrarCoeficientesUtilizacionOcupacion,
					avaluosPdfMostrarCoordenadasDD,
					avaluosPdfMostrarCoordenadasDMS,
					avaluosPdfMostrarCoordenadasUTM,
					avaluosPdfMostrarLicenciaFoto,
					avaluosPdfMostrarNumeroPagina,
					avaluosPdfRellenarEspaciosSinFoto,
					avaluosPdfTipoEncabezado
				} = opciones;
				opcionesPdf = {
					etiquetaConstruccionCodigo: avaluosPdfEtiquetaConstruccionCodigo,
					mostrarCoeficientesUtilizacionOcupacion: avaluosPdfMostrarCoeficientesUtilizacionOcupacion,
					mostrarCoordenadasDD: avaluosPdfMostrarCoordenadasDD,
					mostrarCoordenadasDMS: avaluosPdfMostrarCoordenadasDMS,
					mostrarCoordenadasUTM: avaluosPdfMostrarCoordenadasUTM,
					mostrarLicenciaFoto: avaluosPdfMostrarLicenciaFoto,
					mostrarNumeroPagina: avaluosPdfMostrarNumeroPagina,
					rellenarEspaciosSinFoto: avaluosPdfRellenarEspaciosSinFoto,
					tipoEncabezado: avaluosPdfTipoEncabezado
				};
			}
			const fecha = avaluoFormateadoPdf.antecedentes?.fechaAvaluo ? new Date(avaluoFormateadoPdf.antecedentes?.fechaAvaluo) : new Date();
			const dia = fecha.getDate().toString().padStart(2, '0');
			const mes = fecha.getMonth().toString().padStart(2, '0');
			const ano = fecha.getFullYear().toString().padStart(2, '0');
			const tituloFormato = PDF_FORMATOS[formato];
			const tituloPdf = `Avaluo Folio ${avaluoFormateadoPdf.folio} (${ano}-${mes}-${dia}) [${tituloFormato}]`;
			switch (formato) {
				case 'personalizado':
					avaluoPdf = await generarContenidoPdfPersonalizado({ avaluo: avaluoFormateadoPdf, opciones: opcionesPdf, tituloPdf, usuario });
					break;
				case 'zapopan':
					avaluoPdf = await generarContenidoPdfZapopan({ avaluo: avaluoFormateadoPdf, tituloPdf, usuario });
					break;
				default:
					avaluoPdf = await generarContenidoPdfGeneral({ avaluo: avaluoFormateadoPdf, tituloPdf, usuario });
					break;
			}
			return { contenido: avaluoPdf, titulo: tituloPdf };
		} else {
			const errorMensaje = `El avaluo no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	};
};

/**
 * Guarda un avalúo.
 */
export const useGuardarAvaluo = () => {
	const { sesion } = useSelector((state: StateType) => state);
	const { auth0 } = sesion;
	const { auth0AccessToken } = auth0;
	return async ({ avaluo, usuario }: { avaluo?: Avaluo; usuario?: Usuario }) => {
		if (avaluo) {
			if (usuario) {
				avaluo.id = uuidv4();
				avaluo.usuarioId = usuario.id;
				avaluo = formatearAvaluoParaBD(avaluo);
				await crearAvaluo({ auth0AccessToken, valores: avaluo });
				return avaluo;
			} else {
				const errorMensaje = `El usuario no puede estar vacío`;
				throw new Error(errorMensaje);
			}
		} else {
			const errorMensaje = `El avaluo no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	};
};

/**
 * Copia las fotos existentes en el bucket de AWS S3 para el nuevo avalúo clonado.
 */
const copiarFotos = ({
	avaluoDestino,
	avaluoOriginal,
	awsS3BucketAvaluoFotos
}: {
	avaluoDestino: Avaluo;
	avaluoOriginal: Avaluo;
	awsS3BucketAvaluoFotos?: string;
}): Promise<Array<AvaluoFoto>> =>
	new Promise((resolve) => {
		const promesas: Array<Promise<any>> = [];
		const fotosNuevas: Array<AvaluoFoto> = [];
		avaluoOriginal.fotos?.forEach(async (foto: AvaluoFoto) => {
			const nombreFotoOriginal = foto.nombre;
			const archivoId = uuidv4();
			const archivoExtension = obtenerExtensionArchivo(nombreFotoOriginal);
			const archivoNombre = `${avaluoDestino.id}_${archivoId}.${archivoExtension}`;
			await copiarArchivoS3({
				archivoDestinoNombre: archivoNombre,
				archivoOrigenNombre: nombreFotoOriginal,
				bucketDestinoNombre: awsS3BucketAvaluoFotos,
				bucketOrigenNombre: awsS3BucketAvaluoFotos
			});
			fotosNuevas.push({
				avaluoId: avaluoDestino.id,
				id: archivoId,
				indice: foto.indice,
				nombre: archivoNombre
			});
		});
		Promise.all(promesas).then(() => {
			resolve(fotosNuevas);
		});
	});

/**
 * Formatea un formulario de un avalúo para ser guardado en la base de datos.
 * - ***valores*** - Objeto con los valores del formulario.
 */
const formatearAvaluoParaBD = (valores: any) => {
	const avaluo = valores as Avaluo;
	avaluo.valorTotal = obtenerTotal(avaluo);
	avaluo.estatus = obtenerEstatus(avaluo);
	return avaluo;
};

/**
 * Formatea un avalúo para generar el PDF.
 */
const formatearAvaluoParaPdf = async ({ avaluo, configuracionSistema }: { avaluo: Avaluo; configuracionSistema: ConfiguracionSistema }) => {
	const { awsS3BucketAvaluoFotos, awsS3BucketAvaluoMapas, awsS3BucketPeritoLicencia } = configuracionSistema;
	const { antecedentes, fotos, ubicacion } = avaluo;
	if (antecedentes?.perito?.licenciaFoto) {
		try {
			const licenciaBase64 = await obtenerArchivoS3Base64({ archivoNombre: antecedentes?.perito?.licenciaFoto, bucketNombre: awsS3BucketPeritoLicencia });
			avaluo.pdfLicenciaBase64 = licenciaBase64.datos;
		} catch (error) {
			avaluo.pdfLicenciaBase64 = 'error';
		}
	}
	if (ubicacion?.croquisImagenNombre) {
		try {
			const croquisBase64 = await obtenerArchivoS3Base64({ archivoNombre: ubicacion.croquisImagenNombre, bucketNombre: awsS3BucketAvaluoMapas });
			avaluo.pdfCroquisBase64 = croquisBase64.datos;
		} catch (error) {
			avaluo.pdfCroquisBase64 = 'error';
		}
	}
	if (ubicacion?.mapaImagenNombre) {
		try {
			const mapaBase64 = await obtenerArchivoS3Base64({ archivoNombre: ubicacion.mapaImagenNombre, bucketNombre: awsS3BucketAvaluoMapas });
			avaluo.pdfMapaBase64 = mapaBase64.datos;
		} catch (error) {
			avaluo.pdfMapaBase64 = 'error';
		}
	}
	if (ubicacion?.mapaPersonalizadoImagenNombre) {
		try {
			const mapaPersonalizadoBase64 = await obtenerArchivoS3Base64({ archivoNombre: ubicacion.mapaPersonalizadoImagenNombre, bucketNombre: awsS3BucketAvaluoMapas });
			avaluo.pdfMapaPersonalizadoBase64 = mapaPersonalizadoBase64.datos;
		} catch (error) {
			avaluo.pdfMapaPersonalizadoBase64 = 'error';
		}
	}
	if (fotos) {
		const promesas: Array<Promise<void | McRespuesta>> = [];
		const fotosBase64: Array<string> = [];
		fotos.forEach((foto) => {
			promesas.push(
				obtenerArchivoS3Base64({ archivoNombre: foto.nombre, bucketNombre: awsS3BucketAvaluoFotos }).catch(() => {
					// No hacer nada para permitir continuar
				})
			);
		});
		const respuestas = await Promise.all(promesas);
		respuestas.forEach((respuesta) => {
			if (respuesta) {
				fotosBase64.push(respuesta.datos);
			} else {
				fotosBase64.push('error');
			}
		});
		avaluo.pdfFotosBase64 = fotosBase64;
	}
	return avaluo;
};

/**
 * Obtiene el estatus del avalúo.
 */
const obtenerEstatus = (avaluo: Avaluo) => {
	if (avaluo.antecedentes) {
		if (
			!tieneValor(avaluo.antecedentes.fechaAvaluo) ||
			!tieneValor(avaluo.antecedentes.peritoId) ||
			!tieneValor(avaluo.antecedentes.cuentaPredial) ||
			!tieneValor(avaluo.antecedentes.nombrePropietario) ||
			!tieneValor(avaluo.antecedentes.nombreSolicitante) ||
			!tieneValor(avaluo.antecedentes.tipoInmuebleId) ||
			!tieneValor(avaluo.antecedentes.regimenPropiedadId) ||
			!tieneValor(avaluo.antecedentes.ubicacionPredio)
		) {
			return 'incompleto';
		}
	}
	if (
		avaluo.construcciones &&
		avaluo.instalacionesEspeciales &&
		avaluo.terreno &&
		avaluo.construcciones.detalles &&
		avaluo.instalacionesEspeciales.detalles &&
		avaluo.terreno.detalles &&
		avaluo.construcciones.detalles.length === 0 &&
		avaluo.instalacionesEspeciales.detalles.length === 0 &&
		avaluo.terreno.detalles.length === 0
	) {
		return 'incompleto';
	}
	if (avaluo.construccionesDominantes) {
		if (!tieneValor(avaluo.construccionesDominantes.descripcion)) {
			return 'incompleto';
		}
	}
	if (avaluo.distribucion) {
		if (!tieneValor(avaluo.distribucion.descripcion)) {
			return 'incompleto';
		}
	}
	if (avaluo.domicilio) {
		if (!tieneValor(avaluo.domicilio.estadoId) || !tieneValor(avaluo.domicilio.municipioId)) {
			return 'incompleto';
		}
	}
	if (avaluo.elementosConstruccion) {
		if (
			!tieneValor(avaluo.elementosConstruccion.cimientos) ||
			!tieneValor(avaluo.elementosConstruccion.estructura) ||
			!tieneValor(avaluo.elementosConstruccion.muro) ||
			!tieneValor(avaluo.elementosConstruccion.barda) ||
			!tieneValor(avaluo.elementosConstruccion.entrepiso) ||
			!tieneValor(avaluo.elementosConstruccion.techo) ||
			!tieneValor(avaluo.elementosConstruccion.azotea) ||
			!tieneValor(avaluo.elementosConstruccion.aplanado) ||
			!tieneValor(avaluo.elementosConstruccion.plafon) ||
			!tieneValor(avaluo.elementosConstruccion.lambrin) ||
			!tieneValor(avaluo.elementosConstruccion.pavimentoPetreo) ||
			!tieneValor(avaluo.elementosConstruccion.zoclo) ||
			!tieneValor(avaluo.elementosConstruccion.pintura) ||
			!tieneValor(avaluo.elementosConstruccion.recubrimientoEspecial) ||
			!tieneValor(avaluo.elementosConstruccion.escalera) ||
			!tieneValor(avaluo.elementosConstruccion.carpinteria) ||
			!tieneValor(avaluo.elementosConstruccion.instalacionSanitaria) ||
			!tieneValor(avaluo.elementosConstruccion.muebleBano) ||
			!tieneValor(avaluo.elementosConstruccion.muebleCocina) ||
			!tieneValor(avaluo.elementosConstruccion.instalacionElectrica) ||
			!tieneValor(avaluo.elementosConstruccion.herreria) ||
			!tieneValor(avaluo.elementosConstruccion.vidrieria) ||
			!tieneValor(avaluo.elementosConstruccion.cerrajeria) ||
			!tieneValor(avaluo.elementosConstruccion.fachada) ||
			!tieneValor(avaluo.elementosConstruccion.instalacionEspecial)
		) {
			return 'incompleto';
		}
	}
	if (avaluo.fotos && avaluo.fotos.length === 0) {
		return 'incompleto';
	}
	if (avaluo.medidas) {
		if (
			!tieneValor(avaluo.medidas.superficieUnidadId) ||
			!tieneValor(avaluo.medidas.superficieEscritura) ||
			!tieneValor(avaluo.medidas.superficieCatastro) ||
			!tieneValor(avaluo.medidas.superficieReal) ||
			!tieneValor(avaluo.medidas.calidadProyecto) ||
			!tieneValor(avaluo.medidas.usoEspecificoId) ||
			!tieneValor(avaluo.medidas.destinoEspecificoId) ||
			!tieneValor(avaluo.medidas.unidadesRentables)
		) {
			return 'incompleto';
		}
	}
	if (avaluo.serviciosMunicipales) {
		if (!tieneValor(avaluo.serviciosMunicipales.descripcion)) {
			return 'incompleto';
		}
	}
	if (avaluo.ubicacion) {
		if (
			!tieneValor(avaluo.ubicacion.distanciaEsquina) ||
			!tieneValor(avaluo.ubicacion.distanciaEsquinaCalle) ||
			!tieneValor(avaluo.ubicacion.coordenadasDdLatitud) ||
			!tieneValor(avaluo.ubicacion.coordenadasDdLongitud) ||
			!tieneValor(avaluo.ubicacion.coordenadasDmsLatitudCardinal) ||
			!tieneValor(avaluo.ubicacion.coordenadasDmsLatitudGrados) ||
			!tieneValor(avaluo.ubicacion.coordenadasDmsLongitudMinutos) ||
			!tieneValor(avaluo.ubicacion.coordenadasDmsLatitudSegundos) ||
			!tieneValor(avaluo.ubicacion.coordenadasDmsLongitudCardinal) ||
			!tieneValor(avaluo.ubicacion.coordenadasDmsLongitudGrados) ||
			!tieneValor(avaluo.ubicacion.coordenadasDmsLongitudMinutos) ||
			!tieneValor(avaluo.ubicacion.coordenadasDmsLongitudSegundos) ||
			!tieneValor(avaluo.ubicacion.coordenadasUtmEsteX) ||
			!tieneValor(avaluo.ubicacion.coordenadasUtmHuso) ||
			!tieneValor(avaluo.ubicacion.coordenadasUtmNorteY) ||
			!tieneValor(avaluo.ubicacion.coordenadasUtmZona)
		) {
			return 'incompleto';
		}
	}
	if (!tieneValor(avaluo.valorTotal)) {
		return 'incompleto';
	}
	return 'completo';
};

/**
 * Calcula el total del avalúo (Suma de los totales de los terrenos, construcciones e instalaciones especiales).
 */
const obtenerTotal = (avaluo: Avaluo): number => {
	let totalAvaluo = 0;
	if (avaluo.construcciones && avaluo.construcciones.valorTotal) {
		totalAvaluo += avaluo.construcciones.valorTotal;
	}
	if (avaluo.instalacionesEspeciales && avaluo.instalacionesEspeciales.valorTotal) {
		totalAvaluo += avaluo.instalacionesEspeciales.valorTotal;
	}
	if (avaluo.terreno && avaluo.terreno.valorTotal) {
		totalAvaluo += avaluo.terreno.valorTotal;
	}
	return totalAvaluo;
};
