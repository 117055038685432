import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { getSesionAction, setPantallaCargaMostrarAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { actualizarUsuarioPreferencias } from 'servicios/api/usuarios';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import PreferenciasFormulario from 'componentes/usuarios/PreferenciasFormulario';
import { procesarError } from '@mcsoft/api';
import { StateType } from 'store';
import { texto } from 'idiomas';
import UsuarioPreferencias from 'modelo/UsuarioPreferencias';

const NOMBRE_CLASE = 'paginas/app/usuarios/UsuarioPreferencias';

/**
 * En esta página se pueden cambiar las preferencias del usuario en sesión.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const [formulario, setFormulario] = useState<any>();
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { preferencias } = usuario;
	const [pestanaActiva, setPestanaActiva] = useState('1');
	const RUTA_SALIR = constantes.ruta.appInicio;

	useEffect(() => {
		obtenerRegistro();
	}, []);

	useEffect(() => {
		const pestana = new URLSearchParams(location.search).get('pestana');
		setPestanaActiva(pestana ? pestana : 'avaluos');
	}, [location]);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: RUTA_SALIR });
		history.push(RUTA_SALIR);
	};

	/**
	 * Guarda la información del registro.
	 * - ***valores*** - Valores a guardar.
	 */
	const eventoGuardar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoGuardar';
		mcLogger.log({ mensaje: `Guardando las preferencias del usuario.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		try {
			const preferencias = valores as UsuarioPreferencias;
			dispatch(setPantallaCargaMostrarAction(true));
			await actualizarUsuarioPreferencias({ auth0AccessToken, id: usuario.id, valores: preferencias });
			mcLogger.log({ mensaje: `Preferencias del usuario guardadas con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(getSesionAction(auth0));
			dispatch(setPantallaCargaMostrarAction(false));
			mcNotificaciones.exito({ mensaje: texto(`Preferencias del usuario guardadas con éxito.`) });
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	/**
	 * Obtiene la configuración de la interfaz e inicializa el formulario.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		mcLogger.redux({ mensaje: `Obteniendo preferencias de interfaz desde el state...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		try {
			setFormulario(preferencias);
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener las preferencias de interfaz:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={[{ ruta: RUTA_SALIR, titulo: texto('Inicio') }]} icono={constantes.icono.preferencias} titulo={texto('Mis preferencias')} />
			<Card>
				<Form
					initialValues={formulario}
					onSubmit={eventoGuardar}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<CardBody>
								<Row>
									<Col lg="12">
										<PreferenciasFormulario />
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<BarraHerramientas>
									<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
										<i className={constantes.icono.atras}></i> {texto('Salir')}
									</button>
									{pestanaActiva !== 'interfaz' && (
										<button className="btn btn-success" id="botonGuardad" type="submit">
											<i className={constantes.icono.atras}></i> {texto('Guardar')}
										</button>
									)}
								</BarraHerramientas>
							</CardFooter>
						</form>
					)}
				/>
			</Card>
		</Contenedor>
	);
};

export default Pagina;
