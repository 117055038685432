import ListaOpcion, { ListaOpcionTodas } from 'modelo/ListaOpcion';
import { obtenerListaOpcionesTodosParaAvaluo, obtenerListaOpcionesTodosPorTipo } from 'servicios/api/listaOpciones';
import { McCampoSelectorOpcion } from '@mcsoft/formulario';

/**
 * Filtra la lista de opciones de calidades de construcción o estados de conservación segun el tipo de construcción seleccionado.
 * - ***lista*** - Lista de calidades de constricción o estados de conservación formateadas como McCampoSelectorOpcion.
 * - ***tipoConstruccionId*** - Id del tipo de construcción.
 */
export const filtrarListaPorTipoConstruccion = ({ lista, tipoConstruccionId }: { lista?: Array<ListaOpcion>; tipoConstruccionId?: string }): Array<ListaOpcion> => {
	if (lista && tipoConstruccionId) {
		return lista.filter((elemento) => elemento.tiposConstruccion?.includes(`"${tipoConstruccionId}":true`));
	}
	return [];
};

/**
 * Obtiene el texto a mostrar del tipo de elemento de construcción.
 * - ***tipo*** - Tipo de elemento de construcción.
 */
export const formatearListaOpcionTipoTexto = (tipo?: string) => {
	switch (tipo) {
		case 'aplanado':
			return 'Aplanado';
		case 'azotea':
			return 'Azotea';
		case 'barda':
			return 'Barda';
		case 'carpinteria':
			return 'Carpintería';
		case 'cerrajeria':
			return 'Cerrajería';
		case 'cimientos':
			return 'Cimientos';
		case 'entrepiso':
			return 'Entrepiso';
		case 'escalera':
			return 'Escalera';
		case 'estructura':
			return 'Estructura';
		case 'fachada':
			return 'Fachada';
		case 'herreria':
			return 'Herrería';
		case 'instalacionElectrica':
			return 'Instalación Electrica';
		case 'instalacionEspecial':
			return 'Instalación Especial';
		case 'instalacionSanitaria':
			return 'Instalación Sanitaria';
		case 'lambrin':
			return 'Lambrín';
		case 'muebleBano':
			return 'Mueble de Baño';
		case 'muebleCocina':
			return 'Mueble de Cocina';
		case 'muro':
			return 'Muro';
		case 'pavimentoPetreo':
			return 'Pavimento Pétreo';
		case 'pintura':
			return 'Pintura';
		case 'plafon':
			return 'Plafón';
		case 'recubrimientoEspecial':
			return 'Recubrimiento Especial';
		case 'techo':
			return 'Techo';
		case 'vidrieria':
			return 'Vidriería';
		case 'zoclo':
			return 'Zoclo';
		default:
			return 'Desconocido';
	}
};

/**
 * Formatea una lista de opciones para utilizarse como lista de opciones en un McCampoSelector.
 * - ***lista*** - Arreglo con la lista de opciones.
 */
export const formatearListaOpcionesMcCampoSelector = (lista?: Array<ListaOpcion>): Array<McCampoSelectorOpcion> => {
	const listaOpciones: Array<McCampoSelectorOpcion> = [];
	if (lista) {
		lista.forEach((elemento: ListaOpcion) => {
			listaOpciones.push({ nombre: elemento.nombre, valor: elemento.id });
		});
	}
	return listaOpciones;
};

export const obtenerConstruccionCodigoAbreviatura = ({
	calidadValor,
	estadoConservacionValor,
	listaCalidades,
	listaEstadosConservacion,
	listaTiposConstruccion,
	tipoValor
}: {
	calidadValor: string;
	estadoConservacionValor: string;
	listaCalidades?: Array<ListaOpcion>;
	listaEstadosConservacion?: Array<ListaOpcion>;
	listaTiposConstruccion?: Array<ListaOpcion>;
	tipoValor: string;
}) => {
	let abreviatura = '';
	let codigo = '';
	if (listaTiposConstruccion && listaCalidades && listaEstadosConservacion) {
		const tipoFiltrado = listaTiposConstruccion.filter((tipo) => tipoValor === tipo.id);
		const calidadFiltrado = listaCalidades.filter((calidad) => calidadValor === calidad.id);
		const estadoConservacionFiltrado = listaEstadosConservacion.filter((estadoConservacion) => estadoConservacionValor === estadoConservacion.id);
		if (tipoFiltrado.length > 0 && calidadFiltrado.length > 0 && estadoConservacionFiltrado.length > 0) {
			const [tipo] = tipoFiltrado;
			const [calidad] = calidadFiltrado;
			const [estadoConservacion] = estadoConservacionFiltrado;
			abreviatura = `${tipo.abreviatura}${calidad.abreviatura}${estadoConservacion.abreviatura}`;
			codigo = `${tipo.codigo}${calidad.codigo}${estadoConservacion.codigo}`;
		}
	}
	return { abreviatura, codigo };
};

export const obtenerListaCalidadConstruccion = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'calidadConstruccion' });
	return respuesta.datos;
};

export const obtenerListaClasificacionZona = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'clasificacionZona' });
	return respuesta.datos;
};

export const obtenerListaConstruccionDominante = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'construccionDominante' });
	return respuesta.datos;
};

export const obtenerListaDestinoEspecifico = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'destinoEspecifico' });
	return respuesta.datos;
};

export const obtenerListaDistribucionInmueble = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'distribucionInmueble' });
	return respuesta.datos;
};

export const obtenerListaElementosConstruccionAplanado = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'aplanado' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionAzotea = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'azotea' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionBarda = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'barda' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionCarpinteria = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'carpinteria' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionCerrajeria = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'cerrajeria' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionCimientos = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'cimientos' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionEntrepiso = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'entrepiso' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionEscalera = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'escalera' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionEstructura = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'estructura' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionFachada = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'fachada' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionHerreria = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'herreria' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionInstalacionElectrica = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'instalacionElectrica' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionInstalacionEspecial = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'instalacionEspecial' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionInstalacionSanitaria = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'instalacionSanitaria' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionLambrin = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'lambrin' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionMuebleBano = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'muebleBano' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionMuebleCocina = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'muebleCocina' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionMuro = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'muro' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionPavimentoPetreo = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'pavimentoPetreo' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionPintura = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'pintura' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionPlafon = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'plafon' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionRecubrimientoEspecial = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'recubrimientoEspecial' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionTecho = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'techo' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionVidrieria = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'vidrieria' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaElementosConstruccionZoclo = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'zoclo' });
	return ordenarListaElementoConstruccion(respuesta.datos);
};

export const obtenerListaEstadoConservacion = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'estadoConservacion' });
	return respuesta.datos;
};

export const obtenerListaMotivo = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'motivoAjuste' });
	return respuesta.datos;
};

export const obtenerListaRegimenPropiedad = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'regimenPropiedad' });
	return respuesta.datos;
};

export const obtenerListaServicioMunicipal = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'servicioMunicipal' });
	return respuesta.datos;
};

export const obtenerListaTipoCalle = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'tipoCalle' });
	return respuesta.datos;
};

export const obtenerListaTipoConstruccion = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'tipoConstruccion' });
	return respuesta.datos;
};

export const obtenerListaTipoInmueble = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'tipoInmueble' });
	return respuesta.datos;
};

export const obtenerListaUnidadSuperficie = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'unidadSuperficie' });
	return respuesta.datos;
};

export const obtenerListaUsoEspecifico = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'usoEspecifico' });
	return respuesta.datos;
};

export const obtenerListaVientoCardinal = async (auth0AccessToken: string): Promise<Array<ListaOpcion>> => {
	const respuesta = await obtenerListaOpcionesTodosPorTipo({ auth0AccessToken, tipo: 'vientoCardinal' });
	return respuesta.datos;
};

export const obtenerOpcionDeLaLista = (lista: Array<ListaOpcion> | undefined, id?: string): ListaOpcion => {
	if (lista) {
		const resultado = lista.filter((opcion) => opcion.id === id);
		return resultado[0];
	}
	return {} as ListaOpcion;
};

export const obtenerTodasLasListas = async (auth0AccessToken: string): Promise<ListaOpcionTodas> => {
	const respuesta = await obtenerListaOpcionesTodosParaAvaluo(auth0AccessToken);
	return respuesta.datos;
};

const ordenarListaElementoConstruccion = async (lista: Array<ListaOpcion>): Promise<Array<ListaOpcion>> => {
	let listaOrdenada: Array<ListaOpcion> = [];
	const listaNoTiene = lista.filter((opcion) => opcion.nombre === 'No tiene');
	const listaFiltrada = lista.filter((opcion) => opcion.nombre !== 'No tiene');
	if (listaNoTiene.length > 0) {
		listaOrdenada = [listaNoTiene[0], ...listaFiltrada];
	}
	return listaOrdenada;
};
