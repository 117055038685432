import './style.scss';

const GIRO_HORIZONTAL = false;
const ICONO = 'fa-solid fa-spinner fa-spin';
const TEXTO = {
	mensaje: 'Cargando...'
};

export interface McPantallaCargaProps {
	/**
	 * Indica si el icono girará horizontalmente mientras se muestra la pantalla de carga.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	giroHorizontal?: boolean;
	/**
	 * Icono *FontAwesome* que se mostrará cuando la pantalla de carga sea visible.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-spinner fa-spin'*
	 */
	icono?: string;
	/**
	 * Controla la aparición de la pantalla de carga.
	 */
	mostrar: boolean;
	/**
	 * Objeto con los textos personalizados del componente.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	texto?: McPantallaCargaTexto;
}

export interface McPantallaCargaTexto {
	/**
	 * Mensaje que se mostrará mientras la pantalla de carga sea visible.
	 *
	 * > ***Predeterminado:*** *'Cargando...'*
	 */
	mensaje?: string;
}

/**
 * Muestra un mensaje en medio de la pantalla para indicar que la aplicación esta cargando, cubre toda la pantalla y no permite la interacción con la interfaz mientras esta visible.
 */
const McPantallaCarga = (props: McPantallaCargaProps) => {
	const {
		giroHorizontal = GIRO_HORIZONTAL,
		icono = ICONO,
		mostrar
	} = props;
	let { texto } = props;
	texto = { ...TEXTO, ...texto };

	return (
		<div className={mostrar ? 'mc-pantalla-carga__fondo' : 'mc-pantalla-carga__fondo--oculto'}>
			<div className="mc-pantalla-carga__mensaje">
				<div className={giroHorizontal ? 'mc-pantalla-carga__icono--girando' : 'mc-pantalla-carga__icono'}>
					<i className={icono}></i>
				</div>
				<p>{texto.mensaje}</p>
			</div>
		</div>
	);
};

export default McPantallaCarga;