import Avaluo from 'modelo/Avaluo';
import estilos from './estilos';
import pagina01 from './pagina01';
import pagina02 from './pagina02';
import pagina03 from './pagina03';
import pagina04 from './pagina04';
import pagina05 from './pagina05';
import piePagina from './piePagina';
import Usuario from 'modelo/Usuario';

interface OpcionesPDF {
	etiquetaConstruccionCodigo: string;
	mostrarCoeficientesUtilizacionOcupacion: boolean;
	mostrarCoordenadasDD: boolean;
	mostrarCoordenadasDMS: boolean;
	mostrarCoordenadasUTM: boolean;
	mostrarLicenciaFoto: boolean;
	mostrarNumeroPagina: boolean;
	rellenarEspaciosSinFoto: boolean;
	tipoEncabezado: string;
}

const generarContenidoPdf = ({ avaluo, opciones, tituloPdf, usuario }: { avaluo: Avaluo; opciones: OpcionesPDF; tituloPdf: string; usuario: Usuario }): Promise<any> =>
	new Promise((resolve) => {
		const {
			etiquetaConstruccionCodigo,
			mostrarCoeficientesUtilizacionOcupacion,
			mostrarCoordenadasDD,
			mostrarCoordenadasDMS,
			mostrarCoordenadasUTM,
			mostrarLicenciaFoto,
			mostrarNumeroPagina,
			rellenarEspaciosSinFoto,
			tipoEncabezado
		} = opciones;
		const promesas = [];
		promesas.push(
			pagina01({
				avaluo,
				mostrarCoeficientesUtilizacionOcupacion,
				mostrarCoordenadasDD,
				mostrarCoordenadasDMS,
				mostrarCoordenadasUTM,
				tipoEncabezado,
				usuario
			})
		);
		promesas.push(pagina02({ avaluo, tipoEncabezado, usuario }));
		promesas.push(pagina03({ avaluo, etiquetaConstruccionCodigo, tipoEncabezado, usuario }));
		promesas.push(pagina04({ avaluo, rellenarEspaciosSinFoto, tipoEncabezado, usuario }));
		promesas.push(pagina05({ avaluo, mostrarLicenciaFoto, tipoEncabezado, usuario }));
		Promise.all(promesas).then((paginas) => {
			const contenidoCompleto = [].concat.apply([], paginas as any);
			const contenido = {
				content: contenidoCompleto,
				footer: function (paginaActual: number, paginas: number) {
					return piePagina({ avaluo, mostrarNumeroPagina, paginaActual, paginas });
				},
				info: {
					title: tituloPdf
				},
				pageMargins: [38, 35, 38, 60],
				pageOrientation: 'portrait',
				pageSize: 'LETTER',
				styles: estilos,
				watermark: avaluo.estatus === 'incompleto' ? 'INCOMPLETO' : ''
			};
			resolve(contenido);
		});
	});

export default generarContenidoPdf;
