import antecedentes from './antecedentes';
import Avaluo from 'modelo/Avaluo';
import caracteristicasUrbanas from './caracteristicasUrbanas';
import descripcionGeneralPredio from './descripcionGeneralPredio';
import encabezado from './encabezado';
import marcoPagina from './marcoPagina';
import Usuario from 'modelo/Usuario';

const pagina = ({ avaluo, usuario }: { avaluo: Avaluo; usuario: Usuario }) =>
	new Promise((resolve) => {
		const promesas = [];
		promesas.push(encabezado({ avaluo, usuario }));
		promesas.push(antecedentes(avaluo));
		promesas.push(caracteristicasUrbanas(avaluo));
		promesas.push(descripcionGeneralPredio(avaluo));
		Promise.all(promesas).then((secciones) => {
			const contenido = [].concat.apply([], secciones as any);
			resolve(marcoPagina(contenido));
		});
	});

export default pagina;
