import aplicacion from 'configuracion/aplicacion';
import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del PDF del avalúo relacionado a la licencia del perito.
 */
const InformacionAvaluoPdfLicencia = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Se mostrará en el PDF la foto de la licencia del perito sobre la linea de firma de la última página.')}</p>
		</div>
		<div className="text-center mt-2 mb-2">
			<img alt="Ejemplo" src={aplicacion.tutoriales.ejemploAvaluoPdfLicencia} width="480px" />
		</div>
	</Fragment>
);

export default InformacionAvaluoPdfLicencia;
