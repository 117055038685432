/* eslint-disable no-unused-vars */
import './style.scss';
import { Fragment, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';

const TEXTO = {
	listaArchivosTitulo: 'Archivos seleccionados',
	listaArchivosVacia: 'No se ha seleccionado ningún archivo',
	mensaje: 'Arrastra y suelta los archivos aqui, o has clic para seleccionar los archivos.'
};

interface McSelectorArchivosProps {
	/**
	 * Deshabilita el selector de archivos.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	deshabilitado?: boolean;
	/**
	 * Deshabilita la opcion arrastrar y soltar archivos sobre la zona de arrastre.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	deshabilitarArrastrar?: boolean;
	/**
	 * Deshabilita la opcion de abrir el navegador de archivos al hacer clic sobre la zona de arrastre.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	deshabilitarClic?: boolean;
	/**
	 * Deshabilita la opcion de abrir el navegador de archivos al presionar *space*|*enter* mientras la zona de arrastre esta seleccionada.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	deshabilitarTeclado?: boolean;
	/**
	 * Etiqueta que se mostrará sobre el selector de archivos.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	etiqueta?: string;
	/**
	 * Evento que se ejecuta cuando se selecciona un archivo.
	 * >eventoArchivosSeleccionados(archivos: *Array<objeto>*)
	 * - **archivos**: ***Array<objeto>*** Arreglo de objetos con la información de los archivos seleccionados.
	 */
	eventoArchivosSeleccionados: (archivos: Array<File>) => void
	/**
	 * Indica si se mostrarán las miniaturas de los archivos seleccionados [Solo para archivos de imagen].
	 *
	 * > ***Predeterminado:*** *false*
	 */
	mostrarMiniaturas?: boolean;
	/**
	 * Tamaño máximo en bytes permitido para el archivo seleccionado.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	tamanoMaximo?: number;
	/**
	 * Tamaño mínimo en bytes permitido para el archivo seleccionado.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	tamanoMinimo?: number;
	/**
	 * Objeto con los textos personalizados del componente.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	texto?: McSelectorArchivosTexto;
	/**
	 * Arreglo con los tipos de archivo MIME permitidos.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	tiposArchivosAcceptados?: Array<string>;
	/**
	 * Indica si se permitirá seleccionar varios archivos a la vez.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	variosArchivos?: boolean;
}

export interface McSelectorArchivosTexto {
	/**
	 * Título de la lista de archivos seleccionados.
	 *
	 * > ***Predeterminado:*** *'Archivos seleccionados'*
	 */
	listaArchivosTitulo?: string;
	/**
	 * Mensaje que se mostrará en la lista de archivos mientras no se haya seleccionado ningún archivo.
	 *
	 * > ***Predeterminado:*** *'No se ha seleccionado ningún archivo.'*
	 */
	listaArchivosVacia?: string;
	/**
	 * Mensaje que se mostrará en la zona de arrastre del selector de archivos.
	 *
	 * > ***Predeterminado:*** *'Arrastra y suelta los archivos aqui, o has clic para seleccionar los archivos.'*
	 */
	mensaje?: string;
}

/**
 * Permite seleccionar uno o varios archivos.
 */
const McSelectorArchivos = (props: McSelectorArchivosProps) => {
	const [listaArchivos, setListaArchivos] = useState<Array<JSX.Element>>([]);
	const [archivosSeleccionados, setArchivosSeleccionados] = useState([]);
	const {
		deshabilitado = false,
		deshabilitarArrastrar = false,
		deshabilitarClic = false,
		deshabilitarTeclado = false,
		etiqueta,
		eventoArchivosSeleccionados,
		mostrarMiniaturas = false,
		tamanoMaximo,
		tamanoMinimo,
		tiposArchivosAcceptados,
		variosArchivos = false
	} = props;
	let { texto } = props;
	texto = { ...TEXTO, ...texto };

	useEffect(() => {
		const listaArchivos = archivosSeleccionados.map((archivo: any) => {
			if (mostrarMiniaturas) {
				Object.assign(archivo, {
					preview: URL.createObjectURL(archivo)
				});
				return (
					<div className="mc-selector-archivos__miniatura-marco" key={archivo.name}>
						<div className="mc-selector-archivos__miniatura-contenido">
							<img
								className="mc-selector-archivos__miniatura-imagen"
								src={archivo.preview}
							/>
						</div>
					</div>
				);
			} else {
				return (
					<li key={archivo.path}>
						{archivo.path} - {obtenerTamanoArchivo(archivo.size)}
					</li>
				);
			}
		});
		setListaArchivos(listaArchivos);
	}, [archivosSeleccionados]);

	/**
	 * Se ejecuta cuando un archivo ha sido seleccionado.
	 * - ***archivos*** - Arreglo de objetos con la información de los archivos seleccionados.
	 */
	const eventoSoltarArchivo = (archivos: any): void => {
		setArchivosSeleccionados(archivos);
	};

	/**
	 * Obtiene el tamaño del archivo y su unidad [bytes|KB|MB|GB|TB].
	 * - ***tamanoBytes*** - Tamaño del archivo en bytes.
	 */
	const obtenerTamanoArchivo = (tamanoBytes: number): string => {
		const decimales = 2;
		const multiplo = 1024;
		const kiloByte = multiplo;
		const megaByte = multiplo * kiloByte;
		const gigaByte = multiplo * megaByte;
		const teraByte = multiplo * gigaByte;
		let tamano = '0';
		let unidad = '';
		if (tamanoBytes >= teraByte) {
			tamano = (tamanoBytes / teraByte).toFixed(decimales);
			unidad = 'TB';
		} else if (tamanoBytes >= gigaByte) {
			tamano = (tamanoBytes / gigaByte).toFixed(decimales);
			unidad = 'GB';
		} else if (tamanoBytes >= megaByte) {
			tamano = (tamanoBytes / megaByte).toFixed(decimales);
			unidad = 'MB';
		} else if (tamanoBytes >= kiloByte) {
			tamano = (tamanoBytes / kiloByte).toFixed(decimales);
			unidad = 'KB';
		} else if (tamanoBytes < kiloByte) {
			tamano = tamanoBytes.toString();
			unidad = 'bytes';
		}
		return `${tamano} ${unidad}`;
	};

	return (
		<Dropzone
			accept={tiposArchivosAcceptados}
			disabled={deshabilitado}
			maxSize={tamanoMaximo}
			minSize={tamanoMinimo}
			multiple={variosArchivos}
			noClick={deshabilitarClic}
			noDrag={deshabilitarArrastrar}
			noKeyboard={deshabilitarTeclado}
			onDrop={eventoSoltarArchivo}
			onDropAccepted={eventoArchivosSeleccionados}
		>
			{
				({ getInputProps, getRootProps }: { getRootProps: any; getInputProps: any }): any => (
					<Fragment>
						{etiqueta &&
							<label>{etiqueta}</label>
						}
						<div className="mc-selector-archivos__zona-soltar-archivos-contenedor">
							<div {...getRootProps({ className: 'mc-selector-archivos__zona-soltar-archivos-contenido' })}>
								<input {...getInputProps()} />
								<span>{texto?.mensaje}</span>
							</div>
							{tamanoMinimo &&
								<small className="form-text text-muted">
									{`Tamaño mínimo: ${obtenerTamanoArchivo(tamanoMinimo)}`}
								</small>
							}
							{tamanoMaximo &&
								<small className="form-text text-muted">
									{`Tamaño máximo: ${obtenerTamanoArchivo(tamanoMaximo)}`}
								</small>
							}
						</div>
						<div className="mc-selector-archivos__archivos-contenedor">
							<div className="mc-selector-archivos__archivos-titulo">
								{texto?.listaArchivosTitulo}
							</div>
							{listaArchivos.length > 0 && !mostrarMiniaturas &&
								<ul>{listaArchivos}</ul>
							}
							{listaArchivos.length > 0 && mostrarMiniaturas &&
								<div className="mc-selector-archivos__miniatura-contenedor">
									{listaArchivos}
								</div>
							}
							{listaArchivos.length === 0 &&
								<ul>{texto?.listaArchivosVacia}</ul>
							}
						</div>
					</Fragment>
				)
			}
		</Dropzone>
	);
};

export default McSelectorArchivos;