import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

interface RolFormularioPermisosNotificacionesProps {
	/**
	 * Indica si el formulario será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Formulario para los permisos relacionados a las notificaciones.
 */
const RolFormularioPermisosNotificaciones = (props: RolFormularioPermisosNotificacionesProps) => {
	const { soloLectura = false } = props;

	return (
		<Row>
			<Col lg="12">
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionNotificacionesVerLista}
					etiqueta={texto('Ver la lista de notificaciones.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionNotificacionesVerLista}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionNotificacionesEditarDetalles}
					etiqueta={texto('Editar los detalles de las notificaciones.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionNotificacionesEditarDetalles}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionNotificacionesCrear}
					etiqueta={texto('Crear notificaciones nuevas.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionNotificacionesCrear}
					soloLectura={soloLectura}
				/>
				<McSwitchRedondo
					campo={constantes.permiso.appAdministracionNotificacionesEliminar}
					etiqueta={texto('Eliminar notificaciones.')}
					etiquetaLineal
					id={constantes.permiso.appAdministracionNotificacionesEliminar}
					soloLectura={soloLectura}
				/>
			</Col>
		</Row>
	);
};

export default RolFormularioPermisosNotificaciones;
