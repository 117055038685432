import { Col, Row } from 'reactstrap';
import { McRadioBoton, McRadioBotonGrupo } from '@mcsoft/formulario';
import { useDispatch, useSelector } from 'react-redux';
import { ChangeEvent } from 'react';
import { saveSesionPreferenciasAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';

/**
 * Formulario para las preferencias del usuario relacionadas a la interfaz.
 */
const PreferenciasFormularioInterfaz = () => {
	const dispatch = useDispatch();
	const { sesion } = useSelector((state: StateType) => state);
	const { auth0, usuario } = sesion;
	const { auth0AccessToken } = auth0;
	const { preferencias } = usuario;
	const { interfazTema } = preferencias;

	/**
	 * Guarda el cambio en las preferencias del usuario.
	 * - ***evento*** - Evento que ejecuta la función.
	 * - ***atributo*** - Nombre del atributo que cambio.
	 */
	const eventoCambioCheckbox = ({ atributo, evento }: { atributo: string; evento: ChangeEvent<HTMLInputElement> }) => {
		if (evento.target.checked) {
			const nuevasPreferencias = {
				...preferencias,
				[atributo]: evento.target.value
			};
			dispatch(saveSesionPreferenciasAction({ atributo, auth0AccessToken, preferencias: nuevasPreferencias }));
		}
	};

	return (
		<Row>
			<Col lg="12">
				<Row className="mb-3">
					<Col className="mb-3" md="6" xl="4">
						<label>{texto('Barra de navegación')}</label>
						<McRadioBotonGrupo id="interfazTemaGrupo" tipo="boton">
							<McRadioBoton
								campo="interfazTema"
								etiqueta={texto('Vertical')}
								eventoCambio={(evento) => {
									eventoCambioCheckbox({ atributo: 'interfazTema', evento });
								}}
								id="interfazTemaVertical"
								valor="vertical"
							/>
							<McRadioBoton
								campo="interfazTema"
								etiqueta={texto('Horizontal')}
								eventoCambio={(evento) => {
									eventoCambioCheckbox({ atributo: 'interfazTema', evento });
								}}
								id="interfazTemaHorizontal"
								valor="horizontal"
							/>
						</McRadioBotonGrupo>
					</Col>
					<Col className="mb-3" md="6" xl="4">
						<label>{texto('Tipo de interfaz')}</label>
						<McRadioBotonGrupo id="interfazAnchoGrupo" tipo="boton">
							<McRadioBoton
								campo="interfazAncho"
								etiqueta={texto('Fluido')}
								eventoCambio={(evento) => {
									eventoCambioCheckbox({ atributo: 'interfazAncho', evento });
								}}
								id="interfazAnchoFluido"
								valor="fluid"
							/>
							<McRadioBoton
								campo="interfazAncho"
								etiqueta={texto('En caja')}
								eventoCambio={(evento) => {
									eventoCambioCheckbox({ atributo: 'interfazAncho', evento });
								}}
								id="interfazAnchoEnCaja"
								valor="boxed"
							/>
						</McRadioBotonGrupo>
					</Col>
					<Col className="mb-3" md="6" xl="4">
						<label>{texto('Barra superior')}</label>
						<McRadioBotonGrupo id="interfazBarraNavegacionSuperiorTemaGrupo" tipo="boton">
							<McRadioBoton
								campo="interfazBarraNavegacionSuperiorTema"
								etiqueta={texto('Blanco')}
								eventoCambio={(evento) => {
									eventoCambioCheckbox({ atributo: 'interfazBarraNavegacionSuperiorTema', evento });
								}}
								id="interfazBarraNavegacionSuperiorTemaBlanco"
								valor="light"
							/>
							<McRadioBoton
								campo="interfazBarraNavegacionSuperiorTema"
								etiqueta={texto('Oscuro')}
								eventoCambio={(evento) => {
									eventoCambioCheckbox({ atributo: 'interfazBarraNavegacionSuperiorTema', evento });
								}}
								id="interfazBarraNavegacionSuperiorTemaOscuro"
								valor="dark"
							/>
							{interfazTema === 'horizontal' && (
								<McRadioBoton
									campo="interfazBarraNavegacionSuperiorTema"
									etiqueta={texto('Azul')}
									eventoCambio={(evento) => {
										eventoCambioCheckbox({ atributo: 'interfazBarraNavegacionSuperiorTema', evento });
									}}
									id="interfazBarraNavegacionSuperiorTemaAzul"
									valor="colored"
								/>
							)}
						</McRadioBotonGrupo>
					</Col>
					{interfazTema === 'vertical' && (
						<Col className="mb-3" md="6" xl="4">
							<label>{texto('Tipo de barra de navegación')}</label>
							<McRadioBotonGrupo id="interfazBarraNavegacionLateralAnchoGrupo" tipo="boton">
								<McRadioBoton
									campo="interfazBarraNavegacionLateralAncho"
									etiqueta={texto('Normal')}
									eventoCambio={(evento) => {
										eventoCambioCheckbox({ atributo: 'interfazBarraNavegacionLateralAncho', evento });
									}}
									id="interfazBarraNavegacionLateralAnchoNormal"
									valor="default"
								/>
								<McRadioBoton
									campo="interfazBarraNavegacionLateralAncho"
									etiqueta={texto('Compacta')}
									eventoCambio={(evento) => {
										eventoCambioCheckbox({ atributo: 'interfazBarraNavegacionLateralAncho', evento });
									}}
									id="interfazBarraNavegacionLateralAnchoCompacta"
									valor="compact"
								/>
							</McRadioBotonGrupo>
						</Col>
					)}
					{interfazTema === 'vertical' && (
						<Col className="mb-3" md="6" xl="4">
							<label>{texto('Color de la barra de navegación')}</label>
							<McRadioBotonGrupo id="interfazBarraNavegacionLateralTemaGrupo" tipo="boton">
								<McRadioBoton
									campo="interfazBarraNavegacionLateralTema"
									etiqueta={texto('Blanco')}
									eventoCambio={(evento) => {
										eventoCambioCheckbox({ atributo: 'interfazBarraNavegacionLateralTema', evento });
									}}
									id="interfazBarraNavegacionLateralTemaBlanco"
									valor="light"
								/>
								<McRadioBoton
									campo="interfazBarraNavegacionLateralTema"
									etiqueta={texto('Oscuro')}
									eventoCambio={(evento) => {
										eventoCambioCheckbox({ atributo: 'interfazBarraNavegacionLateralTema', evento });
									}}
									id="interfazBarraNavegacionLateralTemaOscuro"
									valor="dark"
								/>
								<McRadioBoton
									campo="interfazBarraNavegacionLateralTema"
									etiqueta={texto('Azul')}
									eventoCambio={(evento) => {
										eventoCambioCheckbox({ atributo: 'interfazBarraNavegacionLateralTema', evento });
									}}
									id="interfazBarraNavegacionLateralTemaAzul"
									valor="colored"
								/>
							</McRadioBotonGrupo>
						</Col>
					)}
				</Row>
			</Col>
		</Row>
	);
};

export default PreferenciasFormularioInterfaz;
