import { SET_PANTALLA_CARGA, SET_PANTALLA_CARGA_MOSTRAR } from './actionTypes';
import constantes from 'configuracion/constantes';
import mcLogger from '@mcsoft/logger';
import PantallaCarga from 'modelo/PantallaCarga';
import { ReduxAccion } from 'store/actions';
import { texto } from 'idiomas';

const NOMBRE_CLASE = 'store/pantallaCarga/reducer';

const stateInicial: PantallaCarga = {
	giroHorizontal: false,
	icono: constantes.icono.cargando,
	mostrar: false,
	texto: {
		mensaje: `${texto('Cargando')}...`
	}
};

export default (state = stateInicial, action: ReduxAccion) => {
	switch (action.type) {
		case SET_PANTALLA_CARGA:
			const { pantallaCarga } = action.payload;
			state = {
				...state,
				...pantallaCarga
			};
			mcLogger.redux({
				mensaje: 'Seteando state "pantallaCarga":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PANTALLA_CARGA,
				objetoExtra: pantallaCarga
			});
			break;
		case SET_PANTALLA_CARGA_MOSTRAR:
			const { mostrar } = action.payload;
			state = {
				...state,
				giroHorizontal: false,
				icono: constantes.icono.cargando,
				mostrar: mostrar,
				texto: {
					mensaje: `${texto('Cargando')}...`
				}
			};
			mcLogger.redux({
				mensaje: 'Seteando state "pantallaCarga":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_PANTALLA_CARGA_MOSTRAR,
				objetoExtra: state
			});
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
