import { StateType } from 'store';
import { useSelector } from 'react-redux';

/**
 * Formatea un numero decimal con comas.
 */
const useDecimal = () => {
	const { decimalLocale } = useSelector((state: StateType) => state.configuracionAplicacion);
	return (numero: number | string) => Number(numero).toLocaleString(decimalLocale);
};

export default useDecimal;
