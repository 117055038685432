import { Col, Row } from 'reactstrap';
import {
	convertirCoordenadasDDaDMS,
	convertirCoordenadasDMSaUTM,
	CoordenadasDD,
	crearLinea,
	crearMarcador,
	crearPoligono,
	obtenerImagenMapaUrl,
	obtenerLineaArregloCoordenadasDD,
	obtenerLineaLongitud,
	obtenerPoligonoArregloCoordenadasDD
} from '@mcsoft/google-maps';
import { convertirImagenUrlABase64, obtenerExtensionDeTipoMime, obtenerTipoMimeDeCadenaBase64 } from '@mcsoft/archivos';
import { DrawingManager, GoogleMap, LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
import { esEntornoDesarrollo, tieneValor } from '@mcsoft/validaciones';
import { Fragment, useEffect, useState } from 'react';
import { setImagenesTemporalesFotoMapaAction, setPantallaCargaMostrarAction } from 'store/actions';
import { useForm, useFormState } from 'react-final-form';
import aplicacion from 'configuracion/aplicacion';
import Avaluo from 'modelo/Avaluo';
import AvaluoDetallesDesarrollo from 'componentes/avaluos/AvaluoFormularioUbicacionMapaGoogleDesarrollo';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import { McCampoTexto } from '@mcsoft/formulario';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { procesarError } from '@mcsoft/api';
import { texto } from 'idiomas';
import { useDispatch } from 'react-redux';
import { useGuardarAvaluoMapa } from 'hooks/useImagen';

const NOMBRE_CLASE = 'AvaluoFormularioUbicacionMapaGoogle';
const MAPA_ALTURA = '600px';
const MAPA_ANCHO = '600px';
const MAPA_COORDENADAS_INICIALES = { latitud: 20.673847813399757, longitud: -103.32988446260626 };
const MAPA_LIBRERIAS: any = ['geometry', 'places', 'drawing'];
const MAPA_ZOOM_AL_BUSCAR = 19;
const MAPA_ZOOM_INICIAL = 11;

interface AvaluoFormularioUbicacionMapaGoogleProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoIrInicio: () => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
}

/**
 * Formulario para la sección ubicación mapa de google del avalúo.
 */
const AvaluoFormularioUbicacionMapaGoogle = (props: AvaluoFormularioUbicacionMapaGoogleProps) => {
	const dispatch = useDispatch();
	const guardarAvaluoMapa = useGuardarAvaluoMapa();
	const formulario = useForm();
	const { cambiarValorCampo, limpiarValorCampo } = formulario.mutators;
	const formularioValores = useFormState();
	const { ubicacion } = formularioValores.values;
	const { eventoDeshacer, eventoGuardar, eventoIrInicio, hayCambiosSinGuardar } = props;
	const { mapaCentro: mapaCentroInicial, mapaZoom: mapaZoomInicial } = ubicacion;
	const [mapa, setMapa] = useState<google.maps.Map>();
	const [mapaBuscador, setMapaBuscador] = useState<google.maps.places.SearchBox>();
	const [mapaCentro, setMapaCentro] = useState<google.maps.LatLngLiteral>({
		lat: MAPA_COORDENADAS_INICIALES.latitud,
		lng: MAPA_COORDENADAS_INICIALES.longitud
	});
	const [mapaHerramientaActual, setMapaHerramientaActual] = useState<any>(null);
	const [mapaLinea, setMapaLinea] = useState<google.maps.Polyline>();
	const [mapaPoligono, setMapaPoligono] = useState<google.maps.Polygon>();
	const [mapaMarcador, setMapaMarcador] = useState<google.maps.Marker>();
	const [mapaLineaCoordenadasOriginal, setMapaLineaCoordenadasOriginal] = useState<Array<CoordenadasDD>>([]);
	const [mapaPoligonoCoordenadasOriginal, setMapaPoligonoCoordenadasOriginal] = useState<Array<CoordenadasDD>>([]);
	const [mapaMarcadorCoordenadasOriginal, setMapaMarcadorCoordenadasOriginal] = useState<CoordenadasDD>();
	const [mapaZoom, setMapaZoom] = useState<number>(MAPA_ZOOM_INICIAL);

	useEffect(() => {
		inicializarMapa();
	}, [ubicacion]);

	useEffect(() => {
		agregarEventosMarcador();
	}, [mapaMarcador]);

	useEffect(() => {
		agregarEventosLinea();
	}, [mapaLinea]);

	useEffect(() => {
		agregarEventosPoligono();
	}, [mapaPoligono]);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<span className="avaluos-formulario__mapa-barra-herramientas">
				<button
					className={mapaHerramientaActual === null ? 'btn btn-primary' : 'btn btn-outline-primary'}
					id="botonHerramientaMano"
					onClick={eventoHerramientaMano}
					title={texto('Cursor')}
					type="button"
				>
					<i className={constantes.icono.mano}></i>
				</button>
				<button
					className={mapaHerramientaActual === 'marker' ? 'btn btn-primary' : 'btn btn-outline-primary'}
					disabled={tieneValor(mapaMarcador)}
					id="botonHerramientaMarcador"
					onClick={eventoHerramientaMarcador}
					title={texto('Seleccionar ubicación')}
					type="button"
				>
					<i className={constantes.icono.marcador}></i>
				</button>
				<button
					className={mapaHerramientaActual === 'polygon' ? 'btn btn-primary' : 'btn btn-outline-primary'}
					disabled={tieneValor(mapaPoligono)}
					id="botonHerramientaPoligono"
					onClick={eventoHerramientaPoligono}
					title={texto('Dibujar predio')}
					type="button"
				>
					<i className={constantes.icono.poligono}></i>
				</button>
				<button
					className={mapaHerramientaActual === 'polyline' ? 'btn btn-primary' : 'btn btn-outline-primary'}
					disabled={tieneValor(mapaLinea)}
					id="botonHerramientaLinea"
					onClick={eventoHerramientaLinea}
					title={texto('Distancia a la esquina')}
					type="button"
				>
					<i className={constantes.icono.regla}></i>
				</button>
				<button
					className="btn btn-info"
					disabled={!tieneValor(mapaMarcador) && !tieneValor(mapaPoligono) && !tieneValor(mapaLinea)}
					id="botonLimpiar"
					onClick={eventoMapaLimpiar}
					type="button"
				>
					<i className={constantes.icono.eliminar}></i> {texto('Limpiar')}
				</button>
			</span>
			<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
				<i className={constantes.icono.atras}></i> {texto('Cancelar')}
			</button>
			<button className="btn btn-warning" disabled={!hayCambiosSinGuardar} id="botonDeshacer" onClick={eventoMapaReiniciar} type="button">
				<i className={constantes.icono.deshacer}></i> {texto('Deshacer')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardar} id="botonAceptar" onClick={eventoGuardarMapaGoogle} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardar}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	/**
	 * Inicializa la posición inicial y el zoom del mapa.
	 */
	const inicializarMapa = () => {
		if (mapaCentroInicial) {
			setMapaCentro(mapaCentroInicial);
		}
		if (mapaZoomInicial) {
			setMapaZoom(mapaZoomInicial);
		}
	};

	/**
	 * Guarda la información del mapa de Google en el avalúo.
	 */
	const eventoGuardarMapaGoogle = async () => {
		const nombreMetodo = 'eventoGuardarMapaGoogle';
		try {
			dispatch(setPantallaCargaMostrarAction(true));
			const { id } = formularioValores.values;
			const mapaImagenUrl = obtenerImagenMapaUrl({
				alto: 640,
				ancho: 640,
				googleMapsApiClave: aplicacion.google.claveApi,
				googleMapsApiUrl: aplicacion.google.urlApiMapasEstaticos,
				latitud: mapa?.getCenter()?.lat(),
				longitud: mapa?.getCenter()?.lng(),
				poligono: mapaPoligono,
				tipoMapa: 'roadmap',
				zoom: mapa?.getZoom()
			});
			const mapaBase64 = await convertirImagenUrlABase64(mapaImagenUrl);
			const mapaTipoMime = obtenerTipoMimeDeCadenaBase64(mapaBase64);
			const mapaExtension = obtenerExtensionDeTipoMime(mapaTipoMime);
			const nombreArchivoMapa = `${id}_mapa_${new Date().getTime()}.${mapaExtension}`;
			await guardarAvaluoMapa({ archivoBase64: mapaBase64, archivoNombre: nombreArchivoMapa, archivoTipo: mapaTipoMime });
			dispatch(setImagenesTemporalesFotoMapaAction({ avaluoId: id, imagenBase64: mapaBase64 }));
			cambiarValorCampo('ubicacion.mapaImagenGoogleMapsApi', mapaImagenUrl);
			cambiarValorCampo('ubicacion.mapaImagenNombre', nombreArchivoMapa);
			const avaluo = formularioValores.values as Avaluo;
			if (avaluo && avaluo.ubicacion) {
				avaluo.ubicacion.mapaImagenGoogleMapsApi = mapaImagenUrl;
				avaluo.ubicacion.mapaImagenNombre = nombreArchivoMapa;
			}
			eventoGuardar(avaluo);
			eventoIrInicio();
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Habilita la herramienta linea del mapa.
	 */
	const eventoHerramientaLinea = () => {
		setMapaHerramientaActual('polyline');
	};

	/**
	 * Habilita la herramienta mano del mapa.
	 */
	const eventoHerramientaMano = () => {
		setMapaHerramientaActual(null);
	};

	/**
	 * Habilita la herramienta marcador del mapa.
	 */
	const eventoHerramientaMarcador = () => {
		setMapaHerramientaActual('marker');
	};

	/**
	 * Habilita la herramienta poligono del mapa.
	 */
	const eventoHerramientaPoligono = () => {
		setMapaHerramientaActual('polygon');
	};

	/**
	 * Deshace los cambios y redirige a la sección de inicio de la ubicación.
	 */
	const eventoCancelar = () => {
		eventoDeshacer();
		eventoIrInicio();
	};

	/**
	 * Limpia las coordenadas DMS y UTM del formulario.
	 */
	const limpiarCoordenadas = () => {
		limpiarValorCampo('ubicacion.coordenadasDdLatitud');
		limpiarValorCampo('ubicacion.coordenadasDdLongitud');
		limpiarValorCampo('ubicacion.coordenadasDmsLatitudGrados');
		limpiarValorCampo('ubicacion.coordenadasDmsLatitudMinutos');
		limpiarValorCampo('ubicacion.coordenadasDmsLatitudSegundos');
		limpiarValorCampo('ubicacion.coordenadasDmsLatitudCardinal');
		limpiarValorCampo('ubicacion.coordenadasDmsLongitudGrados');
		limpiarValorCampo('ubicacion.coordenadasDmsLongitudMinutos');
		limpiarValorCampo('ubicacion.coordenadasDmsLongitudSegundos');
		limpiarValorCampo('ubicacion.coordenadasDmsLongitudCardinal');
		limpiarValorCampo('ubicacion.coordenadasUtmZona');
		limpiarValorCampo('ubicacion.coordenadasUtmHuso');
		limpiarValorCampo('ubicacion.coordenadasUtmEsteX');
		limpiarValorCampo('ubicacion.coordenadasUtmNorteY');
	};

	/**
	 * Convierte las coordenadas DD y las convierte a DMS y UTM y setea los valores en el formulario.
	 */
	const obtenerCoordenadas = ({ latitud, longitud }: { latitud: number; longitud: number }) => {
		const coordenadadDD = { latitud, longitud };
		const coordenadasDMS = convertirCoordenadasDDaDMS({ latitud, longitud });
		const coordenadasUTM = convertirCoordenadasDMSaUTM(coordenadasDMS);
		cambiarValorCampo('ubicacion.coordenadasDdLatitud', coordenadadDD.latitud);
		cambiarValorCampo('ubicacion.coordenadasDdLongitud', coordenadadDD.longitud);
		cambiarValorCampo('ubicacion.coordenadasDmsLatitudGrados', coordenadasDMS.latitud.grados);
		cambiarValorCampo('ubicacion.coordenadasDmsLatitudMinutos', coordenadasDMS.latitud.minutos);
		cambiarValorCampo('ubicacion.coordenadasDmsLatitudSegundos', coordenadasDMS.latitud.segundos);
		cambiarValorCampo('ubicacion.coordenadasDmsLatitudCardinal', coordenadasDMS.latitud.cardinal);
		cambiarValorCampo('ubicacion.coordenadasDmsLongitudGrados', coordenadasDMS.longitud.grados);
		cambiarValorCampo('ubicacion.coordenadasDmsLongitudMinutos', coordenadasDMS.longitud.minutos);
		cambiarValorCampo('ubicacion.coordenadasDmsLongitudSegundos', coordenadasDMS.longitud.segundos);
		cambiarValorCampo('ubicacion.coordenadasDmsLongitudCardinal', coordenadasDMS.longitud.cardinal);
		cambiarValorCampo('ubicacion.coordenadasUtmZona', coordenadasUTM.zonaUTM);
		cambiarValorCampo('ubicacion.coordenadasUtmHuso', coordenadasUTM.huso);
		cambiarValorCampo('ubicacion.coordenadasUtmEsteX', coordenadasUTM.esteX);
		cambiarValorCampo('ubicacion.coordenadasUtmNorteY', coordenadasUTM.norteY);
	};

	// ****************************************************************************************************
	// Buscador
	// ****************************************************************************************************

	/**
	 * Evento que se ejecuta cuando cambia el campo buscador del mapa.
	 */
	const eventoBuscadorCambio = () => {
		const nombreMetodo = 'eventoBuscadorCambio';
		if (mapaBuscador) {
			mcLogger.dev({ mensaje: `Cambió el buscador del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const lugares = mapaBuscador.getPlaces();
			if (lugares && lugares.length > 0) {
				const [lugar] = lugares;
				if (lugar) {
					const latitud = lugar.geometry?.location?.lat();
					const longitud = lugar.geometry?.location?.lng();
					if (latitud && longitud) {
						setMapaCentro({ lat: latitud, lng: longitud });
						if (mapaMarcador) {
							mapaMarcador?.setPosition({ lat: latitud, lng: longitud });
						} else {
							if (mapa) {
								const marcador = new google.maps.Marker({
									clickable: true,
									draggable: true,
									position: {
										lat: latitud,
										lng: longitud
									}
								});
								marcador.setMap(mapa);
								setMapaMarcador(marcador);
								setMapaZoom(MAPA_ZOOM_AL_BUSCAR);
								setMapaHerramientaActual(null);
							}
						}
						cambiarValorCampo('ubicacion.mapaCentro', { lat: latitud, lng: longitud });
						cambiarValorCampo('ubicacion.coordenadasDdLatitud', latitud);
						cambiarValorCampo('ubicacion.coordenadasDdLongitud', longitud);
						obtenerCoordenadas({ latitud, longitud });
					}
				}
			}
		}
	};

	/**
	 * Evento que se ejecuta cuando carga el buscador del mapa.
	 * - ***buscador*** - Buscador de Google maps.
	 */
	const eventoBuscadorCargo = (buscador: google.maps.places.SearchBox) => {
		const nombreMetodo = 'eventoBuscadorCargo';
		mcLogger.dev({ mensaje: `Cargó el buscador del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		setMapaBuscador(buscador);
	};

	// ****************************************************************************************************
	// Mapa
	// ****************************************************************************************************

	/**
	 * Dibuja una linea en el mapa de Google.
	 * - ***arregloCoordenadasDD*** - Arreglo de CoordenadasDD de los vertices de la linea.
	 * - ***mapa*** - Mapa de Google.
	 */
	const dibujarLinea = ({ arregloCoordenadasDD, mapa }: { arregloCoordenadasDD: Array<CoordenadasDD>; mapa: google.maps.Map }) => {
		const nombreMetodo = 'dibujarLinea';
		if (arregloCoordenadasDD && arregloCoordenadasDD.length > 0) {
			mcLogger.dev({ mensaje: `Dibujando la linea del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const linea = crearLinea({
				arregloCoordenadasDD,
				color: '3333ff'
			});
			linea.setMap(mapa);
			setMapaLinea(linea);
			setMapaLineaCoordenadasOriginal(arregloCoordenadasDD);
		}
	};

	/**
	 * Dibuja un marcador en el mapa de Google.
	 * - ***coordenadasDD*** - CoordenadasDD del marcador.
	 * - ***mapa*** - Mapa de Google.
	 */
	const dibujarMarcador = ({ coordenadasDD, mapa }: { coordenadasDD?: CoordenadasDD; mapa: google.maps.Map }) => {
		const nombreMetodo = 'dibujarMarcador';
		if (coordenadasDD?.latitud && coordenadasDD.longitud) {
			mcLogger.dev({ mensaje: `Dibujando el marcador del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: coordenadasDD });
			const marcador = crearMarcador({
				coordenadasDD
			});
			marcador.setMap(mapa);
			setMapaMarcador(marcador);
			setMapaMarcadorCoordenadasOriginal(coordenadasDD);
		}
	};

	/**
	 * Dibuja un poligono en el mapa de Google.
	 * - ***arregloCoordenadasDD*** - Arreglo de CoordenadasDD de los vertices del polígono.
	 * - ***mapa*** - Mapa de Google.
	 */
	const dibujarPoligono = ({ arregloCoordenadasDD, mapa }: { arregloCoordenadasDD: Array<CoordenadasDD>; mapa: google.maps.Map }) => {
		const nombreMetodo = 'dibujarPoligono';
		if (arregloCoordenadasDD && arregloCoordenadasDD.length > 0) {
			mcLogger.dev({ mensaje: `Dibujando el polígono del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const poligono = crearPoligono({
				arregloCoordenadasDD
			});
			poligono.setMap(mapa);
			setMapaPoligono(poligono);
			setMapaPoligonoCoordenadasOriginal(arregloCoordenadasDD);
		}
	};

	/**
	 * Evento que se ejecuta cuando carga el mapa de Google.
	 * - ***mapa*** - Mapa de Google.
	 */
	const eventoMapaCargo = (mapa: google.maps.Map) => {
		const nombreMetodo = 'eventoMapaCargo';
		mcLogger.dev({ mensaje: `Se ha cargado el mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const { contornoCoordenadasDdArreglo, coordenadasDdLatitud, coordenadasDdLongitud, distanciaEsquinaCoordenadasDdArreglo } = ubicacion;
		dibujarLinea({ arregloCoordenadasDD: distanciaEsquinaCoordenadasDdArreglo, mapa });
		dibujarMarcador({ coordenadasDD: { latitud: coordenadasDdLatitud, longitud: coordenadasDdLongitud }, mapa });
		dibujarPoligono({ arregloCoordenadasDD: contornoCoordenadasDdArreglo, mapa });
		setMapa(mapa);
	};

	/**
	 * Elimina la linea, el poligono y el marcador del mapa de Google.
	 */
	const eventoMapaLimpiar = () => {
		const nombreMetodo = 'eventoMapaLimpiar';
		mcLogger.dev({ mensaje: `Limpiando el mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		setMapaHerramientaActual(null);
		eliminarLinea();
		eliminarMarcador();
		eliminarPoligono();
	};

	/**
	 * Elimina la linea, el poligono y el marcador del mapa de Google y redibuja los originales del avalúo.
	 */
	const eventoMapaReiniciar = () => {
		const nombreMetodo = 'eventoMapaReiniciar';
		mcLogger.dev({ mensaje: `Reiniciando el mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		eventoDeshacer();
		setMapaHerramientaActual(null);
		eliminarLinea();
		eliminarMarcador();
		eliminarPoligono();
		if (mapa) {
			dibujarLinea({ arregloCoordenadasDD: mapaLineaCoordenadasOriginal, mapa });
			dibujarMarcador({ coordenadasDD: mapaMarcadorCoordenadasOriginal, mapa });
			dibujarPoligono({ arregloCoordenadasDD: mapaPoligonoCoordenadasOriginal, mapa });
		}
	};

	/**
	 * Evento que se ejecuta cuando carga el mapa de Google.
	 * - ***mapa*** - Mapa de Google.
	 */
	const eventoMapaMover = () => {
		const nombreMetodo = 'eventoMapaMover';
		const latitud = mapa?.getCenter()?.lat();
		const longitud = mapa?.getCenter()?.lng();
		if (latitud && longitud) {
			mcLogger.dev({ mensaje: `Se ha movido el mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const centro = {
				lat: latitud,
				lng: longitud
			};
			setMapaCentro(centro);
			cambiarValorCampo('ubicacion.mapaCentro', {
				lat: latitud,
				lng: longitud
			});
		}
	};

	/**
	 * Evento que se ejecuta cuando cambia el zoom del mapa de Google.
	 */
	const eventoMapaCambioZoom = () => {
		const nombreMetodo = 'eventoMapaCambioZoom';
		if (mapa) {
			const zoom = mapa.getZoom();
			if (zoom) {
				mcLogger.dev({ mensaje: `Se ha cambiado el zoom del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				setMapaZoom(zoom);
				cambiarValorCampo('ubicacion.mapaZoom', zoom);
			}
		}
	};

	// ****************************************************************************************************
	// Marcador
	// ****************************************************************************************************

	/**
	 * Agrega los eventos al marcador del mapa.
	 */
	const agregarEventosMarcador = () => {
		const nombreMetodo = 'agregarEventosMarcador';
		if (mapaMarcador) {
			mcLogger.dev({ mensaje: `Agregando eventos al marcador del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			google.maps.event.addListener(mapaMarcador, 'dragend', () => eventoMarcadorMover());
			google.maps.event.addListener(mapaMarcador, 'rightclick', () => eventoMarcadorClickDerecho());
		}
	};

	/**
	 * Elimina el marcador del mapa.
	 */
	const eliminarMarcador = () => {
		const nombreMetodo = 'eliminarMarcador';
		if (mapaMarcador) {
			mcLogger.dev({ mensaje: `Eliminando marcador del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			mapaMarcador.setMap(null);
			setMapaMarcador(undefined);
			limpiarValorCampo('ubicacion.coordenadasDdLatitud');
			limpiarValorCampo('ubicacion.coordenadasDdLongitud');
			limpiarCoordenadas();
		}
	};

	/**
	 * Evento que se ejecuta cuando se hace click derecho sobre el marcador.
	 */
	const eventoMarcadorClickDerecho = () => {
		const nombreMetodo = 'eventoMarcadorClickDerecho';
		mcLogger.dev({ mensaje: `Se hizo click derecho sobre el marcador del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		eliminarMarcador();
	};

	/**
	 * Evento que se ejecuta cuando se pone el marcador.
	 * - ***marcador*** - Marcador del mapa.
	 */
	const eventoMarcadorCompleto = (marcador: google.maps.Marker) => {
		const nombreMetodo = 'eventoMarcadorSeMovio';
		const latitud = marcador.getPosition()?.lat();
		const longitud = marcador.getPosition()?.lng();
		if (latitud && longitud) {
			mcLogger.dev({ mensaje: `Se ha creado el marcador del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			setMapaMarcador(marcador);
			setMapaHerramientaActual(null);
			cambiarValorCampo('ubicacion.coordenadasDdLatitud', latitud);
			cambiarValorCampo('ubicacion.coordenadasDdLongitud', longitud);
			obtenerCoordenadas({ latitud, longitud });
		}
	};

	/**
	 * Evento que se ejecuta cuando se mueve el marcador.
	 */
	const eventoMarcadorMover = () => {
		const nombreMetodo = 'eventoMarcadorMover';
		if (mapaMarcador) {
			mcLogger.dev({ mensaje: `Se ha movido el marcador del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const latitud = mapaMarcador.getPosition()?.lat();
			const longitud = mapaMarcador.getPosition()?.lng();
			if (latitud && longitud) {
				cambiarValorCampo('ubicacion.coordenadasDdLatitud', latitud);
				cambiarValorCampo('ubicacion.coordenadasDdLongitud', longitud);
				obtenerCoordenadas({ latitud, longitud });
			}
		}
	};

	// ****************************************************************************************************
	// Linea
	// ****************************************************************************************************

	/**
	 * Agrega los eventos a la linea del mapa.
	 * - ***linea*** - Linea del mapa.
	 */
	const agregarEventosLinea = () => {
		const nombreMetodo = 'agregarEventosLinea';
		if (mapaLinea) {
			mcLogger.dev({ mensaje: `Agregando eventos a la linea del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const path = mapaLinea.getPath();
			google.maps.event.addListener(mapaLinea, 'dragend', () => eventoLineaMover());
			google.maps.event.addListener(mapaLinea, 'rightclick', (evento: google.maps.PolyMouseEvent) => eventoLineaClickDerecho(evento));
			google.maps.event.addListener(path, 'insert_at', () => eventoLineaAgregarPunto());
			google.maps.event.addListener(path, 'set_at', () => eventoLineaMoverPunto());
			google.maps.event.addListener(path, 'remove_at', () => eventoLineaEliminarPunto());
		}
	};

	/**
	 * Elimina la linea del mapa.
	 */
	const eliminarLinea = () => {
		const nombreMetodo = 'eliminarLinea';
		if (mapaLinea) {
			mcLogger.dev({ mensaje: `Eliminando la linea del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			mapaLinea.setMap(null);
			setMapaLinea(undefined);
			limpiarValorCampo('ubicacion.distanciaEsquina');
			limpiarValorCampo('ubicacion.distanciaEsquinaCoordenadasDdArreglo');
		}
	};

	/**
	 * Evento que se ejecuta cuando se agrega un nodo a la linea.
	 */
	const eventoLineaAgregarPunto = () => {
		const nombreMetodo = 'eventoLineaAgregarPunto';
		mcLogger.dev({ mensaje: `Se ha agregado un vertice a la linea del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const distanciaEsquinaCoordenadasArreglo = obtenerLineaArregloCoordenadasDD(mapaLinea);
		cambiarValorCampo('ubicacion.distanciaEsquinaCoordenadasDdArreglo', distanciaEsquinaCoordenadasArreglo);
		const distanciaEsquinaMetros = obtenerLineaLongitud(mapaLinea);
		cambiarValorCampo('ubicacion.distanciaEsquina', distanciaEsquinaMetros);
	};

	/**
	 * Evento que se ejecuta cuando se hace click derecho sobre un nodo de la linea.
	 * - ***evento*** - Evento que ejecuta la función.
	 */
	const eventoLineaClickDerecho = (evento: google.maps.PolyMouseEvent) => {
		const nombreMetodo = 'eventoLineaClickDerecho';
		if (mapaLinea && evento.vertex !== undefined) {
			mcLogger.dev({ mensaje: `Se hizo click derecho sobre la linea del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			mapaLinea.getPath().removeAt(evento.vertex);
			if (mapaLinea.getPath().getLength() === 1) {
				mapaLinea.setMap(null);
				eliminarLinea();
			}
		}
	};

	/**
	 * Evento que se ejecuta cuando la linea ha sido completada.
	 * - ***linea*** - Linea del mapa.
	 */
	const eventoLineaCompleta = (linea: google.maps.Polyline) => {
		const nombreMetodo = 'eventoLineaCompleta';
		mcLogger.dev({ mensaje: `Se ha creado la linea del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		setMapaLinea(linea);
		setMapaHerramientaActual(null);
		const distanciaEsquinaCoordenadasArreglo = obtenerLineaArregloCoordenadasDD(linea);
		cambiarValorCampo('ubicacion.distanciaEsquinaCoordenadasDdArreglo', distanciaEsquinaCoordenadasArreglo);
		const distanciaEsquinaMetros = obtenerLineaLongitud(linea);
		cambiarValorCampo('ubicacion.distanciaEsquina', distanciaEsquinaMetros);
	};

	/**
	 * Evento que se ejecuta cuando se elimina un nodo de la linea.
	 */
	const eventoLineaEliminarPunto = () => {
		const nombreMetodo = 'eventoLineaEliminarPunto';
		mcLogger.dev({ mensaje: `Se ha eliminado un vertice de la linea del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const distanciaEsquinaCoordenadasArreglo = obtenerLineaArregloCoordenadasDD(mapaLinea);
		cambiarValorCampo('ubicacion.distanciaEsquinaCoordenadasDdArreglo', distanciaEsquinaCoordenadasArreglo);
		const distanciaEsquinaMetros = obtenerLineaLongitud(mapaLinea);
		cambiarValorCampo('ubicacion.distanciaEsquina', distanciaEsquinaMetros);
	};

	/**
	 * Evento que se ejecuta cuando se mueve la linea.
	 */
	const eventoLineaMover = () => {
		const nombreMetodo = 'eventoLineaMover';
		mcLogger.dev({ mensaje: `Se ha movido la linea del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const distanciaEsquinaCoordenadasArreglo = obtenerLineaArregloCoordenadasDD(mapaLinea);
		cambiarValorCampo('ubicacion.distanciaEsquinaCoordenadasDdArreglo', distanciaEsquinaCoordenadasArreglo);
		const distanciaEsquinaMetros = obtenerLineaLongitud(mapaLinea);
		cambiarValorCampo('ubicacion.distanciaEsquina', distanciaEsquinaMetros);
	};

	/**
	 * Evento que se ejecuta cuando se mueve un nodo de la linea.
	 */
	const eventoLineaMoverPunto = () => {
		const nombreMetodo = 'eventoLineaMoverPunto';
		mcLogger.dev({ mensaje: `Se ha movido un vertice de la linea del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const distanciaEsquinaCoordenadasArreglo = obtenerLineaArregloCoordenadasDD(mapaLinea);
		cambiarValorCampo('ubicacion.distanciaEsquinaCoordenadasDdArreglo', distanciaEsquinaCoordenadasArreglo);
		const distanciaEsquinaMetros = obtenerLineaLongitud(mapaLinea);
		cambiarValorCampo('ubicacion.distanciaEsquina', distanciaEsquinaMetros);
	};

	// ****************************************************************************************************
	// Poligono
	// ****************************************************************************************************

	/**
	 * Agrega los eventos al polígono del mapa.
	 */
	const agregarEventosPoligono = () => {
		const nombreMetodo = 'agregarEventosPoligono';
		if (mapaPoligono) {
			mcLogger.dev({ mensaje: `Agregando eventos al polígono del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const path = mapaPoligono.getPath();
			google.maps.event.addListener(mapaPoligono, 'dragend', () => eventoPoligonoMover());
			google.maps.event.addListener(mapaPoligono, 'rightclick', (evento: google.maps.PolyMouseEvent) => eventoPoligonoClickDerecho(evento));
			google.maps.event.addListener(path, 'insert_at', () => eventoPoligonoAgregarVertice());
			google.maps.event.addListener(path, 'set_at', () => eventoPoligonoMoverVertice());
			google.maps.event.addListener(path, 'remove_at', () => eventoPoligonoEliminarVertice());
		}
	};

	/**
	 * Elimina el polígono del mapa.
	 */
	const eliminarPoligono = () => {
		const nombreMetodo = 'eliminarPoligono';
		if (mapaPoligono) {
			mcLogger.dev({ mensaje: `Eliminando el polígono del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			mapaPoligono?.setMap(null);
			setMapaPoligono(undefined);
			limpiarValorCampo('ubicacion.contornoCoordenadasDdArreglo');
		}
	};

	/**
	 * Evento que se ejecuta cuando se agrega un nodo al polígono.
	 */
	const eventoPoligonoAgregarVertice = () => {
		const nombreMetodo = 'eventoPoligonoAgregarVertice';
		mcLogger.dev({ mensaje: `Se agregó un vertice al polígono del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const poligonoCoordenadasArreglo = obtenerPoligonoArregloCoordenadasDD(mapaPoligono);
		cambiarValorCampo('ubicacion.contornoCoordenadasDdArreglo', poligonoCoordenadasArreglo);
	};

	/**
	 * Evento que se ejecuta cuando se hace click derecho sobre un nodo del polígono.
	 * - ***evento*** - Evento que ejecuta la función.
	 */
	const eventoPoligonoClickDerecho = (evento: google.maps.PolyMouseEvent) => {
		const nombreMetodo = 'eventoPoligonoClickDerecho';
		if (mapaPoligono && evento.vertex !== undefined) {
			mcLogger.dev({ mensaje: `Se hizo click derecho sobre el polígono del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			mapaPoligono.getPath().removeAt(evento.vertex);
			if (mapaPoligono.getPath().getLength() <= 1) {
				eliminarPoligono();
			}
		}
	};

	/**
	 * Evento que se ejecuta cuando se completa el polígono.
	 * - ***poligono*** - Poligono del mapa.
	 */
	const eventoPoligonoCompleto = (poligono: google.maps.Polygon) => {
		const nombreMetodo = 'eventoPoligonoCompleto';
		mcLogger.dev({ mensaje: `Se ha completado el polígono del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		setMapaPoligono(poligono);
		setMapaHerramientaActual(null);
		const poligonoCoordenadasArreglo = obtenerPoligonoArregloCoordenadasDD(poligono);
		cambiarValorCampo('ubicacion.contornoCoordenadasDdArreglo', poligonoCoordenadasArreglo);
	};

	/**
	 * Evento que se ejecuta cuando se elimina un nodo del polígono.
	 */
	const eventoPoligonoEliminarVertice = () => {
		const nombreMetodo = 'eventoPoligonoEliminarVertice';
		mcLogger.dev({ mensaje: `Se eliminó un vertice del polígono del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const poligonoCoordenadasArreglo = obtenerPoligonoArregloCoordenadasDD(mapaPoligono);
		cambiarValorCampo('ubicacion.contornoCoordenadasDdArreglo', poligonoCoordenadasArreglo);
	};

	/**
	 * Evento que se ejecuta cuando se mueve el polígono.
	 */
	const eventoPoligonoMover = () => {
		const nombreMetodo = 'eventoPoligonoMover';
		mcLogger.dev({ mensaje: `Se movió el polígono del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const poligonoCoordenadasArreglo = obtenerPoligonoArregloCoordenadasDD(mapaPoligono);
		cambiarValorCampo('ubicacion.contornoCoordenadasDdArreglo', poligonoCoordenadasArreglo);
	};

	/**
	 * Evento que se ejecuta cuando se mueve un nodo del polígono.
	 */
	const eventoPoligonoMoverVertice = () => {
		const nombreMetodo = 'eventoPoligonoMoverVertice';
		mcLogger.dev({ mensaje: `Se movió un vertice del polígono del mapa.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const poligonoCoordenadasArreglo = obtenerPoligonoArregloCoordenadasDD(mapaPoligono);
		cambiarValorCampo('ubicacion.contornoCoordenadasDdArreglo', poligonoCoordenadasArreglo);
	};

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.mapa}></i> {texto('Ubicación del Predio - Seleccionar Ubicación')}
					</h3>
					<p>{texto('Selecciona la ubicación del predio del avalúo.')}</p>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">
					<LoadScript googleMapsApiKey={aplicacion.google.claveApi ? aplicacion.google.claveApi : ''} libraries={MAPA_LIBRERIAS}>
						<GoogleMap
							center={mapaCentro}
							clickableIcons
							id="McMapa"
							mapContainerClassName="avaluos-formulario__mapa-google__marco"
							mapContainerStyle={{
								height: MAPA_ALTURA,
								width: MAPA_ANCHO
							}}
							onDragEnd={eventoMapaMover}
							onLoad={eventoMapaCargo}
							onZoomChanged={eventoMapaCambioZoom}
							options={{
								rotateControl: false
							}}
							zoom={mapaZoom}
						>
							<Fragment>
								<StandaloneSearchBox onLoad={eventoBuscadorCargo} onPlacesChanged={eventoBuscadorCambio}>
									<input
										placeholder="Buscar domicilio"
										style={{
											border: `1px solid transparent`,
											borderRadius: `3px`,
											boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
											boxSizing: `border-box`,
											fontSize: `14px`,
											height: `32px`,
											left: '200px',
											marginTop: '10px',
											outline: `none`,
											padding: `0 12px`,
											position: 'absolute',
											textOverflow: `ellipses`,
											width: `50%`
										}}
										type="text"
									/>
								</StandaloneSearchBox>
								<DrawingManager
									drawingMode={mapaHerramientaActual}
									onMarkerComplete={eventoMarcadorCompleto}
									onPolygonComplete={eventoPoligonoCompleto}
									onPolylineComplete={eventoLineaCompleta}
									options={{
										drawingControl: false,
										drawingControlOptions: {
											drawingModes: ['marker' as any, 'polyline' as any, 'polygon' as any],
											position: null
										},
										markerOptions: {
											clickable: true,
											draggable: true
										},
										polygonOptions: {
											clickable: true,
											draggable: true,
											editable: true,
											fillColor: '#b0310b',
											fillOpacity: 0.5,
											strokeColor: '#b0310b',
											strokeWeight: 3,
											zIndex: 1
										},
										polylineOptions: {
											clickable: true,
											draggable: true,
											editable: true,
											strokeColor: '#3333ff',
											strokeWeight: 3,
											zIndex: 1
										}
									}}
								/>
							</Fragment>
						</GoogleMap>
					</LoadScript>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoTexto
						campo="ubicacion.distanciaEsquina"
						etiqueta={texto('Distancia a la esquina')}
						id="ubicacion.distanciaEsquina"
						numeroMinimo={0}
						obligatorio
						soloLectura
						textoDerecha={texto('mts.')}
						tipo="numeroNumerico"
					/>
				</Col>
				<Col lg="8">
					<McCampoTexto campo="ubicacion.distanciaEsquinaCalle" etiqueta={texto('Calle')} id="ubicacion.distanciaEsquinaCalle" longitudMaxima={60} obligatorio />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
			{esEntornoDesarrollo() && (
				<Row>
					<Col lg="12">
						<AvaluoDetallesDesarrollo
							avaluoUbicacion={ubicacion}
							googleMapsCentro={mapaCentro}
							googleMapsHerramienta={mapaHerramientaActual}
							googleMapsLinea={mapaLinea}
							googleMapsMarcador={mapaMarcador}
							googleMapsPoligono={mapaPoligono}
							googleMapsZoom={mapaZoom}
						/>
					</Col>
				</Row>
			)}
		</Fragment>
	);
};

export default AvaluoFormularioUbicacionMapaGoogle;
