import { McRespuesta, procesarError, procesarRespuesta } from '@mcsoft/api';
import aplicacion from 'configuracion/aplicacion';
import axios from 'axios';
import mcLogger from '@mcsoft/logger';
import Usuario from 'modelo/Usuario';
import UsuarioPreferencias from 'modelo/UsuarioPreferencias';

const NOMBRE_CLASE = 'servicios/api/usuarios';

/**
 * Actualiza un usuario existente en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del usuario a actualizar.
 * - ***valores*** - Objeto con los valores a actualizar.
 */
export const actualizarUsuario = ({ auth0AccessToken, id, valores }: { auth0AccessToken: string; id: string; valores: Usuario }): Promise<McRespuesta> => {
	const nombreMetodo = 'actualizarUsuario';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Actualizando el registro con el id '${id}' en la base de datos con los siguientes valores:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: valores
		});
		axios
			.put(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.usuarios}/${id}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al actualizar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Actualiza las preferencias de un usuario existente en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del usuario a actualizar.
 * - ***valores*** - Objeto con los valores de las preferencias a actualizar.
 */
export const actualizarUsuarioPreferencias = ({ auth0AccessToken, id, valores }: { auth0AccessToken: string; id: string; valores: UsuarioPreferencias }): Promise<McRespuesta> => {
	const nombreMetodo = 'actualizarUsuarioPreferencias';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Actualizando las preferencias del registro con el id '${id}' en la base de datos con los siguientes valores:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: valores
		});
		axios
			.put(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.usuarios}/${id}/preferencias
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Preferencias del registro el id '${id}' actualizadas con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al actualizar las preferencias del registro el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Crea un usuario en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***valores*** - Objeto con los valores del usuario a crear.
 */
export const crearUsuario = ({ auth0AccessToken, valores }: { auth0AccessToken: string; valores: Usuario }): Promise<McRespuesta> => {
	const nombreMetodo = 'crearUsuario';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Creando registro en la base de datos con los siguientes valores:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: valores });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuarios}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear el registro:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Envía un correo de cambio de contraseña a un usuario existente en el servicio Auth0.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***correoElectronico*** - Auth0Id del usuario al que se enviara el correo.
 */
export const enviarUsuarioCorreoCambioContrasena = ({ auth0AccessToken, correoElectronico }: { auth0AccessToken: string; correoElectronico: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'enviarUsuarioCorreoCambioContrasena';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Enviando correo de cambio de contraseña al usuario con el correo '${correoElectronico}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuarios}/${correoElectronico}/enviarCorreoContrasena
		`,
				{},
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Correo de cambio de contraseña enviado con éxito al usuario con el correo '${correoElectronico}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({
					mensaje: `Error al enviar correo de cambio de contraseña al usuario con el correo '${correoElectronico}':`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcError
				});
				reject(mcError);
			});
	});
};

/**
 * Envía un correo de verificación a un usuario existente en el servicio Auth0.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***auth0Id*** - Auth0Id del usuario al que se enviara el correo.
 */
export const enviarUsuarioCorreoVerificacion = ({ auth0AccessToken, auth0Id }: { auth0AccessToken: string; auth0Id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'enviarUsuarioCorreoVerificacion';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Enviando correo de verificación al usuario con el id '${auth0Id}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuarios}/${auth0Id}/enviarCorreoVerificacion
		`,
				{},
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Correo de verificación enviado con éxito al usuario con el auth0Id '${auth0Id}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({
					mensaje: `Error al enviar correo de verificación al usuario con el auth0Id '${auth0Id}':`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcError
				});
				reject(mcError);
			});
	});
};

/**
 * Obtiene un usuario específico de la base de datos a través de su Auth0Id.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***auth0Id*** - Auth0Id del usuario a obtener.
 */
export const obtenerUsuarioPorAuth0Id = ({ auth0AccessToken, auth0Id }: { auth0AccessToken: string; auth0Id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioPorAuth0Id';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo el registro con el auth0Id '${auth0Id}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuarios}/${auth0Id}/auth0
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el auth0Id '${auth0Id}' obtenido con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener el registro con el auth0Id '${auth0Id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene un usuario específico de la base de datos a través de su id.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del usuario a obtener.
 */
export const obtenerUsuarioPorId = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioPorId';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo registro con el id '${id}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuarios}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' obtenido con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Filtra y ordena los usuarios y los separa en páginas según los parámetros recibidos y obtiene la lista de usuarios de la página especificada.
 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerUsuariosPaginados = ({
	auth0AccessToken,
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	auth0AccessToken: string;
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuariosPaginados';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo registros de la base de datos con los siguientes parámetros:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: {
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			}
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuarios}/paginados
			?pagina=${pagina}
			&registrosPorPagina=${registrosPorPagina}
			&ordenamiento=${ordenamiento}
			&orden=${orden}
			&criterio=${criterio}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene todos los usuarios de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerUsuariosTodos = (auth0AccessToken: string): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuariosTodos';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo todos los registros de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuarios}/todos
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Todos los registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener todos los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Válida si el valor de un campo único está disponible.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***campo*** - Nombre del campo a validar.
 * - ***id*** - Id del usuario para permitir actualización.
 * - ***valor*** - Valor del campo a validar.
 */
export const validarUsuarioDisponibilidad = ({ auth0AccessToken, campo, id, valor }: { auth0AccessToken: string; campo: string; id: string; valor: any }): Promise<boolean> => {
	const nombreMetodo = 'validarUsuarioDisponibilidad';
	return new Promise((resolve, reject) => {
		const valores = { campo, id, valor };
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Validando disponibilidad del valor '${valor}' para el campo '${campo}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuarios}/validarDisponibilidad
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				if (respuesta.data) {
					mcLogger.api({ mensaje: `El valor '${valor}' para el campo '${campo}' esta disponible.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				} else {
					mcLogger.api({ mensaje: `El valor '${valor}' para el campo '${campo}' no esta disponible.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				}
				resolve(respuesta.data);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear el registro:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};
