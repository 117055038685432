import { copiarArchivoS3, eliminarArchivoS3, subirArchivoS3Base64 } from 'servicios/aws/s3';
import { eliminarExtensionArchivo, obtenerExtensionArchivo } from '@mcsoft/archivos';
import aplicacion from 'configuracion/aplicacion';
import Avaluo from 'modelo/Avaluo';
import AvaluoFoto from 'modelo/AvaluoFoto';
import Perito from 'modelo/Perito';
import { StateType } from 'store';
import { useSelector } from 'react-redux';
import Usuario from 'modelo/Usuario';

/**
 * Copia la imagen del croquis del avalúo.
 */
export const useCopiarAvaluoCroquis = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	return ({ archivoDestinoNombre, archivoOrigenNombre }: { archivoDestinoNombre?: string; archivoOrigenNombre?: string }) =>
		copiarArchivoS3({ archivoDestinoNombre, archivoOrigenNombre, bucketDestinoNombre: awsS3BucketAvaluoMapas, bucketOrigenNombre: awsS3BucketAvaluoMapas });
};

/**
 * Copia la foto del avalúo.
 */
export const useCopiarAvaluoFoto = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	return ({ archivoDestinoNombre, archivoOrigenNombre }: { archivoDestinoNombre?: string; archivoOrigenNombre?: string }) =>
		copiarArchivoS3({ archivoDestinoNombre, archivoOrigenNombre, bucketDestinoNombre: awsS3BucketAvaluoMapas, bucketOrigenNombre: awsS3BucketAvaluoMapas });
};

/**
 * Copia la imagen del mapa del avalúo.
 */
export const useCopiarAvaluoMapa = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	return ({ archivoDestinoNombre, archivoOrigenNombre }: { archivoDestinoNombre?: string; archivoOrigenNombre?: string }) =>
		copiarArchivoS3({ archivoDestinoNombre, archivoOrigenNombre, bucketDestinoNombre: awsS3BucketAvaluoMapas, bucketOrigenNombre: awsS3BucketAvaluoMapas });
};

/**
 * Copia la imagen del mapa personalizado del avalúo.
 */
export const useCopiarAvaluoMapaPersonalizado = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	return ({ archivoDestinoNombre, archivoOrigenNombre }: { archivoDestinoNombre?: string; archivoOrigenNombre?: string }) =>
		copiarArchivoS3({ archivoDestinoNombre, archivoOrigenNombre, bucketDestinoNombre: awsS3BucketAvaluoMapas, bucketOrigenNombre: awsS3BucketAvaluoMapas });
};

/**
 * Elimina la imagen del croquis del avalúo.
 */
export const useEliminarAvaluoCroquis = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	return (archivoNombre?: string) => eliminarArchivoS3({ archivoNombre, bucketNombre: awsS3BucketAvaluoMapas });
};

/**
 * Elimina la foto del avalúo.
 */
export const useEliminarAvaluoFoto = () => {
	const { awsS3BucketAvaluoFotos } = useSelector((state: StateType) => state.configuracionSistema);
	return (archivoNombre?: string) => eliminarArchivoS3({ archivoNombre, bucketNombre: awsS3BucketAvaluoFotos });
};

/**
 * Elimina la imagen del mapa del avalúo.
 */
export const useEliminarAvaluoMapa = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	return (archivoNombre?: string) => eliminarArchivoS3({ archivoNombre, bucketNombre: awsS3BucketAvaluoMapas });
};

/**
 * Elimina la imagen del mapa personalizado del avalúo.
 */
export const useEliminarAvaluoMapaPersonalizado = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	return (archivoNombre?: string) => eliminarArchivoS3({ archivoNombre, bucketNombre: awsS3BucketAvaluoMapas });
};

/**
 * Elimina la foto de la licencia del perito.
 */
export const useEliminarPeritoLicencia = () => {
	const { awsS3BucketPeritoLicencia } = useSelector((state: StateType) => state.configuracionSistema);
	return (archivoNombre?: string) => eliminarArchivoS3({ archivoNombre, bucketNombre: awsS3BucketPeritoLicencia });
};

/**
 * Elimina la foto de perfil del usuario.
 */
export const useEliminarUsuarioFoto = () => {
	const { awsS3BucketUsuarioFotos } = useSelector((state: StateType) => state.configuracionSistema);
	return (archivoNombre?: string) => eliminarArchivoS3({ archivoNombre, bucketNombre: awsS3BucketUsuarioFotos });
};

/**
 * Guarda la imagen del croquis del avalúo.
 */
export const useGuardarAvaluoCroquis = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	return ({ archivoBase64, archivoNombre, archivoTipo }: { archivoBase64?: string; archivoNombre?: string; archivoTipo?: string }) =>
		subirArchivoS3Base64({
			archivoBase64,
			archivoNombre,
			archivoTipo,
			bucketNombre: awsS3BucketAvaluoMapas
		});
};

/**
 * Guarda la foto del avalúo.
 */
export const useGuardarAvaluoFoto = () => {
	const { awsS3BucketAvaluoFotos } = useSelector((state: StateType) => state.configuracionSistema);
	return ({ archivoBase64, archivoNombre, archivoTipo }: { archivoBase64?: string; archivoNombre?: string; archivoTipo?: string }) =>
		subirArchivoS3Base64({
			archivoBase64,
			archivoNombre,
			archivoTipo,
			bucketNombre: awsS3BucketAvaluoFotos
		});
};

/**
 * Guarda la imagen del mapa del avalúo.
 */
export const useGuardarAvaluoMapa = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	return ({ archivoBase64, archivoNombre, archivoTipo }: { archivoBase64?: string; archivoNombre?: string; archivoTipo?: string }) =>
		subirArchivoS3Base64({
			archivoBase64,
			archivoNombre,
			archivoTipo,
			bucketNombre: awsS3BucketAvaluoMapas
		});
};

/**
 * Guarda la imagen del mapa personalizado del avalúo.
 */
export const useGuardarAvaluoMapaPersonalizado = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	return ({ archivoBase64, archivoNombre, archivoTipo }: { archivoBase64?: string; archivoNombre?: string; archivoTipo?: string }) =>
		subirArchivoS3Base64({
			archivoBase64,
			archivoNombre,
			archivoTipo,
			bucketNombre: awsS3BucketAvaluoMapas
		});
};

/**
 * Guarda la foto de la licencia del perito.
 */
export const useGuardarPeritoLicencia = () => {
	const { awsS3BucketPeritoLicencia } = useSelector((state: StateType) => state.configuracionSistema);
	return ({ archivoBase64, archivoNombre, archivoTipo }: { archivoBase64?: string; archivoNombre?: string; archivoTipo?: string }) =>
		subirArchivoS3Base64({
			archivoBase64,
			archivoNombre,
			archivoTipo,
			bucketNombre: awsS3BucketPeritoLicencia
		});
};

/**
 * Guarda la foto de perfil del usuario.
 */
export const useGuardarUsuarioFoto = () => {
	const { awsS3BucketUsuarioFotos } = useSelector((state: StateType) => state.configuracionSistema);
	return ({ archivoBase64, archivoNombre, archivoTipo }: { archivoBase64?: string; archivoNombre?: string; archivoTipo?: string }) =>
		subirArchivoS3Base64({
			archivoBase64,
			archivoNombre,
			archivoTipo,
			bucketNombre: awsS3BucketUsuarioFotos
		});
};

/**
 * Obtiene la imagen del croquis del avalúo.
 */
export const useObtenerAvaluoCroquis = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	const { fotosCroquis } = useSelector((state: StateType) => state.imagenesTemporales);

	return (avaluo?: Avaluo) => {
		let ruta = aplicacion.imagenes.predeterminadaAvaluoCroquis;
		const bucketNombre = awsS3BucketAvaluoMapas;
		const bucketEndpoint = `https://${bucketNombre}.s3.${aplicacion.aws.region}.amazonaws.com`;
		if (avaluo && avaluo.ubicacion) {
			const { id, ubicacion } = avaluo;
			const { croquisImagenNombre } = ubicacion;
			if (fotosCroquis[id]) {
				ruta = fotosCroquis[id];
			} else if (awsS3BucketAvaluoMapas && croquisImagenNombre) {
				ruta = `${bucketEndpoint}/${croquisImagenNombre}`;
			}
		}
		return ruta;
	};
};

/**
 * Obtiene la foto del avalúo.
 */
export const useObtenerAvaluoFoto = () => {
	const { awsS3BucketAvaluoFotos, awsS3BucketAvaluoFotosMiniaturas } = useSelector((state: StateType) => state.configuracionSistema);
	const { fotosAvaluo } = useSelector((state: StateType) => state.imagenesTemporales);

	return ({ foto, miniatura = false }: { foto: AvaluoFoto; miniatura?: Boolean }) => {
		let ruta = '';
		let bucketNombre = awsS3BucketAvaluoFotos;
		if (miniatura) {
			bucketNombre = awsS3BucketAvaluoFotosMiniaturas;
		}
		const bucketEndpoint = `https://${bucketNombre}.s3.${aplicacion.aws.region}.amazonaws.com`;
		if (foto) {
			const { id, nombre } = foto;
			if (fotosAvaluo[id]) {
				ruta = fotosAvaluo[id];
			} else if (awsS3BucketAvaluoFotos && awsS3BucketAvaluoFotosMiniaturas) {
				ruta = `${bucketEndpoint}/${nombre}`;
			}
		}
		return ruta;
	};
};

/**
 * Obtiene la imagen del mapa del avalúo.
 */
export const useObtenerAvaluoMapa = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	const { fotosMapas } = useSelector((state: StateType) => state.imagenesTemporales);

	return (avaluo?: Avaluo) => {
		let ruta = aplicacion.imagenes.predeterminadaAvaluoMapa;
		const bucketNombre = awsS3BucketAvaluoMapas;
		const bucketEndpoint = `https://${bucketNombre}.s3.${aplicacion.aws.region}.amazonaws.com`;
		if (avaluo && avaluo.ubicacion) {
			const { id, ubicacion } = avaluo;
			const { mapaImagenNombre } = ubicacion;
			if (fotosMapas[id]) {
				ruta = fotosMapas[id];
			} else if (awsS3BucketAvaluoMapas && mapaImagenNombre) {
				ruta = `${bucketEndpoint}/${mapaImagenNombre}`;
			}
		}
		return ruta;
	};
};

/**
 * Obtiene la imagen del mapa personalizado del avalúo.
 */
export const useObtenerAvaluoMapaPersonalizado = () => {
	const { awsS3BucketAvaluoMapas } = useSelector((state: StateType) => state.configuracionSistema);
	const { fotosMapasPersonalizados } = useSelector((state: StateType) => state.imagenesTemporales);

	return (avaluo?: Avaluo) => {
		let ruta = aplicacion.imagenes.predeterminadaAvaluoMapaPersonalizado;
		const bucketNombre = awsS3BucketAvaluoMapas;
		const bucketEndpoint = `https://${bucketNombre}.s3.${aplicacion.aws.region}.amazonaws.com`;
		if (avaluo && avaluo.ubicacion) {
			const { id, ubicacion } = avaluo;
			const { mapaPersonalizadoImagenNombre } = ubicacion;
			if (fotosMapasPersonalizados[id]) {
				ruta = fotosMapasPersonalizados[id];
			} else if (awsS3BucketAvaluoMapas && mapaPersonalizadoImagenNombre) {
				ruta = `${bucketEndpoint}/${mapaPersonalizadoImagenNombre}`;
			}
		}
		return ruta;
	};
};

/**
 * Obtiene la foto de la licencia del perito.
 */
export const useObtenerPeritoLicencia = () => {
	const { awsS3BucketPeritoLicencia, awsS3BucketPeritoLicenciaMiniaturas } = useSelector((state: StateType) => state.configuracionSistema);
	const { imagenPredeterminadaPeritoLicencia200, imagenPredeterminadaPeritoLicencia400, imagenPredeterminadaPeritoLicencia700 } = useSelector(
		(state: StateType) => state.configuracionAplicacion
	);
	const { fotosPeritoLicencia } = useSelector((state: StateType) => state.imagenesTemporales);

	return ({ ancho, perito }: { ancho?: '200' | '400' | '700'; perito?: Perito }) => {
		let ruta = '';
		let bucketNombre = awsS3BucketPeritoLicencia;
		if (ancho) {
			bucketNombre = awsS3BucketPeritoLicenciaMiniaturas;
		}
		const bucketEndpoint = `https://${bucketNombre}.s3.${aplicacion.aws.region}.amazonaws.com`;
		if (perito) {
			const { id, licenciaFoto } = perito;
			if (fotosPeritoLicencia[id]) {
				ruta = fotosPeritoLicencia[id];
			} else if (awsS3BucketPeritoLicencia && awsS3BucketPeritoLicenciaMiniaturas && licenciaFoto) {
				if (ancho) {
					const licenciaFotoNombre = eliminarExtensionArchivo(licenciaFoto);
					const licenciaFotoExtension = obtenerExtensionArchivo(licenciaFoto);
					ruta = `${bucketEndpoint}/${licenciaFotoNombre}_${ancho}.${licenciaFotoExtension}`;
				} else {
					ruta = `${bucketEndpoint}/${licenciaFoto}`;
				}
			} else {
				switch (ancho) {
					case '200':
						ruta = imagenPredeterminadaPeritoLicencia200 || aplicacion.imagenes.predeterminadaFotoPeritoLicencia200;
						break;
					case '400':
						ruta = imagenPredeterminadaPeritoLicencia400 || aplicacion.imagenes.predeterminadaFotoPeritoLicencia400;
						break;
					case '700':
						ruta = imagenPredeterminadaPeritoLicencia700 || aplicacion.imagenes.predeterminadaFotoPeritoLicencia700;
						break;
					default:
						ruta = imagenPredeterminadaPeritoLicencia700 || aplicacion.imagenes.predeterminadaFotoPeritoLicencia400;
						break;
				}
			}
		}
		return ruta;
	};
};

/**
 * Obtiene la foto de perfil del usuario.
 */
export const useObtenerUsuarioFoto = () => {
	const { awsS3BucketUsuarioFotos, awsS3BucketUsuarioFotosMiniaturas } = useSelector((state: StateType) => state.configuracionSistema);
	const { imagenPredeterminadaUsuarioFotoPerfil50x50, imagenPredeterminadaUsuarioFotoPerfil100x100, imagenPredeterminadaUsuarioFotoPerfil700x700 } = useSelector(
		(state: StateType) => state.configuracionAplicacion
	);
	const { fotosPerfil } = useSelector((state: StateType) => state.imagenesTemporales);

	return ({ ancho, usuario }: { ancho?: '50' | '100' | '700'; usuario?: Usuario }) => {
		let ruta = '';
		let bucketNombre = awsS3BucketUsuarioFotos;
		if (ancho) {
			bucketNombre = awsS3BucketUsuarioFotosMiniaturas;
		}
		const bucketEndpoint = `https://${bucketNombre}.s3.${aplicacion.aws.region}.amazonaws.com`;
		if (usuario) {
			const { fotoPerfil, id } = usuario;
			if (fotosPerfil[id]) {
				ruta = fotosPerfil[id];
			} else if (awsS3BucketUsuarioFotos && awsS3BucketUsuarioFotosMiniaturas && fotoPerfil) {
				if (ancho) {
					const fotoPerfilNombre = eliminarExtensionArchivo(fotoPerfil);
					const fotoPerfilExtension = obtenerExtensionArchivo(fotoPerfil);
					ruta = `${bucketEndpoint}/${fotoPerfilNombre}_${ancho}x${ancho}.${fotoPerfilExtension}`;
				} else {
					ruta = `${bucketEndpoint}/${fotoPerfil}`;
				}
			} else {
				switch (ancho) {
					case '50':
						ruta = imagenPredeterminadaUsuarioFotoPerfil50x50 || aplicacion.imagenes.predeterminadaFotoPerfil50x50;
						break;
					case '100':
						ruta = imagenPredeterminadaUsuarioFotoPerfil100x100 || aplicacion.imagenes.predeterminadaFotoPerfil100x100;
						break;
					case '700':
						ruta = imagenPredeterminadaUsuarioFotoPerfil700x700 || aplicacion.imagenes.predeterminadaFotoPerfil700x700;
						break;
					default:
						ruta = imagenPredeterminadaUsuarioFotoPerfil700x700 || aplicacion.imagenes.predeterminadaFotoPerfil700x700;
						break;
				}
			}
		}
		return ruta;
	};
};
