import { Col, Row } from 'reactstrap';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import CantidadAvaluos from 'componentes/widgets/cantidadAvaluos/CantidadAvaluos';
import CantidadAvaluosPorMunicipio from 'componentes/widgets/cantidadAvaluosPorMunicipio/CantidadAvaluosPorMunicipio';
import CantidadAvaluosPorPerito from 'componentes/widgets/cantidadAvaluosPorPerito/CantidadAvaluosPorPerito';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { texto } from 'idiomas';

/**
 * Página inicial de la aplicación, a donde el usuario será redirigido al iniciar sesión.
 */
const Pagina = () => (
	<Contenedor>
		<Breadcrumb icono={constantes.icono.inicio} titulo={texto('Inicio')} />
		<Row>
			<Col md={6}>
				<CantidadAvaluos filtrarPorUsuario />
			</Col>
			<Col md={6}>
				<CantidadAvaluosPorPerito filtrarPorUsuario />
			</Col>
		</Row>
		<Row>
			<Col md={6}>
				<CantidadAvaluosPorMunicipio filtrarPorUsuario />
			</Col>
		</Row>
	</Contenedor>
);

export default Pagina;
