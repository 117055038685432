import { Col, Row } from 'reactstrap';
import { formatearListaOpcionesMcCampoSelector, obtenerListaTipoConstruccion } from 'util/modelo/listasOpciones';
import { McCampoSelectorOpcion, McCampoTexto, McCheckbox } from '@mcsoft/formulario';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import constantes from 'configuracion/constantes';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useFormState } from 'react-final-form';
import { validarListaOpcionesDisponibilidad } from 'servicios/api/listaOpciones';

/**
 * Formulario para un estado de conservación.
 */
const EstadoConservacionFormulario = () => {
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const formularioValores = useFormState();
	const dispatch = useDispatch();
	const [listaTipoConstruccion, setListaTipoConstruccion] = useState<Array<McCampoSelectorOpcion>>([]);
	const [formularioInvalido, setFormularioInvalido] = useState(false);

	useEffect(() => {
		obtenerListas();
	}, []);

	useEffect(() => {
		setFormularioInvalido(!hayAlmenosUnTipoConstruccion(formularioValores.values.tiposConstruccion));
	}, [formularioValores]);

	/**
	 * Regresa verdadero si hay por lo menos un tipo de construccion.
	 * - ***tiposConstruccion*** - Objeto con los tipos de construcción.
	 */
	const hayAlmenosUnTipoConstruccion = (tiposConstruccion: any): boolean => {
		if (tiposConstruccion) {
			for (const tipoConstruccion in tiposConstruccion) {
				if (tiposConstruccion[tipoConstruccion]) return true;
			}
		}
		return false;
	};

	const obtenerListas = async () => {
		dispatch(setPantallaCargaMostrarAction(true));
		const lista = await obtenerListaTipoConstruccion(auth0AccessToken);
		setListaTipoConstruccion(formatearListaOpcionesMcCampoSelector(lista));
		dispatch(setPantallaCargaMostrarAction(false));
	};

	/**
	 * Válida la abreviatura del estado de conservación.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioAbreviatura = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarListaOpcionesDisponibilidad({ auth0AccessToken, campo: 'abreviatura', id: valores.id, tipo: valores.tipo, valor });
		if (!disponible) {
			return 'Ya existe un estado de conservación con esa abreviatura';
		}
	};

	/**
	 * Válida el código del estado de conservación.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioCodigo = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarListaOpcionesDisponibilidad({ auth0AccessToken, campo: 'codigo', id: valores.id, tipo: valores.tipo, valor });
		if (!disponible) {
			return 'Ya existe un estado de conservación con ese código';
		}
	};

	/**
	 * Válida el nombre del estado de conservación.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioNombre = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarListaOpcionesDisponibilidad({ auth0AccessToken, campo: 'nombre', id: valores.id, tipo: valores.tipo, valor });
		if (!disponible) {
			return 'Ya existe un estado de conservación con ese nombre';
		}
	};

	return (
		<Row>
			<Col md="12">
				<Row>
					<Col md="6">
						<McCampoTexto campo="nombre" etiqueta={texto('Nombre')} funcionValidacionAsincrona={validarFormularioNombre} id="campoNombre" longitudMaxima={100} obligatorio />
					</Col>
					<Col md="3">
						<McCampoTexto
							campo="abreviatura"
							etiqueta={texto('Abreviatura')}
							funcionValidacionAsincrona={validarFormularioAbreviatura}
							id="campoAbreviatura"
							longitudMaxima={12}
							obligatorio
						/>
					</Col>
					<Col md="3">
						<McCampoTexto campo="codigo" etiqueta={texto('Código')} funcionValidacionAsincrona={validarFormularioCodigo} id="campoCodigo" longitudMaxima={12} obligatorio />
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h5>
							<i className={constantes.icono.estadosConservacion}></i> {texto('Tipo de construcción')}
						</h5>
						<p>{texto('Tipos de construcción en los que será elegible este estado de conservación.')}</p>
					</Col>
				</Row>
				{formularioInvalido && (
					<Row>
						<Col lg="12">
							<div className="alert alert-warning fade show" role="alert">
								{texto('Debes elegir por lo menos un tipo de construcción para que el estado de conservación pueda ser utilizado.')}
							</div>
						</Col>
					</Row>
				)}
				<Row>
					{listaTipoConstruccion.map((opcion) => (
						<Col key={opcion.valor} lg="4">
							<McCheckbox campo={`tiposConstruccion.${opcion.valor}`} etiqueta={opcion.nombre} id={opcion.valor} />
						</Col>
					))}
				</Row>
			</Col>
		</Row>
	);
};

export default EstadoConservacionFormulario;
