import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { useEffect, useState } from 'react';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import { guardarSessionStorage } from 'util/mc-utils/mc-storage';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { procesarError } from '@mcsoft/api';
import SistemaFormularioLogs from 'componentes/sistema/SistemaFormularioLogs';
import { texto } from 'idiomas';
import { useHistory } from 'react-router';

const NOMBRE_CLASE = 'paginas/app/sistema/SistemaLogs';

/**
 * En esta página se pueden activar o desactivar de los logs del sistema.
 */
const Pagina = () => {
	const history = useHistory();
	const [formulario, setFormulario] = useState<any>();

	useEffect(() => {
		obtenerRegistro();
	}, []);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		const ruta = constantes.ruta.appInicio;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	};

	/**
	 * Guarda la información del registro.
	 * - ***valores*** - Valores a guardar.
	 */
	const eventoGuardar = (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoGuardar';
		mcLogger.log({ mensaje: `Guardando la configuración de logs del sistema...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		try {
			guardarSessionStorage({ campo: 'loggerApi', nombreClase: NOMBRE_CLASE, nombreMetodo, valor: valores.loggerApi });
			guardarSessionStorage({ campo: 'loggerDev', nombreClase: NOMBRE_CLASE, nombreMetodo, valor: valores.loggerDev });
			guardarSessionStorage({ campo: 'loggerError', nombreClase: NOMBRE_CLASE, nombreMetodo, valor: valores.loggerError });
			guardarSessionStorage({ campo: 'loggerLocalStorage', nombreClase: NOMBRE_CLASE, nombreMetodo, valor: valores.loggerLocalStorage });
			guardarSessionStorage({ campo: 'loggerLog', nombreClase: NOMBRE_CLASE, nombreMetodo, valor: valores.loggerLog });
			guardarSessionStorage({ campo: 'loggerNodeModule', nombreClase: NOMBRE_CLASE, nombreMetodo, valor: valores.loggerNodeModule });
			guardarSessionStorage({ campo: 'loggerReact', nombreClase: NOMBRE_CLASE, nombreMetodo, valor: valores.loggerReact });
			guardarSessionStorage({ campo: 'loggerRedux', nombreClase: NOMBRE_CLASE, nombreMetodo, valor: valores.loggerRedux });
			guardarSessionStorage({ campo: 'loggerSessionStorage', nombreClase: NOMBRE_CLASE, nombreMetodo, valor: valores.loggerSessionStorage });
			mcLogger.log({ mensaje: `Configuración de logs del sistema guardada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			mcNotificaciones.exito({ mensaje: texto(`Configuración de logs del sistema guardada con éxito.`) });
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la configuración de logs del sistema:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		mcLogger.log({ mensaje: `Obteniendo configuración de logs del sistema...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		try {
			const logs = {
				loggerApi: sessionStorage.getItem('loggerApi') === 'true',
				loggerDev: sessionStorage.getItem('loggerDev') === 'true',
				loggerError: sessionStorage.getItem('loggerError') === 'true',
				loggerLocalStorage: sessionStorage.getItem('loggerLocalStorage') === 'true',
				loggerLog: sessionStorage.getItem('loggerLog') === 'true',
				loggerNodeModule: sessionStorage.getItem('loggerNodeModule') === 'true',
				loggerReact: sessionStorage.getItem('loggerReact') === 'true',
				loggerRedux: sessionStorage.getItem('loggerRedux') === 'true',
				loggerSessionStorage: sessionStorage.getItem('loggerSessionStorage') === 'true'
			};
			mcLogger.log({ mensaje: `Configuración de logs del sistema obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			setFormulario(logs);
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener la configuración de logs del sistema:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={[{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') }]} icono={constantes.icono.logs} titulo={texto('Logs del sistema')} />
			<Form
				initialValues={formulario}
				onSubmit={eventoGuardar}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Card>
							<CardBody>
								<Row>
									<Col lg="12">
										<SistemaFormularioLogs />
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<BarraHerramientas>
									<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
										<i className={constantes.icono.atras}></i> {texto('Cancelar')}
									</button>
									<button className="btn btn-success" id="botonGuardar" type="submit">
										<i className={constantes.icono.guardar}></i> {texto('Guardar')}
									</button>
								</BarraHerramientas>
							</CardFooter>
						</Card>
					</form>
				)}
			/>
		</Contenedor>
	);
};

export default Pagina;
