import antecedentes from './antecedentes';
import Avaluo from 'modelo/Avaluo';
import caracteristicasUrbanas from './caracteristicasUrbanas';
import descripcionGeneralPredio from './descripcionGeneralPredio';
import encabezado from './encabezado';
import marcoPagina from './marcoPagina';
import Usuario from 'modelo/Usuario';

const pagina = ({
	avaluo,
	mostrarCoeficientesUtilizacionOcupacion,
	mostrarCoordenadasDD,
	mostrarCoordenadasDMS,
	mostrarCoordenadasUTM,
	tipoEncabezado,
	usuario
}: {
	avaluo: Avaluo;
	mostrarCoeficientesUtilizacionOcupacion: boolean;
	mostrarCoordenadasDD: boolean;
	mostrarCoordenadasDMS: boolean;
	mostrarCoordenadasUTM: boolean;
	tipoEncabezado: string;
	usuario: Usuario;
}) =>
	new Promise((resolve) => {
		const promesas = [];
		promesas.push(encabezado({ avaluo, tipoEncabezado, usuario }));
		promesas.push(antecedentes(avaluo));
		promesas.push(caracteristicasUrbanas({ avaluo, mostrarCoeficientesUtilizacionOcupacion }));
		promesas.push(descripcionGeneralPredio({ avaluo, mostrarCoordenadasDD, mostrarCoordenadasDMS, mostrarCoordenadasUTM }));
		Promise.all(promesas).then((secciones) => {
			const contenido = [].concat.apply([], secciones as any);
			resolve(marcoPagina(contenido));
		});
	});

export default pagina;
