import aplicacion from 'configuracion/aplicacion';
import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del PDF del avalúo relacionado a los encabezados.
 */
const InformacionAvaluoPdfEncabezado = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Formato del encabezado que se mostrará en cada página del PDF.')}</p>
		</div>
		<div className="text-center mt-2 mb-2">
			<h5>Normal</h5>
			<img alt="Ejemplo" src={aplicacion.tutoriales.ejemploAvaluoPdfEncabezadoNormal} width="480px" />
		</div>
		<div className="text-center mt-2 mb-2">
			<h5>Compacto</h5>
			<img alt="Ejemplo" src={aplicacion.tutoriales.ejemploAvaluoPdfEncabezadoCompacto} width="480px" />
		</div>
	</Fragment>
);

export default InformacionAvaluoPdfEncabezado;
