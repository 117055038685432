import { all, fork } from 'redux-saga/effects';
import configuracionAplicacionSaga from 'store/configuracionAplicacion/saga';
import configuracionSistemaSaga from 'store/configuracionSistema/saga';
import sesionSaga from 'store/sesion/saga';

export default function* rootSaga() {
	yield all([
		fork(configuracionAplicacionSaga),
		fork(configuracionSistemaSaga),
		fork(sesionSaga)
	]);
}