import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Fragment, useState } from 'react';
import { get, map } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import listaIdiomas from 'idiomas/lista';
import mcLogger from '@mcsoft/logger';
import { setSesionIdiomaAction } from 'store/actions';
import { StateType } from 'store';

const NOMBRE_CLASE = 'IdiomaMenuDesplegable';

/**
 * Menú desplegable que muestra una lista de idiomas disponibles para la aplicación.
 */
const IdiomaMenuDesplegable = () => {
	const { sesion } = useSelector((state: StateType) => state);
	const dispatch = useDispatch();
	const [mostrarMenuDesplegable, setMostrarMenuDesplegable] = useState(false);
	const { idioma } = sesion.usuario.preferencias;

	/**
	 * Cambia el idioma de la aplicación.
	 * - ***idioma*** - Código del idioma seleccionado.
	 */
	const eventoCambiarIdioma = (idioma: string) => {
		dispatch(setSesionIdiomaAction({ idioma, sesion }));
	};

	/**
	 * Muestra/oculta el menú desplegable.
	 */
	const eventoMostrarOcultarMenuDesplegable = () => {
		const nombreMetodo = 'eventoMostrarOcultarMenuDesplegable';
		mcLogger.react({ mensaje: `Seteando state "mostrarMenuDesplegable":`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: !mostrarMenuDesplegable });
		setMostrarMenuDesplegable(!mostrarMenuDesplegable);
	};

	return (
		<Fragment>
			<Dropdown className="d-inline-block language-switch" isOpen={mostrarMenuDesplegable} toggle={eventoMostrarOcultarMenuDesplegable}>
				<DropdownToggle className="btn header-item waves-effect" tag="button">
					<img alt="Idioma" height="16" src={get(listaIdiomas, `${idioma}.bandera`)} />
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end" right>
					{map(Object.keys(listaIdiomas), (propiedad) => (
						<DropdownItem className={`notify-item ${idioma === propiedad ? 'active' : 'none'}`} key={propiedad} onClick={() => eventoCambiarIdioma(propiedad)}>
							<img alt={get(listaIdiomas, `${propiedad}.nombre`)} className="me-1" height="12" src={get(listaIdiomas, `${propiedad}.bandera`)} />
							<span className="align-middle">{get(listaIdiomas, `${propiedad}.nombre`)}</span>
						</DropdownItem>
					))}
				</DropdownMenu>
			</Dropdown>
		</Fragment>
	);
};

export default IdiomaMenuDesplegable;
