import { Col, Row } from 'reactstrap';
import { convertirBlobABase64, obtenerExtensionArchivo } from '@mcsoft/archivos';
import { Fragment, useEffect, useState } from 'react';
import { McRespuesta, procesarError } from '@mcsoft/api';
import { setImagenesTemporalesFotoAvaluoAction, setPantallaCargaMostrarAction, setVisorImagenImagenesAction, setVisorImagenMostrarAction } from 'store/actions';
import { useEliminarAvaluoFoto, useGuardarAvaluoFoto, useObtenerAvaluoFoto } from 'hooks/useImagen';
import { useForm, useFormState } from 'react-final-form';
import Avaluo from 'modelo/Avaluo';
import AvaluoFoto from 'modelo/AvaluoFoto';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import McGaleriaOrdenable from '@mcsoft/galeria-ordenable';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import McSelectorArchivo from '@mcsoft/selector-archivos';
import { McVisorImagenElemento } from '@mcsoft/visor-imagen';
import { texto } from 'idiomas';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

const NOMBRE_CLASE = 'AvaluoFormularioFoto';

interface AvaluoFormularioFotosProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
}

/**
 * Formulario para la sección fotos del avalúo.
 */
const AvaluoFormularioFotos = (props: AvaluoFormularioFotosProps) => {
	const dispatch = useDispatch();
	const eliminarAvaluoFoto = useEliminarAvaluoFoto();
	const obtenerAvaluoFoto = useObtenerAvaluoFoto();
	const guardarAvaluoFoto = useGuardarAvaluoFoto();
	const formulario = useForm();
	const formularioValores = useFormState();
	const { fotos, id: avaluoId } = formularioValores.values;
	const { eventoDeshacer, eventoGuardar, eventoSalir, hayCambiosSinGuardar } = props;
	const [verSelectorArchivos, setVerSelectorArchivos] = useState<boolean>(false);
	const [archivos, setArchivos] = useState<Array<File>>([]);
	const [listaFotos, setListaFotos] = useState<Array<McVisorImagenElemento>>([]);
	const [listaEliminar, setListaEliminar] = useState<Array<AvaluoFoto>>([]);
	const [hayCambiosSinGuardarLocal, setHayCambiosSinGuardarLocal] = useState<boolean>(false);

	useEffect(() => {
		obtenerFotos();
	}, [fotos]);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonSalir" onClick={eventoSalir} type="button">
				<i className={constantes.icono.atras}></i> {texto('Salir')}
			</button>
			<button className="btn btn-warning" disabled={!hayCambiosSinGuardarGlobal()} id="botonDeshacer" onClick={eventoDeshacerFormulario} type="button">
				<i className={constantes.icono.deshacer}></i> {texto('Deshacer')}
			</button>
			<button className="btn btn-info" disabled={hayCambiosSinGuardarGlobal()} id="botonAgregarFotos" onClick={eventoAgregarFotos} type="button">
				<i className={constantes.icono.agregar}></i> {texto('Agregar')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardarGlobal()} id="botonGuardar" onClick={eventoGuardarFormulario} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardarGlobal()}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	/**
	 * Dibuja la barra de herramentas del selector de archivos.
	 */
	const dibujarBarraHerramientasSelectorArchivos = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
				<i className={constantes.icono.atras}></i> {texto('Cancelar')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardarGlobal()} id="botonGuardar" onClick={eventoGuardarImagenes} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la barra de herramentas del selector de archivos.
	 */
	const dibujarTitulo = () => (
		<Fragment>
			<h3>
				<i className={constantes.icono.foto}></i> {texto('Reporte Fotográfico')}
			</h3>
			<p>{texto('En esta sección del formulario se agregan las fotos del avalúo.')}</p>
			<hr />
		</Fragment>
	);

	/**
	 * Muestra el componente para agregar fotos.
	 */
	const eventoAgregarFotos = () => {
		setVerSelectorArchivos(!verSelectorArchivos);
	};

	/**
	 * Evento que se ejecuta cuando cambian los archivos seleccionados.
	 * - ***archivos*** - Archivos seleccionados.
	 */
	const eventoArchivosSeleccionados = (archivos: Array<File>) => {
		setArchivos(archivos);
		setHayCambiosSinGuardarLocal(true);
	};

	/**
	 * Oculta el componente para agregar fotos.
	 */
	const eventoCancelar = () => {
		setVerSelectorArchivos(!verSelectorArchivos);
		setHayCambiosSinGuardarLocal(false);
	};

	/**
	 * Deshace los ultimos cambios.
	 */
	const eventoDeshacerFormulario = () => {
		setListaEliminar([]);
		setHayCambiosSinGuardarLocal(false);
		eventoDeshacer();
	};

	/**
	 * Evento que se ejecuta cuando se elimina una imagen de la lista.
	 * - ***indice*** - Indice de la imagen eliminada.
	 */
	const eventoEliminarImagen = (indice: number) => {
		const listaTemporal = [...listaFotos];
		const fotoEliminarArreglo: Array<AvaluoFoto> = fotos.filter((foto: AvaluoFoto) => foto.id === listaTemporal[indice].id);
		const [fotoEliminar] = fotoEliminarArreglo;
		listaTemporal.splice(indice, 1);
		setListaFotos(listaTemporal);
		setListaEliminar([...listaEliminar, fotoEliminar]);
		setHayCambiosSinGuardarLocal(true);
	};

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardarFormulario = async () => {
		const nombreMetodo = 'eventoGuardarFormulario';
		mcLogger.log({ mensaje: `Guardando registro...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const { cambiarValorCampo } = formulario.mutators;
		const avaluo = formularioValores.values as Avaluo;
		dispatch(setPantallaCargaMostrarAction(true));
		const listaAvaluoFotosReordenada: Array<AvaluoFoto> = [];
		const listaAvaluoFotosActualizadasIds: Array<string> = [];
		listaFotos.forEach((fotoOrdenada, indice) => {
			listaAvaluoFotosActualizadasIds.push(fotoOrdenada.id);
			fotos.forEach((foto: any) => {
				if (fotoOrdenada.id === foto.id) {
					listaAvaluoFotosReordenada.push({
						avaluoId: foto.avaluoId,
						id: foto.id,
						indice: indice,
						nombre: foto.nombre
					});
				}
			});
		});
		const promesasEliminar: Array<Promise<McRespuesta | void>> = [];
		listaEliminar.forEach((fotoEliminar) => {
			promesasEliminar.push(eliminarAvaluoFoto(fotoEliminar.nombre));
		});
		Promise.all(promesasEliminar)
			.then(() => {
				const fotosFiltradas = listaAvaluoFotosReordenada.filter((foto: any) => listaAvaluoFotosActualizadasIds.includes(foto.id));
				cambiarValorCampo('fotos', fotosFiltradas);
				avaluo.fotos = fotosFiltradas;
				eventoGuardar(avaluo);
				setHayCambiosSinGuardarLocal(false);
				dispatch(setPantallaCargaMostrarAction(false));
			})
			.catch((error: any) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
				mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
				dispatch(setPantallaCargaMostrarAction(false));
			});
	};

	/**
	 * Guarda las imagenes seleccionadas.
	 */
	const eventoGuardarImagenes = async () => {
		const nombreMetodo = 'eventoGuardarImagenes';
		try {
			dispatch(setPantallaCargaMostrarAction(true));
			const { cambiarValorCampo } = formulario.mutators;
			const avaluo = formularioValores.values as Avaluo;
			const ultimoIndice = fotos.length;
			const listaAvaluoFotos: Array<AvaluoFoto> = [];
			const promesas: Array<Promise<McRespuesta | void>> = [];
			archivos.forEach(async (archivo, indice) => {
				const archivoId = uuidv4();
				const archivoExtension = obtenerExtensionArchivo(archivo.name);
				const archivoNombre = `${avaluoId}_${archivoId}.${archivoExtension}`;
				listaAvaluoFotos.push({
					avaluoId: avaluoId,
					id: archivoId,
					indice: ultimoIndice + (indice + 1),
					nombre: archivoNombre
				});
				const archivoBase64 = await convertirBlobABase64(archivo);
				dispatch(setImagenesTemporalesFotoAvaluoAction({ fotoId: archivoId, imagenBase64: archivoBase64 }));
				promesas.push(guardarAvaluoFoto({ archivoBase64, archivoNombre, archivoTipo: archivo.type }));
			});
			await Promise.all(promesas);
			cambiarValorCampo('fotos', [...fotos, ...listaAvaluoFotos]);
			avaluo.fotos = [...fotos, ...listaAvaluoFotos];
			eventoGuardar(avaluo);
			dispatch(setPantallaCargaMostrarAction(false));
			setHayCambiosSinGuardarLocal(false);
			setVerSelectorArchivos(!verSelectorArchivos);
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Evento que se ejecuta cuando se reordena la lista de fotos.
	 * - ***listaReordenada*** - Indice de la imagen eliminada.
	 */
	const eventoReordenar = (listaReordenada: Array<any>): void => {
		setListaFotos(listaReordenada);
		setHayCambiosSinGuardarLocal(true);
	};

	/**
	 * Evento que se hace click en una imagen de la lista.
	 * - ***indice*** - Indice de la imagen seleccionada.
	 */
	const eventoVerImagen = (indice: number) => {
		const listaParte1 = listaFotos.slice(indice, listaFotos.length);
		const listaParte2 = listaFotos.slice(0, indice);
		const listaNueva = [...listaParte1, ...listaParte2];
		dispatch(setVisorImagenImagenesAction(listaNueva));
		dispatch(setVisorImagenMostrarAction(true));
	};

	/**
	 * Regresa verdadero si hay cambios sin guardar en las fotos o el avalúo.
	 */
	const hayCambiosSinGuardarGlobal = (): boolean => hayCambiosSinGuardar || hayCambiosSinGuardarLocal;

	/**
	 * Obtiene la lista de fotos del avaluo y las setea en el state del componente.
	 */
	const obtenerFotos = () => {
		const arregloFotos: Array<McVisorImagenElemento> = [];
		fotos &&
			fotos.forEach((foto: any) => {
				arregloFotos.push({
					id: foto.id,
					url: obtenerAvaluoFoto({ foto }),
					urlMiniatura: obtenerAvaluoFoto({ foto, miniatura: true })
				});
			});
		setListaFotos(arregloFotos);
	};

	return (
		<Fragment>
			{!verSelectorArchivos && (
				<Fragment>
					<Row>
						<Col lg="12">{dibujarTitulo()}</Col>
					</Row>
					<Row>
						<Col lg="12">{dibujarBarraHerramientas()}</Col>
					</Row>
					<Row>
						<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
					</Row>
					{listaFotos.length === 0 && (
						<Row>
							<Col lg="12">
								<div className="alert alert-warning" role="alert">
									<div className="avaluos-formulario__contenedor-fotos-icono">
										<i className="fa-regular fa-image-slash"></i>
									</div>
									<div className="avaluos-formulario__contenedor-fotos-texto">{texto('Sin fotos')}</div>
								</div>
							</Col>
						</Row>
					)}
					{listaFotos.length > 0 && (
						<Row>
							<Col lg="12">
								<McGaleriaOrdenable eventoEliminar={eventoEliminarImagen} eventoReordenar={eventoReordenar} eventoVerImagen={eventoVerImagen} lista={listaFotos} />
							</Col>
						</Row>
					)}
					<Row>
						<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
					</Row>
					<Row>
						<Col lg="12">{dibujarBarraHerramientas()}</Col>
					</Row>
				</Fragment>
			)}
			{verSelectorArchivos && (
				<Fragment>
					<Row>
						<Col lg="12">{dibujarTitulo()}</Col>
					</Row>
					<Row>
						<Col lg="12">{dibujarBarraHerramientasSelectorArchivos()}</Col>
					</Row>
					<Row>
						<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
					</Row>
					<Row>
						<Col lg="12">
							<McSelectorArchivo eventoArchivosSeleccionados={eventoArchivosSeleccionados} mostrarMiniaturas variosArchivos />
						</Col>
					</Row>
					<Row>
						<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
					</Row>
					<Row>
						<Col lg="12">{dibujarBarraHerramientasSelectorArchivos()}</Col>
					</Row>
				</Fragment>
			)}
		</Fragment>
	);
};

export default AvaluoFormularioFotos;
