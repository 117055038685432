import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del formato de fecha de moment.
 */
const InformacionSelectorFechaFormato = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Formato con el que se mostrarán las fechas en los selectores de fecha.')}</p>
			<p>
				<b>{texto('Ejemplo:')}</b> &quot;d/m/Y&quot;
			</p>
			<p>
				<b>{texto('Resultado:')}</b> &quot;27/07/1985&quot;
			</p>
			<ul>
				<li>
					<span className="badge bg-info text-wrap">d</span> {texto('Día del mes a 2 dígitos y rellenando con ceros')} <span className="fst-italic">01-31</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">D</span> {texto('Día de la semana')} <span className="fst-italic">{texto('Lun-Dom')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">l</span> <span className="fst-italic">{texto('(L Minúscula)')}</span> {texto('Día de la semana completo')}{' '}
					<span className="fst-italic">{texto('Lunes-Domingo')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">j</span> {texto('Día del mes sin rellenar con ceros')} <span className="fst-italic">1-31</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">J</span> {texto('Día del mes sin rellenar con ceros y con sufijo ordinal')}{' '}
					<span className="fst-italic">{texto('1º-31º')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">w</span> {texto('Día de la semana a 1 dígito')} <span className="fst-italic">{texto('0 (Domingo) - 6 (Sábado)')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">W</span> {texto('Semana del año representada con un número')} <span className="fst-italic">1-52</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">F</span> {texto('Nombre del mes completo')} <span className="fst-italic">{texto('Enero-Diciembre')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">m</span> {texto('Mes a 2 dígitos y rellenando con ceros')} <span className="fst-italic">01-12</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">n</span> {texto('Mes sin rellenar con ceros')} <span className="fst-italic">1-12</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">M</span> {texto('Nombre del mes')} <span className="fst-italic">{texto('Ene-Dic')}</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">U</span> {texto('Número de segundos desde la época de Unix')} <span className="fst-italic">1413704993</span>
				</li>
				<li>
					<span className="badge bg-info text-wrap">y</span> {texto('Año a 2 dígitos')} <span className="fst-italic">99 o 03</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">Y</span> {texto('Año a 4 dígitos')} <span className="fst-italic">1999 o 2003</span>.
				</li>
				<li>
					<span className="badge bg-info text-wrap">Z</span> {texto('Formato de fecha ISO')} <span className="fst-italic">2021-05-03T21:32:43.000Z</span>.
				</li>
			</ul>
		</div>
	</Fragment>
);

export default InformacionSelectorFechaFormato;
