import './DomicilioTarjeta.scss';
import { Col } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { Fragment } from 'react';
import PeritoDomicilio from 'modelo/PeritoDomicilio';
import { texto } from 'idiomas';
import UsuarioDomicilio from 'modelo/UsuarioDomicilio';

interface DomicilioTarjetaProps {
	/**
	 * Objeto con la informacion del domicilio.
	 */
	domicilio: UsuarioDomicilio | PeritoDomicilio;
}

/**
 * Muestra una tarjeta con la información del domicilio de un usuario.
 */
const DomicilioTarjeta = (props: DomicilioTarjetaProps) => {
	const { domicilio } = props;
	const { calle, codigoPostal, colonia, estado, municipio, numeroExterior, numeroInterior, pais, tipo } = domicilio as any;

	/**
	 * Regresa el título de la tarjeta dependiendo del tipo de domicilio.
	 */
	const obtenerTitulo = () => {
		switch (tipo) {
			case 'entrega':
				return texto('Domicilio de Entrega');
			case 'facturacion':
				return texto('Domicilio de Facturación');
			default:
				return texto('Domicilio');
		}
	};

	/**
	 * Regresa el icono del título de la tarjeta dependiendo del tipo de domicilio.
	 */
	const obtenerTituloIcono = () => {
		switch (tipo) {
			case 'entrega':
				return constantes.icono.domicilioEntrega;
			case 'facturacion':
				return constantes.icono.domicilioFactura;
			default:
				return constantes.icono.domicilioPrincipal;
		}
	};

	/**
	 * Regresa la dirección del domicilio.
	 */
	const obtenerDireccion = () => {
		let direccion = '';
		if (calle) {
			direccion += `${calle}`;
		}
		if (numeroExterior) {
			direccion += ` ${numeroExterior}`;
		}
		if (numeroInterior) {
			direccion += ` ${texto('Int.')} ${numeroInterior}`;
		}
		return direccion;
	};

	/**
	 * Regresa la localidad del domicilio.
	 */
	const obtenerLocalidad = () => {
		let localidad = '';
		if (municipio) {
			localidad += `${municipio.nombre}`;
		}
		if (estado) {
			localidad += `, ${estado.nombre}`;
		}
		if (pais) {
			localidad += `, ${pais.nombre}`;
		}
		return localidad;
	};

	return (
		<Fragment>
			<Col lg="12">
				<div className="domicilio-tarjeta__informacion">
					<h4>
						<i className={obtenerTituloIcono()}></i> {obtenerTitulo()}
					</h4>
					<p>{obtenerDireccion()}</p>
					<p>
						{colonia && (
							<span>
								<b>{texto('Col.')} </b>
								{colonia}
							</span>
						)}
						{codigoPostal && (
							<span>
								<b> {texto('C.P.')} </b>
								{codigoPostal}
							</span>
						)}
					</p>
					<p>{obtenerLocalidad()}</p>
				</div>
			</Col>
		</Fragment>
	);
};

export default DomicilioTarjeta;
