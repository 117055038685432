import { CoordenadasDD, esCardinalDMSValido, esZonaUTMValida } from '@mcsoft/google-maps';
import { sonCadenasDiferentes, sonFechasDiferentes, sonNumerosDiferentes } from '@mcsoft/cadenas';
import Avaluo from 'modelo/Avaluo';
import AvaluoConstruccionesDetalle from 'modelo/AvaluoConstruccionesDetalle';
import AvaluoInstalacionesEspecialesDetalle from 'modelo/AvaluoInstalacionesEspecialesDetalle';
import AvaluoTerrenoDetalle from 'modelo/AvaluoTerrenoDetalle';
import { nombreCompletoPeritoPorApellido } from './peritos';
import { texto } from 'idiomas';
import { tieneValor } from '@mcsoft/validaciones';
import { ValidationErrors } from 'final-form';

/**
 * Formatea un avalúo para ser mostrado en el formulario.
 * - ***avaluo*** - Objeto con la información del avalúo.
 */
export const formatearAvaluoParaFormulario = (avaluo: Avaluo) => {
	const formulario: any = avaluo;
	if (avaluo && avaluo.antecedentes) {
		if (avaluo.antecedentes.perito) {
			formulario.antecedentes.peritoSeleccion = {
				label: nombreCompletoPeritoPorApellido(avaluo.antecedentes.perito),
				value: avaluo.antecedentes.perito.id
			};
		}
	}
	if (avaluo && avaluo.domicilio) {
		if (avaluo.domicilio.pais) {
			formulario.domicilio.paisSeleccion = {
				label: avaluo.domicilio.pais.nombre,
				value: avaluo.domicilio.pais.id
			};
		}
		if (avaluo.domicilio.estado) {
			formulario.domicilio.estadoSeleccion = {
				label: avaluo.domicilio.estado.nombre,
				value: avaluo.domicilio.estado.id
			};
		}
		if (avaluo.domicilio.municipio) {
			formulario.domicilio.municipioSeleccion = {
				label: avaluo.domicilio.municipio.nombre,
				value: avaluo.domicilio.municipio.id
			};
		}
	}
	if (avaluo && avaluo.escritura) {
		if (avaluo.escritura.estado) {
			formulario.escritura.estadoSeleccion = {
				label: avaluo.escritura.estado.nombre,
				value: avaluo.escritura.estado.id
			};
		}
		if (avaluo.escritura.municipio) {
			formulario.escritura.municipioSeleccion = {
				label: avaluo.escritura.municipio.nombre,
				value: avaluo.escritura.municipio.id
			};
		}
	}
	if (avaluo && avaluo.medidas) {
		if (avaluo.medidas.colindancia1Viento) {
			formulario.medidas.colindancia1VientoSeleccion = {
				label: avaluo.medidas.colindancia1Viento.nombre,
				value: avaluo.medidas.colindancia1Viento.id
			};
		}
		if (avaluo.medidas.colindancia2Viento) {
			formulario.medidas.colindancia2VientoSeleccion = {
				label: avaluo.medidas.colindancia2Viento.nombre,
				value: avaluo.medidas.colindancia2Viento.id
			};
		}
		if (avaluo.medidas.colindancia3Viento) {
			formulario.medidas.colindancia3VientoSeleccion = {
				label: avaluo.medidas.colindancia3Viento.nombre,
				value: avaluo.medidas.colindancia3Viento.id
			};
		}
		if (avaluo.medidas.colindancia4Viento) {
			formulario.medidas.colindancia4VientoSeleccion = {
				label: avaluo.medidas.colindancia4Viento.nombre,
				value: avaluo.medidas.colindancia4Viento.id
			};
		}
		if (avaluo.medidas.colindancia5Viento) {
			formulario.medidas.colindancia5VientoSeleccion = {
				label: avaluo.medidas.colindancia5Viento.nombre,
				value: avaluo.medidas.colindancia5Viento.id
			};
		}
		if (avaluo.medidas.colindancia6Viento) {
			formulario.medidas.colindancia6VientoSeleccion = {
				label: avaluo.medidas.colindancia6Viento.nombre,
				value: avaluo.medidas.colindancia6Viento.id
			};
		}
		if (avaluo.medidas.colindancia7Viento) {
			formulario.medidas.colindancia7VientoSeleccion = {
				label: avaluo.medidas.colindancia7Viento.nombre,
				value: avaluo.medidas.colindancia7Viento.id
			};
		}
		if (avaluo.medidas.colindancia8Viento) {
			formulario.medidas.colindancia8VientoSeleccion = {
				label: avaluo.medidas.colindancia8Viento.nombre,
				value: avaluo.medidas.colindancia8Viento.id
			};
		}
	}
	return formulario;
};

/**
 * Compara dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
export const hayCambiosEnElAvaluo = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	if (avaluo1.estatus !== avaluo2.estatus) return true;
	if (avaluo1.folio !== avaluo2.folio) return true;
	if (avaluo1.id !== avaluo2.id) return true;
	if (avaluo1.usuarioId !== avaluo2.usuarioId) return true;
	if (avaluo1.valorTotal !== avaluo2.valorTotal) return true;
	if (hayCambiosEnAntecedentes(avaluo1, avaluo2)) return true;
	if (hayCambiosEnCaracteristicas(avaluo1, avaluo2)) return true;
	if (hayCambiosEnConstrucciones(avaluo1, avaluo2)) return true;
	if (hayCambiosEnConstruccionesDominantes(avaluo1, avaluo2)) return true;
	if (hayCambiosEnDistribucion(avaluo1, avaluo2)) return true;
	if (hayCambiosEnDomicilio(avaluo1, avaluo2)) return true;
	if (hayCambiosEnElementosConstruccion(avaluo1, avaluo2)) return true;
	if (hayCambiosEnEscritura(avaluo1, avaluo2)) return true;
	if (hayCambiosEnInstalacionesEspeciales(avaluo1, avaluo2)) return true;
	if (hayCambiosEnMedidas(avaluo1, avaluo2)) return true;
	if (hayCambiosEnObservaciones(avaluo1, avaluo2)) return true;
	if (hayCambiosEnTerreno(avaluo1, avaluo2)) return true;
	if (hayCambiosEnUbicacion(avaluo1, avaluo2)) return true;
	if (hayCambiosEnsServiciosMunicipales(avaluo1, avaluo2)) return true;
	return false;
};

/**
 * Válida el formulario de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 */
export const validarAvaluoFormulario = (valores: Record<string, any>): ValidationErrors => {
	const errores: ValidationErrors = {};
	validarFormularioAntecedentes(valores.antecedentes, errores);
	validarFormularioCaracteristicas(valores.caracteristicas, errores);
	validarFormularioConstrucciones(valores.construcciones, errores);
	validarFormularioConstruccionesDominantes(valores.construccionesDominantes, errores);
	validarFormularioDistribucion(valores.distribucion, errores);
	validarFormularioDomicilio(valores.domicilio, errores);
	validarFormularioElementosConstruccion(valores.elementosConstruccion, errores);
	validarFormularioEscritura(valores.escritura, errores);
	validarFormularioInstalacionesEspeciales(valores.instalacionesEspeciales, errores);
	validarFormularioMedidas(valores.medidas, errores);
	validarFormularioServiciosMunicipales(valores.serviciosMunicipales, errores);
	validarFormularioTerreno(valores.terreno, errores);
	validarFormularioUbicacion(valores.ubicacion, errores);
	return errores;
};

/**
 * Compara los antecedentes de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnAntecedentes = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { antecedentes: antecedentes1 } = avaluo1;
	const { antecedentes: antecedentes2 } = avaluo2;
	if (sonCadenasDiferentes(antecedentes1?.avaluoId, antecedentes2?.avaluoId)) return true;
	if (sonCadenasDiferentes(antecedentes1?.claveCatastral, antecedentes2?.claveCatastral)) return true;
	if (sonCadenasDiferentes(antecedentes1?.cuentaPredial, antecedentes2?.cuentaPredial)) return true;
	if (sonFechasDiferentes(antecedentes1?.fechaAvaluo, antecedentes2?.fechaAvaluo)) return true;
	if (sonCadenasDiferentes(antecedentes1?.id, antecedentes2?.id)) return true;
	if (sonCadenasDiferentes(antecedentes1?.nombrePropietario, antecedentes2?.nombrePropietario)) return true;
	if (sonCadenasDiferentes(antecedentes1?.nombreSolicitante, antecedentes2?.nombreSolicitante)) return true;
	if (sonNumerosDiferentes(antecedentes1?.numeroRecaudadora, antecedentes2?.numeroRecaudadora)) return true;
	if (sonCadenasDiferentes(antecedentes1?.peritoId, antecedentes2?.peritoId)) return true;
	if (sonCadenasDiferentes(antecedentes1?.regimenPropiedadId, antecedentes2?.regimenPropiedadId)) return true;
	if (sonCadenasDiferentes(antecedentes1?.tipoInmuebleId, antecedentes2?.tipoInmuebleId)) return true;
	if (sonCadenasDiferentes(antecedentes1?.ubicacionPredio, antecedentes2?.ubicacionPredio)) return true;
	return false;
};

/**
 * Compara las características de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnCaracteristicas = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { caracteristicas: caracteristicas1 } = avaluo1;
	const { caracteristicas: caracteristicas2 } = avaluo2;
	if (sonCadenasDiferentes(caracteristicas1?.avaluoId, caracteristicas2?.avaluoId)) return true;
	if (sonCadenasDiferentes(caracteristicas1?.clasificacionZonaId, caracteristicas2?.clasificacionZonaId)) return true;
	if (sonNumerosDiferentes(caracteristicas1?.coeficienteOcupacion, caracteristicas2?.coeficienteOcupacion)) return true;
	if (sonNumerosDiferentes(caracteristicas1?.coeficienteUtilizacion, caracteristicas2?.coeficienteUtilizacion)) return true;
	if (sonNumerosDiferentes(caracteristicas1?.densidadConstruccion, caracteristicas2?.densidadConstruccion)) return true;
	if (sonCadenasDiferentes(caracteristicas1?.id, caracteristicas2?.id)) return true;
	return false;
};

/**
 * Compara las construcciones de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnConstrucciones = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { construcciones: construcciones1 } = avaluo1;
	const { construcciones: construcciones2 } = avaluo2;
	if (sonCadenasDiferentes(construcciones1?.avaluoId, construcciones2?.avaluoId)) return true;
	if (sonCadenasDiferentes(construcciones1?.id, construcciones2?.id)) return true;
	if (sonNumerosDiferentes(construcciones1?.indiviso, construcciones2?.indiviso)) return true;
	if (sonNumerosDiferentes(construcciones1?.superficieTotal, construcciones2?.superficieTotal)) return true;
	if (sonCadenasDiferentes(construcciones1?.superficieUnidadId, construcciones2?.superficieUnidadId)) return true;
	if (sonNumerosDiferentes(construcciones1?.valor, construcciones2?.valor)) return true;
	if (sonNumerosDiferentes(construcciones1?.valorTotal, construcciones2?.valorTotal)) return true;
	if (hayCambiosEnConstruccionesDetalles(construcciones1?.detalles, construcciones2?.detalles)) return true;
	return false;
};

/**
 * Compara los detalles de las construcciones de dos avalúos y regresa verdadero si son diferentes.
 * - ***detalles1*** - Primer arreglo de detalles a comparar.
 * - ***detalles2*** - Segundo arreglo de detalles a comparar.
 */
const hayCambiosEnConstruccionesDetalles = (detalles1: Array<AvaluoConstruccionesDetalle> | undefined, detalles2: Array<AvaluoConstruccionesDetalle> | undefined): boolean => {
	const longitud1 = detalles1?.length || 0;
	const longitud2 = detalles2?.length || 0;
	if (sonNumerosDiferentes(longitud1, longitud2)) {
		return true;
	} else {
		if (detalles1 && detalles2) {
			for (let i = 0; i < longitud1; i++) {
				if (sonNumerosDiferentes(detalles1[i].ajusteFactor, detalles2[i].ajusteFactor)) return true;
				if (sonCadenasDiferentes(detalles1[i].ajusteMotivoId, detalles2[i].ajusteMotivoId)) return true;
				if (sonCadenasDiferentes(detalles1[i].avaluoConstruccionesId, detalles2[i].avaluoConstruccionesId)) return true;
				if (sonNumerosDiferentes(detalles1[i].bloque, detalles2[i].bloque)) return true;
				if (sonCadenasDiferentes(detalles1[i].calidadId, detalles2[i].calidadId)) return true;
				if (sonCadenasDiferentes(detalles1[i].codigo, detalles2[i].codigo)) return true;
				if (sonCadenasDiferentes(detalles1[i].codigoClasificación, detalles2[i].codigoClasificación)) return true;
				if (sonNumerosDiferentes(detalles1[i].edad, detalles2[i].edad)) return true;
				if (sonCadenasDiferentes(detalles1[i].estadoConservacionId, detalles2[i].estadoConservacionId)) return true;
				if (sonCadenasDiferentes(detalles1[i].id, detalles2[i].id)) return true;
				if (sonNumerosDiferentes(detalles1[i].superficie, detalles2[i].superficie)) return true;
				if (sonCadenasDiferentes(detalles1[i].superficieUnidadId, detalles2[i].superficieUnidadId)) return true;
				if (sonCadenasDiferentes(detalles1[i].tipoConstruccionId, detalles2[i].tipoConstruccionId)) return true;
				if (sonNumerosDiferentes(detalles1[i].valorResultante, detalles2[i].valorResultante)) return true;
				if (sonNumerosDiferentes(detalles1[i].valorUnitario, detalles2[i].valorUnitario)) return true;
			}
		}
	}
	return false;
};

/**
 * Compara las construcciones dominantes de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnConstruccionesDominantes = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { construccionesDominantes: construccionesDominantes1 } = avaluo1;
	const { construccionesDominantes: construccionesDominantes2 } = avaluo2;
	if (construccionesDominantes1?.avaluoId !== construccionesDominantes2?.avaluoId) return true;
	if (construccionesDominantes1?.descripcion !== construccionesDominantes2?.descripcion) return true;
	if (construccionesDominantes1?.id !== construccionesDominantes2?.id) return true;
	return false;
};

/**
 * Compara la distribución de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnDistribucion = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { distribucion: distribucion1 } = avaluo1;
	const { distribucion: distribucion2 } = avaluo2;
	if (sonCadenasDiferentes(distribucion1?.avaluoId, distribucion2?.avaluoId)) return true;
	if (sonCadenasDiferentes(distribucion1?.descripcion, distribucion2?.descripcion)) return true;
	if (sonCadenasDiferentes(distribucion1?.id, distribucion2?.id)) return true;
	return false;
};

/**
 * Compara el domicilio de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnDomicilio = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { domicilio: domicilio1 } = avaluo1;
	const { domicilio: domicilio2 } = avaluo2;
	if (sonCadenasDiferentes(domicilio1?.avaluoId, domicilio2?.avaluoId)) return true;
	if (sonCadenasDiferentes(domicilio1?.calle, domicilio2?.calle)) return true;
	if (sonCadenasDiferentes(domicilio1?.calleTipoId, domicilio2?.calleTipoId)) return true;
	if (sonCadenasDiferentes(domicilio1?.codigoPostal, domicilio2?.codigoPostal)) return true;
	if (sonCadenasDiferentes(domicilio1?.colonia, domicilio2?.colonia)) return true;
	if (sonCadenasDiferentes(domicilio1?.condominio, domicilio2?.condominio)) return true;
	if (sonCadenasDiferentes(domicilio1?.estadoId, domicilio2?.estadoId)) return true;
	if (sonCadenasDiferentes(domicilio1?.fraccionamiento, domicilio2?.fraccionamiento)) return true;
	if (sonCadenasDiferentes(domicilio1?.id, domicilio2?.id)) return true;
	if (sonCadenasDiferentes(domicilio1?.lote, domicilio2?.lote)) return true;
	if (sonCadenasDiferentes(domicilio1?.manzana, domicilio2?.manzana)) return true;
	if (sonCadenasDiferentes(domicilio1?.municipioId, domicilio2?.municipioId)) return true;
	if (sonCadenasDiferentes(domicilio1?.numeroExterior, domicilio2?.numeroExterior)) return true;
	if (sonCadenasDiferentes(domicilio1?.numeroInterior, domicilio2?.numeroInterior)) return true;
	if (sonCadenasDiferentes(domicilio1?.paisId, domicilio2?.paisId)) return true;
	if (sonCadenasDiferentes(domicilio1?.unidadPrivativa, domicilio2?.unidadPrivativa)) return true;
	return false;
};

/**
 * Compara los elementos de construcción de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnElementosConstruccion = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { elementosConstruccion: elementosConstruccion1 } = avaluo1;
	const { elementosConstruccion: elementosConstruccion2 } = avaluo2;
	if (sonCadenasDiferentes(elementosConstruccion1?.aplanado, elementosConstruccion2?.aplanado)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.avaluoId, elementosConstruccion2?.avaluoId)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.azotea, elementosConstruccion2?.azotea)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.barda, elementosConstruccion2?.barda)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.carpinteria, elementosConstruccion2?.carpinteria)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.cerrajeria, elementosConstruccion2?.cerrajeria)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.cimientos, elementosConstruccion2?.cimientos)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.entrepiso, elementosConstruccion2?.entrepiso)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.escalera, elementosConstruccion2?.escalera)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.estructura, elementosConstruccion2?.estructura)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.fachada, elementosConstruccion2?.fachada)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.herreria, elementosConstruccion2?.herreria)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.id, elementosConstruccion2?.id)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.instalacionElectrica, elementosConstruccion2?.instalacionElectrica)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.instalacionEspecial, elementosConstruccion2?.instalacionEspecial)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.instalacionSanitaria, elementosConstruccion2?.instalacionSanitaria)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.lambrin, elementosConstruccion2?.lambrin)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.muebleBano, elementosConstruccion2?.muebleBano)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.muebleCocina, elementosConstruccion2?.muebleCocina)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.muro, elementosConstruccion2?.muro)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.pavimentoPetreo, elementosConstruccion2?.pavimentoPetreo)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.pintura, elementosConstruccion2?.pintura)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.plafon, elementosConstruccion2?.plafon)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.recubrimientoEspecial, elementosConstruccion2?.recubrimientoEspecial)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.techo, elementosConstruccion2?.techo)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.vidrieria, elementosConstruccion2?.vidrieria)) return true;
	if (sonCadenasDiferentes(elementosConstruccion1?.zoclo, elementosConstruccion2?.zoclo)) return true;
	return false;
};

/**
 * Compara la escritura de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnEscritura = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { escritura: escritura1 } = avaluo1;
	const { escritura: escritura2 } = avaluo2;
	if (sonCadenasDiferentes(escritura1?.avaluoId, escritura2?.avaluoId)) return true;
	if (sonCadenasDiferentes(escritura1?.descripcion, escritura2?.descripcion)) return true;
	if (sonCadenasDiferentes(escritura1?.estadoId, escritura2?.estadoId)) return true;
	if (sonFechasDiferentes(escritura1?.fecha, escritura2?.fecha)) return true;
	if (sonCadenasDiferentes(escritura1?.id, escritura2?.id)) return true;
	if (sonCadenasDiferentes(escritura1?.municipioId, escritura2?.municipioId)) return true;
	if (sonCadenasDiferentes(escritura1?.notarioNombre, escritura2?.notarioNombre)) return true;
	if (sonNumerosDiferentes(escritura1?.numero, escritura2?.numero)) return true;
	if (sonNumerosDiferentes(escritura1?.numeroNotaria, escritura2?.numeroNotaria)) return true;
	return false;
};

/**
 * Compara las instalaciones especiales de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnInstalacionesEspeciales = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { instalacionesEspeciales: instalacionesEspeciales1 } = avaluo1;
	const { instalacionesEspeciales: instalacionesEspeciales2 } = avaluo2;
	if (sonCadenasDiferentes(instalacionesEspeciales1?.avaluoId, instalacionesEspeciales2?.avaluoId)) return true;
	if (sonCadenasDiferentes(instalacionesEspeciales1?.id, instalacionesEspeciales2?.id)) return true;
	if (sonNumerosDiferentes(instalacionesEspeciales1?.indiviso, instalacionesEspeciales2?.indiviso)) return true;
	if (sonNumerosDiferentes(instalacionesEspeciales1?.superficieTotal, instalacionesEspeciales2?.superficieTotal)) return true;
	if (sonCadenasDiferentes(instalacionesEspeciales1?.superficieUnidadId, instalacionesEspeciales2?.superficieUnidadId)) return true;
	if (sonNumerosDiferentes(instalacionesEspeciales1?.valor, instalacionesEspeciales2?.valor)) return true;
	if (sonNumerosDiferentes(instalacionesEspeciales1?.valorTotal, instalacionesEspeciales2?.valorTotal)) return true;
	if (hayCambiosEnInstalacionesEspecialesDetalles(instalacionesEspeciales1?.detalles, instalacionesEspeciales2?.detalles)) return true;
	return false;
};

/**
 * Compara los detalles de las instalaciones especiales de dos avalúos y regresa verdadero si son diferentes.
 * - ***detalles1*** - Primer arreglo de detalles a comparar.
 * - ***detalles2*** - Segundo arreglo de detalles a comparar.
 */
const hayCambiosEnInstalacionesEspecialesDetalles = (
	detalles1: Array<AvaluoInstalacionesEspecialesDetalle> | undefined,
	detalles2: Array<AvaluoInstalacionesEspecialesDetalle> | undefined
): boolean => {
	const longitud1 = detalles1?.length || 0;
	const longitud2 = detalles2?.length || 0;
	if (sonNumerosDiferentes(longitud1, longitud2)) {
		return true;
	} else {
		if (detalles1 && detalles2) {
			for (let i = 0; i < longitud1; i++) {
				if (sonNumerosDiferentes(detalles1[i].ajusteFactor, detalles2[i].ajusteFactor)) return true;
				if (sonCadenasDiferentes(detalles1[i].ajusteMotivoId, detalles2[i].ajusteMotivoId)) return true;
				if (sonCadenasDiferentes(detalles1[i].avaluoInstalacionesEspecialesId, detalles2[i].avaluoInstalacionesEspecialesId)) return true;
				if (sonNumerosDiferentes(detalles1[i].bloque, detalles2[i].bloque)) return true;
				if (sonCadenasDiferentes(detalles1[i].calidadId, detalles2[i].calidadId)) return true;
				if (sonCadenasDiferentes(detalles1[i].codigo, detalles2[i].codigo)) return true;
				if (sonCadenasDiferentes(detalles1[i].codigoClasificación, detalles2[i].codigoClasificación)) return true;
				if (sonNumerosDiferentes(detalles1[i].edad, detalles2[i].edad)) return true;
				if (sonCadenasDiferentes(detalles1[i].estadoConservacionId, detalles2[i].estadoConservacionId)) return true;
				if (sonCadenasDiferentes(detalles1[i].id, detalles2[i].id)) return true;
				if (sonNumerosDiferentes(detalles1[i].superficie, detalles2[i].superficie)) return true;
				if (sonCadenasDiferentes(detalles1[i].superficieUnidadId, detalles2[i].superficieUnidadId)) return true;
				if (sonCadenasDiferentes(detalles1[i].tipoConstruccionId, detalles2[i].tipoConstruccionId)) return true;
				if (sonNumerosDiferentes(detalles1[i].valorResultante, detalles2[i].valorResultante)) return true;
				if (sonNumerosDiferentes(detalles1[i].valorUnitario, detalles2[i].valorUnitario)) return true;
			}
		}
	}
	return false;
};

/**
 * Compara las medidas de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnMedidas = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { medidas: medidas1 } = avaluo1;
	const { medidas: medidas2 } = avaluo2;
	if (sonCadenasDiferentes(medidas1?.avaluoId, medidas2?.avaluoId)) return true;
	if (sonCadenasDiferentes(medidas1?.calidadProyecto, medidas2?.calidadProyecto)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia1Descripcion, medidas2?.colindancia1Descripcion)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia1VientoId, medidas2?.colindancia1VientoId)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia2Descripcion, medidas2?.colindancia2Descripcion)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia2VientoId, medidas2?.colindancia2VientoId)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia3Descripcion, medidas2?.colindancia3Descripcion)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia3VientoId, medidas2?.colindancia3VientoId)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia4Descripcion, medidas2?.colindancia4Descripcion)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia4VientoId, medidas2?.colindancia4VientoId)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia5Descripcion, medidas2?.colindancia5Descripcion)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia5VientoId, medidas2?.colindancia5VientoId)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia6Descripcion, medidas2?.colindancia6Descripcion)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia6VientoId, medidas2?.colindancia6VientoId)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia7Descripcion, medidas2?.colindancia7Descripcion)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia7VientoId, medidas2?.colindancia7VientoId)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia8Descripcion, medidas2?.colindancia8Descripcion)) return true;
	if (sonCadenasDiferentes(medidas1?.colindancia8VientoId, medidas2?.colindancia8VientoId)) return true;
	if (sonCadenasDiferentes(medidas1?.colindanciaSeparador, medidas2?.colindanciaSeparador)) return true;
	if (sonCadenasDiferentes(medidas1?.destinoEspecifico2Id, medidas2?.destinoEspecifico2Id)) return true;
	if (sonCadenasDiferentes(medidas1?.destinoEspecificoId, medidas2?.destinoEspecificoId)) return true;
	if (sonCadenasDiferentes(medidas1?.edad, medidas2?.edad)) return true;
	if (sonCadenasDiferentes(medidas1?.id, medidas2?.id)) return true;
	if (sonNumerosDiferentes(medidas1?.indiviso, medidas2?.indiviso)) return true;
	if (sonNumerosDiferentes(medidas1?.superficieCatastro, medidas2?.superficieCatastro)) return true;
	if (sonNumerosDiferentes(medidas1?.superficieEscritura, medidas2?.superficieEscritura)) return true;
	if (sonNumerosDiferentes(medidas1?.superficieReal, medidas2?.superficieReal)) return true;
	if (sonCadenasDiferentes(medidas1?.superficieUnidadId, medidas2?.superficieUnidadId)) return true;
	if (sonCadenasDiferentes(medidas1?.unidadesRentables, medidas2?.unidadesRentables)) return true;
	if (sonCadenasDiferentes(medidas1?.usoEspecifico2Id, medidas2?.usoEspecifico2Id)) return true;
	if (sonCadenasDiferentes(medidas1?.usoEspecificoId, medidas2?.usoEspecificoId)) return true;
	if (sonCadenasDiferentes(medidas1?.vidaRemanenteProbale, medidas2?.vidaRemanenteProbale)) return true;
	return false;
};

/**
 * Compara las observaciones de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnObservaciones = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { observaciones: observaciones1 } = avaluo1;
	const { observaciones: observaciones2 } = avaluo2;
	if (sonCadenasDiferentes(observaciones1?.avaluoId, observaciones2?.avaluoId)) return true;
	if (sonCadenasDiferentes(observaciones1?.descripcion, observaciones2?.descripcion)) return true;
	if (sonCadenasDiferentes(observaciones1?.id, observaciones2?.id)) return true;
	if (hayCambiosEnObservacionesValoresReferidos(observaciones1?.valoresReferidos, observaciones2?.valoresReferidos)) return true;
	return false;
};

/**
 * Compara los valores referidos de las observaciones de dos avalúos y regresa verdadero si son diferentes.
 * - ***valoresReferidos1*** - Primer arreglo de valores referidos a comparar.
 * - ***valoresReferidos2*** - Segundo arreglo de valores referidos a comparar.
 */
const hayCambiosEnObservacionesValoresReferidos = (valoresReferidos1?: Array<string> | null, valoresReferidos2?: Array<string> | null): boolean => {
	const longitud1 = valoresReferidos1?.length || 0;
	const longitud2 = valoresReferidos2?.length || 0;
	if (sonNumerosDiferentes(longitud1, longitud2)) {
		return true;
	} else {
		if (valoresReferidos1 && valoresReferidos2) {
			for (let i = 0; i < longitud1; i++) {
				if (sonCadenasDiferentes(valoresReferidos1[i], valoresReferidos2[i])) return true;
			}
		}
	}
	return false;
};

/**
 * Compara los servicios municipales de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnsServiciosMunicipales = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { serviciosMunicipales: serviciosMunicipales1 } = avaluo1;
	const { serviciosMunicipales: serviciosMunicipales2 } = avaluo2;
	if (serviciosMunicipales1?.avaluoId !== serviciosMunicipales2?.avaluoId) return true;
	if (serviciosMunicipales1?.descripcion !== serviciosMunicipales2?.descripcion) return true;
	if (serviciosMunicipales1?.id !== serviciosMunicipales2?.id) return true;
	return false;
};

/**
 * Compara el terreno de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnTerreno = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { terreno: terreno1 } = avaluo1;
	const { terreno: terreno2 } = avaluo2;
	if (sonCadenasDiferentes(terreno1?.avaluoId, terreno2?.avaluoId)) return true;
	if (sonCadenasDiferentes(terreno1?.id, terreno2?.id)) return true;
	if (sonNumerosDiferentes(terreno1?.indiviso, terreno2?.indiviso)) return true;
	if (sonNumerosDiferentes(terreno1?.superficieTotal, terreno2?.superficieTotal)) return true;
	if (sonCadenasDiferentes(terreno1?.superficieUnidadId, terreno2?.superficieUnidadId)) return true;
	if (sonNumerosDiferentes(terreno1?.valor, terreno2?.valor)) return true;
	if (sonNumerosDiferentes(terreno1?.valorTotal, terreno2?.valorTotal)) return true;
	if (hayCambiosEnTerrenoDetalles(terreno1?.detalles, terreno2?.detalles)) return true;
	return false;
};

/**
 * Compara los detalles del terreno de dos avalúos y regresa verdadero si son diferentes.
 * - ***detalles1*** - Primer arreglo de detalles a comparar.
 * - ***detalles2*** - Segundo arreglo de detalles a comparar.
 */
const hayCambiosEnTerrenoDetalles = (detalles1: Array<AvaluoTerrenoDetalle> | undefined, detalles2: Array<AvaluoTerrenoDetalle> | undefined): boolean => {
	const longitud1 = detalles1?.length || 0;
	const longitud2 = detalles2?.length || 0;
	if (sonNumerosDiferentes(longitud1, longitud2)) {
		return true;
	} else {
		if (detalles1 && detalles2) {
			for (let i = 0; i < longitud1; i++) {
				if (sonNumerosDiferentes(detalles1[i].ajusteFactor, detalles2[i].ajusteFactor)) return true;
				if (sonCadenasDiferentes(detalles1[i].ajusteMotivoId, detalles2[i].ajusteMotivoId)) return true;
				if (sonCadenasDiferentes(detalles1[i].avaluoTerrenoId, detalles2[i].avaluoTerrenoId)) return true;
				if (sonNumerosDiferentes(detalles1[i].fraccion, detalles2[i].fraccion)) return true;
				if (sonCadenasDiferentes(detalles1[i].id, detalles2[i].id)) return true;
				if (sonNumerosDiferentes(detalles1[i].superficie, detalles2[i].superficie)) return true;
				if (sonCadenasDiferentes(detalles1[i].superficieUnidadId, detalles2[i].superficieUnidadId)) return true;
				if (sonNumerosDiferentes(detalles1[i].valorNeto, detalles2[i].valorNeto)) return true;
				if (sonNumerosDiferentes(detalles1[i].valorResultante, detalles2[i].valorResultante)) return true;
				if (sonNumerosDiferentes(detalles1[i].valorUnitario, detalles2[i].valorUnitario)) return true;
			}
		}
	}
	return false;
};

/**
 * Compara la ubicación de dos avalúos y regresa verdadero si son diferentes.
 * - ***avaluo1*** - Primer avalúo a comparar.
 * - ***avaluo2*** - Segundo avalúo a comparar.
 */
const hayCambiosEnUbicacion = (avaluo1: Avaluo, avaluo2: Avaluo): boolean => {
	const { ubicacion: ubicacion1 } = avaluo1;
	const { ubicacion: ubicacion2 } = avaluo2;
	if (sonCadenasDiferentes(ubicacion1?.avaluoId, ubicacion2?.avaluoId)) return true;
	if (hayCambiosEnUbicacionContorno(ubicacion1?.contornoCoordenadasDdArreglo, ubicacion2?.contornoCoordenadasDdArreglo)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasDdLatitud, ubicacion2?.coordenadasDdLatitud)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasDdLongitud, ubicacion2?.coordenadasDdLongitud)) return true;
	if (sonCadenasDiferentes(ubicacion1?.coordenadasDmsLatitudCardinal, ubicacion2?.coordenadasDmsLatitudCardinal)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasDmsLatitudGrados, ubicacion2?.coordenadasDmsLatitudGrados)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasDmsLatitudMinutos, ubicacion2?.coordenadasDmsLatitudMinutos)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasDmsLatitudSegundos, ubicacion2?.coordenadasDmsLatitudSegundos)) return true;
	if (sonCadenasDiferentes(ubicacion1?.coordenadasDmsLongitudCardinal, ubicacion2?.coordenadasDmsLongitudCardinal)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasDmsLongitudGrados, ubicacion2?.coordenadasDmsLongitudGrados)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasDmsLongitudMinutos, ubicacion2?.coordenadasDmsLongitudMinutos)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasDmsLongitudSegundos, ubicacion2?.coordenadasDmsLongitudSegundos)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasUtmEsteX, ubicacion2?.coordenadasUtmEsteX)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasUtmHuso, ubicacion2?.coordenadasUtmHuso)) return true;
	if (sonNumerosDiferentes(ubicacion1?.coordenadasUtmNorteY, ubicacion2?.coordenadasUtmNorteY)) return true;
	if (sonCadenasDiferentes(ubicacion1?.coordenadasUtmZona, ubicacion2?.coordenadasUtmZona)) return true;
	if (sonCadenasDiferentes(ubicacion1?.croquisImagenNombre, ubicacion2?.croquisImagenNombre)) return true;
	if (sonNumerosDiferentes(ubicacion1?.distanciaEsquina, ubicacion2?.distanciaEsquina)) return true;
	if (sonCadenasDiferentes(ubicacion1?.distanciaEsquinaCalle, ubicacion2?.distanciaEsquinaCalle)) return true;
	if (hayCambiosEnUbicacionDistanciaCalle(ubicacion1?.distanciaEsquinaCoordenadasDdArreglo, ubicacion2?.distanciaEsquinaCoordenadasDdArreglo)) return true;
	if (sonCadenasDiferentes(ubicacion1?.id, ubicacion2?.id)) return true;
	if (sonCadenasDiferentes(ubicacion1?.mapaCentro, ubicacion2?.mapaCentro)) return true;
	if (sonCadenasDiferentes(ubicacion1?.mapaImagenGoogleMapsApi, ubicacion2?.mapaImagenGoogleMapsApi)) return true;
	if (sonCadenasDiferentes(ubicacion1?.mapaImagenNombre, ubicacion2?.mapaImagenNombre)) return true;
	if (sonNumerosDiferentes(ubicacion1?.mapaZoom, ubicacion2?.mapaZoom)) return true;
	if (sonCadenasDiferentes(ubicacion1?.mapaPersonalizadoImagenNombre, ubicacion2?.mapaPersonalizadoImagenNombre)) return true;
	if (sonCadenasDiferentes(ubicacion1?.avaluoId, ubicacion2?.avaluoId)) return true;
	if (sonCadenasDiferentes(ubicacion1?.avaluoId, ubicacion2?.avaluoId)) return true;
	if (sonCadenasDiferentes(ubicacion1?.avaluoId, ubicacion2?.avaluoId)) return true;
	if (sonCadenasDiferentes(ubicacion1?.avaluoId, ubicacion2?.avaluoId)) return true;
	return false;
};

/**
 * Compara el arreglo de coordenadas del contorno de dos avalúos y regresa verdadero si son diferentes.
 * - ***arreglo1*** - Primer arreglo de coordenadas DD a comparar.
 * - ***arreglo1*** - Segundo arreglo de coordenadas DD a comparar.
 */
const hayCambiosEnUbicacionContorno = (arreglo1?: Array<CoordenadasDD> | null, arreglo2?: Array<CoordenadasDD> | null): boolean => {
	const longitud1 = arreglo1?.length || 0;
	const longitud2 = arreglo2?.length || 0;
	if (sonNumerosDiferentes(longitud1, longitud2)) {
		return true;
	} else {
		if (arreglo1 && arreglo2) {
			for (let i = 0; i < longitud1; i++) {
				if (sonNumerosDiferentes(arreglo1[i].latitud, arreglo2[i].latitud)) return true;
				if (sonNumerosDiferentes(arreglo1[i].longitud, arreglo2[i].longitud)) return true;
			}
		}
	}
	return false;
};

/**
 * Compara el arreglo de coordenadas de la distancia a la calle de dos avalúos y regresa verdadero si son diferentes.
 * - ***arreglo1*** - Primer arreglo de coordenadas DD a comparar.
 * - ***arreglo1*** - Segundo arreglo de coordenadas DD a comparar.
 */
const hayCambiosEnUbicacionDistanciaCalle = (arreglo1?: Array<CoordenadasDD> | null, arreglo2?: Array<CoordenadasDD> | null): boolean => {
	const longitud1 = arreglo1?.length || 0;
	const longitud2 = arreglo2?.length || 0;
	if (sonNumerosDiferentes(longitud1, longitud2)) {
		return true;
	} else {
		if (arreglo1 && arreglo2) {
			for (let i = 0; i < longitud1; i++) {
				if (sonNumerosDiferentes(arreglo1[i].latitud, arreglo2[i].latitud)) return true;
				if (sonNumerosDiferentes(arreglo1[i].longitud, arreglo2[i].longitud)) return true;
			}
		}
	}
	return false;
};

/**
 * Válida el formulario de la sección antecedentes de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioAntecedentes = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		errores.antecedentes = {};
	}
};

/**
 * Válida el formulario de la sección características de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioCaracteristicas = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		errores.caracteristicas = {};
	}
};

/**
 * Válida el formulario de la sección construcciones de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioConstrucciones = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		if (!valores.formularioDetalle) {
			valores.formularioDetalle = {};
		}
		errores.construcciones = {
			formularioDetalle: {}
		};
	}
};

/**
 * Válida el formulario de la sección constrcciones dominantes de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioConstruccionesDominantes = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		errores.construccionesDominantes = {
			detalles: {}
		};
	}
};

/**
 * Válida el formulario de la sección domicilio de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioDomicilio = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		errores.domicilio = {
			formularioDetalle: {}
		};
	}
};

/**
 * Válida el formulario de la sección distribución de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioDistribucion = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		errores.distribucion = {
			detalles: {}
		};
	}
};

/**
 * Válida el formulario de la sección elementos de construcción de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioElementosConstruccion = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		errores.elementosConstruccion = {
			detalles: {}
		};
	}
};

/**
 * Válida el formulario de la sección escritura de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioEscritura = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		errores.escritura = {};
	}
};

/**
 * Válida el formulario de la sección instalaciones especiales de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioInstalacionesEspeciales = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		if (!valores.formularioDetalle) {
			valores.formularioDetalle = {};
		}
		errores.instalacionesEspeciales = {
			formularioDetalle: {}
		};
	}
};

/**
 * Válida el formulario de la sección medidas de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioMedidas = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		errores.medidas = {};
	}
};

/**
 * Válida el formulario de la sección servicios municipales de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioServiciosMunicipales = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		errores.serviciosMunicipales = {
			detalles: {}
		};
	}
};

/**
 * Válida el formulario de la sección terreno de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioTerreno = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		if (!valores.formularioDetalle) {
			valores.formularioDetalle = {};
		}
		errores.terreno = {
			formularioDetalle: {}
		};
	}
};

/**
 * Válida el formulario de la sección ubicación de manera síncrona.
 * - ***valores*** - Valores de los campos a validar.
 * - ***errores*** - Objeto con los errores del formulario.
 */
const validarFormularioUbicacion = (valores: Record<string, any>, errores: ValidationErrors): void => {
	if (errores) {
		errores.ubicacion = {};
		if (tieneValor(valores.coordenadasDmsLatitudCardinal) && !esCardinalDMSValido(valores.coordenadasDmsLatitudCardinal)) {
			errores.ubicacion.coordenadasDmsLatitudCardinal = texto('Cardinal DMS invalido');
		}
		if (tieneValor(valores.coordenadasDmsLongitudCardinal) && !esCardinalDMSValido(valores.coordenadasDmsLongitudCardinal)) {
			errores.ubicacion.coordenadasDmsLongitudCardinal = texto('Cardinal DMS invalido');
		}
		if (tieneValor(valores.coordenadasUtmZona) && !esZonaUTMValida(valores.coordenadasUtmZona)) {
			errores.ubicacion.coordenadasUtmZona = texto('Zona UTM invalida');
		}
	}
};
