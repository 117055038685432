import Avaluo from 'modelo/Avaluo';
import AvaluoConstruccionesDetalle from 'modelo/AvaluoConstruccionesDetalle';
import { convertirNumeroEnteroANumeroRomano } from '@mcsoft/numeros';
import disenos from './disenos';

const seccion = (avaluo: Avaluo) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.construcciones) {
			const { detalles } = avaluo.construcciones;
			const tablaContrucciones = obtenerTablaContrucciones(detalles);
			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: tablaContrucciones,
						widths: [50, 80, 50, '*', 120]
					}
				}
			];
			resolve(contenido);
		}
	});

const obtenerTablaContrucciones = (detalles: Array<AvaluoConstruccionesDetalle>) => {
	const tablaEncabezado = [
		[
			{
				colSpan: 5,
				style: 'seccionTitulo',
				text: 'TIPOS DE CONSTRUCCIÓN'
			},
			{},
			{},
			{},
			{}
		],
		[
			{
				style: 'seccionTablaEncabezado',
				text: 'BLOQUE'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'CLASIFICACIÓN'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'TIPO'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'CALIDAD'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'ESTADO DE CONSERVACIÓN'
			}
		]
	];
	const renglones: any = [];
	detalles.forEach((detalle) => {
		renglones.push([
			{
				style: 'seccionTablaCelda',
				text: convertirNumeroEnteroANumeroRomano(detalle.bloque)
			},
			{
				style: 'seccionTablaCelda',
				text: detalle.codigo
			},
			{
				style: 'seccionTablaCelda',
				text: detalle.tipoConstruccion?.nombre
			},
			{
				style: 'seccionTablaCelda',
				text: detalle.calidad?.nombre
			},
			{
				style: 'seccionTablaCelda',
				text: detalle.estadoConservacion?.nombre
			}
		]);
	});
	if (detalles.length === 0) {
		renglones.push([
			{
				margin: [0, 10, 0, 0],
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCelda',
				text: ''
			}
		]);
	}
	return [...tablaEncabezado, ...renglones];
};

export default seccion;
