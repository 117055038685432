import 'moment/locale/es-mx';
import { domicilioCompletoPerito, nombreCompletoPerito } from 'util/modelo/peritos';
import { formatearTelefono, rellenarConCaracteresInicio } from '@mcsoft/cadenas';
import Avaluo from 'modelo/Avaluo';
import disenos from './disenos';
import moment from 'moment-timezone';
import Perito from 'modelo/Perito';
import PeritoDomicilio from 'modelo/PeritoDomicilio';
import Usuario from 'modelo/Usuario';
moment.locale('es-mx');

const seccion = ({ avaluo, tipoEncabezado, usuario }: { avaluo: Avaluo; tipoEncabezado: string; usuario: Usuario }) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.antecedentes && avaluo.antecedentes.perito && avaluo.antecedentes.perito.domicilio) {
			const { antecedentes, folio } = avaluo;
			const { perito } = antecedentes;
			if (perito) {
				const { domicilio } = perito;
				const contenido = [
					obtenerEncabezado({ domicilio, folio, perito, tipoEncabezado, usuario }),
					{
						layout: {
							paddingBottom: () => 0,
							paddingLeft: () => 1,
							paddingRight: () => 1,
							paddingTop: () => 0
						},
						style: 'encabezadoTitulo',
						table: {
							body: [[{ text: 'AVALÚO PARA TRANSMISIÓN DE DOMINIO CON VALORES CATASTRALES' }]],
							widths: ['*']
						}
					}
				];
				resolve(contenido);
			}
		}
	});

export default seccion;

/**
 * Genera el body del encabezado.
 * - ***domicilio*** - Objeto con la infomación del domicilio del perito.
 * - ***folio*** - Folio del avalúo.
 * - ***perito*** - Objeto con la infomación del perito.
 * - ***usuario*** - Objeto con la infomación del usuario.
 */
const obtenerEncabezado = ({
	domicilio,
	folio,
	perito,
	tipoEncabezado,
	usuario
}: {
	domicilio: PeritoDomicilio;
	folio: number;
	perito: Perito;
	tipoEncabezado: string;
	usuario: Usuario;
}) => {
	switch (tipoEncabezado) {
		case 'compacto':
			return obtenerEncabezadoCompacto({ domicilio, folio, perito, usuario });
		default:
			return obtenerEncabezadoNormal({ domicilio, folio, perito, usuario });
	}
};

/**
 * Genera el body del encabezado compacto.
 * - ***domicilio*** - Objeto con la infomación del domicilio del perito.
 * - ***folio*** - Folio del avalúo.
 * - ***perito*** - Objeto con la infomación del perito.
 * - ***usuario*** - Objeto con la infomación del usuario.
 */
const obtenerEncabezadoCompacto = ({ domicilio, folio, perito, usuario }: { domicilio: PeritoDomicilio; folio: number; perito: Perito; usuario: Usuario }) => ({
	layout: disenos.sinPaddingArribaAbajo,
	style: 'encabezado',
	table: {
		body: [
			[
				{
					border: [1, 1, 1, 0],
					style: 'encabezadoEtiquetaNegritas',
					text: 'Nombre del Perito:'
				},
				{
					border: [1, 1, 0, 0],
					style: 'encabezadoTextoNombrePerito',
					text: perito && perito.tratamientoProtocolario ? `${perito.tratamientoProtocolario} ${nombreCompletoPerito(perito)}` : ''
				},
				{
					border: [0, 1, 0, 0],
					style: 'encabezadoEtiquetaNegritas',
					text: 'Folio.'
				},
				{
					border: [1, 1, 1, 1],
					style: 'encabezadoTextoFolio',
					text: folio ? rellenarConCaracteresInicio({ cadena: folio.toString(), longitud: 6 }) : ''
				}
			],
			[
				{
					border: [1, 0, 1, 0],
					style: 'encabezadoEtiqueta',
					text: 'Vigencia y número del perito:'
				},
				{
					border: [1, 0, 0, 0],
					style: 'encabezadoTexto',
					text: `${perito && perito.licenciaVigencia ? moment(perito.licenciaVigencia).tz('America/Bahia_Banderas').locale(false).format('DD [de] MMMM [de] YYYY') : ''} No. ${
						perito && perito.licenciaNumeroRegistro ? perito.licenciaNumeroRegistro : ''
					}`
				},
				{
					border: [0, 0, 0, 0],
					text: ''
				},
				{
					border: [0, 0, 1, 0],
					style: 'encabezadoTextoRoej',
					text: usuario && usuario.preferencias && usuario.preferencias.avaluosCodigoValuador ? usuario.preferencias.avaluosCodigoValuador : ''
				}
			],
			[
				{
					border: [1, 0, 1, 0],
					style: 'encabezadoEtiqueta',
					text: 'Domicilio fiscal:'
				},
				{
					border: [1, 0, 0, 0],
					style: 'encabezadoTexto',
					text: domicilio ? domicilioCompletoPerito(domicilio) : ''
				},
				{
					border: [0, 0, 0, 0],
					text: ''
				},
				{
					border: [0, 0, 1, 0],
					text: ''
				}
			],
			[
				{
					border: [1, 0, 1, 1],
					style: 'encabezadoEtiqueta',
					text: 'Correo electrónico:'
				},
				{
					border: [1, 0, 0, 1],
					text: perito && perito.correoElectronico ? perito.correoElectronico : ''
				},
				{
					border: [0, 0, 0, 1],
					style: 'encabezadoEtiquetaTelefono',
					text: 'Tel.'
				},
				{
					border: [0, 0, 1, 1],
					style: 'encabezadoTextoTelefono',
					text: perito && perito.telefono ? formatearTelefono(perito.telefono) : ''
				}
			]
		],
		widths: [100, '*', 25, 80]
	}
});

/**
 * Genera el body del encabezado normal.
 * - ***domicilio*** - Objeto con la infomación del domicilio del perito.
 * - ***folio*** - Folio del avalúo.
 * - ***perito*** - Objeto con la infomación del perito.
 * - ***usuario*** - Objeto con la infomación del usuario.
 */
const obtenerEncabezadoNormal = ({ domicilio, folio, perito, usuario }: { domicilio: PeritoDomicilio; folio: number; perito: Perito; usuario: Usuario }) => ({
	layout: disenos.sinPaddingArribaAbajo,
	style: 'encabezado',
	table: {
		body: [
			[
				{
					border: [1, 1, 1, 0],
					style: 'encabezadoEtiquetaNegritas',
					text: 'Nombre del Perito:'
				},
				{
					border: [1, 1, 0, 0],
					style: 'encabezadoTextoNombrePerito',
					text: perito && perito.tratamientoProtocolario ? `${perito.tratamientoProtocolario} ${nombreCompletoPerito(perito)}` : ''
				},
				{
					border: [0, 1, 0, 0],
					style: 'encabezadoEtiquetaNegritas',
					text: 'Folio.'
				},
				{
					border: [1, 1, 1, 1],
					style: 'encabezadoTextoFolio',
					text: folio ? rellenarConCaracteresInicio({ cadena: folio.toString(), longitud: 6 }) : ''
				}
			],
			[
				{
					border: [1, 0, 1, 0],
					style: 'encabezadoEtiqueta',
					text: 'Domicilio:'
				},
				{
					border: [1, 0, 0, 0],
					style: 'encabezadoTexto',
					text: domicilio ? domicilioCompletoPerito(domicilio) : ''
				},
				{
					border: [0, 0, 0, 0],
					text: ''
				},
				{
					border: [0, 0, 1, 0],
					style: 'encabezadoTextoRoej',
					text: usuario && usuario.preferencias && usuario.preferencias.avaluosCodigoValuador ? usuario.preferencias.avaluosCodigoValuador : ''
				}
			],
			[
				{
					border: [1, 0, 1, 0],
					style: 'encabezadoEtiqueta',
					text: 'No. Perito:'
				},
				{
					border: [1, 0, 0, 0],
					style: 'encabezadoTexto',
					text: perito && perito.licenciaNumeroRegistro ? perito.licenciaNumeroRegistro : ''
				},
				{
					border: [0, 0, 0, 0],
					text: ''
				},
				{
					border: [0, 0, 1, 0],
					text: ''
				}
			],
			[
				{
					border: [1, 0, 1, 0],
					style: 'encabezadoEtiqueta',
					text: 'Vigencia:'
				},
				{
					border: [1, 0, 0, 0],
					style: 'encabezadoTexto',
					text: perito && perito.licenciaVigencia ? moment(perito.licenciaVigencia).tz('America/Bahia_Banderas').locale(false).format('DD [de] MMMM [de] YYYY') : ''
				},
				{
					border: [0, 0, 0, 0],
					text: ''
				},
				{
					border: [0, 0, 1, 0],
					text: ''
				}
			],
			[
				{
					border: [1, 0, 1, 1],
					style: 'encabezadoEtiqueta',
					text: 'Correo electrónico:'
				},
				{
					border: [1, 0, 0, 1],
					text: perito && perito.correoElectronico ? perito.correoElectronico : ''
				},
				{
					border: [0, 0, 0, 1],
					style: 'encabezadoEtiquetaTelefono',
					text: 'Tel.'
				},
				{
					border: [0, 0, 1, 1],
					style: 'encabezadoTextoTelefono',
					text: perito && perito.telefono ? formatearTelefono(perito.telefono) : ''
				}
			]
		],
		widths: [100, '*', 25, 80]
	}
});
