const disenos = {
	conPaddingArribaAbajo5: {
		paddingBottom: () => 5,
		paddingLeft: () => 1,
		paddingRight: () => 1,
		paddingTop: () => 5
	},
	sinBordes: {
		hLineWidth: () => 0,
		vLineWidth: () => 0
	},
	sinPadding: {
		paddingBottom: () => 0,
		paddingLeft: () => 0,
		paddingRight: () => 0,
		paddingTop: () => 0
	},
	sinPaddingArribaAbajo: {
		paddingBottom: () => 0,
		paddingLeft: () => 1,
		paddingRight: () => 1,
		paddingTop: () => 0
	}
};

export default disenos;
