import McLoggerConsola from './logger-consola';
import McLoggerSentry from './logger-sentry';

let instancia: McLogger;

/**
 * Clase para generar los registros de log de distintos niveles.
 */
class McLogger {
	forzarLoggerConsola: boolean;

	loggerConsola: McLoggerConsola | null;

	loggerSentry: McLoggerSentry | null;

	usarLoggerSentry: boolean;

	constructor() {
		this.loggerConsola = null;
		this.loggerSentry = null;
		this.forzarLoggerConsola = false;
		this.usarLoggerSentry = false;
		if (!instancia) {
			this.loggerConsola = new McLoggerConsola();
			instancia = this;
		}
		return instancia;
	}

	/**
	 * Crea un registro de nivel API. Utilizado para logs de llamadas a API's.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	api({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		this.loggerConsola?.api({
			forzarLog: this.forzarLoggerConsola,
			mensaje,
			nombreArchivo,
			nombreMetodo,
			objetoExtra
		});
	}

	/**
	 * Configura la instancia de Sentry y la habilita para guardar registros en el servidor
	 * - ***dns*** DNS de la aplicación en Sentry.
	 * - ***paquete*** Paquete de la aplicación en Sentry.
	 * - ***version*** Versión de la aplicación en Sentry.
	 * - ***entorno*** Entorno en el que esta corriendo la aplicación.
	 * - ***usuarioId*** Id del usuario actual de la aplicación.
	 * - ***usuarioNombre*** Nombre del usuario actual de la aplicación.
	 * - ***usuarioCorreoElectronico*** Correo electrónico del usuario actual de la aplicación.
	 */
	configurarLoggerSentry(dns: string, paquete: string, version: string, entorno: string, usuarioId: string, usuarioNombre: string, usuarioCorreoElectronico: string): void {
		this.loggerSentry = new McLoggerSentry(dns, paquete, version, entorno, usuarioId, usuarioNombre, usuarioCorreoElectronico);
		this.usarLoggerSentry = true;
	}

	/**
	 * Crea un registro de nivel DEV. Utilizado para logs de desarrollo.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	dev({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		this.loggerConsola?.dev({
			forzarLog: this.forzarLoggerConsola,
			mensaje,
			nombreArchivo,
			nombreMetodo,
			objetoExtra
		});
	}

	/**
	 * Crea un registro de nivel ERROR. Utilizado para logs de error.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	error({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		this.loggerConsola?.error({
			forzarLog: this.forzarLoggerConsola,
			mensaje,
			nombreArchivo,
			nombreMetodo,
			objetoExtra
		});
		this.loggerSentry?.error({ mensaje, nombreArchivo, nombreMetodo, objetoExtra });
	}

	/**
	 * Crea un registro de nivel LOCAL STORAGE. Utilizado para logs de cambios en el localStorage.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	localStorage({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		this.loggerConsola?.localStorage({
			forzarLog: this.forzarLoggerConsola,
			mensaje,
			nombreArchivo,
			nombreMetodo,
			objetoExtra
		});
	}

	/**
	 * Crea un registro de nivel LOG. Utilizado para logs normales.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	log({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		this.loggerConsola?.log({
			forzarLog: this.forzarLoggerConsola,
			mensaje,
			nombreArchivo,
			nombreMetodo,
			objetoExtra
		});
	}

	/**
	 * Configura la instancia del logger para forzar todos los logs de consola.
	 */
	modoDesarrollo(): void {
		this.forzarLoggerConsola = true;
	}

	/**
	 * Crea un registro de nivel NODE MODULE. Utilizado para logs dentro de librerias externas.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	nodeModule({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		this.loggerConsola?.nodeModule({
			forzarLog: this.forzarLoggerConsola,
			mensaje,
			nombreArchivo,
			nombreMetodo,
			objetoExtra
		});
	}

	/**
	 * Crea un registro de nivel REACT. Utilizado para logs de los ciclos de vida de React.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	react({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		this.loggerConsola?.react({
			forzarLog: this.forzarLoggerConsola,
			mensaje,
			nombreArchivo,
			nombreMetodo,
			objetoExtra
		});
	}

	/**
	 * Crea un registro de nivel REDUX. Utilizado para logs de cambios en el state de Redux.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	redux({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		this.loggerConsola?.redux({
			forzarLog: this.forzarLoggerConsola,
			mensaje,
			nombreArchivo,
			nombreMetodo,
			objetoExtra
		});
	}

	/**
	 * Crea un registro de nivel SESSION STORAGE. Utilizado para logs de cambios en el sessionStorage.
	 * - ***nombreArchivo*** Nombre del archivo desde donde se realiza el registro.
	 * - ***nombreMetodo*** Nombre del método que realiza el registro.
	 * - ***mensaje*** Mensaje que se desea registrar.
	 * - ***objetoExtra*** Objeto con información extra para el registro.
	 */
	sessionStorage({ mensaje, nombreArchivo, nombreMetodo, objetoExtra }: { mensaje: string; nombreArchivo: string; nombreMetodo: string; objetoExtra?: any }): void {
		this.loggerConsola?.sessionStorage({
			forzarLog: this.forzarLoggerConsola,
			mensaje,
			nombreArchivo,
			nombreMetodo,
			objetoExtra
		});
	}
}

export default new McLogger();
