import { Col, Row } from 'reactstrap';
import { McCampoSelector, McSwitchRedondo } from '@mcsoft/formulario';
import InformacionAvaluoPdfCoeficientesUtilizacionOcupacion from 'componentes/informacion/InformacionAvaluoPdfCoeficientesUtilizacionOcupacion';
import InformacionAvaluoPdfCoordenadasDd from 'componentes/informacion/InformacionAvaluoPdfCoordenadasDd';
import InformacionAvaluoPdfCoordenadasDms from 'componentes/informacion/InformacionAvaluoPdfCoordenadasDms';
import InformacionAvaluoPdfCoordenadasUtm from 'componentes/informacion/InformacionAvaluoPdfCoordenadasUtm';
import InformacionAvaluoPdfEncabezado from 'componentes/informacion/InformacionAvaluoPdfEncabezado';
import InformacionAvaluoPdfEtiquetaConstruccion from 'componentes/informacion/InformacionAvaluoPdfEtiquetaConstruccion';
import InformacionAvaluoPdfLicencia from 'componentes/informacion/InformacionAvaluoPdfLicencia';
import InformacionAvaluoPdfNumeroPagina from 'componentes/informacion/InformacionAvaluoPdfNumeroPagina';
import InformacionAvaluoPdfRellenarEspacios from 'componentes/informacion/InformacionAvaluoPdfRellenarEspacios';
import { texto } from 'idiomas';

/**
 * Formulario para las opciones de creación del PDF del avalúo.
 */
const AvaluoFormularioPdf = () => (
	<>
		<div className="modal-header border-soft-secondary bg-soft-secondary">
			<h5 className="modal-title mt-0">{texto('PDF Personalizado')}</h5>
		</div>
		<div className="modal-body border-soft-secondary">
			<p>{texto('Selecciona la configuración del PDF del avalúo')}</p>
		</div>
		<Row className="ms-3">
			<Col md="11">
				<McCampoSelector
					campo="avaluosPdfTipoEncabezado"
					etiqueta={texto('Tipo de encabezado')}
					id="avaluosPdfTipoEncabezado"
					informacion={InformacionAvaluoPdfEncabezado}
					opciones={[
						{ nombre: 'Normal', valor: 'normal' },
						{ nombre: 'Compacto', valor: 'compacto' }
					]}
					sinOpcionEnBlanco
				/>
			</Col>
			<Col md="11">
				<McCampoSelector
					campo="avaluosPdfEtiquetaConstruccionCodigo"
					etiqueta={texto('Etiqueta del codigo de construcción')}
					id="avaluosPdfEtiquetaConstruccionCodigo"
					informacion={InformacionAvaluoPdfEtiquetaConstruccion}
					opciones={[
						{ nombre: 'Código', valor: 'codigo' },
						{ nombre: 'Clasificación', valor: 'clasificacion' }
					]}
					sinOpcionEnBlanco
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosPdfMostrarCoeficientesUtilizacionOcupacion"
					etiqueta={texto('Mostrar coeficientes de utilización y ocupación')}
					etiquetaLineal
					id="avaluosPdfMostrarCoeficientesUtilizacionOcupacion"
					informacion={InformacionAvaluoPdfCoeficientesUtilizacionOcupacion}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosPdfMostrarCoordenadasDD"
					etiqueta={texto('Mostrar coordenadas DD')}
					etiquetaLineal
					id="avaluosPdfMostrarCoordenadasDD"
					informacion={InformacionAvaluoPdfCoordenadasDd}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosPdfMostrarCoordenadasDMS"
					etiqueta={texto('Mostrar coordenadas DMS')}
					etiquetaLineal
					id="avaluosPdfMostrarCoordenadasDMS"
					informacion={InformacionAvaluoPdfCoordenadasDms}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosPdfMostrarCoordenadasUTM"
					etiqueta={texto('Mostrar coordenadas UTM')}
					etiquetaLineal
					id="avaluosPdfMostrarCoordenadasUTM"
					informacion={InformacionAvaluoPdfCoordenadasUtm}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosPdfMostrarLicenciaFoto"
					etiqueta={texto('Mostrar la foto de la licencia del perito')}
					etiquetaLineal
					id="avaluosPdfMostrarLicenciaFoto"
					informacion={InformacionAvaluoPdfLicencia}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosPdfMostrarNumeroPagina"
					etiqueta={texto('Mostrar número de pagina')}
					etiquetaLineal
					id="avaluosPdfMostrarNumeroPagina"
					informacion={InformacionAvaluoPdfNumeroPagina}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosPdfRellenarEspaciosSinFoto"
					etiqueta={texto('Rellenar los espacios sin foto')}
					etiquetaLineal
					id="avaluosPdfRellenarEspaciosSinFoto"
					informacion={InformacionAvaluoPdfRellenarEspacios}
				/>
			</Col>
		</Row>
	</>
);

export default AvaluoFormularioPdf;
