import { Card, CardBody } from 'reactstrap';
import { formatearAvaluoParaFormulario, validarAvaluoFormulario } from 'util/modelo/avaluos';
import { mutatorCambiarValorCampo, mutatorCopiarValorCampo, mutatorLimpiarValorCampo } from '@mcsoft/formulario';
import { useActualizarAvaluo, useGuardarAvaluo } from 'hooks/useAvaluo';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEsSeccionAdministracion, useEsSeccionCreacion, useEsSeccionEdicion, useEsSeccionPreferencias } from 'hooks/useSeccion';
import { useHistory, useParams } from 'react-router';
import { actualizarUsuarioPreferencias } from 'servicios/api/usuarios';
import AvaluoFormulario from 'componentes/avaluos/AvaluoFormulario';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import formularioValorInicial from 'paginas/app/avaluos/valorInicial';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { obtenerAvaluoPorId } from 'servicios/api/avaluos';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { ValidationErrors } from 'final-form';

const NOMBRE_CLASE = 'paginas/app/avaluos/AvaluoDetallesEditar';

/**
 * En esta página se puede crear y editar un avalúo.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const guardarAvaluo = useGuardarAvaluo();
	const actualizarAvaluo = useActualizarAvaluo();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const esSeccionCreacion = useEsSeccionCreacion();
	const esSeccionEdicion = useEsSeccionEdicion();
	const esSeccionPreferencias = useEsSeccionPreferencias();
	const { id } = useParams<any>();
	const { sesion } = useSelector((state: StateType) => state);
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.avaluos);
	const {
		criterio: criterioAdmin,
		orden: ordenAdmin,
		ordenamiento: ordenamientoAdmin,
		pagina: paginaAdmin,
		registrosPorPagina: registrosPorPaginaAdmin
	} = useSelector((state: StateType) => state.parametrosPaginacion.avaluosAdmin);
	const [iconoSeccion, setIconoSeccion] = useState<string>('');
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [rutaSalida, setRutaSalida] = useState<string>(`${constantes.ruta.appAvaluosDetalles}/${id}`);
	const [enlaces, setEnlaces] = useState<any>([]);
	const [formulario, setFormulario] = useState<any>(formularioValorInicial);
	const [formularioOriginal, setFormularioOriginal] = useState<any>(formularioValorInicial);
	const { auth0, usuario } = sesion;
	const { auth0AccessToken } = auth0;
	const { location } = history;

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, []);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, [location.pathname]);

	/**
	 * Deshace los ultimos cambios.
	 */
	const eventoDeshacer = () => {
		const nombreMetodo = 'eventoDeshacer';
		mcLogger.log({ mensaje: `Deshaciendo los cambios.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		obtenerRegistro();
	};

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoGuardar';
		try {
			dispatch(setPantallaCargaMostrarAction(true));
			mcLogger.log({ mensaje: `Guardando avalúo...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			if (esSeccionCreacion()) {
				const avaluo = await guardarAvaluo({ avaluo: valores as any, usuario });
				mcLogger.log({ mensaje: `Avalúo creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto('Avalúo creado con exito.') });
				history.push(`${constantes.ruta.appAvaluosDetallesEditar}/${avaluo.id}`);
			}
			if (esSeccionEdicion()) {
				await actualizarAvaluo(valores as any);
				mcLogger.log({ mensaje: `Avalúo actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto('Avalúo actualizado con exito.') });
				obtenerRegistro();
			}
			if (esSeccionPreferencias()) {
				const { id, preferencias } = usuario;
				preferencias.avaluosValoresPredeterminados = JSON.stringify(valores);
				await actualizarUsuarioPreferencias({ auth0AccessToken, id: id, valores: preferencias });
				mcLogger.log({ mensaje: `Preferencias actualizadas con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto('Preferencias actualizadas con éxito.') });
			}
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar el avalúo:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Válida el formulario de manera síncrona.
	 * - ***valores*** - Valores de los campos a validar.
	 */
	const eventoValidarFormulario = (valores: Record<string, any>): ValidationErrors => {
		const errores: ValidationErrors = {};
		if (!esSeccionPreferencias()) {
			validarAvaluoFormulario(valores);
		}
		return errores;
	};

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoSalir = () => {
		const nombreMetodo = 'eventoSalir';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Inicializando formulario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			if (esSeccionCreacion()) {
				const { avaluosValoresPredeterminados } = usuario.preferencias;
				let formulario: any;
				if (avaluosValoresPredeterminados) {
					formulario = formatearAvaluoParaFormulario(JSON.parse(avaluosValoresPredeterminados) as any);
				} else {
					formulario = formatearAvaluoParaFormulario(formularioValorInicial as any);
				}
				setFormulario(formulario);
				setFormularioOriginal(formulario);
			}
			if (esSeccionEdicion()) {
				const respuesta = await obtenerAvaluoPorId({ auth0AccessToken, id });
				const { datos } = respuesta;
				const formulario: any = formatearAvaluoParaFormulario(datos);
				setFormulario(formulario);
				setFormularioOriginal(formulario);
			}
			if (esSeccionPreferencias()) {
				const { avaluosValoresPredeterminados } = usuario.preferencias;
				let formulario: any;
				if (avaluosValoresPredeterminados) {
					formulario = formatearAvaluoParaFormulario(JSON.parse(avaluosValoresPredeterminados) as any);
				} else {
					formulario = formatearAvaluoParaFormulario(formularioValorInicial as any);
				}
				setFormulario(formulario);
				setFormularioOriginal(formulario);
			}
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al inicializar el formulario:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	/**
	 * Obtiene el título de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionCreacion()) {
			setTituloSeccion(texto('Crear avalúo'));
			setIconoSeccion(constantes.icono.agregar);
			const rutaSalida = `${constantes.ruta.appAvaluosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Lista de avalúos') }
			]);
		}
		if (esSeccionEdicion()) {
			if (esSeccionAdministracion()) {
				setTituloSeccion(texto('Editar avalúo'));
				setIconoSeccion(constantes.icono.editar);
				const rutaSalida = `${constantes.ruta.appAdministracionAvaluosDetalles}/${id}`;
				const rutaSalidaLista = `${constantes.ruta.appAdministracionAvaluosLista}?pagina=${paginaAdmin}&registrosPorPagina=${registrosPorPaginaAdmin}&ordenamiento=${ordenamientoAdmin}&orden=${ordenAdmin}&criterio=${criterioAdmin}`;
				setRutaSalida(rutaSalida);
				setEnlaces([
					{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
					{
						ruta: rutaSalidaLista,
						titulo: texto('Lista de avalúos')
					},
					{ ruta: rutaSalida, titulo: texto('Detalles del avalúo') }
				]);
			} else {
				setTituloSeccion(texto('Editar mi avalúo'));
				setIconoSeccion(constantes.icono.editar);
				const rutaSalida = `${constantes.ruta.appAvaluosDetalles}/${id}`;
				const rutaSalidaLista = `${constantes.ruta.appAvaluosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
				setRutaSalida(rutaSalida);
				setEnlaces([
					{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
					{
						ruta: rutaSalidaLista,
						titulo: texto('Mis avalúos')
					},
					{ ruta: rutaSalida, titulo: texto('Detalles de mi avalúo') }
				]);
			}
		}
		if (esSeccionPreferencias()) {
			setTituloSeccion(texto('Definir valores predeterminados'));
			setIconoSeccion(constantes.icono.predeterminados);
			const rutaSalida = `${constantes.ruta.appUsuarioPreferencias}?pestana=avaluos`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Mis preferencias') }
			]);
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={iconoSeccion} titulo={tituloSeccion} />
			<Form
				initialValues={formulario}
				mutators={{
					cambiarValorCampo: mutatorCambiarValorCampo,
					copiarValorCampo: mutatorCopiarValorCampo,
					limpiarValorCampo: mutatorLimpiarValorCampo
				}}
				onSubmit={eventoGuardar}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Card>
							<CardBody>
								<AvaluoFormulario eventoDeshacer={eventoDeshacer} eventoGuardar={eventoGuardar} eventoSalir={eventoSalir} formularioOriginal={formularioOriginal} />
							</CardBody>
						</Card>
					</form>
				)}
				validate={eventoValidarFormulario}
			/>
		</Contenedor>
	);
};

export default Pagina;
