import Avaluo from 'modelo/Avaluo';
import disenos from './disenos';

const seccion = (avaluo: Avaluo) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.caracteristicas && avaluo.construccionesDominantes && avaluo.serviciosMunicipales) {
			const { clasificacionZona, densidadConstruccion } = avaluo.caracteristicas;
			const { descripcion: descripcionConstruccionesDominantes } = avaluo.construccionesDominantes;
			const { descripcion: descripcionServiciosMunicipales } = avaluo.serviciosMunicipales;
			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									style: 'seccionTitulo',
									text: 'CARACTERISTICAS URBANAS'
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									layout: { ...disenos.sinBordes, ...disenos.sinPaddingArribaAbajo },
									margin: [0, 5, 0, 0],
									style: 'seccion',
									table: {
										body: [
											[
												{
													style: 'seccionEtiqueta',
													text: 'Clasificación de la Zona:'
												},
												{
													text: clasificacionZona?.nombre
												},
												{
													style: 'seccionEtiqueta',
													text: 'Densidad de Construcción:'
												},
												{
													text: densidadConstruccion ? `${densidadConstruccion}%` : ''
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Tipos de Const. Dominante:'
												},
												{
													colSpan: 3,
													text: descripcionConstruccionesDominantes
												},
												{},
												{}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Servicios Municipales:'
												},
												{
													colSpan: 3,
													text: descripcionServiciosMunicipales
												},
												{},
												{}
											]
										],
										heights: [10, 20, 30],
										widths: [100, '*', 100, 20]
									}
								}
							]
						],
						widths: ['*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;
