import { Card, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap';
import { enviarUsuarioCorreoCambioContrasena, enviarUsuarioCorreoVerificacion, obtenerUsuarioPorId } from 'servicios/api/usuarios';
import { esSysAdmin, tieneAlMenosUnPermiso, tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEsSeccionAdministracion, useEsSeccionPerfil } from 'hooks/useSeccion';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import Usuario from 'modelo/Usuario';
import UsuarioTarjeta from 'componentes/usuarios/UsuarioTarjeta';

const NOMBRE_CLASE = 'paginas/app/usuarios/UsuarioDetalles';

/**
 * En esta página se muestra la información detallada un usuario.
 */
const Pagina = () => {
	const history = useHistory();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const esSeccionPerfil = useEsSeccionPerfil();
	const dispatch = useDispatch();
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken, user } = auth0;
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.usuarios);
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [rutaSalida, setRutaSalida] = useState<string>(
		`${constantes.ruta.appAdministracionUsuariosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`
	);
	const [enlaces, setEnlaces] = useState<any>([]);
	const { id } = useParams<any>();
	const [registro, setRegistro] = useState<Usuario>();
	const [modal, setModal] = useState<boolean>(false);
	const [modalTipo, setModalTipo] = useState<string>('');

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, []);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, [location.pathname]);

	/**
	 * Dibuja el modal de envio de correos.
	 */
	const dibujarModalCorreo = () => {
		let descripcion;
		let eventoEnviar;
		let titulo;
		switch (modalTipo) {
			case 'contrasena':
				titulo = texto('Cambiar Contraseña');
				descripcion = texto('Se enviará un correo con las instrucciones para cambiar tu contraseña.');
				eventoEnviar = eventoEnviarCorreoCambiarContrasena;
				break;
			case 'verificacion':
				titulo = texto('Verificar Correo Electrónico');
				descripcion = texto('Se enviará un correo con las instrucciones para verificar tu correo electrónico.');
				eventoEnviar = eventoReenviarCorreoVerificacion;
				break;
			default:
				break;
		}
		return (
			<Modal
				centered={true}
				isOpen={modal}
				toggle={() => {
					setModal(modal);
				}}
			>
				<div className="modal-header border-warning bg-warning">
					<h5 className="modal-title mt-0 text-white">{titulo}</h5>
				</div>
				<div className="modal-body border-warning">
					<p>{descripcion}</p>
				</div>
				<BarraHerramientas>
					<button
						className="btn btn-secondary"
						id="botonCancelar"
						onClick={() => {
							setModal(false);
						}}
						type="button"
					>
						<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
					</button>
					<button className="btn btn-warning" id="botonEnviar" onClick={eventoEnviar} type="button">
						<i className={constantes.icono.enviar}></i> {texto('Enviar')}
					</button>
				</BarraHerramientas>
			</Modal>
		);
	};

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Redirecciona al usuario a la ruta de edición de la foto de perfil.
	 */
	const eventoEditarFoto = () => {
		const nombreMetodo = 'eventoEditarFoto';
		let ruta;
		if (esSeccionAdministracion()) {
			ruta = `${constantes.ruta.appAdministracionUsuariosFotoEditar}/${id}`;
		} else {
			ruta = constantes.ruta.appUsuarioPerfilFoto;
		}
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	};

	/**
	 * Redirecciona al usuario a la ruta de edición.
	 */
	const eventoEditarDetalles = () => {
		const nombreMetodo = 'eventoEditarDetalles';
		let ruta;
		if (esSeccionAdministracion()) {
			ruta = `${constantes.ruta.appAdministracionUsuariosDetallesEditar}/${id}`;
		} else {
			ruta = constantes.ruta.appUsuarioPerfilEditar;
		}
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	};

	/**
	 * Envia el correo de cambio de contraseña al usuario.
	 */
	const eventoEnviarCorreoCambiarContrasena = async () => {
		const nombreMetodo = 'eventoEnviarCorreoCambiarContrasena';
		try {
			if (registro) {
				dispatch(setPantallaCargaMostrarAction(true));
				mcLogger.log({ mensaje: `Enviando correo de cambio de contraseña.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				await enviarUsuarioCorreoCambioContrasena({ auth0AccessToken, correoElectronico: registro.correoElectronico });
				mcLogger.log({ mensaje: `Correo de cambio de contraseña enviado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Correo de cambio de contraseña enviado con éxito.`) });
				dispatch(setPantallaCargaMostrarAction(false));
				setModal(false);
			}
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al enviar correo de cambio de contraseña:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Reenvia el correo de verificación al usuario.
	 */
	const eventoReenviarCorreoVerificacion = async () => {
		const nombreMetodo = 'eventoReenviarCorreoVerificacion';
		try {
			if (registro) {
				dispatch(setPantallaCargaMostrarAction(true));
				mcLogger.log({ mensaje: `Enviando correo de verificación.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				await enviarUsuarioCorreoVerificacion({ auth0AccessToken, auth0Id: registro.auth0Id });
				mcLogger.log({ mensaje: `Correo de verificación enviado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Correo de verificación enviado con éxito.`) });
				dispatch(setPantallaCargaMostrarAction(false));
				setModal(false);
			}
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al enviar correo de verificación:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Obteniendo información del usuario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionAdministracion()) {
				const respuesta = await obtenerUsuarioPorId({ auth0AccessToken, id });
				const { datos } = respuesta;
				setRegistro(datos);
			}
			if (esSeccionPerfil()) {
				const respuesta = await obtenerUsuarioPorId({ auth0AccessToken, id: usuario.id });
				const { datos } = respuesta;
				setRegistro(datos);
			}
			mcLogger.log({ mensaje: `Información del usuario obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener el usuario: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los valores de las variables que cambian en función de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionAdministracion()) {
			setTituloSeccion(texto('Detalles del usuario'));
			const rutaSalida = `${constantes.ruta.appAdministracionUsuariosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Lista de Usuarios') }
			]);
		}
		if (esSeccionPerfil()) {
			setTituloSeccion(texto('Mi perfil'));
			const rutaSalida = constantes.ruta.appInicio;
			setRutaSalida(rutaSalida);
			setEnlaces([{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') }]);
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={constantes.icono.usuario} titulo={tituloSeccion} />
			{registro && (
				<Card>
					<CardHeader>
						<BarraHerramientas>
							{esSeccionPerfil() && (
								<button
									className="btn btn-warning"
									id="botonCorreoCambiarContrasena"
									onClick={() => {
										setModal(true);
										setModalTipo('contrasena');
									}}
									type="button"
								>
									<i className={constantes.icono.contrasena}></i> {texto('Cambiar Contraseña')}
								</button>
							)}
							{esSeccionPerfil() && !user.email_verified && (
								<button
									className="btn btn-warning"
									id="botonCorreoVerificacion"
									onClick={() => {
										setModal(true);
										setModalTipo('verificacion');
									}}
									type="button"
								>
									<i className={constantes.icono.correoElectronico}></i> {texto('Reenviar correo de verificación')}
								</button>
							)}
							{dibujarModalCorreo()}
							{((esSeccionAdministracion() &&
								tieneAlMenosUnPermiso({ permisos: [constantes.permiso.appAdministracionUsuariosEditarDetalles, constantes.permiso.appAdministracionRolesAsignar], usuario }) &&
								!esSysAdmin(registro)) ||
								esSeccionPerfil()) && (
								<button className="btn btn-info" id="botonEditarDetalles" onClick={eventoEditarDetalles} type="button">
									<i className={constantes.icono.editarUsuario}></i> {texto('Editar detalles')}
								</button>
							)}
							{((esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionUsuariosEditarFoto, usuario }) && !esSysAdmin(registro)) ||
								esSeccionPerfil()) && (
								<button className="btn btn-info" id="botonEditarFoto" onClick={eventoEditarFoto} type="button">
									<i className={constantes.icono.foto}></i> {texto('Editar foto')}
								</button>
							)}
							<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
								<i className={constantes.icono.atras}></i> {texto('Salir')}
							</button>
						</BarraHerramientas>
					</CardHeader>
					<CardBody>
						<Row>
							<Col md="12">{registro && <UsuarioTarjeta registro={registro} usuario={usuario} />}</Col>
						</Row>
					</CardBody>
				</Card>
			)}
		</Contenedor>
	);
};

export default Pagina;
