import { convertirBase64ABlob, convertirBlobABase64, convertirBytesABase64 } from '@mcsoft/archivos';
import {
	CopyObjectCommand,
	CopyObjectRequest,
	DeleteObjectCommand,
	DeleteObjectRequest,
	GetObjectCommand,
	GetObjectRequest,
	PutObjectCommand,
	PutObjectRequest,
	S3Client
} from '@aws-sdk/client-s3';
import { McRespuesta, procesarRespuesta } from '@mcsoft/api';
import aplicacion from 'configuracion/aplicacion';
import mcLogger from '@mcsoft/logger';

const NOMBRE_CLASE = 'servicios/aws/s3';
const clienteS3 = new S3Client({
	credentials: {
		accessKeyId: aplicacion.aws.accessKeyId || '',
		secretAccessKey: aplicacion.aws.secretAccessKey || ''
	},
	region: aplicacion.aws.region
});

/**
 * Copia un archivo de un bucket S3.
 * - ***bucketDestinoNombre*** - Nombre del Bucket S3 de destino.
 * - ***bucketOrigenNombre*** - Nombre del Bucket S3 de origen.
 * - ***archivoDestinoNombre*** - Nombre del archivo de destino.
 * - ***archivoOrigenNombre*** - Nombre del archivo de origen.
 */
export const copiarArchivoS3 = async ({
	archivoDestinoNombre,
	archivoOrigenNombre,
	bucketDestinoNombre,
	bucketOrigenNombre
}: {
	archivoDestinoNombre?: string;
	archivoOrigenNombre?: string;
	bucketDestinoNombre?: string;
	bucketOrigenNombre?: string;
}): Promise<void> => {
	const nombreMetodo = 'copiarArchivoS3';
	if (archivoDestinoNombre) {
		if (archivoOrigenNombre) {
			if (bucketDestinoNombre) {
				if (bucketOrigenNombre) {
					const parametros: CopyObjectRequest = {
						Bucket: bucketDestinoNombre,
						CopySource: `/${bucketOrigenNombre}/${archivoOrigenNombre}`,
						Key: archivoDestinoNombre
					};
					mcLogger.api({
						mensaje: `Copiando archivo '${bucketOrigenNombre}/${archivoOrigenNombre}' -> '${bucketDestinoNombre}/${archivoDestinoNombre}'...`,
						nombreArchivo: NOMBRE_CLASE,
						nombreMetodo
					});
					const comando = new CopyObjectCommand(parametros);
					await clienteS3.send(comando);
					mcLogger.api({
						mensaje: `Archivo '${bucketOrigenNombre}/${archivoOrigenNombre}' -> '${bucketDestinoNombre}/${archivoDestinoNombre}' copiado con éxito.`,
						nombreArchivo: NOMBRE_CLASE,
						nombreMetodo
					});
				} else {
					const errorMensaje = `El nombre del bucket de origen no puede estar vacío`;
					throw new Error(errorMensaje);
				}
			} else {
				const errorMensaje = `El nombre del bucket de destino no puede estar vacío`;
				throw new Error(errorMensaje);
			}
		} else {
			const errorMensaje = `El nombre del archivo de origen no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	} else {
		const errorMensaje = `El nombre del archivo de destino no puede estar vacío`;
		throw new Error(errorMensaje);
	}
};

/**
 * Elimina un archivo del bucket S3 requerido.
 * - ***archivoNombre*** - Nombre del archivo.
 * - ***bucketNombre*** - Nombre del Bucket S3.
 */
export const eliminarArchivoS3 = async ({ archivoNombre, bucketNombre }: { archivoNombre?: string; bucketNombre?: string }): Promise<void> => {
	const nombreMetodo = 'eliminarArchivoS3';
	if (archivoNombre) {
		if (bucketNombre) {
			const parametros: DeleteObjectRequest = {
				Bucket: bucketNombre,
				Key: archivoNombre
			};
			mcLogger.api({ mensaje: `Eliminando archivo '${archivoNombre}' del bucket '${bucketNombre}'...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const comando = new DeleteObjectCommand(parametros);
			await clienteS3.send(comando);
			mcLogger.api({ mensaje: `Archivo '${archivoNombre}' eliminado con éxito del bucket '${bucketNombre}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		} else {
			const errorMensaje = `El nombre del bucket no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	} else {
		const errorMensaje = `El nombre del archivo no puede estar vacío`;
		throw new Error(errorMensaje);
	}
};

/**
 * Obtiene un archivo del bucket S3 requerido.
 * - ***archivoNombre*** - Nombre del archivo.
 * - ***bucketNombre*** - Nombre del Bucket S3.
 */
export const obtenerArchivoS3 = async ({ archivoNombre, bucketNombre }: { archivoNombre?: string; bucketNombre?: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerArchivoS3';
	if (archivoNombre) {
		if (bucketNombre) {
			const parametros: GetObjectRequest = {
				Bucket: bucketNombre,
				Key: archivoNombre
			};
			mcLogger.api({ mensaje: `Obteniendo archivo '${archivoNombre}' del bucket '${bucketNombre}'...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const comando = new GetObjectCommand(parametros);
			const response = await clienteS3.send(comando);
			mcLogger.api({ mensaje: `Archivo '${archivoNombre}' obtenido con éxito del bucket '${bucketNombre}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			return procesarRespuesta(response);
		} else {
			const errorMensaje = `El nombre del bucket no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	} else {
		const errorMensaje = `El nombre del archivo no puede estar vacío`;
		throw new Error(errorMensaje);
	}
};

/**
 * Obtiene un archivo del bucket S3 requerido como una cadeba base64.
 * - ***archivoNombre*** - Nombre del archivo.
 * - ***bucketNombre*** - Nombre del Bucket S3.
 */
export const obtenerArchivoS3Base64 = async ({ archivoNombre, bucketNombre }: { archivoNombre?: string; bucketNombre?: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerArchivoS3Base64';
	if (archivoNombre) {
		if (bucketNombre) {
			const parametros: GetObjectRequest = {
				Bucket: bucketNombre,
				Key: archivoNombre
			};
			mcLogger.api({ mensaje: `Obteniendo archivo '${archivoNombre}' del bucket '${bucketNombre}'...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const comando = new GetObjectCommand(parametros);
			const response = await clienteS3.send(comando);
			mcLogger.api({ mensaje: `Archivo '${archivoNombre}' obtenido con éxito del bucket '${bucketNombre}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const mcRespuesta = procesarRespuesta(response);
			const archivoTipo = response.ContentType;
			const resultado = await response.Body?.transformToByteArray();
			const archivoBase64 = convertirBytesABase64(resultado);
			mcRespuesta.datos = `data:${archivoTipo};base64,${archivoBase64}`;
			return procesarRespuesta(mcRespuesta);
		} else {
			const errorMensaje = `El nombre del bucket no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	} else {
		const errorMensaje = `El nombre del archivo no puede estar vacío`;
		throw new Error(errorMensaje);
	}
};

/**
 * Guarda un archivo en el bucket S3 requerido.
 * - ***archivo*** - Archivo tipo File.
 * - ***archivoNombre*** - Nombre del archivo.
 * - ***archivoTipo*** - Tipo MIME del archivo.
 * - ***bucketNombre*** - Nombre del Bucket S3.
 */
export const subirArchivoS3 = async ({
	archivo,
	archivoNombre,
	archivoTipo,
	bucketNombre
}: {
	archivo?: File;
	archivoNombre?: string;
	archivoTipo?: string;
	bucketNombre?: string;
}): Promise<void> => {
	const nombreMetodo = 'subirArchivoS3';
	if (archivo) {
		if (archivoNombre) {
			if (archivoTipo) {
				if (bucketNombre) {
					const parametros: PutObjectRequest = {
						Body: archivo,
						Bucket: bucketNombre,
						ContentEncoding: 'base64',
						ContentType: archivoTipo,
						Key: archivoNombre
					};
					mcLogger.api({ mensaje: `Guardando archivo '${archivoNombre}' en el bucket '${bucketNombre}'...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: parametros });
					const comando = new PutObjectCommand(parametros);
					await clienteS3.send(comando);
					mcLogger.api({ mensaje: `Archivo '${archivoNombre}' guardado con éxito en el bucket '${bucketNombre}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				} else {
					const errorMensaje = `El nombre del bucket no puede estar vacío`;
					throw new Error(errorMensaje);
				}
			} else {
				const errorMensaje = `El tipo del archivo no puede estar vacío`;
				throw new Error(errorMensaje);
			}
		} else {
			const errorMensaje = `El nombre del archivo no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	} else {
		const errorMensaje = `El archivo no puede estar vacío`;
		throw new Error(errorMensaje);
	}
};

/**
 * Guarda un archivo en el bucket S3 requerido.
 * - ***archivoBase64*** - Archivo codificado en Base64.
 * - ***archivoNombre*** - Nombre del archivo.
 * - ***archivoTipo*** - Tipo MIME del archivo.
 * - ***bucketNombre*** - Nombre del Bucket S3.
 */
export const subirArchivoS3Base64 = async ({
	archivoBase64,
	archivoNombre,
	archivoTipo,
	bucketNombre
}: {
	archivoBase64?: string;
	archivoNombre?: string;
	archivoTipo?: string;
	bucketNombre?: string;
}): Promise<void> => {
	const nombreMetodo = 'subirArchivoS3Base64';
	if (archivoBase64) {
		if (archivoNombre) {
			if (archivoTipo) {
				if (bucketNombre) {
					const archivoBlob = await convertirBase64ABlob(archivoBase64);
					const parametros: PutObjectRequest = {
						Body: archivoBlob,
						Bucket: bucketNombre,
						ContentEncoding: 'base64',
						ContentType: archivoTipo,
						Key: archivoNombre
					};
					mcLogger.api({ mensaje: `Guardando archivo '${archivoNombre}' en el bucket '${bucketNombre}'...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: parametros });
					const comando = new PutObjectCommand(parametros);
					await clienteS3.send(comando);
					mcLogger.api({ mensaje: `Archivo '${archivoNombre}' guardado con éxito en el bucket '${bucketNombre}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				} else {
					const errorMensaje = `El nombre del bucket no puede estar vacío`;
					throw new Error(errorMensaje);
				}
			} else {
				const errorMensaje = `El tipo del archivo no puede estar vacío`;
				throw new Error(errorMensaje);
			}
		} else {
			const errorMensaje = `El nombre del archivo no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	} else {
		const errorMensaje = `El archivo base 64 no puede estar vacío`;
		throw new Error(errorMensaje);
	}
};

/**
 * Guarda un archivo en el bucket S3 requerido y regresa la cadena base64.
 * - ***archivo*** - Archivo tipo File.
 * - ***archivoNombre*** - Nombre del archivo.
 * - ***archivoTipo*** - Tipo MIME del archivo.
 * - ***bucketNombre*** - Nombre del Bucket S3.
 */
export const subirArchivoS3YObtenerBase64 = async ({
	archivo,
	archivoNombre,
	archivoTipo,
	bucketNombre
}: {
	archivo?: File;
	archivoNombre?: string;
	archivoTipo?: string;
	bucketNombre?: string;
}): Promise<string> => {
	const nombreMetodo = 'subirArchivoS3YObtenerBase64';
	if (archivo) {
		if (archivoNombre) {
			if (archivoTipo) {
				if (bucketNombre) {
					mcLogger.dev({ mensaje: `Convirtiendo archivo '${archivoNombre}' a base 64...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
					const imagenBase64 = await convertirBlobABase64(archivo);
					await subirArchivoS3Base64({ archivoBase64: imagenBase64, archivoNombre, archivoTipo, bucketNombre });
					return imagenBase64;
				} else {
					const errorMensaje = `El nombre del bucket no puede estar vacío`;
					throw new Error(errorMensaje);
				}
			} else {
				const errorMensaje = `El tipo del archivo no puede estar vacío`;
				throw new Error(errorMensaje);
			}
		} else {
			const errorMensaje = `El nombre del archivo no puede estar vacío`;
			throw new Error(errorMensaje);
		}
	} else {
		const errorMensaje = `El archivo no puede estar vacío`;
		throw new Error(errorMensaje);
	}
};
