import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { McCampoSelectorMultiple, McCampoSelectorOpcion, McCampoTexto, obtenerValorMcCampoSelector } from '@mcsoft/formulario';
import aplicacion from 'configuracion/aplicacion';
import constantes from 'configuracion/constantes';
import { formatearListaEstadosMcCampoSelectorMultiple } from 'util/modelo/estado';
import { formatearListaMunicipiosMcCampoSelectorMultiple } from 'util/modelo/municipio';
import { formatearListaPaisesMcCampoSelectorMultiple } from 'util/modelo/pais';
import { obtenerEstadosTodosPorPais } from 'servicios/api/estados';
import { obtenerMunicipiosTodosPorEstado } from 'servicios/api/municipios';
import { obtenerPaisesTodos } from 'servicios/api/paises';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

interface DomicilioFormularioProps {
	/**
	 * Indica si los detalles serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	detallesEditables?: boolean;
	/**
	 * Indica el nombre del atributo en el que se guardara la información del formulario.
	 */
	nombreFormulario: string;
}

/**
 * Formulario para un domicilio.
 */
const DomicilioFormulario = (props: DomicilioFormularioProps) => {
	const formulario = useForm();
	const { auth0AccessToken } = useSelector((state: StateType) => state.sesion.auth0);
	const { detallesEditables = false, nombreFormulario } = props;
	const [listaEstados, setListaEstados] = useState<Array<McCampoSelectorOpcion>>([]);
	const [listaMunicipios, setListaMunicipios] = useState<Array<McCampoSelectorOpcion>>([]);
	const [listaPaises, setListaPaises] = useState<Array<McCampoSelectorOpcion>>([]);

	useEffect(() => {
		obtenerListaEstados(aplicacion.api.id.paisPredeterminado);
		obtenerListaMunicipios(aplicacion.api.id.estadoPredeterminado);
		obtenerListaPaises();
	}, []);

	/**
	 * Evento que se ejecuta cuando cambia el estado seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioEstado = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const { cambiarValorCampo, limpiarValorCampo } = formulario.mutators;
		const campoValor = obtenerValorMcCampoSelector(evento);
		if (campoValor) {
			obtenerListaMunicipios(campoValor);
			cambiarValorCampo(`${nombreFormulario}.estadoId`, campoValor);
			limpiarValorCampo(`${nombreFormulario}.municipioId`);
			limpiarValorCampo(`${nombreFormulario}.municipioSeleccion`);
		}
	};

	/**
	 * Evento que se ejecuta cuando cambia el municipio seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioMunicipio = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const { cambiarValorCampo } = formulario.mutators;
		const campoValor = obtenerValorMcCampoSelector(evento);
		if (campoValor) {
			cambiarValorCampo(`domicilio.municipioId`, campoValor);
		}
	};

	/**
	 * Evento que se ejecuta cuando cambia el país seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioPais = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const { cambiarValorCampo, limpiarValorCampo } = formulario.mutators;
		const campoValor = obtenerValorMcCampoSelector(evento);
		if (campoValor) {
			obtenerListaEstados(campoValor);
			cambiarValorCampo(`${nombreFormulario}.paisId`, campoValor);
			limpiarValorCampo(`${nombreFormulario}.estadoId`);
			limpiarValorCampo(`${nombreFormulario}.estadoSeleccion`);
			limpiarValorCampo(`${nombreFormulario}.municipioId`);
			limpiarValorCampo(`${nombreFormulario}.municipioSeleccion`);
		}
	};

	/**
	 * Obtiene la lista de estados relacionados a un país.
	 * - ***paisId*** - Id del país seleccionado.
	 */
	const obtenerListaEstados = async (paisId: string) => {
		if (paisId) {
			const respuesta = await obtenerEstadosTodosPorPais({ auth0AccessToken, paisId });
			setListaEstados(formatearListaEstadosMcCampoSelectorMultiple(respuesta.datos));
		}
	};

	/**
	 * Obtiene la lista de municipios relacionados a un estado.
	 * - ***estadoId*** - Id del estado seleccionado.
	 */
	const obtenerListaMunicipios = async (estadoId: string) => {
		if (estadoId) {
			const respuesta = await obtenerMunicipiosTodosPorEstado({ auth0AccessToken, estadoId });
			setListaMunicipios(formatearListaMunicipiosMcCampoSelectorMultiple(respuesta.datos));
		}
	};

	/**
	 * Obtiene la lista de todos los paises.
	 */
	const obtenerListaPaises = async () => {
		const respuesta = await obtenerPaisesTodos(auth0AccessToken);
		setListaPaises(formatearListaPaisesMcCampoSelectorMultiple(respuesta.datos));
	};

	return (
		<Fragment>
			<Row>
				<Col lg="6">
					<McCampoTexto campo={`${nombreFormulario}.calle`} etiqueta={texto('Calle')} id="domicilioPrincipal.calle" longitudMaxima={100} soloLectura={!detallesEditables} />
				</Col>
				<Col lg="3" md="6">
					<McCampoTexto
						campo={`${nombreFormulario}.numeroExterior`}
						etiqueta={texto('No. Ext.')}
						iconoIzquierda={constantes.icono.numero}
						id="numeroExterior"
						longitudMaxima={10}
						soloLectura={!detallesEditables}
					/>
				</Col>
				<Col lg="3" md="6">
					<McCampoTexto
						campo={`${nombreFormulario}.numeroInterior`}
						etiqueta={texto('No. Int.')}
						iconoIzquierda={constantes.icono.numero}
						id="numeroInterior"
						longitudMaxima={10}
						soloLectura={!detallesEditables}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="6">
					<McCampoTexto campo={`${nombreFormulario}.colonia`} etiqueta={texto('Colonia')} id="colonia" longitudMaxima={100} soloLectura={!detallesEditables} />
				</Col>
				<Col lg="3" md="6">
					<McCampoTexto
						campo={`${nombreFormulario}.codigoPostal`}
						etiqueta={texto('Código Postal')}
						iconoIzquierda={constantes.icono.correo}
						id="codigoPostal"
						longitudMaxima={5}
						soloLectura={!detallesEditables}
						tipo="telefono"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoSelectorMultiple
						campo={`${nombreFormulario}.paisSeleccion`}
						etiqueta={texto('País')}
						eventoCambio={eventoCambioPais}
						id="paisSeleccion"
						opciones={listaPaises}
						soloLectura={!detallesEditables}
					/>
				</Col>
				<Col lg="4">
					<McCampoSelectorMultiple
						campo={`${nombreFormulario}.estadoSeleccion`}
						etiqueta={texto('Estado')}
						eventoCambio={eventoCambioEstado}
						id="estadoSeleccion"
						opciones={listaEstados}
						soloLectura={!detallesEditables}
					/>
				</Col>
				<Col lg="4">
					<McCampoSelectorMultiple
						campo={`${nombreFormulario}.municipioSeleccion`}
						etiqueta={texto('Municipio')}
						eventoCambio={eventoCambioMunicipio}
						id="municipioSeleccion"
						opciones={listaMunicipios}
						soloLectura={!detallesEditables}
					/>
				</Col>
			</Row>
		</Fragment>
	);
};

export default DomicilioFormulario;
