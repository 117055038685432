import { McRespuesta, procesarError, procesarRespuesta } from '@mcsoft/api';
import aplicacion from 'configuracion/aplicacion';
import axios from 'axios';
import mcLogger from '@mcsoft/logger';
import Pais from 'modelo/Pais';

const NOMBRE_CLASE = 'servicios/api/paises';

/**
 * Actualiza un pais existente en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del pais a actualizar.
 * - ***valores*** - Objeto con los valores a actualizar.
 */
export const actualizarPais = ({ auth0AccessToken, id, valores }: { auth0AccessToken: string; id: string; valores: Pais }): Promise<McRespuesta> => {
	const nombreMetodo = 'actualizarPais';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Actualizando el registro con el id '${id}' en la base de datos con los siguientes valores:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: valores
		});
		axios
			.put(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.paises}/${id}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al actualizar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Crea un pais en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***valores*** - Objeto con los valores del pais a crear.
 */
export const crearPais = ({ auth0AccessToken, valores }: { auth0AccessToken: string; valores: Pais }): Promise<McRespuesta> => {
	const nombreMetodo = 'crearPais';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Creando registro en la base de datos con los siguientes valores:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: valores });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.paises}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear el registro:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Elimina un pais de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del pais a eliminar.
 */
export const eliminarPais = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'eliminarPais';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Eliminando el registro con el id '${id}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.delete(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.paises}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' eliminado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al eliminar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene un pais específico de la base de datos a través de su id.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del pais a obtener.
 */
export const obtenerPaisPorId = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerPaisPorId';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo registro con el id '${id}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.paises}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' obtenido con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Filtra y ordena los paises y las separa en páginas según los parámetros recibidos y obtiene la lista de paises de la página especificada.
 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerPaisesPaginados = ({
	auth0AccessToken,
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	auth0AccessToken: string;
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerPaisesPaginados';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo registros de la base de datos con los siguientes parámetros:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: {
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			}
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.paises}/paginados
			?pagina=${pagina}
			&registrosPorPagina=${registrosPorPagina}
			&ordenamiento=${ordenamiento}
			&orden=${orden}
			&criterio=${criterio}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene la lista de todos los paises de un estado de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerPaisesTodos = (auth0AccessToken: string): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerPaisesTodos';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo todos los registros.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.paises}/todos
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros encontrados:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener todos los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};
