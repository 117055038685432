import { actualizarUsuarioNotificacionDetalle, obtenerUsuarioNotificacionesCantidadNuevasPorUsuario } from 'servicios/api/usuariosNotificaciones';
import { actualizarUsuarioPreferencias, crearUsuario, obtenerUsuarioPorAuth0Id } from 'servicios/api/usuarios';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { estatusHttp, McRespuesta, procesarError } from '@mcsoft/api';
import {
	GET_SESION,
	GET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD,
	SAVE_SESION_PREFERENCIAS,
	SAVE_SESION_USUARIO_NOTIFICACIONES_CANTIDAD,
	SET_SESION_IDIOMA,
	SET_SESION_PREFERENCIAS,
	SET_SESION_PREFERENCIAS_ARIBUTOS,
	SET_SESION_USUARIO,
	SET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD
} from './actionTypes';
import constantes from 'configuracion/constantes';
import { guardarLocalStorage } from 'util/mc-utils/mc-storage';
import i18n from 'idiomas';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { ReduxAccion } from 'store/actions';
import Sesion from 'modelo/Sesion';
import Usuario from 'modelo/Usuario';

const NOMBRE_CLASE = 'store/sesion/saga';

function cambiarAtributoAlBody({ atributo, valor }: { atributo: string; valor: string }) {
	if (document.body) document.body.setAttribute(atributo, valor);
	return true;
}

function modificarClaseBody({ accion = 'toggle', clase }: { accion: string; clase: string }) {
	switch (accion) {
		case 'add':
			if (document.body) document.body.classList.add(clase);
			break;
		case 'remove':
			if (document.body) document.body.classList.remove(clase);
			break;
		default:
			if (document.body) document.body.classList.toggle(clase);
			break;
	}
	return true;
}

function* getSesionSaga(action: ReduxAccion) {
	const nombreMetodo = 'getSesionSaga';
	const { auth0 } = action.payload as Sesion;
	const { auth0AccessToken, user } = auth0;
	const { email, email_verified, sub } = user;
	let respuesta: McRespuesta;
	try {
		mcLogger.dev({ mensaje: `Verificando si existe el usuario con auth0Id "${sub}" en la base de datos`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		respuesta = yield call(obtenerUsuarioPorAuth0Id, { auth0AccessToken, auth0Id: sub });
		const usuario: Usuario = respuesta.datos;
		mcLogger.dev({ mensaje: `El usuario con auth0Id "${sub}" ya existe en la base de datos`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		mcLogger.configurarLoggerSentry(
			'https://ed72dc6b1926483eace7f3cb23b11f95@o714238.ingest.sentry.io/6125376',
			'avaluos',
			'0.0.0',
			process.env.REACT_APP_ENV || 'development',
			usuario.id,
			usuario.nombreUsuario,
			usuario.correoElectronico
		);
		usuario.correoElectronicoVerificado = email_verified;
		const accionSetSesionUsuario: ReduxAccion = {
			payload: {
				usuario
			},
			type: SET_SESION_USUARIO
		};
		const accionGetSesionUsuarioNotificacionesCantidad: ReduxAccion = {
			payload: {
				auth0AccessToken,
				usuario
			},
			type: GET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD
		};
		yield put(accionSetSesionUsuario);
		yield put(accionGetSesionUsuarioNotificacionesCantidad);
	} catch (error) {
		const mcError = procesarError(error);
		if (mcError.codigo === estatusHttp.estatus404.codigo) {
			mcLogger.dev({ mensaje: `No existe un usuario con auth0Id "${sub}" en la base de datos`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			try {
				const nuevoUsuario: Usuario = {
					auth0Id: sub,
					correoElectronico: email,
					id: '',
					nombreUsuario: '',
					preferencias: {
						avaluosClonarAntecedentes: true,
						avaluosClonarCaracteristicas: true,
						avaluosClonarDetallesConstrucciones: false,
						avaluosClonarDetallesInstalacionesEspeciales: false,
						avaluosClonarDetallesTerrenos: false,
						avaluosClonarDistribucion: true,
						avaluosClonarElementosConstruccion: true,
						avaluosClonarFotos: false,
						avaluosClonarGeneral: true,
						avaluosClonarObservaciones: true,
						avaluosClonarUbicacion: false,
						avaluosCodigoValuador: '',
						avaluosPdfEtiquetaConstruccionCodigo: 'codigo',
						avaluosPdfMostrarCoeficientesUtilizacionOcupacion: true,
						avaluosPdfMostrarCoordenadasDD: false,
						avaluosPdfMostrarCoordenadasDMS: false,
						avaluosPdfMostrarCoordenadasUTM: true,
						avaluosPdfMostrarLicenciaFoto: true,
						avaluosPdfMostrarNumeroPagina: true,
						avaluosPdfRellenarEspaciosSinFoto: true,
						avaluosPdfTipoEncabezado: 'normal',
						avaluosValoresPredeterminados: '',
						id: '',
						idioma: '',
						interfazAncho: '',
						interfazBarraNavegacionLateralAncho: '',
						interfazBarraNavegacionLateralTema: '',
						interfazBarraNavegacionSuperiorTema: '',
						interfazTema: '',
						usuarioId: ''
					}
				};
				mcLogger.dev({ mensaje: `Creando usuario con auth0Id "${sub}" en la base de datos`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				yield call(crearUsuario, { auth0AccessToken, valores: nuevoUsuario });
				respuesta = yield call(obtenerUsuarioPorAuth0Id, { auth0AccessToken, auth0Id: nuevoUsuario.auth0Id });
				const usuario: Usuario = respuesta.datos;
				usuario.correoElectronicoVerificado = email_verified;
				const accion: ReduxAccion = {
					payload: {
						usuario
					},
					type: SET_SESION_USUARIO
				};
				yield put(accion);
			} catch (error) {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear usuario con auth0Id "${sub}" en la base de datos:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.datos });
				const rutaDestino = constantes.ruta.webInicio;
				mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaDestino });
				window.location.replace(rutaDestino);
			}
		} else {
			mcLogger.error({
				mensaje: `Error al obtener la información del usuario de sesión de la base de datos:`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: mcError.datos
			});
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			const rutaDestino = constantes.ruta.webInicio;
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaDestino });
			window.location.replace(rutaDestino);
		}
	}
}

function* getSesionUsuarioNotificacionesCantidadSaga(action: ReduxAccion) {
	const nombreMetodo = 'getSesionUsuarioNotificacionesCantidadSaga';
	const { auth0AccessToken, usuario } = action.payload;
	const { id } = usuario;
	let respuesta: McRespuesta;
	try {
		mcLogger.dev({ mensaje: `Obteniendo la cantidad de notificaciones del usuario con id "${id}" en la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		respuesta = yield call(obtenerUsuarioNotificacionesCantidadNuevasPorUsuario, { auth0AccessToken, usuarioId: id });
		const cantidadNotificaciones = respuesta.datos;
		mcLogger.dev({ mensaje: `El usuario con id "${id}" tiene "${cantidadNotificaciones}" notificaciones nuevas.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const accion: ReduxAccion = {
			payload: {
				cantidadNotificaciones
			},
			type: SET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD
		};
		yield put(accion);
	} catch (error) {
		const mcError = procesarError(error);
		mcLogger.error({
			mensaje: `Error al obtener la cantidad de notificaciones del usuario con id "${id}" de la base de datos:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: mcError.datos
		});
		mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		const rutaDestino = constantes.ruta.webInicio;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaDestino });
		window.location.replace(rutaDestino);
	}
}

function* saveSesionUsuarioNotificacionesCantidadSaga(action: ReduxAccion) {
	const nombreMetodo = 'saveSesionUsuarioNotificacionesCantidadSaga';
	const { auth0AccessToken, notificacion, usuario } = action.payload;
	const { id } = notificacion;
	try {
		mcLogger.dev({ mensaje: `Actualizando la notificación con id "${id}" en la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		yield call(actualizarUsuarioNotificacionDetalle, { auth0AccessToken, id, valores: notificacion });
		mcLogger.dev({ mensaje: `Notificación con id "${id}" actualizada en la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const accion: ReduxAccion = {
			payload: {
				auth0AccessToken,
				usuario
			},
			type: GET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD
		};
		yield put(accion);
	} catch (error) {
		const mcError = procesarError(error);
		mcLogger.error({ mensaje: `Error al obtener la notificación id "${id}" de la base de datos:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.datos });
		mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		const rutaDestino = constantes.ruta.webInicio;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaDestino });
		window.location.replace(rutaDestino);
	}
}

function* saveSesionPreferenciasSaga(action: ReduxAccion) {
	const nombreMetodo = 'saveSesionPreferenciasSaga';
	const { atributo, auth0AccessToken, preferencias } = action.payload;
	try {
		mcLogger.dev({ mensaje: `Actualizando las preferencias del usuario "${preferencias.usuarioId}"`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		yield call(actualizarUsuarioPreferencias, { auth0AccessToken, id: preferencias.usuarioId, valores: preferencias });
		const accion: ReduxAccion = {
			payload: {
				atributo,
				preferencias
			},
			type: SET_SESION_PREFERENCIAS_ARIBUTOS
		};
		yield put(accion);
	} catch (error) {
		const mcError = procesarError(error);
		mcLogger.error({ mensaje: `Error al actualizar las preferencias:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.datos });
		mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
	}
}

function* setSesionIdiomaSaga(action: ReduxAccion) {
	const nombreMetodo = 'setSesionIdiomaSaga';
	const { idioma, sesion } = action.payload;
	const { auth0, usuario } = sesion;
	const { auth0AccessToken } = auth0;
	const { preferencias } = usuario;
	preferencias.idioma = idioma;
	try {
		mcLogger.dev({ mensaje: `Actualizando el idioma a "${idioma}" en las preferencias del usuario "${usuario.id}"`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		yield call(actualizarUsuarioPreferencias, { auth0AccessToken, id: preferencias.usuarioId, valores: preferencias });
		const accion = {
			action: GET_SESION,
			payload: { auth0 }
		};
		yield call(<any>getSesionSaga, accion);
		mcLogger.dev({ mensaje: `Cambiando el idioma en la libreria i18n: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: idioma });
		i18n.changeLanguage(idioma);
		guardarLocalStorage({ campo: 'idiomaSeleccionado', nombreClase: NOMBRE_CLASE, nombreMetodo, valor: idioma });
	} catch (error) {
		const mcError = procesarError(error);
		mcLogger.error({ mensaje: `Error al actualizar las preferencias:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.datos });
		mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
	}
}

function* setSesionPreferenciasAtributosSaga(action: ReduxAccion) {
	const nombreMetodo = 'setSesionPreferenciasAtributosSaga';
	const { atributo, preferencias } = action.payload;
	const { interfazAncho, interfazBarraNavegacionLateralAncho, interfazBarraNavegacionLateralTema, interfazBarraNavegacionSuperiorTema, interfazTema } = preferencias;
	try {
		mcLogger.dev({ mensaje: `Seteando las preferencias en el DOM`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const esMovil = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
		if (atributo === 'interfazAncho') {
			switch (interfazAncho) {
				case 'boxed':
					preferencias.interfazBarraNavegacionLateralAncho = 'compact';
					yield call(cambiarAtributoAlBody, { atributo: 'data-layout-size', valor: interfazAncho });
					yield call(cambiarAtributoAlBody, { atributo: 'data-layout-scrollable', valor: 'false' });
					break;
				case 'scrollable':
					preferencias.interfazBarraNavegacionLateralAncho = 'default';
					yield call(cambiarAtributoAlBody, { atributo: 'data-layout-scrollable', valor: 'true' });
					break;
				default:
					preferencias.interfazBarraNavegacionLateralAncho = 'default';
					yield call(cambiarAtributoAlBody, { atributo: 'data-layout-size', valor: interfazAncho });
					yield call(cambiarAtributoAlBody, { atributo: 'data-layout-scrollable', valor: 'false' });
					break;
			}
		}
		if (atributo === 'interfazBarraNavegacionLateralAncho') {
			switch (interfazBarraNavegacionLateralAncho) {
				case 'compact':
					yield call(cambiarAtributoAlBody, { atributo: 'data-sidebar-size', valor: 'small' });
					yield call(modificarClaseBody, { accion: 'remove', clase: 'sidebar-enable' });
					break;
				case 'icon':
					yield call(cambiarAtributoAlBody, { atributo: 'data-keep-enlarged', valor: 'true' });
					break;
				case 'condensed':
					yield call(modificarClaseBody, { accion: 'add', clase: 'sidebar-enable' });
					if (window.screen.width >= 992) {
						yield call(modificarClaseBody, { accion: 'remove', clase: 'sidebar-enable' });
						yield call(modificarClaseBody, { accion: 'add', clase: 'sidebar-enable' });
					} else {
						yield call(modificarClaseBody, { accion: 'add', clase: 'sidebar-enable' });
					}
					break;
				default:
					yield call(cambiarAtributoAlBody, { atributo: 'data-sidebar-size', valor: '' });
					yield call(modificarClaseBody, { accion: 'remove', clase: 'sidebar-enable' });
					if (!esMovil) break;
			}
		}
		if (atributo === 'interfazBarraNavegacionLateralTema') {
			yield call(cambiarAtributoAlBody, { atributo: 'data-sidebar', valor: interfazBarraNavegacionLateralTema });
		}
		if (atributo === 'interfazBarraNavegacionSuperiorTema') {
			yield call(cambiarAtributoAlBody, { atributo: 'data-topbar', valor: interfazBarraNavegacionSuperiorTema });
		}
		if (atributo === 'interfazTema') {
			if (interfazTema === 'horizontal') {
				preferencias.interfazBarraNavegacionSuperiorTema = 'dark';
				document.body.removeAttribute('data-sidebar');
				document.body.removeAttribute('data-sidebar-size');
			} else {
				preferencias.interfazBarraNavegacionSuperiorTema = 'light';
			}
			yield call(cambiarAtributoAlBody, { atributo: 'data-layout', valor: interfazTema });
		}
		const accion: ReduxAccion = {
			payload: {
				preferencias
			},
			type: SET_SESION_PREFERENCIAS
		};
		yield put(accion);
	} catch (error) {
		const mcError = procesarError(error);
		mcLogger.error({ mensaje: `Error al actualizar las preferencias:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.datos });
		mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
	}
}

function* getSesionUsuarioNotificacionesCantidadWatcher() {
	yield takeEvery(GET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD, getSesionUsuarioNotificacionesCantidadSaga);
}

function* getSesionWatcher() {
	yield takeEvery(GET_SESION, getSesionSaga);
}

function* saveSesionPreferenciasWatcher() {
	yield takeEvery(SAVE_SESION_PREFERENCIAS, saveSesionPreferenciasSaga);
}

function* saveSesionUsuarioNotificacionesCantidadWatcher() {
	yield takeEvery(SAVE_SESION_USUARIO_NOTIFICACIONES_CANTIDAD, saveSesionUsuarioNotificacionesCantidadSaga);
}

function* setSesionIdiomaWatcher() {
	yield takeEvery(SET_SESION_IDIOMA, setSesionIdiomaSaga);
}

function* setSesionPreferenciasAtributosWatcher() {
	yield takeEvery(SET_SESION_PREFERENCIAS_ARIBUTOS, setSesionPreferenciasAtributosSaga);
}

function* Saga() {
	yield all([
		fork(getSesionUsuarioNotificacionesCantidadWatcher),
		fork(getSesionWatcher),
		fork(saveSesionPreferenciasWatcher),
		fork(saveSesionUsuarioNotificacionesCantidadWatcher),
		fork(setSesionPreferenciasAtributosWatcher),
		fork(setSesionIdiomaWatcher)
	]);
}

export default Saga;
