import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del tratamiento protocolario.
 */
const InformacionTratamientoProtocolario = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Tratamiento o título que se antepondra al nombre del perito en el encabezado.')}</p>
			<p>{texto('Ejemplo')}:</p>
			<p>
				{texto('Ing.')}
				<br />
				{texto('Lic.')}
				<br />
				{texto('Arq.')}
			</p>
		</div>
	</Fragment>
);

export default InformacionTratamientoProtocolario;
