import { SET_SESION_AUTH0, SET_SESION_PREFERENCIAS, SET_SESION_USUARIO, SET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD } from './actionTypes';
import { guardarLocalStorage } from 'util/mc-utils/mc-storage';
import mcLogger from '@mcsoft/logger';
import { ReduxAccion } from 'store/actions';
import Sesion from 'modelo/Sesion';

const NOMBRE_CLASE = 'store/sesion/reducer';

const stateInicial: Sesion = {
	auth0: {
		auth0AccessToken: '',
		auth0IdToken: '',
		buildAuthorizeUrl: () => {},
		buildLogoutUrl: () => {},
		error: undefined,
		getAccessTokenSilently: () => {},
		getAccessTokenWithPopup: () => {},
		getIdTokenClaims: () => {},
		handleRedirectCallback: () => {},
		isAuthenticated: false,
		isLoading: false,
		loginWithPopup: () => {},
		loginWithRedirect: () => {},
		logout: () => {},
		user: {
			email: '',
			email_verified: false,
			name: '',
			nickname: '',
			picture: '',
			sub: '',
			updated_at: ''
		}
	},
	inicializada: false,
	notificacionesNuevas: 0,
	usuario: {
		apellidoMaterno: '',
		apellidoPaterno: '',
		auth0Id: '',
		correoElectronico: '',
		fechaCreacion: new Date(),
		fechaModificacion: new Date(),
		fotoPerfil: '',
		id: '',
		nombre: '',
		nombreUsuario: '',
		permisos: [],
		preferencias: {
			avaluosClonarAntecedentes: true,
			avaluosClonarCaracteristicas: true,
			avaluosClonarDetallesConstrucciones: false,
			avaluosClonarDetallesInstalacionesEspeciales: false,
			avaluosClonarDetallesTerrenos: false,
			avaluosClonarDistribucion: true,
			avaluosClonarElementosConstruccion: true,
			avaluosClonarFotos: false,
			avaluosClonarGeneral: true,
			avaluosClonarObservaciones: true,
			avaluosClonarUbicacion: false,
			avaluosCodigoValuador: '',
			avaluosPdfEtiquetaConstruccionCodigo: 'codigo',
			avaluosPdfMostrarCoeficientesUtilizacionOcupacion: true,
			avaluosPdfMostrarCoordenadasDD: false,
			avaluosPdfMostrarCoordenadasDMS: false,
			avaluosPdfMostrarCoordenadasUTM: true,
			avaluosPdfMostrarLicenciaFoto: true,
			avaluosPdfMostrarNumeroPagina: false,
			avaluosPdfRellenarEspaciosSinFoto: true,
			avaluosPdfTipoEncabezado: 'normal',
			avaluosValoresPredeterminados: '',
			fechaCreacion: new Date(),
			fechaModificacion: new Date(),
			id: '',
			idioma: 'es',
			interfazAncho: 'fluid',
			interfazBarraNavegacionLateralAncho: 'default',
			interfazBarraNavegacionLateralTema: 'dark',
			interfazBarraNavegacionSuperiorTema: 'light',
			interfazTema: 'vertical',
			usuarioId: ''
		},
		rol: {
			descripcion: '',
			fechaCreacion: new Date(),
			fechaModificacion: new Date(),
			id: '',
			nombre: '',
			permisos: []
		},
		rolId: ''
	}
};

export default (state = stateInicial, action: ReduxAccion) => {
	switch (action.type) {
		case SET_SESION_AUTH0:
			const { auth0 } = action.payload;
			state = {
				...state,
				auth0: auth0
			};
			mcLogger.redux({
				mensaje: 'Seteando state "auth0":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_SESION_AUTH0,
				objetoExtra: auth0
			});
			break;
		case SET_SESION_PREFERENCIAS:
			const { preferencias } = action.payload;
			const usuario_SET_SESION_PREFERENCIAS = state.usuario;
			usuario_SET_SESION_PREFERENCIAS.preferencias = preferencias;
			state = {
				...state,
				usuario: usuario_SET_SESION_PREFERENCIAS
			};
			mcLogger.redux({
				mensaje: 'Seteando state "usuario.preferencias":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_SESION_USUARIO,
				objetoExtra: preferencias
			});
			break;
		case SET_SESION_USUARIO:
			const usuario_SET_SESION_USUARIO = action.payload.usuario;
			if (usuario_SET_SESION_USUARIO && usuario_SET_SESION_USUARIO.preferencias && usuario_SET_SESION_USUARIO.preferencias.idioma !== localStorage.getItem('idiomaSeleccionado')) {
				guardarLocalStorage({ campo: 'idiomaSeleccionado', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_SESION_USUARIO, valor: usuario_SET_SESION_USUARIO.preferencias.idioma });
				window.location.reload();
			}
			state = {
				...state,
				inicializada: true,
				usuario: usuario_SET_SESION_USUARIO
			};
			mcLogger.redux({
				mensaje: 'Seteando state "inicializada":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_SESION_USUARIO,
				objetoExtra: true
			});
			mcLogger.redux({
				mensaje: 'Seteando state "usuario":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_SESION_USUARIO,
				objetoExtra: usuario_SET_SESION_USUARIO
			});
			break;
		case SET_SESION_USUARIO_NOTIFICACIONES_CANTIDAD:
			const { cantidadNotificaciones } = action.payload;
			state = {
				...state,
				notificacionesNuevas: cantidadNotificaciones
			};
			mcLogger.redux({
				mensaje: 'Seteando state "notificacionesNuevas":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_SESION_USUARIO,
				objetoExtra: cantidadNotificaciones
			});
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
