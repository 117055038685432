import { Col, Row } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import { setVisorImagenImagenesAction, setVisorImagenMostrarAction } from 'store/actions';
import { useObtenerAvaluoCroquis, useObtenerAvaluoMapa, useObtenerAvaluoMapaPersonalizado } from 'hooks/useImagen';
import aplicacion from 'configuracion/aplicacion';
import Avaluo from 'modelo/Avaluo';
import { convertirNumeroEnteroANumeroRomano } from '@mcsoft/numeros';
import { McVisorImagenElemento } from '@mcsoft/visor-imagen';
import { texto } from 'idiomas';
import { useDispatch } from 'react-redux';

const CROQUIS_IMAGEN_INICIAL = aplicacion.imagenes.predeterminadaAvaluoCroquis;
const MAPA_IMAGEN_INICIAL = aplicacion.imagenes.predeterminadaAvaluoMapa;
const MAPA_PERSONALIZADO_IMAGEN_INICIAL = aplicacion.imagenes.predeterminadaAvaluoMapaPersonalizado;

interface AvaluoDetallesUbicacionProps {
	/**
	 * Objeto con la información del avalúo.
	 */
	avaluo: Avaluo;
}

/**
 * Detalles para la sección ubicación del predio del avalúo.
 */
const AvaluoDetallesUbicacion = (props: AvaluoDetallesUbicacionProps) => {
	const dispatch = useDispatch();
	const obtenerAvaluoCroquis = useObtenerAvaluoCroquis();
	const obtenerAvaluoMapa = useObtenerAvaluoMapa();
	const obtenerAvaluoMapaPersonalizado = useObtenerAvaluoMapaPersonalizado();
	const [croquisImagenUrl, setCroquisImagenUrl] = useState<string>(CROQUIS_IMAGEN_INICIAL);
	const [mapaImagenUrl, setMapaImagenUrl] = useState<string>(MAPA_IMAGEN_INICIAL);
	const [mapaPersonalizadoImagenUrl, setMapaPersonalizadoImagenUrl] = useState<string>(MAPA_PERSONALIZADO_IMAGEN_INICIAL);
	const { avaluo } = props;

	useEffect(() => {
		if (avaluo && avaluo.ubicacion) {
			setCroquisImagenUrl(obtenerAvaluoCroquis(avaluo));
			setMapaImagenUrl(obtenerAvaluoMapa(avaluo));
			setMapaPersonalizadoImagenUrl(obtenerAvaluoMapaPersonalizado(avaluo));
		}
	}, [avaluo]);

	if (avaluo && avaluo.ubicacion && avaluo.distribucion && avaluo.construcciones && avaluo.medidas) {
		const {
			coordenadasDdLatitud,
			coordenadasDdLongitud,
			coordenadasDmsLatitudCardinal,
			coordenadasDmsLatitudGrados,
			coordenadasDmsLatitudMinutos,
			coordenadasDmsLatitudSegundos,
			coordenadasDmsLongitudCardinal,
			coordenadasDmsLongitudGrados,
			coordenadasDmsLongitudMinutos,
			coordenadasDmsLongitudSegundos,
			coordenadasUtmEsteX,
			coordenadasUtmHuso,
			coordenadasUtmNorteY,
			coordenadasUtmZona,
			distanciaEsquina,
			distanciaEsquinaCalle
		} = avaluo.ubicacion;
		const { descripcion: descripcionDistribucion } = avaluo.distribucion;
		const { detalles, superficieUnidad } = avaluo.construcciones;
		const { superficieReal } = avaluo.medidas;

		/**
		 * Setea la imagen del croquis en el state de Redux para ser visible en el visor de imágenes.
		 */
		const eventoVerImagenCroquis = () => {
			const imagen: McVisorImagenElemento = {
				id: `${avaluo.id}_croquis`,
				titulo: texto('Croquis del predio'),
				url: croquisImagenUrl
			};
			dispatch(setVisorImagenImagenesAction([imagen]));
			dispatch(setVisorImagenMostrarAction(true));
		};

		/**
		 * Setea la imagen del mapa en el state de Redux para ser visible en el visor de imágenes.
		 */
		const eventoVerImagenMapa = () => {
			const imagen: McVisorImagenElemento = {
				id: `${avaluo.id}_mapa`,
				titulo: texto('Mapa del predio'),
				url: mapaImagenUrl
			};
			dispatch(setVisorImagenImagenesAction([imagen]));
			dispatch(setVisorImagenMostrarAction(true));
		};

		/**
		 * Setea la imagen del mapa personalizado en el state de Redux para ser visible en el visor de imágenes.
		 */
		const eventoVerImagenMapaPersonalizado = () => {
			const imagen: McVisorImagenElemento = {
				id: `${avaluo.id}_mapa-personalizado`,
				titulo: texto('Mapa personalizado del predio'),
				url: mapaPersonalizadoImagenUrl
			};
			dispatch(setVisorImagenImagenesAction([imagen]));
			dispatch(setVisorImagenMostrarAction(true));
		};

		return (
			<Fragment>
				<Row>
					<Col lg="12">
						<h3>{texto('Localización del Predio y Croquis Esquematico sin Escala de la Construcción')}</h3>
					</Col>
				</Row>
				<Row>
					<Col lg="8">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Tramo de calle, Distancia a las Esquina, Calles Transversales y Orientación.')}</span>
						</p>
						<Row>
							<Col lg="6">
								<p>
									<span className="avaluo-detalles__etiqueta">{texto('Distancia a la esquina')}:</span>
									<span className="avaluo-detalles__valor">{`${distanciaEsquina} m`}</span>
								</p>
							</Col>
							<Col lg="6">
								<p>
									<span className="avaluo-detalles__etiqueta">{texto('A la calle')}:</span>
									<span className="avaluo-detalles__valor">{distanciaEsquinaCalle}</span>
								</p>
							</Col>
						</Row>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Superficie real según levantamiento')}:</span>
							<span className="avaluo-detalles__valor">{`${superficieReal} ${superficieUnidad?.simbolo}`}</span>
						</p>
					</Col>
					<Col lg="4"></Col>
				</Row>
				<Row>
					<Col lg="4">
						<div className="avaluos-detalles-mapa">
							<img alt="Mapa del predio" className="mc-soft__imagen--cuadrado" onClick={eventoVerImagenMapa} src={mapaImagenUrl} width="50%" />
							<div>{texto('Ubicación')}</div>
						</div>
					</Col>
					<Col lg="4">
						<div className="avaluos-detalles-mapa">
							<img
								alt="Mapa personalizado del predio"
								className="mc-soft__imagen--cuadrado"
								onClick={eventoVerImagenMapaPersonalizado}
								src={mapaPersonalizadoImagenUrl}
								width="50%"
							/>
							<div>{texto('Mapa Personalizado')}</div>
						</div>
					</Col>
					<Col lg="4">
						<div className="avaluos-detalles-mapa">
							<img alt="Croquis del predio" className="mc-soft__imagen--cuadrado" onClick={eventoVerImagenCroquis} src={croquisImagenUrl} width="50%" />
							<div>{texto('Croquis')}</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h3>{texto('Coordenadas Geograficas Decimales Predios Urbanos')}</h3>
					</Col>
				</Row>
				<Row>
					<Col lg="4">
						<h5>{texto('Decimales')}</h5>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Latitud')}:</span>
							<span className="avaluo-detalles__valor">{`${coordenadasDdLatitud}`}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Longitud')}:</span>
							<span className="avaluo-detalles__valor">{`${coordenadasDdLongitud}`}</span>
						</p>
					</Col>
					<Col lg="4">
						<h5>{texto('DMS')}</h5>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Latitud')}:</span>
							<span className="avaluo-detalles__valor">{`${coordenadasDmsLatitudGrados}°${coordenadasDmsLatitudMinutos}'${coordenadasDmsLatitudSegundos}'' ${coordenadasDmsLatitudCardinal}`}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Longitud')}:</span>
							<span className="avaluo-detalles__valor">{`${coordenadasDmsLongitudGrados}°${coordenadasDmsLongitudMinutos}'${coordenadasDmsLongitudSegundos}'' ${coordenadasDmsLongitudCardinal}`}</span>
						</p>
					</Col>
					<Col lg="4">
						<h5>{texto('UTM')}</h5>
						<p>
							<span className="avaluo-detalles__valor">{`${coordenadasUtmHuso}${coordenadasUtmZona} ${coordenadasUtmEsteX} m E ${coordenadasUtmNorteY} m N`}</span>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h3>{texto('Clasificación de la Construcción')}</h3>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<div className="border-responsive">
							<table className="table table-bordered">
								<thead>
									<tr className="avaluo-detalles__tabla-encabezado">
										<th>{texto('Bloque')}</th>
										<th>{texto('Código')}</th>
										<th>{texto('Edad')}</th>
										<th>{texto('Calidad')}</th>
										<th>{texto('Estado de Conservación')}</th>
									</tr>
								</thead>
								<tbody>
									{detalles.length > 0 &&
										detalles.map((detalle) => (
											<tr className="avaluo-detalles__tabla-celda__centrada" key={detalle.id}>
												<td>{convertirNumeroEnteroANumeroRomano(detalle.bloque)}</td>
												<td>{detalle.codigo}</td>
												<td>{detalle.edad}</td>
												<td>{detalle.calidad?.nombre}</td>
												<td>{detalle.estadoConservacion?.nombre}</td>
											</tr>
										))}
									{detalles.length === 0 && (
										<tr>
											<td className="avaluos-detalles-tabla__sin-detalles" colSpan={12}>
												{texto('Sin detalles.')}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h3>{texto('Descripción de la Distribución del Inmueble')}</h3>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Consta de')}:</span>
							<span className="avaluo-detalles__valor">{descripcionDistribucion}</span>
						</p>
					</Col>
				</Row>
			</Fragment>
		);
	} else {
		return null;
	}
};

export default AvaluoDetallesUbicacion;
