import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del locale de moment.
 */
const InformacionMomentFechaLocale = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Utilizado para definir el lenguaje de la librería moment.js')}</p>
			<ul>
				<li>
					<span className="badge bg-info text-wrap">es-mx</span> {texto('Español de México')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">en</span> {texto('Inglés')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">fr</span> {texto('Frances')}{' '}
				</li>
			</ul>
		</div>
	</Fragment>
);

export default InformacionMomentFechaLocale;
