import { Col, Row } from 'reactstrap';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import CantidadAvaluos from 'componentes/widgets/cantidadAvaluos/CantidadAvaluos';
import CantidadAvaluosPorMunicipio from 'componentes/widgets/cantidadAvaluosPorMunicipio/CantidadAvaluosPorMunicipio';
import CantidadAvaluosPorPerito from 'componentes/widgets/cantidadAvaluosPorPerito/CantidadAvaluosPorPerito';
import CantidadAvaluosPorUsuario from 'componentes/widgets/cantidadAvaluosPorUsuario/CantidadAvaluosPorUsuario';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { texto } from 'idiomas';

/**
 * Página que muestra información relevante al administrador de la aplicación.
 */
const Pagina = () => (
	<Contenedor>
		<Breadcrumb icono={constantes.icono.dashboard} titulo={texto('Dashboard')} />
		<Row>
			<Col md={6}>
				<CantidadAvaluos />
			</Col>
			<Col md={6}>
				<CantidadAvaluosPorPerito />
			</Col>
		</Row>
		<Row>
			<Col md={6}>
				<CantidadAvaluosPorMunicipio />
			</Col>
			<Col md={6}>
				<CantidadAvaluosPorUsuario />
			</Col>
		</Row>
	</Contenedor>
);

export default Pagina;
