import 'toastr/build/toastr.min.css';
import toastr from 'toastr';

toastr.options = {
	closeButton: false,
	debug: false,
	escapeHtml: false,
	extendedTimeOut: 1000,
	hideDuration: 1000,
	hideEasing: 'linear',
	hideMethod: 'fadeOut',
	newestOnTop: true,
	positionClass: 'toast-bottom-right',
	preventDuplicates: true,
	progressBar: true,
	showDuration: 300,
	showEasing: 'swing',
	showMethod: 'fadeIn',
	timeOut: 5000
};

export default {
	/**
	 * Muestra una notificación de advertencia.
	 * - ***mensaje*** - Mensaje o arreglo de mensajes a mostrar en la notificación.
	 * - ***titulo?*** - Título a mostrar en la notificación.
	 */
	advertencia: ({ mensaje, titulo }: { mensaje: Array<string> | string; titulo?: string }): void => {
		if (Array.isArray(mensaje)) {
			mensaje.forEach((elemento) => {
				if (titulo) {
					toastr.warning(elemento, titulo);
				} else {
					toastr.warning(elemento);
				}
			});
		} else {
			if (titulo) {
				toastr.warning(mensaje, titulo);
			} else {
				toastr.warning(mensaje);
			}
		}
	},
	/**
	 * Muestra una notificación de error.
	 * - ***mensaje*** - Mensaje o arreglo de mensajes a mostrar en la notificación.
	 * - ***titulo?*** - Título a mostrar en la notificación.
	 */
	error: ({ mensaje, titulo }: { mensaje: Array<string> | string; titulo?: string }): void => {
		if (Array.isArray(mensaje)) {
			mensaje.forEach((elemento) => {
				if (titulo) {
					toastr.error(elemento, titulo);
				} else {
					toastr.error(elemento);
				}
			});
		} else {
			if (titulo) {
				toastr.error(mensaje, titulo);
			} else {
				toastr.error(mensaje);
			}
		}
	},
	/**
	 * Muestra una notificación de éxito.
	 * - ***mensaje*** - Mensaje o arreglo de mensajes a mostrar en la notificación.
	 * - ***titulo?*** - Título a mostrar en la notificación.
	 */
	exito: ({ mensaje, titulo }: { mensaje: Array<string> | string; titulo?: string }): void => {
		if (Array.isArray(mensaje)) {
			mensaje.forEach((elemento) => {
				if (titulo) {
					toastr.success(elemento, titulo);
				} else {
					toastr.success(elemento);
				}
			});
		} else {
			if (titulo) {
				toastr.success(mensaje, titulo);
			} else {
				toastr.success(mensaje);
			}
		}
	},
	/**
	 * Muestra una notificación de información.
	 * - ***mensaje*** - Mensaje o arreglo de mensajes a mostrar en la notificación.
	 * - ***titulo?*** - Título a mostrar en la notificación.
	 */
	informacion: ({ mensaje, titulo }: { mensaje: Array<string> | string; titulo?: string }): void => {
		if (Array.isArray(mensaje)) {
			mensaje.forEach((elemento) => {
				if (titulo) {
					toastr.info(elemento, titulo);
				} else {
					toastr.info(elemento);
				}
			});
		} else {
			if (titulo) {
				toastr.info(mensaje, titulo);
			} else {
				toastr.info(mensaje);
			}
		}
	}
};
