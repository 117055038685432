import Avaluo from 'modelo/Avaluo';
import clasificacionConstruccion from './clasificacionConstruccion';
import descripcionDistribucionInmueble from './descripcionDistribucionInmueble';
import encabezado from './encabezado';
import localizacionPredio from './localizacionPredio';
import marcoPagina from './marcoPagina';
import Usuario from 'modelo/Usuario';

const pagina = ({
	avaluo,
	etiquetaConstruccionCodigo,
	tipoEncabezado,
	usuario
}: {
	avaluo: Avaluo;
	etiquetaConstruccionCodigo: string;
	tipoEncabezado: string;
	usuario: Usuario;
}) =>
	new Promise((resolve) => {
		const promesas = [];
		promesas.push(encabezado({ avaluo, tipoEncabezado, usuario }));
		promesas.push(localizacionPredio(avaluo));
		promesas.push(clasificacionConstruccion({ avaluo, etiquetaConstruccionCodigo }));
		promesas.push(descripcionDistribucionInmueble(avaluo));
		Promise.all(promesas).then((secciones) => {
			const contenido = [].concat.apply([], secciones as any);
			resolve(marcoPagina(contenido));
		});
	});

export default pagina;
