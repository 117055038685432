import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { formatearListaOpcionesMcCampoSelector, obtenerOpcionDeLaLista } from 'util/modelo/listasOpciones';
import {
	McCampoFecha,
	McCampoSelector,
	McCampoSelectorMultiple,
	McCampoSelectorMultipleOpcion,
	McCampoTexto,
	obtenerValorMcCampoFecha,
	obtenerValorMcCampoSelector,
	obtenerValorMcCampoTexto
} from '@mcsoft/formulario';
import { useForm, useFormState } from 'react-final-form';
import aplicacion from 'configuracion/aplicacion';
import Avaluo from 'modelo/Avaluo';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import ListaOpcion from 'modelo/ListaOpcion';
import { obtenerEstadosTodosPorPais } from 'servicios/api/estados';
import { obtenerMunicipiosTodosPorEstado } from 'servicios/api/municipios';
import { StateType } from 'store';
import { texto } from 'idiomas';
import useFecha from 'hooks/useFecha';
import { useSelector } from 'react-redux';

interface AvaluoFormularioGeneralProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
	listaDestinoEspecifico: Array<ListaOpcion> | undefined;
	listaUnidadSuperficie: Array<ListaOpcion> | undefined;
	listaUsoEspecifico: Array<ListaOpcion> | undefined;
	listaVientoCardinal: Array<ListaOpcion> | undefined;
}

/**
 * Formulario para la sección general del avalúo.
 */
const AvaluoFormularioGeneral = (props: AvaluoFormularioGeneralProps) => {
	const fechaFormateada = useFecha();
	const formulario = useForm();
	const formularioValores = useFormState();
	const { escritura } = formularioValores.values;
	const { estadoId, fecha, municipioId, notarioNombre, numero, numeroNotaria } = escritura;
	const { eventoDeshacer, eventoGuardar, eventoSalir, hayCambiosSinGuardar, listaDestinoEspecifico, listaUnidadSuperficie, listaUsoEspecifico, listaVientoCardinal } = props;
	const { auth0AccessToken } = useSelector((state: StateType) => state.sesion.auth0);
	const { selectorFechaFormato } = useSelector((state: StateType) => state.configuracionAplicacion);
	const [escrituraDescripcionEditable, setEscrituraDescripcionEditable] = useState<boolean>(false);
	const [listaEstados, setListaEstados] = useState<Array<ListaOpcion>>([]);
	const [listaMunicipios, setListaMunicipios] = useState<Array<ListaOpcion>>([]);
	const [listaVientoCardinalOpciones, setListaVientoCardinalOpciones] = useState<Array<McCampoSelectorMultipleOpcion>>([]);

	useEffect(() => {
		obtenerListaEstados(aplicacion.api.id.paisPredeterminado);
		// obtenerListaMunicipios(aplicacion.api.id.estadoPredeterminado);
	}, []);

	useEffect(() => {
		obtenerListaMunicipios(estadoId);
	}, [estadoId]);

	useEffect(() => {
		setListaVientoCardinalOpciones(formatearListaOpcionesMcCampoSelector(listaVientoCardinal));
	}, [listaVientoCardinal]);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonSalir" onClick={eventoSalir} type="button">
				<i className={constantes.icono.atras}></i> {texto('Salir')}
			</button>
			<button className="btn btn-warning" disabled={!hayCambiosSinGuardar} id="botonDeshacer" onClick={eventoDeshacer} type="button">
				<i className={constantes.icono.deshacer}></i> {texto('Deshacer')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardar} id="botonGuardar" onClick={eventoGuardarFormulario} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardar}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	/**
	 * Evento que se ejecuta cuando cambia un campo de la escritura.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioEscritura = ({ campo, valor }: { campo: string; valor: string | null }) => {
		const { cambiarValorCampo } = formulario.mutators;
		let escrituraDescripcion = '';
		if (campo === 'numero' && valor) {
			escrituraDescripcion += `Escritura ${Number(valor).toLocaleString('es-mx')}`;
		} else if (campo !== 'numero' && numero) {
			escrituraDescripcion += `Escritura ${Number(numero).toLocaleString('es-mx')}`;
		}
		if (campo === 'fecha' && valor) {
			if (valor) {
				escrituraDescripcion += ` de fecha ${valor}`;
			} else {
				escrituraDescripcion += ``;
			}
		} else if (campo !== 'fecha' && fecha) {
			escrituraDescripcion += ` de fecha ${fechaFormateada(fecha, 'DD [de] MMMM [de] YYYY')}`;
		}
		if (campo === 'notarioNombre' && valor) {
			escrituraDescripcion += ` Firmada ante ${valor}`;
		} else if (campo !== 'notarioNombre' && notarioNombre) {
			escrituraDescripcion += ` Firmada ante ${notarioNombre}`;
		}
		if (campo === 'numeroNotaria' && valor) {
			escrituraDescripcion += ` Titular de la Notaria ${valor}`;
		} else if (campo !== 'numeroNotaria' && numeroNotaria) {
			escrituraDescripcion += ` Titular de la Notaria ${numeroNotaria}`;
		}
		if (campo === 'municipioSeleccion' && valor) {
			escrituraDescripcion += ` de ${obtenerOpcionDeLaLista(listaMunicipios, valor)?.nombre}`;
		} else if (campo !== 'municipioSeleccion' && municipioId) {
			if (campo === 'estadoSeleccion') {
				escrituraDescripcion += ``;
			} else {
				escrituraDescripcion += ` de ${obtenerOpcionDeLaLista(listaMunicipios, municipioId)?.nombre}`;
			}
		}
		if (campo === 'estadoSeleccion' && valor) {
			escrituraDescripcion += `, ${obtenerOpcionDeLaLista(listaEstados, valor)?.nombre}`;
		} else if (campo !== 'estadoSeleccion' && estadoId) {
			escrituraDescripcion += `, ${obtenerOpcionDeLaLista(listaEstados, estadoId)?.nombre}`;
		}
		cambiarValorCampo('escritura.descripcion', escrituraDescripcion);
	};

	/**
	 * Evento que se ejecuta cuando cambia el estado seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioEstado = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const { cambiarValorCampo, limpiarValorCampo } = formulario.mutators;
		const valor = obtenerValorMcCampoSelector(evento);
		obtenerListaMunicipios(valor);
		cambiarValorCampo(`escritura.estadoId`, valor);
		limpiarValorCampo(`escritura.municipioId`);
		limpiarValorCampo(`escritura.municipioSeleccion`);
		eventoCambioEscritura({ campo: 'estadoSeleccion', valor });
	};

	/**
	 * Evento que se ejecuta cuando cambia la fecha seleccionada.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioFecha = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const { cambiarValorCampo } = formulario.mutators;
		const campoValorDate = obtenerValorMcCampoFecha(evento);
		cambiarValorCampo(`escritura.fecha`, campoValorDate);
		if (campoValorDate) {
			const valor = fechaFormateada(campoValorDate, 'DD [de] MMMM [de] YYYY');
			eventoCambioEscritura({ campo: 'fecha', valor });
		} else {
			eventoCambioEscritura({ campo: 'fecha', valor: campoValorDate });
		}
	};

	/**
	 * Evento que se ejecuta cuando cambia el municipio seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoCambioMunicipio = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const { cambiarValorCampo } = formulario.mutators;
		const valor = obtenerValorMcCampoSelector(evento);
		cambiarValorCampo(`escritura.municipioId`, valor);
		eventoCambioEscritura({ campo: 'municipioSeleccion', valor });
	};

	/**
	 * Evento que se ejecuta cuando cambia el viento cardinal seleccionado.
	 * - ***evento*** - Evento que invoca la función.
	 * - ***numeroViento*** - El número de viento que cambio.
	 */
	const eventoCambioVientoCardinal = async ({ evento, numeroViento }: { evento: ChangeEvent<HTMLSelectElement>; numeroViento: number }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const valor = obtenerValorMcCampoSelector(evento);
		cambiarValorCampo(`medidas.colindancia${numeroViento}VientoId`, valor);
	};

	/**
	 * Copia la superficie de la escritura en el campo superficie según catastro.
	 */
	const eventoCopiarSuperficieCatastro = () => {
		const { copiarValorCampo } = formulario.mutators;
		copiarValorCampo('medidas.superficieEscritura', 'medidas.superficieCatastro');
	};

	/**
	 * Copia la superficie de la escritura en el campo superficie real.
	 */
	const eventoCopiarSuperficieReal = () => {
		const { copiarValorCampo } = formulario.mutators;
		copiarValorCampo('medidas.superficieEscritura', 'medidas.superficieReal');
	};

	/**
	 * Habilita la edición manual del campo descripción.
	 */
	const eventoEditarEscrituraDescripcion = () => {
		setEscrituraDescripcionEditable(!escrituraDescripcionEditable);
	};

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardarFormulario = () => {
		const avaluo = formularioValores.values as Avaluo;
		eventoGuardar(avaluo);
	};

	/**
	 * Obtiene la lista de estados relacionados al país seleccionado.
	 */
	const obtenerListaEstados = async (paisId: string) => {
		if (paisId) {
			const respuesta = await obtenerEstadosTodosPorPais({ auth0AccessToken, paisId });
			setListaEstados(respuesta.datos);
		} else {
			setListaEstados([]);
		}
	};

	/**
	 * Obtiene la lista de municipios relacionados al estado seleccionado.
	 */
	const obtenerListaMunicipios = async (estadoId: string) => {
		if (estadoId) {
			const respuesta = await obtenerMunicipiosTodosPorEstado({ auth0AccessToken, estadoId });
			setListaMunicipios(respuesta.datos);
		} else {
			setListaMunicipios([]);
		}
	};

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.avaluo}></i> {texto('Información General')}
					</h3>
					<p>{texto('En esta sección del formulario se captura la información general del avalúo.')}</p>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">
					<h5>
						<i className={constantes.icono.escritura}></i> {texto('Escritura')}
					</h5>
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="6" xl="3">
					<McCampoTexto
						campo="escritura.numero"
						etiqueta={texto('Número')}
						eventoCambio={(evento) => eventoCambioEscritura({ campo: 'numero', valor: obtenerValorMcCampoTexto(evento) })}
						id="escritura.numero"
						numeroMinimo={0}
						tipo="numeroEntero"
					/>
				</Col>
				<Col lg="6" md="6" xl="3">
					<McCampoFecha
						campo="escritura.fecha"
						etiqueta={texto('Fecha')}
						eventoCambio={eventoCambioFecha}
						fechaInicial={fecha}
						formatoFecha={selectorFechaFormato}
						id="escritura.fecha"
					/>
				</Col>
				<Col lg="6" md="6" xl="3">
					<McCampoSelectorMultiple
						campo="escritura.estadoSeleccion"
						etiqueta={texto('Estado')}
						eventoCambio={eventoCambioEstado}
						id="escritura.estadoSeleccion"
						opciones={formatearListaOpcionesMcCampoSelector(listaEstados)}
					/>
				</Col>
				<Col lg="6" md="6" xl="3">
					<McCampoSelectorMultiple
						campo="escritura.municipioSeleccion"
						etiqueta={texto('Municipio')}
						eventoCambio={eventoCambioMunicipio}
						id="escritura.municipioSeleccion"
						opciones={formatearListaOpcionesMcCampoSelector(listaMunicipios)}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="3" md="6" xl="3">
					<McCampoTexto
						campo="escritura.numeroNotaria"
						etiqueta={texto('Notaria No.')}
						eventoCambio={(evento) => eventoCambioEscritura({ campo: 'numeroNotaria', valor: obtenerValorMcCampoTexto(evento) })}
						id="escritura.numeroNotaria"
						numeroMinimo={0}
						tipo="numeroEntero"
					/>
				</Col>
				<Col lg="9">
					<McCampoTexto
						campo="escritura.notarioNombre"
						etiqueta={texto('Notario')}
						eventoCambio={(evento) => eventoCambioEscritura({ campo: 'notarioNombre', valor: obtenerValorMcCampoTexto(evento) })}
						id="escritura.notarioNombre"
						longitudMaxima={100}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<span className="avaluos-formulario__botton-editar">
						<button className="btn btn-sm btn-primary" onClick={eventoEditarEscrituraDescripcion} title={texto('Editar Manualmente')} type="button">
							<i className={constantes.icono.editar}></i>
						</button>
					</span>
					<McCampoTexto
						campo="escritura.descripcion"
						etiqueta={texto('Descripción')}
						id="escritura.descripcion"
						longitudMaxima={360}
						renglones={3}
						soloLectura={!escrituraDescripcionEditable}
						tipo="areaTexto"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<hr />
					<h5>
						<i className={constantes.icono.regla}></i> {texto('Medidas')}
					</h5>
				</Col>
			</Row>
			<Row>
				<Col lg="4" md="6" xl="2">
					<McCampoSelector
						campo="medidas.superficieUnidadId"
						etiqueta={texto('Unidad')}
						id="medidas.superficieUnidadId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaUnidadSuperficie)}
					/>
				</Col>
				<Col lg="6" md="6" xl="4">
					<McCampoTexto
						campo="medidas.superficieEscritura"
						etiqueta={texto('Superficie según escrituras')}
						id="medidas.superficieEscritura"
						numeroMinimo={0}
						obligatorio
						tipo="numeroNumerico"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="8" md="6" xl="6">
					<McCampoTexto
						campo="medidas.superficieCatastro"
						etiqueta={texto('Superficie según catastro')}
						eventoBotonDerecha={eventoCopiarSuperficieCatastro}
						id="medidas.superficieCatastro"
						numeroMinimo={0}
						obligatorio
						textoDerecha={texto('Copiar de escritura')}
						tipo="numeroNumerico"
					/>
				</Col>
				<Col lg="8" md="6" xl="6">
					<McCampoTexto
						campo="medidas.superficieReal"
						etiqueta={texto('Superficie real según levantamiento')}
						eventoBotonDerecha={eventoCopiarSuperficieReal}
						id="medidas.superficieReal"
						numeroMinimo={0}
						obligatorio
						textoDerecha={texto('Copiar de escritura')}
						tipo="numeroNumerico"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4" md="6" xl="3">
					<McCampoTexto campo="medidas.indiviso" etiqueta={texto('Indiviso')} id="medidas.indiviso" numeroMaximo={100} numeroMinimo={0} textoDerecha="%" tipo="numeroNumerico" />
				</Col>
				<Col lg="4" md="6" xl="3">
					<McCampoTexto campo="medidas.edad" etiqueta={texto('Edad')} id="medidas.edad" longitudMaxima={10} textoDerecha={texto('años')} />
				</Col>
				<Col lg="6" md="6" xl="4">
					<McCampoTexto
						campo="medidas.vidaRemanenteProbale"
						etiqueta={texto('Vida remanente probable')}
						id="medidas.vidaRemanenteProbale"
						longitudMaxima={10}
						textoDerecha={texto('años o más')}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="6" xl="6">
					<McCampoTexto campo="medidas.calidadProyecto" etiqueta={texto('Calidad de proyecto')} id="medidas.calidadProyecto" longitudMaxima={70} obligatorio />
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="6" xl="4">
					<McCampoSelector
						campo="medidas.usoEspecificoId"
						etiqueta={texto('Uso especifico')}
						id="medidas.usoEspecificoId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaUsoEspecifico)}
					/>
				</Col>
				<Col lg="6" md="6" xl="4">
					<McCampoSelector
						campo="medidas.usoEspecifico2Id"
						etiqueta={texto('Uso especifico secundario')}
						id="medidas.usoEspecifico2Id"
						opciones={formatearListaOpcionesMcCampoSelector(listaUsoEspecifico)}
					/>
				</Col>
				<Col lg="6" md="6" xl="4">
					<McCampoSelector
						campo="medidas.unidadesRentables"
						etiqueta={texto('Unidades rentables')}
						id="medidas.unidadesRentables"
						obligatorio
						opciones={[
							{
								nombre: 'Una',
								valor: 'Una'
							},
							{
								nombre: 'Dos',
								valor: 'Dos'
							},
							{
								nombre: 'Tres',
								valor: 'Tres'
							},
							{
								nombre: 'Cuatro',
								valor: 'Cuatro'
							},
							{
								nombre: 'Cinco',
								valor: 'Cinco'
							},
							{
								nombre: 'Seis',
								valor: 'Seis'
							},
							{
								nombre: 'Siete',
								valor: 'Siete'
							},
							{
								nombre: 'Ocho',
								valor: 'Ocho'
							},
							{
								nombre: 'Nueve',
								valor: 'Nueve'
							},
							{
								nombre: 'Diez',
								valor: 'Diez'
							}
						]}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="6" xl="4">
					<McCampoSelector
						campo="medidas.destinoEspecificoId"
						etiqueta={texto('Destino especifico')}
						id="medidas.destinoEspecificoId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaDestinoEspecifico)}
					/>
				</Col>
				<Col lg="6" md="6" xl="4">
					<McCampoSelector
						campo="medidas.destinoEspecifico2Id"
						etiqueta={texto('Destino especifico secundario')}
						id="medidas.destinoEspecifico2Id"
						opciones={formatearListaOpcionesMcCampoSelector(listaDestinoEspecifico)}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">
					<hr />
					<h5>
						<i className={constantes.icono.rosaVientos}></i> {texto('Colindancias')}
					</h5>
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="12" xl="4">
					<McCampoSelectorMultiple
						campo="medidas.colindancia1VientoSeleccion"
						eventoCambio={(evento: ChangeEvent<HTMLSelectElement>) => eventoCambioVientoCardinal({ evento, numeroViento: 1 })}
						id="medidas.colindancia1VientoSeleccion"
						opciones={listaVientoCardinalOpciones}
					/>
				</Col>
				<Col lg="6" md="12" xl="8">
					<McCampoTexto campo="medidas.colindancia1Descripcion" id="medidas.colindancia1Descripcion" longitudMaxima={500} renglones={2} tipo="areaTexto" />
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="12" xl="4">
					<McCampoSelectorMultiple
						campo="medidas.colindancia2VientoSeleccion"
						eventoCambio={(evento: ChangeEvent<HTMLSelectElement>) => eventoCambioVientoCardinal({ evento, numeroViento: 2 })}
						id="medidas.colindancia2VientoSeleccion"
						opciones={listaVientoCardinalOpciones}
					/>
				</Col>
				<Col lg="6" md="12" xl="8">
					<McCampoTexto campo="medidas.colindancia2Descripcion" id="medidas.colindancia2Descripcion" longitudMaxima={500} renglones={2} tipo="areaTexto" />
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="12" xl="4">
					<McCampoSelectorMultiple
						campo="medidas.colindancia3VientoSeleccion"
						eventoCambio={(evento: ChangeEvent<HTMLSelectElement>) => eventoCambioVientoCardinal({ evento, numeroViento: 3 })}
						id="medidas.colindancia3VientoSeleccion"
						opciones={listaVientoCardinalOpciones}
					/>
				</Col>
				<Col lg="6" md="12" xl="8">
					<McCampoTexto campo="medidas.colindancia3Descripcion" id="medidas.colindancia3Descripcion" longitudMaxima={500} renglones={2} tipo="areaTexto" />
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="12" xl="4">
					<McCampoSelectorMultiple
						campo="medidas.colindancia4VientoSeleccion"
						eventoCambio={(evento: ChangeEvent<HTMLSelectElement>) => eventoCambioVientoCardinal({ evento, numeroViento: 4 })}
						id="medidas.colindancia4VientoSeleccion"
						opciones={listaVientoCardinalOpciones}
					/>
				</Col>
				<Col lg="6" md="12" xl="8">
					<McCampoTexto campo="medidas.colindancia4Descripcion" id="medidas.colindancia4Descripcion" longitudMaxima={500} renglones={2} tipo="areaTexto" />
				</Col>
			</Row>
			<Row>
				<Col md="12">
					<McCampoTexto campo="medidas.colindanciaSeparador" id="medidas.colindanciaSeparador" longitudMaxima={300} renglones={2} tipo="areaTexto" />
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="12" xl="4">
					<McCampoSelectorMultiple
						campo="medidas.colindancia5VientoSeleccion"
						eventoCambio={(evento: ChangeEvent<HTMLSelectElement>) => eventoCambioVientoCardinal({ evento, numeroViento: 5 })}
						id="medidas.colindancia5VientoSeleccion"
						opciones={listaVientoCardinalOpciones}
					/>
				</Col>
				<Col lg="6" md="12" xl="8">
					<McCampoTexto campo="medidas.colindancia5Descripcion" id="medidas.colindancia5Descripcion" longitudMaxima={500} renglones={2} tipo="areaTexto" />
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="12" xl="4">
					<McCampoSelectorMultiple
						campo="medidas.colindancia6VientoSeleccion"
						eventoCambio={(evento: ChangeEvent<HTMLSelectElement>) => eventoCambioVientoCardinal({ evento, numeroViento: 6 })}
						id="medidas.colindancia6VientoSeleccion"
						opciones={listaVientoCardinalOpciones}
					/>
				</Col>
				<Col lg="6" md="12" xl="8">
					<McCampoTexto campo="medidas.colindancia6Descripcion" id="medidas.colindancia6Descripcion" longitudMaxima={500} renglones={2} tipo="areaTexto" />
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="12" xl="4">
					<McCampoSelectorMultiple
						campo="medidas.colindancia7VientoSeleccion"
						eventoCambio={(evento: ChangeEvent<HTMLSelectElement>) => eventoCambioVientoCardinal({ evento, numeroViento: 7 })}
						id="medidas.colindancia7VientoSeleccion"
						opciones={listaVientoCardinalOpciones}
					/>
				</Col>
				<Col lg="6" md="12" xl="8">
					<McCampoTexto campo="medidas.colindancia7Descripcion" id="medidas.colindancia7Descripcion" longitudMaxima={500} renglones={2} tipo="areaTexto" />
				</Col>
			</Row>
			<Row>
				<Col lg="6" md="12" xl="4">
					<McCampoSelectorMultiple
						campo="medidas.colindancia8VientoSeleccion"
						eventoCambio={(evento: ChangeEvent<HTMLSelectElement>) => eventoCambioVientoCardinal({ evento, numeroViento: 8 })}
						id="medidas.colindancia8VientoSeleccion"
						opciones={listaVientoCardinalOpciones}
					/>
				</Col>
				<Col lg="6" md="12" xl="8">
					<McCampoTexto campo="medidas.colindancia8Descripcion" id="medidas.colindancia8Descripcion" longitudMaxima={500} renglones={2} tipo="areaTexto" />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
		</Fragment>
	);
};

export default AvaluoFormularioGeneral;
