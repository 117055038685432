/* eslint-disable no-unused-vars */
import './McCampoFecha.scss';
import 'flatpickr/dist/themes/material_blue.css';
import { Field, FieldMetaState } from 'react-final-form';
import { FocusEvent, Fragment, MouseEvent, useState } from 'react';
import Flatpickr from 'react-flatpickr';
import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { Tooltip } from 'reactstrap';

const TEXTO = {
	errorObligatorio: 'Obligatorio'
};

flatpickr.localize(Spanish);
let fechaInicializada = false;

interface McCampoFechaProps {
	/**
	 * Nombre del campo del formulario donde se guardara el valor del input.
	 */
	campo: string;
	/**
	 * Clase del botón que se mostrará a la derecha del campo.
	 *
	 * > ***Predeterminado:*** *'primary'*
	 *
	 * **Valores Admitidos**
	 * - **'primary':** Fondo azul y texto blanco.
	 * - **'secondary':** Fondo gris y texto blanco.
	 * - **'success':** Fondo verde y texto blanco.
	 * - **'danger':** Fondo rojo y texto blanco.
	 * - **'warning':** Fondo amarillo y texto negro.
	 * - **'info':** Fondo aqua y texto negro.
	 * - **'light':** Fondo blanco y texto negro.
	 * - **'dark':** Fondo negro y texto blanco.
	 * - **'primary contorno':** Contorno azul, fondo negro y texto azul.
	 * - **'secondary contorno':** Contorno gris, fondo negro y texto gris.
	 * - **'success contorno':** Contorno verde, fondo negro y texto verde.
	 * - **'danger contorno':** Contorno rojo, fondo negro y texto rojo.
	 * - **'warning contorno':** Contorno amarillo, fondo negro y texto amarillo.
	 * - **'info contorno':** Contorno aqua, fondo negro y texto aqua.
	 * - **'light contorno':** Contorno blanco, fondo negro y texto blanco.
	 * - **'dark contorno':** Contorno negro, fondo blanco y texto negro.
	 * - **'link':** Formato de hyper enlace.
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > (iconoDerecha != undefined || textoDerecha != undefined) && eventoBotonDerecha != undefined
	 */
	claseBotonDerecha?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'danger'
		| 'warning'
		| 'info'
		| 'light'
		| 'dark'
		| 'primary contorno'
		| 'secondary contorno'
		| 'success contorno'
		| 'danger contorno'
		| 'warning contorno'
		| 'info contorno'
		| 'light contorno'
		| 'dark contorno'
		| 'link';
	/**
	 * Clase del botón que se mostrará a la izquierda del campo.
	 *
	 * > ***Predeterminado:*** *'primary'*
	 *
	 * **Valores Admitidos**
	 * - **'primary':** Fondo azul y texto blanco.
	 * - **'secondary':** Fondo gris y texto blanco.
	 * - **'success':** Fondo verde y texto blanco.
	 * - **'danger':** Fondo rojo y texto blanco.
	 * - **'warning':** Fondo amarillo y texto negro.
	 * - **'info':** Fondo aqua y texto negro.
	 * - **'light':** Fondo blanco y texto negro.
	 * - **'dark':** Fondo negro y texto blanco.
	 * - **'primary contorno':** Contorno azul, fondo negro y texto azul.
	 * - **'secondary contorno':** Contorno gris, fondo negro y texto gris.
	 * - **'success contorno':** Contorno verde, fondo negro y texto verde.
	 * - **'danger contorno':** Contorno rojo, fondo negro y texto rojo.
	 * - **'warning contorno':** Contorno amarillo, fondo negro y texto amarillo.
	 * - **'info contorno':** Contorno aqua, fondo negro y texto aqua.
	 * - **'light contorno':** Contorno blanco, fondo negro y texto blanco.
	 * - **'dark contorno':** Contorno negro, fondo blanco y texto negro.
	 * - **'link':** Formato de hyper enlace.
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > (iconoIzquierda != undefined || textoIzquierda != undefined) && eventoBotonIzquierda != undefined
	 */
	claseBotonIzquierda?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'danger'
		| 'warning'
		| 'info'
		| 'light'
		| 'dark'
		| 'primary contorno'
		| 'secondary contorno'
		| 'success contorno'
		| 'danger contorno'
		| 'warning contorno'
		| 'info contorno'
		| 'light contorno'
		| 'dark contorno'
		| 'link';
	/**
	 * Etiqueta que se mostrará sobre el input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	etiqueta?: string;
	/**
	 * Evento que se ejecuta al presionar el botón ubicado a la derecha del campo.
	 * - ***evento*** - Evento que invoca la función.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > iconoDerecha != undefined || textoDerecha != undefined
	 */
	eventoBotonDerecha?: (evento: MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Evento que se ejecuta al presionar el botón ubicado a la izquierda del campo.
	 * - ***evento*** - Evento que invoca la función.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > iconoIzquierda != undefined || textoIzquierda != undefined
	 */
	eventoBotonIzquierda?: (evento: MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Evento que se ejecuta al seleccionar una fecha.
	 * - ***evento*** - Evento que invoca la función.
	 */
	eventoCambio?: (evento: any) => void;
	/**
	 * Fecha inicial del campo.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **single:** Una sola fecha.
	 * > - 'yyyy-mm-dd'
	 *
	 * **multiple:** Múltiples fechas.
	 * > - ['yyyy-mm-dd', 'yyyy-mm-dd', 'yyyy-mm-dd']
	 *
	 * **range:** Un rango de fechas.
	 * > - ['yyyy-mm-dd', 'yyyy-mm-dd']
	 */
	fechaInicial?: Date | string | Array<Date> | Array<string>;
	/**
	 * Formato con el que se mostrará la fecha.
	 *
	 * > ***Predeterminado:*** *'d-m-Y'*
	 * > - **d:** Día del mes a 2 dígitos y rellenando con ceros *01-31*.
	 * > - **D:** Día de la semana *Lun-Dom*.
	 * > - **l:** *(L Minúscula)* Día de la semana completo *Lunes-Domingo*.
	 * > - **j:** Día del mes sin rellenar con ceros *1-31*.
	 * > - **J:** Día del mes sin rellenar con ceros y con sufijo ordinal *1º-31º*.
	 * > - **w:** Día de la semana a 1 dígito *0 (Domingo) - 6 (Sábado)*.
	 * > - **W:** Semana del año representada con un número *1-52*.
	 * > - **F:** Nombre del mes completo *Enero-Diciembre*.
	 * > - **m:** Mes a 2 dígitos y rellenando con ceros *01-12*.
	 * > - **n:** Mes sin rellenar con ceros *1-12*.
	 * > - **M:** Nombre del mes *Ene-Dic*.
	 * > - **U:** Número de segundos desde la época de Unix *1413704993*
	 * > - **y:** Año a 2 dígitos *99 o 03*.
	 * > - **Y:** Año a 4 dígitos *1999 o 2003*.
	 * > - **Z:** Formato de fecha ISO *2021-05-03T21:32:43.000Z*.
	 */
	formatoFecha?: string;
	/**
	 * Formato con el que se mostrará la fecha en el input, pero el valor de retorno al servidor será diferente (formatoFecha).
	 *
	 * > ***Predeterminado:*** *undefined*
	 * > - **d:** Día del mes a 2 dígitos y rellenando con ceros *01-31*.
	 * > - **D:** Día de la semana *Lun-Dom*.
	 * > - **l:** *(L Minúscula)* Día de la semana completo *Lunes-Domingo*.
	 * > - **j:** Día del mes sin rellenar con ceros *1-31*.
	 * > - **J:** Día del mes sin rellenar con ceros y con sufijo ordinal *1º-31º*.
	 * > - **w:** Día de la semana a 1 dígito *0 (Domingo) - 6 (Sábado)*.
	 * > - **W:** Semana del año representada con un número *1-52*.
	 * > - **F:** Nombre del mes completo *Enero-Diciembre*.
	 * > - **m:** Mes a 2 dígitos y rellenando con ceros *01-12*.
	 * > - **n:** Mes sin rellenar con ceros *1-12*.
	 * > - **M:** Nombre del mes *Ene-Dic*.
	 * > - **U:** Número de segundos desde la época de Unix *1413704993*
	 * > - **y:** Año a 2 dígitos *99 o 03*.
	 * > - **Y:** Año a 4 dígitos *1999 o 2003*.
	 * > - **Z:** Formato de fecha ISO *2021-05-03T21:32:43.000Z*.
	 */
	formatoFechaAlternativo?: string;
	/**
	 * Icono *FontAwesome* que se mostrará a la derecha del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Ejemplo:** *'fa-solid fa-info-circle'*
	 */
	iconoDerecha?: string;
	/**
	 * Icono *FontAwesome* que se mostrará junto a la etiqueta si hay información para mostrar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-info-circle'*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > informacion != undefined
	 */
	iconoInformacion?: string;
	/**
	 * Icono *FontAwesome* que se mostrará a la izquierda del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Ejemplo:** *'fa-solid fa-info-circle'*
	 */
	iconoIzquierda?: string;
	/**
	 * Identificador único del componente.
	 */
	id: string;
	/**
	 * Contenido que se mostrará al colocar el cursor en el icono de información.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	informacion?: any;
	/**
	 * Indica si el campo es obligatorio.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	obligatorio?: boolean;
	/**
	 * Texto que se mostrará en el input cuando este vacío.
	 *
	 * > ***Predeterminado:*** *'dd-mm-yyyy'*
	 */
	placeholder?: string;
	/**
	 * Indica si el input mostrará colores de validación*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	sinValidacion?: boolean;
	/**
	 * Indica si el input será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
	/**
	 * Objeto con los textos personalizados del componente.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	texto?: McCampoFechaTexto;
	/**
	 * Texto que se mostrará a la derecha del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	textoDerecha?: string;
	/**
	 * Texto que se mostrará a la izquierda del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	textoIzquierda?: string;
	/**
	 * Tipo de campo de fecha.
	 *
	 * > ***Predeterminado:*** *'single'*
	 * > - **'multiple':** Se podrán seleccionar varias fechas a la vez.
	 * > - **'single':** Se podrá seleccionar solo una fecha.
	 * > - **'range':** Se podrá seleccionar un rango de fechas.
	 */
	tipo?: 'multiple' | 'single' | 'range';
}

interface McCampoFechaTexto {
	/**
	 * Texto que se mostrará al indicar que el campo es obligatorio.
	 *
	 * > ***Predeterminado:*** *'Obligatorio'*
	 */
	errorObligatorio?: string;
}

/**
 * Input de tipo fecha que muestra un calendario.
 */
export const McCampoFecha = (props: McCampoFechaProps): JSX.Element => {
	const [mostrarTooltip, setMostrarTooltip] = useState(false);
	const {
		campo,
		claseBotonDerecha = 'primary',
		claseBotonIzquierda = 'primary',
		etiqueta,
		eventoBotonDerecha,
		eventoBotonIzquierda,
		eventoCambio,
		fechaInicial,
		formatoFecha = 'd-m-Y',
		formatoFechaAlternativo,
		iconoDerecha,
		iconoInformacion = 'fa-solid fa-info-circle',
		iconoIzquierda,
		id,
		informacion,
		obligatorio = false,
		placeholder = 'dd-mm-yyyy',
		sinValidacion = false,
		soloLectura = false,
		textoDerecha,
		textoIzquierda,
		tipo = 'single'
	} = props;
	let { texto } = props;
	texto = { ...TEXTO, ...texto };

	/**
	 * Indica si la variable recibida tiene un valor (No es *null*, *undefined* ni cadena vacía).
	 * - ***valor*** - Valor a analizar.
	 */
	const tieneValor = (valor: any): boolean => {
		if (valor === undefined) {
			return false;
		}
		if (valor === null) {
			return false;
		}
		if (valor === '') {
			return false;
		}
		return true;
	};

	/**
	 * Válida el input.
	 * - ***eventoParametro*** - Evento que ejecuta la función.
	 */
	const validarCampo = async ({ meta, valor, valores }: { meta: FieldMetaState<string>; valor: string; valores?: any }): Promise<string | undefined | void> => {
		if (obligatorio && !tieneValor(valor)) {
			return texto?.errorObligatorio;
		}
	};

	const CLASES_BOTON = {
		danger: 'btn btn-danger',
		'danger contorno': 'btn btn-outline-danger',
		dark: 'btn btn-dark',
		'dark contorno': 'btn btn-outline-dark',
		info: 'btn btn-info',
		'info contorno': 'btn btn-outline-info',
		light: 'btn btn-light',
		'light contorno': 'btn btn-outline-light',
		link: 'btn btn-link',
		primary: 'btn btn-primary',
		'primary contorno': 'btn btn-outline-primary',
		secondary: 'btn btn-secondary',
		'secondary contorno': 'btn btn-outline-secondary',
		success: 'btn btn-success',
		'success contorno': 'btn btn-outline-success',
		warning: 'btn btn-warning',
		'warning contorno': 'btn btn-outline-warning'
	};

	const claseBotonDerechaOriginal = CLASES_BOTON[claseBotonDerecha];
	const claseBotonIzquierdaOriginal = CLASES_BOTON[claseBotonIzquierda];

	return (
		<Field
			name={campo}
			parse={(valor) => {
				if (!valor) {
					return null as any;
				} else {
					return valor;
				}
			}}
			validate={(valor: string, valores?: any, meta?: any) => validarCampo({ meta, valor, valores })}
		>
			{({ input, meta }) => {
				const valido = !meta.error && meta.touched;
				const esInvalido = meta.error;
				let fechaPredeterminada = undefined;
				if (fechaInicial && !fechaInicializada) {
					if (Array.isArray(fechaInicial)) {
						const arregloFechas: Array<Date> = [];
						fechaInicial.forEach((fecha: Date | string) => {
							if (typeof fecha === 'string') {
								arregloFechas.push(new Date(`${fecha}T00:00:00`));
							} else {
								arregloFechas.push(new Date(fecha));
							}
						});
						fechaPredeterminada = arregloFechas;
					} else {
						if (typeof fechaInicial === 'string') {
							if (fechaInicial.indexOf('T') > 0) {
								fechaPredeterminada = new Date(fechaInicial);
							} else {
								fechaPredeterminada = new Date(`${fechaInicial}T00:00:00`);
							}
						} else {
							fechaPredeterminada = new Date(fechaInicial);
						}
					}
					fechaInicializada = true;
				}
				const eventoFocusNuevo = (evento: FocusEvent<HTMLInputElement>) => {
					input.onFocus(evento);
					evento.target.classList.add('mc-campo-fecha__input--focused');
					if (fechaInicializada && !sinValidacion) {
						evento.target.classList.add('is-valid');
						evento.target.classList.remove('is-invalid');
					} else if (!sinValidacion) {
						evento.target.classList.add('is-invalid');
						evento.target.classList.remove('is-valid');
					}
				};
				const eventoBlurNuevo = (evento: FocusEvent<HTMLElement>) => {
					input.onBlur(evento);
					evento.target.classList.remove('mc-campo-fecha__input--focused');
					if (fechaInicializada && !sinValidacion) {
						evento.target.classList.add('is-valid');
						evento.target.classList.remove('is-invalid');
					} else if (!sinValidacion) {
						evento.target.classList.add('is-invalid');
						evento.target.classList.remove('is-valid');
					}
				};
				const eventoCambioNuevo = (evento: any) => {
					if (Array.isArray(evento)) {
						if (evento.length === 0) {
							// Se elimino la fecha y el componente regresa un arreglo vacio, le enviamos un null para obtener el error de campos obligatorios
							input.onChange(null);
						} else {
							input.onChange(evento);
						}
					} else {
						input.onChange(evento);
					}
					if (eventoCambio) {
						eventoCambio(evento);
					}
				};
				const inputElemento = document.getElementById(id);
				if (inputElemento) {
					if (valido && !sinValidacion) {
						(inputElemento as any).style.borderColor = '#34c38f';
						inputElemento.classList.add('is-valid');
						inputElemento.classList.remove('is-invalid');
					} else if (esInvalido && !sinValidacion) {
						(inputElemento as any).style.borderColor = '#f46a6a';
						inputElemento.classList.remove('is-valid');
						inputElemento.classList.add('is-invalid');
					} else {
						(inputElemento as any).style.borderColor = '#ced4da';
						inputElemento.classList.remove('is-valid');
						inputElemento.classList.remove('is-invalid');
					}
				}
				return (
					<Fragment>
						{informacion && (
							<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
								{informacion}
							</Tooltip>
						)}
						{etiqueta && (
							<label className="form-label" htmlFor={id}>
								{etiqueta}
							</label>
						)}
						{informacion && (
							<span className="text-primary">
								{' '}
								<i className={iconoInformacion} id={`${id}_tooltip`}></i>
							</span>
						)}
						<div className="input-group" style={{ marginBottom: '10px' }}>
							{(iconoIzquierda || textoIzquierda) && !eventoBotonIzquierda && (
								<span className="input-group-text">
									<i className={iconoIzquierda}></i>&nbsp;{textoIzquierda}
								</span>
							)}
							{(iconoIzquierda || textoIzquierda) && eventoBotonIzquierda && (
								<button
									className={claseBotonIzquierdaOriginal}
									onClick={(evento: MouseEvent<HTMLButtonElement>) => {
										eventoBotonIzquierda(evento);
									}}
								>
									<i className={iconoIzquierda}></i>&nbsp;{textoIzquierda}
								</button>
							)}
							<Flatpickr
								className={`form-control${valido && !sinValidacion ? ' is-valid' : ''}${esInvalido && !sinValidacion ? ' is-invalid' : ''}`}
								disabled={soloLectura}
								id={id}
								options={{
									altFormat: formatoFechaAlternativo,
									altInput: formatoFechaAlternativo ? true : false,
									dateFormat: formatoFecha,
									defaultDate: fechaPredeterminada,
									mode: tipo
								}}
								placeholder={placeholder}
								style={soloLectura ? { backgroundColor: '#d6d5d4' } : {}}
								{...input}
								onBlur={eventoBlurNuevo}
								onChange={eventoCambioNuevo}
								onFocus={eventoFocusNuevo}
							/>
							{(iconoDerecha || textoDerecha) && !eventoBotonDerecha && (
								<span className="input-group-text">
									<i className={iconoDerecha}></i>&nbsp;{textoDerecha}
								</span>
							)}
							{(iconoDerecha || textoDerecha) && eventoBotonDerecha && (
								<button
									className={claseBotonDerechaOriginal}
									onClick={(evento: MouseEvent<HTMLButtonElement>) => {
										eventoBotonDerecha(evento);
									}}
								>
									<i className={iconoDerecha}></i>&nbsp;{textoDerecha}
								</button>
							)}
						</div>
						{esInvalido && (
							<div className="form-text" style={{ color: '#f46a6a' }}>
								{meta.error}
							</div>
						)}
					</Fragment>
				);
			}}
		</Field>
	);
};
