import { Card, CardBody, CardHeader, Col, Modal, Row } from 'reactstrap';
import { eliminarPerito, obtenerPeritoPorId, validarPeritoEliminacion } from 'servicios/api/peritos';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import Perito from 'modelo/Perito';
import PeritoTarjeta from 'componentes/peritos/PeritoTarjeta';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { useEliminarPeritoLicencia } from 'hooks/useImagen';
import { useEsSeccionAdministracion } from 'hooks/useSeccion';

const NOMBRE_CLASE = 'paginas/app/peritos/PeritoDetalles';

/**
 * En esta página se muestra la información detallada un perito.
 */
const Pagina = () => {
	const history = useHistory();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const dispatch = useDispatch();
	const eliminarPeritoLicencia = useEliminarPeritoLicencia();
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.peritos);
	const { auth0AccessToken } = auth0;
	const { id } = useParams<any>();
	const [registro, setRegistro] = useState<Perito>();
	const [sePuedeEliminar, setSePuedeEliminar] = useState<boolean>(false);
	const [modalEliminar, setModalEliminar] = useState<boolean>(false);
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [rutaSalida, setRutaSalida] = useState<string>(
		`${constantes.ruta.appPeritosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`
	);
	const [enlaces, setEnlaces] = useState<any>([]);

	useEffect(() => {
		obtenerRegistro();
		obtenerVariablesDeSeccion();
	}, []);

	/**
	 * Dibuja el modal de eliminación.
	 */
	const dibujarModalEliminar = () => (
		<Modal
			centered={true}
			isOpen={modalEliminar}
			toggle={() => {
				setModalEliminar(modalEliminar);
			}}
		>
			<div className="modal-header border-danger bg-danger">
				<h5 className="modal-title mt-0 text-white">{texto('Eliminar Perito')}</h5>
			</div>
			<div className="modal-body border-danger">
				<p>
					{texto('Se eliminará el perito, esta operación ')}
					<b>{texto('no puede deshacerse.')}</b>
				</p>
				<p>{texto('¿Estas seguro de continuar con la eliminación del perito?')}</p>
			</div>
			<BarraHerramientas>
				<button
					className="btn btn-secondary"
					id="botonCancelar"
					onClick={() => {
						setModalEliminar(false);
					}}
					type="button"
				>
					<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
				</button>
				<button className="btn btn-danger" id="botonEliminar" onClick={eventoEliminar} type="button">
					<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
				</button>
			</BarraHerramientas>
		</Modal>
	);

	/**
	 * Redirecciona al perito a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Redirecciona al perito a la ruta de edición de la foto de perfil.
	 */
	const eventoEditarFoto = () => {
		const nombreMetodo = 'eventoEditarFoto';
		let ruta;
		if (esSeccionAdministracion()) {
			ruta = `${constantes.ruta.appAdministracionPeritosLicenciaEditar}/${id}`;
		} else {
			ruta = `${constantes.ruta.appPeritosLicenciaEditar}/${id}`;
		}
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	};

	/**
	 * Redirecciona al perito a la ruta de edición.
	 */
	const eventoEditarDetalles = () => {
		const nombreMetodo = 'eventoEditarDetalles';
		let ruta;
		if (esSeccionAdministracion()) {
			ruta = `${constantes.ruta.appAdministracionPeritosDetallesEditar}/${id}`;
		} else {
			ruta = `${constantes.ruta.appPeritosDetallesEditar}/${id}`;
		}
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	};

	/**
	 * Elimina el registro.
	 */
	const eventoEliminar = async () => {
		const nombreMetodo = 'eventoEliminar';
		mcLogger.log({ mensaje: `Eliminando el perito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		dispatch(setPantallaCargaMostrarAction(true));
		setModalEliminar(false);
		if (registro?.licenciaFoto) {
			await eliminarPeritoLicencia(registro.licenciaFoto);
		}
		await eliminarPerito({ auth0AccessToken, id });
		mcNotificaciones.exito({ mensaje: texto('Perito eliminado con exito.') });
		dispatch(setPantallaCargaMostrarAction(false));
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Obteniendo información del perito...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			const respuesta = await obtenerPeritoPorId({ auth0AccessToken, id });
			mcLogger.log({ mensaje: `Información del perito obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const { datos } = respuesta;
			const sePuedeEliminar = await validarPeritoEliminacion({ auth0AccessToken, id: datos.id });
			setSePuedeEliminar(sePuedeEliminar);
			setRegistro(datos);
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener el perito: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el título de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionAdministracion()) {
			setTituloSeccion(texto('Detalles del perito'));
			const rutaSalida = `${constantes.ruta.appAdministracionPeritosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Lista de peritos') }
			]);
		} else {
			setTituloSeccion(texto('Detalles de mi perito'));
			const rutaSalida = `${constantes.ruta.appPeritosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Mis peritos') }
			]);
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={constantes.icono.perito} titulo={tituloSeccion} />
			{registro && (
				<Card>
					<CardHeader>
						<BarraHerramientas>
							{((esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionPeritosEliminar, usuario })) ||
								(!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appPeritosEliminar, usuario }))) &&
								sePuedeEliminar && (
									<button
										className="btn btn-danger"
										id="botonEliminarPerito"
										onClick={() => {
											setModalEliminar(true);
										}}
										type="button"
									>
										<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
									</button>
								)}
							{dibujarModalEliminar()}
							{((esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionPeritosEditarDetalles, usuario })) ||
								(!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appPeritosEditarDetalles, usuario }))) && (
								<button className="btn btn-info" id="botonEditarDetalles" onClick={eventoEditarDetalles} type="button">
									<i className={constantes.icono.editar}></i> {texto('Editar detalles')}
								</button>
							)}
							{((esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionPeritosEditarLicencia, usuario })) ||
								(!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appPeritosEditarLicencia, usuario }))) && (
								<button className="btn btn-info" id="botonEditarFoto" onClick={eventoEditarFoto} type="button">
									<i className={constantes.icono.licencia}></i> {texto('Editar imagen de la licencia')}
								</button>
							)}
							<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
								<i className={constantes.icono.atras}></i> {texto('Salir')}
							</button>
						</BarraHerramientas>
					</CardHeader>
					<CardBody>
						<Row>
							<Col md="12">{registro && <PeritoTarjeta registro={registro} />}</Col>
						</Row>
					</CardBody>
				</Card>
			)}
		</Contenedor>
	);
};

export default Pagina;
