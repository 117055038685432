import { McCampoSelectorMultipleOpcion } from '@mcsoft/formulario';
import Pais from 'modelo/Pais';

/**
 * Formatea una lista de paises para utilizarse como lista de opciones en un McCampoSelectorMultiple.
 * - ***lista*** - Arreglo con la lista de paises.
 */
export const formatearListaPaisesMcCampoSelectorMultiple = (lista?: Array<Pais>): Array<McCampoSelectorMultipleOpcion> => {
	const listaOpciones: Array<McCampoSelectorMultipleOpcion> = [];
	if (lista) {
		lista.forEach((elemento: Pais) => {
			listaOpciones.push({ nombre: elemento.nombre, valor: elemento.id });
		});
	}
	return listaOpciones;
};
