import { Col, Row } from 'reactstrap';
import { convertirNumeroALetras, convertirNumeroEnteroANumeroRomano } from '@mcsoft/numeros';
import Avaluo from 'modelo/Avaluo';
import { Fragment } from 'react';
import { texto } from 'idiomas';
import useDecimal from 'hooks/useDecimal';
import useMoneda from 'hooks/useMoneda';

interface AvaluoDetallesAvaluoFisicoProps {
	/**
	 * Objeto con la información del avalúo.
	 */
	avaluo: Avaluo;
}

/**
 * Detalles para la sección avalúo físico del avalúo.
 */
const AvaluoDetallesAvaluoFisico = (props: AvaluoDetallesAvaluoFisicoProps) => {
	const decimal = useDecimal();
	const moneda = useMoneda();
	const { avaluo } = props;
	if (avaluo && avaluo.construcciones && avaluo.instalacionesEspeciales && avaluo.terreno && avaluo.observaciones) {
		const { valorTotal } = avaluo;
		const { descripcion, valoresReferidos } = avaluo.observaciones;
		const {
			detalles: detallesConstrucciones,
			indiviso: indivisoConstrucciones,
			superficieTotal: superficieTotalConstrucciones,
			superficieUnidad: superficieUnidadConstrucciones,
			valor: valorConstrucciones,
			valorTotal: valorTotalConstrucciones
		} = avaluo.construcciones;
		const {
			detalles: detallesInstalacionesEspeciales,
			indiviso: indivisoInstalacionesEspeciales,
			superficieTotal: superficieTotalInstalacionesEspeciales,
			superficieUnidad: superficieUnidadInstalacionesEspeciales,
			valor: valorInstalacionesEspeciales,
			valorTotal: valorTotalInstalacionesEspeciales
		} = avaluo.instalacionesEspeciales;
		const {
			detalles: detallesTerreno,
			indiviso: indivisoTerreno,
			superficieTotal: superficieTotalTerreno,
			superficieUnidad: superficieUnidadTerreno,
			valor: valorTerreno,
			valorTotal: valorTotalTerreno
		} = avaluo.terreno;

		return (
			<Fragment>
				<Row>
					<Col lg="12">
						<h3>{texto('Avalúo Físico')}</h3>
						<hr />
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<h5>{texto('A) Del Terreno')}</h5>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<div className="border-responsive">
							<table className="table table-bordered">
								<thead>
									<tr className="avaluo-detalles__tabla-encabezado">
										<th>{texto('Fracción')}</th>
										<th>{texto('Superficie')}</th>
										<th>{texto('Valor Unitario')}</th>
										<th>{texto('Factor')}</th>
										<th>{texto('Motivo')}</th>
										<th>{texto('Valor Neto')}</th>
										<th>{texto('Valor Resultante')}</th>
									</tr>
								</thead>
								<tbody>
									{detallesTerreno.length > 0 &&
										detallesTerreno.map((detalle) => (
											<tr className="avaluo-detalles__tabla-celda__centrada" key={detalle.id}>
												<td>{convertirNumeroEnteroANumeroRomano(detalle.fraccion)}</td>
												<td>{decimal(detalle.superficie)}</td>
												<td>{moneda(detalle.valorUnitario)}</td>
												<td>{decimal(detalle.ajusteFactor)}</td>
												<td>{detalle.ajusteMotivo?.nombre}</td>
												<td>{moneda(detalle.valorNeto)}</td>
												<td>{moneda(detalle.valorResultante)}</td>
											</tr>
										))}
									{detallesTerreno.length === 0 && (
										<tr>
											<td className="avaluos-detalles-tabla__sin-detalles" colSpan={12}>
												{texto('Sin detalles.')}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
				{detallesTerreno.length > 0 && (
					<Row>
						<Col lg="12">
							<table className="table-nowrap table-centered mb-0 table">
								<tbody>
									<tr>
										<th className="text-end">{texto('Superficie total')}:</th>
										<td className="text-end" style={{ width: '200px' }}>
											{`${decimal(Number(superficieTotalTerreno))} ${superficieUnidadTerreno?.simbolo || ''}`}
										</td>
									</tr>
									<tr>
										<th className="border-0 text-end">{texto('Valor del terreno')}:</th>
										<td className="border-0 text-end">{moneda(Number(valorTerreno))}</td>
									</tr>
									<tr>
										<th className="border-0 text-end">{texto('Indiviso')}:</th>
										<td className="border-0 text-end">{`${decimal(Number(indivisoTerreno))} %`}</td>
									</tr>
									<tr>
										<th className="border-0 text-end">{texto('Valor total')}:</th>
										<td className="border-0 text-end">
											<b className="m-0">{moneda(Number(valorTotalTerreno))}</b>
										</td>
									</tr>
								</tbody>
							</table>
						</Col>
					</Row>
				)}
				<Row>
					<Col lg="12">
						<hr />
						<h5>{texto('B) De las Construcciones')}</h5>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<div className="border-responsive">
							<table className="table table-bordered">
								<thead>
									<tr className="avaluo-detalles__tabla-encabezado">
										<th>{texto('Clasificación Código')}</th>
										<th>{texto('Superficie')}</th>
										<th>{texto('Valor Unitario')}</th>
										<th>{texto('Edad')}</th>
										<th>{texto('Factor')}</th>
										<th>{texto('Motivo')}</th>
										<th>{texto('Valor Resultante')}</th>
									</tr>
								</thead>
								<tbody>
									{detallesConstrucciones.length > 0 &&
										detallesConstrucciones.map((detalle) => (
											<tr className="avaluo-detalles__tabla-celda__centrada" key={detalle.id}>
												<td>{detalle.codigoClasificación}</td>
												<td>{decimal(detalle.superficie)}</td>
												<td>{moneda(detalle.valorUnitario)}</td>
												<td>{detalle.edad}</td>
												<td>{decimal(detalle.ajusteFactor)}</td>
												<td>{detalle.ajusteMotivo?.nombre}</td>
												<td>{moneda(detalle.valorResultante)}</td>
											</tr>
										))}
									{detallesConstrucciones.length === 0 && (
										<tr>
											<td className="avaluos-detalles-tabla__sin-detalles" colSpan={12}>
												{texto('Sin detalles.')}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
				{detallesConstrucciones.length > 0 && (
					<Row>
						<Col lg="12">
							<div className="border-responsive">
								<table className="table table-bordered">
									<tbody>
										<tr>
											<th className="text-end">{texto('Superficie total')}:</th>
											<td className="text-end" style={{ width: '200px' }}>
												{`${decimal(Number(superficieTotalConstrucciones))} ${superficieUnidadConstrucciones?.simbolo || ''}`}
											</td>
										</tr>
										<tr>
											<th className="border-0 text-end">{texto('Valor del terreno')}:</th>
											<td className="border-0 text-end">{moneda(Number(valorConstrucciones))}</td>
										</tr>
										<tr>
											<th className="border-0 text-end">{texto('Indiviso')}:</th>
											<td className="border-0 text-end">{`${decimal(Number(indivisoConstrucciones))} %`}</td>
										</tr>
										<tr>
											<th className="border-0 text-end">{texto('Valor total')}:</th>
											<td className="border-0 text-end">
												<b className="m-0">{moneda(Number(valorTotalConstrucciones))}</b>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</Col>
					</Row>
				)}
				<Row>
					<Col lg="12">
						<hr />
						<h5>{texto('C) Instalaciones Especiales')}</h5>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<div className="border-responsive">
							<table className="table table-bordered">
								<thead>
									<tr className="avaluo-detalles__tabla-encabezado">
										<th>{texto('Clasificación Código')}</th>
										<th>{texto('Superficie')}</th>
										<th>{texto('Valor Unitario')}</th>
										<th>{texto('Edad')}</th>
										<th>{texto('Factor')}</th>
										<th>{texto('Motivo')}</th>
										<th>{texto('Valor Resultante')}</th>
									</tr>
								</thead>
								<tbody>
									{detallesInstalacionesEspeciales.length > 0 &&
										detallesInstalacionesEspeciales.map((detalle) => (
											<tr className="avaluo-detalles__tabla-celda__centrada" key={detalle.id}>
												<td>{detalle.codigoClasificación}</td>
												<td>{decimal(detalle.superficie)}</td>
												<td>{moneda(detalle.valorUnitario)}</td>
												<td>{detalle.edad}</td>
												<td>{decimal(detalle.ajusteFactor)}</td>
												<td>{detalle.ajusteMotivo?.nombre}</td>
												<td>{moneda(detalle.valorResultante)}</td>
											</tr>
										))}
									{detallesInstalacionesEspeciales.length === 0 && (
										<tr>
											<td className="avaluos-detalles-tabla__sin-detalles" colSpan={12}>
												{texto('Sin detalles.')}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
				{detallesInstalacionesEspeciales.length > 0 && (
					<Row>
						<Col lg="12">
							<div className="border-responsive">
								<table className="table table-bordered">
									<tbody>
										<tr>
											<th className="text-end">{texto('Superficie total')}:</th>
											<td className="text-end" style={{ width: '200px' }}>
												{`${decimal(Number(superficieTotalInstalacionesEspeciales))} ${superficieUnidadInstalacionesEspeciales?.simbolo || ''}`}
											</td>
										</tr>
										<tr>
											<th className="border-0 text-end">{texto('Valor del terreno')}:</th>
											<td className="border-0 text-end">{moneda(Number(valorInstalacionesEspeciales))}</td>
										</tr>
										<tr>
											<th className="border-0 text-end">{texto('Indiviso')}:</th>
											<td className="border-0 text-end">{`${decimal(Number(indivisoInstalacionesEspeciales))} %`}</td>
										</tr>
										<tr>
											<th className="border-0 text-end">{texto('Valor total')}:</th>
											<td className="border-0 text-end">
												<b className="m-0">{moneda(Number(valorTotalInstalacionesEspeciales))}</b>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</Col>
					</Row>
				)}
				<Row>
					<Col lg="12">
						<div className="border-responsive">
							<table className="table table-bordered">
								<tbody>
									<tr>
										<th className="border-0 text-end">{texto('Valor total del terreno, construcciones e instalaciones especiales')}:</th>
										<td className="border-0 text-end">
											<h4 className="mb-3">{moneda(Number(valorTotal))}</h4>
										</td>
									</tr>
									<tr>
										<th className="avaluo-detalles__tabla-celda__centrada" colSpan={2}>
											{`${convertirNumeroALetras({ cantidad: valorTotal })} MONEDA NACIONAL`}
										</th>
									</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<div className="border-responsive">
							<table className="table table-bordered">
								<tbody>
									<tr>
										<th className="border-0" style={{ width: '150px' }}>
											{texto('Valores referidos')}:
										</th>
										<td className="border-0">{valoresReferidos?.toString()}</td>
									</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<div className="border-responsive">
							<table className="table table-bordered">
								<tbody>
									<tr>
										<th className="valuo-detalles__tabla-celda__centrada">{texto('Observaciones')}:</th>
									</tr>
									<tr>
										<th className="border-0">{descripcion}</th>
									</tr>
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			</Fragment>
		);
	} else {
		return null;
	}
};

export default AvaluoDetallesAvaluoFisico;
