import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { McRespuesta, procesarError } from '@mcsoft/api';
import ConfiguracionSistema from 'modelo/ConfiguracionSistema';
import constantes from 'configuracion/constantes';
import { GET_CONFIGURACION_SISTEMA } from './actionTypes';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { obtenerConfiguracionSistema } from 'servicios/api/configuracionSistema';
import { ReduxAccion } from 'store/actions';
import { setConfiguracionSistemaAction } from './actions';

const NOMBRE_CLASE = 'store/configuracionSistema/saga';

function* getConfiguracionSistemaSaga(action: ReduxAccion) {
	const nombreMetodo = 'getConfiguracionSistemaSaga';
	const { auth0AccessToken } = action.payload;
	let respuesta: McRespuesta;
	try {
		mcLogger.dev({ mensaje: `Obteniendo la configuración del sistema`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		respuesta = yield call(obtenerConfiguracionSistema, auth0AccessToken);
		const configuracionSistema: ConfiguracionSistema = respuesta.datos;
		yield put(setConfiguracionSistemaAction(configuracionSistema));
	} catch (error) {
		const mcError = procesarError(error);
		mcLogger.error({ mensaje: `Error al obtener la configuración del sistema de la base de datos:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.datos });
		mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		const rutaDestino = constantes.ruta.webInicio;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaDestino });
		window.location.replace(rutaDestino);
	}
}

export function* getConfiguracionSistemaWatcher() {
	yield takeEvery(GET_CONFIGURACION_SISTEMA, getConfiguracionSistemaSaga);
}

function* Saga() {
	yield all([fork(getConfiguracionSistemaWatcher)]);
}

export default Saga;
