import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import InformacionDecimalLocale from 'componentes/informacion/InformacionDecimalLocale';
import InformacionMomentFechaFormato from 'componentes/informacion/InformacionMomentFechaFormato';
import InformacionMomentFechaLocale from 'componentes/informacion/InformacionMomentFechaLocale';
import InformacionMomentFechaZona from 'componentes/informacion/InformacionMomentFechaZona';
import InformacionMonedaCodigo from 'componentes/informacion/InformacionMonedaCodigo';
import InformacionMonedaLocale from 'componentes/informacion/InformacionMonedaLocale';
import InformacionSelectorFechaFormato from 'componentes/informacion/InformacionSelectorFechaFormato';
import { McCampoTexto } from '@mcsoft/formulario';
import { texto } from 'idiomas';

/**
 * Formulario para la configuración general de la aplicación.
 */
const AplicacionFormularioGeneral = () => (
	<Row>
		<Col lg="12">
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.configuracionAplicacionGeneral}></i> {texto('General')}
					</h3>
					<p>{texto('Aquí se configuran los ajustes generales de la aplicación.')}</p>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col lg="4">
					<McCampoTexto
						campo="selectorFechaFormato"
						etiqueta={texto('Formato de la fecha del selector')}
						id="selectorFechaFormato"
						informacion={<InformacionSelectorFechaFormato />}
						longitudMaxima={10}
						obligatorio
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoTexto
						campo="momentFechaFormato"
						etiqueta={texto('Formato de la fecha')}
						id="momentFechaFormato"
						informacion={<InformacionMomentFechaFormato />}
						longitudMaxima={50}
						obligatorio
					/>
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="momentFechaLocale"
						etiqueta={texto('Locale de la fecha')}
						id="momentFechaLocale"
						informacion={<InformacionMomentFechaLocale />}
						longitudMaxima={10}
						obligatorio
					/>
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="momentFechaZona"
						etiqueta={texto('Zona horaria')}
						id="momentFechaZona"
						informacion={<InformacionMomentFechaZona />}
						longitudMaxima={100}
						obligatorio
						textoDerecha={
							<a href="https://momentjs.com/timezone/" rel="noreferrer" target="_blank">
								{texto('Encuentra tu zona')}
							</a>
						}
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoTexto campo="monedaCodigo" etiqueta={texto('Código de la moneda')} id="monedaCodigo" informacion={<InformacionMonedaCodigo />} longitudMaxima={10} obligatorio />
				</Col>
				<Col lg="4">
					<McCampoTexto campo="monedaLocale" etiqueta={texto('Locale de la moneda')} id="monedaLocale" informacion={<InformacionMonedaLocale />} longitudMaxima={10} obligatorio />
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="decimalLocale"
						etiqueta={texto('Locale de los decimales')}
						id="decimalLocale"
						informacion={<InformacionDecimalLocale />}
						longitudMaxima={10}
						obligatorio
					/>
				</Col>
			</Row>
		</Col>
	</Row>
);

export default AplicacionFormularioGeneral;
