import { McRespuesta, procesarError, procesarRespuesta } from '@mcsoft/api';
import aplicacion from 'configuracion/aplicacion';
import axios from 'axios';
import mcLogger from '@mcsoft/logger';

const NOMBRE_CLASE = 'servicios/api/widgets';

/**
 * Obtiene la información para de la cantidad de avalúos.
 * - ***id?*** - Id del usuario (en caso de querer filtrar por usuario).
 * - ***periodo*** - Periodo de tiempo del que se requiere la información.
 */
export const obtenerCantidadAvaluos = ({ auth0AccessToken, id, periodo = 'siempre' }: { auth0AccessToken: string; id?: string; periodo: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerCantidadAvaluos';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo información de los avalúos de la base de datos${id ? ` para el usuario con el id '${id}.` : '.'}.`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.widgets}/cantidadAvaluos/${periodo}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({
					mensaje: `Información de los avalúos obtenida con éxito${id ? ` para el usuario con el id '${id}.` : '.'}.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcRespuesta
				});
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({
					mensaje: `Error al obtener la información de los avalúos${id ? ` para el usuario con el id '${id}.` : '.'}.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcError
				});
				reject(mcError);
			});
	});
};

/**
 * Obtiene la información para de la cantidad de avalúos de cada municipio.
 * - ***id?*** - Id del usuario (en caso de querer filtrar por usuario).
 * - ***periodo*** - Periodo de tiempo del que se requiere la información.
 */
export const obtenerCantidadAvaluosPorMunicipio = ({
	auth0AccessToken,
	id,
	periodo = 'siempre'
}: {
	auth0AccessToken: string;
	id?: string;
	periodo: string;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerCantidadAvaluosPorMunicipio';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo información de los avalúos por municipio de la base de datos${id ? ` para el usuario con el id '${id}.` : '.'}.`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.widgets}/cantidadAvaluosPorMunicipio/${periodo}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({
					mensaje: `Información de los avalúos por municipio obtenida con éxito${id ? ` para el usuario con el id '${id}.` : '.'}.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcRespuesta
				});
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({
					mensaje: `Error al obtener la información de los avalúos por municipio${id ? ` para el usuario con el id '${id}.` : '.'}.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcError
				});
				reject(mcError);
			});
	});
};

/**
 * Obtiene la información para de la cantidad de avalúos de cada perito.
 * - ***id?*** - Id del usuario (en caso de querer filtrar por usuario).
 * - ***periodo*** - Periodo de tiempo del que se requiere la información.
 */
export const obtenerCantidadAvaluosPorPerito = ({
	auth0AccessToken,
	id,
	periodo = 'siempre'
}: {
	auth0AccessToken: string;
	id?: string;
	periodo: string;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerCantidadAvaluosPorPerito';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo información de los avalúos por perito de la base de datos${id ? ` para el usuario con el id '${id}.` : '.'}.`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.widgets}/cantidadAvaluosPorPerito/${periodo}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({
					mensaje: `Información de los avalúos por perito obtenida con éxito${id ? ` para el usuario con el id '${id}.` : '.'}.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcRespuesta
				});
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({
					mensaje: `Error al obtener la información de los avalúos por perito${id ? ` para el usuario con el id '${id}.` : '.'}.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcError
				});
				reject(mcError);
			});
	});
};

/**
 * Obtiene la información para de la cantidad de avalúos de cada usuario.
 * - ***periodo*** - Periodo de tiempo del que se requiere la información.
 */
export const obtenerCantidadAvaluosPorUsuario = ({ auth0AccessToken, periodo = 'siempre' }: { auth0AccessToken: string; periodo: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerCantidadAvaluosPorUsuario';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo información de los avalúos por usuario de la base de datos.`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.widgets}/cantidadAvaluosPorUsuario/${periodo}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({
					mensaje: `Información de los avalúos por usuario obtenida con éxito.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcRespuesta
				});
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({
					mensaje: `Error al obtener la información de los avalúos por usuario.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcError
				});
				reject(mcError);
			});
	});
};
