import { Col, Row } from 'reactstrap';
import constantes from 'configuracion/constantes';
import { McCampoTexto } from '@mcsoft/formulario';
import { texto } from 'idiomas';

/**
 * Formulario para la configuración de Amazon Web Services del sistema.
 */
const SistemaFormularioAws = () => (
	<Row>
		<Col lg="12">
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.aws}></i> {texto('Amazon Web Services')}
					</h3>
					<p>{texto('Aquí se configura todo lo relacionado con los servicios de Amazon Web Services.')}</p>
					<hr />
				</Col>
			</Row>
			<Row>
				<h4>
					<i className={constantes.icono.foto}></i> {texto('Fotos de perfil')}
				</h4>
				<Col lg="6">
					<McCampoTexto campo="awsS3BucketUsuarioFotos" etiqueta={texto('Nombre del bucket')} id="awsS3BucketUsuarioFotos" longitudMaxima={1000} obligatorio />
				</Col>
				<Col lg="6">
					<McCampoTexto
						campo="awsS3BucketUsuarioFotosMiniaturas"
						etiqueta={texto('Nombre del bucket de miniaturas')}
						id="awsS3BucketUsuarioFotosMiniaturas"
						longitudMaxima={1000}
						obligatorio
					/>
				</Col>
			</Row>
			<Row>
				<hr />
				<h4>
					<i className={constantes.icono.licencia}></i> {texto('Licencia de perito')}
				</h4>
				<Col lg="6">
					<McCampoTexto campo="awsS3BucketPeritoLicencia" etiqueta={texto('Nombre del bucket')} id="awsS3BucketPeritoLicencia" longitudMaxima={1000} obligatorio />
				</Col>
				<Col lg="6">
					<McCampoTexto
						campo="awsS3BucketPeritoLicenciaMiniaturas"
						etiqueta={texto('Nombre del bucket de miniaturas')}
						id="awsS3BucketPeritoLicenciaMiniaturas"
						longitudMaxima={1000}
						obligatorio
					/>
				</Col>
			</Row>
			<Row>
				<hr />
				<h4>
					<i className={constantes.icono.mapa}></i> {texto('Mapa y Croquis')}
				</h4>
				<Col lg="6">
					<McCampoTexto campo="awsS3BucketAvaluoMapas" etiqueta={texto('Nombre del bucket')} id="awsS3BucketAvaluoMapas" longitudMaxima={1000} obligatorio />
				</Col>
			</Row>
			<Row>
				<hr />
				<h4>
					<i className={constantes.icono.imagenes}></i> {texto('Fotos del avalúo')}
				</h4>
				<Col lg="6">
					<McCampoTexto campo="awsS3BucketAvaluoFotos" etiqueta={texto('Nombre del bucket')} id="awsS3BucketAvaluoFotos" longitudMaxima={1000} obligatorio />
				</Col>
				<Col lg="6">
					<McCampoTexto
						campo="awsS3BucketAvaluoFotosMiniaturas"
						etiqueta={texto('Nombre del bucket de miniaturas')}
						id="awsS3BucketAvaluoFotosMiniaturas"
						longitudMaxima={1000}
						obligatorio
					/>
				</Col>
			</Row>
		</Col>
	</Row>
);

export default SistemaFormularioAws;
