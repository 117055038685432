import { Link, useLocation } from 'react-router-dom';
import { setInterfazBarraNavegacionLateralMostrarAction, setSesionPreferenciasAtributosAction } from 'store/actions';
import { tieneAlMenosUnPermiso, tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { StateType } from 'store';

interface MenuBotonProps {
	/**
	 * Etiqueta que se mostrará en el botón.
	 */
	etiqueta: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	icono?: string;
	/**
	 * Identificador único del botón.
	 */
	id: string;
	/**
	 * Permiso necesario para ver el componente. Si se recibe un arreglo de permisos, el componente será visible si el usuario tiene al menos un permiso.
	 */
	permiso?: string | Array<string>;
	/**
	 * Ruta a la que se redirigirá al presionar el botón.
	 */
	ruta: string;
}

/**
 * Botón del menú.
 */
const MenuBoton = (props: MenuBotonProps) => {
	const dispatch = useDispatch();
	const { interfaz, sesion } = useSelector((state: StateType) => state);
	const { barraNavegacionLateralMostrar } = interfaz;
	const { usuario } = sesion;
	const { preferencias } = usuario;
	const { interfazBarraNavegacionLateralAncho } = preferencias;
	const [botonActivo, setBotonActivo] = useState(false);
	const [botonOculto, setBotonOculto] = useState(true);
	const location = useLocation();
	const { etiqueta, icono, id, permiso, ruta } = props;

	useEffect(() => {
		if (usuario && permiso) {
			if (Array.isArray(permiso)) {
				setBotonOculto(!tieneAlMenosUnPermiso({ permisos: permiso, usuario }));
			} else {
				setBotonOculto(!tienePermiso({ permiso, usuario }));
			}
		} else {
			setBotonOculto(false);
		}
	}, [permiso, usuario]);

	useEffect(() => {
		if (location.pathname.includes(ruta)) {
			setBotonActivo(true);
		} else {
			setBotonActivo(false);
		}
	}, [location.pathname]);

	useEffect(() => {
		if (botonActivo) {
			const li = document.getElementById(id);
			const ul = li?.parentElement;
			const grupo = ul?.parentElement;
			grupo?.classList.add('mm-active');
		}
	}, [botonActivo]);

	/**
	 * Oculta la barra de navegación.
	 */
	const eventoOcultarBarraNavegacionLateral = () => {
		dispatch(setInterfazBarraNavegacionLateralMostrarAction(!barraNavegacionLateralMostrar));
		if (interfazBarraNavegacionLateralAncho === 'condensed') {
			const nuevasPreferencias = {
				...preferencias,
				interfazBarraNavegacionLateralAncho: 'default'
			};
			dispatch(setSesionPreferenciasAtributosAction({ atributo: 'interfazBarraNavegacionLateralAncho', preferencias: nuevasPreferencias }));
		}
	};

	return (
		<li className={botonActivo ? 'mm-active' : ''} data-oculto={botonOculto} hidden={botonOculto} id={id}>
			<Link className={botonActivo ? 'waves-effect active' : 'waves-effect'} onClick={eventoOcultarBarraNavegacionLateral} to={ruta}>
				<i className={icono}></i>
				<span className="ms-1">{etiqueta}</span>
			</Link>
		</li>
	);
};

export default MenuBoton;
