import aplicacion from 'configuracion/aplicacion';
import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del avaluo instalaciones especiales.
 */
const InformacionAvaluoInstalacionesEspeciales = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Se copiará la información de las instalaciones especiales del avalúo original en el avalúo clonado.')}</p>
		</div>
		<div className="text-center mt-2 mb-2">
			<img alt="Ejemplo" src={aplicacion.tutoriales.ejemploAvaluoInstalacionesEspeciales} width="480px" />
		</div>
	</Fragment>
);

export default InformacionAvaluoInstalacionesEspeciales;
