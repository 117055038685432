import { actualizarUsuarioNotificacion, crearUsuarioNotificacion, eliminarUsuarioNotificacion, obtenerUsuarioNotificacionPorId } from 'servicios/api/usuariosNotificaciones';
import { Card, CardBody, CardFooter, CardHeader, Col, Modal, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEsSeccionCreacion, useEsSeccionEdicion } from 'hooks/useSeccion';
import { useHistory, useParams } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { mutatorCambiarValorCampo } from '@mcsoft/formulario';
import NotificacionFormulario from 'componentes/notificaciones/NotificacionFormulario';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { tieneValor } from '@mcsoft/validaciones';
import UsuarioNotificacionDetalle from 'modelo/UsuarioNotificacionDetalle';
import { v4 as uuidv4 } from 'uuid';
import { ValidationErrors } from 'final-form';

const NOMBRE_CLASE = 'paginas/app/catalogos/NotificacionDetallesEditar';

/**
 * En esta página se pueden editar los detalles de una notificación.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const esSeccionCreacion = useEsSeccionCreacion();
	const esSeccionEdicion = useEsSeccionEdicion();
	const { location } = history;
	const { id } = useParams<any>();
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.notificaciones);
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [formulario, setFormulario] = useState<any>();
	const [modalEliminar, setModalEliminar] = useState<boolean>(false);
	const RUTA_SALIR = `${constantes.ruta.appAdministracionNotificacionesLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, []);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, [location.pathname]);

	/**
	 * Dibuja el modal de eliminación.
	 */
	const dibujarModalEliminar = () => (
		<Modal
			centered={true}
			isOpen={modalEliminar}
			toggle={() => {
				setModalEliminar(modalEliminar);
			}}
		>
			<div className="modal-header border-danger bg-danger">
				<h5 className="modal-title mt-0 text-white">{texto('Eliminar Notificación')}</h5>
			</div>
			<div className="modal-body border-danger">
				<p>
					{texto('Se eliminará la notificación, esta operación ')}
					<b>{texto('no puede deshacerse.')}</b>
				</p>
				<p>{texto('¿Estas seguro de continuar con la eliminación de la notificación?')}</p>
			</div>
			<BarraHerramientas>
				<button
					className="btn btn-secondary"
					id="botonCancelar"
					onClick={() => {
						setModalEliminar(false);
					}}
					type="button"
				>
					<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
				</button>
				<button className="btn btn-danger" id="botonEliminar" onClick={eventoEliminar} type="button">
					<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
				</button>
			</BarraHerramientas>
		</Modal>
	);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: RUTA_SALIR });
		history.push(RUTA_SALIR);
	};

	/**
	 * Elimina el registro.
	 */
	const eventoEliminar = async () => {
		const nombreMetodo = 'eventoEliminar';
		mcLogger.log({ mensaje: `Eliminando la notificación.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		dispatch(setPantallaCargaMostrarAction(true));
		setModalEliminar(false);
		try {
			await eliminarUsuarioNotificacion({ auth0AccessToken, id });
			mcNotificaciones.exito({ mensaje: texto('Notificación eliminada con exito.') });
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: RUTA_SALIR });
			history.push(RUTA_SALIR);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Guarda la información del registro.
	 * - ***valores*** - Valores a guardar.
	 */
	const eventoGuardar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoGuardar';
		try {
			const registro = valores as UsuarioNotificacionDetalle;
			mcLogger.log({ mensaje: `Guardando la información de la notificación...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: registro });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				await crearUsuarioNotificacion({ auth0AccessToken, valores: registro });
				mcLogger.log({ mensaje: `Notificación creada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Notificación creada con éxito.`) });
			}
			if (esSeccionEdicion()) {
				await actualizarUsuarioNotificacion({ auth0AccessToken, id: registro.id, valores: registro });
				mcLogger.log({ mensaje: `Notificación actualizada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Notificación actualizada con éxito.`) });
			}
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: RUTA_SALIR });
			history.push(RUTA_SALIR);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la información de la notificación:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Válida el formulario de manera síncrona.
	 * - ***valores*** - Valores de los campos a validar.
	 */
	const eventoValidarFormulario = (valores: Record<string, any>): ValidationErrors => {
		const errores: ValidationErrors = { notificacion: {} };
		if (esSeccionCreacion() && !tieneValor(valores.destinatarioTipo)) {
			errores.destinatarioTipo = texto('Obligatorio');
		}
		if (valores.destinatarioTipo === 'usuarios' && !tieneValor(valores.destinatarioUsuarios)) {
			errores.destinatarioUsuarios = texto('Obligatorio');
		}
		if (valores.destinatarioTipo === 'roles' && !tieneValor(valores.destinatarioRoles)) {
			errores.destinatarioRoles = texto('Obligatorio');
		}
		return errores;
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Inicializando formulario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				const notificacionId = uuidv4();
				setFormulario({
					estatus: 'nueva',
					id: uuidv4(),
					notificacion: {
						iconoColorFondo: '#000000',
						id: notificacionId
					},
					notificacionId: notificacionId
				});
			}
			if (esSeccionEdicion()) {
				const respuesta = await obtenerUsuarioNotificacionPorId({ auth0AccessToken, id });
				const formulario = { ...respuesta.datos };
				setFormulario(formulario);
			}
			mcLogger.log({ mensaje: `Formulario inicializado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al inicializar formulario:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los valores de las variables que cambian en función de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionCreacion()) {
			setTituloSeccion(texto('Crear notificación'));
		}
		if (esSeccionEdicion()) {
			setTituloSeccion(texto('Editar notificación'));
		}
	};

	return (
		<Contenedor>
			<Breadcrumb
				enlaces={[
					{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
					{ ruta: RUTA_SALIR, titulo: texto('Lista de notificaciones') }
				]}
				icono={constantes.icono.notificacion}
				titulo={tituloSeccion}
			/>
			{formulario && (
				<Form
					initialValues={formulario}
					mutators={{
						cambiarValorCampo: mutatorCambiarValorCampo
					}}
					onSubmit={eventoGuardar}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<Card>
								<CardHeader>
									<BarraHerramientas>
										{esSeccionEdicion() && tienePermiso({ permiso: constantes.permiso.appAdministracionNotificacionesEliminar, usuario }) && (
											<button
												className="btn btn-danger"
												id="botonEliminarNotificacion"
												onClick={() => {
													setModalEliminar(true);
												}}
												type="button"
											>
												<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
											</button>
										)}
										{dibujarModalEliminar()}
									</BarraHerramientas>
								</CardHeader>
								<CardBody>
									<Row>
										<Col md="12">
											<NotificacionFormulario destinatarioSeleccionable={esSeccionCreacion()} />
										</Col>
									</Row>
								</CardBody>
								<CardFooter>
									<BarraHerramientas>
										<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
											<i className={constantes.icono.atras}></i> {texto('Cancelar')}
										</button>
										<button className="btn btn-success" id="botonGuardar" type="submit">
											<i className={constantes.icono.guardar}></i> {texto('Guardar')}
										</button>
									</BarraHerramientas>
								</CardFooter>
							</Card>
						</form>
					)}
					validate={eventoValidarFormulario}
					validateOnBlur
				/>
			)}
		</Contenedor>
	);
};

export default Pagina;
