import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { McRespuesta, procesarError } from '@mcsoft/api';
import ConfiguracionAplicacion from 'modelo/ConfiguracionAplicacion';
import constantes from 'configuracion/constantes';
import { GET_CONFIGURACION_APLICACION } from './actionTypes';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { obtenerConfiguracionAplicacion } from 'servicios/api/configuracionAplicacion';
import { ReduxAccion } from 'store/actions';
import { setConfiguracionAplicacionAction } from './actions';

const NOMBRE_CLASE = 'store/configuracionAplicacion/saga';

function* getConfiguracionAplicacionSaga(action: ReduxAccion) {
	const nombreMetodo = 'getConfiguracionAplicacionSaga';
	const { auth0AccessToken } = action.payload;
	let respuesta: McRespuesta;
	try {
		mcLogger.dev({ mensaje: `Obteniendo la configuración de la aplicación`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		respuesta = yield call(obtenerConfiguracionAplicacion, auth0AccessToken);
		const configuracionAplicacion: ConfiguracionAplicacion = respuesta.datos;
		yield put(setConfiguracionAplicacionAction(configuracionAplicacion));
	} catch (error) {
		const mcError = procesarError(error);
		mcLogger.error({ mensaje: `Error al obtener la configuración de la aplicación de la base de datos:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.datos });
		mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		const rutaDestino = constantes.ruta.webInicio;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaDestino });
		window.location.replace(rutaDestino);
	}
}

export function* getConfiguracionAplicacionWatcher() {
	yield takeEvery(GET_CONFIGURACION_APLICACION, getConfiguracionAplicacionSaga);
}

function* Saga() {
	yield all([fork(getConfiguracionAplicacionWatcher)]);
}

export default Saga;
