/* eslint-disable no-unused-vars */
import aplicacion from 'configuracion/aplicacion';
import App from 'App';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { compose } from 'redux';
import constantes from 'configuracion/constantes';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import store from 'store';

declare global {
	interface Document {
		cancelFullScreen: () => void;
		fullscreenElement: any;
		mozCancelFullScreen: () => void;
		mozFullScreenElement: any;
		webkitFullscreenElement: any;
		webkitCancelFullScreen: () => void;
	}

	interface HTMLElement {
		mozRequestFullScreen?: () => void;
		webkitRequestFullscreen?: () => void;
	}

	interface ChildNode {
		classList: any;
		id: any;
	}

	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<Auth0Provider
				audience={aplicacion.auth0.audience}
				clientId={aplicacion.auth0.clientId ? aplicacion.auth0.clientId : ''}
				domain={aplicacion.auth0.domain ? aplicacion.auth0.domain : ''}
				redirectUri={`${window.location.origin}${constantes.ruta.appInicio}`}
			>
				<PayPalScriptProvider
					options={{
						'client-id': aplicacion.paypal.clientId ? aplicacion.paypal.clientId : '',
						currency: 'MXN'
					}}
				>
					<App />
				</PayPalScriptProvider>
			</Auth0Provider>
		</BrowserRouter>
	</Provider>
);

const root = ReactDOM.createRoot(document.getElementById('root') as any);
root.render(app);
