import { actualizarUsuario, obtenerUsuarioPorId } from 'servicios/api/usuarios';
import { setImagenesTemporalesFotoPerfilAction, setPantallaCargaMostrarAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEliminarUsuarioFoto, useGuardarUsuarioFoto, useObtenerUsuarioFoto } from 'hooks/useImagen';
import { useEsSeccionAdministracion, useEsSeccionPerfil } from 'hooks/useSeccion';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import McSelectorImagen from '@mcsoft/selector-imagen';
import { procesarError } from '@mcsoft/api';
import { StateType } from 'store';
import { texto } from 'idiomas';
import Usuario from 'modelo/Usuario';

const NOMBRE_CLASE = 'paginas/app/usuarios/UsuarioFotoEditar';

/**
 * En esta página se puede editar la foto de perfil de un usuario.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const esSeccionPerfil = useEsSeccionPerfil();
	const obtenerFotoPerfil = useObtenerUsuarioFoto();
	const eliminarUsuarioFoto = useEliminarUsuarioFoto();
	const guardarUsuarioFoto = useGuardarUsuarioFoto();
	const [registro, setRegistro] = useState<Usuario>();
	const history = useHistory();
	const { id } = useParams<any>();
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.usuarios);
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [rutaSalida, setRutaSalida] = useState<string>(`${constantes.ruta.appAdministracionUsuariosDetalles}/${id}`);
	const [enlaces, setEnlaces] = useState<any>([]);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, []);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, [location.pathname]);

	/**
	 * Actualiza el registro.
	 */
	const actualizarRegistro = async (registro: Usuario) => {
		const nombreMetodo = 'actualizarRegistro';
		try {
			mcLogger.log({ mensaje: `Actualizando usuario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			await actualizarUsuario({ auth0AccessToken, id: registro.id, valores: registro });
			mcLogger.log({ mensaje: `Usuario actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
			mcNotificaciones.exito({ mensaje: texto('Foto de perfil guardada con éxito.') });
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
			history.push(rutaSalida);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al actualizar el usuario:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			dispatch(setPantallaCargaMostrarAction(false));
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Guarda la imagen.
	 * > - ***imagenTipo*** - Tipo MIME de la imagen.
	 * > - ***imagenExtension*** - Extensión de la imagen.
	 * > - ***imagenBase64*** - Imagen recortada codificada en base64.
	 */
	const eventoGuardarImagen = async (imagenTipo: string, imagenExtension: string, imagenBase64: string) => {
		const nombreMetodo = 'eventoGuardarImagen';
		try {
			if (registro) {
				const nombreImagen = `${registro.id}_${new Date().getTime()}`;
				const nombreImagenConExtension = `${nombreImagen}.${imagenExtension}`;
				mcLogger.log({ mensaje: `Guardando foto de perfil...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				dispatch(setPantallaCargaMostrarAction(true));
				await guardarUsuarioFoto({ archivoBase64: imagenBase64, archivoNombre: nombreImagenConExtension, archivoTipo: imagenTipo });
				mcLogger.log({ mensaje: `Foto de perfil guardada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				dispatch(setImagenesTemporalesFotoPerfilAction({ imagenBase64, usuarioId: registro.id }));
				const fotoPerfilAnterior = registro.fotoPerfil;
				registro.fotoPerfil = nombreImagenConExtension;
				if (fotoPerfilAnterior) {
					mcLogger.log({ mensaje: `Eliminando foto de perfil anterior...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
					await eliminarUsuarioFoto(fotoPerfilAnterior);
					mcLogger.log({ mensaje: `Foto de perfil anterior eliminada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				}
				await actualizarRegistro(registro);
			}
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la foto de perfil:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			dispatch(setPantallaCargaMostrarAction(false));
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Obteniendo información del usuario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionAdministracion()) {
				const respuesta = await obtenerUsuarioPorId({ auth0AccessToken, id });
				const { datos } = respuesta;
				setRegistro(datos);
			}
			if (esSeccionPerfil()) {
				const respuesta = await obtenerUsuarioPorId({ auth0AccessToken, id: usuario.id });
				const { datos } = respuesta;
				setRegistro(datos);
			}
			mcLogger.log({ mensaje: `Información del usuario obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener el usuario: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los valores de las variables que cambian en función de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionAdministracion()) {
			setTituloSeccion(texto('Editar foto del usuario'));
			const rutaSalida = `${constantes.ruta.appAdministracionUsuariosDetalles}/${id}`;
			const rutaSalidaLista = `${constantes.ruta.appAdministracionUsuariosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{
					ruta: rutaSalidaLista,
					titulo: texto('Lista de Usuarios')
				},
				{ ruta: rutaSalida, titulo: texto('Detalles del usuario') }
			]);
		}
		if (esSeccionPerfil()) {
			setTituloSeccion(texto('Editar mi foto de perfil'));
			const rutaSalida = constantes.ruta.appUsuarioPerfil;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Mi perfil') }
			]);
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={constantes.icono.foto} titulo={tituloSeccion} />
			<McSelectorImagen eventoCancelar={eventoCancelar} eventoGuardar={eventoGuardarImagen} imagen={obtenerFotoPerfil({ ancho: '700', usuario: registro })} />
		</Contenedor>
	);
};

export default Pagina;
