import { Col, Row } from 'reactstrap';
import { McCampoSelector, McCampoTexto, McSwitchRedondo } from '@mcsoft/formulario';
import constantes from 'configuracion/constantes';
import InformacionAvaluoAntecedentes from 'componentes/informacion/InformacionAvaluoAntecedentes';
import InformacionAvaluoCaracteristicas from 'componentes/informacion/InformacionAvaluoCaracteristicas';
import InformacionAvaluoCodigoValuador from 'componentes/informacion/InformacionAvaluoCodigoValuador';
import InformacionAvaluoContrucciones from 'componentes/informacion/InformacionAvaluoContrucciones';
import InformacionAvaluoDistribucion from 'componentes/informacion/InformacionAvaluoDistribucion';
import InformacionAvaluoElementosConstruccion from 'componentes/informacion/InformacionAvaluoElementosConstruccion';
import InformacionAvaluoFotos from 'componentes/informacion/InformacionAvaluoFotos';
import InformacionAvaluoGeneral from 'componentes/informacion/InformacionAvaluoGeneral';
import InformacionAvaluoInstalacionesEspeciales from 'componentes/informacion/InformacionAvaluoInstalacionesEspeciales';
import InformacionAvaluoObservaciones from 'componentes/informacion/InformacionAvaluoObservaciones';
import InformacionAvaluoPdfCoeficientesUtilizacionOcupacion from 'componentes/informacion/InformacionAvaluoPdfCoeficientesUtilizacionOcupacion';
import InformacionAvaluoPdfCoordenadasDd from 'componentes/informacion/InformacionAvaluoPdfCoordenadasDd';
import InformacionAvaluoPdfCoordenadasDms from 'componentes/informacion/InformacionAvaluoPdfCoordenadasDms';
import InformacionAvaluoPdfCoordenadasUtm from 'componentes/informacion/InformacionAvaluoPdfCoordenadasUtm';
import InformacionAvaluoPdfEncabezado from 'componentes/informacion/InformacionAvaluoPdfEncabezado';
import InformacionAvaluoPdfEtiquetaConstruccion from 'componentes/informacion/InformacionAvaluoPdfEtiquetaConstruccion';
import InformacionAvaluoPdfLicencia from 'componentes/informacion/InformacionAvaluoPdfLicencia';
import InformacionAvaluoPdfNumeroPagina from 'componentes/informacion/InformacionAvaluoPdfNumeroPagina';
import InformacionAvaluoPdfRellenarEspacios from 'componentes/informacion/InformacionAvaluoPdfRellenarEspacios';
import InformacionAvaluoTerrenos from 'componentes/informacion/InformacionAvaluoTerrenos';
import InformacionAvaluoUbicacion from 'componentes/informacion/InformacionAvaluoUbicacion';
import mcLogger from '@mcsoft/logger';
import { texto } from 'idiomas';
import { useHistory } from 'react-router-dom';

const NOMBRE_CLASE = 'componentes/usuarios/PreferenciasFormularioAvaluos';

/**
 * Formulario para lad preferencias del usuario relacionadas a los avalúos.
 */
const PreferenciasFormularioAvaluos = () => {
	const history = useHistory();

	/**
	 * Redirecciona al usuario a la ruta de edición.
	 */
	const eventoEstablecerValoresPredeterminados = () => {
		const nombreMetodo = 'eventoEditarDetalles';
		const ruta = constantes.ruta.appUsuarioPreferenciasAvaluo;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	};

	return (
		<Row>
			<Col lg="12">
				<Row>
					<Col md="3">
						<McCampoTexto campo="avaluosCodigoValuador" etiqueta={texto('Código de valuador')} id="avaluosCodigoValuador" informacion={InformacionAvaluoCodigoValuador} />
					</Col>
				</Row>
				<Row>
					<Col className="mb-3" md="12">
						<hr />
						<legend>{texto('Valores predeterminados')}</legend>
						<p>{texto('Valores predeterminados que tendrá el avalúo al ser creado.')}</p>
					</Col>
				</Row>
				<Row>
					<Col md="12">
						<button className="btn btn btn-info" onClick={eventoEstablecerValoresPredeterminados} type="button">
							{texto('Establecer')}
						</button>
					</Col>
				</Row>
				<Row>
					<Col className="mb-3" md="12">
						<hr />
						<legend>{texto('Clonación')}</legend>
						<p>{texto('Valores predeterminados que tendrá el modal de opciones al clonar un avalúo.')}</p>
					</Col>
				</Row>
				<Row>
					<Col md="3">
						<McSwitchRedondo campo="avaluosClonarAntecedentes" etiqueta={texto('Clonar antecedentes')} id="avaluosClonarAntecedentes" informacion={InformacionAvaluoAntecedentes} />
					</Col>
					<Col md="3">
						<McSwitchRedondo
							campo="avaluosClonarCaracteristicas"
							etiqueta={texto('Clonar características')}
							id="avaluosClonarCaracteristicas"
							informacion={InformacionAvaluoCaracteristicas}
						/>
					</Col>
					<Col md="3">
						<McSwitchRedondo campo="avaluosClonarGeneral" etiqueta={texto('Clonar general')} id="avaluosClonarGeneral" informacion={InformacionAvaluoGeneral} />
					</Col>
					<Col md="3">
						<McSwitchRedondo campo="avaluosClonarDistribucion" etiqueta={texto('Clonar distribución')} id="avaluosClonarDistribucion" informacion={InformacionAvaluoDistribucion} />
					</Col>
					<Col md="3">
						<McSwitchRedondo
							campo="avaluosClonarElementosConstruccion"
							etiqueta={texto('Clonar elementos de construcción')}
							id="avaluosClonarElementosConstruccion"
							informacion={InformacionAvaluoElementosConstruccion}
						/>
					</Col>
					<Col md="3">
						<McSwitchRedondo campo="avaluosClonarUbicacion" etiqueta={texto('Clonar ubicación')} id="avaluosClonarUbicacion" informacion={InformacionAvaluoUbicacion} />
					</Col>
					<Col md="3">
						<McSwitchRedondo campo="avaluosClonarFotos" etiqueta={texto('Clonar fotos')} id="avaluosClonarFotos" informacion={InformacionAvaluoFotos} />
					</Col>
					<Col md="3">
						<McSwitchRedondo
							campo="avaluosClonarDetallesTerrenos"
							etiqueta={texto('Clonar detalles de los terrenos')}
							id="avaluosClonarDetallesTerrenos"
							informacion={InformacionAvaluoTerrenos}
						/>
					</Col>
					<Col md="3">
						<McSwitchRedondo
							campo="avaluosClonarDetallesConstrucciones"
							etiqueta={texto('Clonar detalles de las construcciones')}
							id="avaluosClonarDetallesConstrucciones"
							informacion={InformacionAvaluoContrucciones}
						/>
					</Col>
					<Col md="3">
						<McSwitchRedondo
							campo="avaluosClonarDetallesInstalacionesEspeciales"
							etiqueta={texto('Clonar detalles de las instalaciones especiales')}
							id="avaluosClonarDetallesInstalacionesEspeciales"
							informacion={InformacionAvaluoInstalacionesEspeciales}
						/>
					</Col>
					<Col md="3">
						<McSwitchRedondo
							campo="avaluosClonarObservaciones"
							etiqueta={texto('Clonar observaciones')}
							id="avaluosClonarObservaciones"
							informacion={InformacionAvaluoObservaciones}
						/>
					</Col>
				</Row>
				<Row>
					<Col className="mb-3" md="12">
						<hr />
						<legend>{texto('PDF')}</legend>
						<p>{texto('Valores predeterminados que tendrá el modal de opciones al generar el PDF personalizado.')}</p>
					</Col>
				</Row>
				<Row>
					<Col md="3">
						<McCampoSelector
							campo="avaluosPdfTipoEncabezado"
							etiqueta={texto('Tipo de encabezado')}
							id="avaluosPdfTipoEncabezado"
							informacion={InformacionAvaluoPdfEncabezado}
							opciones={[
								{ nombre: 'Compacto', valor: 'compacto' },
								{ nombre: 'Normal', valor: 'normal' }
							]}
							sinOpcionEnBlanco
						/>
					</Col>
					<Col md="3">
						<McCampoSelector
							campo="avaluosPdfEtiquetaConstruccionCodigo"
							etiqueta={texto('Etiqueta del codigo de construcción')}
							id="avaluosPdfEtiquetaConstruccionCodigo"
							informacion={InformacionAvaluoPdfEtiquetaConstruccion}
							opciones={[
								{ nombre: 'Clasificación', valor: 'clasificacion' },
								{ nombre: 'Código', valor: 'codigo' }
							]}
							sinOpcionEnBlanco
						/>
					</Col>
				</Row>
				<Row>
					<Col md="4">
						<McSwitchRedondo
							campo="avaluosPdfMostrarCoeficientesUtilizacionOcupacion"
							etiqueta={texto('Mostrar coeficientes de utilización y ocupación')}
							etiquetaLineal
							id="avaluosPdfMostrarCoeficientesUtilizacionOcupacion"
							informacion={InformacionAvaluoPdfCoeficientesUtilizacionOcupacion}
						/>
					</Col>
					<Col md="4">
						<McSwitchRedondo
							campo="avaluosPdfMostrarCoordenadasDD"
							etiqueta={texto('Mostrar coordenadas DD')}
							etiquetaLineal
							id="avaluosPdfMostrarCoordenadasDD"
							informacion={InformacionAvaluoPdfCoordenadasDd}
						/>
					</Col>
					<Col md="4">
						<McSwitchRedondo
							campo="avaluosPdfMostrarCoordenadasDMS"
							etiqueta={texto('Mostrar coordenadas DMS')}
							etiquetaLineal
							id="avaluosPdfMostrarCoordenadasDMS"
							informacion={InformacionAvaluoPdfCoordenadasDms}
						/>
					</Col>
					<Col md="4">
						<McSwitchRedondo
							campo="avaluosPdfMostrarCoordenadasUTM"
							etiqueta={texto('Mostrar coordenadas UTM')}
							etiquetaLineal
							id="avaluosPdfMostrarCoordenadasUTM"
							informacion={InformacionAvaluoPdfCoordenadasUtm}
						/>
					</Col>
					<Col md="4">
						<McSwitchRedondo
							campo="avaluosPdfMostrarLicenciaFoto"
							etiqueta={texto('Mostrar la foto de la licencia del perito')}
							etiquetaLineal
							id="avaluosPdfMostrarLicenciaFoto"
							informacion={InformacionAvaluoPdfLicencia}
						/>
					</Col>
					<Col md="4">
						<McSwitchRedondo
							campo="avaluosPdfMostrarNumeroPagina"
							etiqueta={texto('Mostrar número de página')}
							etiquetaLineal
							id="avaluosPdfMostrarNumeroPagina"
							informacion={InformacionAvaluoPdfNumeroPagina}
						/>
					</Col>
					<Col md="4">
						<McSwitchRedondo
							campo="avaluosPdfRellenarEspaciosSinFoto"
							etiqueta={texto('Rellenar los espacios sin foto')}
							etiquetaLineal
							id="avaluosPdfRellenarEspaciosSinFoto"
							informacion={InformacionAvaluoPdfRellenarEspacios}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default PreferenciasFormularioAvaluos;
