import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { McCampoSelector, obtenerValorMcCampoSelector } from '@mcsoft/formulario';
import constantes from 'configuracion/constantes';
import { Form } from 'react-final-form';
import McGraficaLineal from '@mcsoft/grafica-lineal';
import mcLogger from '@mcsoft/logger';
import { obtenerCantidadAvaluosPorMunicipio } from 'servicios/api/widgets';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';

const NOMBRE_CLASE = 'componentes/widget/cantidadAvaluosPorMunicipio/CantidadAvaluosPorMunicipio';

interface CantidadAvaluosPorMunicipioProps {
	/**
	 * Indica si los resultados solo mostrarán la información del usuario en sesión.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	filtrarPorUsuario?: boolean;
}

/**
 * Muestra una tarjeta con la información de la cantidad de avalúos por municipio.
 */
const CantidadAvaluosPorMunicipio = (props: CantidadAvaluosPorMunicipioProps) => {
	const { filtrarPorUsuario = false } = props;
	const { sesion } = useSelector((state: StateType) => state);
	const [titulo, setTitulo] = useState<string>('');
	const [informacionRenglones, setInformacionRenglones] = useState<Array<any>>([]);
	const [etiquetas, setEtiquetas] = useState<Array<string>>([]);
	const [series, setSeries] = useState<Array<any>>([]);
	const [periodo, setPeriodo] = useState<string>('mes');
	const { auth0, usuario } = sesion;
	const { auth0AccessToken } = auth0;

	useEffect(() => {
		obtenerInformacion();
	}, [periodo]);

	/**
	 * Setea la imagen en el estado de Redux para ser usada en el visor de imágenes.
	 */
	const eventoCambioPeriodo = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const valor = obtenerValorMcCampoSelector(evento);
		setPeriodo(valor);
	};

	/**
	 * Setea la imagen en el estado de Redux para ser usada en el visor de imágenes.
	 */
	const obtenerInformacion = async () => {
		const nombreMetodo = 'obtenerInformacion';
		const parametros: any = { auth0AccessToken, periodo };
		setTitulo(texto('Top 5 avalúos por municipio'));
		if (filtrarPorUsuario) {
			setTitulo(texto('Top 5 mis avalúos por municipio'));
			parametros.id = usuario.id;
		}
		const respuesta = await obtenerCantidadAvaluosPorMunicipio(parametros);
		mcLogger.log({ mensaje: `Información obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const informacionRenglones: Array<any> = respuesta.datos;
		const arregloEtiquetas: Array<string> = [];
		const arregloSeries: Array<any> = [];
		const arregloIncompletos: Array<any> = [];
		const arregloCompletos: Array<any> = [];
		const arregloTotales: Array<any> = [];
		informacionRenglones.forEach((renglon) => {
			arregloEtiquetas.push(renglon.nombre || texto('Sin Municipio'));
			arregloIncompletos.push(renglon.incompletos);
			arregloCompletos.push(renglon.completos);
			arregloTotales.push(renglon.total);
		});
		arregloSeries.push(
			{
				datos: arregloIncompletos,
				nombre: 'Incompletos',
				tipo: 'barra'
			},
			{
				datos: arregloCompletos,
				nombre: 'Completos',
				tipo: 'barra'
			},
			{
				datos: arregloTotales,
				nombre: 'Total',
				tipo: 'barra'
			}
		);
		setInformacionRenglones(informacionRenglones);
		setEtiquetas(arregloEtiquetas);
		setSeries(arregloSeries);
	};

	return (
		<Fragment>
			<Form
				initialValues={{ seleccion: 'mes' }}
				onSubmit={() => {}}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Card>
							<CardBody>
								<Row>
									<Col lg="12">
										<div className="float-start">
											<h4 className="card-title mb-4">{titulo}</h4>
										</div>
										<div className="float-end">
											<McCampoSelector
												campo="seleccion"
												eventoCambio={eventoCambioPeriodo}
												iconoIzquierda={constantes.icono.fecha}
												id="seleccion"
												opciones={[
													{ nombre: 'Este mes', valor: 'mes' },
													{ nombre: 'Este año', valor: 'año' },
													{ nombre: 'Todos', valor: 'siempre' }
												]}
												sinOpcionEnBlanco
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<div className="table-responsive">
											<Table className="table-borderless table-centered table-nowrap">
												<tbody>
													<tr>
														<th></th>
														<th style={{ textAlign: 'center', width: '103px' }}>{texto('Incompletos')}</th>
														<th style={{ textAlign: 'center', width: '103px' }}>{texto('Completos')}</th>
														<th style={{ textAlign: 'center', width: '103px' }}>{texto('Total')}</th>
													</tr>
													{informacionRenglones.map((informacion: any) => (
														<tr key={`avaluosPorMunicipio_${informacion.municipioId}`}>
															<td>
																<h6 className="font-size-15 mb-1 fw-normal">{informacion.nombre || texto('Sin Municipio')}</h6>
															</td>
															<td style={{ textAlign: 'center' }}>
																<span className="font-size-15 badge rounded-pill bg-warning">{informacion.incompletos}</span>
															</td>
															<td style={{ textAlign: 'center' }}>
																<span className="font-size-15 badge rounded-pill bg-success ">{informacion.completos}</span>
															</td>
															<td style={{ textAlign: 'center' }}>
																<span className="font-size-15 badge rounded-pill bg-primary">{informacion.total}</span>
															</td>
														</tr>
													))}
												</tbody>
											</Table>
										</div>
									</Col>
								</Row>
								<Row>
									<Col md={12}>
										<McGraficaLineal
											colores={[constantes.color.bootstrapWarning, constantes.color.bootstrapSuccess, constantes.color.bootstrapPrimary]}
											etiquetas={etiquetas}
											series={series}
										/>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</form>
				)}
			/>
		</Fragment>
	);
};

export default CantidadAvaluosPorMunicipio;
