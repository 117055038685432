import { Col, Row } from 'reactstrap';
import { convertirBlobABase64, obtenerExtensionDeTipoMime } from '@mcsoft/archivos';
import { Fragment, useState } from 'react';
import { setImagenesTemporalesFotoMapaPersonalizadoAction, setPantallaCargaMostrarAction } from 'store/actions';
import { useForm, useFormState } from 'react-final-form';
import Avaluo from 'modelo/Avaluo';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import McSelectorArchivo from '@mcsoft/selector-archivos';
import { procesarError } from '@mcsoft/api';
import { texto } from 'idiomas';
import { useDispatch } from 'react-redux';
import { useGuardarAvaluoMapaPersonalizado } from 'hooks/useImagen';

const NOMBRE_CLASE = 'AvaluoFormularioUbicacionMapaPersonalizado';

interface AvaluoFormularioUbicacionMapaPersonalizadoProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoIrInicio: () => void;
	hayCambiosSinGuardar: boolean;
}

/**
 * Formulario para la sección ubicación mapa personalizado del avalúo.
 */
const AvaluoFormularioUbicacionMapaPersonalizado = (props: AvaluoFormularioUbicacionMapaPersonalizadoProps) => {
	const dispatch = useDispatch();
	const guardarAvaluoMapaPersonalizado = useGuardarAvaluoMapaPersonalizado();
	const formulario = useForm();
	const { cambiarValorCampo } = formulario.mutators;
	const formularioValores = useFormState();
	const { id: avaluoId } = formularioValores.values;
	const { eventoDeshacer, eventoGuardar, eventoIrInicio, hayCambiosSinGuardar } = props;
	const [archivoSeleccionado, setArchivoSeleccionado] = useState<Array<File>>([]);
	const [hayCambiosSinGuardarLocal, setHayCambiosSinGuardarLocal] = useState<boolean>(false);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardarGlobal()}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
				<i className={constantes.icono.atras}></i> {texto('Cancelar')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardarGlobal()} id="botonGuardar" onClick={eventoGuardarArchivo} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Setea los archivos seleccionados en el estado del componente.
	 * - ***archivos*** - Arreglo con los archivos seleccionados.
	 */
	const eventoArchivosSeleccionados = (archivos: Array<File>) => {
		setArchivoSeleccionado(archivos);
		setHayCambiosSinGuardarLocal(true);
	};

	/**
	 * Deshace los cambios y redirige a la sección de inicio de la ubicación.
	 */
	const eventoCancelar = () => {
		eventoDeshacer();
		eventoIrInicio();
		setHayCambiosSinGuardarLocal(false);
	};

	/**
	 * Guarda la información del mapa personalizado.
	 */
	const eventoGuardarArchivo = async () => {
		const nombreMetodo = 'eventoGuardarArchivo';
		try {
			dispatch(setPantallaCargaMostrarAction(true));
			const [archivo] = archivoSeleccionado;
			if (archivo) {
				let archivoNombre = '';
				const archivoExtension = obtenerExtensionDeTipoMime(archivo.type);
				const archivoBase64 = await convertirBlobABase64(archivo);
				archivoNombre = `${avaluoId}_mapaPersonalizado_${new Date().getTime()}.${archivoExtension}`;
				dispatch(setImagenesTemporalesFotoMapaPersonalizadoAction({ avaluoId, imagenBase64: archivoBase64 }));
				await guardarAvaluoMapaPersonalizado({ archivoBase64: archivoBase64, archivoNombre: archivoNombre, archivoTipo: archivo.type });
				const avaluo = formularioValores.values as Avaluo;
				cambiarValorCampo('ubicacion.mapaPersonalizadoImagenNombre', archivoNombre);
				if (avaluo && avaluo.ubicacion) {
					avaluo.ubicacion.mapaPersonalizadoImagenNombre = archivoNombre;
				}
				eventoGuardar(avaluo);
				setHayCambiosSinGuardarLocal(false);
				eventoIrInicio();
				dispatch(setPantallaCargaMostrarAction(false));
			} else {
				const avaluo = formularioValores.values as Avaluo;
				eventoGuardar(avaluo);
				setHayCambiosSinGuardarLocal(false);
			}
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Regresa verdadero si hay cambios sin guardar en el croquis o el avalúo.
	 */
	const hayCambiosSinGuardarGlobal = (): boolean => hayCambiosSinGuardar || hayCambiosSinGuardarLocal;

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.mapa}></i> {texto('Ubicación del Predio - Subir Mapa Personalizado')}
					</h3>
					<p>{texto('Selecciona el archivo con el mapa personalizado del predio del avalúo.')}</p>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">
					<McSelectorArchivo eventoArchivosSeleccionados={eventoArchivosSeleccionados} mostrarMiniaturas />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
		</Fragment>
	);
};

export default AvaluoFormularioUbicacionMapaPersonalizado;
