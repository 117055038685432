import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { McCampoSelector, McCampoSelectorOpcion, McCampoTexto, obtenerValorMcCampoSelector } from '@mcsoft/formulario';
import { useForm, useFormState } from 'react-final-form';
import Avaluo from 'modelo/Avaluo';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import ListaOpcion from 'modelo/ListaOpcion';
import { obtenerOpcionDeLaLista } from 'util/modelo/listasOpciones';
import { ordenarArregloCadenasAlfabeticamente } from '@mcsoft/arreglos';
import { texto } from 'idiomas';

interface AvaluoFormularioDistribucionProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
	listaDistribucionInmueble: Array<ListaOpcion> | undefined;
}

/**
 * Formulario para la sección distribución del avalúo.
 */
const AvaluoFormularioDistribucion = (props: AvaluoFormularioDistribucionProps) => {
	const formulario = useForm();
	const formularioValores = useFormState();
	const { distribucion } = formularioValores.values;
	const { eventoDeshacer, eventoGuardar, eventoSalir, hayCambiosSinGuardar, listaDistribucionInmueble } = props;
	const [listaCantidad, setListaCantidad] = useState<Array<McCampoSelectorOpcion>>([]);
	const [listaCantidadConMedios, setListaCantidadConMedios] = useState<Array<McCampoSelectorOpcion>>([]);
	const [distribucionInmuebleEditable, setDistribucionInmuebleEditable] = useState<boolean>(false);

	useEffect(() => {
		setListaCantidad(obtenerListaCantidad());
		setListaCantidadConMedios(obtenerListaCantidadConMedios());
	}, []);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonSalir" onClick={eventoSalir} type="button">
				<i className={constantes.icono.atras}></i> {texto('Salir')}
			</button>
			<button className="btn btn-warning" disabled={!hayCambiosSinGuardar} id="botonDeshacer" onClick={eventoDeshacer} type="button">
				<i className={constantes.icono.deshacer}></i> {texto('Deshacer')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardar} id="botonGuardar" onClick={eventoGuardarFormulario} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardar}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	/**
	 * Evento que se ejecuta cuando cambia una distribución del inmueble seleccionada.
	 * - ***campo*** - Nombre del campo que cambiará.
	 * - ***valor*** - Valor que tomará el campo.
	 */
	const eventoCambioDistribucionInmueble = ({ campo, valor }: { campo: string; valor: string }) => {
		const { cambiarValorCampo } = formulario.mutators;
		const { detalles } = distribucion;
		const arreglo: Array<string> = [];
		if (Number(valor) > 0.1) {
			const opcion = obtenerOpcionDeLaLista(listaDistribucionInmueble, campo);
			const opcionCantidad = listaCantidadConMedios.filter((opcion) => opcion.valor === valor)[0].nombre;
			if (opcion.id === '382a2035-f183-4137-a2da-0ac8711b2d5e') {
				// Es cochera
				if (Number(valor) > 1) {
					arreglo.push(`${opcion.nombre} ${opcionCantidad} autos`);
				} else {
					arreglo.push(opcion.nombre);
				}
			} else {
				// No es cochera
				if (Number(valor) > 1) {
					arreglo.push(opcion.nombrePlural ? `${opcionCantidad} ${opcion.nombrePlural}` : `${opcionCantidad} ${opcion.nombre}(s)`);
				} else if (Number(valor) > 0.1) {
					arreglo.push(`${opcionCantidad} ${opcion.nombre}`);
				} else {
					arreglo.push(opcion.nombre);
				}
			}
		} else {
			delete detalles[campo];
			cambiarValorCampo('distribucion.detalles', detalles);
		}
		for (const propiedad in detalles) {
			if (campo !== propiedad) {
				const cantidadDetalle = detalles[propiedad];
				const opcionCantidad = listaCantidadConMedios.filter((opcion) => opcion.valor === cantidadDetalle)[0].nombre;
				if (cantidadDetalle > 0.1) {
					const opcion = obtenerOpcionDeLaLista(listaDistribucionInmueble, propiedad);
					if (opcion.id === '382a2035-f183-4137-a2da-0ac8711b2d5e') {
						// Es cochera
						if (cantidadDetalle > 1) {
							arreglo.push(`${opcion.nombre} ${opcionCantidad} autos`);
						} else {
							arreglo.push(opcion.nombre);
						}
					} else {
						// No es cochera
						if (cantidadDetalle > 1) {
							arreglo.push(opcion.nombrePlural ? `${opcionCantidad} ${opcion.nombrePlural}` : `${opcionCantidad} ${opcion.nombre}(s)`);
						} else if (Number(cantidadDetalle) > 0.1) {
							arreglo.push(`${opcionCantidad} ${opcion.nombre}`);
						} else {
							arreglo.push(opcion.nombre);
						}
					}
				}
			}
		}
		ordenarArregloCadenasAlfabeticamente({ arreglo });
		cambiarValorCampo('distribucion.descripcion', arreglo.join(', '));
	};

	/**
	 * Habilita la edición manual del campo distribución del inmueble.
	 */
	const eventoEditarDistribucionInmueble = () => {
		setDistribucionInmuebleEditable(!distribucionInmuebleEditable);
	};

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardarFormulario = () => {
		const avaluo = formularioValores.values as Avaluo;
		eventoGuardar(avaluo);
	};

	/**
	 * Obtiene la lista de cantidades (Enteros).
	 */
	const obtenerListaCantidad = () => {
		const lista: Array<McCampoSelectorOpcion> = [];
		for (let i = 1; i < 11; i++) {
			lista.push({
				nombre: i.toString(),
				valor: i.toString()
			});
		}
		return lista;
	};

	/**
	 * Obtiene la lista de cantidades (Enteros y medios).
	 */
	const obtenerListaCantidadConMedios = () => {
		const lista: Array<McCampoSelectorOpcion> = [];
		lista.push({
			nombre: 'Medio',
			valor: '0.5'
		});
		for (let i = 1; i < 11; i++) {
			lista.push({
				nombre: i.toString(),
				valor: i.toString()
			});
			lista.push({
				nombre: `${i.toString()}½`,
				valor: `${i.toString()}.5`
			});
		}
		return lista;
	};

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.cochera}></i> {texto('Distribución del Inmueble')}
					</h3>
					<p>{texto('En esta sección del formulario se captura la información de la distribución del inmueble del avalúo.')}</p>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				{listaDistribucionInmueble?.map((opcion) => (
					<Col key={opcion.id} lg="4">
						<McCampoSelector
							campo={`distribucion.detalles.${opcion.id}`}
							etiqueta={texto(opcion.nombre)}
							eventoCambio={(evento: ChangeEvent<HTMLSelectElement>) => eventoCambioDistribucionInmueble({ campo: opcion.id, valor: obtenerValorMcCampoSelector(evento) })}
							id={`distribucion.detalles.${opcion.id}`}
							opciones={
								opcion.id === '55116811-cab2-4450-97e0-f062c39f4184' // Baño
									? listaCantidadConMedios
									: listaCantidad
							}
						/>
					</Col>
				))}
			</Row>
			<Row>
				<Col lg="12">
					<span className="avaluos-formulario__botton-editar">
						<button className="btn btn-sm btn-primary" onClick={eventoEditarDistribucionInmueble} title={texto('Editar Manualmente')} type="button">
							<i className={constantes.icono.editar}></i>
						</button>
					</span>
					<McCampoTexto
						campo="distribucion.descripcion"
						id="distribucion.descripcion"
						longitudMaxima={330}
						obligatorio
						renglones={3}
						soloLectura={!distribucionInmuebleEditable}
						tipo="areaTexto"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
		</Fragment>
	);
};

export default AvaluoFormularioDistribucion;
