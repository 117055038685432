import Avaluo from 'modelo/Avaluo';
import estilos from './estilos';
import pagina01 from './pagina01';
import pagina02 from './pagina02';
import pagina03 from './pagina03';
import pagina04 from './pagina04';
import pagina05 from './pagina05';
import piePagina from './piePagina';
import Usuario from 'modelo/Usuario';

const generarContenidoPdf = ({ avaluo, tituloPdf, usuario }: { avaluo: Avaluo; tituloPdf: string; usuario: Usuario }): Promise<any> =>
	new Promise((resolve) => {
		const promesas = [];
		promesas.push(pagina01({ avaluo, usuario }));
		promesas.push(pagina02({ avaluo, usuario }));
		promesas.push(pagina03({ avaluo, usuario }));
		promesas.push(pagina04({ avaluo, usuario }));
		promesas.push(pagina05({ avaluo, usuario }));
		Promise.all(promesas).then((paginas) => {
			const contenidoCompleto = [].concat.apply([], paginas as any);
			const contenido = {
				content: contenidoCompleto,
				footer: function (paginaActual: number, paginas: number) {
					return piePagina({ avaluo, paginaActual, paginas });
				},
				info: {
					title: tituloPdf
				},
				pageMargins: [38, 35, 38, 60],
				pageOrientation: 'portrait',
				pageSize: 'LETTER',
				styles: estilos,
				watermark: avaluo.estatus === 'incompleto' ? 'INCOMPLETO' : ''
			};
			resolve(contenido);
		});
	});

export default generarContenidoPdf;
