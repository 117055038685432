import { McRespuesta, procesarError, procesarRespuesta } from '@mcsoft/api';
import aplicacion from 'configuracion/aplicacion';
import axios from 'axios';
import mcLogger from '@mcsoft/logger';
import UsuarioNotificacion from 'modelo/UsuarioNotificacion';
import UsuarioNotificacionDetalle from 'modelo/UsuarioNotificacionDetalle';

const NOMBRE_CLASE = 'servicios/api/usuariosNotificaciones';

/**
 * Actualiza una notificación existente en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id de la notificación a actualizar.
 * - ***valores*** - Objeto con los valores a actualizar.
 */
export const actualizarUsuarioNotificacion = ({
	auth0AccessToken,
	id,
	valores
}: {
	auth0AccessToken: string;
	id: string;
	valores: UsuarioNotificacionDetalle;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'actualizarUsuarioNotificacion';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Actualizando el registro con el id '${id}' en la base de datos con los siguientes valores:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: valores
		});
		axios
			.put(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.usuariosNotificaciones}/${id}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al actualizar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Actualiza una notificación detalle existente en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id de la notificación a actualizar.
 * - ***valores*** - Objeto con los valores a actualizar.
 */
export const actualizarUsuarioNotificacionDetalle = ({
	auth0AccessToken,
	id,
	valores
}: {
	auth0AccessToken: string;
	id: string;
	valores: UsuarioNotificacionDetalle;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'actualizarUsuarioNotificacionDetalle';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Actualizando el registro con el id '${id}' en la base de datos con los siguientes valores:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: valores
		});
		axios
			.put(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.usuariosNotificaciones}/detalle/${id}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al actualizar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Crea una notificación en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***valores*** - Objeto con los valores de la notificación a crear.
 */
export const crearUsuarioNotificacion = ({ auth0AccessToken, valores }: { auth0AccessToken: string; valores: UsuarioNotificacionDetalle }): Promise<McRespuesta> => {
	const nombreMetodo = 'crearUsuarioNotificacion';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Creando registro en la base de datos con los siguientes valores:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: valores });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosNotificaciones}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear el registro:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Crea varias notificaciones en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***valores*** - Arreglo con las notificaciones a crear.
 */
export const crearVariasUsuarioNotificacion = ({ auth0AccessToken, valores }: { auth0AccessToken: string; valores: Array<UsuarioNotificacion> }): Promise<McRespuesta> => {
	const nombreMetodo = 'crearVariasUsuarioNotificacion';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Creando registros en la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosNotificaciones}/varias
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros creados con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Elimina una notificación de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id de la notificación a eliminar.
 */
export const eliminarUsuarioNotificacion = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'eliminarUsuarioNotificacion';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Eliminando el registro con el id '${id}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.delete(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.usuariosNotificaciones}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' eliminado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al eliminar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Actualiza el estatus a 'leida' de todas las notificaciones relacionadas al usuario con el id recibido.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***usuarioId*** - Id del usuario relacionado a las notificaciones.
 */
export const marcarUsuarioNotificacionesLeidasPorUsuario = ({ auth0AccessToken, usuarioId }: { auth0AccessToken: string; usuarioId: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'marcarUsuarioNotificacionesLeidasPorUsuario';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Marcando como leídos los registros del usuario con el id '${usuarioId}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.put(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosNotificaciones}/leidas/${usuarioId}
		`,
				null,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({
					mensaje: `Registros del usuario con el id '${usuarioId}' marcados como leídos con éxito.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcRespuesta
				});
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({
					mensaje: `Error al marcar como leídos los registros del usuario con el id '${usuarioId}':`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcError
				});
				reject(mcError);
			});
	});
};

/**
 * Obtiene una notificación específica de la base de datos a través de su id.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id de la notificación a obtener.
 */
export const obtenerUsuarioNotificacionPorId = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioNotificacionPorId';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo registro con el id '${id}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosNotificaciones}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' obtenido con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene la cantidad de notificaciones con el estatus 'nueva' relacionadas al usuario con el id recibido.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***usuarioId*** - Id del usuario relacionado a las notificaciones.
 */
export const obtenerUsuarioNotificacionesCantidadNuevasPorUsuario = ({ auth0AccessToken, usuarioId }: { auth0AccessToken: string; usuarioId: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioNotificacionesCantidadNuevasPorUsuario';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo la cantidad de registros del usuario con el id '${usuarioId}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosNotificaciones}/nuevas/${usuarioId}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({
					mensaje: `Cantidad de registros del usuario con el id '${usuarioId}' obtenida con éxito.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcRespuesta
				});
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({
					mensaje: `Error al obtener la cantidad de registros del usuario con el id '${usuarioId}':`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcError
				});
				reject(mcError);
			});
	});
};

/**
 * Filtra y ordena las notificaciones y las separa en páginas según los parámetros recibidos y obtiene la lista de notificaciones de la página especificada.
 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerUsuarioNotificacionesPaginados = ({
	auth0AccessToken,
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	auth0AccessToken: string;
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioNotificacionesPaginados';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo registros de la base de datos con los siguientes parámetros:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: {
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			}
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosNotificaciones}/paginados
			?pagina=${pagina}
			&registrosPorPagina=${registrosPorPagina}
			&ordenamiento=${ordenamiento}
			&orden=${orden}
			&criterio=${criterio}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Filtra y ordena las notificaciones de un usuario y las separa en páginas según los parámetros recibidos y obtiene la lista de notificaciones de la página especificada.
 * - ***usuarioId*** - Id del usuario relacionado a las notificaciones.
 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerUsuarioNotificacionesPaginadosPorUsuario = ({
	auth0AccessToken,
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina,
	usuarioId
}: {
	auth0AccessToken: string;
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
	usuarioId: string;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioNotificacionesPaginadosPorUsuario';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo registros de la base de datos con los siguientes parámetros:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: {
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			}
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosNotificaciones}/paginados/${usuarioId}
			?pagina=${pagina}
			&registrosPorPagina=${registrosPorPagina}
			&ordenamiento=${ordenamiento}
			&orden=${orden}
			&criterio=${criterio}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene la lista de todas las notificaciones de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerUsuarioNotificacionesTodas = (auth0AccessToken: string): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioNotificacionesTodas';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo todos los registros de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosNotificaciones}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener todos los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene las últimas notificaciones relacionadas al usuario con el id recibido.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***usuarioId*** - Id del usuario relacionado a las notificaciones.
 */
export const obtenerUsuarioNotificacionesUltimasPorUsuario = ({ auth0AccessToken, usuarioId }: { auth0AccessToken: string; usuarioId: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerUsuarioNotificacionesUltimasPorUsuario';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo los últimos registros del usuario con el id '${usuarioId}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.usuariosNotificaciones}/ultimas/${usuarioId}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({
					mensaje: `Últimos registros del usuario con el id '${usuarioId}' obtenidos con éxito.`,
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: mcRespuesta
				});
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener últimos registros del usuario con el id '${usuarioId}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};
