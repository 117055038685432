import Interfaz from 'modelo/Interfaz';
import mcLogger from '@mcsoft/logger';
import { ReduxAccion } from 'store/actions';
import { SET_INTERFAZ_BARRA_NAVEGACION_LATERAL_MOSTRAR } from './actionTypes';

const NOMBRE_CLASE = 'store/interfaz/reducer';

const stateInicial: Interfaz = {
	barraNavegacionLateralMostrar: false
};

export default (state = stateInicial, action: ReduxAccion) => {
	switch (action.type) {
		case SET_INTERFAZ_BARRA_NAVEGACION_LATERAL_MOSTRAR:
			const { barraNavegacionLateralMostrar } = action.payload;
			state = {
				...state,
				barraNavegacionLateralMostrar
			};
			mcLogger.redux({
				mensaje: 'Seteando state "barraNavegacionLateralMostrar":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_INTERFAZ_BARRA_NAVEGACION_LATERAL_MOSTRAR,
				objetoExtra: barraNavegacionLateralMostrar
			});
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
