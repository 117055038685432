import Avaluo from 'modelo/Avaluo';
import encabezado from './encabezado';
import marcoPagina from './marcoPagina';
import reporteFotografico from './reporteFotografico';
import Usuario from 'modelo/Usuario';

const pagina = ({ avaluo, rellenarEspaciosSinFoto, tipoEncabezado, usuario }: { avaluo: Avaluo; rellenarEspaciosSinFoto: boolean; tipoEncabezado: string; usuario: Usuario }) =>
	new Promise((resolve) => {
		const promesas = [];
		promesas.push(encabezado({ avaluo, tipoEncabezado, usuario }));
		promesas.push(reporteFotografico({ avaluo, rellenarEspaciosSinFoto }));
		Promise.all(promesas).then((secciones) => {
			const contenido = [].concat.apply([], secciones as any);
			resolve(marcoPagina(contenido));
		});
	});

export default pagina;
