import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información de la zona de moment.
 */
const InformacionMomentFechaZona = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Utilizado para definir la zona de la librería moment.js')}</p>
			<ul>
				<li>
					<span className="badge bg-info text-wrap">America/Bahia_Banderas</span> {texto('Jalisco')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">America/Tijuana</span> {texto('Tijuana')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">America/Hermosillo</span> {texto('Sonora')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">America/Mazatlan</span> {texto('Sinaloa')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">America/Chihuaha</span> {texto('Chihuaha')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">America/Monterrey</span> {texto('Monterrey')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">America/Mexico_City</span> {texto('Ciudad de México')}{' '}
				</li>
			</ul>
		</div>
	</Fragment>
);

export default InformacionMomentFechaZona;
