import 'moment/locale/es-mx';
import moment from 'moment-timezone';
import { StateType } from 'store';
import { useSelector } from 'react-redux';

/**
 * Instancia de moment.js configurada con la zona horaria y el formato según la configuración del sistema.
 */
const useFecha = () => {
	const { momentFechaFormato, momentFechaLocale, momentFechaZona } = useSelector((state: StateType) => state.configuracionAplicacion);
	moment.locale(momentFechaLocale);
	return (fecha?: Date | null, formato?: string) => {
		const formatoFechaFinal = formato ? formato : momentFechaFormato;
		if (fecha) {
			return moment(fecha).tz(momentFechaZona).locale(false).format(formatoFechaFinal);
		} else {
			return '';
		}
	};
};

export default useFecha;
