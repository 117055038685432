import { Col, Row } from 'reactstrap';
import { McCampoTexto } from '@mcsoft/formulario';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';
import UsuarioRol from 'modelo/UsuarioRol';
import { validarUsuarioRolDisponibilidad } from 'servicios/api/usuariosRoles';

interface RolFormularioProps {
	/**
	 * Indica si los detalles serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	detallesEditables?: boolean;
	/**
	 * Objeto con la información del rol.
	 */
	registro: UsuarioRol;
}

/**
 * Formulario para un rol.
 */
const RolFormulario = (props: RolFormularioProps) => {
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { detallesEditables = false, registro } = props;

	/**
	 * Válida el nombre de usuario.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioNombre = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarUsuarioRolDisponibilidad({ auth0AccessToken, campo: 'nombre', id: valores.id, valor });
		if (!disponible) {
			return 'Ya existe un rol con ese nombre';
		}
	};

	return (
		<Row>
			<Col md="12">
				{!detallesEditables && (
					<Row>
						<Col md="12">
							<h3>{registro?.nombre}</h3>
							<p className="card-text">{registro?.descripcion}</p>
						</Col>
					</Row>
				)}
				{detallesEditables && (
					<Row>
						<Col md="6">
							<McCampoTexto campo="nombre" etiqueta={texto('Nombre')} funcionValidacionAsincrona={validarFormularioNombre} id="campoNombre" longitudMaxima={100} obligatorio />
						</Col>
						<Col md="12">
							<McCampoTexto campo="descripcion" etiqueta={texto('Descripción')} id="campoDescripcion" longitudMaxima={1000} obligatorio renglones={5} tipo="areaTexto" />
						</Col>
					</Row>
				)}
			</Col>
		</Row>
	);
};

export default RolFormulario;
