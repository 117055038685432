import {
	SET_IMAGENES_TEMPORALES_FOTO_AVALUO,
	SET_IMAGENES_TEMPORALES_FOTO_CROQUIS,
	SET_IMAGENES_TEMPORALES_FOTO_MAPA,
	SET_IMAGENES_TEMPORALES_FOTO_MAPA_PERSONALIZADO,
	SET_IMAGENES_TEMPORALES_FOTO_PERFIL,
	SET_IMAGENES_TEMPORALES_FOTO_PERITO_LICENCIA
} from './actionTypes';
import ImagenesTemporales from 'modelo/ImagenesTemporales';
import mcLogger from '@mcsoft/logger';
import { ReduxAccion } from 'store/actions';

const NOMBRE_CLASE = 'store/imagenesTemporales/reducer';

const stateInicial: ImagenesTemporales = {
	fotosAvaluo: {},
	fotosCroquis: {},
	fotosMapas: {},
	fotosMapasPersonalizados: {},
	fotosPerfil: {},
	fotosPeritoLicencia: {}
};

export default (state = stateInicial, action: ReduxAccion) => {
	switch (action.type) {
		case SET_IMAGENES_TEMPORALES_FOTO_AVALUO:
			const { fotoId: fotoIdAvaluo, imagenBase64: fotoBase64 } = action.payload;
			state.fotosAvaluo[fotoIdAvaluo] = fotoBase64;
			state = {
				...state,
				fotosAvaluo: { ...state.fotosAvaluo }
			};
			mcLogger.redux({
				mensaje: 'Seteando state "fotosAvaluo":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_IMAGENES_TEMPORALES_FOTO_AVALUO,
				objetoExtra: fotoIdAvaluo
			});
			break;
		case SET_IMAGENES_TEMPORALES_FOTO_CROQUIS:
			const { avaluoId: avaluoIdCroquis, imagenBase64: croquisBase64 } = action.payload;
			state.fotosCroquis[avaluoIdCroquis] = croquisBase64;
			state = {
				...state,
				fotosCroquis: { ...state.fotosCroquis }
			};
			mcLogger.redux({
				mensaje: 'Seteando state "fotosCroquis":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_IMAGENES_TEMPORALES_FOTO_CROQUIS,
				objetoExtra: avaluoIdCroquis
			});
			break;
		case SET_IMAGENES_TEMPORALES_FOTO_MAPA:
			const { avaluoId: avaluoIdMapa, imagenBase64: mapaBase64 } = action.payload;
			state.fotosMapas[avaluoIdMapa] = mapaBase64;
			state = {
				...state,
				fotosMapas: { ...state.fotosMapas }
			};
			mcLogger.redux({
				mensaje: 'Seteando state "fotosMapas":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_IMAGENES_TEMPORALES_FOTO_MAPA,
				objetoExtra: avaluoIdMapa
			});
			break;
		case SET_IMAGENES_TEMPORALES_FOTO_MAPA_PERSONALIZADO:
			const { avaluoId: avaluoIdMapaPersonalizado, imagenBase64: mapaPersonalizadoBase64 } = action.payload;
			state.fotosMapasPersonalizados[avaluoIdMapaPersonalizado] = mapaPersonalizadoBase64;
			state = {
				...state,
				fotosMapasPersonalizados: { ...state.fotosMapasPersonalizados }
			};
			mcLogger.redux({
				mensaje: 'Seteando state "fotosMapasPersonalizados":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_IMAGENES_TEMPORALES_FOTO_MAPA_PERSONALIZADO,
				objetoExtra: avaluoIdMapaPersonalizado
			});
			break;
		case SET_IMAGENES_TEMPORALES_FOTO_PERFIL:
			const { imagenBase64: fotoPerfilBase64, usuarioId } = action.payload;
			state.fotosPerfil[usuarioId] = fotoPerfilBase64;
			state = {
				...state,
				fotosPerfil: { ...state.fotosPerfil }
			};
			mcLogger.redux({
				mensaje: 'Seteando state "fotosPerfil":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_IMAGENES_TEMPORALES_FOTO_PERFIL,
				objetoExtra: usuarioId
			});
			break;
		case SET_IMAGENES_TEMPORALES_FOTO_PERITO_LICENCIA:
			const { imagenBase64: fotoPeritoLicenciaBase64, peritoId } = action.payload;
			state.fotosPeritoLicencia[peritoId] = fotoPeritoLicenciaBase64;
			state = {
				...state,
				fotosPeritoLicencia: { ...state.fotosPeritoLicencia }
			};
			mcLogger.redux({
				mensaje: 'Seteando state "fotosPeritoLicencia":',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_IMAGENES_TEMPORALES_FOTO_PERITO_LICENCIA,
				objetoExtra: peritoId
			});
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
