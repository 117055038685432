import './McSwitchRedondo.scss';
import { Field } from 'react-final-form';
import Switch from 'react-switch';
import { Tooltip } from 'reactstrap';
import { useState } from 'react';

interface McSwitchRedondoProps {
	/**
	 * Nombre del campo del formulario donde se guardará el valor del input.
	 */
	campo: string;
	/**
	 * Código hexadecimal del color que tomara el switch cuando este apagado.
	 *
	 * > ***Predeterminado:*** *'#888'*
	 *
	 > **Nota:** Acepta 3 o 6 dígitos, ejemplo: #888, #45abcd.
	 */
	colorApagado?: string;
	/**
	 * Código hexadecimal del color que tomara el switch cuando este encendido.
	 *
	 * > ***Predeterminado:*** *'#080'*
	 *
	 > **Nota:** Acepta 3 o 6 dígitos, ejemplo: #888, #45abcd.
	 */
	colorEncendido?: string;
	/**
	 * Etiqueta que se mostrará sobre el input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	etiqueta?: string;
	/**
	 * Indica si la etiqueta se mostrará a la derecha del input en vez de arriba.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	etiquetaLineal?: boolean;
	/**
	 * Icono *FontAwesome* que se mostrará cuando el switch este apagado.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	iconoApagado?: string;
	/**
	 * Icono *FontAwesome* que se mostrará cuando el switch este encendido.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	iconoEncendido?: string;
	/**
	 * Icono *FontAwesome* que se mostrará junto a la etiqueta si hay información para mostrar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-info-circle'*
	 */
	iconoInformacion?: string;
	/**
	 * Identificador único del componente.
	 */
	id: string;
	/**
	 * Contenido que se mostrará al colocar el cursor en el icono de información.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	informacion?: any;
	/**
	 * Indica si el input será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Input booleano con forma de switch redondo.
 */
export const McSwitchRedondo = (props: McSwitchRedondoProps): JSX.Element => {
	const [mostrarTooltip, setMostrarTooltip] = useState(false);
	const {
		campo,
		colorApagado = '#888',
		colorEncendido = '#080',
		etiqueta,
		etiquetaLineal = false,
		iconoApagado,
		iconoEncendido,
		iconoInformacion = 'fa-solid fa-info-circle',
		id,
		informacion,
		soloLectura = false,
		...rest
	} = props;

	const iconoApagadoContenedor = () => (
		<div
			style={{
				alignItems: 'center',
				color: '#fff',
				display: 'flex',
				fontSize: 12,
				height: '100%',
				justifyContent: 'center',
				paddingRight: 2
			}}
		>
			<i className={iconoApagado}></i>
		</div>
	);

	const iconoEncendidoContenedor = () => (
		<div
			style={{
				alignItems: 'center',
				color: '#fff',
				display: 'flex',
				fontSize: 12,
				height: '100%',
				justifyContent: 'center',
				paddingRight: 2
			}}
		>
			<i className={iconoEncendido}></i>
		</div>
	);

	return (
		<Field name={campo}>
			{({ input }) => {
				const { onChange, value } = input;
				return (
					<div className="mc-switch-redondo">
						{informacion && (
							<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
								{informacion}
							</Tooltip>
						)}
						<div>
							{etiqueta && !etiquetaLineal && (
								<label className="form-label" htmlFor={id}>
									{etiqueta}
								</label>
							)}
							{informacion && !etiquetaLineal && (
								<span className="text-primary">
									{' '}
									<i className={iconoInformacion} id={`${id}_tooltip`}></i>
								</span>
							)}
						</div>
						<Switch
							checked={value ? value : false}
							checkedIcon={iconoEncendidoContenedor()}
							disabled={soloLectura}
							id={id}
							offColor={colorApagado}
							onChange={onChange}
							onColor={colorEncendido}
							uncheckedIcon={iconoApagadoContenedor()}
							{...rest}
						/>
						{etiqueta && etiquetaLineal && (
							<label className="form-label mc-switch-redondo__etiqueta--lineal" htmlFor={id}>
								{etiqueta}
							</label>
						)}
						{informacion && etiquetaLineal && (
							<span className="text-primary mc-switch-redondo__informacion--lineal">
								{' '}
								<i className={iconoInformacion} id={`${id}_tooltip`}></i>
							</span>
						)}
					</div>
				);
			}}
		</Field>
	);
};
