import { actualizarConfiguracionAplicacion, obtenerConfiguracionAplicacion } from 'servicios/api/configuracionAplicacion';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { getConfiguracionAplicacionAction, setPantallaCargaMostrarAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import AplicacionFormulario from 'componentes/administracion/AplicacionFormulario';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import ConfiguracionAplicacion from 'modelo/ConfiguracionAplicacion';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { procesarError } from '@mcsoft/api';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useHistory } from 'react-router';

const NOMBRE_CLASE = 'paginas/app/administracion/AplicacionConfiguracion';

/**
 * En esta página se puede cambiar la configuración de la aplicación.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const { sesion } = useSelector((state: StateType) => state);
	const [formulario, setFormulario] = useState<any>();
	const { auth0, usuario } = sesion;
	const { auth0AccessToken } = auth0;

	useEffect(() => {
		obtenerRegistro();
	}, []);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		const ruta = constantes.ruta.appInicio;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	};

	/**
	 * Guarda la información del registro.
	 * - ***valores*** - Valores a guardar.
	 */
	const eventoGuardar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Guardando la configuración de la aplicación...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const configuracionAplicacion = valores as ConfiguracionAplicacion;
			dispatch(setPantallaCargaMostrarAction(true));
			await actualizarConfiguracionAplicacion({ auth0AccessToken, id: valores.id, valores: configuracionAplicacion });
			mcLogger.log({ mensaje: `Configuración de la aplicación guardada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			mcNotificaciones.exito({ mensaje: texto(`Configuración de la aplicación guardada con éxito.`) });
			dispatch(getConfiguracionAplicacionAction(auth0AccessToken));
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la configuración de la aplicación:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Obteniendo configuración de la aplicación...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			const respuesta = await obtenerConfiguracionAplicacion(auth0AccessToken);
			mcLogger.log({ mensaje: `Configuración de la aplicación obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			setFormulario(respuesta.datos);
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener la configuración de la aplicación:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	return (
		<Contenedor>
			<Breadcrumb
				enlaces={[{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') }]}
				icono={constantes.icono.configuracionAplicacion}
				titulo={texto('Configuración de la aplicación')}
			/>
			<Form
				initialValues={formulario}
				onSubmit={eventoGuardar}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Card>
							<CardBody>
								<Row>
									<Col lg="12">
										<AplicacionFormulario usuario={usuario} />
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<BarraHerramientas>
									<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar}>
										<i className={constantes.icono.atras}></i> {texto('Cancelar')}
									</button>
									<button className="btn btn-success" id="botonGuardar" type="submit">
										<i className={constantes.icono.guardar}></i> {texto('Guardar')}
									</button>
								</BarraHerramientas>
							</CardFooter>
						</Card>
					</form>
				)}
			/>
		</Contenedor>
	);
};

export default Pagina;
