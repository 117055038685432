/* eslint-disable no-unused-vars */
import './style.scss';
import { Col, Row } from 'reactstrap';
import React, { ChangeEvent, CSSProperties, Fragment, KeyboardEvent, useEffect, useState } from 'react';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import moment from 'moment';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';
import Usuario from 'modelo/Usuario';

const CANTIDAD_BOTONES = 3;
const CODIGO_TECLA_PARA_BUSCAR = 'Enter';
const FORMATO_FECHA = 'DD/MM/YYYY';
const ICONO_BUSCAR = 'fa-solid fa-search';
const ICONO_ORDEN_ASCENDENTE = 'fa-solid fa-arrow-down-a-z';
const ICONO_ORDEN_DESCENDENTE = 'fa-solid fa-arrow-up-z-a';
const ICONO_SIN_RESULTADOS = 'fa-solid fa-list-ul';
const OPCIONES_REGISTROS_POR_PAGINA = ['5', '10', '20', '50', '100'];
const TEXTO = {
	botonAnterior: 'Anterior',
	botonPrimera: 'Primera',
	botonSiguiente: 'Siguiente',
	botonUltima: 'Última',
	buscar: 'Buscar',
	limpiar: 'Limpiar',
	pagina: 'Página',
	paginaDe: 'de',
	resultadosAl: 'al',
	resultadosDe: 'de',
	resultadosMostrando: 'Mostrando resultados del',
	resultadosPorPagina: 'Resultados por página',
	sinResultadosDescripcion: 'No se encontraron resultados.',
	sinResultadosTitulo: 'Sin resultados'
};

/**
 * Interfaz para un encabezado de la McTablaPaginada.
 */
export type McTablaPaginadaEncabezado = McTablaPaginadaEncabezadoComunes & McTablaPaginadaEncabezadoCondicionales;

interface McTablaPaginadaEncabezadoComunes {
	/**
	 * Atributo del registro que se mostrará en las celdas de la tabla.
	 *
	 * > **Nota:** Nombre del atributo en el objecto ***JavaScript***.
	 */
	atributo?: string;
	/**
	 * Nombre del campo.
	 *
	 * > **Nota:** Nombre del campo como aparece en la base de datos.
	 */
	campo?: string;
	/**
	 * Clase que se aplicará en la celda de cada registro.
	 */
	claseCelda?: string;
	/**
	 * Clase que se aplicará en el encabezado.
	 */
	claseEncabezado?: string;
	/**
	 * Estilo que se aplicará en la celda de cada registro.
	 */
	estiloCelda?: CSSProperties;
	/**
	 * Estilo que se aplicará en el encabezado.
	 */
	estiloEncabezado?: CSSProperties;
	/**
	 * Evento que se ejecuta cuando se va a dibujar una celda.
	 * - ***registro*** - Objeto con la información del registro.
	 */
	eventoDibujarCeldaPersonalizada?: (registro: any) => JSX.Element;
	/**
	 * Formato para la fecha en caso de que el campo sea tipo *'fecha'*.
	 *
	 * > ***Predeterminado:*** *'DD/MM/YYYY'*
	 */
	formatoFecha?: string;
	/**
	 * Ordenamiento de Sequelize para ordenar los registros.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Nota:** Si el ordenamiento es indefinido, la tabla no podrá ordenarse por el campo de este encabezado.
	 */
	ordenamiento?: Array<Array<string>>;
	/**
	 * Título que aparecerá en el encabezado de la tabla.
	 */
	titulo?: string;
	/**
	 * Tipo de dato que se mostrará en la celda.
	 *
	 * > ***Predeterminado:*** *'texto'*
	 * > - 'fecha'
	 * > - 'imagen'
	 * > - 'texto'
	 */
	tipo?: 'fecha' | 'imagen' | 'texto';
}

type McTablaPaginadaEncabezadoCondicionales = ({ tipo: 'fecha'; formatoFecha?: string } | { tipo?: 'imagen'; formatoFecha?: never } | { tipo?: 'texto'; formatoFecha?: never }) &
	(
		| {
				eventoDibujarCeldaPersonalizada: (registro: any) => JSX.Element;
				atributo?: never;
				campo?: never;
		  }
		| { eventoDibujarCeldaPersonalizada?: never; atributo: string; campo: string }
	);

/**
 * Interfaz para los parámetros de paginación de la McTablaPaginada.
 */
export interface McTablaPaginadaPaginacion {
	/**
	 * Palabra clave para filtrar los resultados de la lista.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	criterio?: string;
	/**
	 * Orden en que se ordenaran los registros.
	 *
	 * > ***Predeterminado:*** *'asc'*
	 * > - 'asc'
	 * > - 'desc'
	 */
	orden?: string;
	/**
	 * Ordenamiento de Sequelize para ordenar los registros.
	 */
	ordenamiento: string;
	/**
	 * Página requerida.
	 */
	pagina: number;
	/**
	 * Cantidad de registros que se mostrarán por página.
	 */
	registrosPorPagina: number;
}

interface McTablaPaginadaProps {
	/**
	 * Controla la cantidad de botones de páginas que se mostrarán tanto antes como despues del botón de la página actual.
	 *
	 * > ***Predeterminado:*** *3*
	 */
	cantidadBotones?: number;
	/**
	 * Evento que se ejecuta cuando se presiona un encabezado de la tabla para ordenar los registros mostrados por un campo específico.
	 * - ***ordenamiento*** - Campo por el que se ordenarán los registros.
	 * - ***orden*** - Orden en que se mostrarán los registros.
	 *
	 * > ***Predeterminado:*** *'asc'*
	 * > - 'asc'
	 * > - 'desc'
	 */
	eventoCambiarOrdenamiento: ({ orden, ordenamiento }: { orden: string; ordenamiento?: string }) => void;
	/**
	 * Evento que se ejecuta cuando se presiona un botón de página específica.
	 * > - ***numeroPagina*** - Número de página requerida.
	 */
	eventoCambiarPaginaActual: (numeroPagina: number) => void;
	/**
	 * Evento que se ejecuta cada que hay un cambio en los parametros de paginacion.
	 * > - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
	 */
	eventoCambiarParametrosPaginacion: ({
		criterio,
		orden,
		ordenamiento,
		pagina,
		registrosPorPagina
	}: {
		criterio?: string;
		orden?: string;
		ordenamiento?: string;
		pagina?: number;
		registrosPorPagina?: number;
	}) => void;
	/**
	 * Evento que se ejecuta cuando se cambia la cantidad de registros que se mostrarán por página.
	 * > - ***cantidadRegistrosPorPagina*** - Cantidad de registros a mostrar por página.
	 */
	eventoCambiarRegistrosPorPagina: (cantidadRegistrosPorPagina: number) => void;
	/**
	 * Evento que se ejecuta cuando se hace clic sobre un registro de la tabla.
	 * > - ***registroId*** - Id del registro seleccionado.
	 * > - ***registro*** - Objeto con la informacion del registro seleccionado.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	eventoSeleccionarRegistro?: ({ registro, registroId }: { registro?: any; registroId: any }) => void;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón de buscar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-search'*
	 */
	iconoBuscar?: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el encabezado cuando los registros esten ordenados de manera ascendente.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-sort-alpha-down'*
	 */
	iconoOrdenAscendente?: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el encabezado cuando los registros esten ordenados de manera ascendente.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-sort-alpha-up'*
	 */
	iconoOrdenDescendente?: string;
	/**
	 * Icono *FontAwesome* que se mostrará la lista de registros recibida este vacía.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-list-ul'*
	 */
	iconoSinResultados?: string;
	/**
	 * Criterio de busqueda para los párametros de paginación.
	 */
	registrosCriterio: string;
	/**
	 * Arreglo de objetos con la configuración de los encabezados de la tabla.
	 */
	registrosEncabezados: Array<McTablaPaginadaEncabezado>;
	/**
	 * Arreglo de objetos con los registros que se mostrarán en la tabla.
	 */
	registrosLista: Array<any>;
	/**
	 * Último registro mostrado en la página actual.
	 */
	registrosMostrandoFinal: number;
	/**
	 * Primer registro mostrado en la página actual.
	 */
	registrosMostrandoInicial: number;
	/**
	 * Cantidad de registros que serán mostrados en cada página.
	 */
	registrosMostrandoPorPagina: number;
	/**
	 * Arreglo de cadenas con las opciones a mostrar de registros por página.
	 *
	 * > ***Predeterminado:*** *['5', '10', '20','50','100']*
	 */
	registrosMostrandoPorPaginaOpciones?: Array<string>;
	/**
	 * Orden en que se mostrarán los registros.
	 *
	 * > ***Predeterminado:*** *'asc'*
	 * > - 'asc'
	 * > - 'desc'
	 */
	registrosOrden?: string;
	/**
	 * Ordenamiento de Sequelize para ordenar los registros.
	 */
	registrosOrdenamiento: string;
	/**
	 * Página actualmente seleccionada.
	 */
	registrosPaginaActual: number;
	/**
	 * Última página existente.
	 */
	registrosPaginaFinal: number;
	/**
	 * Cantidad total de registros.
	 */
	registrosTotal: number;
	/**
	 * Objeto con los textos personalizados del componente.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	texto?: McTablaPaginadaTexto;
}

export interface McTablaPaginadaTexto {
	/**
	 * Texto que se mostrará en el botón de página anterior.
	 *
	 * > ***Predeterminado:*** *'Anterior'*
	 */
	botonAnterior?: string;
	/**
	 * Texto que se mostrará en el botón de la primera página.
	 *
	 * > ***Predeterminado:*** *'Primera'*
	 */
	botonPrimera?: string;
	/**
	 * Texto que se mostrará en el botón de página siguiente.
	 *
	 * > ***Predeterminado:*** *'Siguiente'*
	 */
	botonSiguiente?: string;
	/**
	 * Texto que se mostrará en el botón de la última página.
	 *
	 * > ***Predeterminado:*** *'Última'*
	 */
	botonUltima?: string;
	/**
	 * Texto que se mostrará en el campo de texto de busqueda.
	 *
	 * > ***Predeterminado:*** *'Buscar'*
	 */
	buscar?: string;
	/**
	 * Texto que se mostrará en el botón de limpiar del campo de busqueda.
	 *
	 * > ***Predeterminado:*** *'Limpiar'*
	 */
	limpiar?: string;
	/**
	 * Parte del texto **Página** # de #.
	 *
	 * > ***Predeterminado:*** *'Página'*
	 */
	pagina?: string;
	/**
	 * Parte del texto Página # **de** #.
	 *
	 * > ***Predeterminado:*** *'de'*
	 */
	paginaDe?: string;
	/**
	 * Parte del texto Mostrando resultados del # **al** # de #.
	 *
	 * > ***Predeterminado:*** *'al'*
	 */
	resultadosAl?: string;
	/**
	 * Parte del texto Mostrando resultados del # al # **de** #.
	 *
	 * > ***Predeterminado:*** *'de'*
	 */
	resultadosDe?: string;
	/**
	 * Parte del texto **Mostrando resultados del** # al # de #.
	 *
	 * > ***Predeterminado:*** *'Mostrando resultados del'*
	 */
	resultadosMostrando?: string;
	/**
	 * Texto que se mostrará junto al selector de resultados por página.
	 *
	 * > ***Predeterminado:*** *'Resultados por página'*
	 */
	resultadosPorPagina?: string;
	/**
	 * Mensaje que se mostrará cuando la lista de registros este vacía.
	 *
	 * > ***Predeterminado:*** *'No se encontraron resultados.'*
	 */
	sinResultadosDescripcion?: string;
	/**
	 * Título que se mostrará cuando la lista de registros este vacía.
	 *
	 * > ***Predeterminado:*** *'Sin resultados'*
	 */
	sinResultadosTitulo?: string;
}

/**
 * Redirecciona al usuario a la ruta de creación.
 * - ***history*** - Historial de react-router.
 * - ***nombreClase*** - Nombre de la clase que invoca la función.
 * - ***rutaCreacion*** - Ruta de creación.
 */
export const tablaPaginadaEventoAgregar = ({ history, nombreClase, rutaCreacion }: { history: any; nombreClase: string; rutaCreacion: string }): void => {
	const nombreMetodo = 'tablaPaginadaEventoAgregar';
	const ruta = rutaCreacion;
	mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: nombreClase, nombreMetodo, objetoExtra: ruta });
	history.push(ruta);
};

/**
 * Cambia el ordenamiento de los registros.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***history*** - Historial de react-router.
 * - ***nombreClase*** - Nombre de la clase que invoca la función.
 * - ***orden*** - Orden en que se mostrarán los registros.
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página requerida.
 * - ***registrosPorPagina*** - Cantidad de registros que se mostrarán por página.
 */
export const tablaPaginadaEventoCambiarOrdenamiento = ({
	criterio,
	history,
	nombreClase,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	history: any;
	nombreClase: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): void => {
	const nombreMetodo = 'tablaPaginadaEventoCambiarOrdenamiento';
	if (ordenamiento) {
		const ruta = `${location.pathname}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: nombreClase, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	}
};

/**
 * Cambia la página actual.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***history*** - Historial de react-router.
 * - ***nombreClase*** - Nombre de la clase que invoca la función.
 * - ***orden*** - Orden en que se mostrarán los registros.
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página requerida.
 * - ***registrosPorPagina*** - Cantidad de registros que se mostrarán por página.
 */
export const tablaPaginadaEventoCambiarPaginaActual = ({
	criterio,
	history,
	nombreClase,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	history: any;
	nombreClase: string;
	orden?: string;
	ordenamiento?: string | undefined;
	pagina?: number;
	registrosPorPagina?: number;
}): void => {
	const nombreMetodo = 'tablaPaginadaEventoCambiarPaginaActual';
	const ruta = `${location.pathname}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
	mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: nombreClase, nombreMetodo, objetoExtra: ruta });
	history.push(ruta);
};

/**
 * Cambia los parámetros de paginación en la ruta url.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***history*** - Historial de react-router.
 * - ***nombreClase*** - Nombre de la clase que invoca la función.
 * - ***orden*** - Orden en que se mostrarán los registros.
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***pagina*** - Página requerida.
 * - ***registrosPorPagina*** - Cantidad de registros que se mostrarán por página.
 */
export const tablaPaginadaEventoCambiarParametrosPaginacion = ({
	criterio,
	history,
	nombreClase,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	criterio?: string;
	history: any;
	nombreClase: string;
	orden?: string;
	ordenamiento?: string | undefined;
	pagina?: number;
	registrosPorPagina?: number;
}): void => {
	const nombreMetodo = 'tablaPaginadaEventoCambiarParametrosPaginacion';
	const ruta = `${location.pathname}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
	mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: nombreClase, nombreMetodo, objetoExtra: ruta });
	history.push(ruta);
};

/**
 * Cambia la cantidad de registros mostrados por página.
 * - ***criterio*** - Palabra clave para filtrar los resultados de la lista.
 * - ***history*** - Historial de react-router.
 * - ***nombreClase*** - Nombre de la clase que invoca la función.
 * - ***orden*** - Orden en que se mostrarán los registros.
 * - ***ordenamiento*** - Ordenamiento de Sequelize para ordenar los registros.
 * - ***registrosPorPagina*** - Cantidad de registros que se mostrarán por página.
 */
export const tablaPaginadaEventoCambiarRegistrosPorPagina = ({
	criterio,
	history,
	nombreClase,
	orden,
	ordenamiento,
	registrosPorPagina
}: {
	criterio?: string;
	history: any;
	nombreClase: string;
	orden?: string;
	ordenamiento?: string | undefined;
	registrosPorPagina?: number;
}): void => {
	const nombreMetodo = 'tablaPaginadaEventoCambiarRegistrosPorPagina';
	const ruta = `${location.pathname}?pagina=${1}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
	mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: nombreClase, nombreMetodo, objetoExtra: ruta });
	history.push(ruta);
};

/**
 * Redirecciona al usuario a la ruta de salida.
 * - ***history*** - Historial de react-router.
 * - ***nombreClase*** - Nombre de la clase que invoca la función.
 * - ***rutaSalir*** - Ruta a la que se redireccionará.
 */
export const tablaPaginadaEventoCancelar = ({ history, nombreClase, rutaSalir }: { history: any; nombreClase: string; rutaSalir: string }): void => {
	const nombreMetodo = 'tablaPaginadaEventoCancelar';
	mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: nombreClase, nombreMetodo, objetoExtra: rutaSalir });
	history.push(rutaSalir);
};

/**
 * Redirecciona a la ruta de los detalles del registro.
 * - ***history*** - Historial de react-router.
 * - ***nombreClase*** - Nombre de la clase que invoca la función.
 * - ***permiso*** - Permiso requerido.
 * - ***registroId*** - Id del registro seleccionado.
 * - ***ruta*** - Ruta a direccionar.
 * - ***usuario*** - Objeto con la información del usuario en sesión.
 */
export const tablaPaginadaEventoSeleccionarRegistro = ({
	history,
	nombreClase,
	permiso,
	registroId,
	ruta,
	usuario
}: {
	history: any;
	nombreClase: string;
	permiso: string;
	registroId: any;
	ruta: string;
	usuario: Usuario;
}): void => {
	const nombreMetodo = 'tablaPaginadaEventoSeleccionarRegistro';
	if (tienePermiso({ permiso, usuario })) {
		const rutaRegistro = `${ruta}/${registroId}`;
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: nombreClase, nombreMetodo, objetoExtra: rutaRegistro });
		history.push(rutaRegistro);
	} else {
		mcNotificaciones.advertencia({ mensaje: texto('No tienes permiso para acceder a la sección de edición del tipo de inmueble.') });
	}
};

/**
 * Componente de React que genera una tabla paginada la cual puede ser ordenada por los campos de la misma, asi como filtrar los resultados.
 */
const McTablaPaginada = (props: McTablaPaginadaProps): JSX.Element => {
	const [campoBuscar, setCampoBuscar] = useState('');
	const {
		cantidadBotones = CANTIDAD_BOTONES,
		eventoCambiarOrdenamiento,
		eventoCambiarPaginaActual,
		eventoCambiarParametrosPaginacion,
		eventoCambiarRegistrosPorPagina,
		eventoSeleccionarRegistro = () => {},
		iconoBuscar = ICONO_BUSCAR,
		iconoOrdenAscendente = ICONO_ORDEN_ASCENDENTE,
		iconoOrdenDescendente = ICONO_ORDEN_DESCENDENTE,
		iconoSinResultados = ICONO_SIN_RESULTADOS,
		registrosCriterio,
		registrosEncabezados,
		registrosLista,
		registrosMostrandoFinal,
		registrosMostrandoInicial,
		registrosMostrandoPorPagina,
		registrosMostrandoPorPaginaOpciones = OPCIONES_REGISTROS_POR_PAGINA,
		registrosOrden,
		registrosOrdenamiento,
		registrosPaginaActual,
		registrosPaginaFinal,
		registrosTotal
	} = props;
	let { texto } = props;
	texto = { ...TEXTO, ...texto };

	useEffect(() => {
		setCampoBuscar(registrosCriterio);
	}, [registrosCriterio]);

	/**
	 * Crea los botones de las páginas siguientes a la actual.
	 * - ***cantidadBotones*** Cantidad de botones.
	 */
	const crearBotonesAdelante = (cantidadBotones: number): Array<React.ReactNode> => {
		const paginaSiguiente = registrosPaginaActual + 1;
		let primerBotonAtras = registrosPaginaActual - cantidadBotones;
		if (primerBotonAtras < 1) primerBotonAtras = 1;
		let ultimoBotonAdelante = registrosPaginaActual + cantidadBotones;
		if (ultimoBotonAdelante > registrosPaginaFinal) ultimoBotonAdelante = registrosPaginaFinal;
		const botonesExtraAdelante = cantidadBotones - (registrosPaginaActual - primerBotonAtras);
		let ultimoBoton = ultimoBotonAdelante + botonesExtraAdelante;
		if (ultimoBoton > registrosPaginaFinal) ultimoBoton = registrosPaginaFinal;
		const botonesAdelante = [];
		for (let i = paginaSiguiente; i <= ultimoBoton; i++) {
			botonesAdelante.push(
				<button className="btn btn-default" id={`botonPagina${i}`} key={`botonPagina${i}`} onClick={(): void => eventoCambiarPaginaActual(i)} type="button">
					{i}
				</button>
			);
		}
		return botonesAdelante;
	};

	/**
	 * Crea los botones de las páginas anteriores a la actual.
	 * - ***cantidadBotones*** Cantidad de botones.
	 */
	const crearBotonesAtras = (cantidadBotones: number): Array<React.ReactNode> => {
		const paginaAnterior = registrosPaginaActual - 1;
		let primerBotonAtras = registrosPaginaActual - cantidadBotones;
		if (primerBotonAtras < 1) primerBotonAtras = 1;
		let ultimoBotonAdelante = registrosPaginaActual + cantidadBotones;
		if (ultimoBotonAdelante > registrosPaginaFinal) ultimoBotonAdelante = registrosPaginaFinal;
		const botonesExtraAtras = cantidadBotones - (ultimoBotonAdelante - registrosPaginaActual);
		let primerBoton = primerBotonAtras - botonesExtraAtras;
		if (primerBoton < 1) primerBoton = 1;
		const botonesAtras = [];
		for (let i = primerBoton; i <= paginaAnterior; i++) {
			botonesAtras.push(
				<button className="btn btn-default" id={`botonPagina${i}`} key={`botonPagina${i}`} onClick={(): void => eventoCambiarPaginaActual(i)} type="button">
					{i}
				</button>
			);
		}
		return botonesAtras;
	};

	/**
	 * Regresa el encabezado (<th></th>) con icono de ordenamiento en caso de ser necesario.
	 * - ***encabezado*** - Objeto con la información del encabezado.
	 * - ***indice*** - Indice del encabezado.
	 */
	const crearEncabezadoTabla = ({ encabezado, indice }: { encabezado: McTablaPaginadaEncabezado; indice: number }): React.ReactNode => {
		const { claseEncabezado, estiloEncabezado, ordenamiento, titulo } = encabezado;
		const estiloPrederterminado: React.CSSProperties = { textAlign: 'center' };
		let nuevoOrden = 'asc';
		const ordenamientoCadena = JSON.stringify(ordenamiento);
		if (registrosOrdenamiento === ordenamientoCadena) {
			nuevoOrden = registrosOrden === 'asc' ? 'desc' : 'asc';
		}
		return titulo ? (
			<th
				className={`${ordenamientoCadena ? 'mc-tabla-paginada__encabezado-ordenable' : ''} ${claseEncabezado}`}
				key={`encabezado_${indice}`}
				onClick={(): void => eventoCambiarOrdenamiento({ orden: nuevoOrden, ordenamiento: ordenamientoCadena })}
				style={{ ...estiloPrederterminado, ...estiloEncabezado }}
			>
				{titulo} {obtenerIconoOrdenamiento(ordenamientoCadena)}
			</th>
		) : null;
	};

	/**
	 * Regresa el renglón (<tr></tr>) con todas las celdas (<tr></tr>) del registro.
	 * - ***registro*** - Objeto con la información del registro.
	 */
	const crearRenglonTabla = (registro: any): React.ReactNode => (
		<tr className="mc-tabla-paginada__renglon" key={registro.id} onClick={(): void => eventoSeleccionarRegistro({ registro, registroId: registro.id })}>
			{registrosEncabezados.map((encabezado, indice) => {
				let valor = null;
				if (encabezado.atributo) {
					const atributos = encabezado.atributo.split('.');
					if (atributos.length === 1) {
						valor = registro[atributos[0]];
					}
					if (atributos.length === 2) {
						valor = registro[atributos[0]] && registro[atributos[0]][atributos[1]] ? registro[atributos[0]][atributos[1]] : '';
					}
					if (atributos.length === 3) {
						valor =
							registro[atributos[0]] && registro[atributos[0]][atributos[1]] && registro[atributos[0]][atributos[1]][atributos[2]]
								? registro[atributos[0]][atributos[1]][atributos[2]]
								: '';
					}
				}
				if (encabezado.tipo === 'fecha') {
					valor = valor ? moment(valor).format(encabezado.formatoFecha ? encabezado.formatoFecha : FORMATO_FECHA) : '';
				}
				if (encabezado.eventoDibujarCeldaPersonalizada) {
					valor = encabezado.eventoDibujarCeldaPersonalizada(registro);
				}
				return (
					<td
						className={`${encabezado.claseCelda ? encabezado.claseCelda : ''} ${encabezado.tipo === 'imagen' ? 'mc-tabla-paginada__celda--imagen' : ''}`}
						key={`${registro.id}_${indice}`}
						style={encabezado.estiloCelda}
					>
						{valor}
					</td>
				);
			})}
		</tr>
	);

	/**
	 * Regresa el arreglo de opciones del select resultados por página.
	 */
	const crearResultadosPorPagina = () => {
		const opcionesSelect: Array<React.ReactNode> = [];
		let opcionSeleccionada = '';
		registrosMostrandoPorPaginaOpciones.forEach((opcion, index) => {
			if (registrosMostrandoPorPagina === Number(opcion)) {
				opcionSeleccionada = opcion;
			}
			opcionesSelect.push(
				<option key={`mostrandoPorPagina_${index}`} value={opcion}>
					{opcion}
				</option>
			);
		});
		return (
			<select className="m-l-md" name="resultadosPorPagina" onChange={(evento): void => eventoCambiarRegistrosPorPagina(Number(evento.target.value))} value={opcionSeleccionada}>
				{opcionesSelect}
			</select>
		);
	};

	/**
	 * Regresa el icono de ordenamiento solo si el nombre del campo es el criterio de ordenamiento actual.
	 * - ***ordenamiento*** Nombre del campo de ordenamiento.
	 */
	const obtenerIconoOrdenamiento = (ordenamiento?: string): React.ReactNode => {
		if (ordenamiento === registrosOrdenamiento) {
			return registrosOrden === 'asc' ? <i className={iconoOrdenAscendente}></i> : <i className={iconoOrdenDescendente}></i>;
		}
	};

	/**
	 * Busca en la lista de registros los resultados que concuerden con el criterio de búsqueda.
	 */
	const eventoBuscarResultados = (): void => {
		eventoCambiarPaginaActual(1);
		const parametrosPaginacion: McTablaPaginadaPaginacion = {
			criterio: campoBuscar,
			orden: registrosOrden,
			ordenamiento: registrosOrdenamiento,
			pagina: 1,
			registrosPorPagina: registrosMostrandoPorPagina
		};
		eventoCambiarParametrosPaginacion(parametrosPaginacion);
	};

	/**
	 * Cambia el valor del campo buscar y setea su valor en el state local del componente.
	 * - ***evento*** Evento que ejecuta la función.
	 */
	const eventoCambioCampoBuscar = (evento: ChangeEvent<HTMLInputElement>): void => {
		setCampoBuscar(evento.target.value);
	};

	/**
	 * Limpia el cambo de busqueda y realiza la busqueda de resultados.
	 */
	const eventoLimpiar = (): void => {
		setCampoBuscar('');
		const parametrosPaginacion: McTablaPaginadaPaginacion = {
			criterio: '',
			orden: registrosOrden,
			ordenamiento: registrosOrdenamiento,
			pagina: 1,
			registrosPorPagina: registrosMostrandoPorPagina
		};
		eventoCambiarParametrosPaginacion(parametrosPaginacion);
	};

	/**
	 * Si la tecla presionada es Enter realiza la busqueda.
	 * - ***evento*** Evento que ejecuta la función.
	 */
	const eventoTeclaPresionada = (evento: KeyboardEvent<HTMLInputElement>): void => {
		const tecla = evento.key;
		if (tecla === CODIGO_TECLA_PARA_BUSCAR) {
			eventoBuscarResultados();
		}
	};

	const paginaAnterior = registrosPaginaActual - 1;
	const paginaSiguiente = registrosPaginaActual + 1;
	const botonesAtras = crearBotonesAtras(cantidadBotones);
	const botonesAdelante = crearBotonesAdelante(cantidadBotones);
	return (
		<Fragment>
			<Row className="mc-tabla-paginada__barra-herramientas">
				<Col md="6">
					<label>{texto.resultadosPorPagina}</label>
					{crearResultadosPorPagina()}
				</Col>
				<Col md="6">
					<div className="input-group m-b">
						<span className="input-group-prepend">
							<button className="btn btn-primary" onClick={eventoBuscarResultados} type="button">
								<i className={iconoBuscar}></i>
							</button>
						</span>
						<input
							className="form-control"
							id="campoBuscar"
							onChange={eventoCambioCampoBuscar}
							onKeyPress={eventoTeclaPresionada}
							placeholder={texto.buscar}
							type="text"
							value={campoBuscar}
						/>
						<span className="input-group-append">
							<button className="btn btn-primary" onClick={eventoLimpiar} type="button">
								{texto.limpiar}
							</button>
						</span>
					</div>
				</Col>
			</Row>
			{registrosLista.length === 0 && (
				<div className="alert alert-warning mc-tabla-paginada__sin-resultados" role="alert">
					<h4 className="alert-heading mc-tabla-paginada__sin-resultados-titulo">{texto.sinResultadosTitulo}</h4>
					<span className="fa-stack fa-2x">
						<i className={`${iconoSinResultados} fa-stack-1x`} data-fa-transform="grow-15 left-4" />
						<i className="fa-solid fa-ban fa-stack-2x" data-fa-transform="left-6" />
					</span>
					<p className="mc-tabla-paginada__sin-resultados-descripcion">{texto.sinResultadosDescripcion}</p>
				</div>
			)}
			{registrosLista.length > 0 && (
				<Fragment>
					<div className="table-responsive">
						<table className="table mc-tabla-paginada table-hover table-striped" id="tabla">
							<thead>
								<tr>{registrosEncabezados.map((encabezado, indice) => crearEncabezadoTabla({ encabezado, indice }))}</tr>
							</thead>
							<tbody>{registrosLista.map((registro) => crearRenglonTabla(registro))}</tbody>
						</table>
					</div>

					<Row>
						<Col className="mc-tabla-paginada__paginacion" md="12">
							<div className="btn-group">
								<button className="btn btn-primary" disabled={registrosPaginaActual === 1} id="botonPrimeraPagina" onClick={(): void => eventoCambiarPaginaActual(1)} type="button">
									{texto.botonPrimera}
								</button>
								<button
									className="btn btn-default"
									disabled={registrosPaginaActual <= 1}
									id="botonPaginaAnterior"
									onClick={(): void => eventoCambiarPaginaActual(paginaAnterior)}
									type="button"
								>
									{texto.botonAnterior}
								</button>
								{botonesAtras}
								<button className="btn btn-primary" id="botonPaginaActual" type="button">
									{registrosPaginaActual}
								</button>
								{botonesAdelante}
								<button
									className="btn btn-default"
									disabled={registrosPaginaActual >= registrosPaginaFinal}
									id="botonPaginaSiguiente"
									onClick={(): void => eventoCambiarPaginaActual(paginaSiguiente)}
									type="button"
								>
									{texto.botonSiguiente}
								</button>
								<button
									className="btn btn-primary"
									disabled={registrosPaginaActual === registrosPaginaFinal}
									id="botonUltimaPagina"
									onClick={(): void => eventoCambiarPaginaActual(registrosPaginaFinal)}
									type="button"
								>
									{texto.botonUltima}
								</button>
							</div>
						</Col>
					</Row>
				</Fragment>
			)}
			{registrosLista.length > 0 && (
				<div className="card-footer">
					{`${texto.resultadosMostrando} ${registrosMostrandoInicial} ${texto.resultadosAl} ${registrosMostrandoFinal} ${texto.resultadosDe} ${registrosTotal}.`}
					<span className="mc-tabla-paginada__pie-pagina">{`${texto.pagina} ${registrosPaginaActual} ${texto.paginaDe} ${registrosPaginaFinal}.`}</span>
				</div>
			)}
		</Fragment>
	);
};

export default McTablaPaginada;
