/**
 * Este archivo contiene las constantes de la aplicación.
 */
export default {
	color: {
		bootstrapDanger: '#f46a6a',
		bootstrapDark: '#343a40',
		bootstrapInfo: '#50a5f1',
		bootstrapLight: '#f5f6f8',
		bootstrapPink: '#e83e8c',
		bootstrapPrimary: '#5b73e8',
		bootstrapPurple: '#6f42c1',
		bootstrapSecondary: '#74788d',
		bootstrapSuccess: '#34c38f',
		bootstrapWarning: '#f1b44c',
		notificacionColorPredeterminado: '#5b73e8'
	},
	icono: {
		administracion: 'fa-solid fa-key',
		agregar: 'fa-regular fa-plus-square',
		alberca: 'fa-regular fa-water-ladder',
		alerta: 'fa-solid fa-exclamation-circle',
		antecedentes: 'fa-light fa-file-lines',
		archivoPdf: 'fa-light fa-file-pdf',
		archivoPdfAlt: 'fa-solid fa-file-pdf',
		atras: 'fa-solid fa-arrow-alt-circle-left',
		autenticando: 'fa-solid fa-user-shield',
		avaluo: 'fa-light fa-file-invoice-dollar',
		aws: 'fa-brands fa-aws',
		bloquesConstruccion: 'fa-solid fa-archway',
		calidadesConstruccion: 'fa-solid fa-building-circle-check',
		calle: 'fa-solid fa-road',
		cancelar: 'fa-solid fa-ban',
		caracteristicasUrbanas: 'fa-solid fa-car-building',
		cargando: 'fa-solid fa-spinner fa-spin',
		catalogos: 'fa-solid fa-chart-tree-map',
		cerrar: 'fa-solid fa-times-circle',
		cerrarSesion: 'fa-solid fa-sign-out-alt',
		ciudad: 'fa-solid fa-city',
		clasificacionesZona: 'fa-solid fa-badge-check',
		clonar: 'fa-light fa-copy',
		cochera: 'fa-solid fa-garage-car',
		colonia: 'fa-solid fa-house-tree',
		configuracionAplicacion: 'fa-solid fa-gear',
		configuracionAplicacionGeneral: 'fa-light fa-gear',
		configuracionSistema: 'fa-solid fa-gears',
		configuracionSistemaGeneral: 'fa-light fa-gears',
		construccionesDominantes: 'fa-solid fa-building-flag',
		contacto: 'fa-solid fa-address-book',
		contrasena: 'fa-solid fa-key',
		correo: 'fa-solid fa-mailbox',
		correoElectronico: 'fa-regular fa-envelope',
		correoElectronicoConfirmacion: 'fa-regular fa-envelope-dot',
		dashboard: 'fa-solid fa-gauge',
		datos: 'fa-solid fa-list-check',
		descargar: 'fa-solid fa-download',
		deshacer: 'fa-solid fa-arrow-rotate-left',
		destinosEspecificos: 'fa-solid fa-shop',
		domicilio: 'fa-solid fa-home',
		domicilioEntrega: 'fa-solid fa-truck',
		domicilioFactura: 'fa-regular fa-file-invoice-dollar',
		domicilioPrincipal: 'fa-solid fa-house-user',
		editar: 'fa-solid fa-edit',
		editarUsuario: 'fa-solid fa-user-edit',
		elementosConstruccion: 'fa-solid fa-trowel-bricks',
		eliminar: 'fa-solid fa-trash',
		enviar: 'fa-solid fa-paper-plane',
		escritura: 'fa-light fa-memo',
		estado: 'fa-regular fa-shield-cross',
		estadosConservacion: 'fa-regular fa-hexagon-check',
		facebook: 'fa-brands fa-facebook-f',
		fecha: 'fa-regular fa-calendar',
		foto: 'fa-solid fa-camera-retro',
		girarHorizontalmente: 'fa-solid fa-arrows-alt-h',
		girarVerticalmente: 'fa-solid fa-arrows-alt-v',
		google: 'fa-brands fa-google',
		guardar: 'fa-solid fa-save',
		id: 'fa-solid fa-id-card',
		imagen: 'fa-regular fa-image',
		imagenes: 'fa-regular fa-images',
		inicio: 'fa-solid fa-home',
		instagram: 'fa-brands fa-instagram',
		interfaz: 'fa-solid fa-toggle-on',
		leer: 'fa-solid fa-glasses',
		licencia: 'fa-light fa-id-card',
		linkedIn: 'fa-brands fa-linkedin-in',
		lista: 'fa-solid fa-list-check',
		logs: 'fa-solid fa-clipboard-list',
		mano: 'fa-regular fa-hand-back-point-up',
		mapa: 'fa-regular fa-map-location-dot',
		marcador: 'fa-regular fa-location-dot',
		menu: 'fa-solid fa-bars',
		motivosAjuste: 'fa-solid fa-slider',
		municipio: 'fa-solid fa-house-building',
		notificacion: 'fa-regular fa-bell',
		notificaciones: 'fa-light fa-bells',
		numero: 'fa-solid fa-hashtag',
		observaciones: 'fa-light fa-telescope',
		pais: 'fa-solid fa-earth-americas',
		peligro: 'fa-solid fa-skull-crossbones',
		perito: 'fa-regular fa-id-badge',
		poligono: 'fa-regular fa-draw-polygon',
		predeterminados: 'fa-solid fa-square-sliders',
		preferencias: 'fa-solid fa-sliders-h',
		regimenesPropiedad: 'fa-solid fa-ballot-check',
		regla: 'fa-regular fa-ruler',
		rol: 'fa-solid fa-user-tag',
		rosaVientos: 'fa-light fa-star-christmas',
		salir: 'fa-solid fa-arrow-alt-circle-left',
		serviciosMunicipales: 'fa-duotone fa-utility-pole-double',
		sistema: 'fa-solid fa-desktop',
		subir: 'fa-solid fa-upload',
		telefono: 'fa-solid fa-phone',
		terreno: 'fa-regular fa-mountain-sun',
		tiposCalle: 'fa-solid fa-road',
		tiposConstruccion: 'fa-solid fa-shovel',
		tiposInmueble: 'fa-light fa-house-building',
		twitter: 'fa-brands fa-twitter',
		unidadesSuperficie: 'fa-solid fa-ruler',
		usosEspecificos: 'fa-regular fa-shop',
		usuario: 'fa-solid fa-user',
		usuarioPermisos: 'fa-solid fa-user-unlock',
		usuarios: 'fa-solid fa-users',
		vientosCardinales: 'fa-regular fa-compass',
		youTube: 'fa-brands fa-youtube'
	},
	permiso: {
		appAdministracionAvaluosClonar: 'appAdministracionAvaluosClonar',
		appAdministracionAvaluosEditarDetalles: 'appAdministracionAvaluosEditarDetalles',
		appAdministracionAvaluosEliminar: 'appAdministracionAvaluosEliminar',
		appAdministracionAvaluosGenerarPdf: 'appAdministracionAvaluosGenerarPdf',
		appAdministracionAvaluosVerDetalles: 'appAdministracionAvaluosVerDetalles',
		appAdministracionAvaluosVerLista: 'appAdministracionAvaluosVerLista',
		appAdministracionCatalogosCalidadesConstruccionCrear: 'appAdministracionCatalogosCalidadesConstruccionCrear',
		appAdministracionCatalogosCalidadesConstruccionEditarDetalles: 'appAdministracionCatalogosCalidadesConstruccionEditarDetalles',
		appAdministracionCatalogosCalidadesConstruccionEliminar: 'appAdministracionCatalogosCalidadesConstruccionEliminar',
		appAdministracionCatalogosCalidadesConstruccionVerLista: 'appAdministracionCatalogosCalidadesConstruccionVerLista',
		appAdministracionCatalogosClasificacionesZonaCrear: 'appAdministracionCatalogosClasificacionesZonaCrear',
		appAdministracionCatalogosClasificacionesZonaEditarDetalles: 'appAdministracionCatalogosClasificacionesZonaEditarDetalles',
		appAdministracionCatalogosClasificacionesZonaEliminar: 'appAdministracionCatalogosClasificacionesZonaEliminar',
		appAdministracionCatalogosClasificacionesZonaVerLista: 'appAdministracionCatalogosClasificacionesZonaVerLista',
		appAdministracionCatalogosConstruccionesDominantesCrear: 'appAdministracionCatalogosConstruccionesDominantesCrear',
		appAdministracionCatalogosConstruccionesDominantesEditarDetalles: 'appAdministracionCatalogosConstruccionesDominantesEditarDetalles',
		appAdministracionCatalogosConstruccionesDominantesEliminar: 'appAdministracionCatalogosConstruccionesDominantesEliminar',
		appAdministracionCatalogosConstruccionesDominantesVerLista: 'appAdministracionCatalogosConstruccionesDominantesVerLista',
		appAdministracionCatalogosDestinosEspecificosCrear: 'appAdministracionCatalogosDestinosEspecificosCrear',
		appAdministracionCatalogosDestinosEspecificosEditarDetalles: 'appAdministracionCatalogosDestinosEspecificosEditarDetalles',
		appAdministracionCatalogosDestinosEspecificosEliminar: 'appAdministracionCatalogosDestinosEspecificosEliminar',
		appAdministracionCatalogosDestinosEspecificosVerLista: 'appAdministracionCatalogosDestinosEspecificosVerLista',
		appAdministracionCatalogosElementosConstruccionCrear: 'appAdministracionCatalogosElementosConstruccionCrear',
		appAdministracionCatalogosElementosConstruccionEditarDetalles: 'appAdministracionCatalogosElementosConstruccionEditarDetalles',
		appAdministracionCatalogosElementosConstruccionEliminar: 'appAdministracionCatalogosElementosConstruccionEliminar',
		appAdministracionCatalogosElementosConstruccionVerLista: 'appAdministracionCatalogosElementosConstruccionVerLista',
		appAdministracionCatalogosEstadosConservacionCrear: 'appAdministracionCatalogosEstadosConservacionCrear',
		appAdministracionCatalogosEstadosConservacionEditarDetalles: 'appAdministracionCatalogosEstadosConservacionEditarDetalles',
		appAdministracionCatalogosEstadosConservacionEliminar: 'appAdministracionCatalogosEstadosConservacionEliminar',
		appAdministracionCatalogosEstadosConservacionVerLista: 'appAdministracionCatalogosEstadosConservacionVerLista',
		appAdministracionCatalogosMotivosAjusteCrear: 'appAdministracionCatalogosMotivosAjusteCrear',
		appAdministracionCatalogosMotivosAjusteEditarDetalles: 'appAdministracionCatalogosMotivosAjusteEditarDetalles',
		appAdministracionCatalogosMotivosAjusteEliminar: 'appAdministracionCatalogosMotivosAjusteEliminar',
		appAdministracionCatalogosMotivosAjusteVerLista: 'appAdministracionCatalogosMotivosAjusteVerLista',
		appAdministracionCatalogosRegimenesPropiedadCrear: 'appAdministracionCatalogosRegimenesPropiedadCrear',
		appAdministracionCatalogosRegimenesPropiedadEditarDetalles: 'appAdministracionCatalogosRegimenesPropiedadEditarDetalles',
		appAdministracionCatalogosRegimenesPropiedadEliminar: 'appAdministracionCatalogosRegimenesPropiedadEliminar',
		appAdministracionCatalogosRegimenesPropiedadVerLista: 'appAdministracionCatalogosRegimenesPropiedadVerLista',
		appAdministracionCatalogosServiciosMunicipalesCrear: 'appAdministracionCatalogosServiciosMunicipalesCrear',
		appAdministracionCatalogosServiciosMunicipalesEditarDetalles: 'appAdministracionCatalogosServiciosMunicipalesEditarDetalles',
		appAdministracionCatalogosServiciosMunicipalesEliminar: 'appAdministracionCatalogosServiciosMunicipalesEliminar',
		appAdministracionCatalogosServiciosMunicipalesVerLista: 'appAdministracionCatalogosServiciosMunicipalesVerLista',
		appAdministracionCatalogosTiposCalleCrear: 'appAdministracionCatalogosTiposCalleCrear',
		appAdministracionCatalogosTiposCalleEditarDetalles: 'appAdministracionCatalogosTiposCalleEditarDetalles',
		appAdministracionCatalogosTiposCalleEliminar: 'appAdministracionCatalogosTiposCalleEliminar',
		appAdministracionCatalogosTiposCalleVerLista: 'appAdministracionCatalogosTiposCalleVerLista',
		appAdministracionCatalogosTiposConstruccionCrear: 'appAdministracionCatalogosTiposConstruccionCrear',
		appAdministracionCatalogosTiposConstruccionEditarDetalles: 'appAdministracionCatalogosTiposConstruccionEditarDetalles',
		appAdministracionCatalogosTiposConstruccionEliminar: 'appAdministracionCatalogosTiposConstruccionEliminar',
		appAdministracionCatalogosTiposConstruccionVerLista: 'appAdministracionCatalogosTiposConstruccionVerLista',
		appAdministracionCatalogosTiposInmuebleCrear: 'appAdministracionCatalogosTiposInmuebleCrear',
		appAdministracionCatalogosTiposInmuebleEditarDetalles: 'appAdministracionCatalogosTiposInmuebleEditarDetalles',
		appAdministracionCatalogosTiposInmuebleEliminar: 'appAdministracionCatalogosTiposInmuebleEliminar',
		appAdministracionCatalogosTiposInmuebleVerLista: 'appAdministracionCatalogosTiposInmuebleVerLista',
		appAdministracionCatalogosUnidadesSuperficieCrear: 'appAdministracionCatalogosUnidadesSuperficieCrear',
		appAdministracionCatalogosUnidadesSuperficieEditarDetalles: 'appAdministracionCatalogosUnidadesSuperficieEditarDetalles',
		appAdministracionCatalogosUnidadesSuperficieEliminar: 'appAdministracionCatalogosUnidadesSuperficieEliminar',
		appAdministracionCatalogosUnidadesSuperficieVerLista: 'appAdministracionCatalogosUnidadesSuperficieVerLista',
		appAdministracionCatalogosUsosEspecificosCrear: 'appAdministracionCatalogosUsosEspecificosCrear',
		appAdministracionCatalogosUsosEspecificosEditarDetalles: 'appAdministracionCatalogosUsosEspecificosEditarDetalles',
		appAdministracionCatalogosUsosEspecificosEliminar: 'appAdministracionCatalogosUsosEspecificosEliminar',
		appAdministracionCatalogosUsosEspecificosVerLista: 'appAdministracionCatalogosUsosEspecificosVerLista',
		appAdministracionCatalogosVientosCardinalesCrear: 'appAdministracionCatalogosVientosCardinalesCrear',
		appAdministracionCatalogosVientosCardinalesEditarDetalles: 'appAdministracionCatalogosVientosCardinalesEditarDetalles',
		appAdministracionCatalogosVientosCardinalesEliminar: 'appAdministracionCatalogosVientosCardinalesEliminar',
		appAdministracionCatalogosVientosCardinalesVerLista: 'appAdministracionCatalogosVientosCardinalesVerLista',
		appAdministracionConfiguracionDatos: 'appAdministracionConfiguracionDatos',
		appAdministracionConfiguracionGeneral: 'appAdministracionConfiguracionGeneral',
		appAdministracionConfiguracionImagenes: 'appAdministracionConfiguracionImagenes',
		appAdministracionDashboard: 'appAdministracionDashboard',
		appAdministracionNotificacionesCrear: 'appAdministracionNotificacionesCrear',
		appAdministracionNotificacionesEditarDetalles: 'appAdministracionNotificacionesEditarDetalles',
		appAdministracionNotificacionesEliminar: 'appAdministracionNotificacionesEliminar',
		appAdministracionNotificacionesVerLista: 'appAdministracionNotificacionesVerLista',
		appAdministracionPeritosEditarDetalles: 'appAdministracionPeritosEditarDetalles',
		appAdministracionPeritosEditarLicencia: 'appAdministracionPeritosEditarLicencia',
		appAdministracionPeritosEliminar: 'appAdministracionPeritosEliminar',
		appAdministracionPeritosVerDetalles: 'appAdministracionPeritosVerDetalles',
		appAdministracionPeritosVerLista: 'appAdministracionPeritosVerLista',
		appAdministracionRolesAsignar: 'appAdministracionRolesAsignar',
		appAdministracionRolesCrear: 'appAdministracionRolesCrear',
		appAdministracionRolesEditarDetalles: 'appAdministracionRolesEditarDetalles',
		appAdministracionRolesEditarPermisosAplicacion: 'appAdministracionRolesEditarPermisosAplicacion',
		appAdministracionRolesEditarPermisosAvaluos: 'appAdministracionRolesEditarPermisosAvaluos',
		appAdministracionRolesEditarPermisosCatalogos: 'appAdministracionRolesEditarPermisosCatalogos',
		appAdministracionRolesEditarPermisosNotificaciones: 'appAdministracionRolesEditarPermisosNotificaciones',
		appAdministracionRolesEditarPermisosPeritos: 'appAdministracionRolesEditarPermisosPeritos',
		appAdministracionRolesEditarPermisosRoles: 'appAdministracionRolesEditarPermisosRoles',
		appAdministracionRolesEditarPermisosUsuarios: 'appAdministracionRolesEditarPermisosUsuarios',
		appAdministracionRolesEliminar: 'appAdministracionRolesEliminar',
		appAdministracionRolesVerDetalles: 'appAdministracionRolesVerDetalles',
		appAdministracionRolesVerLista: 'appAdministracionRolesVerLista',
		appAdministracionUsuariosEditarCorreoElectronico: 'appAdministracionUsuariosEditarCorreoElectronico',
		appAdministracionUsuariosEditarDetalles: 'appAdministracionUsuariosEditarDetalles',
		appAdministracionUsuariosEditarFoto: 'appAdministracionUsuariosEditarFoto',
		appAdministracionUsuariosVerDetalles: 'appAdministracionUsuariosVerDetalles',
		appAdministracionUsuariosVerLista: 'appAdministracionUsuariosVerLista',
		appAvaluosClonar: 'appAvaluosClonar',
		appAvaluosCrear: 'appAvaluosCrear',
		appAvaluosEditarDetalles: 'appAvaluosEditarDetalles',
		appAvaluosEliminar: 'appAvaluosEliminar',
		appAvaluosGenerarPdf: 'appAvaluosGenerarPdf',
		appAvaluosVerDetalles: 'appAvaluosVerDetalles',
		appAvaluosVerLista: 'appAvaluosVerLista',
		appPeritosCrear: 'appPeritosCrear',
		appPeritosEditarDetalles: 'appPeritosEditarDetalles',
		appPeritosEditarLicencia: 'appPeritosEditarLicencia',
		appPeritosEliminar: 'appPeritosEliminar',
		appPeritosVerDetalles: 'appPeritosVerDetalles',
		appPeritosVerLista: 'appPeritosVerLista',
		desarrollo: 'desarrollo',
		sysAdmin: 'sysAdmin'
	},
	ruta: {
		app: '/app',
		appAdministracionAvaluosDetalles: '/app/administracion/avaluos/detalles',
		appAdministracionAvaluosDetallesEditar: '/app/administracion/avaluos/detalles/editar',
		appAdministracionAvaluosLista: '/app/administracion/avaluos',
		appAdministracionCatalogosCalidadesConstruccionDetallesEditar: '/app/administracion/catalogos/calidadesConstruccion/editar',
		appAdministracionCatalogosCalidadesConstruccionLista: '/app/administracion/catalogos/calidadesConstruccion',
		appAdministracionCatalogosCalidadesConstruccionNuevo: '/app/administracion/catalogos/calidadesConstruccion/nuevo',
		appAdministracionCatalogosClasificacionesZonaDetallesEditar: '/app/administracion/catalogos/clasificacionesZona/editar',
		appAdministracionCatalogosClasificacionesZonaLista: '/app/administracion/catalogos/clasificacionesZona',
		appAdministracionCatalogosClasificacionesZonaNuevo: '/app/administracion/catalogos/clasificacionesZona/nuevo',
		appAdministracionCatalogosConstruccionesDominantesDetallesEditar: '/app/administracion/catalogos/construccionesDominantes/editar',
		appAdministracionCatalogosConstruccionesDominantesLista: '/app/administracion/catalogos/construccionesDominantes',
		appAdministracionCatalogosConstruccionesDominantesNuevo: '/app/administracion/catalogos/construccionesDominantes/nuevo',
		appAdministracionCatalogosDestinosEspecificosDetallesEditar: '/app/administracion/catalogos/destinosEspecificos/editar',
		appAdministracionCatalogosDestinosEspecificosLista: '/app/administracion/catalogos/destinosEspecificos',
		appAdministracionCatalogosDestinosEspecificosNuevo: '/app/administracion/catalogos/destinosEspecificos/nuevo',
		appAdministracionCatalogosElementosConstruccionDetallesEditar: '/app/administracion/catalogos/elementosConstruccion/editar',
		appAdministracionCatalogosElementosConstruccionLista: '/app/administracion/catalogos/elementosConstruccion',
		appAdministracionCatalogosElementosConstruccionNuevo: '/app/administracion/catalogos/elementosConstruccion/nuevo',
		appAdministracionCatalogosEstadosConservacionDetallesEditar: '/app/administracion/catalogos/estadosConservacion/editar',
		appAdministracionCatalogosEstadosConservacionLista: '/app/administracion/catalogos/estadosConservacion',
		appAdministracionCatalogosEstadosConservacionNuevo: '/app/administracion/catalogos/estadosConservacion/nuevo',
		appAdministracionCatalogosMotivosAjusteDetallesEditar: '/app/administracion/catalogos/motivosAjuste/editar',
		appAdministracionCatalogosMotivosAjusteLista: '/app/administracion/catalogos/motivosAjuste',
		appAdministracionCatalogosMotivosAjusteNuevo: '/app/administracion/catalogos/motivosAjuste/nuevo',
		appAdministracionCatalogosRegimenesPropiedadDetallesEditar: '/app/administracion/catalogos/regimenesPropiedad/editar',
		appAdministracionCatalogosRegimenesPropiedadLista: '/app/administracion/catalogos/regimenesPropiedad',
		appAdministracionCatalogosRegimenesPropiedadNuevo: '/app/administracion/catalogos/regimenesPropiedad/nuevo',
		appAdministracionCatalogosServiciosMunicipalesDetallesEditar: '/app/administracion/catalogos/serviciosMunicipales/editar',
		appAdministracionCatalogosServiciosMunicipalesLista: '/app/administracion/catalogos/serviciosMunicipales',
		appAdministracionCatalogosServiciosMunicipalesNuevo: '/app/administracion/catalogos/serviciosMunicipales/nuevo',
		appAdministracionCatalogosTiposCalleDetallesEditar: '/app/administracion/catalogos/tiposCalle/editar',
		appAdministracionCatalogosTiposCalleLista: '/app/administracion/catalogos/tiposCalle',
		appAdministracionCatalogosTiposCalleNuevo: '/app/administracion/catalogos/tiposCalle/nuevo',
		appAdministracionCatalogosTiposConstruccionDetallesEditar: '/app/administracion/catalogos/tiposConstruccion/editar',
		appAdministracionCatalogosTiposConstruccionLista: '/app/administracion/catalogos/tiposConstruccion',
		appAdministracionCatalogosTiposConstruccionNuevo: '/app/administracion/catalogos/tiposConstruccion/nuevo',
		appAdministracionCatalogosTiposInmuebleDetallesEditar: '/app/administracion/catalogos/tiposInmueble/editar',
		appAdministracionCatalogosTiposInmuebleLista: '/app/administracion/catalogos/tiposInmueble',
		appAdministracionCatalogosTiposInmuebleNuevo: '/app/administracion/catalogos/tiposInmueble/nuevo',
		appAdministracionCatalogosUnidadesSuperficieDetallesEditar: '/app/administracion/catalogos/unidadesSuperficie/editar',
		appAdministracionCatalogosUnidadesSuperficieLista: '/app/administracion/catalogos/unidadesSuperficie',
		appAdministracionCatalogosUnidadesSuperficieNuevo: '/app/administracion/catalogos/unidadesSuperficie/nuevo',
		appAdministracionCatalogosUsosEspecificosDetallesEditar: '/app/administracion/catalogos/usosEspecificos/editar',
		appAdministracionCatalogosUsosEspecificosLista: '/app/administracion/catalogos/usosEspecificos',
		appAdministracionCatalogosUsosEspecificosNuevo: '/app/administracion/catalogos/usosEspecificos/nuevo',
		appAdministracionCatalogosVientosCardinalesDetallesEditar: '/app/administracion/catalogos/vientosCardinales/editar',
		appAdministracionCatalogosVientosCardinalesLista: '/app/administracion/catalogos/vientosCardinales',
		appAdministracionCatalogosVientosCardinalesNuevo: '/app/administracion/catalogos/vientosCardinales/nuevo',
		appAdministracionConfiguracionAplicacion: '/app/administracion/configuracion',
		appAdministracionDashboard: '/app/administracion/dashboard',
		appAdministracionNotificacionesDetallesEditar: '/app/administracion/notificaciones/editar',
		appAdministracionNotificacionesLista: '/app/administracion/notificaciones',
		appAdministracionNotificacionesNuevo: '/app/administracion/notificaciones/nuevo',
		appAdministracionPeritosDetalles: '/app/administracion/peritos/detalles',
		appAdministracionPeritosDetallesEditar: '/app/administracion/peritos/detalles/editar',
		appAdministracionPeritosLicenciaEditar: '/app/administracion/peritos/licencia/editar',
		appAdministracionPeritosLista: '/app/administracion/peritos',
		appAdministracionRolesDetalles: '/app/administracion/roles/detalles',
		appAdministracionRolesDetallesEditar: '/app/administracion/roles/detalles/editar',
		appAdministracionRolesLista: '/app/administracion/roles',
		appAdministracionRolesNuevo: '/app/administracion/roles/nuevo',
		appAdministracionUsuariosDetalles: '/app/administracion/usuarios/detalles',
		appAdministracionUsuariosDetallesEditar: '/app/administracion/usuarios/detalles/editar',
		appAdministracionUsuariosFotoEditar: '/app/administracion/usuarios/foto/editar',
		appAdministracionUsuariosLista: '/app/administracion/usuarios',
		appAvaluosDetalles: '/app/avaluos/detalles',
		appAvaluosDetallesEditar: '/app/avaluos/detalles/editar',
		appAvaluosLista: '/app/avaluos',
		appAvaluosNuevo: '/app/avaluos/nuevo',
		appInicio: '/app/inicio',
		appPeritosDetalles: '/app/peritos/detalles',
		appPeritosDetallesEditar: '/app/peritos/detalles/editar',
		appPeritosLicenciaEditar: '/app/peritos/licencia/editar',
		appPeritosLista: '/app/peritos',
		appPeritosNuevo: '/app/peritos/nuevo',
		appSistemaConfiguracion: '/app/sistema/configuracion',
		appSistemaLogs: '/app/sistema/logs',
		appUsuarioNotificaciones: '/app/usuario/notificaciones',
		appUsuarioPerfil: '/app/usuario/perfil',
		appUsuarioPerfilEditar: '/app/usuario/perfil/editar',
		appUsuarioPerfilFoto: '/app/usuario/perfil/foto',
		appUsuarioPreferencias: '/app/usuario/preferencias',
		appUsuarioPreferenciasAvaluo: '/app/usuario/preferencias/avaluo',
		web: '/web',
		webInicio: '/web/inicio'
	}
};
