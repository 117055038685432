import { guardarLocalStorage, guardarSessionStorage } from 'util/mc-utils/mc-storage';
import ConfiguracionSistema from 'modelo/ConfiguracionSistema';
import mcLogger from '@mcsoft/logger';
import { ReduxAccion } from 'store/actions';
import { SET_CONFIGURACION_SISTEMA } from './actionTypes';

const NOMBRE_CLASE = 'store/sesion/reducer';

const stateInicial: ConfiguracionSistema = {
	awsS3BucketAvaluoFotos: '',
	awsS3BucketAvaluoFotosMiniaturas: '',
	awsS3BucketAvaluoMapas: '',
	awsS3BucketPeritoLicencia: '',
	awsS3BucketPeritoLicenciaMiniaturas: '',
	awsS3BucketUsuarioFotos: '',
	awsS3BucketUsuarioFotosMiniaturas: '',
	busquedaHabilitada: false,
	copyright: '',
	datosUsuarioDomicilioEntrega: false,
	datosUsuarioDomicilioFacturacion: false,
	fechaCreacion: new Date(),
	fechaModificacion: new Date(),
	id: '',
	multilenguaje: false,
	nombreAplicacion: '',
	versionAplicacion: ''
};

export default (state = stateInicial, action: ReduxAccion) => {
	switch (action.type) {
		case SET_CONFIGURACION_SISTEMA:
			const { configuracionSistema } = action.payload;
			const { formatoFecha } = configuracionSistema;
			if (formatoFecha && formatoFecha !== localStorage.getItem('formatoFecha')) {
				guardarLocalStorage({ campo: 'formatoFecha', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_CONFIGURACION_SISTEMA, valor: formatoFecha });
			}
			if (sessionStorage.getItem('loggerApi') === 'true') {
				guardarSessionStorage({ campo: 'loggerApi', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_CONFIGURACION_SISTEMA, valor: 'true' });
			}
			if (sessionStorage.getItem('loggerDev') === 'true') {
				guardarSessionStorage({ campo: 'loggerDev', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_CONFIGURACION_SISTEMA, valor: 'true' });
			}
			if (sessionStorage.getItem('loggerError') === 'true') {
				guardarSessionStorage({ campo: 'loggerError', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_CONFIGURACION_SISTEMA, valor: 'true' });
			}
			if (sessionStorage.getItem('loggerLocalStorage') === 'true') {
				guardarSessionStorage({ campo: 'loggerLocalStorage', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_CONFIGURACION_SISTEMA, valor: 'true' });
			}
			if (sessionStorage.getItem('loggerLog') === 'true') {
				guardarSessionStorage({ campo: 'loggerLog', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_CONFIGURACION_SISTEMA, valor: 'true' });
			}
			if (sessionStorage.getItem('loggerNodeModule') === 'true') {
				guardarSessionStorage({ campo: 'loggerNodeModule', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_CONFIGURACION_SISTEMA, valor: 'true' });
			}
			if (sessionStorage.getItem('loggerReact') === 'true') {
				guardarSessionStorage({ campo: 'loggerReact', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_CONFIGURACION_SISTEMA, valor: 'true' });
			}
			if (sessionStorage.getItem('loggerRedux') === 'true') {
				guardarSessionStorage({ campo: 'loggerRedux', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_CONFIGURACION_SISTEMA, valor: 'true' });
			}
			if (sessionStorage.getItem('loggerSessionStorage') === 'true') {
				guardarSessionStorage({ campo: 'loggerSessionStorage', nombreClase: NOMBRE_CLASE, nombreMetodo: SET_CONFIGURACION_SISTEMA, valor: 'true' });
			}
			state = {
				...state,
				...configuracionSistema
			};
			mcLogger.redux({
				mensaje: `Seteando state "configuracionSistema":`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_CONFIGURACION_SISTEMA,
				objetoExtra: configuracionSistema
			});
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
