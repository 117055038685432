import { McCampoSelectorMultipleOpcion } from '@mcsoft/formulario';
import UsuarioRol from 'modelo/UsuarioRol';

/**
 * Formatea una lista de roles para utilizarse como lista de opciones en un McCampoSelectorMultiple.
 * - ***lista*** - Arreglo con la lista de roles.
 */
export const formatearListaRolesMcCampoSelectorMultiple = (lista?: Array<UsuarioRol>): Array<McCampoSelectorMultipleOpcion> => {
	const listaOpciones: Array<McCampoSelectorMultipleOpcion> = [];
	if (lista) {
		lista.forEach((elemento: UsuarioRol) => {
			listaOpciones.push({ nombre: elemento.nombre, valor: elemento.id });
		});
	}
	return listaOpciones;
};
