import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import MCTablaPaginada, {
	McTablaPaginadaEncabezado,
	tablaPaginadaEventoAgregar,
	tablaPaginadaEventoCambiarOrdenamiento,
	tablaPaginadaEventoCambiarPaginaActual,
	tablaPaginadaEventoCambiarParametrosPaginacion,
	tablaPaginadaEventoCambiarRegistrosPorPagina,
	tablaPaginadaEventoCancelar
} from '@mcsoft/tabla-paginada';
import { obtenerAvaluosPaginados, obtenerAvaluosPaginadosPorUsuario } from 'servicios/api/avaluos';
import { procesarError, RegistrosPaginados } from '@mcsoft/api';
import { setPantallaCargaMostrarAction, setParametrosPaginacionAvaluosAction, setParametrosPaginacionAvaluosAdminAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Avaluo from 'modelo/Avaluo';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { nombreCompletoPerito } from 'util/modelo/peritos';
import { nombreCompletoUsuario } from 'util/modelo/usuarios';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { useEsSeccionAdministracion } from 'hooks/useSeccion';

const NOMBRE_CLASE = 'paginas/app/avaluos/AvaluosLista';

/**
 * En esta página se muestra la lista de roles.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const urlCriterio = new URLSearchParams(location.search).get('criterio');
	const urlOrden = new URLSearchParams(location.search).get('orden');
	const urlOrdenamiento = new URLSearchParams(location.search).get('ordenamiento');
	const urlPagina = new URLSearchParams(location.search).get('pagina');
	const urlRegistrosPorPagina = new URLSearchParams(location.search).get('registrosPorPagina');
	const { auth0AccessToken } = useSelector((state: StateType) => state.sesion.auth0);
	const { usuario } = useSelector((state: StateType) => state.sesion);
	const {
		criterio: criterioAdmin,
		orden: ordenAdmin,
		ordenamiento: ordenamientoAdmin,
		pagina: paginaAdmin,
		registrosPorPagina: registrosPorPaginaAdmin
	} = useSelector((state: StateType) => state.parametrosPaginacion.avaluosAdmin);
	const {
		criterio: criterioUsuario,
		orden: ordenUsuario,
		ordenamiento: ordenamientoUsuario,
		pagina: paginaUsuario,
		registrosPorPagina: registrosPorPaginaUsuario
	} = useSelector((state: StateType) => state.parametrosPaginacion.avaluos);
	const [criterio, setCriterio] = useState<string>('');
	const [orden, setOrden] = useState<string>('folio');
	const [ordenamiento, setOrdenamiento] = useState<string>('');
	const [pagina, setPagina] = useState<number>(1);
	const [registrosPorPagina, setRegistrosPorPagina] = useState<number>(10);
	const [registrosLista, setRegistrosLista] = useState<Array<any>>([]);
	const [registrosMostrandoFinal, setRegistrosMostrandoFinal] = useState<number>(0);
	const [registrosMostrandoInicial, setRegistrosMostrandoInicial] = useState<number>(0);
	const [registrosMostrandoPorPagina, setRegistrosMostrandoPorPagina] = useState<number>(20);
	const [registrosMostrandoPorPaginaOpciones] = useState<Array<string>>();
	const [registrosPaginaFinal, setRegistrosPaginaFinal] = useState<number>(1);
	const [registrosTotal, setRegistrosTotal] = useState<number>(0);
	const [tablaEncabezados, setTablaEncabezados] = useState<Array<McTablaPaginadaEncabezado>>([]);
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const RUTA_CREAR = constantes.ruta.appAvaluosNuevo;
	const RUTA_SALIR = constantes.ruta.appInicio;

	useEffect(() => {
		obtenerVariablesDeSeccion();
	}, []);

	useEffect(() => {
		setearParametroPaginacion();
		obtenerVariablesDeSeccion();
	}, [location]);

	useEffect(() => {
		if (esSeccionAdministracion()) {
			dispatch(
				setParametrosPaginacionAvaluosAdminAction({
					criterio: urlCriterio !== null ? urlCriterio : criterioAdmin,
					orden: urlOrden ? urlOrden : ordenAdmin,
					ordenamiento: urlOrdenamiento ? urlOrdenamiento : ordenamientoAdmin,
					pagina: urlPagina ? Number(urlPagina) : paginaAdmin,
					registrosPorPagina: urlRegistrosPorPagina ? Number(urlRegistrosPorPagina) : registrosPorPaginaAdmin
				})
			);
		} else {
			dispatch(
				setParametrosPaginacionAvaluosAction({
					criterio: urlCriterio !== null ? urlCriterio : criterioUsuario,
					orden: urlOrden ? urlOrden : ordenUsuario,
					ordenamiento: urlOrdenamiento ? urlOrdenamiento : ordenamientoUsuario,
					pagina: urlPagina ? Number(urlPagina) : paginaUsuario,
					registrosPorPagina: urlRegistrosPorPagina ? Number(urlRegistrosPorPagina) : registrosPorPaginaUsuario
				})
			);
		}
	}, [urlCriterio, urlOrden, urlOrdenamiento, urlPagina, urlRegistrosPorPagina]);

	useEffect(() => {
		setearParametroPaginacion();
	}, [criterioAdmin, ordenAdmin, ordenamientoAdmin, paginaAdmin, registrosPorPaginaAdmin]);

	useEffect(() => {
		setearParametroPaginacion();
	}, [criterioUsuario, ordenUsuario, ordenamientoUsuario, paginaUsuario, registrosPorPaginaUsuario]);

	useEffect(() => {
		eventoObtenerRegistros({ criterio, orden, ordenamiento, pagina, registrosPorPagina });
	}, [location, criterio, orden, ordenamiento, pagina, registrosPorPagina]);

	/**
	 * Dibuja la informacion del domicilio en una celda.
	 * > - ***registro*** - Objeto con la información del registro.
	 */
	const dibujarCeldaDomicilio = (registro: Avaluo) => {
		const { domicilio } = registro;
		let domicilioCompleto = '';
		if (domicilio) {
			const { calle, calleTipo, numeroExterior, numeroInterior } = domicilio;
			if (calleTipo) {
				domicilioCompleto += `${calleTipo.nombre}`;
			}
			if (calle) {
				domicilioCompleto += ` ${calle}`;
			}
			if (numeroExterior) {
				domicilioCompleto += ` No. ${numeroExterior}`;
			}
			if (numeroInterior) {
				domicilioCompleto += ` Int. ${numeroInterior}`;
			}
		}
		return <>{domicilioCompleto}</>;
	};

	/**
	 * Dibuja el estatus del avalúo en una celda.
	 * > - ***registro*** - Objeto con la información del registro.
	 */
	const dibujarCeldaEstatus = (registro: Avaluo) => {
		const { estatus } = registro;
		let estatusTexto: string = '';
		let estatusClase: string = '';
		switch (estatus) {
			case 'incompleto':
				estatusTexto = 'Incompleto';
				estatusClase = 'warning';
				break;
			case 'completo':
				estatusTexto = 'Completo';
				estatusClase = 'success';
				break;
			default:
				break;
		}
		return <span className={`badge rounded-pill bg-${estatusClase}`}>{estatusTexto}</span>;
	};

	/**
	 * Dibuja la informacion del perito en una celda.
	 * > - ***registro*** - Objeto con la información del registro.
	 */
	const dibujarCeldaPerito = (registro: Avaluo) => {
		const { antecedentes } = registro;
		if (antecedentes) {
			const { perito } = antecedentes;
			return <>{perito ? nombreCompletoPerito(perito) : ''}</>;
		}
		return <></>;
	};

	/**
	 * Dibuja la informacion del usuario en una celda.
	 * > - ***registro*** - Objeto con la información del registro.
	 */
	const dibujarCeldaUsuario = (registro: Avaluo) => {
		const { usuario } = registro;
		return <>{usuario ? nombreCompletoUsuario(usuario) : ''}</>;
	};

	/**
	 * Obtiene los registros.
	 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
	 */
	const eventoObtenerRegistros = async ({
		criterio,
		orden,
		ordenamiento,
		pagina,
		registrosPorPagina
	}: {
		criterio?: string;
		orden?: string;
		ordenamiento?: string;
		pagina?: number;
		registrosPorPagina?: number;
	}) => {
		const nombreMetodo = 'eventoObtenerRegistros';
		try {
			mcLogger.log({ mensaje: `Obteniendo lista de avalúos...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			let respuesta = null;
			if (esSeccionAdministracion()) {
				respuesta = await obtenerAvaluosPaginados({
					auth0AccessToken,
					criterio,
					orden,
					ordenamiento,
					pagina,
					registrosPorPagina
				});
			} else {
				respuesta = await obtenerAvaluosPaginadosPorUsuario({
					auth0AccessToken,
					criterio,
					orden,
					ordenamiento,
					pagina,
					registrosPorPagina,
					usuarioId: usuario.id
				});
			}
			mcLogger.log({ mensaje: `Lista de avalúos obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const registrosPaginados = respuesta.datos as RegistrosPaginados;
			setRegistrosLista(registrosPaginados.lista);
			setRegistrosPaginaFinal(registrosPaginados.paginaFinal);
			setRegistrosMostrandoFinal(registrosPaginados.registroFinal);
			setRegistrosMostrandoInicial(registrosPaginados.registroInicial);
			setRegistrosMostrandoPorPagina(registrosPaginados.registrosPorPagina);
			setRegistrosTotal(registrosPaginados.total);
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener la lista de avalúos: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Redirecciona a la ruta de los detalles del registro.
	 * - ***registroId*** - Id del registro seleccionado.
	 */
	const eventoSeleccionarRegistro = ({ registroId }: { registroId: any }) => {
		const nombreMetodo = 'eventoSeleccionarRegistro';
		let ruta = null;
		if (esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionAvaluosVerDetalles, usuario })) {
			ruta = `${constantes.ruta.appAdministracionAvaluosDetalles}/${registroId}`;
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
			history.push(ruta);
		} else if (!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAvaluosVerDetalles, usuario })) {
			ruta = `${constantes.ruta.appAvaluosDetalles}/${registroId}`;
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
			history.push(ruta);
		} else {
			mcNotificaciones.advertencia({ mensaje: texto('No tienes permiso para acceder a la sección de detalles del avalúo.') });
		}
	};

	/**
	 * Setea los parametros de paginacion.
	 */
	const setearParametroPaginacion = () => {
		if (esSeccionAdministracion()) {
			setCriterio(criterioAdmin);
			setOrden(ordenAdmin);
			setOrdenamiento(ordenamientoAdmin);
			setPagina(paginaAdmin);
			setRegistrosPorPagina(registrosPorPaginaAdmin);
		} else {
			setCriterio(criterioUsuario);
			setOrden(ordenUsuario);
			setOrdenamiento(ordenamientoUsuario);
			setPagina(paginaUsuario);
			setRegistrosPorPagina(registrosPorPaginaUsuario);
		}
	};

	/**
	 * Obtiene el título de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionAdministracion()) {
			setTituloSeccion(texto('Lista de avalúos'));
			setTablaEncabezados([
				{
					atributo: 'folio',
					campo: 'folio',
					estiloCelda: { textAlign: 'center' },
					ordenamiento: [['folio']],
					titulo: texto('Folio')
				},
				{
					estiloCelda: { textAlign: 'center' },
					eventoDibujarCeldaPersonalizada: dibujarCeldaEstatus,
					ordenamiento: [['estatus']],
					titulo: texto('Estatus')
				},
				{
					atributo: 'antecedentes.fechaAvaluo',
					campo: 'antecedentes.fechaAvaluo',
					estiloCelda: { textAlign: 'center' },
					ordenamiento: [['antecedentes', 'fechaAvaluo']],
					tipo: 'fecha',
					titulo: texto('Fecha')
				},
				{
					atributo: 'antecedentes.tipoInmueble.nombre',
					campo: 'antecedentes.tipoInmueble.nombre',
					estiloCelda: { textAlign: 'center' },
					ordenamiento: [['antecedentes', 'tipoInmueble', 'nombre']],
					titulo: texto('Tipo de inmueble')
				},
				{
					eventoDibujarCeldaPersonalizada: dibujarCeldaDomicilio,
					ordenamiento: [['domicilio', 'calle']],
					titulo: texto('Domicilio')
				},
				{
					atributo: 'domicilio.colonia',
					campo: 'domicilio.colonia',
					ordenamiento: [['domicilio', 'colonia']],
					titulo: texto('Colonia')
				},
				{
					atributo: 'domicilio.codigoPostal',
					campo: 'domicilio.codigoPostal',
					estiloCelda: { textAlign: 'center' },
					ordenamiento: [['domicilio', 'codigoPostal']],
					titulo: texto('Código Postal')
				},
				{
					atributo: 'domicilio.municipio.nombre',
					campo: 'domicilio.municipio.nombre',
					estiloCelda: { textAlign: 'center' },
					ordenamiento: [['domicilio', 'municipio', 'nombre']],
					titulo: texto('Municipio')
				},
				{
					eventoDibujarCeldaPersonalizada: dibujarCeldaUsuario,
					ordenamiento: [['usuario', 'nombre']],
					titulo: texto('Usuario')
				},
				{
					eventoDibujarCeldaPersonalizada: dibujarCeldaPerito,
					ordenamiento: [['antecedentes', 'perito', 'nombre']],
					titulo: texto('Perito')
				}
			]);
		} else {
			setTituloSeccion(texto('Mis avalúos'));
			setTablaEncabezados([
				{
					atributo: 'folio',
					campo: 'folio',
					estiloCelda: { textAlign: 'center' },
					ordenamiento: [['folio']],
					titulo: texto('Folio')
				},
				{
					estiloCelda: { textAlign: 'center' },
					eventoDibujarCeldaPersonalizada: dibujarCeldaEstatus,
					ordenamiento: [['estatus']],
					titulo: texto('Estatus')
				},
				{
					atributo: 'antecedentes.fechaAvaluo',
					campo: 'antecedentes.fechaAvaluo',
					estiloCelda: { textAlign: 'center' },
					ordenamiento: [['antecedentes', 'fechaAvaluo']],
					tipo: 'fecha',
					titulo: texto('Fecha')
				},
				{
					atributo: 'antecedentes.tipoInmueble.nombre',
					campo: 'antecedentes.tipoInmueble.nombre',
					estiloCelda: { textAlign: 'center' },
					ordenamiento: [['antecedentes', 'tipoInmueble', 'nombre']],
					titulo: texto('Tipo de inmueble')
				},
				{
					eventoDibujarCeldaPersonalizada: dibujarCeldaDomicilio,
					ordenamiento: [['domicilio', 'calle']],
					titulo: texto('Domicilio')
				},
				{
					atributo: 'domicilio.colonia',
					campo: 'domicilio.colonia',
					ordenamiento: [['domicilio', 'colonia']],
					titulo: texto('Colonia')
				},
				{
					atributo: 'domicilio.codigoPostal',
					campo: 'domicilio.codigoPostal',
					estiloCelda: { textAlign: 'center' },
					ordenamiento: [['domicilio', 'codigoPostal']],
					titulo: texto('Código Postal')
				},
				{
					atributo: 'domicilio.municipio.nombre',
					campo: 'domicilio.municipio.nombre',
					estiloCelda: { textAlign: 'center' },
					ordenamiento: [['domicilio', 'municipio', 'nombre']],
					titulo: texto('Municipio')
				},
				{
					eventoDibujarCeldaPersonalizada: dibujarCeldaPerito,
					ordenamiento: [['antecedentes', 'perito', 'nombre']],
					titulo: texto('Perito')
				}
			]);
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={[{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') }]} icono={constantes.icono.avaluo} titulo={tituloSeccion} />
			<Card>
				<CardHeader>
					<BarraHerramientas>
						{!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAvaluosCrear, usuario }) && (
							<button
								className="btn btn-success"
								id="botonAgregar"
								onClick={() => tablaPaginadaEventoAgregar({ history, nombreClase: NOMBRE_CLASE, rutaCreacion: RUTA_CREAR })}
								type="button"
							>
								<i className={constantes.icono.agregar}></i> {texto('Agregar')}
							</button>
						)}
						<button
							className="btn btn-danger"
							id="botonCancelar"
							onClick={() => tablaPaginadaEventoCancelar({ history, nombreClase: NOMBRE_CLASE, rutaSalir: RUTA_SALIR })}
							type="button"
						>
							<i className={constantes.icono.atras}></i> {texto('Salir')}
						</button>
					</BarraHerramientas>
				</CardHeader>
				<CardBody>
					<Row>
						<Col md="12">
							<MCTablaPaginada
								eventoCambiarOrdenamiento={({ orden, ordenamiento }) =>
									tablaPaginadaEventoCambiarOrdenamiento({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, pagina, registrosPorPagina })
								}
								eventoCambiarPaginaActual={(pagina) =>
									tablaPaginadaEventoCambiarPaginaActual({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, pagina, registrosPorPagina })
								}
								eventoCambiarParametrosPaginacion={({ criterio, orden, ordenamiento, pagina, registrosPorPagina }) =>
									tablaPaginadaEventoCambiarParametrosPaginacion({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, pagina, registrosPorPagina })
								}
								eventoCambiarRegistrosPorPagina={(registrosPorPagina) =>
									tablaPaginadaEventoCambiarRegistrosPorPagina({ criterio, history, nombreClase: NOMBRE_CLASE, orden, ordenamiento, registrosPorPagina })
								}
								eventoSeleccionarRegistro={eventoSeleccionarRegistro}
								registrosCriterio={criterio}
								registrosEncabezados={tablaEncabezados}
								registrosLista={registrosLista}
								registrosMostrandoFinal={registrosMostrandoFinal}
								registrosMostrandoInicial={registrosMostrandoInicial}
								registrosMostrandoPorPagina={registrosMostrandoPorPagina}
								registrosMostrandoPorPaginaOpciones={registrosMostrandoPorPaginaOpciones}
								registrosOrden={orden}
								registrosOrdenamiento={ordenamiento}
								registrosPaginaActual={pagina}
								registrosPaginaFinal={registrosPaginaFinal}
								registrosTotal={registrosTotal}
							/>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Contenedor>
	);
};

export default Pagina;
