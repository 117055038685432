import { ReduxAccion } from 'store/actions';
import {
	SET_INTERFAZ_BARRA_NAVEGACION_LATERAL_MOSTRAR
} from './actionTypes';

/**
 * Muestra/Oculta la barra de navegación lateral.
 * - ***barraNavegacionLateralMostrar*** - Indica si se mostrará la barra de navegación lateral.
 */
export const setInterfazBarraNavegacionLateralMostrarAction = (barraNavegacionLateralMostrar: boolean): ReduxAccion => ({
	payload: { barraNavegacionLateralMostrar },
	type: SET_INTERFAZ_BARRA_NAVEGACION_LATERAL_MOSTRAR
});