import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del código de la moneda.
 */
const InformacionMonedaCodigo = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Utilizado para definir el código de la moneda.')}</p>
			<ul>
				<li>
					<span className="badge bg-info text-wrap">MXN</span> {texto('Peso Mexicano')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">EUR</span> {texto('Euro')}{' '}
				</li>
				<li>
					<span className="badge bg-info text-wrap">USD</span> {texto('Dolar Estadounidense')}{' '}
				</li>
			</ul>
		</div>
	</Fragment>
);

export default InformacionMonedaCodigo;
