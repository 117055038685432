import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { Fragment, useState } from 'react';
import constantes from 'configuracion/constantes';
import mcLogger from '@mcsoft/logger';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router';
import { useObtenerUsuarioFoto } from 'hooks/useImagen';
import { useSelector } from 'react-redux';

const NOMBRE_CLASE = 'PerfilMenuDesplegable';

/**
 * Menú desplegable que muestra una lista opciones del usuario en sesión.
 */
const PerfilMenuDesplegable = () => {
	const { logout } = useAuth0();
	const history = useHistory();
	const obtenerFotoPerfil = useObtenerUsuarioFoto();
	const sesion = useSelector((state: StateType) => state.sesion);
	const [mostrarMenuDesplegable, setMostrarMenuDesplegable] = useState(false);
	const { usuario } = sesion;
	const { nombreUsuario } = usuario;

	/**
	 * Muestra/oculta el menú desplegable.
	 */
	const eventoMostrarOcultarMenuDesplegable = () => {
		const nombreMetodo = 'eventoMostrarOcultarMenuDesplegable';
		mcLogger.react({ mensaje: `Seteando state "mostrarMenuDesplegable":`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: !mostrarMenuDesplegable });
		setMostrarMenuDesplegable(!mostrarMenuDesplegable);
	};

	/**
	 * Cierra la sesión del usuario.
	 */
	const eventoCerrarSesion = () => {
		const nombreMetodo = 'eventoCerrarSesion';
		mcLogger.log({ mensaje: `Cerrando sesión...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		mcLogger.dev({ mensaje: `Cerrando sesión del usuario con el id '${usuario.id}'...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		logout({ returnTo: window.location.origin });
		mcLogger.log({ mensaje: `Sesión cerrada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		mcLogger.dev({ mensaje: `Sesión del usuario con el id '${usuario.id}' cerrada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
	};

	return (
		<Fragment>
			<Dropdown className="d-inline-block" isOpen={mostrarMenuDesplegable} toggle={eventoMostrarOcultarMenuDesplegable}>
				<DropdownToggle className="btn header-item waves-effect" id="page-header-user-dropdown" tag="button">
					<img alt="Header Avatar" className="rounded-circle header-profile-user" src={obtenerFotoPerfil({ ancho: '50', usuario })} />
					<span className="d-none d-xl-inline-block ms-1 fw-medium font-size-15">{nombreUsuario}</span> <i className="uil-angle-down d-none d-xl-inline-block font-size-15"></i>
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end">
					<DropdownItem onClick={() => history.push(constantes.ruta.appUsuarioPerfil)}>
						<i className={constantes.icono.usuario}></i>
						<span>&nbsp;{texto('Ver perfil')}</span>
					</DropdownItem>
					<DropdownItem onClick={() => history.push(constantes.ruta.appUsuarioPreferencias)}>
						<i className={constantes.icono.preferencias}></i>
						<span>&nbsp;{texto('Preferencias')}</span>
					</DropdownItem>
					<div className="dropdown-divider" />
					<DropdownItem onClick={eventoCerrarSesion}>
						<i className={constantes.icono.cerrarSesion}></i>
						<span>&nbsp;{texto('Cerrar sesión')}</span>
					</DropdownItem>
				</DropdownMenu>
			</Dropdown>
		</Fragment>
	);
};

export default PerfilMenuDesplegable;
