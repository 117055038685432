import 'assets/scss/theme.scss';
import 'assets/fontawesome/v612/css/all.css';
import 'App.scss';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { Fragment, useEffect } from 'react';
import { RutaConAutenticacion, RutaPublica } from 'util/mc-utils/mc-autenticacion';
import Auth0Sesion from 'modelo/Auth0Sesion';
import constantes from 'configuracion/constantes';
import mcLogger from '@mcsoft/logger';
import McPantallaCarga from '@mcsoft/pantalla-carga';
import { procesarError } from '@mcsoft/api';
import RutasApp from 'rutas/RutasApp';
import RutasWeb from 'rutas/RutasWeb';
import { setSesionAuth0Action } from 'store/actions';
import { texto } from 'idiomas';
import { ToastContainer } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';

const NOMBRE_CLASE = 'App';

/**
 * Componente principal de la aplicación.
 */
const App = () => {
	const nombreMetodo = 'App';
	if (process.env.REACT_APP_ENV === 'development') {
		mcLogger.modoDesarrollo();
	}
	const dispatch = useDispatch();
	const useAuth0Instancia = useAuth0();

	useEffect(() => {
		iniciarSesion();
	}, [useAuth0Instancia]);

	const iniciarSesion = async () => {
		mcLogger.api({ mensaje: `Obteniendo datos de autenticación de Auht0...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		mcLogger.log({ mensaje: `Verificando si el usuario está autenticado en Auht0...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		if (useAuth0Instancia.isAuthenticated) {
			try {
				mcLogger.log({ mensaje: `El usuario está autenticado en Auht0.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcLogger.api({ mensaje: `Obteniendo el id token de Auth0...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				const claims = await useAuth0Instancia.getIdTokenClaims();
				mcLogger.api({ mensaje: `Id Token de Auth0 obtenido con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcLogger.api({ mensaje: `Obteniendo token de Auth0...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				const accessToken = await useAuth0Instancia.getAccessTokenSilently();
				mcLogger.api({ mensaje: `Token de Auth0 obtenido con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				const auth0 = {
					...useAuth0Instancia,
					auth0AccessToken: accessToken,
					auth0IdToken: claims?.__raw
				};
				dispatch(setSesionAuth0Action(auth0 as Auth0Sesion));
			} catch (error: any) {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los tokens de Auth0:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			}
		} else {
			mcLogger.log({ mensaje: `El usuario no está autenticado en Auht0.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		}
	};

	return (
		<Fragment>
			{useAuth0Instancia.isLoading && (
				<McPantallaCarga
					giroHorizontal={true}
					icono={constantes.icono.autenticando}
					mostrar={true}
					texto={{
						mensaje: `${texto('Autenticando')}...`
					}}
				/>
			)}
			{!useAuth0Instancia.isLoading && (
				<BrowserRouter>
					<div>
						<ToastContainer />
						<Switch>
							<RutaConAutenticacion component={RutasApp} path={constantes.ruta.app} />
							<RutaPublica component={RutasWeb} path={constantes.ruta.web} />
							<Redirect from="/*" to={constantes.ruta.web} />
						</Switch>
					</div>
				</BrowserRouter>
			)}
		</Fragment>
	);
};

export default App;
