/* eslint-disable no-unused-vars */
import { ChangeEvent, Fragment, useState } from 'react';
import { Field } from 'react-final-form';
import { Tooltip } from 'reactstrap';

interface McCheckboxProps {
	/**
	 * Nombre del campo del formulario donde se guardará el valor del input.
	 */
	campo: string;
	/**
	 * Etiqueta que se mostrará en el checkbox.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	etiqueta?: string;
	/**
	 * Evento que se ejecuta al cambiar el valor del campo.
	 * - ***evento*** - Evento que invoca la función.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	eventoCambio?: (evento: ChangeEvent<HTMLElement>) => void;
	/**
	 * Icono *FontAwesome* que se mostrará junto a la etiqueta si hay información para mostrar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-info-circle'*
	 */
	iconoInformacion?: string;
	/**
	 * Identificador único del componente.
	 */
	id: string;
	/**
	 * Contenido que se mostrará al colocar el cursor en el icono de información.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	informacion?: any;
	/**
	 * Indica si el campo debe ser resaltado cuando es obligatorio.
	 *
	 * > ***Predeterminado:*** *true*
	 *
	 * > **Nota:** Se resaltará cuando la validación de ***React Final Form*** regrese un error para el campo del input.
	 */
	resaltarObligatorio?: boolean;
	/**
	 * Indica si el input será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
}

/**
 * Input booleano sencillo.
 */
export const McCheckbox = (props: McCheckboxProps): JSX.Element => {
	const [mostrarTooltip, setMostrarTooltip] = useState(false);
	const { campo, etiqueta, eventoCambio, iconoInformacion = 'fa-solid fa-info-circle', id, informacion, resaltarObligatorio = true, soloLectura = false } = props;

	return (
		<Field name={campo} type="checkbox">
			{({ input, meta }) => {
				const obligatorio = meta.error && !meta.dirty && resaltarObligatorio;
				const esInvalido = meta.error && meta.dirty;
				const eventoCambioNuevo = (evento: ChangeEvent<HTMLElement>) => {
					input.onChange(evento);
					if (eventoCambio) {
						eventoCambio(evento);
					}
				};
				return (
					<Fragment>
						<div className="form-check">
							{informacion && (
								<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
									{informacion}
								</Tooltip>
							)}
							<input
								checked={input.checked}
								className={`form-check-input${esInvalido ? ' is-invalid' : ''}`}
								disabled={soloLectura}
								id={id}
								onChange={(event: ChangeEvent<HTMLInputElement>) => {
									input.onChange(event.target.checked);
									eventoCambioNuevo(event);
								}}
								style={obligatorio ? { borderColor: '#b1860c' } : {}}
								type="checkbox"
							/>
							{etiqueta && (
								<label className="form-check-label" htmlFor={id} style={obligatorio ? { color: '#b1860c' } : {}}>
									{etiqueta}
								</label>
							)}
							{informacion && (
								<span className="text-primary">
									{' '}
									<i className={iconoInformacion} id={`${id}_tooltip`}></i>
								</span>
							)}
						</div>
					</Fragment>
				);
			}}
		</Field>
	);
};
