import ConfiguracionAplicacion from 'modelo/ConfiguracionAplicacion';
import mcLogger from '@mcsoft/logger';
import { ReduxAccion } from 'store/actions';
import { SET_CONFIGURACION_APLICACION } from './actionTypes';

const NOMBRE_CLASE = 'store/sesion/reducer';

const stateInicial: ConfiguracionAplicacion = {
	datosUsuarioCorreoEditable: false,
	datosUsuarioDomicilioObligatorio: false,
	datosUsuarioFacebook: true,
	datosUsuarioInstagram: true,
	datosUsuarioLinkedIn: true,
	datosUsuarioTelefonoObligatorio: false,
	datosUsuarioTwitter: true,
	datosUsuarioYouTube: true,
	decimalLocale: 'es-MX',
	fechaCreacion: new Date(),
	fechaModificacion: new Date(),
	id: '',
	imagenPredeterminadaPeritoLicencia200: '',
	imagenPredeterminadaPeritoLicencia400: '',
	imagenPredeterminadaPeritoLicencia700: '',
	imagenPredeterminadaUsuarioFotoPerfil100x100: '',
	imagenPredeterminadaUsuarioFotoPerfil50x50: '',
	imagenPredeterminadaUsuarioFotoPerfil700x700: '',
	momentFechaFormato: 'DD/MM/YYYY',
	momentFechaLocale: 'es-mx',
	momentFechaZona: 'America/Bahia_Banderas',
	monedaCodigo: 'MXN',
	monedaLocale: 'es-MX',
	selectorFechaFormato: 'd-m-Y'
};

export default (state = stateInicial, action: ReduxAccion) => {
	switch (action.type) {
		case SET_CONFIGURACION_APLICACION:
			const { configuracionAplicacion } = action.payload;
			state = {
				...state,
				...configuracionAplicacion
			};
			mcLogger.redux({
				mensaje: `Seteando state "configuracionAplicacion":`,
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo: SET_CONFIGURACION_APLICACION,
				objetoExtra: configuracionAplicacion
			});
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};
