import { Col, Row } from 'reactstrap';
import { formatearListaOpcionesMcCampoSelector, obtenerOpcionDeLaLista } from 'util/modelo/listasOpciones';
import { Fragment, useEffect, useState } from 'react';
import { McCampoSelector, McCampoTexto } from '@mcsoft/formulario';
import { useForm, useFormState } from 'react-final-form';
import Avaluo from 'modelo/Avaluo';
import AvaluoTerrenoDetalle from 'modelo/AvaluoTerrenoDetalle';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import BarraHerramientasAlerta from 'componentes/tema-comun/pagina/BarraHerramientasAlerta';
import constantes from 'configuracion/constantes';
import { convertirNumeroEnteroANumeroRomano } from '@mcsoft/numeros';
import ListaOpcion from 'modelo/ListaOpcion';
import { texto } from 'idiomas';
import { useEsSeccionPreferencias } from 'hooks/useSeccion';
import useMoneda from 'hooks/useMoneda';

interface AvaluoFormularioTerrenoProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoSalir: () => void;
	hayCambiosSinGuardar: boolean;
	listaMotivo: Array<ListaOpcion> | undefined;
	listaUnidadSuperficie: Array<ListaOpcion> | undefined;
}

/**
 * Formulario para la sección terreno del avalúo.
 */
const AvaluoFormularioTerreno = (props: AvaluoFormularioTerrenoProps) => {
	const moneda = useMoneda();
	const esSeccionPreferencias = useEsSeccionPreferencias();
	const formulario = useForm();
	const formularioValores = useFormState();
	const { eventoDeshacer, eventoGuardar, eventoSalir, hayCambiosSinGuardar, listaMotivo, listaUnidadSuperficie } = props;
	const { detalles, formularioDetalle, superficieTotal, superficieUnidadId, valor, valorTotal } = formularioValores.values.terreno;
	const { detalleAjusteFactor, detalleAjusteMotivoId, detalleFraccion, detalleIndiviso, detalleSuperficie, detalleSuperficieUnidadId, detalleValorUnitario } = formularioDetalle;
	const [construccionFraccionEditable, setConstruccionFraccionEditable] = useState<boolean>(false);
	const [detallesTemporales, setDetallesTemporales] = useState<Array<AvaluoTerrenoDetalle>>([]);

	useEffect(() => {
		setDetallesTemporales(detalles);
	}, [detalles]);

	/**
	 * Dibuja la barra de herramentas.
	 */
	const dibujarBarraHerramientas = () => (
		<BarraHerramientas>
			<button className="btn btn-danger" id="botonSalir" onClick={eventoSalir} type="button">
				<i className={constantes.icono.atras}></i> {texto('Salir')}
			</button>
			<button className="btn btn-warning" disabled={!hayCambiosSinGuardar} id="botonDeshacer" onClick={eventoDeshacer} type="button">
				<i className={constantes.icono.deshacer}></i> {texto('Deshacer')}
			</button>
			<button className="btn btn-success" disabled={!hayCambiosSinGuardar} id="botonGuardar" onClick={eventoGuardarFormulario} type="button">
				<i className={constantes.icono.guardar}></i> {texto('Guardar')}
			</button>
		</BarraHerramientas>
	);

	/**
	 * Dibuja la alerta de la barra de herramientas.
	 */
	const dibujarBarraHerramientasAlerta = () => (
		<BarraHerramientasAlerta mostrar={hayCambiosSinGuardar}>
			<i className="fa-solid fa-triangle-exclamation"></i>
			&nbsp;{texto('Hay cambios sin guardar')}
		</BarraHerramientasAlerta>
	);

	/**
	 * Agrega un detalle de terreno.
	 */
	const eventoAgregarDetalle = () => {
		const { cambiarValorCampo, limpiarValorCampo } = formulario.mutators;
		cambiarValorCampo('terreno.indiviso', detalleIndiviso);
		cambiarValorCampo('terreno.superficieUnidadId', detalleSuperficieUnidadId);
		const valorNeto = Number(detalleValorUnitario) * Number(detalleAjusteFactor);
		const valorResultante = Number(valorNeto) * Number(detalleSuperficie);
		const detalleNuevo: AvaluoTerrenoDetalle = {
			ajusteFactor: detalleAjusteFactor,
			ajusteMotivoId: detalleAjusteMotivoId,
			avaluoTerrenoId: '',
			fraccion: detalleFraccion,
			id: '',
			superficie: detalleSuperficie,
			superficieUnidadId: detalleSuperficieUnidadId,
			valorNeto: valorNeto,
			valorResultante: valorResultante,
			valorUnitario: detalleValorUnitario
		};
		obtenerValorDeLasTerrenos({ detalles: [...detallesTemporales, detalleNuevo], indiviso: detalleIndiviso });
		setDetallesTemporales([...detallesTemporales, detalleNuevo]);
		// Limpiar formulario
		cambiarValorCampo('terreno.formularioDetalle.detalleFraccion', Number(detalleFraccion) + 1);
		limpiarValorCampo('terreno.formularioDetalle.detalleSuperficie');
		limpiarValorCampo('terreno.formularioDetalle.detalleValorUnitario');
	};

	/**
	 * Habilita la edición manual del campo fracción.
	 */
	const eventoEditarConstruccionFraccion = () => {
		setConstruccionFraccionEditable(!construccionFraccionEditable);
	};

	/**
	 * Evento que se ejecuta cuando se elimina un detalle de la lista.
	 * - ***indice*** - Indice del detalle eliminada.
	 */
	const eventoEliminarDetalle = (indice: number) => {
		const listaTemporal = [...detallesTemporales];
		listaTemporal.splice(indice, 1);
		setDetallesTemporales(listaTemporal);
		obtenerValorDeLasTerrenos({ detalles: listaTemporal, indiviso: detalleIndiviso });
	};

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardarFormulario = () => {
		const { cambiarValorCampo } = formulario.mutators;
		const avaluo = formularioValores.values as Avaluo;
		cambiarValorCampo('terreno.detalles', detallesTemporales);
		if (avaluo.terreno) {
			avaluo.terreno.detalles = detallesTemporales;
		}
		if (detallesTemporales.length === 0) {
			if (avaluo.terreno) {
				avaluo.terreno.superficieUnidadId = null;
			}
		}
		eventoGuardar(avaluo);
	};

	/**
	 * Indica si el formulario cumple con todos los campos requeridos para agregar un detalle.
	 */
	const formularioValido = () => {
		const { terreno } = formularioValores.errors as any;
		return terreno === undefined;
	};

	/**
	 * Calcula el valor total de los terrenos.
	 * - ***detalles*** - Arreglo con los detalles de los terrenos.
	 * - ***indiviso*** - Indiviso de los terrenos.
	 */
	const obtenerValorDeLasTerrenos = ({ detalles, indiviso }: { detalles: Array<AvaluoTerrenoDetalle>; indiviso: number }) => {
		const { cambiarValorCampo } = formulario.mutators;
		let superficieTotal = 0;
		let valor = 0;
		detalles.forEach((detalle: AvaluoTerrenoDetalle) => {
			superficieTotal = superficieTotal + Number(detalle.superficie);
			valor = valor + Number(detalle.valorResultante);
		});
		const valorTotal = (valor * indiviso) / 100;
		cambiarValorCampo('terreno.superficieTotal', superficieTotal);
		cambiarValorCampo('terreno.valor', valor);
		cambiarValorCampo('terreno.valorTotal', valorTotal);
	};

	return (
		<Fragment>
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.terreno}></i> {texto('Avalúo del Terreno')}
					</h3>
					<p>{texto('En esta sección del formulario se captura la información de los detalles del terreno del avalúo.')}</p>
					<hr />
				</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="4">
					{!esSeccionPreferencias() && (
						<span className="avaluos-formulario__botton-editar">
							<button className="btn btn-sm btn-primary" onClick={eventoEditarConstruccionFraccion} title={texto('Editar Fracción')} type="button">
								<i className={constantes.icono.editar}></i>
							</button>
						</span>
					)}
					<McCampoTexto
						campo="terreno.formularioDetalle.detalleFraccion"
						etiqueta={texto('Fracción')}
						id="terreno.formularioDetalle.detalleFraccion"
						numeroMinimo={0}
						obligatorio
						soloLectura={!esSeccionPreferencias() && !construccionFraccionEditable}
						tipo="numeroEnteroPequeno"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="3">
					<McCampoSelector
						campo="terreno.formularioDetalle.detalleSuperficieUnidadId"
						etiqueta={texto('Unidad')}
						id="terreno.formularioDetalle.detalleSuperficieUnidadId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaUnidadSuperficie)}
						soloLectura={detallesTemporales.length > 0}
					/>
				</Col>
				<Col lg="3">
					<McCampoTexto
						campo="terreno.formularioDetalle.detalleSuperficie"
						etiqueta={texto('Superficie')}
						id="terreno.formularioDetalle.detalleSuperficie"
						numeroMinimo={0}
						obligatorio
						tipo="numeroNumerico"
					/>
				</Col>
				<Col lg="3">
					<McCampoTexto
						campo="terreno.formularioDetalle.detalleValorUnitario"
						etiqueta={texto('Valor unitario')}
						id="terreno.formularioDetalle.detalleValorUnitario"
						numeroMinimo={0}
						obligatorio
						tipo="numeroNumerico"
					/>
				</Col>
			</Row>
			<Row>
				<Col lg="4">
					<McCampoTexto
						campo="terreno.formularioDetalle.detalleAjusteFactor"
						etiqueta={texto('Factor de ajuste')}
						id="terreno.formularioDetalle.detalleAjusteFactor"
						numeroMinimo={0}
						obligatorio
						tipo="numeroNumerico"
					/>
				</Col>
				<Col lg="4">
					<McCampoSelector
						campo="terreno.formularioDetalle.detalleAjusteMotivoId"
						etiqueta={texto('Motivo de ajuste')}
						id="terreno.formularioDetalle.detalleAjusteMotivoId"
						obligatorio
						opciones={formatearListaOpcionesMcCampoSelector(listaMotivo)}
					/>
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="terreno.formularioDetalle.detalleIndiviso"
						etiqueta={texto('Indiviso en su caso')}
						id="terreno.formularioDetalle.detalleIndiviso"
						numeroMaximo={100}
						numeroMinimo={0}
						obligatorio
						soloLectura={detallesTemporales.length > 0}
						textoDerecha="%"
						tipo="numeroNumerico"
					/>
				</Col>
			</Row>
			{!esSeccionPreferencias() && (
				<Row>
					<Col lg="12">
						<BarraHerramientas>
							<button
								className="btn btn-success"
								disabled={!formularioValido()}
								id="botonAgregarDetalle"
								onClick={eventoAgregarDetalle}
								title={texto('Agregar Detalle')}
								type="button"
							>
								<i className={constantes.icono.agregar}></i> {texto('Agregar')}
							</button>
						</BarraHerramientas>
					</Col>
				</Row>
			)}
			{!esSeccionPreferencias() && (
				<Row>
					<Col lg="12">
						<div className="table-responsive">
							<table className="table mc-tabla-paginada table-hover table-striped" id="tabla">
								<thead>
									<tr style={{ padding: '10px 0px', textAlign: 'center' }}>
										<th style={{ width: '50px' }}>{texto('Fracción')}</th>
										<th>{texto('Superficie')}</th>
										<th>{texto('Valor unitario')}</th>
										<th style={{ width: '50px' }}>{texto('Factor')}</th>
										<th style={{ width: '200px' }}>{texto('Motivo')}</th>
										<th style={{ width: '100px' }}>{texto('Valor neto')}</th>
										<th>{texto('Valor resultante')}</th>
										<th style={{ width: '64px' }}></th>
									</tr>
								</thead>
								<tbody>
									{detallesTemporales.map((detalle, indice) => (
										<tr key={`bloque_${indice}`} style={{ padding: '10px 0px', textAlign: 'center' }}>
											<td>{convertirNumeroEnteroANumeroRomano(Number(detalle.fraccion))}</td>
											<td style={{ textAlign: 'right' }}>{`${Number(detalle.superficie).toLocaleString('es-mx')} ${
												obtenerOpcionDeLaLista(listaUnidadSuperficie, detalle.superficieUnidadId)?.simbolo || ''
											}`}</td>
											<td style={{ textAlign: 'right' }}>{moneda(Number(detalle.valorUnitario))}</td>
											<td>{detalle.ajusteFactor}</td>
											<td>{obtenerOpcionDeLaLista(listaMotivo, detalle.ajusteMotivoId)?.nombre}</td>
											<td>{moneda(Number(detalle.valorNeto))}</td>
											<td style={{ textAlign: 'right' }}>{moneda(Number(detalle.valorResultante))}</td>
											<td>
												<button
													className="btn btn-danger"
													id={`botonEliminarDetalle_${detalle.id}`}
													onClick={() => eventoEliminarDetalle(indice)}
													title={texto('Eliminar Detalle')}
													type="button"
												>
													<i className={constantes.icono.eliminar}></i>
												</button>
											</td>
										</tr>
									))}
									{detallesTemporales.length === 0 && (
										<tr>
											<td className="avaluos-formulario__tabla__sin-detalles" colSpan={12}>
												{texto('Sin detalles.')}
											</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					</Col>
				</Row>
			)}
			{detallesTemporales.length > 0 && (
				<Row>
					<Col lg="12">
						<table className="table-nowrap table-centered mb-0 table">
							<tbody>
								<tr>
									<th className="text-end">{texto('Superficie total')}:</th>
									<td className="text-end" style={{ width: '200px' }}>
										{`${Number(superficieTotal).toLocaleString('es-MX')} ${obtenerOpcionDeLaLista(listaUnidadSuperficie, superficieUnidadId)?.simbolo || ''}`}
									</td>
								</tr>
								<tr>
									<th className="border-0 text-end">{texto('Valor del terreno')}:</th>
									<td className="border-0 text-end">{moneda(Number(valor))}</td>
								</tr>
								<tr>
									<th className="border-0 text-end">{texto('Valor total')}:</th>
									<td className="border-0 text-end">
										<h4 className="m-0">{moneda(Number(valorTotal))}</h4>
									</td>
								</tr>
							</tbody>
						</table>
					</Col>
				</Row>
			)}
			<Row>
				<Col lg="12">{dibujarBarraHerramientasAlerta()}</Col>
			</Row>
			<Row>
				<Col lg="12">{dibujarBarraHerramientas()}</Col>
			</Row>
		</Fragment>
	);
};

export default AvaluoFormularioTerreno;
