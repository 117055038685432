import { Fragment, useEffect, useState } from 'react';
import aplicacion from 'configuracion/aplicacion';
import BarraNavegacionLateralContenido from 'componentes/tema-vertical/BarraNavegacionLateralContenido';
import { Link } from 'react-router-dom';
import { StateType } from 'store';
import { useSelector } from 'react-redux';

/**
 * Barra de navegación lateral izquerda que contiene el logo de la aplicación y el menú.
 */
const BarraNavegacionLateral = () => {
	const { configuracionAplicacion } = useSelector((state: StateType) => state);
	const { interfazBarraNavegacionLateralAncho } = useSelector((state: StateType) => state.sesion.usuario.preferencias);
	const [logoClaro1080x202, setLogoClaro1080x202] = useState(aplicacion.imagenes.logoClaro1080x202);
	const [logoClaro202x202, setLogoClaro202x202] = useState(aplicacion.imagenes.logoClaro202x202);
	const [logoOscuro1080x202, setLogoOscuro1080x202] = useState(aplicacion.imagenes.logoOscuro1080x202);
	const [logoOscuro202x202, setLogoOscuro202x202] = useState(aplicacion.imagenes.logoOscuro202x202);
	const { imagenLogoClaro202x202, imagenLogoClaro1080x202, imagenLogoOscuro202x202, imagenLogoOscuro1080x202 } = configuracionAplicacion;

	useEffect(() => {
		setLogoClaro1080x202(imagenLogoClaro1080x202 ? imagenLogoClaro1080x202 : aplicacion.imagenes.logoClaro1080x202);
		setLogoClaro202x202(imagenLogoClaro202x202 ? imagenLogoClaro202x202 : aplicacion.imagenes.logoClaro202x202);
		setLogoOscuro1080x202(imagenLogoOscuro1080x202 ? imagenLogoOscuro1080x202 : aplicacion.imagenes.logoOscuro1080x202);
		setLogoOscuro202x202(imagenLogoOscuro202x202 ? imagenLogoOscuro202x202 : aplicacion.imagenes.logoOscuro202x202);
	}, [imagenLogoClaro202x202, imagenLogoClaro1080x202, imagenLogoOscuro1080x202, imagenLogoOscuro202x202]);

	return (
		<Fragment>
			<div className="vertical-menu">
				<div className="navbar-brand-box">
					<Link className="logo logo-dark" to="/">
						<span className="logo-sm">
							<img alt="" height="35" src={logoOscuro202x202} />
						</span>
						<span className="logo-lg">
							<img alt="" height="30" src={logoOscuro1080x202} />
						</span>
					</Link>

					<Link className="logo logo-light" to="/">
						<span className="logo-sm">
							<img alt="" height="35" src={logoClaro202x202} />
						</span>
						<span className="logo-lg">
							<img alt="" height="30" src={logoClaro1080x202} />
						</span>
					</Link>
				</div>
				<div className="h-100" data-simplebar>
					{interfazBarraNavegacionLateralAncho !== 'condensed' ? <BarraNavegacionLateralContenido /> : <BarraNavegacionLateralContenido />}
				</div>
			</div>
		</Fragment>
	);
};

export default BarraNavegacionLateral;
