import { Col, Row } from 'reactstrap';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

/**
 * Formulario para la configuración de logs del sistema.
 */
const SistemaFormularioLogs = () => (
	<Row>
		<Col lg="12">
			<Row>
				<Col md="6" xl="4">
					<McSwitchRedondo
						campo="loggerLog"
						colorEncendido="#000000"
						etiqueta={texto('Logs normales')}
						id="loggerLog"
						informacion={texto('Permite ver en la consola los logs normales')}
					/>
				</Col>
				<Col md="6" xl="4">
					<McSwitchRedondo
						campo="loggerApi"
						colorEncendido="#006600"
						etiqueta={texto('Logs de llamadas a API')}
						id="loggerApi"
						informacion={texto('Permite ver en la consola los logs de las peticiones HTTP')}
					/>
				</Col>
				<Col md="6" xl="4">
					<McSwitchRedondo
						campo="loggerError"
						colorEncendido="#FF0000"
						etiqueta={texto('Logs de errores')}
						id="loggerError"
						informacion={texto('Permite ver en la consola los logs de error')}
					/>
				</Col>
				<Col md="6" xl="4">
					<McSwitchRedondo
						campo="loggerDev"
						colorEncendido="#996600"
						etiqueta={texto('Logs de desarrollo')}
						id="loggerDev"
						informacion={texto('Permite ver en la consola los logs de desarrollo')}
					/>
				</Col>
				<Col md="6" xl="4">
					<McSwitchRedondo
						campo="loggerLocalStorage"
						colorEncendido="#ff00ff"
						etiqueta={texto('Logs de localStorage')}
						id="loggerLocalStorage"
						informacion={texto('Permite ver en la consola los logs de las modificaciones al localStorage')}
					/>
				</Col>
				<Col md="6" xl="4">
					<McSwitchRedondo
						campo="loggerSessionStorage"
						colorEncendido="#ff3399"
						etiqueta={texto('Logs de sessionStorage')}
						id="loggerSessionStorage"
						informacion={texto('Permite ver en la consola los logs de las modificaciones al sessionStorage')}
					/>
				</Col>
				<Col md="6" xl="4">
					<McSwitchRedondo
						campo="loggerReact"
						colorEncendido="#0000FF"
						etiqueta={texto('Logs de React')}
						id="loggerReact"
						informacion={texto('Permite ver en la consola los logs de las modificaciones al state de React')}
					/>
				</Col>
				<Col md="6" xl="4">
					<McSwitchRedondo
						campo="loggerRedux"
						colorEncendido="#6600FF"
						etiqueta={texto('Logs de Redux')}
						id="loggerRedux"
						informacion={texto('Permite ver en la consola los logs de las modificaciones al state de Redux')}
					/>
				</Col>
				<Col md="6" xl="4">
					<McSwitchRedondo
						campo="loggerNodeModule"
						colorEncendido="#ff6600"
						etiqueta={texto('Logs de node_modules')}
						id="loggerNodeModule"
						informacion={texto('Permite ver en la consola los logs de las librerías node_modules que implementan el McLogger')}
					/>
				</Col>
			</Row>
		</Col>
	</Row>
);

export default SistemaFormularioLogs;
