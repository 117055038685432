import './style.scss';
import 'react-image-lightbox/style.css';
import { Fragment, useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';

const TEXTO = {
	aumentarZoom: 'Aumentar zoom',
	cerrarVisor: 'Cerrar visor',
	disminuirZoom: 'Disminuir zoom',
	imagenAnterior: 'Imagen anterior',
	siguienteImagen: 'Siguiente imagen'
};

/**
 * Interfaz para un elemeneto del McVisorImagen.
 */
export interface McVisorImagenElemento {
	/**
	 * Descripción de la imagen.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	descripcion?: string;
	/**
	 * Identificador único de la imagen.
	 */
	id: string;
	/**
	 * Título de la imagen.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	titulo?: string;
	/**
	 * URL de la imagen.
	 */
	url: string;
	/**
	 * URL de la miniatura de la imagen.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	urlMiniatura?: string;
}

interface McVisorImagenProps {
	/**
	 * Arreglo con las URL de las imagenes a mostrar.
	 */
	imagenes: Array<McVisorImagenElemento>;
	/**
	 * Muestra/oculta el visor de imagen.
	 */
	mostrar: boolean;
	/**
	 * Evento que se ejecuta cuando se cierra el visor de imagen.
	 */
	eventoCerrar: () => void;
	/**
	 * Objeto con los textos personalizados del componente.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	texto?: McVisorImagenTexto;
}

export interface McVisorImagenTexto {
	/**
	 * Texto que se mostrará en los atributos *aria-label* y *title* del botón de aumentar zoom.
	 *
	 * > ***Predeterminado:*** *'Aumentar zoom'*
	 */
	aumentarZoom?: string;
	/**
	 * Texto que se mostrará en los atributos *aria-label* y *title* del botón de cerrar visor.
	 *
	 * > ***Predeterminado:*** *'Cerrar visor'*
	 */
	cerrarVisor?: string;
	/**
	 * Texto que se mostrará en los atributos *aria-label* y *title* del botón de disminuir zoom.
	 *
	 * > ***Predeterminado:*** *'Disminuir zoom'*
	 */
	disminuirZoom?: string;
	/**
	 * Texto que se mostrará en los atributos *aria-label* y *title* del botón de imagen anterior.
	 *
	 * > ***Predeterminado:*** *'Imagen anterior'*
	 */
	imagenAnterior?: string;
	/**
	 * Texto que se mostrará en los atributos *aria-label* y *title* del botón de siguiente imagen.
	 *
	 * > ***Predeterminado:*** *'Siguiente imagen'*
	 */
	siguienteImagen?: string;
}

/**
 * Componente de ***React*** que permite seleccionar una imagen del disco duro y cortarla para después guardarla.
 */
const McVisorImagen = (props: McVisorImagenProps) => {
	const [indiceActual, setIndiceActual] = useState<number>(0);
	const [imagenAnterior, setImagenAnterior] = useState<string>();
	const [siguienteImagen, setSiguienteImagen] = useState<string>();
	const { eventoCerrar, imagenes, mostrar } = props;
	let { texto } = props;
	texto = { ...TEXTO, ...texto };

	useEffect(() => {
		setIndiceActual(0);
		if (imagenes.length > 1) {
			setImagenAnterior(imagenes[(indiceActual + imagenes.length - 1) % imagenes.length].url);
			setSiguienteImagen(imagenes[(indiceActual + 1) % imagenes.length].url);
		} else {
			setImagenAnterior(undefined);
			setSiguienteImagen(undefined);
		}
	}, [imagenes]);

	/**
	 * Cambia el índice actual por el anterior en el arreglo.
	 */
	const verImagenAnterior = (): void => {
		setIndiceActual((indiceActual + imagenes.length - 1) % imagenes.length);
	};

	/**
	 * Cambia el índice actual por el siguiente en el arreglo.
	 */
	const verImagenSiguiente = (): void => {
		setIndiceActual((indiceActual + 1) % imagenes.length);
	};

	return (
		<Fragment>
			{mostrar && (
				<Lightbox
					clickOutsideToClose={false}
					closeLabel={texto.cerrarVisor}
					enableZoom={true}
					imageCaption={imagenes[indiceActual]?.descripcion}
					imagePadding={50}
					imageTitle={imagenes[indiceActual]?.titulo}
					mainSrc={imagenes[indiceActual] ? imagenes[indiceActual].url : ''}
					nextLabel={texto.siguienteImagen}
					nextSrc={siguienteImagen}
					onCloseRequest={eventoCerrar}
					onMoveNextRequest={verImagenSiguiente}
					onMovePrevRequest={verImagenAnterior}
					prevLabel={texto.imagenAnterior}
					prevSrc={imagenAnterior}
					zoomInLabel={texto.aumentarZoom}
					zoomOutLabel={texto.disminuirZoom}
				/>
			)}
		</Fragment>
	);
};

export default McVisorImagen;
