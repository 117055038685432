import { actualizarPerito, obtenerPeritoPorId } from 'servicios/api/peritos';
import { setImagenesTemporalesFotoPeritoLicenciaAction, setPantallaCargaMostrarAction } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEliminarPeritoLicencia, useGuardarPeritoLicencia, useObtenerPeritoLicencia } from 'hooks/useImagen';
import { useHistory, useParams } from 'react-router-dom';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import McSelectorImagen from '@mcsoft/selector-imagen';
import Perito from 'modelo/Perito';
import { procesarError } from '@mcsoft/api';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useEsSeccionAdministracion } from 'hooks/useSeccion';

const NOMBRE_CLASE = 'paginas/app/peritos/PeritoLicenciaEditar';

/**
 * En esta página se puede editar la foto de la licencia del perito.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const obtenerFotoPeritoLicencia = useObtenerPeritoLicencia();
	const eliminarPeritoLicencia = useEliminarPeritoLicencia();
	const guardarPeritoLicencia = useGuardarPeritoLicencia();
	const [registro, setRegistro] = useState<Perito>();
	const history = useHistory();
	const { id } = useParams<any>();
	const { auth0AccessToken } = useSelector((state: StateType) => state.sesion.auth0);
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.peritos);
	const {
		criterio: criterioAdmin,
		orden: ordenAdmin,
		ordenamiento: ordenamientoAdmin,
		pagina: paginaAdmin,
		registrosPorPagina: registrosPorPaginaAdmin
	} = useSelector((state: StateType) => state.parametrosPaginacion.peritosAdmin);
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [rutaSalida, setRutaSalida] = useState<string>(`${constantes.ruta.appPeritosDetalles}/${id}`);
	const [enlaces, setEnlaces] = useState<any>([]);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, []);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, [location.pathname]);

	/**
	 * Actualiza el registro.
	 */
	const actualizarRegistro = async (registro: Perito) => {
		const nombreMetodo = 'actualizarRegistro';
		try {
			mcLogger.log({ mensaje: `Actualizando perito...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			await actualizarPerito({ auth0AccessToken, id: registro.id, valores: registro });
			mcLogger.log({ mensaje: `Perito actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
			mcNotificaciones.exito({ mensaje: texto('Foto de licencia guardada con éxito.') });
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
			history.push(rutaSalida);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al actualizar el perito:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			dispatch(setPantallaCargaMostrarAction(false));
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Guarda la imagen.
	 * > - ***imagenTipo*** - Tipo MIME de la imagen.
	 * > - ***imagenExtension*** - Extensión de la imagen.
	 * > - ***imagenBase64*** - Imagen recortada codificada en base64.
	 */
	const eventoGuardarImagen = async (imagenTipo: string, imagenExtension: string, imagenBase64: string) => {
		const nombreMetodo = 'eventoGuardarImagen';
		try {
			if (registro) {
				const nombreImagen = `${registro.id}_${new Date().getTime()}`;
				const nombreImagenConExtension = `${nombreImagen}.${imagenExtension}`;
				mcLogger.log({ mensaje: `Guardando foto de licencia...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				dispatch(setPantallaCargaMostrarAction(true));
				await guardarPeritoLicencia({
					archivoBase64: imagenBase64,
					archivoNombre: nombreImagenConExtension,
					archivoTipo: imagenTipo
				});
				mcLogger.log({ mensaje: `Foto de licencia guardada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				dispatch(setImagenesTemporalesFotoPeritoLicenciaAction({ imagenBase64, peritoId: registro.id }));
				const licenciaFotoAnterior = registro.licenciaFoto;
				registro.licenciaFoto = nombreImagenConExtension;
				if (licenciaFotoAnterior) {
					mcLogger.log({ mensaje: `Eliminando foto de licencia anterior...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
					await eliminarPeritoLicencia(licenciaFotoAnterior);
					mcLogger.log({ mensaje: `Foto de licencia anterior eliminada con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				}
				await actualizarRegistro(registro);
			}
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar la foto de licencia:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			dispatch(setPantallaCargaMostrarAction(false));
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Obteniendo información del perito...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			const respuesta = await obtenerPeritoPorId({ auth0AccessToken, id });
			mcLogger.log({ mensaje: `Información del perito obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const { datos } = respuesta;
			setRegistro(datos);
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener el perito: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los valores de las variables que cambian en función de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionAdministracion()) {
			setTituloSeccion(texto('Editar licencia del perito'));
			const rutaSalida = `${constantes.ruta.appAdministracionPeritosDetalles}/${id}`;
			const rutaSalidaLista = `${constantes.ruta.appAdministracionPeritosLista}?pagina=${paginaAdmin}&registrosPorPagina=${registrosPorPaginaAdmin}&ordenamiento=${ordenamientoAdmin}&orden=${ordenAdmin}&criterio=${criterioAdmin}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{
					ruta: rutaSalidaLista,
					titulo: texto('Lista de peritos')
				},
				{ ruta: rutaSalida, titulo: texto('Detalles del perito') }
			]);
		} else {
			setTituloSeccion(texto('Editar licencia de mi perito'));
			const rutaSalida = `${constantes.ruta.appPeritosDetalles}/${id}`;
			const rutaSalidaLista = `${constantes.ruta.appPeritosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{
					ruta: rutaSalidaLista,
					titulo: texto('Mis peritos')
				},
				{ ruta: rutaSalida, titulo: texto('Detalles de mi perito') }
			]);
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={constantes.icono.licencia} titulo={tituloSeccion} />
			<McSelectorImagen
				eventoCancelar={eventoCancelar}
				eventoGuardar={eventoGuardarImagen}
				imagen={obtenerFotoPeritoLicencia({ perito: registro })}
				minimoZoom={0.5}
				relacionAspecto={420 / 260}
			/>
		</Contenedor>
	);
};

export default Pagina;
