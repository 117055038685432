/* eslint-disable padded-blocks */
import { Component, ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import constantes from 'configuracion/constantes';
import mcLogger from '@mcsoft/logger';
import { texto } from 'idiomas';

const NOMBRE_CLASE = 'ManejadorErrores';

interface ManejadorErroresProps extends RouteComponentProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
}

interface ManejadorErroresState {
	/**
	 * Indica si existe un error.
	 */
	error: boolean;
}

/**
 * Clase para manejar los errores ocurridos en los componentes de React.
 */
class ManejadorErrores extends Component<ManejadorErroresProps, ManejadorErroresState> {
	constructor(props: ManejadorErroresProps) {
		super(props);
		this.state = {
			error: false
		};
	}

	componentDidCatch(error: Error, info: any) {
		const nombreMetodo = 'componentDidCatch';
		mcLogger.react({ mensaje: `Ocurrio un error:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: info });
		mcLogger.error({ mensaje: `Error: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: error });
	}

	static getDerivedStateFromError() {
		return {
			error: true
		};
	}

	render() {
		const { children } = this.props;
		const { error } = this.state;
		if (error) {
			return (
				<div className="middle-box text-center animated fadeInDown" id={`${NOMBRE_CLASE}_errorBoundary_error500`}>
					<h1>{texto('Error')}</h1>
					<div className="error-desc">
						{texto('Ocurrio un error inesperado en la aplicación y no pudo completarse la solicitud. Nos disculpamos por las molestias')}
						<br />
						{texto('Puedes regresar a la página principal')}
						<br />
						<button className="btn btn-primary m-t" id="botonRedireccionarInicio" onClick={() => this.eventoRedireccionarInicio()} type="button">
							{texto('Ir al inicio')}
						</button>
					</div>
				</div>
			);
		}
		return children;
	}

	/**
	 * Redirige al usuario a la página de inicio de la aplicación según la sección en la que se encontraba cuando sucedió el error.
	 */
	eventoRedireccionarInicio() {
		const nombreMetodo = 'eventoRedireccionarInicio';
		const { history } = this.props;
		const rutasArreglo = history.location.pathname.split('/');
		const [, seccionAplicacion] = rutasArreglo;
		let ruta = '';
		switch (seccionAplicacion) {
			case 'app':
				ruta = constantes.ruta.appInicio;
				break;
			case 'web':
				ruta = constantes.ruta.webInicio;
				break;
			default:
				ruta = constantes.ruta.webInicio;
				break;
		}
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	}
}

export default withRouter(ManejadorErrores);
