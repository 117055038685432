import { ReactNode, useEffect, useState } from 'react';

interface MenuGrupoProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
	/**
	 * Etiqueta que se mostrará en el botón.
	 */
	etiqueta: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	icono?: string;
	/**
	 * Identificador único del grupo.
	 */
	id: string;
}

/**
 * Agrupa un conjunto de botones.
 */
const MenuGrupo = (props: MenuGrupoProps) => {
	const [grupoOculto, setGrupoOculto] = useState(true);
	const { children, etiqueta, icono, id } = props;

	useEffect(() => {
		const grupo = document.getElementById(id);
		const botones = grupo?.getElementsByTagName('a');
		setGrupoOculto(true);
		if (botones) {
			for (let i = 0; i < botones.length; i++) {
				if (botones[i].getAttribute('data-oculto') === 'false') {
					setGrupoOculto(false);
					break;
				}
			}
		}
	}, [children]);

	/**
	 * Colapsa todos los grupos excepto el recibido.
	 * - ***item*** - Grupo activo.
	 * - ***padresIds*** - Arreglo con los ids de los padres.
	 */
	const colapsar = ({ item, padresIds }: { item: HTMLElement; padresIds: Array<string> }) =>
		new Promise((resolve) => {
			if (item.classList.contains('navbar-nav')) {
				const hijos = item.children;
				for (let i = 0; i < hijos.length; i++) {
					const contenedor = hijos[i].lastElementChild;
					if (contenedor) {
						const contenedorId = contenedor.getAttribute('id');
						if (contenedorId && contenedorId !== id && !padresIds.includes(contenedorId)) {
							contenedor.classList.remove('show');
						}
					}
				}
			}
			resolve(null);
		});

	/**
	 * Expande el grupo y colapsa todos los demas.
	 * - ***item*** - Grupo activo.
	 */
	const expandirColapsar = async () => {
		const contenedor = document.getElementById(id);
		contenedor?.classList.toggle('show');
		const itemPadre = contenedor?.parentElement;
		if (itemPadre) {
			const padresIds = obtenerPadres(itemPadre);
			await colapsar({ item: itemPadre, padresIds });
			const itemPadreNivel2 = itemPadre.parentElement;
			if (itemPadreNivel2) {
				await colapsar({ item: itemPadreNivel2, padresIds });
				const itemPadreNivel3 = itemPadreNivel2.parentElement;
				if (itemPadreNivel3) {
					await colapsar({ item: itemPadreNivel3, padresIds });
					const itemPadreNivel4 = itemPadreNivel3.parentElement;
					if (itemPadreNivel4) {
						await colapsar({ item: itemPadreNivel4, padresIds });
						const itemPadreNivel5 = itemPadreNivel4.parentElement;
						if (itemPadreNivel5) {
							await colapsar({ item: itemPadreNivel5, padresIds });
							const itemPadreNivel6 = itemPadreNivel5.parentElement;
							if (itemPadreNivel6) {
								await colapsar({ item: itemPadreNivel6, padresIds });
							}
						}
					}
				}
			}
		}
	};

	/**
	 * Colapsa todos los grupos excepto el recibido.
	 * - ***item*** - Grupo activo.
	 * - ***padresIds*** - Arreglo con los ids de los padres.
	 */
	const obtenerPadre = ({ item, padresIds }: { item: HTMLElement; padresIds: Array<string> }) => {
		const contenedorId = item.getAttribute('id');
		if (contenedorId === 'topnav-menu-content' || contenedorId === 'navigation' || contenedorId === 'page-topbar' || contenedorId === 'layout-wrapper' || contenedorId === 'root') {
			return;
		}
		if (contenedorId) {
			padresIds.push(contenedorId);
		}
	};

	/**
	 * Expande el grupo y colapsa todos los demas.
	 * - ***item*** - Grupo activo.
	 */
	const obtenerPadres = (item: HTMLElement) => {
		const padresIds: Array<string> = [];
		const itemPadre = item?.parentElement;
		if (itemPadre) {
			obtenerPadre({ item: itemPadre, padresIds });
			const itemPadreNivel2 = itemPadre.parentElement;
			if (itemPadreNivel2) {
				obtenerPadre({ item: itemPadreNivel2, padresIds });
				const itemPadreNivel3 = itemPadreNivel2.parentElement;
				if (itemPadreNivel3) {
					obtenerPadre({ item: itemPadreNivel3, padresIds });
					const itemPadreNivel4 = itemPadreNivel3.parentElement;
					if (itemPadreNivel4) {
						obtenerPadre({ item: itemPadreNivel4, padresIds });
						const itemPadreNivel5 = itemPadreNivel4.parentElement;
						if (itemPadreNivel5) {
							obtenerPadre({ item: itemPadreNivel5, padresIds });
							const itemPadreNivel6 = itemPadreNivel5.parentElement;
							if (itemPadreNivel6) {
								obtenerPadre({ item: itemPadreNivel6, padresIds });
							}
						}
					}
				}
			}
		}
		return padresIds;
	};

	return (
		<li className="nav-item dropdown" hidden={grupoOculto}>
			<span className="nav-link dropdown-togglez arrow-none" onClick={expandirColapsar} style={{ cursor: 'pointer' }}>
				<i className={icono}></i> {etiqueta} <div className="arrow-down"></div>
			</span>
			<div className="dropdown-menu" id={id}>
				{children}
			</div>
		</li>
	);
};

export default MenuGrupo;
