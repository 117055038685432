import 'moment/locale/es-mx';
import Avaluo from 'modelo/Avaluo';
import disenos from './disenos';
import moment from 'moment-timezone';
moment.locale('es-mx');

const seccion = (avaluo: Avaluo) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.antecedentes) {
			const { claveCatastral, cuentaPredial, fechaAvaluo, nombrePropietario, nombreSolicitante, numeroRecaudadora, regimenPropiedad, tipoInmueble, ubicacionPredio } =
				avaluo.antecedentes;
			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									colSpan: 6,
									style: 'seccionTitulo',
									text: 'ANTECEDENTES'
								},
								{},
								{},
								{},
								{},
								{}
							],
							[
								{
									style: { alignment: 'right' },
									text: 'Cuenta Predial / CURT'
								},
								{
									style: { alignment: 'center', bold: true },
									text: cuentaPredial
								},
								{
									border: [1, 1, 0, 1],
									style: { alignment: 'center' },
									text: 'Rec. No.'
								},
								{
									border: [0, 1, 1, 1],
									style: { alignment: 'center', bold: true },
									text: numeroRecaudadora
								},
								{
									border: [1, 1, 0, 1],
									text: 'Clave Catastral:'
								},
								{
									border: [0, 1, 1, 1],
									style: { alignment: 'left', bold: true },
									text: claveCatastral
								}
							],
							[
								{
									border: [1, 1, 1, 0],
									colSpan: 6,
									layout: { ...disenos.sinBordes, ...disenos.sinPaddingArribaAbajo },
									margin: [0, 5, 0, 5],
									style: 'seccion',
									table: {
										body: [
											[
												{
													style: 'seccionEtiqueta',
													text: 'Nombre del Solicitante:'
												},
												{
													text: nombreSolicitante
												}
											]
										],
										widths: [100, '*']
									}
								},
								{},
								{},
								{},
								{},
								{}
							],
							[
								{
									border: [1, 0, 1, 0],
									colSpan: 6,
									layout: { ...disenos.sinBordes, ...disenos.sinPaddingArribaAbajo },
									margin: [0, 0, 0, 0],
									style: 'seccion',
									table: {
										body: [
											[
												{
													style: 'seccionEtiqueta',
													text: 'Titular:'
												},
												{
													text: nombrePropietario
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Fecha del Avalúo:'
												},
												{
													text: fechaAvaluo ? moment(fechaAvaluo).tz('America/Bahia_Banderas').locale(false).format('DD [de] MMMM [de] YYYY') : ''
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Inmueble que se Valúa:'
												},
												{
													text: tipoInmueble?.nombre
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Ubicación del Predio:'
												},
												{
													text: ubicacionPredio
												}
											]
										],
										heights: [10, 10, 10, 50],
										widths: [100, '*']
									}
								},
								{},
								{},
								{},
								{},
								{}
							],
							[
								{
									border: [1, 0, 1, 1],
									colSpan: 6,
									layout: { ...disenos.sinBordes, ...disenos.sinPaddingArribaAbajo },
									margin: [0, 0, 0, 5],
									style: 'seccion',
									table: {
										body: [
											[
												{
													style: 'seccionEtiqueta',
													text: 'Régimen de Propiedad:'
												},
												{
													text: regimenPropiedad?.nombre
												}
											]
										],
										widths: [100, '*']
									}
								},
								{},
								{},
								{},
								{},
								{}
							]
						],
						widths: [100, 100, 50, 50, 63, '*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;
