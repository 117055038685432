/* eslint-disable no-unused-vars */
import './McCampoSelectorMultiple.scss';
import { ChangeEvent, Fragment, MouseEvent, useEffect, useState } from 'react';
import { Field, FieldMetaState } from 'react-final-form';
import Select from 'react-select';
import { Tooltip } from 'reactstrap';

const TEXTO = {
	errorObligatorio: 'Obligatorio'
};

interface McCampoSelectorMultipleProps {
	/**
	 * Nombre del campo del formulario donde se guardara el valor del input.
	 */
	campo: string;
	/**
	 * Clase del botón que se mostrará a la derecha del campo.
	 *
	 * > ***Predeterminado:*** *'primary'*
	 *
	 * **Valores Admitidos**
	 * - **'primary':** Fondo azul y texto blanco.
	 * - **'secondary':** Fondo gris y texto blanco.
	 * - **'success':** Fondo verde y texto blanco.
	 * - **'danger':** Fondo rojo y texto blanco.
	 * - **'warning':** Fondo amarillo y texto negro.
	 * - **'info':** Fondo aqua y texto negro.
	 * - **'light':** Fondo blanco y texto negro.
	 * - **'dark':** Fondo negro y texto blanco.
	 * - **'primary contorno':** Contorno azul, fondo negro y texto azul.
	 * - **'secondary contorno':** Contorno gris, fondo negro y texto gris.
	 * - **'success contorno':** Contorno verde, fondo negro y texto verde.
	 * - **'danger contorno':** Contorno rojo, fondo negro y texto rojo.
	 * - **'warning contorno':** Contorno amarillo, fondo negro y texto amarillo.
	 * - **'info contorno':** Contorno aqua, fondo negro y texto aqua.
	 * - **'light contorno':** Contorno blanco, fondo negro y texto blanco.
	 * - **'dark contorno':** Contorno negro, fondo blanco y texto negro.
	 * - **'link':** Formato de hyper enlace.
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > (iconoDerecha != undefined || textoDerecha != undefined) && eventoBotonDerecha != undefined
	 */
	claseBotonDerecha?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'danger'
		| 'warning'
		| 'info'
		| 'light'
		| 'dark'
		| 'primary contorno'
		| 'secondary contorno'
		| 'success contorno'
		| 'danger contorno'
		| 'warning contorno'
		| 'info contorno'
		| 'light contorno'
		| 'dark contorno'
		| 'link';
	/**
	 * Clase del botón que se mostrará a la izquierda del campo.
	 *
	 * > ***Predeterminado:*** *'primary'*
	 *
	 * **Valores Admitidos**
	 * - **'primary':** Fondo azul y texto blanco.
	 * - **'secondary':** Fondo gris y texto blanco.
	 * - **'success':** Fondo verde y texto blanco.
	 * - **'danger':** Fondo rojo y texto blanco.
	 * - **'warning':** Fondo amarillo y texto negro.
	 * - **'info':** Fondo aqua y texto negro.
	 * - **'light':** Fondo blanco y texto negro.
	 * - **'dark':** Fondo negro y texto blanco.
	 * - **'primary contorno':** Contorno azul, fondo negro y texto azul.
	 * - **'secondary contorno':** Contorno gris, fondo negro y texto gris.
	 * - **'success contorno':** Contorno verde, fondo negro y texto verde.
	 * - **'danger contorno':** Contorno rojo, fondo negro y texto rojo.
	 * - **'warning contorno':** Contorno amarillo, fondo negro y texto amarillo.
	 * - **'info contorno':** Contorno aqua, fondo negro y texto aqua.
	 * - **'light contorno':** Contorno blanco, fondo negro y texto blanco.
	 * - **'dark contorno':** Contorno negro, fondo blanco y texto negro.
	 * - **'link':** Formato de hyper enlace.
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > (iconoIzquierda != undefined || textoIzquierda != undefined) && eventoBotonIzquierda != undefined
	 */
	claseBotonIzquierda?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'danger'
		| 'warning'
		| 'info'
		| 'light'
		| 'dark'
		| 'primary contorno'
		| 'secondary contorno'
		| 'success contorno'
		| 'danger contorno'
		| 'warning contorno'
		| 'info contorno'
		| 'light contorno'
		| 'dark contorno'
		| 'link';
	/**
	 * Etiqueta que se mostrará sobre el input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	etiqueta?: string;
	/**
	 * Evento que se ejecuta al presionar el botón ubicado a la derecha del campo.
	 * - ***evento*** - Evento que invoca la función.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > iconoDerecha != undefined || textoDerecha != undefined
	 */
	eventoBotonDerecha?: (evento: MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Evento que se ejecuta al presionar el botón ubicado a la izquierda del campo.
	 * - ***evento*** - Evento que invoca la función.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > iconoIzquierda != undefined || textoIzquierda != undefined
	 */
	eventoBotonIzquierda?: (evento: MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Evento que se ejecuta al seleccionar una opción del selector.
	 * - ***evento*** - Evento que invoca la función.
	 */
	eventoCambio?: (evento: ChangeEvent<HTMLSelectElement>) => void;
	/**
	 * Icono *FontAwesome* que se mostrará a la derecha del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Ejemplo:** *'fa-solid fa-info-circle'*
	 */
	iconoDerecha?: string;
	/**
	 * Icono *FontAwesome* que se mostrará junto a la etiqueta si hay información para mostrar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-info-circle'*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > informacion != undefined
	 */
	iconoInformacion?: string;
	/**
	 * Icono *FontAwesome* que se mostrará a la izquierda del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Ejemplo:** *'fa-solid fa-info-circle'*
	 */
	iconoIzquierda?: string;
	/**
	 * Identificador único del componente.
	 */
	id: string;
	/**
	 * Contenido que se mostrará al colocar el cursor en el icono de información.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	informacion?: any;
	/**
	 * Indica si el campo es obligatorio.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	obligatorio?: boolean;
	/**
	 * Lista de opciones del selector.
	 */
	opciones: Array<McCampoSelectorMultipleOpcion> | Array<McCampoSelectorMultipleGrupo>;
	/**
	 * Texto que se mostrará en el input cuando este vacío.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	placeholder?: string;
	/**
	 * Indica si el campo debe ser resaltado cuando es obligatorio.
	/**
	 * Indica si se podrán seleccionar más de una opción a la vez.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	seleccionMultiple?: boolean;
	/**
	 * Indica si el input mostrará colores de validación*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	sinValidacion?: boolean;
	/**
	 * Indica si el input será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
	/**
	 * Objeto con los textos personalizados del componente.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	texto?: McCampoSelectorMultipleTexto;
	/**
	 * Texto que se mostrará a la derecha del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	textoDerecha?: string;
	/**
	 * Texto que se mostrará a la izquierda del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	textoIzquierda?: string;
}

interface McCampoSelectorMultipleTexto {
	/**
	 * Texto que se mostrará al indicar que el campo es obligatorio.
	 *
	 * > ***Predeterminado:*** *'Obligatorio'*
	 */
	errorObligatorio?: string;
}

/**
 * Input que permite elegir entre una lista de valores y permite elegir varias opciones a la vez.
 */
export const McCampoSelectorMultiple = (props: McCampoSelectorMultipleProps): JSX.Element => {
	const [mostrarTooltip, setMostrarTooltip] = useState(false);
	const [opcionesFormateadas, setOpcionesFormateadas] = useState<Array<any>>([]);
	const {
		campo,
		claseBotonDerecha = 'primary',
		claseBotonIzquierda = 'primary',
		etiqueta,
		eventoBotonDerecha,
		eventoBotonIzquierda,
		eventoCambio,
		iconoDerecha,
		iconoInformacion = 'fa-solid fa-info-circle',
		iconoIzquierda,
		id,
		informacion,
		obligatorio = false,
		opciones,
		placeholder,
		seleccionMultiple = false,
		sinValidacion = false,
		soloLectura = false,
		textoDerecha,
		textoIzquierda
	} = props;
	let { texto } = props;
	texto = { ...TEXTO, ...texto };

	/**
	 * Indica si la variable recibida tiene un valor (No es *null*, *undefined* ni cadena vacía).
	 * - ***valor*** - Valor a analizar.
	 */
	const tieneValor = (valor: any): boolean => {
		if (valor === undefined) {
			return false;
		}
		if (valor === null) {
			return false;
		}
		if (valor === '') {
			return false;
		}
		return true;
	};

	/**
	 * Válida el input.
	 * - ***eventoParametro*** - Evento que ejecuta la función.
	 */
	const validarCampo = async ({ meta, valor, valores }: { meta: FieldMetaState<string>; valor: string; valores?: any }): Promise<string | undefined | void> => {
		if (obligatorio && !tieneValor(valor)) {
			return texto?.errorObligatorio;
		}
	};

	const CLASES_BOTON = {
		danger: 'btn btn-danger',
		'danger contorno': 'btn btn-outline-danger',
		dark: 'btn btn-dark',
		'dark contorno': 'btn btn-outline-dark',
		info: 'btn btn-info',
		'info contorno': 'btn btn-outline-info',
		light: 'btn btn-light',
		'light contorno': 'btn btn-outline-light',
		link: 'btn btn-link',
		primary: 'btn btn-primary',
		'primary contorno': 'btn btn-outline-primary',
		secondary: 'btn btn-secondary',
		'secondary contorno': 'btn btn-outline-secondary',
		success: 'btn btn-success',
		'success contorno': 'btn btn-outline-success',
		warning: 'btn btn-warning',
		'warning contorno': 'btn btn-outline-warning'
	};

	const formatearOpciones = (): Array<any> => {
		const opcionesFormateadas: Array<any> = [];
		opciones.forEach((opcion: any) => {
			if (opcion.opciones) {
				const opcionesAnidadas: Array<any> = [];
				opcion.opciones.forEach((opcionAnidada: McCampoSelectorMultipleOpcion) => {
					opcionesAnidadas.push({
						label: opcionAnidada.nombre,
						value: opcionAnidada.valor
					});
				});
				opcionesFormateadas.push({
					label: opcion.nombreGrupo,
					options: opcionesAnidadas
				});
			} else {
				opcionesFormateadas.push({
					label: opcion.nombre,
					value: opcion.valor
				});
			}
		});
		return opcionesFormateadas;
	};

	useEffect(() => {
		setOpcionesFormateadas(formatearOpciones());
	}, [opciones]);

	const claseBotonDerechaOriginal = CLASES_BOTON[claseBotonDerecha];
	const claseBotonIzquierdaOriginal = CLASES_BOTON[claseBotonIzquierda];

	return (
		<Field
			name={campo}
			parse={(valor) => {
				if (!valor) {
					return null as any;
				} else {
					return valor;
				}
			}}
			validate={(valor: string, valores?: any, meta?: any) => validarCampo({ meta, valor, valores })}
		>
			{({ input, meta }) => {
				const valido = !meta.error && meta.touched;
				const esInvalido = meta.error;
				const inputSelector = document.getElementById(id);
				if (inputSelector) {
					inputSelector.style.padding = '0px 36.64px 0px 0px';
					const primerHijo = inputSelector.firstChild;
					if (primerHijo) {
						(primerHijo as any).style.borderStyle = 'none';
						if (soloLectura) {
							inputSelector.style.backgroundColor = '#d6d5d4';
							(primerHijo as any).style.backgroundColor = '#d6d5d4';
						} else if (valido && !sinValidacion) {
							inputSelector.style.borderColor = '#34c38f';
						} else if (esInvalido && !sinValidacion) {
							inputSelector.style.borderColor = '#f46a6a';
						} else {
							inputSelector.style.borderColor = '#ced4da';
						}
					}
				}
				const eventoFocusNuevo = (evento: any) => {
					input.onFocus(evento);
					const inputSelector = document.getElementById(id);
					if (inputSelector) {
						const primerHijo = inputSelector.firstChild;
						if (primerHijo) {
							inputSelector.classList.add('mc-campo-selector-multiple__input--focused');
						}
					}
				};
				const eventoBlurNuevo = (evento: any) => {
					input.onBlur(evento);
					const inputSelector = document.getElementById(id);
					if (inputSelector) {
						const primerHijo = inputSelector.firstChild;
						if (primerHijo) {
							inputSelector.classList.remove('mc-campo-selector-multiple__input--focused');
						}
					}
				};
				const eventoCambioNuevo = (evento: any) => {
					input.onChange(evento);
					if (eventoCambio) {
						eventoCambio(evento);
					}
				};
				return (
					<Fragment>
						{informacion && (
							<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
								{informacion}
							</Tooltip>
						)}
						{etiqueta && (
							<label className="form-label" htmlFor={id}>
								{etiqueta}
							</label>
						)}
						{informacion && (
							<span className="text-primary">
								{' '}
								<i className={iconoInformacion} id={`${id}_tooltip`}></i>
							</span>
						)}
						<div className="input-group" style={{ marginBottom: '10px' }}>
							{(iconoIzquierda || textoIzquierda) && !eventoBotonIzquierda && (
								<span className="input-group-text">
									<i className={iconoIzquierda}></i>&nbsp;{textoIzquierda}
								</span>
							)}
							{(iconoIzquierda || textoIzquierda) && eventoBotonIzquierda && (
								<button
									className={claseBotonIzquierdaOriginal}
									onClick={(evento: MouseEvent<HTMLButtonElement>) => {
										eventoBotonIzquierda(evento);
									}}
								>
									<i className={iconoIzquierda}></i>&nbsp;{textoIzquierda}
								</button>
							)}
							<Select
								className={`form-control${valido && !sinValidacion ? ' is-valid' : ''}${esInvalido && !sinValidacion ? ' is-invalid' : ''}`}
								id={id}
								isClearable={true}
								isDisabled={soloLectura}
								isMulti={seleccionMultiple}
								menuPortalTarget={document.body}
								menuPosition="fixed"
								options={opcionesFormateadas}
								placeholder={placeholder ? placeholder : ''}
								styles={{
									menu: (provided) => ({ ...provided, zIndex: 9999 }),
									menuPortal: (provided) => ({ ...provided, zIndex: 9999 })
								}}
								{...input}
								onBlur={eventoBlurNuevo}
								onChange={eventoCambioNuevo}
								onFocus={eventoFocusNuevo}
								value={[input.value] || []}
							/>
							{(iconoDerecha || textoDerecha) && !eventoBotonDerecha && (
								<span className="input-group-text">
									<i className={iconoDerecha}></i>&nbsp;{textoDerecha}
								</span>
							)}
							{(iconoDerecha || textoDerecha) && eventoBotonDerecha && (
								<button
									className={claseBotonDerechaOriginal}
									onClick={(evento: MouseEvent<HTMLButtonElement>) => {
										eventoBotonDerecha(evento);
									}}
								>
									<i className={iconoDerecha}></i>&nbsp;{textoDerecha}
								</button>
							)}
						</div>
						{esInvalido && (
							<div className="form-text" style={{ color: '#f46a6a' }}>
								{meta.error}
							</div>
						)}
					</Fragment>
				);
			}}
		</Field>
	);
};

/**
 * Interface para una grupo del McCampoSelectorMultiple.
 */
export interface McCampoSelectorMultipleGrupo {
	/**
	 * Nombre del grupo de ociones en el selector.
	 */
	nombreGrupo: string;
	/**
	 * Lista de opciones del grupo que se mostrará en selector.
	 */
	opciones: Array<McCampoSelectorMultipleOpcion>;
}

/**
 * Interface para una opción del McCampoSelectorMultiple.
 */
export interface McCampoSelectorMultipleOpcion {
	/**
	 * Nombre que se mostrará en la opción el selector.
	 */
	nombre: string;
	/**
	 * Valor que tomara el input al seleccionar esta opción.
	 */
	valor: string;
}
