import aplicacion from 'configuracion/aplicacion';
import { McCampoSelectorMultipleOpcion } from '@mcsoft/formulario';
import Usuario from 'modelo/Usuario';
import UsuarioDomicilio from 'modelo/UsuarioDomicilio';

/**
 * Regresa el domicilio completo del usuario.
 * - ***domicilio*** - Objeto con la información del usuario.
 */
export const domicilioCompletoUsuario = (domicilio: UsuarioDomicilio): string => {
	let domicilioCompleto = '';
	if (domicilio) {
		const { calle, colonia, numeroExterior, numeroInterior } = domicilio;
		domicilioCompleto += calle ? calle : '';
		domicilioCompleto += numeroExterior ? ` No. ${numeroExterior}` : '';
		domicilioCompleto += numeroInterior ? ` Int. ${numeroInterior}` : '';
		domicilioCompleto += colonia ? ` Col. ${colonia}` : '';
	}
	return domicilioCompleto;
};

/**
 * Formatea una lista de usuarios para utilizarse como lista de opciones en un McCampoSelectorMultiple.
 * - ***lista*** - Arreglo con la lista de usuarios.
 */
export const formatearListaUsuariosMcCampoSelectorMultiple = (lista?: Array<Usuario>): Array<McCampoSelectorMultipleOpcion> => {
	const listaOpciones: Array<McCampoSelectorMultipleOpcion> = [];
	if (lista) {
		lista.forEach((elemento: Usuario) => {
			listaOpciones.push({ nombre: nombreCompletoUsuarioPorApellido(elemento), valor: elemento.id });
		});
	}
	return listaOpciones;
};

/**
 * Formatea un usuario para ser mostrado en el formulario.
 * - ***usuario*** - Objeto con la información del usuario.
 */
export const formatearUsuarioParaFormulario = (usuario: Usuario) => {
	const formulario: any = usuario;
	formulario.correoElectronicoConfirmacion = usuario.correoElectronico;
	if (usuario && usuario.domicilio) {
		if (usuario.domicilio.pais) {
			formulario.domicilio.paisSeleccion = {
				label: usuario.domicilio.pais.nombre,
				value: usuario.domicilio.pais.id
			};
		}
		if (usuario.domicilio.estado) {
			formulario.domicilio.estadoSeleccion = {
				label: usuario.domicilio.estado.nombre,
				value: usuario.domicilio.estado.id
			};
		}
		if (usuario.domicilio.municipio) {
			formulario.domicilio.municipioSeleccion = {
				label: usuario.domicilio.municipio.nombre,
				value: usuario.domicilio.municipio.id
			};
		}
	} else {
		formulario.domicilio = {
			paisSeleccion: {
				label: aplicacion.api.id.paisPredeterminadoNombre,
				value: aplicacion.api.id.paisPredeterminado
			}
		};
	}
	return formulario;
};

/**
 * Regresa el nombre completo del usuario (Nombre(s), Apellido paterno, Apellido materno).
 * - ***usuario*** - Objeto con la información del usuario.
 */
export const nombreCompletoUsuario = (usuario: Usuario): string => {
	let nombreCompleto = '';
	if (usuario) {
		const { apellidoMaterno, apellidoPaterno, nombre } = usuario;
		nombreCompleto += nombre ? `${nombre}` : '';
		nombreCompleto += apellidoPaterno ? ` ${apellidoPaterno}` : '';
		nombreCompleto += apellidoMaterno ? ` ${apellidoMaterno}` : '';
	}
	return nombreCompleto;
};

/**
 * Regresa el nombre completo del usuario (Apellido paterno, Apellido materno, Nombre(s)).
 * - ***usuario*** - Objeto con la información del usuario.
 */
export const nombreCompletoUsuarioPorApellido = (usuario: Usuario): string => {
	let nombreCompleto = '';
	if (usuario) {
		const { apellidoMaterno, apellidoPaterno, nombre } = usuario;
		nombreCompleto += apellidoPaterno ? `${apellidoPaterno}` : '';
		nombreCompleto += apellidoMaterno ? ` ${apellidoMaterno}` : '';
		nombreCompleto += nombre ? ` ${nombre}` : '';
	}
	return nombreCompleto;
};
