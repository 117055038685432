import { McCampoSelectorMultipleOpcion } from '@mcsoft/formulario';
import Municipio from 'modelo/Municipio';

/**
 * Formatea una lista de municipios para utilizarse como lista de opciones en un McCampoSelectorMultiple.
 * - ***lista*** - Arreglo con la lista de municipios.
 */
export const formatearListaMunicipiosMcCampoSelectorMultiple = (lista?: Array<Municipio>): Array<McCampoSelectorMultipleOpcion> => {
	const listaOpciones: Array<McCampoSelectorMultipleOpcion> = [];
	if (lista) {
		lista.forEach((elemento: Municipio) => {
			listaOpciones.push({ nombre: elemento.nombre, valor: elemento.id });
		});
	}
	return listaOpciones;
};
