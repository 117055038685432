import { ReactNode } from 'react';

interface MenuContenedorProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
}

/**
 * Contenedor del menú.
 */
const MenuContenedor = (props: MenuContenedorProps) => {
	const { children } = props;

	return (
		<div id="sidebar-menu">
			<ul className="metismenu list-unstyled" id="side-menu">
				{children}
			</ul>
		</div>
	);
};

export default MenuContenedor;
