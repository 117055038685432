/* eslint-disable no-unused-vars */
import './McCampoSelector.scss';
import { ChangeEvent, FocusEvent, Fragment, MouseEvent, useState } from 'react';
import { Field, FieldMetaState } from 'react-final-form';
import { Tooltip } from 'reactstrap';

const TEXTO = {
	errorObligatorio: 'Obligatorio'
};

interface McCampoSelectorProps {
	/**
	 * Nombre del campo del formulario donde se guardara el valor del input.
	 */
	campo: string;
	/**
	 * Clase del botón que se mostrará a la derecha del campo.
	 *
	 * > ***Predeterminado:*** *'primary'*
	 *
	 * **Valores Admitidos**
	 * - **'primary':** Fondo azul y texto blanco.
	 * - **'secondary':** Fondo gris y texto blanco.
	 * - **'success':** Fondo verde y texto blanco.
	 * - **'danger':** Fondo rojo y texto blanco.
	 * - **'warning':** Fondo amarillo y texto negro.
	 * - **'info':** Fondo aqua y texto negro.
	 * - **'light':** Fondo blanco y texto negro.
	 * - **'dark':** Fondo negro y texto blanco.
	 * - **'primary contorno':** Contorno azul, fondo negro y texto azul.
	 * - **'secondary contorno':** Contorno gris, fondo negro y texto gris.
	 * - **'success contorno':** Contorno verde, fondo negro y texto verde.
	 * - **'danger contorno':** Contorno rojo, fondo negro y texto rojo.
	 * - **'warning contorno':** Contorno amarillo, fondo negro y texto amarillo.
	 * - **'info contorno':** Contorno aqua, fondo negro y texto aqua.
	 * - **'light contorno':** Contorno blanco, fondo negro y texto blanco.
	 * - **'dark contorno':** Contorno negro, fondo blanco y texto negro.
	 * - **'link':** Formato de hyper enlace.
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > (iconoDerecha != undefined || textoDerecha != undefined) && eventoBotonDerecha != undefined
	 */
	claseBotonDerecha?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'danger'
		| 'warning'
		| 'info'
		| 'light'
		| 'dark'
		| 'primary contorno'
		| 'secondary contorno'
		| 'success contorno'
		| 'danger contorno'
		| 'warning contorno'
		| 'info contorno'
		| 'light contorno'
		| 'dark contorno'
		| 'link';
	/**
	 * Clase del botón que se mostrará a la izquierda del campo.
	 *
	 * > ***Predeterminado:*** *'primary'*
	 *
	 * **Valores Admitidos**
	 * - **'primary':** Fondo azul y texto blanco.
	 * - **'secondary':** Fondo gris y texto blanco.
	 * - **'success':** Fondo verde y texto blanco.
	 * - **'danger':** Fondo rojo y texto blanco.
	 * - **'warning':** Fondo amarillo y texto negro.
	 * - **'info':** Fondo aqua y texto negro.
	 * - **'light':** Fondo blanco y texto negro.
	 * - **'dark':** Fondo negro y texto blanco.
	 * - **'primary contorno':** Contorno azul, fondo negro y texto azul.
	 * - **'secondary contorno':** Contorno gris, fondo negro y texto gris.
	 * - **'success contorno':** Contorno verde, fondo negro y texto verde.
	 * - **'danger contorno':** Contorno rojo, fondo negro y texto rojo.
	 * - **'warning contorno':** Contorno amarillo, fondo negro y texto amarillo.
	 * - **'info contorno':** Contorno aqua, fondo negro y texto aqua.
	 * - **'light contorno':** Contorno blanco, fondo negro y texto blanco.
	 * - **'dark contorno':** Contorno negro, fondo blanco y texto negro.
	 * - **'link':** Formato de hyper enlace.
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > (iconoIzquierda != undefined || textoIzquierda != undefined) && eventoBotonIzquierda != undefined
	 */
	claseBotonIzquierda?:
		| 'primary'
		| 'secondary'
		| 'success'
		| 'danger'
		| 'warning'
		| 'info'
		| 'light'
		| 'dark'
		| 'primary contorno'
		| 'secondary contorno'
		| 'success contorno'
		| 'danger contorno'
		| 'warning contorno'
		| 'info contorno'
		| 'light contorno'
		| 'dark contorno'
		| 'link';
	/**
	 * Etiqueta que se mostrará sobre el input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	etiqueta?: string;
	/**
	 * Evento que se ejecuta al presionar el botón ubicado a la derecha del campo.
	 * - ***evento*** - Evento que invoca la función.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > iconoDerecha != undefined || textoDerecha != undefined
	 */
	eventoBotonDerecha?: (evento: MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Evento que se ejecuta al presionar el botón ubicado a la izquierda del campo.
	 * - ***evento*** - Evento que invoca la función.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > iconoIzquierda != undefined || textoIzquierda != undefined
	 */
	eventoBotonIzquierda?: (evento: MouseEvent<HTMLButtonElement>) => void;
	/**
	 * Evento que se ejecuta al seleccionar una opción del selector.
	 * - ***evento*** - Evento que invoca la función.
	 */
	eventoCambio?: (evento: ChangeEvent<HTMLSelectElement>) => void;
	/**
	 * Icono *FontAwesome* que se mostrará a la derecha del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Ejemplo:** *'fa-solid fa-info-circle'*
	 */
	iconoDerecha?: string;
	/**
	 * Icono *FontAwesome* que se mostrará junto a la etiqueta si hay información para mostrar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-info-circle'*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > informacion != undefined
	 */
	iconoInformacion?: string;
	/**
	 * Icono *FontAwesome* que se mostrará a la izquierda del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Ejemplo:** *'fa-solid fa-info-circle'*
	 */
	iconoIzquierda?: string;
	/**
	 * Identificador único del componente.
	 */
	id: string;
	/**
	 * Contenido que se mostrará al colocar el cursor en el icono de información.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	informacion?: any;
	/**
	 * Indica si el campo es obligatorio.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	obligatorio?: boolean;
	/**
	 * Lista de opciones del selector.
	 */
	opciones: Array<McCampoSelectorOpcion>;
	/**
	 * Texto que se mostrará en el input cuando este vacío.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	placeholder?: string;
	/**
	 * Indica si el input tendrá una opcion vacia al inicio de la lista*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	sinOpcionEnBlanco?: boolean;
	/**
	 * Indica si el input mostrará colores de validación*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	sinValidacion?: boolean;
	/**
	 * Indica si el input será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
	/**
	 * Tamaño del input.
	 *
	 * > ***Predeterminado:*** *md*
	 */
	tamano?: string;
	/**
	 * Objeto con los textos personalizados del componente.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	texto?: McCampoSelectorTexto;
	/**
	 * Texto que se mostrará a la derecha del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	textoDerecha?: string;
	/**
	 * Texto que se mostrará a la izquierda del input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	textoIzquierda?: string;
}

interface McCampoSelectorTexto {
	/**
	 * Texto que se mostrará al indicar que el campo es obligatorio.
	 *
	 * > ***Predeterminado:*** *'Obligatorio'*
	 */
	errorObligatorio?: string;
}

/**
 * Input que permite elegir entre una lista de valores.
 */
export const McCampoSelector = (props: McCampoSelectorProps): JSX.Element => {
	const [mostrarTooltip, setMostrarTooltip] = useState(false);
	const {
		campo,
		claseBotonDerecha = 'primary',
		claseBotonIzquierda = 'primary',
		etiqueta,
		eventoBotonDerecha,
		eventoBotonIzquierda,
		eventoCambio,
		iconoDerecha,
		iconoInformacion = 'fa-solid fa-info-circle',
		iconoIzquierda,
		id,
		informacion,
		obligatorio = false,
		opciones,
		placeholder,
		sinOpcionEnBlanco = false,
		sinValidacion = false,
		soloLectura = false,
		tamano,
		textoDerecha,
		textoIzquierda
	} = props;
	let { texto } = props;
	texto = { ...TEXTO, ...texto };

	/**
	 * Indica si la variable recibida tiene un valor (No es *null*, *undefined* ni cadena vacía).
	 * - ***valor*** - Valor a analizar.
	 */
	const tieneValor = (valor: any): boolean => {
		if (valor === undefined) {
			return false;
		}
		if (valor === null) {
			return false;
		}
		if (valor === '') {
			return false;
		}
		return true;
	};

	/**
	 * Válida el input.
	 * - ***eventoParametro*** - Evento que ejecuta la función.
	 */
	const validarCampo = async ({ meta, valor, valores }: { meta: FieldMetaState<string>; valor: string; valores?: any }): Promise<string | undefined | void> => {
		if (obligatorio && !tieneValor(valor)) {
			return texto?.errorObligatorio;
		}
	};

	const CLASES_BOTON = {
		danger: 'btn btn-danger',
		'danger contorno': 'btn btn-outline-danger',
		dark: 'btn btn-dark',
		'dark contorno': 'btn btn-outline-dark',
		info: 'btn btn-info',
		'info contorno': 'btn btn-outline-info',
		light: 'btn btn-light',
		'light contorno': 'btn btn-outline-light',
		link: 'btn btn-link',
		primary: 'btn btn-primary',
		'primary contorno': 'btn btn-outline-primary',
		secondary: 'btn btn-secondary',
		'secondary contorno': 'btn btn-outline-secondary',
		success: 'btn btn-success',
		'success contorno': 'btn btn-outline-success',
		warning: 'btn btn-warning',
		'warning contorno': 'btn btn-outline-warning'
	};

	const claseBotonDerechaOriginal = CLASES_BOTON[claseBotonDerecha];
	const claseBotonIzquierdaOriginal = CLASES_BOTON[claseBotonIzquierda];

	return (
		<Field
			name={campo}
			parse={(valor) => {
				if (!valor) {
					return null as any;
				} else {
					return valor;
				}
			}}
			validate={(valor: string, valores?: any, meta?: any) => validarCampo({ meta, valor, valores })}
		>
			{({ input, meta }) => {
				const valido = !meta.error && meta.touched;
				const esInvalido = meta.error;
				let tamanoFinal = '';
				switch (tamano) {
					case 'chico':
						tamanoFinal = 'sm';
						break;
					case 'grande':
						tamanoFinal = 'lg';
						break;
					default:
						tamanoFinal = '';
						break;
				}
				const claseTamano = tamanoFinal !== '' ? `form-control-${tamanoFinal}` : '';
				const claseInvalido = esInvalido && !sinValidacion ? 'is-invalid' : '';
				const claseValido = valido && !sinValidacion ? 'is-valid' : '';
				const eventoFocusNuevo = (evento: FocusEvent<HTMLSelectElement>) => {
					input.onFocus(evento);
					evento.target.classList.add('mc-campo-selector__input--focused');
				};
				const eventoBlurNuevo = (evento: FocusEvent<HTMLSelectElement>) => {
					input.onBlur(evento);
					evento.target.classList.remove('mc-campo-selector__input--focused');
				};
				const eventoCambioNuevo = (evento: ChangeEvent<HTMLSelectElement>) => {
					input.onChange(evento);
					if (eventoCambio) {
						eventoCambio(evento);
					}
				};
				return (
					<Fragment>
						{informacion && (
							<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
								{informacion}
							</Tooltip>
						)}
						{etiqueta && (
							<label className="form-label" htmlFor={id}>
								{etiqueta}
							</label>
						)}
						{informacion && (
							<span className="text-primary">
								{' '}
								<i className={iconoInformacion} id={`${id}_tooltip`}></i>
							</span>
						)}
						<div className="input-group" style={{ marginBottom: '10px' }}>
							{(iconoIzquierda || textoIzquierda) && !eventoBotonIzquierda && (
								<span className="input-group-text">
									<i className={iconoIzquierda}></i>&nbsp;{textoIzquierda}
								</span>
							)}
							{(iconoIzquierda || textoIzquierda) && eventoBotonIzquierda && (
								<button
									className={claseBotonIzquierdaOriginal}
									onClick={(evento: MouseEvent<HTMLButtonElement>) => {
										eventoBotonIzquierda(evento);
									}}
								>
									<i className={iconoIzquierda}></i>&nbsp;{textoIzquierda}
								</button>
							)}
							<select
								className={`form-control ${claseValido} ${claseInvalido} ${claseTamano}`}
								disabled={soloLectura}
								id={id}
								style={soloLectura ? { backgroundColor: '#d6d5d4' } : {}}
								{...input}
								onBlur={eventoBlurNuevo}
								onChange={eventoCambioNuevo}
								onFocus={eventoFocusNuevo}
							>
								{!sinOpcionEnBlanco && (
									<option style={{ color: '#999999' }} value="">
										{placeholder}
									</option>
								)}
								{opciones &&
									opciones.map((opcion, index) => (
										<option key={`mcSelector_${id}_opcion_${index}`} value={opcion.valor}>
											{opcion.nombre}
										</option>
									))}
							</select>
							{(iconoDerecha || textoDerecha) && !eventoBotonDerecha && (
								<span className="input-group-text">
									<i className={iconoDerecha}></i>&nbsp;{textoDerecha}
								</span>
							)}
							{(iconoDerecha || textoDerecha) && eventoBotonDerecha && (
								<button
									className={claseBotonDerechaOriginal}
									onClick={(evento: MouseEvent<HTMLButtonElement>) => {
										eventoBotonDerecha(evento);
									}}
								>
									<i className={iconoDerecha}></i>&nbsp;{textoDerecha}
								</button>
							)}
						</div>
						{esInvalido && (
							<div className="form-text" style={{ color: '#f46a6a' }}>
								{meta.error}
							</div>
						)}
					</Fragment>
				);
			}}
		</Field>
	);
};

/**
 * Interface para una opción del McCampoSelector.
 */
export interface McCampoSelectorOpcion {
	/**
	 * Nombre que se mostrará en la opción el selector.
	 */
	nombre: string;
	/**
	 * Valor que tomara el input al seleccionar esta opción.
	 */
	valor: string;
}
