import { ChangeEvent, Fragment, useState } from 'react';
import { Field } from 'react-final-form';
import { Tooltip } from 'reactstrap';

interface McSwitchCuadradoProps {
	/**
	 * Nombre del campo del formulario donde se guardará el valor del input.
	 */
	campo: string;
	/**
	 * Código hexadecimal del color que tomara el switch cuando este apagado.
	 *
	 * > ***Predeterminado:*** *'#888'*
	 *
	 > **Nota:** Acepta 3 o 6 dígitos, ejemplo: #888, #45abcd.
	 */
	colorApagado?: string;
	/**
	 * Código hexadecimal del color que tomara el switch cuando este encendido.
	 *
	 * > ***Predeterminado:*** *'#080'*
	 *
	 > **Nota:** Acepta 3 o 6 dígitos, ejemplo: #888, #45abcd.
	 */
	colorEncendido?: string;
	/**
	 * Etiqueta que se mostrará sobre el input.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	etiqueta?: string;
	/**
	 * Icono *FontAwesome* que se mostrará junto a la etiqueta si hay información para mostrar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-info-circle'*
	 */
	iconoInformacion?: string;
	/**
	 * Identificador único del componente.
	 */
	id: string;
	/**
	 * Contenido que se mostrará al colocar el cursor en el icono de información.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	informacion?: any;
	/**
	 * Indica si el input será de solo lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	soloLectura?: boolean;
	/**
	 * Texto que se mostrará cuando el switch este apagado.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	textoApagado?: string;
	/**
	 * Texto que se mostrará cuando el switch este encendido.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	textoEncendido?: string;
}

/**
 * Input booleano con forma de switch cuadrado.
 */
export const McSwitchCuadrado = (props: McSwitchCuadradoProps): JSX.Element => {
	const [mostrarTooltip, setMostrarTooltip] = useState(false);
	const {
		campo,
		colorApagado = '#888',
		colorEncendido = '#080',
		etiqueta,
		iconoInformacion = 'fa-solid fa-info-circle',
		id,
		informacion,
		soloLectura = false,
		textoApagado,
		textoEncendido
	} = props;

	return (
		<Field name={campo} type="checkbox">
			{({ input }) => {
				input.switch = 'none';
				const { checked, onChange } = input;
				const color = checked ? colorEncendido : colorApagado;
				return (
					<Fragment>
						{informacion && (
							<Tooltip isOpen={mostrarTooltip} placement="right" target={`${id}_tooltip`} toggle={() => setMostrarTooltip(!mostrarTooltip)}>
								{informacion}
							</Tooltip>
						)}
						{etiqueta && (
							<label className="form-label" htmlFor={id}>
								{etiqueta}
							</label>
						)}
						{informacion && (
							<span className="text-primary">
								{' '}
								<i className={iconoInformacion} id={`${id}_tooltip`}></i>
							</span>
						)}
						<div className="square-switch">
							<input checked={checked} disabled={soloLectura} id={id} {...input} onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.checked)} />
							<label data-off-label={textoApagado} data-on-label={textoEncendido} htmlFor={id} style={{ backgroundColor: color }} />
						</div>
					</Fragment>
				);
			}}
		</Field>
	);
};
