import aplicacion from 'configuracion/aplicacion';

/**
 * Lista de idiomas disponibles.
 */
const idiomas = {
	en: {
		bandera: aplicacion.imagenes.banderaUsa64x42,
		nombre: 'English'
	},
	es: {
		bandera: aplicacion.imagenes.banderaMexico64x42,
		nombre: 'Español'
	}
};

export default idiomas;