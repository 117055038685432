import Avaluo from 'modelo/Avaluo';

const seccion = (avaluo: Avaluo) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.pdfFotosBase64) {
			const medidas = obtenerMedidas(avaluo.pdfFotosBase64.length);
			const renglones = obtenerRenglones(avaluo.pdfFotosBase64, medidas);
			const contenido = [
				{
					layout: {
						paddingBottom: () => 0,
						paddingLeft: () => 0,
						paddingRight: () => 0,
						paddingTop: () => 0
					},
					margin: [0, 0, 0, 0],
					table: {
						body: [
							[
								{
									margin: [0, 2, 0, 2],
									style: 'seccionTitulo',
									text: 'REPORTE FOTOGRÁFICO'
								}
							],
							[
								{
									layout: {
										paddingBottom: () => 2,
										paddingLeft: () => 0,
										paddingRight: () => 0,
										paddingTop: () => 2
									},
									margin: [0, 0, 0, 0],
									table: {
										body: renglones,
										widths: medidas.widths
									}
								}
							]
						],
						heights: ['*', 510],
						widths: ['*']
					}
				}
			];
			resolve(contenido);
		}
	});

const obtenerRenglon = (fotos: Array<string>, fotosPorRenglon: number, medidas: any) => {
	const { fotoAlto, fotoAncho, fuente, transX, transY } = medidas;
	const renglon = [];
	for (let i = 0; i < fotosPorRenglon; i++) {
		if (fotos[i] && fotos[i] !== 'error') {
			renglon.push({
				alignment: 'center',
				border: [0, 0, 0, 0],
				image: fotos[i],
				maxHeight: fotoAlto,
				maxWidth: fotoAncho
			});
		} else if (fotos[i] && fotos[i] === 'error') {
			renglon.push({
				alignment: 'center',
				border: [0, 0, 0, 0],
				height: fotoAlto,
				svg: `
					<svg>
						<text
						fill="rgba(204, 204, 204, 0.5)"
						font-weight="bold"
						style="font-size: ${fuente}px;"
						transform="translate(${transX}, ${transY}) rotate(30)"
						>
						-- ERROR --
						</text>
					</svg>
					`,
				width: fotoAncho
			});
		} else {
			renglon.push({
				alignment: 'center',
				border: [0, 0, 0, 0],
				height: fotoAlto,
				svg: `
					<svg>
						<text
						fill="rgba(204, 204, 204, 0.5)"
						font-weight="bold"
						style="font-size: ${fuente}px;"
						transform="translate(${transX}, ${transY}) rotate(30)"
						>
						SIN TEXTO
						</text>
					</svg>
					`,
				width: fotoAncho
			});
		}
	}
	return renglon;
};

const obtenerRenglones = (fotos: Array<string>, medidas: any) => {
	const fotosCantidad = fotos.length;
	const renglones = [];

	if (fotosCantidad >= 0 && fotosCantidad <= 6) {
		// 3x4 Fotos
		renglones.push(obtenerRenglon(fotos.slice(0, 2), 2, medidas));
		renglones.push(obtenerRenglon(fotos.slice(2, 4), 2, medidas));
		renglones.push(obtenerRenglon(fotos.slice(4), 2, medidas));
	}

	if (fotosCantidad > 6 && fotosCantidad <= 12) {
		// 3x4 Fotos
		renglones.push(obtenerRenglon(fotos.slice(0, 3), 3, medidas));
		renglones.push(obtenerRenglon(fotos.slice(3, 6), 3, medidas));
		renglones.push(obtenerRenglon(fotos.slice(6, 9), 3, medidas));
		renglones.push(obtenerRenglon(fotos.slice(9), 3, medidas));
	}

	if (fotosCantidad > 12 && fotosCantidad <= 20) {
		// 4x5 Fotos
		renglones.push(obtenerRenglon(fotos.slice(0, 4), 4, medidas));
		renglones.push(obtenerRenglon(fotos.slice(4, 8), 4, medidas));
		renglones.push(obtenerRenglon(fotos.slice(8, 12), 4, medidas));
		renglones.push(obtenerRenglon(fotos.slice(12, 16), 4, medidas));
		renglones.push(obtenerRenglon(fotos.slice(16), 4, medidas));
	}

	if (fotosCantidad > 20 && fotosCantidad <= 30) {
		// 5x6 Fotos
		renglones.push(obtenerRenglon(fotos.slice(0, 5), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(5, 10), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(10, 15), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(15, 20), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(20, 25), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(25), 5, medidas));
	}

	if (fotosCantidad > 30 && fotosCantidad <= 35) {
		// 5x7 Fotos
		renglones.push(obtenerRenglon(fotos.slice(0, 5), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(5, 10), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(10, 15), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(15, 20), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(20, 25), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(25, 30), 5, medidas));
		renglones.push(obtenerRenglon(fotos.slice(30), 5, medidas));
	}

	return renglones;
};

const obtenerMedidas = (fotosCantidad: number) => {
	let medidias = {
		fotoAlto: 0,
		fotoAncho: 0,
		fuente: 0,
		transX: 0,
		transY: 0,
		widths: ['*']
	};

	if (fotosCantidad >= 0 && fotosCantidad <= 6) {
		// 2x3 Fotos
		medidias = {
			fotoAlto: 174,
			fotoAncho: 255,
			fuente: 54,
			transX: 5,
			transY: 38,
			widths: ['*', '*']
		};
	}

	if (fotosCantidad > 6 && fotosCantidad <= 12) {
		// 3x4 Fotos
		medidias = {
			fotoAlto: 120,
			fotoAncho: 170,
			fuente: 36,
			transX: 5,
			transY: 26,
			widths: ['*', '*', '*']
		};
	}

	if (fotosCantidad > 12 && fotosCantidad <= 20) {
		// 4x5 Fotos
		medidias = {
			fotoAlto: 99,
			fotoAncho: 128,
			fuente: 26,
			transX: 5,
			transY: 26,
			widths: ['*', '*', '*', '*']
		};
	}

	if (fotosCantidad > 20 && fotosCantidad <= 30) {
		// 5x6 Fotos
		medidias = {
			fotoAlto: 79,
			fotoAncho: 102,
			fuente: 22,
			transX: 1,
			transY: 20,
			widths: ['*', '*', '*', '*', '*']
		};
	}

	if (fotosCantidad > 30 && fotosCantidad <= 35) {
		// 5x7 Fotos
		medidias = {
			fotoAlto: 72,
			fotoAncho: 93,
			fuente: 20,
			transX: 1,
			transY: 18,
			widths: ['*', '*', '*', '*', '*']
		};
	}

	return medidias;
};

export default seccion;
