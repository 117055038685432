import Avaluo from 'modelo/Avaluo';
import disenos from './disenos';
import ListaOpcion from 'modelo/ListaOpcion';

const seccion = ({ avaluo, mostrarCoeficientesUtilizacionOcupacion }: { avaluo: Avaluo; mostrarCoeficientesUtilizacionOcupacion: boolean }) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.caracteristicas && avaluo.construccionesDominantes && avaluo.serviciosMunicipales) {
			const { clasificacionZona, coeficienteOcupacion, coeficienteUtilizacion, densidadConstruccion } = avaluo.caracteristicas;
			const { descripcion: descripcionConstruccionesDominantes } = avaluo.construccionesDominantes;
			const { descripcion: descripcionServiciosMunicipales } = avaluo.serviciosMunicipales;
			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: obtenerBody({
							clasificacionZona,
							coeficienteOcupacion,
							coeficienteUtilizacion,
							densidadConstruccion,
							descripcionConstruccionesDominantes,
							descripcionServiciosMunicipales,
							mostrarCoeficientesUtilizacionOcupacion
						}),
						widths: ['*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;

const obtenerBody = ({
	clasificacionZona,
	coeficienteOcupacion,
	coeficienteUtilizacion,
	densidadConstruccion,
	descripcionConstruccionesDominantes,
	descripcionServiciosMunicipales,
	mostrarCoeficientesUtilizacionOcupacion
}: {
	clasificacionZona?: ListaOpcion | null;
	coeficienteOcupacion?: number | null;
	coeficienteUtilizacion?: number | null;
	densidadConstruccion?: number | null;
	descripcionConstruccionesDominantes?: string | null;
	descripcionServiciosMunicipales?: string | null;
	mostrarCoeficientesUtilizacionOcupacion?: boolean | null;
}) => {
	const body: any = [
		[
			{
				style: 'seccionTitulo',
				text: 'CARACTERISTICAS URBANAS'
			}
		],
		[
			{
				border: [1, 1, 1, 0],
				layout: { ...disenos.sinBordes, ...disenos.sinPaddingArribaAbajo },
				margin: [0, 5, 0, 0],
				style: 'seccion',
				table: {
					body: [
						[
							{
								style: 'seccionEtiqueta',
								text: 'Clasificación de la Zona:'
							},
							{
								text: clasificacionZona?.nombre
							}
						],
						[
							{
								style: 'seccionEtiqueta',
								text: 'Densidad de Construcción:'
							},
							{
								text: densidadConstruccion ? `${densidadConstruccion}%` : ''
							}
						],
						[
							{
								style: 'seccionEtiqueta',
								text: 'Tipos de Const. Dominante:'
							},
							{
								text: descripcionConstruccionesDominantes
							}
						],
						[
							{
								style: 'seccionEtiqueta',
								text: 'Servicios Municipales:'
							},
							{
								text: descripcionServiciosMunicipales
							}
						]
					],
					heights: [10, 10, 20, 30],
					widths: [100, '*']
				}
			}
		]
	];
	if (mostrarCoeficientesUtilizacionOcupacion) {
		body.push([
			{
				border: [1, 0, 1, 1],
				layout: disenos.sinPaddingArribaAbajo,
				margin: [0, 0, 0, 5],
				style: 'seccion',
				table: {
					body: [
						[
							{
								border: [0, 0, 0, 0],
								style: 'seccionEtiqueta',
								text: 'Coeficiente de Utilización:'
							},
							{
								border: [0, 0, 0, 0],
								style: { alignment: 'center' },
								text: 'C.U.S.'
							},
							{
								border: [1, 1, 1, 1],
								style: { alignment: 'center' },
								text: coeficienteUtilizacion ? `${coeficienteUtilizacion}%` : ''
							},
							{
								border: [0, 0, 0, 0],
								style: 'seccionEtiqueta',
								text: 'Coeficiente de Ocupación:'
							},
							{
								border: [0, 0, 0, 0],
								style: { alignment: 'center' },
								text: 'C.O.S.'
							},
							{
								border: [1, 1, 1, 1],
								style: { alignment: 'center' },
								text: coeficienteOcupacion ? `${coeficienteOcupacion}%` : ''
							},
							{
								border: [0, 0, 0, 0],
								text: ''
							}
						]
					],
					widths: [100, 50, 50, '*', 63, 50, 30]
				}
			}
		]);
	}
	return body;
};
