import { Card, CardBody, Col, Row, Table } from 'reactstrap';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { McCampoSelector, obtenerValorMcCampoSelector } from '@mcsoft/formulario';
import constantes from 'configuracion/constantes';
import { Form } from 'react-final-form';
import mcLogger from '@mcsoft/logger';
import { nombreCompletoPeritoPorApellido } from 'util/modelo/peritos';
import { obtenerCantidadAvaluosPorPerito } from 'servicios/api/widgets';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';

const NOMBRE_CLASE = 'componentes/widget/cantidadAvaluosPorPerito/CantidadAvaluosPorPerito';

interface CantidadAvaluosPorPeritoProps {
	/**
	 * Indica si los resultados solo mostrarán la información del usuario en sesión.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	filtrarPorUsuario?: boolean;
}

/**
 * Muestra una tarjeta con la información de la cantidad de avalúos por perito.
 */
const CantidadAvaluosPorPerito = (props: CantidadAvaluosPorPeritoProps) => {
	const { filtrarPorUsuario = false } = props;
	const { sesion } = useSelector((state: StateType) => state);
	const [titulo, setTitulo] = useState<string>('');
	const [informacionRenglones, setInformacionRenglones] = useState<Array<any>>([]);
	const [periodo, setPeriodo] = useState<string>('mes');
	const { auth0, usuario } = sesion;
	const { auth0AccessToken } = auth0;

	useEffect(() => {
		obtenerInformacion();
	}, [periodo]);

	/**
	 * Setea la imagen en el estado de Redux para ser usada en el visor de imágenes.
	 */
	const eventoCambioPeriodo = async (evento: ChangeEvent<HTMLSelectElement>) => {
		const valor = obtenerValorMcCampoSelector(evento);
		setPeriodo(valor);
	};

	/**
	 * Setea la imagen en el estado de Redux para ser usada en el visor de imágenes.
	 */
	const obtenerInformacion = async () => {
		const nombreMetodo = 'obtenerInformacion';
		const parametros: any = { auth0AccessToken, periodo };
		setTitulo(texto('Top 5 avalúos por perito'));
		if (filtrarPorUsuario) {
			setTitulo(texto('Top 5 mis avalúos por perito'));
			parametros.id = usuario.id;
		}
		const respuesta = await obtenerCantidadAvaluosPorPerito(parametros);
		mcLogger.log({ mensaje: `Información obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		const informacionRenglones = respuesta.datos;
		setInformacionRenglones(informacionRenglones);
	};

	return (
		<Fragment>
			<Form
				initialValues={{ seleccion: 'mes' }}
				onSubmit={() => {}}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Card>
							<CardBody>
								<Row>
									<Col lg="12">
										<div className="float-start">
											<h4 className="card-title mb-4">{titulo}</h4>
										</div>
										<div className="float-end">
											<McCampoSelector
												campo="seleccion"
												eventoCambio={eventoCambioPeriodo}
												iconoIzquierda={constantes.icono.fecha}
												id="seleccion"
												opciones={[
													{ nombre: 'Este mes', valor: 'mes' },
													{ nombre: 'Este año', valor: 'año' },
													{ nombre: 'Todos', valor: 'siempre' }
												]}
												sinOpcionEnBlanco
											/>
										</div>
									</Col>
								</Row>
								<Row>
									<Col lg="12">
										<div className="table-responsive">
											<Table className="table-borderless table-centered table-nowrap">
												<tbody>
													<tr>
														<th></th>
														<th style={{ textAlign: 'center', width: '103px' }}>{texto('Incompletos')}</th>
														<th style={{ textAlign: 'center', width: '103px' }}>{texto('Completos')}</th>
														<th style={{ textAlign: 'center', width: '103px' }}>{texto('Total')}</th>
													</tr>
													{informacionRenglones.map((informacion: any) => (
														<tr key={`avaluosPorPerito_${informacion.peritoId}`}>
															<td>
																<h6 className="font-size-15 mb-1 fw-normal">{nombreCompletoPeritoPorApellido(informacion)}</h6>
															</td>
															<td style={{ textAlign: 'center' }}>
																<span className="font-size-15 badge rounded-pill bg-warning">{informacion.incompletos}</span>
															</td>
															<td style={{ textAlign: 'center' }}>
																<span className="font-size-15 badge rounded-pill bg-success ">{informacion.completos}</span>
															</td>
															<td style={{ textAlign: 'center' }}>
																<span className="font-size-15 badge rounded-pill bg-primary">{informacion.total}</span>
															</td>
														</tr>
													))}
												</tbody>
											</Table>
										</div>
									</Col>
								</Row>
							</CardBody>
						</Card>
					</form>
				)}
			/>
		</Fragment>
	);
};

export default CantidadAvaluosPorPerito;
