import aplicacion from 'configuracion/aplicacion';
import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del PDF del avalúo relacionado a las coordenadas UTM (Universal Transverse Mercator).
 */
const InformacionAvaluoPdfCoordenadasUtm = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Se mostrarán en el PDF las coordenadas UTM (Universal Transverse Mercator).')}</p>
		</div>
		<div className="text-center mt-2 mb-2">
			<img alt="Ejemplo" src={aplicacion.tutoriales.ejemploAvaluoPdfCoordenadasUtm} width="480px" />
		</div>
	</Fragment>
);

export default InformacionAvaluoPdfCoordenadasUtm;
