import { Fragment, ReactNode } from 'react';

interface TemaWebProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
}

/**
 * Componente contenedor del tema web.
 */
const TemaWeb = (props: TemaWebProps) => {
	const { children } = props;

	return <Fragment>{children}</Fragment>;
};

export default TemaWeb;
