/* eslint-disable no-unused-vars */
import './style.scss';
import { Area, Point } from 'react-easy-crop/types';
import { Col, Row } from 'reactstrap';
import { Fragment, useCallback, useEffect, useState } from 'react';
import { obtenerExtensionArchivo, obtenerTipoMimeDeExtension } from '@mcsoft/archivos';
import Cropper from 'react-easy-crop';
import mcLogger from '@mcsoft/logger';
import Slider from 'react-rangeslider';

const NOMBRE_CLASE = 'McSelectorImagen';

const ICONO_CANCELAR = 'fa-solid fa-arrow-circle-left';
const ICONO_GUARDAR = 'fa-solid fa-save';
const ICONO_IMAGEN_PREDETERMINADA = 'far fa-image';
const ICONO_ROTACION = 'fa-solid fa-rotate-right';
const ICONO_VOLTEAR_HORIZONTALMENTE = 'fa-duotone fa-repeat';
const ICONO_VOLTEAR_VERTICALMENTE = 'fa-duotone fa-repeat fa-rotate-90';
const ICONO_ZOOM = 'fa-regular fa-magnifying-glass-plus';
const LIMITE_ZOOM = 5;
const MINIMO_ZOOM = 1;
const TEXTO = {
	cancelar: 'Cancelar',
	guardar: 'Guardar',
	rotacion: 'Rotación',
	sinImagen: 'Selecciona una imagen.',
	zoom: 'Zoom'
};
const RELACION_ASPECTO = 1 / 1;

interface Archivo {
	base64: string;
	extension: string;
	nombre: string;
	tamano: number;
	tipo: string;
}

interface McSelectorImagenProps {
	/**
	 * Evento que se ejecuta cuando se presiona el botón de cancelar.
	 */
	eventoCancelar: () => void;
	/**
	 * Evento que se ejecuta cuando se presiona el botón de guardar.
	 * > - ***imagenTipo*** - Tipo MIME de la imagen.
	 * > - ***imagenExtension*** - Extensión de la imagen.
	 * > - ***imagenBase64*** - Imagen recortada codificada en base64.
	 */
	eventoGuardar: (imagenTipo: string, imagenExtension: string, imagenBase64: string) => void;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón de cancelar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-arrow-circle-left'*
	 */
	iconoCancelar?: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón de guardar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-save'*
	 */
	iconoGuardar?: string;
	/**
	 * Icono *FontAwesome* que se mostrará mientras aun no se ha seleccionado una imagen.
	 *
	 * > ***Predeterminado:*** *'far fa-image'*
	 */
	iconoImagenPredeterminada?: string;
	/**
	 * Icono *FontAwesome* que se mostrará junto al selector de rotación.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-sync-alt'*
	 */
	iconoRotacion?: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón de voltear horizontalmente.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-arrows-alt-h'*
	 */
	iconoVoltearHorizontalmente?: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón de voltear verticalmente.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-arrows-alt-v'*
	 */
	iconoVoltearVerticalmente?: string;
	/**
	 * Icono *FontAwesome* que se mostrará junto al selector de zoom.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-search-plus'*
	 */
	iconoZoom?: string;
	/**
	 * Imagen seleccionada.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * > **Nota:** En caso de que quiera seleccionarse una imagen desde el principio.
	 */
	imagen?: string;
	/**
	 * Límite máximo de zoom que se podrá aplicar a la imagen.
	 *
	 * > ***Predeterminado:*** *5*
	 *
	 * > **Nota:** El valor introducido equivale a la cantidad de veces que podrá crecer la imagen.
	 * > - **2:** La imagen podrá crecer el doble.
	 * > - **3:** La imagen podrá crecer el triple.
	 */
	limiteZoom?: number;
	/**
	 * Límite mínimo de zoom que se podrá aplicar a la imagen.
	 *
	 * > ***Predeterminado:*** *1*
	 *
	 * > **Nota:** El valor introducido equivale al tamaño mínimo de la imagen.
	 * > - **0.5:** La imagen podrá decrecer a la mitad de su tamaño original.
	 * > - **0.25:** La imagen podrá decrecer a un cuarto de su tamaño original.
	 */
	minimoZoom?: number;
	/**
	 * Relación de aspecto que tendra la sección del selector para recortar la imagen.
	 *
	 * > ***Predeterminado:*** *1/1*
	 */
	relacionAspecto?: number;
	/**
	 * Objeto con los textos personalizados del componente.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	texto?: McSelectorImagenTexto;
}

export interface McSelectorImagenTexto {
	/**
	 * Texto que se mostrará en el botón de cancelar.
	 *
	 * > ***Predeterminado:*** *'Cancelar'*
	 */
	cancelar?: string;
	/**
	 * Texto que se mostrará en el botón de guardar.
	 *
	 * > ***Predeterminado:*** *'Guardar'*
	 */
	guardar?: string;
	/**
	 * Texto que se mostrará en el botón de rotación.
	 *
	 * > ***Predeterminado:*** *'Rotación'*
	 */
	rotacion?: string;
	/**
	 * Texto que se mostrará mientras aun no se ha seleccionado una imagen.
	 *
	 * > ***Predeterminado:*** *'Selecciona una imagen.'*
	 */
	sinImagen?: string;
	/**
	 * Texto que se mostrará en el botón de zoom.
	 *
	 * > ***Predeterminado:*** *'Zoom'*
	 */
	zoom?: string;
}

/**
 * Componente de ***React*** que permite seleccionar una imagen del disco duro y cortarla para después guardarla.
 */
const McSelectorImagen = (props: McSelectorImagenProps) => {
	const [archivo, setArchivo] = useState<Archivo>();
	const [corte, setCorte] = useState<Point>({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [rotacion, setRotacion] = useState(0);
	const [areaCortadaPixeles, setAreaCortadaPixeles] = useState<Area | null>(null);
	const [imagenTemporal, setImagenTemporal] = useState<string | undefined>();
	const {
		eventoCancelar,
		eventoGuardar,
		iconoCancelar = ICONO_CANCELAR,
		iconoGuardar = ICONO_GUARDAR,
		iconoImagenPredeterminada = ICONO_IMAGEN_PREDETERMINADA,
		iconoRotacion = ICONO_ROTACION,
		iconoVoltearHorizontalmente = ICONO_VOLTEAR_HORIZONTALMENTE,
		iconoVoltearVerticalmente = ICONO_VOLTEAR_VERTICALMENTE,
		iconoZoom = ICONO_ZOOM,
		imagen,
		limiteZoom = LIMITE_ZOOM,
		minimoZoom = MINIMO_ZOOM,
		relacionAspecto = RELACION_ASPECTO
	} = props;
	let { texto } = props;
	texto = { ...TEXTO, ...texto };

	useEffect(() => {
		if (imagen) {
			setImagenTemporal(imagen);
			setArchivo({
				base64: '',
				extension: obtenerExtensionArchivo(imagen),
				nombre: imagen,
				tamano: 0,
				tipo: obtenerTipoMimeDeExtension(obtenerExtensionArchivo(imagen))
			});
		}
	}, [imagen]);

	/**
	 * Crea una imagen a partir de la URL.
	 * - ***url*** - URL de la imagen.
	 */
	const crearImagen = (url: string): Promise<HTMLImageElement> =>
		new Promise((resolve, reject) => {
			const imagen = new Image();
			imagen.addEventListener('load', () => resolve(imagen));
			imagen.addEventListener('error', (error) => reject(error));
			imagen.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
			imagen.src = url;
		});

	/**
	 * Setea la imagen cortada en el state del componente.
	 * - ***areaCortada*** - Área del corte realizado.
	 * - ***areaCortadaPixeles*** - Pixeles del área del corte realizado.
	 */
	const eventoCorteCompleto = useCallback((areaCortada: Area, areaCortadaPixeles: Area): void => {
		setAreaCortadaPixeles(areaCortadaPixeles);
	}, []);

	/**
	 * Guarda la imagen cortada.
	 */
	const eventoGuardarImagen = useCallback(async (): Promise<void> => {
		const nombreMetodo = 'eventoGuardarImagen';
		try {
			if (imagenTemporal) {
				const imagenCortada = await obtenerImagenCortada({ areaCortadaPixeles, rotacion, urlImagen: imagenTemporal });
				eventoGuardar(archivo?.tipo ? archivo?.tipo : '', archivo?.extension ? archivo?.extension : '', imagenCortada);
			}
		} catch (error) {
			mcLogger.error({ mensaje: `Error al guardar la imagen:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: error });
		}
	}, [areaCortadaPixeles, rotacion]);

	/**
	 * Obtiene la información del archivo seleccionado y lo guarda en el state local del componente.
	 * - ***evento*** - Evento que invoca la función.
	 */
	const eventoSeleccionarArchivo = (evento: any): void => {
		const nombreMetodo = 'eventoSeleccionarArchivo';
		if (evento.target.files && evento.target.files.length > 0) {
			const [archivoSeleccionado] = evento.target.files;
			const lector = new FileReader();
			lector.readAsDataURL(archivoSeleccionado);
			lector.onload = () => {
				setArchivo({
					base64: lector.result as string,
					extension: obtenerExtensionArchivo(archivoSeleccionado.name),
					nombre: archivoSeleccionado.name,
					tamano: archivoSeleccionado.size,
					tipo: archivoSeleccionado.type
				});
				setImagenTemporal(lector.result as string);
			};
			lector.onerror = (error) => {
				mcLogger.error({ mensaje: `Error al leer el archivo:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: error });
			};
		}
	};

	/**
	 * Evento que se ejecuta al presionar el botón de voltear imagen horizontalmente.
	 */
	const eventoVoltearImagenHorizontalmente = async (): Promise<void> => {
		const nombreMetodo = 'eventoVoltearImagenHorizontalmente';
		try {
			if (imagenTemporal) {
				const imagenVolteadaHorizontalmente = await obtenerImagenVolteadaHorizontalmente(imagenTemporal);
				setImagenTemporal(imagenVolteadaHorizontalmente);
			}
		} catch (error) {
			mcLogger.error({ mensaje: `Error al voltear la imagen:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: error });
		}
	};

	/**
	 * Evento que se ejecuta al presionar el botón de voltear imagen verticalmente.
	 */
	const eventoVoltearImagenVerticalmente = async (): Promise<void> => {
		const nombreMetodo = 'eventoVoltearImagenVerticalmente';
		try {
			if (imagenTemporal) {
				const imagenVolteadaVerticalmente = await obtenerImagenVolteadaVerticalmente(imagenTemporal);
				setImagenTemporal(imagenVolteadaVerticalmente);
			}
		} catch (error) {
			mcLogger.error({ mensaje: `Error al voltear la imagen:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: error });
		}
	};

	/**
	 * Obtiene el ángulo de rotación de la imagen en radianes.
	 * - ***rotacion*** - Rotación de la imagen en grados.
	 */
	const obtenerAnguloRadianes = (rotacion: number): number => (rotacion * Math.PI) / 180;

	/**
	 * Obtiene la imagen cortada a partir de la imagen original.
	 * - ***urlImagen*** - URL de la imagen original.
	 * - ***areaCortadaPixeles*** - Pixeles del área del corte realizado.
	 * - ***rotacion*** - Rotación de la imagen en grados.
	 */
	const obtenerImagenCortada = async ({
		areaCortadaPixeles,
		rotacion = 0,
		urlImagen
	}: {
		areaCortadaPixeles: Area | null;
		rotacion: number;
		urlImagen: string;
	}): Promise<string> => {
		const imagenOriginal = await crearImagen(urlImagen);
		const lienzo = document.createElement('canvas');
		const contexto = lienzo.getContext('2d');

		const tamanoMaximo = Math.max(imagenOriginal.width, imagenOriginal.height);
		const areaSegura = 2 * ((tamanoMaximo / 2) * Math.sqrt(2));

		lienzo.width = areaSegura;
		lienzo.height = areaSegura;

		if (contexto && areaCortadaPixeles) {
			contexto.translate(areaSegura / 2, areaSegura / 2);
			contexto.rotate(obtenerAnguloRadianes(rotacion));
			contexto.translate(-areaSegura / 2, -areaSegura / 2);
			contexto.drawImage(imagenOriginal, areaSegura / 2 - imagenOriginal.width * 0.5, areaSegura / 2 - imagenOriginal.height * 0.5);
			const datosImagen = contexto.getImageData(0, 0, areaSegura, areaSegura);

			lienzo.width = areaCortadaPixeles.width;
			lienzo.height = areaCortadaPixeles.height;

			contexto.putImageData(
				datosImagen,
				Math.round(0 - areaSegura / 2 + imagenOriginal.width * 0.5 - areaCortadaPixeles.x),
				Math.round(0 - areaSegura / 2 + imagenOriginal.height * 0.5 - areaCortadaPixeles.y)
			);
		}

		return lienzo.toDataURL(archivo?.tipo);
	};

	/**
	 * Voltea la imagen horizontalmente.
	 * - ***urlImagen*** - URL de la imagen original.
	 */
	const obtenerImagenVolteadaHorizontalmente = async (urlImagen: string): Promise<string> => {
		const imagenOriginal = await crearImagen(urlImagen);
		const lienzo = document.createElement('canvas');
		const contexto = lienzo.getContext('2d');

		lienzo.width = imagenOriginal.width;
		lienzo.height = imagenOriginal.height;

		if (contexto) {
			contexto.translate(lienzo.width, 0);
			contexto.scale(-1, 1);
			contexto.drawImage(imagenOriginal, 0, 0);
		}

		return new Promise((resolve) => {
			lienzo.toBlob((file) => {
				if (file) {
					resolve(URL.createObjectURL(file));
				}
			}, 'image/jpeg');
		});
	};

	/**
	 * Voltea la imagen verticalmente.
	 * - ***urlImagen*** - URL de la imagen original.
	 */
	const obtenerImagenVolteadaVerticalmente = async (urlImagen: string): Promise<string> => {
		const imagenOriginal = await crearImagen(urlImagen);
		const lienzo = document.createElement('canvas');
		const contexto = lienzo.getContext('2d');

		const tamanoMaximo = Math.max(imagenOriginal.width, imagenOriginal.height);
		const areaSegura = 2 * ((tamanoMaximo / 2) * Math.sqrt(2));
		lienzo.width = areaSegura;
		lienzo.height = areaSegura;

		if (contexto) {
			contexto.translate(areaSegura / 2, areaSegura / 2);
			contexto.rotate(obtenerAnguloRadianes(180));
			contexto.translate(-areaSegura / 2, -areaSegura / 2);
			contexto.drawImage(imagenOriginal, areaSegura / 2 - imagenOriginal.width * 0.5, areaSegura / 2 - imagenOriginal.height * 0.5);
			const datosImagen = contexto.getImageData(0, 0, areaSegura, areaSegura);

			lienzo.width = imagenOriginal.width;
			lienzo.height = imagenOriginal.height;

			contexto.putImageData(
				datosImagen,
				Math.round(0 - areaSegura / 2 + imagenOriginal.width * 0.5 - imagenOriginal.x),
				Math.round(0 - areaSegura / 2 + imagenOriginal.height * 0.5 - imagenOriginal.y)
			);
		}

		return new Promise((resolve) => {
			lienzo.toBlob((file) => {
				if (file) {
					resolve(URL.createObjectURL(file));
				}
			}, 'image/jpeg');
		});
	};

	return (
		<Fragment>
			<div className="mc-selector-imagen__imagen-seleccionada">
				{imagenTemporal && (
					<Cropper
						aspect={relacionAspecto}
						crop={corte}
						image={imagenTemporal}
						maxZoom={limiteZoom}
						minZoom={minimoZoom}
						onCropChange={setCorte}
						onCropComplete={eventoCorteCompleto}
						onRotationChange={setRotacion}
						onZoomChange={setZoom}
						restrictPosition={false}
						rotation={rotacion}
						zoom={zoom}
					/>
				)}
			</div>
			{!imagenTemporal && (
				<div className="mc-selector-imagen__imagen-predeterminada">
					<div className="mc-selector-imagen__imagen-predeterminada-icono">
						<i className={iconoImagenPredeterminada}></i>
					</div>
					<div className="mc-selector-imagen__imagen-predeterminada-mensaje">{texto.sinImagen}</div>
				</div>
			)}
			<div className="mc-selector-imagen__barra-herramientas">
				<Row>
					<Col lg="6">
						<div className="mc-selector-imagen__barra-herramientas-etiqueta">
							<i className={iconoZoom}></i> {texto.zoom}
						</div>
						<Slider max={limiteZoom} min={minimoZoom} onChange={setZoom} step={0.01} tooltip={false} value={zoom} />
					</Col>
					<Col lg="6">
						<div className="mc-selector-imagen__barra-herramientas-etiqueta">
							<i className={iconoRotacion}></i> {texto.rotacion}
						</div>
						<Slider max={360} min={0} onChange={setRotacion} step={1} tooltip={false} value={rotacion} />
					</Col>
				</Row>
				<Row>
					<Col lg="6">
						<input className="form-control" id="formFile" onChange={eventoSeleccionarArchivo} type="file" />
					</Col>
					<Col lg="6">
						<div className="mc-selector-imagen__barra-herramientas-botones">
							<button className="btn btn-info" id="botonVoltearImagenHorizontalmente" onClick={eventoVoltearImagenHorizontalmente} type="button">
								<i className={iconoVoltearHorizontalmente}></i>
							</button>
							<button className="btn btn-info" id="botonVoltearImagenVertical" onClick={eventoVoltearImagenVerticalmente} style={{ width: 40.41 }} type="button">
								<i className={iconoVoltearVerticalmente}></i>
							</button>
							<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
								<i className={iconoCancelar}></i> <span className="mc-selector-imagen__barra-herramientas-botones-texto">{texto.cancelar}</span>
							</button>
							<button className="btn btn-success" id="botonGuardarImagen" onClick={eventoGuardarImagen} type="button">
								<i className={iconoGuardar}></i> <span className="mc-selector-imagen__barra-herramientas-botones-texto">{texto.guardar}</span>
							</button>
						</div>
					</Col>
				</Row>
			</div>
		</Fragment>
	);
};

export default McSelectorImagen;
