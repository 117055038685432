import './PeritoTarjeta.scss';
import { Col, Row } from 'reactstrap';
import { setVisorImagenImagenesAction, setVisorImagenMostrarAction } from 'store/actions';
import constantes from 'configuracion/constantes';
import DomicilioTarjeta from 'componentes/domicilio/DomicilioTarjeta';
import { Fragment } from 'react';
import { McVisorImagenElemento } from '@mcsoft/visor-imagen';
import { nombreCompletoPerito } from 'util/modelo/peritos';
import Perito from 'modelo/Perito';
import { texto } from 'idiomas';
import { useDispatch } from 'react-redux';
import useFecha from 'hooks/useFecha';
import { useObtenerPeritoLicencia } from 'hooks/useImagen';

interface PeritoTarjetaProps {
	/**
	 * Objeto con la información del registro a mostrar.
	 */
	registro: Perito;
}

/**
 * Muestra una tarjeta con la información de un perito.
 */
const PeritoTarjeta = (props: PeritoTarjetaProps) => {
	const fecha = useFecha();
	const dispatch = useDispatch();
	const obtenerFotoPeritoLicencia = useObtenerPeritoLicencia();
	const { registro } = props;
	const { correoElectronico, domicilio, licenciaNumeroRegistro, licenciaVigencia, telefono, tratamientoProtocolario } = registro;

	/**
	 * Sete la imagen de la licencia del perito en el estado de Redux para ser vista en el visor de imágenes.
	 */
	const eventoVerImagen = () => {
		const imagen: McVisorImagenElemento = {
			descripcion: texto('Foto de la licencia'),
			id: `${registro.id}_licencia`,
			titulo: nombreCompletoPerito(registro),
			url: obtenerFotoPeritoLicencia({ perito: registro })
		};
		dispatch(setVisorImagenImagenesAction([imagen]));
		dispatch(setVisorImagenMostrarAction(true));
	};

	if (registro) {
		return (
			<Fragment>
				<Row>
					<Col lg="4" md="3" sm="12" xl="3">
						<div className="perito-tarjeta__licencia-contenedor">
							<img
								alt="Foto de perfil"
								className="mc-soft__imagen--cuadrado perito-tarjeta__licencia"
								onClick={eventoVerImagen}
								src={obtenerFotoPeritoLicencia({ ancho: '700', perito: registro })}
							/>
						</div>
					</Col>
					<Col lg="8" md="9" sm="12" xl="9">
						<div className="perito-tarjeta__informacion">
							<p>
								{tratamientoProtocolario ? tratamientoProtocolario : ''} {nombreCompletoPerito(registro)}
							</p>
							{correoElectronico && (
								<p>
									<i className={constantes.icono.correoElectronico}></i> {correoElectronico}
								</p>
							)}
							<p>
								<i className={constantes.icono.telefono}></i> {telefono}
							</p>
							<hr />
							<h4>
								<i className={constantes.icono.licencia}></i> {texto('Licencia')}
							</h4>
							<p>
								<b>{texto('No. Registro')}:</b> {licenciaNumeroRegistro}
							</p>
							<p>
								<b>{texto('Vigencia')}:</b> {fecha(licenciaVigencia)}
							</p>
						</div>
						<div className="perito-tarjeta__domicilio">
							<hr />
							<DomicilioTarjeta domicilio={domicilio} />
						</div>
					</Col>
				</Row>
			</Fragment>
		);
	}
	return null;
};

export default PeritoTarjeta;
