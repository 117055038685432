import {
	GET_CONFIGURACION_SISTEMA,
	SET_CONFIGURACION_SISTEMA
} from './actionTypes';
import ConfiguracionSistema from 'modelo/ConfiguracionSistema';
import { ReduxAccion } from 'store/actions';

/**
 * Obtiene la configuración del sistema de la base de datos y la asigna en el store de *Redux*.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const getConfiguracionSistemaAction = (auth0AccessToken: string): ReduxAccion => ({
	payload: {
		auth0AccessToken
	},
	type: GET_CONFIGURACION_SISTEMA
});

/**
 * Asigna la configuración del sistema en el store de *Redux*.
 * - ***configuracionSistema*** - Token de Auth0.
 */
export const setConfiguracionSistemaAction = (configuracionSistema: ConfiguracionSistema): ReduxAccion => ({
	payload: {
		configuracionSistema
	},
	type: SET_CONFIGURACION_SISTEMA
});