import { Redirect, Route } from 'react-router-dom';
import constantes from 'configuracion/constantes';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import Usuario from 'modelo/Usuario';

interface RutaConAlMenosUnPermisoProps {
	/**
	 * Componente a proteger.
	 */
	component: any;
	/**
	 * Ruta en la cual se mostrará el componente.
	 */
	path: string;
	/**
	 * Lista de permisos necesario para ver el componente.
	 */
	permisos: Array<string>;
	/**
	 * Usuario a validar si tiene permiso.
	 */
	usuario: Usuario;
}

/**
 * Si el usuario no está autenticado lo redirecciona a la página de web. Si el usuario está autenticado y no tiene el permiso requerido lo redirecciona a la página de inicio. Si el usuario está autenticado y tiene al menos un permiso requerido lo redirecciona a la ruta especificada.
 */
export const RutaConAlMenosUnPermiso = (props: RutaConAlMenosUnPermisoProps): JSX.Element => {
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
	const { component: Component, path, permisos, usuario, ...rest } = props;

	useEffect(() => {
		if (isLoading || isAuthenticated) {
			return;
		}
		loginWithRedirect();
	}, [isLoading, isAuthenticated, loginWithRedirect, path]);

	return (
		<Route
			{...rest}
			exact
			render={(props): any => {
				if (isAuthenticated === true) {
					if (tieneAlMenosUnPermiso({ permisos, usuario })) {
						return <Component {...props} />;
					} else {
						return <Redirect to={{ pathname: constantes.ruta.appInicio }} />;
					}
				} else {
					return <Redirect to={{ pathname: constantes.ruta.webInicio }} />;
				}
			}}
		/>
	);
};

interface RutaConAutenticacionProps {
	/**
	 * Componente a proteger.
	 */
	component: any;
	/**
	 * Ruta en la cual se mostrará el componente.
	 */
	path: string;
}

/**
 * Si el usuario no está autenticado lo redirecciona a la página de web. Si el usuario está autenticado y no tiene el permiso requerido lo redirecciona a la página de inicio. Si el usuario está autenticado y tiene el permiso requerido lo redirecciona a la ruta especificada.
 */
export const RutaConAutenticacion = (props: RutaConAutenticacionProps): JSX.Element => {
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
	const { component: Component, path, ...rest } = props;

	useEffect(() => {
		if (isLoading || isAuthenticated) {
			return;
		}
		loginWithRedirect();
	}, [isLoading, isAuthenticated, loginWithRedirect, path]);

	return (
		<Route
			{...rest}
			exact
			render={(props): any => {
				if (isAuthenticated === true) {
					return <Component {...props} />;
				} else {
					return <Redirect to={{ pathname: constantes.ruta.webInicio }} />;
				}
			}}
		/>
	);
};

interface RutaConPermisoProps {
	/**
	 * Componente a proteger.
	 */
	component: any;
	/**
	 * Permiso necesario para ver el componente.
	 */
	permiso: string;
	/**
	 * Ruta en la cual se mostrará el componente.
	 */
	path: string;
	/**
	 * Usuario a validar si tiene permiso.
	 */
	usuario: Usuario;
}

/**
 * Si el usuario no está autenticado lo redirecciona a la página de web. Si el usuario está autenticado y no tiene el permiso requerido lo redirecciona a la página de inicio. Si el usuario está autenticado y tiene el permiso requerido lo redirecciona a la ruta especificada.
 */
export const RutaConPermiso = (props: RutaConPermisoProps): JSX.Element => {
	const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
	const { component: Component, path, permiso, usuario, ...rest } = props;

	useEffect(() => {
		if (isLoading || isAuthenticated) {
			return;
		}
		loginWithRedirect();
	}, [isLoading, isAuthenticated, loginWithRedirect, path]);

	return (
		<Route
			{...rest}
			exact
			render={(props): any => {
				if (isAuthenticated === true) {
					if (tienePermiso({ permiso, usuario })) {
						return <Component {...props} />;
					} else {
						return <Redirect to={{ pathname: constantes.ruta.appInicio }} />;
					}
				} else {
					return <Redirect to={{ pathname: constantes.ruta.webInicio }} />;
				}
			}}
		/>
	);
};

interface RutaPublicaProps {
	/**
	 * Componente.
	 */
	component: any;
	/**
	 * Ruta en la cual se mostrará el componente.
	 */
	path: string;
}

/**
 * Redirecciona a la ruta especificada.
 */
export const RutaPublica = (props: RutaPublicaProps): JSX.Element => {
	const { component: Component, ...rest } = props;

	return <Route {...rest} exact render={(props): any => <Component {...props} />} />;
};

/**
 * Verifica si el usuario es Administrador del Sistema.
 * - ***usuario*** - Usuario a validar.
 */
export const esSysAdmin = (usuario: Usuario): boolean => {
	if (usuario.nombreUsuario && usuario.nombreUsuario.toLowerCase() === 'sysadmin') {
		return true;
	} else {
		return false;
	}
};

/**
 * Verifica si el usuario tiene al menos un permiso de la lista especificada.
 * - ***permisos*** - Lista de permisos a validar.
 * - ***usuario*** - Usuario a validar.
 */
export const tieneAlMenosUnPermiso = ({ permisos, usuario }: { permisos: Array<string>; usuario: Usuario }): boolean => {
	if (permisos) {
		if (usuario.nombreUsuario && usuario.nombreUsuario.toLowerCase() === 'sysadmin') {
			return true;
		} else {
			for (let i = 0; i < permisos.length; i++) {
				if (usuario.rol && usuario.rol.permisos && usuario.rol.permisos.includes(permisos[i])) {
					return true;
				}
			}
		}
	}
	return false;
};

/**
 * Verifica si el usuario tiene el permiso especificado.
 * - ***permiso*** - Permiso a validar.
 * - ***usuario*** - Usuario a validar.
 */
export const tienePermiso = ({ permiso, usuario }: { permiso: string; usuario: Usuario }): boolean => {
	if (permiso) {
		if (usuario.nombreUsuario && usuario.nombreUsuario.toLowerCase() === 'sysadmin') {
			return true;
		} else if (usuario.rol && usuario.rol.permisos && usuario.rol.permisos.includes(permiso)) {
			return true;
		} else {
			return false;
		}
	}
	return false;
};
