import './BarraHerramientasAlerta.scss';
import { ReactNode } from 'react';

interface BarraHerramientasAlertaProps {
	/**
	 * Nodos HTML hijos del componente.
	 */
	children: ReactNode;
	/**
	 * Clase de la alerta.
	 *
	 * **Valores Admitidos**
	 * - **'primary':** Fondo azul y texto azul oscuro.
	 * - **'secondary':** Fondo gris y texto gris oscuro.
	 * - **'success':** Fondo verde y texto verde oscuro.
	 * - **'danger':** Fondo rojo y texto rojo oscuro.
	 * - **'warning':** Fondo amarillo y texto amarillo oscuro.
	 * - **'info':** Fondo aqua y texto aqua oscuro.
	 * - **'light':** Fondo blanco y texto gris oscuro.
	 * - **'dark':** Fondo gris oscuro y texto negro.
	 *
	 * > ***Predeterminado:*** *'warning'*
	 */
	clase?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';
	/**
	 * Indica si la alerta debe mostrarse.
	 */
	mostrar: boolean;
}

/**
 * Componente que muestra una alerta en la barra de herramientas de la página.
 */
const BarraHerramientasAlerta = (props: BarraHerramientasAlertaProps) => {
	const { children, clase = 'warning', mostrar } = props;

	const CLASES = {
		danger: 'alert alert-danger',
		dark: 'alert alert-dark',
		info: 'alert alert-info',
		light: 'alert alert-light',
		primary: 'alert alert-primary',
		secondary: 'alert alert-secondary',
		success: 'alert alert-success',
		warning: 'alert alert-warning'
	};

	const claseOriginal = CLASES[clase];

	return <>{mostrar && <div className={`barra-herramientas-alerta ${claseOriginal}`}>{children}</div>}</>;
};

export default BarraHerramientasAlerta;
