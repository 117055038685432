import './AvaluoFormulario.scss';
import { Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import Avaluo from 'modelo/Avaluo';
import AvaluoFormularioAntecedentes from './AvaluoFormularioAntecedentes';
import AvaluoFormularioCaracteristicas from './AvaluoFormularioCaracteristicas';
import AvaluoFormularioConstruccion from './AvaluoFormularioConstruccion';
import AvaluoFormularioDistribucion from 'componentes/avaluos/AvaluoFormularioDistribucion';
import AvaluoFormularioElementosConstruccion from './AvaluoFormularioElementosConstruccion';
import AvaluoFormularioEspeciales from './AvaluoFormularioEspeciales';
import AvaluoFormularioFotos from './AvaluoFormularioFotos';
import AvaluoFormularioGeneral from './AvaluoFormularioGeneral';
import AvaluoFormularioObservaciones from './AvaluoFormularioObservaciones';
import AvaluoFormularioTerreno from './AvaluoFormularioTerreno';
import AvaluoFormularioUbicacion from './AvaluoFormularioUbicacion';
import { hayCambiosEnElAvaluo } from 'util/modelo/avaluos';
import ListaOpcion from 'modelo/ListaOpcion';
import { obtenerPeritosTodosPorUsuario } from 'servicios/api/peritos';
import { obtenerTodasLasListas } from 'util/modelo/listasOpciones';
import Perito from 'modelo/Perito';
import { pestanaConErrores } from '@mcsoft/formulario';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useEsSeccionPreferencias } from 'hooks/useSeccion';
import { useFormState } from 'react-final-form';

const PestanaTipo = {
	ANTECEDENTES: 'antecedentes',
	CARACTERISTICAS: 'caracteristicas',
	CONSTRUCCION: 'construccion',
	DISTRIBUCION: 'distribucion',
	ELEMENTOS_CONSTRUCCION: 'elementosConstruccion',
	ESPECIALES: 'especiales',
	FOTOS: 'fotos',
	GENERAL: 'general',
	OBSERVACIONES: 'observaciones',
	TERRENO: 'terreno',
	UBICACION: 'ubicacion'
};

interface AvaluoFormularioProps {
	eventoDeshacer: () => void;
	// eslint-disable-next-line no-unused-vars
	eventoGuardar: (valores: Avaluo) => void;
	eventoSalir: () => void;
	formularioOriginal: any;
}

/**
 * Formulario para el avalúo.
 */
const AvaluoFormulario = (props: AvaluoFormularioProps) => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const esSeccionPreferencias = useEsSeccionPreferencias();
	const formularioValores = useFormState();
	const { construcciones, fotos, instalacionesEspeciales, terreno } = formularioValores.values;
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { eventoDeshacer, eventoGuardar, eventoSalir, formularioOriginal } = props;
	const [pestanaActiva, setPestanaActiva] = useState(PestanaTipo.ANTECEDENTES);
	const [hayCambiosSinGuardar, setHayCambiosSinGuardar] = useState(false);
	const [erroresPestanaAntecedentes, setErroresPestanaAntecedentes] = useState(false);
	const [erroresPestanaCaracteristicas, setErroresPestanaCaracteristicas] = useState(false);
	const [erroresPestanaConstruccion, setErroresPestanaConstruccion] = useState(false);
	const [erroresPestanaDistribucion, setErroresPestanaDistribucion] = useState(false);
	const [erroresPestanaElementosConstruccion, setErroresPestanaElementosConstruccion] = useState(false);
	const [erroresPestanaEspeciales, setErroresPestanaEspeciales] = useState(false);
	const [erroresPestanaFotos, setErroresPestanaFotos] = useState(false);
	const [erroresPestanaGeneral, setErroresPestanaGeneral] = useState(false);
	const [erroresPestanaObservaciones, setErroresPestanaObservaciones] = useState(false);
	const [erroresPestanaTerreno, setErroresPestanaTerreno] = useState(false);
	const [erroresPestanaUbicacion, setErroresPestanaUbicacion] = useState(false);
	const [listaCalidadConstruccion, setListaCalidadConstruccion] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaClasificacionZona, setListaClasificacionZona] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaConstruccionDominante, setListaConstruccionDominante] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaDestinoEspecifico, setListaDestinoEspecifico] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaDistribucionInmueble, setListaDistribucionInmueble] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionAplanado, setListaElementosConstruccionAplanado] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionAzotea, setListaElementosConstruccionAzotea] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionBarda, setListaElementosConstruccionBarda] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionCarpinteria, setListaElementosConstruccionCarpinteria] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionCerrajeria, setListaElementosConstruccionCerrajeria] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionCimientos, setListaElementosConstruccionCimientos] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionEntrepiso, setListaElementosConstruccionEntrepiso] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionEscalera, setListaElementosConstruccionEscalera] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionEstructura, setListaElementosConstruccionEstructura] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionFachada, setListaElementosConstruccionFachada] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionHerreria, setListaElementosConstruccionHerreria] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionInstalacionElectrica, setListaElementosConstruccionInstalacionElectrica] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionInstalacionEspecial, setListaElementosConstruccionInstalacionEspecial] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionInstalacionSanitaria, setListaElementosConstruccionInstalacionSanitaria] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionLambrin, setListaElementosConstruccionLambrin] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionMuebleBano, setListaElementosConstruccionMuebleBano] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionMuebleCocina, setListaElementosConstruccionMuebleCocina] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionMuro, setListaElementosConstruccionMuro] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionPavimentoPetreo, setListaElementosConstruccionPavimentoPetreo] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionPintura, setListaElementosConstruccionPintura] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionPlafon, setListaElementosConstruccionPlafon] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionRecubrimientoEspecial, setListaElementosConstruccionRecubrimientoEspecial] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionTecho, setListaElementosConstruccionTecho] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionVidrieria, setListaElementosConstruccionVidrieria] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaElementosConstruccionZoclo, setListaElementosConstruccionZoclo] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaEstadoConservacion, setListaEstadoConservacion] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaMotivo, setListaMotivo] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaPeritos, setListaPeritos] = useState<Array<Perito>>([]);
	const [listaRegimenPropiedad, setListaRegimenPropiedad] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaServicioMunicipal, setListaServicioMunicipal] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaTipoCalle, setListaTipoCalle] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaTipoConstruccion, setListaTipoConstruccion] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaTipoInmueble, setListaTipoInmueble] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaUnidadSuperficie, setListaUnidadSuperficie] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaUsoEspecifico, setListaUsoEspecifico] = useState<Array<ListaOpcion> | undefined>([]);
	const [listaVientoCardinal, setListaVientoCardinal] = useState<Array<ListaOpcion> | undefined>([]);

	useEffect(() => {
		obtenerListas();
	}, []);

	const obtenerListas = async () => {
		dispatch(setPantallaCargaMostrarAction(true));
		const respuestaPeritos = await obtenerPeritosTodosPorUsuario({ auth0AccessToken, usuarioId: usuario.id });
		const peritos = respuestaPeritos.datos;
		const listas = await obtenerTodasLasListas(auth0AccessToken);
		setListaCalidadConstruccion(listas.calidadConstruccion);
		setListaClasificacionZona(listas.clasificacionZona);
		setListaConstruccionDominante(listas.construccionDominante);
		setListaDestinoEspecifico(listas.destinoEspecifico);
		setListaDistribucionInmueble(listas.distribucionInmueble);
		setListaElementosConstruccionAplanado(listas.aplanado);
		setListaElementosConstruccionAzotea(listas.azotea);
		setListaElementosConstruccionBarda(listas.barda);
		setListaElementosConstruccionCarpinteria(listas.carpinteria);
		setListaElementosConstruccionCerrajeria(listas.cerrajeria);
		setListaElementosConstruccionCimientos(listas.cimientos);
		setListaElementosConstruccionEntrepiso(listas.entrepiso);
		setListaElementosConstruccionEscalera(listas.escalera);
		setListaElementosConstruccionEstructura(listas.estructura);
		setListaElementosConstruccionFachada(listas.fachada);
		setListaElementosConstruccionHerreria(listas.herreria);
		setListaElementosConstruccionInstalacionElectrica(listas.instalacionElectrica);
		setListaElementosConstruccionInstalacionEspecial(listas.instalacionEspecial);
		setListaElementosConstruccionInstalacionSanitaria(listas.instalacionSanitaria);
		setListaElementosConstruccionLambrin(listas.lambrin);
		setListaElementosConstruccionMuebleBano(listas.muebleBano);
		setListaElementosConstruccionMuebleCocina(listas.muebleCocina);
		setListaElementosConstruccionMuro(listas.muro);
		setListaElementosConstruccionPavimentoPetreo(listas.pavimentoPetreo);
		setListaElementosConstruccionPintura(listas.pintura);
		setListaElementosConstruccionPlafon(listas.plafon);
		setListaElementosConstruccionRecubrimientoEspecial(listas.recubrimientoEspecial);
		setListaElementosConstruccionTecho(listas.techo);
		setListaElementosConstruccionVidrieria(listas.vidrieria);
		setListaElementosConstruccionZoclo(listas.zoclo);
		setListaEstadoConservacion(listas.estadoConservacion);
		setListaMotivo(listas.motivoAjuste);
		setListaPeritos(peritos);
		setListaRegimenPropiedad(listas.regimenPropiedad);
		setListaServicioMunicipal(listas.servicioMunicipal);
		setListaTipoCalle(listas.tipoCalle);
		setListaTipoConstruccion(listas.tipoConstruccion);
		setListaTipoInmueble(listas.tipoInmueble);
		setListaUnidadSuperficie(listas.unidadSuperficie);
		setListaUsoEspecifico(listas.usoEspecifico);
		setListaVientoCardinal(listas.vientoCardinal);
		dispatch(setPantallaCargaMostrarAction(false));
	};

	useEffect(() => {
		const pestana = new URLSearchParams(location.search).get('pestana');
		setPestanaActiva(pestana ? pestana : PestanaTipo.ANTECEDENTES);
	}, [location]);

	useEffect(() => {
		const avaluo1 = formularioOriginal as Avaluo;
		const avaluo2 = formularioValores.values as Avaluo;
		if (hayCambiosEnElAvaluo(avaluo1, avaluo2)) {
			setHayCambiosSinGuardar(true);
		} else {
			setHayCambiosSinGuardar(false);
		}
	}, [formularioOriginal, formularioValores]);

	useEffect(() => {
		setErroresPestanaAntecedentes(
			pestanaConErrores({
				camposPestana: [
					'claveCatastral',
					'cuentaPredial',
					'fechaAvaluo',
					'nombrePropietario',
					'nombreSolicitante',
					'numeroRecaudadora',
					'peritoId',
					'peritoSeleccion',
					'regimenPropiedadId',
					'tipoInmuebleId',
					'ubicacionPredio'
				],
				errores: formularioValores.errors?.antecedentes
			}) ||
				pestanaConErrores({
					camposPestana: [
						'calle',
						'calleTipoId',
						'codigoPostal',
						'colonia',
						'condominio',
						'estadoId',
						'estadoSeleccion',
						'fraccionamiento',
						'lote',
						'manzana',
						'municipioId',
						'municipioSeleccion',
						'numeroExterior',
						'numeroInterior',
						'paisId',
						'paisSeleccion',
						'unidadPrivativa'
					],
					errores: formularioValores.errors?.domicilio
				})
		);
		setErroresPestanaCaracteristicas(
			pestanaConErrores({
				camposPestana: ['clasificacionZonaId', 'coeficienteOcupacion', 'coeficienteUtilizacion', 'densidadConstruccion'],
				errores: formularioValores.errors?.caracteristicas
			}) ||
				pestanaConErrores({
					camposPestana: ['descripcion'],
					errores: formularioValores.errors?.construccionesDominantes
				}) ||
				pestanaConErrores({
					camposPestana: ['descripcion'],
					errores: formularioValores.errors?.serviciosMunicipales
				})
		);
		setErroresPestanaConstruccion(!esSeccionPreferencias() && !existeAlMenosUnDetalle());
		setErroresPestanaDistribucion(
			pestanaConErrores({
				camposPestana: ['descripcion'],
				errores: formularioValores.errors?.distribucion
			})
		);
		setErroresPestanaElementosConstruccion(
			pestanaConErrores({
				camposPestana: [
					'aplanado',
					'azotea',
					'barda',
					'carpinteria',
					'cerrajeria',
					'cimientos',
					'entrepiso',
					'escalera',
					'estructura',
					'fachada',
					'herreria',
					'instalacionElectrica',
					'instalacionEspecial',
					'instalacionSanitaria',
					'lambrin',
					'muebleBano',
					'muebleCocina',
					'muro',
					'pavimentoPetreo',
					'pintura',
					'plafon',
					'recubrimientoEspecial',
					'techo',
					'vidrieria',
					'zoclo'
				],
				errores: formularioValores.errors?.elementosConstruccion
			})
		);
		setErroresPestanaEspeciales(!esSeccionPreferencias() && !existeAlMenosUnDetalle());
		setErroresPestanaFotos(!esSeccionPreferencias() && !existeAlMenosUnaFoto());
		setErroresPestanaGeneral(
			pestanaConErrores({
				camposPestana: ['descripcion', 'estadoId', 'fecha', 'municipioId', 'notarioNombre', 'numero', 'numeroNotaria'],
				errores: formularioValores.errors?.escritura
			}) ||
				pestanaConErrores({
					camposPestana: [
						'calidadProyecto',
						'colindancia1Descripcion',
						'colindancia1VientoId',
						'colindancia2Descripcion',
						'colindancia2VientoId',
						'colindancia3Descripcion',
						'colindancia3VientoId',
						'colindancia4Descripcion',
						'colindancia4VientoId',
						'colindancia5Descripcion',
						'colindancia5VientoId',
						'colindancia6Descripcion',
						'colindancia6VientoId',
						'colindancia7Descripcion',
						'colindancia7VientoId',
						'colindancia8Descripcion',
						'colindancia8VientoId',
						'colindanciaSeparador',
						'destinoEspecificoId',
						'edad',
						'indiviso',
						'superficieCatastro',
						'superficieEscritura',
						'superficieReal',
						'superficieUnidadId',
						'unidadesRentables',
						'usoEspecificoId',
						'vidaRemanenteProbale'
					],
					errores: formularioValores.errors?.medidas
				})
		);
		setErroresPestanaObservaciones(
			pestanaConErrores({
				camposPestana: [],
				errores: formularioValores.errors?.observaciones
			})
		);
		setErroresPestanaTerreno(!esSeccionPreferencias() && !existeAlMenosUnDetalle());
		setErroresPestanaUbicacion(
			pestanaConErrores({
				camposPestana: [
					'contornoCoordenadasDdArreglo',
					'coordenadasDdLatitud',
					'coordenadasDdLongitud',
					'coordenadasDmsLatitudCardinal',
					'coordenadasDmsLatitudGrados',
					'coordenadasDmsLatitudMinutos',
					'coordenadasDmsLatitudSegundos',
					'coordenadasDmsLongitudCardinal',
					'coordenadasDmsLongitudGrados',
					'coordenadasDmsLongitudMinutos',
					'coordenadasDmsLongitudSegundos',
					'coordenadasUtmEsteX',
					'coordenadasUtmHuso',
					'coordenadasUtmNorteY',
					'coordenadasUtmZona',
					'mapaImagenGoogleMapsApi',
					'distanciaEsquina',
					'distanciaEsquinaCalle',
					'distanciaEsquinaCoordenadasDdArreglo',
					'mapaCentro',
					'mapaZoom'
				],
				errores: formularioValores.errors?.ubicacion
			})
		);
	}, [formularioValores.errors]);

	/**
	 * Guarda la información del registro.
	 */
	const eventoGuardarFormulario = (avaluo: Avaluo) => {
		eventoGuardar(avaluo);
		setHayCambiosSinGuardar(false);
	};

	/**
	 * Indica si el avalúo cuenta con al menos una foto.
	 */
	const existeAlMenosUnaFoto = (): boolean => {
		if (fotos && fotos.length > 0) {
			return true;
		}
		return false;
	};

	/**
	 * Indica si el avalúo cuenta con al menos un detalle de construcciones, instalaciones especiales o terreno.
	 */
	const existeAlMenosUnDetalle = (): boolean => {
		if (construcciones.detalles.length > 0 || instalacionesEspeciales.detalles.length > 0 || terreno.detalles.length > 0) {
			return true;
		}
		return false;
	};

	return (
		<Row>
			<Col lg="3" md="3" sm="3" xl="2" xs="12">
				<div className="flex-column nav nav-pills">
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.ANTECEDENTES ? ' active' : ''} ${erroresPestanaAntecedentes ? 'link-warning' : ''}`}
						id="pestanaAntecedentes"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.ANTECEDENTES}`)}
						type="button"
					>
						{texto('Antecedentes')}
					</button>
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.CARACTERISTICAS ? ' active' : ''} ${erroresPestanaCaracteristicas ? 'link-warning' : ''}`}
						id="pestanaCaracteristicas"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.CARACTERISTICAS}`)}
						type="button"
					>
						{texto('Características')}
					</button>
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.GENERAL ? ' active' : ''} ${erroresPestanaGeneral ? 'link-warning' : ''}`}
						id="pestanaGeneral"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.GENERAL}`)}
						type="button"
					>
						{texto('General')}
					</button>
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.DISTRIBUCION ? ' active' : ''} ${erroresPestanaDistribucion ? 'link-warning' : ''}`}
						id="pestanaDistribucion"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.DISTRIBUCION}`)}
						type="button"
					>
						{texto('Distribución')}
					</button>
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.ELEMENTOS_CONSTRUCCION ? ' active' : ''} ${erroresPestanaElementosConstruccion ? 'link-warning' : ''}`}
						id="pestanaElementosConstruccion"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.ELEMENTOS_CONSTRUCCION}`)}
						type="button"
					>
						{texto('Elementos de Construcción')}
					</button>
					{!esSeccionPreferencias() && (
						<button
							className={`nav-link ${pestanaActiva === PestanaTipo.UBICACION ? ' active' : ''} ${erroresPestanaUbicacion ? 'link-warning' : ''}`}
							id="pestanaUbicacion"
							onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.UBICACION}`)}
							type="button"
						>
							{texto('Ubicación')}
						</button>
					)}
					{!esSeccionPreferencias() && (
						<button
							className={`nav-link ${pestanaActiva === PestanaTipo.FOTOS ? ' active' : ''} ${erroresPestanaFotos ? 'link-warning' : ''}`}
							id="pestanaFotos"
							onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.FOTOS}`)}
							type="button"
						>
							{texto('Reporte Fotográfico')}
						</button>
					)}
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.TERRENO ? ' active' : ''} ${erroresPestanaTerreno ? 'link-warning' : ''}`}
						id="pestanaTerreno"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.TERRENO}`)}
						type="button"
					>
						{texto('Avalúo del Terreno')}
					</button>
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.CONSTRUCCION ? ' active' : ''} ${erroresPestanaConstruccion ? 'link-warning' : ''}`}
						id="pestanaConstruccion"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.CONSTRUCCION}`)}
						type="button"
					>
						{texto('Bloques de Construcción')}
					</button>
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.ESPECIALES ? ' active' : ''} ${erroresPestanaEspeciales ? 'link-warning' : ''}`}
						id="pestanaEspeciales"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.ESPECIALES}`)}
						type="button"
					>
						{texto('Instalaciones Especiales')}
					</button>
					<button
						className={`nav-link ${pestanaActiva === PestanaTipo.OBSERVACIONES ? ' active' : ''} ${erroresPestanaObservaciones ? 'link-warning' : ''}`}
						id="pestanaObservaciones"
						onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.OBSERVACIONES}`)}
						type="button"
					>
						{texto('Observaciones')}
					</button>
				</div>
			</Col>
			<Col lg="9" md="9" sm="9" xl="10" xs="12">
				<div className="tab-content">
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.ANTECEDENTES ? ' show active' : ''}`} id="contenidoAntecedentes">
						<AvaluoFormularioAntecedentes
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardarFormulario}
							eventoSalir={eventoSalir}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
							listaPeritos={listaPeritos}
							listaRegimenPropiedad={listaRegimenPropiedad}
							listaTipoCalle={listaTipoCalle}
							listaTipoInmueble={listaTipoInmueble}
						/>
					</div>
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.CARACTERISTICAS ? ' show active' : ''}`} id="contenidoCaracteristicas">
						<AvaluoFormularioCaracteristicas
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardarFormulario}
							eventoSalir={eventoSalir}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
							listaClasificacionZona={listaClasificacionZona}
							listaConstruccionDominante={listaConstruccionDominante}
							listaServicioMunicipal={listaServicioMunicipal}
						/>
					</div>
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.GENERAL ? ' show active' : ''}`} id="contenidoGeneral">
						<AvaluoFormularioGeneral
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardarFormulario}
							eventoSalir={eventoSalir}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
							listaDestinoEspecifico={listaDestinoEspecifico}
							listaUnidadSuperficie={listaUnidadSuperficie}
							listaUsoEspecifico={listaUsoEspecifico}
							listaVientoCardinal={listaVientoCardinal}
						/>
					</div>
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.DISTRIBUCION ? ' show active' : ''}`} id="contenidoDistribucion">
						<AvaluoFormularioDistribucion
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardarFormulario}
							eventoSalir={eventoSalir}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
							listaDistribucionInmueble={listaDistribucionInmueble}
						/>
					</div>
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.ELEMENTOS_CONSTRUCCION ? ' show active' : ''}`} id="contenidoElementosConstruccion">
						<AvaluoFormularioElementosConstruccion
							auth0AccessToken={auth0AccessToken}
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardarFormulario}
							eventoRefrescarListas={obtenerListas}
							eventoSalir={eventoSalir}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
							listaElementosConstruccionAplanado={listaElementosConstruccionAplanado}
							listaElementosConstruccionAzotea={listaElementosConstruccionAzotea}
							listaElementosConstruccionBarda={listaElementosConstruccionBarda}
							listaElementosConstruccionCarpinteria={listaElementosConstruccionCarpinteria}
							listaElementosConstruccionCerrajeria={listaElementosConstruccionCerrajeria}
							listaElementosConstruccionCimientos={listaElementosConstruccionCimientos}
							listaElementosConstruccionEntrepiso={listaElementosConstruccionEntrepiso}
							listaElementosConstruccionEscalera={listaElementosConstruccionEscalera}
							listaElementosConstruccionEstructura={listaElementosConstruccionEstructura}
							listaElementosConstruccionFachada={listaElementosConstruccionFachada}
							listaElementosConstruccionHerreria={listaElementosConstruccionHerreria}
							listaElementosConstruccionInstalacionElectrica={listaElementosConstruccionInstalacionElectrica}
							listaElementosConstruccionInstalacionEspecial={listaElementosConstruccionInstalacionEspecial}
							listaElementosConstruccionInstalacionSanitaria={listaElementosConstruccionInstalacionSanitaria}
							listaElementosConstruccionLambrin={listaElementosConstruccionLambrin}
							listaElementosConstruccionMuebleBano={listaElementosConstruccionMuebleBano}
							listaElementosConstruccionMuebleCocina={listaElementosConstruccionMuebleCocina}
							listaElementosConstruccionMuro={listaElementosConstruccionMuro}
							listaElementosConstruccionPavimentoPetreo={listaElementosConstruccionPavimentoPetreo}
							listaElementosConstruccionPintura={listaElementosConstruccionPintura}
							listaElementosConstruccionPlafon={listaElementosConstruccionPlafon}
							listaElementosConstruccionRecubrimientoEspecial={listaElementosConstruccionRecubrimientoEspecial}
							listaElementosConstruccionTecho={listaElementosConstruccionTecho}
							listaElementosConstruccionVidrieria={listaElementosConstruccionVidrieria}
							listaElementosConstruccionZoclo={listaElementosConstruccionZoclo}
							usuario={usuario}
						/>
					</div>
					{!esSeccionPreferencias() && (
						<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.UBICACION ? ' show active' : ''}`} id="contenidoUbicacion">
							<AvaluoFormularioUbicacion
								eventoDeshacer={eventoDeshacer}
								eventoGuardar={eventoGuardarFormulario}
								eventoSalir={eventoSalir}
								hayCambiosSinGuardar={hayCambiosSinGuardar}
							/>
						</div>
					)}
					{!esSeccionPreferencias() && (
						<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.FOTOS ? ' show active' : ''}`} id="contenidoFotos">
							<AvaluoFormularioFotos
								eventoDeshacer={eventoDeshacer}
								eventoGuardar={eventoGuardarFormulario}
								eventoSalir={eventoSalir}
								hayCambiosSinGuardar={hayCambiosSinGuardar}
							/>
						</div>
					)}
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.TERRENO ? ' show active' : ''}`} id="contenidoTerreno">
						<AvaluoFormularioTerreno
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardarFormulario}
							eventoSalir={eventoSalir}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
							listaMotivo={listaMotivo}
							listaUnidadSuperficie={listaUnidadSuperficie}
						/>
					</div>
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.CONSTRUCCION ? ' show active' : ''}`} id="contenidoConstruccion">
						<AvaluoFormularioConstruccion
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardarFormulario}
							eventoSalir={eventoSalir}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
							listaCalidadConstruccion={listaCalidadConstruccion}
							listaEstadoConservacion={listaEstadoConservacion}
							listaMotivo={listaMotivo}
							listaTipoConstruccion={listaTipoConstruccion}
							listaUnidadSuperficie={listaUnidadSuperficie}
						/>
					</div>
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.ESPECIALES ? ' show active' : ''}`} id="contenidoEspeciales">
						<AvaluoFormularioEspeciales
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardar}
							eventoSalir={eventoSalir}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
							listaCalidadConstruccion={listaCalidadConstruccion}
							listaEstadoConservacion={listaEstadoConservacion}
							listaMotivo={listaMotivo}
							listaTipoConstruccion={listaTipoConstruccion}
							listaUnidadSuperficie={listaUnidadSuperficie}
						/>
					</div>
					<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.OBSERVACIONES ? ' show active' : ''}`} id="contenidoObservaciones">
						<AvaluoFormularioObservaciones
							eventoDeshacer={eventoDeshacer}
							eventoGuardar={eventoGuardarFormulario}
							eventoSalir={eventoSalir}
							hayCambiosSinGuardar={hayCambiosSinGuardar}
						/>
					</div>
				</div>
			</Col>
		</Row>
	);
};

export default AvaluoFormulario;
