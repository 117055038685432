import Excel from 'exceljs';
import fs from 'fs';
import mcLogger from '@mcsoft/logger';

const NOMBRE_CLASE = '@mcsoft/archivos';

/**
 * Obtiene el archivo blob apartir de a cadena codificada en base64.
 * - ***base64*** - Base64 del archivo.
 */
export const convertirBase64ABlob = (base64: string): Promise<Blob> => {
	const nombreMetodo = 'convertirBase64ABlob';
	mcLogger.nodeModule({
		mensaje: 'Archivo Base64 recibido:',
		nombreArchivo: NOMBRE_CLASE,
		nombreMetodo,
		objetoExtra: base64
	});
	return new Promise((resolve, reject) => {
		try {
			const contentType = obtenerTipoMimeDeCadenaBase64(base64);
			const base64SinData = quitarDataDeCadenaBase64(base64);
			const byteCharacters = atob(base64SinData);
			const byteArrays = [];
			const sliceSize = 512;

			for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
				const slice = byteCharacters.slice(offset, offset + sliceSize);

				const byteNumbers = new Array(slice.length);
				for (let i = 0; i < slice.length; i++) {
					byteNumbers[i] = slice.charCodeAt(i);
				}

				const byteArray = new Uint8Array(byteNumbers);
				byteArrays.push(byteArray);
			}

			const blob = new Blob(byteArrays, { type: contentType });
			resolve(blob);
		} catch (error) {
			mcLogger.error({
				mensaje: 'Error al convertir el archivo:',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: error
			});
			reject(error);
		}
	});
};

/**
 * Obtiene un arreglo de bytes apartir de la cadena codificada en base64.
 * - ***base64*** - Base64 del archivo.
 */
export const convertirBase64ABytes = (base64: string): Uint8Array => {
	if (base64.length % 4 !== 0) {
		throw new Error('Imposible convertir a base64.');
	}
	const indice = base64.indexOf('=');
	if (indice !== -1 && indice < base64.length - 2) {
		throw new Error('Imposible convertir a base64.');
	}
	const octetosPerdidos = base64.endsWith('==') ? 2 : base64.endsWith('=') ? 1 : 0;
	const longitud = base64.length;
	const bytes = new Uint8Array(3 * (longitud / 4));
	let buffer: any;
	for (let i = 0, j = 0; i < longitud; i += 4, j += 3) {
		buffer =
			(obtenerCodigoBase64(base64.charCodeAt(i)) << 18) |
			(obtenerCodigoBase64(base64.charCodeAt(i + 1)) << 12) |
			(obtenerCodigoBase64(base64.charCodeAt(i + 2)) << 6) |
			obtenerCodigoBase64(base64.charCodeAt(i + 3));
		bytes[j] = buffer >> 16;
		bytes[j + 1] = (buffer >> 8) & 0xff;
		bytes[j + 2] = buffer & 0xff;
	}
	return bytes.subarray(0, bytes.length - octetosPerdidos);
};

/**
 * Obtiene la cadena codificada en base64 apartir del blob del archivo.
 * - ***archivoBlob*** - Blob del archivo.
 */
export const convertirBlobABase64 = (archivoBlob: Blob): Promise<string> => {
	const nombreMetodo = 'convertirBlobABase64';
	mcLogger.nodeModule({
		mensaje: 'Archivo BLOB recibido:',
		nombreArchivo: NOMBRE_CLASE,
		nombreMetodo,
		objetoExtra: archivoBlob
	});
	return new Promise((resolve, reject) => {
		const lectorDeArchivos = new FileReader();
		lectorDeArchivos.onload = (): any => {
			const dataUrl = lectorDeArchivos.result;
			if (dataUrl) {
				const base64 = dataUrl as string;
				mcLogger.nodeModule({
					mensaje: 'Archivo convertido con éxito.',
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo
				});
				resolve(base64);
			} else {
				mcLogger.error({
					mensaje: 'Error desconocido al convertir el archivo.',
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo
				});
				reject('Error desconocido al convertir el archivo.');
			}
		};
		lectorDeArchivos.onerror = (error): any => {
			mcLogger.error({
				mensaje: 'Error al convertir el archivo:',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: error
			});
			reject(error);
		};
		mcLogger.nodeModule({
			mensaje: 'Convirtiendo el archivo...',
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo
		});
		lectorDeArchivos.readAsDataURL(archivoBlob);
	});
};

/**
 * Obtiene la cadena codificada en base64 apartir del arreglo binario del archivo.
 * - ***bytes*** - Arreglo binario del archivo.
 */
export const convertirBytesABase64 = (bytes: any): string => {
	let base64 = '';
	let i;
	const l = bytes.length;
	for (i = 2; i < l; i += 3) {
		base64 += base64abecedario[bytes[i - 2] >> 2];
		base64 += base64abecedario[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
		base64 += base64abecedario[((bytes[i - 1] & 0x0f) << 2) | (bytes[i] >> 6)];
		base64 += base64abecedario[bytes[i] & 0x3f];
	}
	if (i === l + 1) {
		// 1 octet yet to write
		base64 += base64abecedario[bytes[i - 2] >> 2];
		base64 += base64abecedario[(bytes[i - 2] & 0x03) << 4];
		base64 += '==';
	}
	if (i === l) {
		// 2 octets yet to write
		base64 += base64abecedario[bytes[i - 2] >> 2];
		base64 += base64abecedario[((bytes[i - 2] & 0x03) << 4) | (bytes[i - 1] >> 4)];
		base64 += base64abecedario[(bytes[i - 1] & 0x0f) << 2];
		base64 += '=';
	}
	return base64;
};

/**
 * Crea una cadena base64 a partir de la URL de una imagen.
 * - ***url*** - URL de la imagen.
 */
export const convertirImagenUrlABase64 = (url: string): Promise<string> =>
	new Promise((resolve, reject) => {
		const imagen = new Image();
		imagen.addEventListener('load', () => {
			const canvas = document.createElement('canvas');
			canvas.width = imagen.width;
			canvas.height = imagen.height;
			const ctx = canvas.getContext('2d');
			if (ctx) {
				ctx.drawImage(imagen, 0, 0);
				const dataURL = canvas.toDataURL('image/png');
				resolve(dataURL);
			}
			resolve('');
		});
		imagen.addEventListener('error', (error) => reject(error));
		imagen.setAttribute('crossOrigin', 'anonymous');
		imagen.src = url;
	});

/**
 * Crea una imagen HTML a partir de la URL.
 * - ***url*** - URL de la imagen.
 */
export const convertirImagenUrlAImagenHtml = (url: string): Promise<HTMLImageElement> =>
	new Promise((resolve, reject) => {
		const imagen = new Image();
		imagen.addEventListener('load', () => resolve(imagen));
		imagen.addEventListener('error', (error) => reject(error));
		imagen.setAttribute('crossOrigin', 'anonymous');
		imagen.src = url;
	});

/**
 * Elimina la extensión del nombre del archivo.
 * - ***nombreArchivo*** - Nombre del archivo incluyendo la extensión.
 */
export const eliminarExtensionArchivo = (nombreArchivo: string): string => {
	if (nombreArchivo) {
		const nombreArchivoSinExtension = nombreArchivo.replace(/\.[^/.]+$/, '');
		return nombreArchivoSinExtension;
	} else {
		return '';
	}
};

/**
 * Lee una hoja de un archivo de excel y regresa su contenido como un arreglo de arreglos.
 * - ***buffer*** - Buffer del archivo.
 * - ***numeroHoja*** - Index o nombre de la hoja a leer.
 */
export const leerArchivoExcel = ({ buffer, numeroHoja }: { buffer: Buffer; numeroHoja: string | number }): Promise<Array<Array<any>>> => {
	const nombreMetodo = 'leerArchivoExcel';
	mcLogger.nodeModule({
		mensaje: `Leyendo la hoja '${numeroHoja}' del archivo...`,
		nombreArchivo: NOMBRE_CLASE,
		nombreMetodo
	});
	return new Promise((resolve, reject) => {
		const workbook = new Excel.Workbook();
		workbook.xlsx
			.load(buffer)
			.then((resultado: any) => {
				if (!isNaN(numeroHoja as number)) {
					numeroHoja = Number.parseInt(numeroHoja as string);
				}
				const worksheet = resultado.getWorksheet(numeroHoja);
				const renglones = worksheet.getSheetValues();
				mcLogger.nodeModule({
					mensaje: 'Archivo leído con éxito:',
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: renglones
				});
				resolve(renglones);
			})
			.catch((error: Error) => {
				mcLogger.error({
					mensaje: 'Error al leer archivo:',
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: error
				});
				reject(error);
			});
	});
};

/**
 * Lee un archivo de texto.
 * - ***rutaArchivo*** - Ruta del archivo a leer.
 */
export const leerArchivoTexto = (rutaArchivo: string): Promise<string> => {
	const nombreMetodo = 'leerArchivoTexto';
	mcLogger.nodeModule({
		mensaje: `Leyendo archivo '${rutaArchivo}'...`,
		nombreArchivo: NOMBRE_CLASE,
		nombreMetodo
	});
	return new Promise((resolve, reject) => {
		fs.readFile(rutaArchivo, 'utf8', function (error: any, datos: string) {
			if (error) {
				reject(error);
				mcLogger.error({
					mensaje: 'Error al leer archivo:',
					nombreArchivo: NOMBRE_CLASE,
					nombreMetodo,
					objetoExtra: error
				});
			}
			mcLogger.nodeModule({
				mensaje: 'Archivo leído con éxito:',
				nombreArchivo: NOMBRE_CLASE,
				nombreMetodo,
				objetoExtra: datos
			});
			resolve(datos);
		});
	});
};

/**
 * Obtiene el codigo base64 de un caracter.
 * - ***nombreArchivo*** - Nombre del archivo incluyendo la extensión.
 */
const obtenerCodigoBase64 = (charCode: number): number => {
	if (charCode >= base64codes.length) {
		throw new Error('Imposible convertir a base64.');
	}
	const code = base64codes[charCode];
	if (code === 255) {
		throw new Error('Imposible convertir a base64.');
	}
	return code;
};

/**
 * Obtiene la extensión apartir del nombre del archivo.
 * - ***nombreArchivo*** - Nombre del archivo incluyendo la extensión.
 */
export const obtenerExtensionArchivo = (nombreArchivo: string): string => {
	const resultadoMatch = nombreArchivo.match(/\.([^.]*)$/);
	if (!resultadoMatch) {
		return 'extensionDesconocida';
	} else {
		const extension = resultadoMatch[1].toLowerCase();
		return extension;
	}
};

/**
 * Obtiene la extensión a partir del tipo MIME del archivo.
 * - ***tipoMime*** - Tipo MIME del archivo.
 */
export const obtenerExtensionDeTipoMime = (tipoMime: string): string => {
	switch (tipoMime.toLowerCase()) {
		case 'audio/aac':
			return 'aac';
		case 'application/x-abiword':
			return 'abw';
		case 'application/x-freearc':
			return 'arc';
		case 'video/x-msvideo':
			return 'avi';
		case 'application/vnd.amazon.ebook':
			return 'azw';
		case 'application/octet-stream':
			return 'bin';
		case 'image/bmp':
			return 'bmp';
		case 'application/x-bzip':
			return 'bz';
		case 'application/x-bzip2':
			return 'bz2';
		case 'application/x-csh':
			return 'csh';
		case 'text/css':
			return 'css';
		case 'text/csv':
			return 'csv';
		case 'application/msword':
			return 'doc';
		case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
			return 'docx';
		case 'application/vnd.ms-fontobject':
			return 'eot';
		case 'application/epub+zip':
			return 'epub';
		case 'application/gzip':
			return 'gz';
		case 'image/gif':
			return 'gif';
		case 'text/html':
			return 'html';
		case 'image/vnd.microsoft.icon':
			return 'ico';
		case 'text/calendar':
			return 'ics';
		case 'application/java-archive':
			return 'jar';
		case 'image/jpeg':
			return 'jpeg';
		case 'text/javascript':
			return 'js';
		case 'application/json':
			return 'json';
		case 'application/ld+json':
			return 'jsonld';
		case 'audio/midi':
			return 'mid';
		case 'audio/x-midi':
			return 'midi';
		case 'audio/mpeg':
			return 'mp3';
		case 'video/mpeg':
			return 'mpeg';
		case 'application/vnd.apple.installer+xml':
			return 'mpkg';
		case 'application/vnd.oasis.opendocument.presentation':
			return 'odp';
		case 'application/vnd.oasis.opendocument.spreadsheet':
			return 'ods';
		case 'application/vnd.oasis.opendocument.text':
			return 'odt';
		case 'audio/ogg':
			return 'oga';
		case 'video/ogg':
			return 'ogv';
		case 'application/ogg':
			return 'ogx';
		case 'audio/opus':
			return 'opus';
		case 'font/otf':
			return 'otf';
		case 'image/png':
			return 'png';
		case 'application/pdf':
			return 'pdf';
		case 'application/x-httpd-php':
			return 'php';
		case 'application/vnd.ms-powerpoint':
			return 'ppt';
		case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
			return 'pptx';
		case 'application/vnd.rar':
			return 'rar';
		case 'application/rtf':
			return 'rtf';
		case 'application/x-sh':
			return 'sh';
		case 'image/svg+xml':
			return 'svg';
		case 'application/x-shockwave-flash':
			return 'swf';
		case 'application/x-tar':
			return 'tar';
		case 'image/tiff':
			return 'tiff';
		case 'video/mp2t':
			return 'ts';
		case 'font/ttf':
			return 'ttf';
		case 'text/plain':
			return 'txt';
		case 'application/vnd.visio':
			return 'vsd';
		case 'audio/wav':
			return 'wav';
		case 'audio/webm':
			return 'weba';
		case 'video/webm':
			return 'webm';
		case 'image/webp':
			return 'webp';
		case 'font/woff':
			return 'woff';
		case 'font/woff2':
			return 'woff2';
		case 'application/xhtml+xml':
			return 'xhtml';
		case 'application/vnd.ms-excel':
			return 'xls';
		case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
			return 'xlsx';
		case 'application/xml':
			return 'xml';
		case 'application/vnd.mozilla.xul+xml':
			return 'xul';
		case 'application/zip':
			return 'zip';
		case 'video/3gpp':
			return '3gp';
		case 'video/3gpp2':
			return '3g2';
		case 'application/x-7z-compressed':
			return '7z';
		default:
			return 'extensionDesconocida';
	}
};

/**
 * Obtiene el tamaño del archivo y su unidad [bytes|KB|MB|GB|TB].
 * - ***tamanoBytes*** - Tamaño del archivo en bytes.
 */
export const obtenerTamanoArchivo = (tamanoBytes: number): string => {
	const decimales = 2;
	const multiplo = 1024;
	const kiloByte = multiplo;
	const megaByte = multiplo * kiloByte;
	const gigaByte = multiplo * megaByte;
	const teraByte = multiplo * gigaByte;
	let tamano = '0';
	let unidad = '';
	if (tamanoBytes >= teraByte) {
		tamano = (tamanoBytes / teraByte).toFixed(decimales);
		unidad = 'TB';
	} else if (tamanoBytes >= gigaByte) {
		tamano = (tamanoBytes / gigaByte).toFixed(decimales);
		unidad = 'GB';
	} else if (tamanoBytes >= megaByte) {
		tamano = (tamanoBytes / megaByte).toFixed(decimales);
		unidad = 'MB';
	} else if (tamanoBytes >= kiloByte) {
		tamano = (tamanoBytes / kiloByte).toFixed(decimales);
		unidad = 'KB';
	} else if (tamanoBytes < kiloByte) {
		tamano = tamanoBytes.toString();
		unidad = 'bytes';
	}
	return `${tamano} ${unidad}`;
};

/**
 * Obtiene el tipo MIME a partir de la cadena en base 64 del archivo.
 * - ***cadenaBase64*** - Archivo en base 64.
 */
export const obtenerTipoMimeDeCadenaBase64 = (cadenaBase64: string): string => {
	const indiceInicio = cadenaBase64.indexOf(':') + 1;
	const indiceFin = cadenaBase64.indexOf(';');
	const tipoMime = cadenaBase64.slice(indiceInicio, indiceFin);
	return tipoMime;
};

/**
 * Obtiene el tipo MIME a partir de la extensión del archivo.
 * - ***extension*** - Extension del archivo.
 */
export const obtenerTipoMimeDeExtension = (extension: string): string => {
	switch (extension.toLowerCase()) {
		case 'aac':
			return 'audio/aac';
		case 'abw':
			return 'application/x-abiword';
		case 'arc':
			return 'application/x-freearc';
		case 'avi':
			return 'video/x-msvideo';
		case 'azw':
			return 'application/vnd.amazon.ebook';
		case 'bin':
			return 'application/octet-stream';
		case 'bmp':
			return 'image/bmp';
		case 'bz':
			return 'application/x-bzip';
		case 'bz2':
			return 'application/x-bzip2';
		case 'csh':
			return 'application/x-csh';
		case 'css':
			return 'text/css';
		case 'csv':
			return 'text/csv';
		case 'doc':
			return 'application/msword';
		case 'docx':
			return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
		case 'eot':
			return 'application/vnd.ms-fontobject';
		case 'epub':
			return 'application/epub+zip';
		case 'gz':
			return 'application/gzip';
		case 'gif':
			return 'image/gif';
		case 'htm':
			return 'text/html';
		case 'html':
			return 'text/html';
		case 'ico':
			return 'image/vnd.microsoft.icon';
		case 'ics':
			return 'text/calendar';
		case 'jar':
			return 'application/java-archive';
		case 'jpeg':
			return 'image/jpeg';
		case 'jpg':
			return 'image/jpeg';
		case 'js':
			return 'text/javascript';
		case 'json':
			return 'application/json';
		case 'jsonld':
			return 'application/ld+json';
		case 'mid':
			return 'audio/midi';
		case 'midi':
			return 'audio/x-midi';
		case 'mjs':
			return 'text/javascript';
		case 'mp3':
			return 'audio/mpeg';
		case 'mpeg':
			return 'video/mpeg';
		case 'mpkg':
			return 'application/vnd.apple.installer+xml';
		case 'odp':
			return 'application/vnd.oasis.opendocument.presentation';
		case 'ods':
			return 'application/vnd.oasis.opendocument.spreadsheet';
		case 'odt':
			return 'application/vnd.oasis.opendocument.text';
		case 'oga':
			return 'audio/ogg';
		case 'ogv':
			return 'video/ogg';
		case 'ogx':
			return 'application/ogg';
		case 'opus':
			return 'audio/opus';
		case 'otf':
			return 'font/otf';
		case 'png':
			return 'image/png';
		case 'pdf':
			return 'application/pdf';
		case 'php':
			return 'application/x-httpd-php';
		case 'ppt':
			return 'application/vnd.ms-powerpoint';
		case 'pptx':
			return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
		case 'rar':
			return 'application/vnd.rar';
		case 'rtf':
			return 'application/rtf';
		case 'sh':
			return 'application/x-sh';
		case 'svg':
			return 'image/svg+xml';
		case 'swf':
			return 'application/x-shockwave-flash';
		case 'tar':
			return 'application/x-tar';
		case 'tif':
			return 'image/tiff';
		case 'tiff':
			return 'image/tiff';
		case 'ts':
			return 'video/mp2t';
		case 'ttf':
			return 'font/ttf';
		case 'txt':
			return 'text/plain';
		case 'vsd':
			return 'application/vnd.visio';
		case 'wav':
			return 'audio/wav';
		case 'weba':
			return 'audio/webm';
		case 'webm':
			return 'video/webm';
		case 'webp':
			return 'image/webp';
		case 'woff':
			return 'font/woff';
		case 'woff2':
			return 'font/woff2';
		case 'xhtml':
			return 'application/xhtml+xml';
		case 'xls':
			return 'application/vnd.ms-excel';
		case 'xlsx':
			return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
		case 'xml':
			return 'application/xml';
		case 'xul':
			return 'application/vnd.mozilla.xul+xml';
		case 'zip':
			return 'application/zip';
		case '3gp':
			return 'video/3gpp';
		case '3g2':
			return 'video/3gpp2';
		case '7z':
			return 'application/x-7z-compressed';
		default:
			return 'tipoMimeDesconocido';
	}
};

/**
 * Elimina la sección data de la cadena en base 64 del archivo.
 * - ***cadenaBase64*** - Archivo en base 64.
 */
export const quitarDataDeCadenaBase64 = (cadenaBase64: string): string => {
	const indiceInicio = cadenaBase64.indexOf(',') + 1;
	const base64SinData = cadenaBase64.slice(indiceInicio);
	return base64SinData;
};

/**
 * Arreglo de caracteres base64.
 */
const base64abecedario = [
	'A',
	'B',
	'C',
	'D',
	'E',
	'F',
	'G',
	'H',
	'I',
	'J',
	'K',
	'L',
	'M',
	'N',
	'O',
	'P',
	'Q',
	'R',
	'S',
	'T',
	'U',
	'V',
	'W',
	'X',
	'Y',
	'Z',
	'a',
	'b',
	'c',
	'd',
	'e',
	'f',
	'g',
	'h',
	'i',
	'j',
	'k',
	'l',
	'm',
	'n',
	'o',
	'p',
	'q',
	'r',
	's',
	't',
	'u',
	'v',
	'w',
	'x',
	'y',
	'z',
	'0',
	'1',
	'2',
	'3',
	'4',
	'5',
	'6',
	'7',
	'8',
	'9',
	'+',
	'/'
];

/**
 * Arreglo de codigos base64.
 */
const base64codes = [
	255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255,
	255, 255, 255, 255, 255, 255, 255, 255, 62, 255, 255, 255, 63, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 255, 255, 255, 0, 255, 255, 255, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,
	13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 255, 255, 255, 255, 255, 255, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49,
	50, 51
];
