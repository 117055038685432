import 'moment/locale/es-mx';
import { convertirNumeroALetras, convertirNumeroEnteroANumeroRomano } from '@mcsoft/numeros';
import Avaluo from 'modelo/Avaluo';
import AvaluoConstruccionesDetalle from 'modelo/AvaluoConstruccionesDetalle';
import AvaluoInstalacionesEspecialesDetalle from 'modelo/AvaluoInstalacionesEspecialesDetalle';
import AvaluoTerrenoDetalle from 'modelo/AvaluoTerrenoDetalle';
import disenos from './disenos';
import moment from 'moment-timezone';
moment.locale('es-mx');

const formateador = new Intl.NumberFormat('es-MX', {
	currency: 'MXN',
	style: 'currency'
});

const seccion = (avaluo: Avaluo) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.antecedentes && avaluo.observaciones && avaluo.construcciones && avaluo.instalacionesEspeciales && avaluo.terreno) {
			const { valorTotal } = avaluo;
			const { fechaAvaluo } = avaluo.antecedentes;
			const { descripcion: descripcionObservaciones, valoresReferidos } = avaluo.observaciones;
			const {
				detalles: detallesConstrucciones,
				indiviso: indivisoConstrucciones,
				superficieTotal: superficieTotalConstrucciones,
				superficieUnidad: superficieUnidadConstrucciones,
				valor: valorConstrucciones,
				valorTotal: valorTotalConstrucciones
			} = avaluo.construcciones;
			const {
				detalles: detallesInstalacionesEspeciales,
				indiviso: indivisoInstalacionesEspeciales,
				superficieTotal: superficieTotalInstalacionesEspeciales,
				superficieUnidad: superficieUnidadInstalacionesEspeciales,
				valor: valorInstalacionesEspeciales,
				valorTotal: valorTotalInstalacionesEspeciales
			} = avaluo.instalacionesEspeciales;
			const {
				detalles: detallesTerreno,
				indiviso: indivisoTerreno,
				superficieTotal: superficieTotalTerreno,
				superficieUnidad: superficieUnidadTerreno,
				valor: valorTerreno,
				valorTotal: valorTotalTerreno
			} = avaluo.terreno;
			const tablaContrucciones = obtenerTablaContrucciones(detallesConstrucciones);
			const tablaInstalacionesEspeciales = obtenerTablaInstalacionesEspeciales(detallesInstalacionesEspeciales);
			const tablaTerreno = obtenerTablaTerreno(detallesTerreno);
			const tablaValoresReferidos = obtenerTablaValoresReferidos(valoresReferidos);
			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									colSpan: 8,
									style: 'seccionTitulo',
									text: 'AVALÚO FÍSICO'
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							// ****************************************************************************************************
							// Terreno
							// ****************************************************************************************************
							[
								{
									border: [1, 0, 1, 0],
									colSpan: 8,
									style: { bold: true },
									text: 'A) Del Terreno:'
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							...tablaTerreno,
							[
								{
									border: [1, 1, 1, 0],
									style: 'seccionTablaCeldaDerecha',
									text: 'Total:'
								},
								{
									border: [1, 1, 1, 1],
									style: 'seccionTablaCeldaDerecha',
									text: superficieTotalTerreno ? `${superficieTotalTerreno.toLocaleString('es-MX')}${superficieUnidadTerreno?.simbolo}` : ''
								},
								{
									border: [1, 1, 0, 0],
									colSpan: 5,
									style: 'seccionTablaCeldaDerecha',
									text: 'Valor del Terreno:'
								},
								{},
								{},
								{},
								{},
								{
									border: [0, 1, 1, 0],
									style: 'seccionTablaCeldaDerecha',
									text: valorTerreno ? formateador.format(valorTerreno) : formateador.format(0)
								}
							],
							[
								{
									border: [1, 0, 0, 0],
									colSpan: 4,
									text: ''
								},
								{},
								{},
								{},
								{
									border: [0, 0, 1, 0],
									colSpan: 2,
									style: 'seccionTablaCelda',
									text: 'Indiviso en su caso:'
								},
								{},
								{
									border: [1, 1, 1, 1],
									style: 'seccionTablaCeldaDerecha',
									text: indivisoTerreno ? `${indivisoTerreno.toLocaleString('es-MX')} %` : ''
								},
								{
									border: [1, 0, 1, 0],
									style: 'seccionTablaCeldaDerechaNegrita',
									text: valorTotalTerreno ? formateador.format(valorTotalTerreno) : formateador.format(0)
								}
							],
							// ****************************************************************************************************
							// Construcciones
							// ****************************************************************************************************
							[
								{
									border: [1, 0, 1, 0],
									colSpan: 8,
									style: { bold: true },
									text: 'B) De las Construcciones:'
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							...tablaContrucciones,
							[
								{
									border: [1, 1, 1, 0],
									style: 'seccionTablaCeldaDerecha',
									text: 'Total:'
								},
								{
									border: [1, 1, 1, 1],
									style: 'seccionTablaCeldaDerecha',
									text: superficieTotalConstrucciones ? `${superficieTotalConstrucciones.toLocaleString('es-MX')}${superficieUnidadConstrucciones?.simbolo}` : ''
								},
								{
									border: [1, 1, 0, 0],
									colSpan: 5,
									style: 'seccionTablaCeldaDerecha',
									text: 'Valor de las Construcciones:'
								},
								{},
								{},
								{},
								{},
								{
									border: [0, 1, 1, 0],
									style: 'seccionTablaCeldaDerecha',
									text: valorConstrucciones ? formateador.format(valorConstrucciones) : formateador.format(0)
								}
							],
							[
								{
									border: [1, 0, 0, 0],
									colSpan: 4,
									text: ''
								},
								{},
								{},
								{},
								{
									border: [0, 0, 1, 0],
									colSpan: 2,
									style: 'seccionTablaCelda',
									text: 'Indiviso en su caso:'
								},
								{},
								{
									border: [1, 1, 1, 1],
									style: 'seccionTablaCeldaDerecha',
									text: indivisoConstrucciones ? `${indivisoConstrucciones.toLocaleString('es-MX')} %` : ''
								},
								{
									border: [1, 0, 1, 0],
									style: 'seccionTablaCeldaDerechaNegrita',
									text: valorTotalConstrucciones ? formateador.format(valorTotalConstrucciones) : formateador.format(0)
								}
							],
							// ****************************************************************************************************
							// Instalaciones Especiales
							// ****************************************************************************************************
							[
								{
									border: [1, 0, 1, 0],
									colSpan: 8,
									style: { bold: true },
									text: 'C) Instalaciones Especiales:'
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							...tablaInstalacionesEspeciales,
							[
								{
									border: [1, 1, 1, 0],
									style: 'seccionTablaCeldaDerecha',
									text: 'Total:'
								},
								{
									border: [1, 1, 1, 1],
									style: 'seccionTablaCeldaDerecha',
									text: superficieTotalInstalacionesEspeciales
										? `${superficieTotalInstalacionesEspeciales.toLocaleString('es-MX')}${superficieUnidadInstalacionesEspeciales?.simbolo}`
										: ''
								},
								{
									border: [0, 1, 0, 0],
									colSpan: 5,
									style: 'seccionTablaCeldaDerecha',
									text: 'Valor de las Instalaciones Especiales:'
								},
								{},
								{},
								{},
								{},
								{
									border: [0, 1, 1, 0],
									style: 'seccionTablaCeldaDerecha',
									text: valorInstalacionesEspeciales ? formateador.format(valorInstalacionesEspeciales) : formateador.format(0)
								}
							],
							[
								{
									border: [1, 0, 0, 0],
									colSpan: 4,
									text: ''
								},
								{},
								{},
								{},
								{
									border: [0, 0, 1, 0],
									colSpan: 2,
									style: 'seccionTablaCelda',
									text: 'Indiviso en su caso:'
								},
								{},
								{
									border: [1, 1, 1, 1],
									style: 'seccionTablaCeldaDerecha',
									text: indivisoInstalacionesEspeciales ? `${indivisoInstalacionesEspeciales.toLocaleString('es-MX')} %` : ''
								},
								{
									border: [1, 0, 1, 0],
									style: 'seccionTablaCeldaDerechaNegrita',
									text: valorTotalInstalacionesEspeciales ? formateador.format(valorTotalInstalacionesEspeciales) : formateador.format(0)
								}
							],
							// ****************************************************************************************************
							// Totales
							// ****************************************************************************************************
							[
								{
									border: [1, 0, 0, 0],
									colSpan: 7,
									margin: [0, 10, 0, 0],
									style: 'seccionTablaCeldaDerecha',
									text: 'Valor Total del Terreno, Construcciones e Instalaciones Especiales:'
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{
									border: [0, 0, 1, 0],
									margin: [0, 10, 0, 0],
									style: 'seccionTablaCeldaDerechaNegrita',
									text: valorTotal ? formateador.format(valorTotal) : formateador.format(0)
								}
							],
							[
								{
									border: [1, 0, 1, 0],
									colSpan: 8,
									style: { bold: true },
									text: 'Valor Total con letra'
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							[
								{
									border: [1, 0, 1, 0],
									colSpan: 8,
									style: 'seccionTablaCelda',
									text: valorTotal ? `( ${convertirNumeroALetras({ cantidad: valorTotal })} MONEDA NACIONAL )` : ''
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							[
								{
									border: [1, 0, 0, 1],
									colSpan: 2,
									style: 'seccionTablaCeldaDerecha',
									text: 'Fecha de Avalúo:'
								},
								{},
								{
									border: [0, 0, 0, 1],
									colSpan: 2,
									style: 'seccionTablaCeldaDerecha',
									text: fechaAvaluo ? moment(fechaAvaluo).tz('America/Bahia_Banderas').locale(false).format('DD [de] MMMM [de] YYYY') : ''
								},
								{
									border: [0, 0, 0, 1],
									text: ''
								},
								{
									border: [0, 0, 0, 1],
									text: ''
								},
								{
									border: [0, 0, 0, 1],
									text: ''
								},
								{
									border: [0, 0, 0, 1],
									text: ''
								},
								{
									border: [0, 0, 1, 1],
									text: ''
								}
							],
							[
								{
									border: [0, 1, 0, 1],
									colSpan: 8,
									margin: [0, 10, 0, 0],
									text: ''
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							// ****************************************************************************************************
							// Valores Referidos
							// ****************************************************************************************************
							[
								{
									border: [1, 0, 1, 0],
									colSpan: 8,
									style: 'seccionTablaCelda',
									text: 'ESPACIO PARA ASIGNACIÓN DE VALORES REFERIDOS'
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							...tablaValoresReferidos,
							[
								{
									border: [1, 0, 1, 1],
									colSpan: 8,
									margin: [0, 0, 0, 0],
									style: 'seccionTablaCeldaInformacion',
									text: '(Este dato no tiene validez si carece del sello de valor referido asignado por Catastro)'
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							[
								{
									border: [0, 1, 0, 1],
									colSpan: 8,
									margin: [0, 10, 0, 0],
									text: ''
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							// ****************************************************************************************************
							// Observaciones
							// ****************************************************************************************************
							[
								{
									border: [1, 0, 1, 0],
									colSpan: 8,
									margin: [10, 0, 0, 0],
									style: { bold: true },
									text: 'Observaciones:'
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							],
							obtenerTablaObservaciones(descripcionObservaciones),
							[
								{
									border: [1, 0, 1, 1],
									colSpan: 8,
									margin: [0, 0, 0, 0],
									style: 'seccionTablaCeldaInformacion',
									text: '(Este avalúo tiene una vigencia de 6 (seis) meses después de aprobado por Catastro Municipal o al cambio de año natural según primero ocurra)'
								},
								{},
								{},
								{},
								{},
								{},
								{},
								{}
							]
						],
						widths: [60, 60, 70, 50, 50, 50, 60, '*']
					}
				}
			];
			resolve(contenido);
		}
	});

const obtenerTablaObservaciones = (observaciones?: string | null) => {
	let tablaObservaciones: Array<any> = [];
	if (observaciones && observaciones !== '') {
		tablaObservaciones = [
			{
				border: [1, 0, 1, 0],
				colSpan: 8,
				margin: [0, 0, 0, 0],
				text: observaciones
			},
			{},
			{},
			{},
			{},
			{},
			{},
			{}
		];
	} else {
		tablaObservaciones = [
			{
				border: [1, 0, 1, 0],
				colSpan: 8,
				margin: [0, 30, 0, 0],
				text: ''
			},
			{},
			{},
			{},
			{},
			{},
			{},
			{}
		];
	}
	return tablaObservaciones;
};

const obtenerTablaContrucciones = (detalles: Array<AvaluoConstruccionesDetalle>) => {
	const tablaEncabezado = [
		[
			{
				style: 'seccionTablaEncabezado',
				text: 'Clasificación'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Superficie'
			},
			{
				colSpan: 2,
				style: 'seccionTablaEncabezado',
				text: 'Valor Unitario'
			},
			{},
			{
				style: 'seccionTablaEncabezado',
				text: 'Edad'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Factor'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Motivo'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Valor Resultante'
			}
		]
	];
	const renglones: any = [];
	detalles.forEach((detalle) => {
		renglones.push([
			{
				style: 'seccionTablaCelda',
				text: detalle.codigoClasificación
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: `${detalle.superficie.toLocaleString('es-MX')}${detalle.superficieUnidad?.simbolo}`
			},
			{
				colSpan: 2,
				style: 'seccionTablaCelda',
				text: formateador.format(detalle.valorUnitario)
			},
			{},
			{
				style: 'seccionTablaCelda',
				text: detalle.edad
			},
			{
				style: 'seccionTablaCelda',
				text: detalle.ajusteFactor.toLocaleString('es-MX')
			},
			{
				style: 'seccionTablaCelda',
				text: detalle.ajusteMotivo?.nombre
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: formateador.format(detalle.valorResultante)
			}
		]);
	});
	if (detalles.length === 0) {
		renglones.push([
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: ''
			},
			{
				colSpan: 2,
				style: 'seccionTablaCelda',
				text: ''
			},
			{},
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: ''
			},
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: formateador.format(0)
			}
		]);
	}
	return [...tablaEncabezado, ...renglones];
};

const obtenerTablaInstalacionesEspeciales = (detalles: Array<AvaluoInstalacionesEspecialesDetalle>) => {
	const tablaEncabezado = [
		[
			{
				style: 'seccionTablaEncabezado',
				text: 'Clasificación'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Superficie'
			},
			{
				colSpan: 2,
				style: 'seccionTablaEncabezado',
				text: 'Valor Unitario'
			},
			{},
			{
				style: 'seccionTablaEncabezado',
				text: 'Edad'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Factor'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Motivo'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Valor Resultante'
			}
		]
	];
	const renglones: any = [];
	detalles.forEach((detalle) => {
		renglones.push([
			{
				style: 'seccionTablaCelda',
				text: detalle.codigoClasificación
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: `${detalle.superficie.toLocaleString('es-MX')}${detalle.superficieUnidad?.simbolo}`
			},
			{
				colSpan: 2,
				style: 'seccionTablaCelda',
				text: formateador.format(detalle.valorUnitario)
			},
			{},
			{
				style: 'seccionTablaCelda',
				text: detalle.edad
			},
			{
				style: 'seccionTablaCelda',
				text: detalle.ajusteFactor.toLocaleString('es-MX')
			},
			{
				style: 'seccionTablaCelda',
				text: detalle.ajusteMotivo?.nombre
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: formateador.format(detalle.valorResultante)
			}
		]);
	});
	if (detalles.length === 0) {
		renglones.push([
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: ''
			},
			{
				colSpan: 2,
				style: 'seccionTablaCelda',
				text: ''
			},
			{},
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: ''
			},
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: formateador.format(0)
			}
		]);
	}
	return [...tablaEncabezado, ...renglones];
};

const obtenerTablaTerreno = (detalles: Array<AvaluoTerrenoDetalle>) => {
	const tablaEncabezado = [
		[
			{
				style: 'seccionTablaEncabezado',
				text: 'Fracción'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Superficie'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Valor Unitario'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Factor'
			},
			{
				colSpan: 2,
				style: 'seccionTablaEncabezado',
				text: 'Motivo'
			},
			{},
			{
				style: 'seccionTablaEncabezado',
				text: 'Valor Neto'
			},
			{
				style: 'seccionTablaEncabezado',
				text: 'Valor Resultante'
			}
		]
	];
	const renglones: any = [];
	detalles.forEach((detalle) => {
		renglones.push([
			{
				style: 'seccionTablaCelda',
				text: convertirNumeroEnteroANumeroRomano(detalle.fraccion)
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: `${detalle.superficie.toLocaleString('es-MX')}${detalle.superficieUnidad?.simbolo}`
			},
			{
				style: 'seccionTablaCelda',
				text: formateador.format(detalle.valorUnitario)
			},
			{
				style: 'seccionTablaCelda',
				text: detalle.ajusteFactor.toLocaleString('es-MX')
			},
			{
				colSpan: 2,
				style: 'seccionTablaCelda',
				text: detalle.ajusteMotivo?.nombre
			},
			{},
			{
				style: 'seccionTablaCeldaDerecha',
				text: formateador.format(detalle.valorNeto)
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: formateador.format(detalle.valorResultante)
			}
		]);
	});
	if (detalles.length === 0) {
		renglones.push([
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: ''
			},
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				style: 'seccionTablaCelda',
				text: ''
			},
			{
				colSpan: 2,
				style: 'seccionTablaCelda',
				text: ''
			},
			{},
			{
				style: 'seccionTablaCeldaDerecha',
				text: ''
			},
			{
				style: 'seccionTablaCeldaDerecha',
				text: formateador.format(0)
			}
		]);
	}
	return [...tablaEncabezado, ...renglones];
};

const obtenerTablaValoresReferidos = (valoresReferidos?: Array<string> | null) => {
	let tablaValoresReferidos: Array<any> = [];
	if (valoresReferidos && valoresReferidos.length === 0) {
		tablaValoresReferidos = [
			[
				{
					border: [1, 0, 0, 0],
					margin: [0, 30, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: ''
				},
				{
					border: [0, 0, 0, 0],
					colSpan: 3,
					text: ''
				},
				{},
				{},
				{
					border: [0, 0, 0, 0],
					colSpan: 2,
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: ''
				},
				{},
				{
					border: [0, 0, 1, 0],
					colSpan: 2,
					text: ''
				},
				{}
			]
		];
	}
	if (valoresReferidos && valoresReferidos.length > 0 && valoresReferidos.length <= 6) {
		tablaValoresReferidos = [
			[
				{
					border: [1, 0, 0, 0],
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[0] ? `Valor Referido a ${valoresReferidos[0]}:` : ''
				},
				{
					border: [0, 0, 0, 0],
					colSpan: 3,
					text: ''
				},
				{},
				{},
				{
					border: [0, 0, 0, 0],
					colSpan: 2,
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[1] ? `Valor Referido a ${valoresReferidos[1]}:` : ''
				},
				{},
				{
					border: [0, 0, 1, 0],
					colSpan: 2,
					text: ''
				},
				{}
			],
			[
				{
					border: [1, 0, 0, 0],
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[2] ? `Valor Referido a ${valoresReferidos[2]}:` : ''
				},
				{
					border: [0, 0, 0, 0],
					colSpan: 3,
					text: ''
				},
				{},
				{},
				{
					border: [0, 0, 0, 0],
					colSpan: 2,
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[3] ? `Valor Referido a ${valoresReferidos[3]}:` : ''
				},
				{},
				{
					border: [0, 0, 1, 0],
					colSpan: 2,
					text: ''
				},
				{}
			],
			[
				{
					border: [1, 0, 0, 0],
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[4] ? `Valor Referido a ${valoresReferidos[4]}:` : ''
				},
				{
					border: [0, 0, 0, 0],
					colSpan: 3,
					text: ''
				},
				{},
				{},
				{
					border: [0, 0, 0, 0],
					colSpan: 2,
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[5] ? `Valor Referido a ${valoresReferidos[5]}:` : ''
				},
				{},
				{
					border: [0, 0, 1, 0],
					colSpan: 2,
					text: ''
				},
				{}
			]
		];
	}
	if (valoresReferidos && valoresReferidos.length > 6 && valoresReferidos.length <= 9) {
		tablaValoresReferidos = [
			[
				{
					border: [1, 0, 0, 0],
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[0] ? `Valor Referido a ${valoresReferidos[0]}:` : ''
				},
				{
					border: [0, 0, 0, 0],
					colSpan: 2,
					text: ''
				},
				{},
				{
					border: [0, 0, 0, 0],
					colSpan: 3,
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[1] ? `Valor Referido a ${valoresReferidos[1]}:` : ''
				},
				{},
				{},
				{
					border: [0, 0, 1, 0],
					colSpan: 2,
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[2] ? `Valor Referido a ${valoresReferidos[2]}:` : ''
				},
				{}
			],
			[
				{
					border: [1, 0, 0, 0],
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[3] ? `Valor Referido a ${valoresReferidos[3]}:` : ''
				},
				{
					border: [0, 0, 0, 0],
					colSpan: 2,
					text: ''
				},
				{},
				{
					border: [0, 0, 0, 0],
					colSpan: 3,
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[4] ? `Valor Referido a ${valoresReferidos[4]}:` : ''
				},
				{},
				{},
				{
					border: [0, 0, 1, 0],
					colSpan: 2,
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[5] ? `Valor Referido a ${valoresReferidos[5]}:` : ''
				},
				{}
			],
			[
				{
					border: [1, 0, 0, 0],
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[6] ? `Valor Referido a ${valoresReferidos[6]}:` : ''
				},
				{
					border: [0, 0, 0, 0],
					colSpan: 2,
					text: ''
				},
				{},
				{
					border: [0, 0, 0, 0],
					colSpan: 3,
					margin: [0, 0, 0, 10],
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[7] ? `Valor Referido a ${valoresReferidos[7]}:` : ''
				},
				{},
				{},
				{
					border: [0, 0, 1, 0],
					colSpan: 2,
					style: 'seccionTablaCeldaInformacion',
					text: valoresReferidos[8] ? `Valor Referido a ${valoresReferidos[8]}:` : ''
				},
				{}
			]
		];
	}
	return tablaValoresReferidos;
};

export default seccion;
