import { ChangeEvent } from 'react';

/**
 * Limpia el valor de un campo del formulario.
 * - ***argumentos*** - Argumentos del mutator de *React Final Form*.
 * > - ***campo*** - Campo del formulario que se cambiará.
 * > - ***valor*** - Valor que se asignará.
 * - ***estado*** - State del formulario de *React Final Form*.
 * - ***herramientas*** - Herramientas del formulario de *React Final Form*.
 */
export const mutatorCambiarValorCampo = (argumentos: any, estado: any, herramientas: any): void => {
	const [campo, valor] = argumentos;
	const { changeValue } = herramientas;
	changeValue(estado, campo, () => valor);
};

/**
 * Copia el valor de un campo del formulario y lo pega en otro.
 * - ***argumentos*** - Argumentos del mutator de *React Final Form*.
 * > - ***campoOrigen*** - Campo del formulario que se copiará.
 * > - ***campoDestino*** - Campo al que se copiará el valor del campo de origen.
 * - ***estado*** - State del formulario de *React Final Form*.
 * - ***herramientas*** - Herramientas del formulario de *React Final Form*.
 */
export const mutatorCopiarValorCampo = (argumentos: any, estado: any, herramientas: any): void => {
	const [campoOrigen, campoDestino] = argumentos;
	const { changeValue } = herramientas;
	const camposOrigen: Array<string> = campoOrigen.split('.');
	let valorOrigen = estado.formState.values[campoOrigen];
	if (camposOrigen.length > 0) {
		switch (camposOrigen.length) {
			case 5:
				valorOrigen = estado.formState.values[camposOrigen[0]][camposOrigen[1]][camposOrigen[2]][camposOrigen[3]][camposOrigen[4]];
				break;
			case 4:
				valorOrigen = estado.formState.values[camposOrigen[0]][camposOrigen[1]][camposOrigen[2]][camposOrigen[3]];
				break;
			case 3:
				valorOrigen = estado.formState.values[camposOrigen[0]][camposOrigen[1]][camposOrigen[2]];
				break;
			case 2:
				valorOrigen = estado.formState.values[camposOrigen[0]][camposOrigen[1]];
				break;
			default:
				valorOrigen = estado.formState.values[camposOrigen[0]];
				break;
		}
	}
	if (valorOrigen && Object.prototype.hasOwnProperty.call(valorOrigen, 'id')) {
		delete valorOrigen.id;
	}
	changeValue(estado, campoDestino, () => valorOrigen);
};

/**
 * Limpia el valor de un campo del formulario.
 * - ***argumentos*** - Argumentos del mutator de *React Final Form*.
 * > - ***campo*** - Campo del formulario que se limpiará.
 * - ***estado*** - State del formulario de *React Final Form*.
 * - ***herramientas*** - Herramientas del formulario de *React Final Form*.
 */
export const mutatorLimpiarValorCampo = (argumentos: any, estado: any, herramientas: any): void => {
	const [campo] = argumentos;
	const { changeValue } = herramientas;
	changeValue(estado, campo, () => null);
};

/**
 * Obtiene el valor seleccionado del evento onChange() de un McCampoFecha.
 * - ***evento*** - Evento que invoca la función.
 */
export const obtenerValorMcCampoFecha = (evento: ChangeEvent<HTMLSelectElement>): Date => {
	let valor = null;
	if (evento) {
		if ((evento as any)[0]) {
			[valor] = evento as any;
		}
	}
	return valor;
};

/**
 * Obtiene el valor seleccionado del evento onChange() de un McCampoSelector o McCampoSelectorMultiple.
 * - ***evento*** - Evento que invoca la función.
 */
export const obtenerValorMcCampoSelector = (evento: ChangeEvent<HTMLSelectElement>): string => {
	let valor = null;
	if (evento) {
		if (evento.target) {
			valor = (evento.target as any).value;
		} else {
			valor = (evento as any).value;
		}
	}
	return valor;
};

/**
 * Obtiene el valor seleccionado del evento onChange() de un McCampoTexto.
 * - ***evento*** - Evento que invoca la función.
 */
export const obtenerValorMcCampoTexto = (evento: ChangeEvent<HTMLElement>): string => {
	let valor = null;
	if (evento) {
		if (evento.target) {
			valor = (evento.target as any).value;
		}
	}
	return valor;
};

/**
 * Obtiene el valor seleccionado del evento onChange() de un McCheckbox.
 * - ***evento*** - Evento que invoca la función.
 */
export const obtenerValorMcCheckbox = (evento: ChangeEvent<HTMLElement>): string => {
	let valor = null;
	if (evento) {
		if (evento.target) {
			valor = (evento.target as any).checked;
		}
	}
	return valor;
};

/**
 * Regresa verdadero si la pestaña contiene errores de validación del formulario.
 * - ***camposPestana*** - Arreglo con todos los nombres de los campos de la pestaña.
 * - ***errores*** - Objeto con los errores del formulario de *React Final Form*.
 */
export const pestanaConErrores = ({ camposPestana, errores }: { camposPestana: Array<string>; errores: any }): boolean => {
	for (const propiedad in errores) {
		if (camposPestana.includes(propiedad)) {
			return true;
		}
	}
	return false;
};
