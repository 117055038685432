import aplicacion from 'configuracion/aplicacion';
import Avaluo from 'modelo/Avaluo';
import { convertirImagenUrlABase64 } from '@mcsoft/archivos';
import disenos from './disenos';

const seccion = (avaluo: Avaluo) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.medidas && avaluo.ubicacion) {
			const { superficieReal, superficieUnidad } = avaluo.medidas;
			const { distanciaEsquina, distanciaEsquinaCalle } = avaluo.ubicacion;
			convertirImagenUrlABase64(aplicacion.imagenes.rosaVientos).then((rosaVientosBase64) => {
				const contenido = [
					{
						layout: disenos.sinPaddingArribaAbajo,
						margin: [0, 10, 0, 0],
						style: 'seccion',
						table: {
							body: [
								[
									{
										style: 'seccionTitulo',
										text: 'LOCALIZACIÓN DEL PREDIO Y CROQUIS ESQUEMATICO SIN ESCALA DE LA CONSTRUCCIÓN'
									}
								],
								[
									{
										border: [1, 0, 1, 0],
										layout: disenos.sinPaddingArribaAbajo,
										margin: [0, 5, 0, 10],
										style: 'seccion',
										table: {
											body: [
												[
													{
														border: [0, 0, 0, 0],
														colSpan: 6,
														margin: [25, 0, 0, 0],
														style: { bold: true },
														text: 'Tramo de calle, Distancia a Esquina, Calles Transversales y Orientación.'
													},
													{},
													{},
													{},
													{},
													{},
													{
														border: [1, 1, 1, 1],
														image: rosaVientosBase64,
														margin: [0, 2, 0, 2],
														rowSpan: 5,
														style: { alignment: 'center' },
														width: 48
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													}
												],
												[
													{
														border: [0, 0, 0, 0],
														style: 'seccionEtiquetaNormal',
														text: 'Distancia a la Esquina:'
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [1, 1, 1, 1],
														style: { alignment: 'center', bold: true },
														text: distanciaEsquina ? `${distanciaEsquina.toLocaleString('es-MX')} m` : '0 m'
													},
													{
														border: [0, 0, 0, 0],
														style: 'seccionEtiquetaNormal',
														text: 'A la calle:'
													},
													{
														border: [0, 0, 0, 0],
														colSpan: 2,
														rowSpan: 2,
														style: { bold: true },
														text: distanciaEsquinaCalle
													},
													{},
													{},
													{
														border: [0, 0, 0, 0],
														text: ''
													}
												],
												[
													{
														border: [0, 0, 0, 0],
														margin: [0, 5, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{},
													{
														border: [0, 0, 0, 0],
														text: ''
													}
												],
												[
													{
														border: [0, 0, 0, 0],
														colSpan: 3,
														style: 'seccionEtiquetaNormal',
														text: 'Superficie Real según Levantamiento:'
													},
													{},
													{},
													{
														border: [1, 1, 1, 1],
														style: { alignment: 'center', bold: true },
														text: superficieReal ? superficieReal.toLocaleString('es-MX') : ''
													},
													{
														border: [0, 0, 0, 0],
														style: { alignment: 'left', bold: true },
														text: superficieUnidad?.simbolo
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{},
													{
														border: [0, 0, 0, 0],
														text: ''
													}
												],
												[
													{
														border: [0, 0, 0, 0],
														margin: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														text: ''
													},
													{},
													{
														border: [0, 0, 0, 0],
														text: ''
													}
												]
											],
											widths: [100, 10, 50, 50, 63, '*', 50, 10]
										}
									}
								],
								[
									{
										border: [1, 0, 1, 1],
										margin: [0, 0, 0, 0],
										table: {
											body: [
												[
													{
														border: [0, 0, 0, 0],
														margin: [0, 0, 0, 0],
														text: ''
													},
													obtenerMapa(avaluo),
													{
														border: [0, 0, 0, 0],
														margin: [0, 0, 0, 0],
														text: ''
													},
													obtenerCroquis(avaluo),
													{
														border: [0, 0, 0, 0],
														margin: [0, 0, 0, 0],
														text: ''
													}
												],
												[
													{
														border: [0, 0, 0, 0],
														margin: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														margin: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														margin: [0, 0, 0, 0],
														text: ''
													},
													{
														border: [0, 0, 0, 0],
														margin: [0, 0, 0, 0],
														style: 'croquisInformacion',
														text: 'PLANO CON BLOQUES DE CONSTRUCCION'
													},
													{
														border: [0, 0, 0, 0],
														margin: [0, 0, 0, 0],
														text: ''
													}
												]
											],
											heights: [270, '*'],
											widths: ['*', 240, '*', 240, '*']
										}
									}
								]
							],
							widths: ['*']
						}
					}
				];
				resolve(contenido);
			});
		}
	});

const obtenerCroquis = (avaluo: Avaluo) => {
	const { pdfCroquisBase64 } = avaluo;
	if (pdfCroquisBase64 && pdfCroquisBase64 !== 'error') {
		return {
			border: [0, 0, 0, 0],
			image: pdfCroquisBase64,
			margin: [0, 0, 0, 0],
			maxHeight: 250,
			style: { alignment: 'center' },
			width: 250
		};
	} else if (pdfCroquisBase64 && pdfCroquisBase64 === 'error') {
		return {
			alignment: 'center',
			border: [0, 0, 0, 0],
			height: 250,
			svg: `
				<svg>
					<text
					fill="rgba(204, 204, 204, 0.5)"
					font-weight="bold"
					style="font-size: 16px;"
					transform="translate(0, 5) rotate(30)"
					>
					-- ERROR AL OBTENER EL CROQUIS --
					</text>
				</svg>
				`,
			width: 250
		};
	} else {
		return {
			border: [0, 0, 0, 0],
			margin: [0, 0, 0, 0],
			maxHeight: 250,
			style: { alignment: 'center' },
			text: '',
			width: 250
		};
	}
};

const obtenerMapa = (avaluo: Avaluo) => {
	let { pdfMapaBase64 } = avaluo;
	const { pdfMapaPersonalizadoBase64 } = avaluo;
	if (pdfMapaPersonalizadoBase64 && pdfMapaPersonalizadoBase64 !== 'error') {
		pdfMapaBase64 = pdfMapaPersonalizadoBase64;
	}
	if (pdfMapaBase64 && pdfMapaBase64 !== 'error') {
		return {
			border: [0, 0, 0, 0],
			image: pdfMapaBase64,
			margin: [0, 0, 0, 0],
			maxHeight: 250,
			style: { alignment: 'center' },
			width: 250
		};
	} else if (pdfMapaBase64 && pdfMapaBase64 === 'error') {
		return {
			alignment: 'center',
			border: [0, 0, 0, 0],
			height: 250,
			svg: `
					<svg>
						<text
						fill="rgba(204, 204, 204, 0.5)"
						font-weight="bold"
						style="font-size: 16px;"
						transform="translate(0, 5) rotate(30)"
						>
						-- ERROR AL OBTENER EL MAPA --
						</text>
					</svg>
					`,
			width: 250
		};
	} else {
		return {
			border: [0, 0, 0, 0],
			margin: [0, 0, 0, 0],
			maxHeight: 250,
			style: { alignment: 'center' },
			text: '',
			width: 250
		};
	}
};

export default seccion;
