import { Col, Row } from 'reactstrap';
import { Fragment, useEffect, useState } from 'react';
import { McCampoSelector, McCampoSelectorOpcion, McCampoTexto } from '@mcsoft/formulario';
import { useDispatch, useSelector } from 'react-redux';
import constantes from 'configuracion/constantes';
import { formatearListaRolesMcCampoSelectorMultiple } from 'util/modelo/roles';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { obtenerUsuarioRolesTodos } from 'servicios/api/usuariosRoles';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { validarUsuarioDisponibilidad } from 'servicios/api/usuarios';

const NOMBRE_CLASE = 'UsuarioFormularioInformacionPersonal';

interface UsuarioFormularioInformacionPersonalProps {
	/**
	 * Indica si los detalles serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	detallesEditables?: boolean;
	/**
	 * Indica si el registro es el administrador del sistema.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	esSysAdmin?: boolean;
	/**
	 * Indica si el rol será editable o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	rolEditable?: boolean;
}

/**
 * Formulario para un la información personal de un usuario.
 */
const UsuarioFormularioInformacionPersonal = (props: UsuarioFormularioInformacionPersonalProps) => {
	const dispatch = useDispatch();
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const [opcionesRoles, setOpcionesRoles] = useState<Array<McCampoSelectorOpcion>>([]);
	const { detallesEditables = false, esSysAdmin = false, rolEditable = false } = props;

	useEffect(() => {
		obtenerListaRoles();
	}, []);

	/**
	 * Documentación pendiente.
	 */
	const obtenerListaRoles = async () => {
		const nombreMetodo = 'obtenerListaRoles';
		try {
			mcLogger.log({ mensaje: `Obteniendo lista de roles...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			const respuesta = await obtenerUsuarioRolesTodos(auth0AccessToken);
			mcLogger.log({ mensaje: `Lista de roles obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const { datos } = respuesta;
			let rolesLista = formatearListaRolesMcCampoSelectorMultiple(datos);
			if (esSysAdmin) {
				rolesLista = [{ nombre: 'Administrador del Sistema', valor: '' }];
			}
			setOpcionesRoles(rolesLista);
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener la lista de roles: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Válida el nombre de usuario.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioNombreUsuario = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarUsuarioDisponibilidad({ auth0AccessToken, campo: 'nombreUsuario', id: valores.id, valor });
		if (!disponible) {
			return 'Ya existe un usuario con ese nombre de usuario';
		}
	};

	return (
		<Fragment>
			<Row>
				<Col lg="6">
					<McCampoTexto
						campo="nombreUsuario"
						etiqueta={texto('Nombre de usuario')}
						funcionValidacionAsincrona={validarFormularioNombreUsuario}
						id="nombreUsuario"
						longitudMaxima={100}
						obligatorio
						soloLectura={!detallesEditables}
					/>
				</Col>
				<Col lg="6">
					<McCampoTexto campo="nombre" etiqueta={texto('Nombre')} id="nombre" longitudMaxima={100} soloLectura={!detallesEditables} />
				</Col>
				<Col lg="6">
					<McCampoTexto campo="apellidoPaterno" etiqueta={texto('Apellido Paterno')} id="apellidoPaterno" longitudMaxima={100} soloLectura={!detallesEditables} />
				</Col>
				<Col lg="6">
					<McCampoTexto campo="apellidoMaterno" etiqueta={texto('Apellido Materno')} id="apellidoMaterno" longitudMaxima={100} soloLectura={!detallesEditables} />
				</Col>
				<Col lg="6">
					<McCampoSelector campo="rolId" etiqueta={texto('Rol')} iconoIzquierda={constantes.icono.rol} id="rolId" obligatorio opciones={opcionesRoles} soloLectura={!rolEditable} />
				</Col>
			</Row>
		</Fragment>
	);
};

export default UsuarioFormularioInformacionPersonal;
