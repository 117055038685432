import {
	SET_VISOR_IMAGEN_IMAGENES,
	SET_VISOR_IMAGEN_MOSTRAR
} from './actionTypes';
import { McVisorImagenElemento } from '@mcsoft/visor-imagen';
import { ReduxAccion } from 'store/actions';

/**
 * Asigna las imágenes del visor de imágenes en el store de *Redux*.
 * - ***imagenes*** - Arreglo con las imágenes.
 */
export const setVisorImagenImagenesAction = (imagenes: Array<McVisorImagenElemento>): ReduxAccion => ({
	payload: { imagenes },
	type: SET_VISOR_IMAGEN_IMAGENES
});

/**
 * Muestra/Oculta el visor de imágenes.
 * - ***mostrar*** - Indica si se mostrará visor de imágenes.
 */
export const setVisorImagenMostrarAction = (mostrar: boolean): ReduxAccion => ({
	payload: { mostrar },
	type: SET_VISOR_IMAGEN_MOSTRAR
});