import { McRespuesta, procesarError, procesarRespuesta } from '@mcsoft/api';
import aplicacion from 'configuracion/aplicacion';
import Avaluo from 'modelo/Avaluo';
import axios from 'axios';
import mcLogger from '@mcsoft/logger';

const NOMBRE_CLASE = 'servicios/api/avaluo';

/**
 * Actualiza un avalúo existente en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del avalúo a actualizar.
 * - ***valores*** - Objeto con los valores a actualizar.
 */
export const actualizarAvaluo = ({ auth0AccessToken, id, valores }: { auth0AccessToken: string; id: string; valores: Avaluo }): Promise<McRespuesta> => {
	const nombreMetodo = 'actualizarAvaluo';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Actualizando el registro con el id '${id}' en la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: valores });
		axios
			.put(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.avaluos}/${id}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al actualizar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Crea un avalúo en la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***valores*** - Objeto con los valores del avalúo a crear.
 */
export const crearAvaluo = ({ auth0AccessToken, valores }: { auth0AccessToken: string; valores: Avaluo }): Promise<McRespuesta> => {
	const nombreMetodo = 'crearAvaluo';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Creando registro en la base de datos con los siguientes valores:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: valores });
		axios
			.post(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.avaluos}
		`,
				valores,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al crear el registro:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Elimina un avalúo de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del avalúo a eliminar.
 */
export const eliminarAvaluo = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'eliminarAvaluo';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Eliminando el registro con el id '${id}'.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.delete(
				`
		${aplicacion.api.servidor}/
		${aplicacion.api.version}/
		${aplicacion.api.recurso.avaluos}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' eliminado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al eliminar el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Obtiene un avalúo de la base de datos.
 * - ***auth0AccessToken*** - Token de Auth0.
 * - ***id*** - Id del avalúo a obtener.
 */
export const obtenerAvaluoPorId = ({ auth0AccessToken, id }: { auth0AccessToken: string; id: string }): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerAvaluoPorId';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({ mensaje: `Obteniendo registro con el id '${id}' de la base de datos.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.avaluos}/${id}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registro con el id '${id}' obtenido con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener el registro con el id '${id}':`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Filtra y ordena los avalúos y los separa en páginas según los parámetros recibidos y obtiene la lista de avalúos de la página especificada.
 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerAvaluosPaginados = ({
	auth0AccessToken,
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina
}: {
	auth0AccessToken: string;
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerAvaluosPaginados';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo registros de la base de datos con los siguientes parámetros:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: {
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			}
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.avaluos}/paginados
			?pagina=${pagina}
			&registrosPorPagina=${registrosPorPagina}
			&ordenamiento=${ordenamiento}
			&orden=${orden}
			&criterio=${criterio}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};

/**
 * Filtra y ordena los avalúos relacionados a un usuario y los separa en páginas según los parámetros recibidos y obtiene la lista de avalúos de la página especificada.
 * - ***usuarioId*** - Id del usuario relacionado a los avalúos.
 * - ***parametrosPaginacion*** - Objeto con los parámetros de paginación.
 * - ***auth0AccessToken*** - Token de Auth0.
 */
export const obtenerAvaluosPaginadosPorUsuario = ({
	auth0AccessToken,
	criterio,
	orden,
	ordenamiento,
	pagina,
	registrosPorPagina,
	usuarioId
}: {
	auth0AccessToken: string;
	criterio?: string;
	orden?: string;
	ordenamiento?: string;
	pagina?: number;
	registrosPorPagina?: number;
	usuarioId: string;
}): Promise<McRespuesta> => {
	const nombreMetodo = 'obtenerAvaluosPaginadosPorUsuario';
	return new Promise((resolve, reject) => {
		const configuracionPeticion = {
			headers: { Authorization: `Bearer ${auth0AccessToken}` }
		};
		mcLogger.api({
			mensaje: `Obteniendo registros de la base de datos con los siguientes parámetros:`,
			nombreArchivo: NOMBRE_CLASE,
			nombreMetodo,
			objetoExtra: {
				criterio,
				orden,
				ordenamiento,
				pagina,
				registrosPorPagina
			}
		});
		axios
			.get(
				`
			${aplicacion.api.servidor}/
			${aplicacion.api.version}/
			${aplicacion.api.recurso.avaluos}/paginados/${usuarioId}
			?pagina=${pagina}
			&registrosPorPagina=${registrosPorPagina}
			&ordenamiento=${ordenamiento}
			&orden=${orden}
			&criterio=${criterio}
		`,
				configuracionPeticion
			)
			.then((respuesta) => {
				const mcRespuesta = procesarRespuesta(respuesta);
				mcLogger.api({ mensaje: `Registros obtenidos con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcRespuesta });
				resolve(mcRespuesta);
			})
			.catch((error) => {
				const mcError = procesarError(error);
				mcLogger.error({ mensaje: `Error al obtener los registros:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError });
				reject(mcError);
			});
	});
};
