import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useHistory, useLocation } from 'react-router';
import constantes from 'configuracion/constantes';
import DomicilioFormulario from 'componentes/domicilio/DomicilioFormulario';
import { pestanaConErrores } from '@mcsoft/formulario';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';
import UsuarioFormularioContacto from './UsuarioFormularioContacto';
import UsuarioFormularioInformacionPersonal from './UsuarioFormularioInformacionPersonal';

const PestanaTipo = {
	CONTACTO: 'contacto',
	DOMICILIO: 'domicilio',
	INFORMACION_PERSONAL: 'informacionPersonal'
};

interface UsuarioFormularioProps {
	/**
	 * Indica si el correo electrónico será editable o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	correoElectronicoEditable?: boolean;
	/**
	 * Indica si los detalles serán editables o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	detallesEditables?: boolean;
	/**
	 * Indica si el registro es el administrador del sistema.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	esSysAdmin?: boolean;
	/**
	 * Indica si el rol será editable o solo de lectura *(No podrá editarse su valor)*.
	 *
	 * > ***Predeterminado:*** *false*
	 */
	rolEditable?: boolean;
}

/**
 * Formulario para un usuario.
 */
const UsuarioFormulario = (props: UsuarioFormularioProps) => {
	const history = useHistory();
	const location = useLocation();
	const formulario = useForm();
	const formularioValores = useFormState();
	const [pestanaActiva, setPestanaActiva] = useState(PestanaTipo.INFORMACION_PERSONAL);
	const [erroresPestanaInformacionPersonal, setErroresPestanaInformacionPersonal] = useState(false);
	const [erroresPestanaContacto, setErroresPestanaContacto] = useState(false);
	const [erroresPestanaDomicilio, setErroresPestanaDomicilio] = useState(false);
	const { datosUsuarioDomicilioEntrega, datosUsuarioDomicilioFacturacion } = useSelector((state: StateType) => state.configuracionSistema);
	const { correoElectronicoEditable = false, detallesEditables = false, esSysAdmin = false, rolEditable = false } = props;

	useEffect(() => {
		const pestana = new URLSearchParams(location.search).get('pestana');
		setPestanaActiva(pestana ? pestana : PestanaTipo.INFORMACION_PERSONAL);
	}, [location]);

	useEffect(() => {
		setErroresPestanaInformacionPersonal(
			pestanaConErrores({
				camposPestana: ['nombreUsuario', 'nombre', 'apellidoPaterno', 'apellidoMaterno', 'rolId'],
				errores: formularioValores.errors
			})
		);
		setErroresPestanaContacto(
			pestanaConErrores({
				camposPestana: ['correoElectronico', 'correoElectronicoConfirmacion', 'telefono', 'urlFacebook', 'urlInstagram', 'urlLinkedIn', 'urlTwitter', 'urlYouTube'],
				errores: formularioValores.errors
			})
		);
		setErroresPestanaDomicilio(
			pestanaConErrores({
				camposPestana: ['calle', 'numeroExterior', 'numeroInterior', 'colonia', 'codigoPostal', 'municipio', 'ciudad', 'estado', 'pais'],
				errores: formularioValores.errors
			})
		);
	}, [formularioValores.errors]);

	/**
	 * Limpia el formulario del domicilio principal.
	 */
	const eventoLimpiarDomicilioPrincipal = () => {
		const { limpiarValorCampo } = formulario.mutators;
		limpiarValorCampo('domicilio');
	};

	/**
	 * Copia la información del domicilio principal y lo pega en el domicilio de entrega.
	 */
	const eventoCopiarDomicilioEntrega = () => {
		const { copiarValorCampo } = formulario.mutators;
		copiarValorCampo('domicilio', 'domicilioEntrega');
	};

	/**
	 * Limpia el formulario del domicilio de entrega.
	 */
	const eventoLimpiarDomicilioEntrega = () => {
		const { limpiarValorCampo } = formulario.mutators;
		limpiarValorCampo('domicilioEntrega');
	};

	/**
	 * Copia la información del domicilio principal y lo pega en el domicilio de facturación.
	 */
	const eventoCopiarDomicilioFacturacion = () => {
		const { copiarValorCampo } = formulario.mutators;
		copiarValorCampo('domicilio', 'domicilioFacturacion');
	};

	/**
	 * Limpia el formulario del domicilio de facturación.
	 */
	const eventoLimpiarDomicilioFacturacion = () => {
		const { limpiarValorCampo } = formulario.mutators;
		limpiarValorCampo('domicilioFacturacion');
	};

	return (
		<Row>
			<Col lg="12">
				<Nav tabs>
					<NavItem>
						<NavLink
							className={pestanaActiva === PestanaTipo.INFORMACION_PERSONAL ? 'active' : ''}
							onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.INFORMACION_PERSONAL}`)}
							style={{ cursor: 'pointer' }}
						>
							<span className={`d-block d-sm-none ${erroresPestanaInformacionPersonal ? 'link-danger' : ''}`}>
								<i className={constantes.icono.id}></i>
							</span>
							<span className={`d-none d-sm-block  ${erroresPestanaInformacionPersonal ? 'link-danger' : ''}`}>
								<i className={constantes.icono.id}></i> {texto('Información personal')}
							</span>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={pestanaActiva === PestanaTipo.CONTACTO ? 'active' : ''}
							onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.CONTACTO}`)}
							style={{ cursor: 'pointer' }}
						>
							<span className={`d-block d-sm-none ${erroresPestanaContacto ? 'link-danger' : ''}`}>
								<i className={constantes.icono.contacto}></i>
							</span>
							<span className={`d-none d-sm-block  ${erroresPestanaContacto ? 'link-danger' : ''}`}>
								<i className={constantes.icono.contacto}></i> {texto('Contacto')}
							</span>
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink
							className={pestanaActiva === PestanaTipo.DOMICILIO ? 'active' : ''}
							onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.DOMICILIO}`)}
							style={{ cursor: 'pointer' }}
						>
							<span className={`d-block d-sm-none ${erroresPestanaDomicilio ? 'link-danger' : ''}`}>
								<i className={constantes.icono.domicilio}></i>
							</span>
							<span className={`d-none d-sm-block  ${erroresPestanaDomicilio ? 'link-danger' : ''}`}>
								<i className={constantes.icono.domicilio}></i> {texto('Domicilio')}
							</span>
						</NavLink>
					</NavItem>
				</Nav>
				<TabContent activeTab={pestanaActiva} className="p-3 text-muted">
					<TabPane className="p-3" tabId={PestanaTipo.INFORMACION_PERSONAL}>
						<UsuarioFormularioInformacionPersonal detallesEditables={detallesEditables} esSysAdmin={esSysAdmin} rolEditable={rolEditable} />
					</TabPane>
					<TabPane className="p-3" tabId={PestanaTipo.CONTACTO}>
						<UsuarioFormularioContacto correoElectronicoEditable={correoElectronicoEditable} detallesEditables={detallesEditables} />
					</TabPane>
					<TabPane className="p-3" tabId={PestanaTipo.DOMICILIO}>
						<Row>
							<Col md="12">
								<h4>{texto('Domicilio Principal')}</h4>
								<DomicilioFormulario detallesEditables={detallesEditables} nombreFormulario="domicilio" />
								<div className="pagina__barra-herramientas">
									<button className="btn btn-link" onClick={eventoLimpiarDomicilioPrincipal} type="button">
										{texto('Limpiar')}
									</button>
								</div>
							</Col>
						</Row>
						{datosUsuarioDomicilioFacturacion && (
							<Row>
								<Col md="12">
									<hr />
									<h4>{texto('Domicilio de Facturación')}</h4>
									<DomicilioFormulario detallesEditables={detallesEditables} nombreFormulario="domicilioFacturacion" />
									<div className="pagina__barra-herramientas">
										<button className="btn btn-link" onClick={eventoCopiarDomicilioFacturacion} type="button">
											{texto('Copiar domicilio principal')}
										</button>
										<button className="btn btn-link" onClick={eventoLimpiarDomicilioFacturacion} type="button">
											{texto('Limpiar')}
										</button>
									</div>
								</Col>
							</Row>
						)}
						{datosUsuarioDomicilioEntrega && (
							<Row>
								<Col md="12">
									<hr />
									<h4>{texto('Domicilio de Entrega')}</h4>
									<DomicilioFormulario detallesEditables={detallesEditables} nombreFormulario="domicilioEntrega" />
									<div className="pagina__barra-herramientas">
										<button className="btn btn-link" onClick={eventoCopiarDomicilioEntrega} type="button">
											{texto('Copiar domicilio principal')}
										</button>
										<button className="btn btn-link" onClick={eventoLimpiarDomicilioEntrega} type="button">
											{texto('Limpiar')}
										</button>
									</div>
								</Col>
							</Row>
						)}
					</TabPane>
				</TabContent>
			</Col>
		</Row>
	);
};

export default UsuarioFormulario;
