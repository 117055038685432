import './AvaluoDetalles.scss';
import { ButtonDropdown, Card, CardBody, CardHeader, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, Row } from 'reactstrap';
import { useClonarAvaluo, useEliminarAvaluo, useGenerarAvaluoPdfContenido } from 'hooks/useAvaluo';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import AvaluoDetallesAvaluoFisico from 'componentes/avaluos/AvaluoDetallesAvaluoFisico';
import AvaluoDetallesDescripcion from 'componentes/avaluos/AvaluoDetallesDescripcion';
import AvaluoDetallesFotos from 'componentes/avaluos/AvaluoDetallesFotos';
import AvaluoDetallesGeneral from 'componentes/avaluos/AvaluoDetallesGeneral';
import AvaluoDetallesUbicacion from 'componentes/avaluos/AvaluoDetallesUbicacion';
import AvaluoFormularioClonar from 'componentes/avaluos/AvaluoFormularioClonar';
import AvaluoFormularioPdf from 'componentes/avaluos/AvaluoFormularioPdf';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { obtenerAvaluoPorId } from 'servicios/api/avaluos';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import pdfMake from 'pdfmake/build/pdfmake';
import { procesarError } from '@mcsoft/api';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';
import { useEsSeccionAdministracion } from 'hooks/useSeccion';
import UsuarioPreferencias from 'modelo/UsuarioPreferencias';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const NOMBRE_CLASE = 'paginas/app/avaluos/AvaluoDetalles';

const PestanaTipo = {
	AVALUO_FISICO: 'avaluoFisico',
	DESCRIPCION: 'descripcion',
	FOTOS: 'fotos',
	GENERAL: 'general',
	UBICACION: 'ubicacion'
};

/**
 * En esta página se muestra la información detallada del rol.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const esSeccionAdministracion = useEsSeccionAdministracion();
	const clonarAvaluo = useClonarAvaluo();
	const eliminarAvaluo = useEliminarAvaluo();
	const generarAvaluoPdfContenido = useGenerarAvaluoPdfContenido();
	const [modalClonar, setModalClonar] = useState<boolean>(false);
	const [modalEliminar, setModalEliminar] = useState<boolean>(false);
	const [modalPdfDescargar, setModalPdfDescargar] = useState<boolean>(false);
	const [modalPdfGenerar, setModalPdfGenerar] = useState<boolean>(false);
	const [dropdownPdfDescargar, setDropdownPdfDescargar] = useState<boolean>(false);
	const [dropdownPdfGenerar, setDropdownPdfGenerar] = useState<boolean>(false);
	const [pestanaActiva, setPestanaActiva] = useState(PestanaTipo.GENERAL);
	const { sesion } = useSelector((state: StateType) => state);
	const [registro, setRegistro] = useState<any>();
	const { auth0, usuario } = sesion;
	const { preferencias } = usuario;
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.avaluos);
	const {
		criterio: criterioAdmin,
		orden: ordenAdmin,
		ordenamiento: ordenamientoAdmin,
		pagina: paginaAdmin,
		registrosPorPagina: registrosPorPaginaAdmin
	} = useSelector((state: StateType) => state.parametrosPaginacion.avaluosAdmin);
	const { auth0AccessToken } = auth0;
	const { id } = useParams<any>();
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [rutaSalida, setRutaSalida] = useState<string>(
		`${constantes.ruta.appAvaluosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`
	);
	const [enlaces, setEnlaces] = useState<any>([]);

	useEffect(() => {
		obtenerRegistro();
		obtenerVariablesDeSeccion();
	}, []);

	useEffect(() => {
		const pestana = new URLSearchParams(location.search).get('pestana');
		setPestanaActiva(pestana ? pestana : PestanaTipo.GENERAL);
		obtenerVariablesDeSeccion();
	}, [location]);

	/**
	 * Dibuja el modal de clonacion.
	 */
	const dibujarModalClonar = () => (
		<Modal
			centered={true}
			isOpen={modalClonar}
			toggle={() => {
				setModalClonar(modalClonar);
			}}
		>
			<Form
				initialValues={{ ...preferencias }}
				onSubmit={eventoClonar}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<AvaluoFormularioClonar />
						<BarraHerramientas>
							<button
								className="btn btn-secondary"
								id="botonCancelar"
								onClick={() => {
									setModalClonar(false);
								}}
								type="button"
							>
								<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
							</button>
							<button className="btn btn-dark" id="botonClonar" type="submit">
								<i className={constantes.icono.clonar}></i> {texto('Clonar')}
							</button>
						</BarraHerramientas>
					</form>
				)}
			/>
		</Modal>
	);

	/**
	 * Dibuja el modal de eliminación.
	 */
	const dibujarModalEliminar = () => (
		<Modal
			centered={true}
			isOpen={modalEliminar}
			toggle={() => {
				setModalEliminar(modalEliminar);
			}}
		>
			<div className="modal-header border-danger bg-danger">
				<h5 className="modal-title mt-0 text-white">{texto('Eliminar Avalúo')}</h5>
			</div>
			<div className="modal-body border-danger">
				<p>
					{texto('Se eliminará el avalúo, esta operación ')}
					<b>{texto('no puede deshacerse.')}</b>
				</p>
				<p>{texto('¿Estas seguro de continuar con la eliminación del avalúo?')}</p>
			</div>
			<BarraHerramientas>
				<button
					className="btn btn-secondary"
					id="botonCancelar"
					onClick={() => {
						setModalEliminar(false);
					}}
					type="button"
				>
					<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
				</button>
				<button className="btn btn-danger" id="botonEliminar" onClick={eventoEliminar} type="button">
					<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
				</button>
			</BarraHerramientas>
		</Modal>
	);

	/**
	 * Dibuja el modal de descargar PDF.
	 */
	const dibujarModalPdfDescargar = () => (
		<Modal
			centered={true}
			isOpen={modalPdfDescargar}
			toggle={() => {
				setModalPdfDescargar(modalPdfDescargar);
			}}
		>
			<Form
				initialValues={{ ...preferencias }}
				onSubmit={(valores) => eventoGenerarPdf({ descargar: true, formato: 'personalizado', valores })}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<AvaluoFormularioPdf />
						<BarraHerramientas>
							<button
								className="btn btn-secondary"
								id="botonCancelarDescargar"
								onClick={() => {
									setModalPdfDescargar(false);
								}}
								type="button"
							>
								<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
							</button>
							<button className="btn btn-soft-secondary" id="botonDescargarPdfPersonalizado" type="submit">
								<i className={constantes.icono.archivoPdf}></i> {texto('Descargar')}
							</button>
						</BarraHerramientas>
					</form>
				)}
			/>
		</Modal>
	);

	/**
	 * Dibuja el modal de generar PDF.
	 */
	const dibujarModalPdfGenerar = () => (
		<Modal
			centered={true}
			isOpen={modalPdfGenerar}
			toggle={() => {
				setModalPdfGenerar(modalPdfGenerar);
			}}
		>
			<Form
				initialValues={{ ...preferencias }}
				onSubmit={(valores) => eventoGenerarPdf({ formato: 'personalizado', valores })}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<AvaluoFormularioPdf />
						<BarraHerramientas>
							<button
								className="btn btn-secondary"
								id="botonCancelarGenerar"
								onClick={() => {
									setModalPdfGenerar(false);
								}}
								type="button"
							>
								<i className={constantes.icono.cancelar}></i> {texto('Cancelar')}
							</button>
							<button className="btn btn-soft-secondary" id="botonGenerarPdfPersonalizado" type="submit">
								<i className={constantes.icono.archivoPdf}></i> {texto('Generar')}
							</button>
						</BarraHerramientas>
					</form>
				)}
			/>
		</Modal>
	);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Redirecciona al usuario a la ruta de clonacion.
	 */
	const eventoClonar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'eventoClonar';
		try {
			dispatch(setPantallaCargaMostrarAction(true));
			mcLogger.log({ mensaje: `Clonando avalúo.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const avaluoClonado = await clonarAvaluo({
				avaluo: registro,
				opciones: valores as UsuarioPreferencias,
				usuario
			});
			mcLogger.log({ mensaje: `Avalúo clonado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
			let ruta;
			if (esSeccionAdministracion()) {
				ruta = `${constantes.ruta.appAdministracionAvaluosDetallesEditar}/${avaluoClonado.id}`;
			} else {
				ruta = `${constantes.ruta.appAvaluosDetallesEditar}/${avaluoClonado.id}`;
			}
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
			history.push(ruta);
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Redirecciona al usuario a la ruta de edición.
	 */
	const eventoEditarDetalles = () => {
		const nombreMetodo = 'eventoEditarDetalles';
		let ruta;
		if (esSeccionAdministracion()) {
			ruta = `${constantes.ruta.appAdministracionAvaluosDetallesEditar}/${id}`;
		} else {
			ruta = `${constantes.ruta.appAvaluosDetallesEditar}/${id}`;
		}
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: ruta });
		history.push(ruta);
	};

	/**
	 * Elimina el registro.
	 */
	const eventoEliminar = async () => {
		const nombreMetodo = 'eventoEliminar';
		mcLogger.log({ mensaje: `Eliminando el avalúo.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
		dispatch(setPantallaCargaMostrarAction(true));
		setModalEliminar(false);
		try {
			await eliminarAvaluo(registro);
			mcNotificaciones.exito({ mensaje: texto('Avalúo eliminado con exito.') });
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
			history.push(rutaSalida);
		} catch (error) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Genera el PDF del avalúo.
	 */
	const eventoGenerarPdf = async ({
		descargar = false,
		formato,
		valores
	}: {
		descargar?: boolean;
		formato: 'general' | 'personalizado' | 'zapopan';
		valores?: Record<string, any>;
	}) => {
		const nombreMetodo = 'eventoGenerarPdf';
		try {
			mcLogger.log({ mensaje: `Generando PDF del avaluo...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			const avaluoPdf = await generarAvaluoPdfContenido({
				avaluo: registro,
				formato,
				opciones: valores as UsuarioPreferencias,
				usuario
			});
			if (descargar) {
				pdfMake.createPdf(avaluoPdf.contenido).download(avaluoPdf.titulo);
			} else {
				pdfMake.createPdf(avaluoPdf.contenido).open();
			}
			setModalPdfDescargar(false);
			setModalPdfGenerar(false);
			mcLogger.log({ mensaje: `PDF del avaluo generado correctamente.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.log({ mensaje: `Error al general PDF del avaluo.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			mcLogger.error({ mensaje: `Error :`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Obteniendo información del avaluo...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			const respuesta = await obtenerAvaluoPorId({ auth0AccessToken, id });
			mcLogger.log({ mensaje: `Información del avaluo obtenida con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			const { datos } = respuesta;
			setRegistro(datos);
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al obtener el avaluo: `, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el título de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionAdministracion()) {
			setTituloSeccion(texto('Detalles del avalúo'));
			const rutaSalida = `${constantes.ruta.appAdministracionAvaluosLista}?pagina=${paginaAdmin}&registrosPorPagina=${registrosPorPaginaAdmin}&ordenamiento=${ordenamientoAdmin}&orden=${ordenAdmin}&criterio=${criterioAdmin}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Lista de avalúos') }
			]);
		} else {
			setTituloSeccion(texto('Detalles de mi avalúo'));
			const rutaSalida = `${constantes.ruta.appAvaluosLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Mis avalúos') }
			]);
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={constantes.icono.avaluo} titulo={tituloSeccion} />
			<Card>
				<CardHeader>
					<BarraHerramientas>
						{((esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionAvaluosEliminar, usuario })) ||
							(!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAvaluosEliminar, usuario }))) && (
							<button
								className="btn btn-danger"
								id="botonEliminarAvaluo"
								onClick={() => {
									setModalEliminar(true);
								}}
								type="button"
							>
								<i className={constantes.icono.eliminar}></i> {texto('Eliminar')}
							</button>
						)}
						{dibujarModalEliminar()}
						{((esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionAvaluosClonar, usuario })) ||
							(!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAvaluosClonar, usuario }))) && (
							<button
								className="btn btn-dark"
								id="botonClonarAvaluo"
								onClick={() => {
									setModalClonar(true);
								}}
								type="button"
							>
								<i className={constantes.icono.clonar}></i> {texto('Clonar')}
							</button>
						)}
						{dibujarModalClonar()}
						{((esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionAvaluosGenerarPdf, usuario })) ||
							(!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAvaluosGenerarPdf, usuario }))) && (
							<ButtonDropdown
								isOpen={dropdownPdfGenerar}
								toggle={() => {
									setDropdownPdfGenerar(!dropdownPdfGenerar);
								}}
							>
								<DropdownToggle caret color="secondary">
									<i className={constantes.icono.archivoPdf}></i> {registro?.estatus === 'incompleto' ? texto('Vista Previa PDF') : texto('Generar PDF')}{' '}
									<i className="mdi mdi-chevron-down"></i>
								</DropdownToggle>
								<DropdownMenu>
									<DropdownItem
										onClick={() => {
											eventoGenerarPdf({ formato: 'general' });
										}}
									>
										{texto('Formato General')}
									</DropdownItem>
									<DropdownItem
										onClick={() => {
											eventoGenerarPdf({ formato: 'zapopan' });
										}}
									>
										{texto('Formato de Zapopan')}
									</DropdownItem>
									<DropdownItem onClick={() => setModalPdfGenerar(true)}>{texto('Formato Personalizado')}</DropdownItem>
								</DropdownMenu>
							</ButtonDropdown>
						)}
						{registro?.estatus === 'completo' &&
							((esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionAvaluosGenerarPdf, usuario })) ||
								(!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAvaluosGenerarPdf, usuario }))) && (
								<ButtonDropdown
									isOpen={dropdownPdfDescargar}
									toggle={() => {
										setDropdownPdfDescargar(!dropdownPdfDescargar);
									}}
								>
									<DropdownToggle caret color="secondary" outline>
										<i className={constantes.icono.archivoPdfAlt}></i> {texto('Descargar PDF')} <i className="mdi mdi-chevron-down"></i>
									</DropdownToggle>
									<DropdownMenu>
										<DropdownItem
											onClick={() => {
												eventoGenerarPdf({ descargar: true, formato: 'general' });
											}}
										>
											{texto('Formato General')}
										</DropdownItem>
										<DropdownItem
											onClick={() => {
												eventoGenerarPdf({ descargar: true, formato: 'zapopan' });
											}}
										>
											{texto('Formato de Zapopan')}
										</DropdownItem>
										<DropdownItem onClick={() => setModalPdfDescargar(true)}>{texto('Formato Personalizado')}</DropdownItem>
									</DropdownMenu>
								</ButtonDropdown>
							)}
						{dibujarModalPdfGenerar()}
						{dibujarModalPdfDescargar()}
						{((esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAdministracionAvaluosEditarDetalles, usuario })) ||
							(!esSeccionAdministracion() && tienePermiso({ permiso: constantes.permiso.appAvaluosEditarDetalles, usuario }))) && (
							<button className="btn btn-info" id="botonEditarAvaluo" onClick={eventoEditarDetalles} type="button">
								<i className={constantes.icono.editar}></i> {texto('Editar detalles')}
							</button>
						)}
						<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
							<i className={constantes.icono.atras}></i> {texto('Salir')}
						</button>
					</BarraHerramientas>
				</CardHeader>
				<CardBody>
					<Row>
						<Col lg="3" md="3" sm="3" xl="2" xs="12">
							<div className="flex-column nav nav-pills">
								<button
									className={`nav-link ${pestanaActiva === PestanaTipo.GENERAL ? ' active' : ''}`}
									id="pestanaGeneral"
									onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.GENERAL}`)}
									type="button"
								>
									{texto('General')}
								</button>
								<button
									className={`nav-link ${pestanaActiva === PestanaTipo.DESCRIPCION ? ' active' : ''}`}
									id="pestanaDescripcion"
									onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.DESCRIPCION}`)}
									type="button"
								>
									{texto('Descripción')}
								</button>
								<button
									className={`nav-link ${pestanaActiva === PestanaTipo.UBICACION ? ' active' : ''}`}
									id="pestanaUbicacion"
									onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.UBICACION}`)}
									type="button"
								>
									{texto('Ubicación')}
								</button>
								<button
									className={`nav-link ${pestanaActiva === PestanaTipo.FOTOS ? ' active' : ''}`}
									id="pestanaFotos"
									onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.FOTOS}`)}
									type="button"
								>
									{texto('Reporte Fotográfico')}
								</button>
								<button
									className={`nav-link ${pestanaActiva === PestanaTipo.AVALUO_FISICO ? ' active' : ''}`}
									id="pestanaAvaluoFisico"
									onClick={() => history.push(`${location.pathname}?pestana=${PestanaTipo.AVALUO_FISICO}`)}
									type="button"
								>
									{texto('Avalúo Físico')}
								</button>
							</div>
						</Col>
						<Col lg="9" md="9" sm="9" xl="10" xs="12">
							<div className="tab-content">
								<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.GENERAL ? ' show active' : ''}`} id="contenidoGeneral">
									<AvaluoDetallesGeneral avaluo={registro} usuario={usuario} />
								</div>
								<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.DESCRIPCION ? ' show active' : ''}`} id="contenidoDescripcion">
									<AvaluoDetallesDescripcion avaluo={registro} />
								</div>
								<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.UBICACION ? ' show active' : ''}`} id="contenidoUbicacion">
									<AvaluoDetallesUbicacion avaluo={registro} />
								</div>
								<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.FOTOS ? ' show active' : ''}`} id="contenidoFotos">
									<AvaluoDetallesFotos avaluo={registro} />
								</div>
								<div className={`tab-pane fade ${pestanaActiva === PestanaTipo.AVALUO_FISICO ? ' show active' : ''}`} id="contenidoAvaluoFisico">
									<AvaluoDetallesAvaluoFisico avaluo={registro} />
								</div>
							</div>
						</Col>
					</Row>
				</CardBody>
			</Card>
		</Contenedor>
	);
};

export default Pagina;
