import aplicacion from 'configuracion/aplicacion';
import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información del código de valuador.
 */
const InformacionAvaluoCodigoValuador = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Codigo personalizado del valuador que aparecerá bajo el folio del avalúo en el PDF.')}</p>
		</div>
		<div className="text-center mt-2 mb-2">
			<img alt="Ejemplo" src={aplicacion.tutoriales.ejemploAvaluoCodigoValuador} />
		</div>
	</Fragment>
);

export default InformacionAvaluoCodigoValuador;
