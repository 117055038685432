import { Col, Row } from 'reactstrap';
import Avaluo from 'modelo/Avaluo';
import { Fragment } from 'react';
import { texto } from 'idiomas';

interface AvaluoDetallesElementosConstruccionProps {
	/**
	 * Objeto con la información del avalúo.
	 */
	avaluo: Avaluo;
}

/**
 * Detalles para la sección descripción del avalúo.
 */
const AvaluoDetallesElementosConstruccion = (props: AvaluoDetallesElementosConstruccionProps) => {
	const { avaluo } = props;
	if (avaluo && avaluo.elementosConstruccion && avaluo.escritura && avaluo.medidas) {
		const {
			aplanado,
			azotea,
			barda,
			carpinteria,
			cerrajeria,
			cimientos,
			entrepiso,
			escalera,
			estructura,
			fachada,
			herreria,
			instalacionElectrica,
			instalacionEspecial,
			instalacionSanitaria,
			lambrin,
			muebleBano,
			muebleCocina,
			muro,
			pavimentoPetreo,
			pintura,
			plafon,
			recubrimientoEspecial,
			techo,
			vidrieria,
			zoclo
		} = avaluo.elementosConstruccion;
		const { descripcion: descripcionEscritura } = avaluo.escritura;
		const {
			calidadProyecto,
			colindancia1Descripcion,
			colindancia1Viento,
			colindancia2Descripcion,
			colindancia2Viento,
			colindancia3Descripcion,
			colindancia3Viento,
			colindancia4Descripcion,
			colindancia4Viento,
			colindancia5Descripcion,
			colindancia5Viento,
			colindancia6Descripcion,
			colindancia6Viento,
			colindancia7Descripcion,
			colindancia7Viento,
			colindancia8Descripcion,
			colindancia8Viento,
			colindanciaSeparador,
			destinoEspecifico,
			destinoEspecifico2,
			edad,
			indiviso,
			superficieCatastro,
			superficieEscritura,
			superficieUnidad,
			unidadesRentables,
			usoEspecifico,
			usoEspecifico2,
			vidaRemanenteProbale
		} = avaluo.medidas;

		return (
			<Fragment>
				<Row>
					<Col lg="12">
						<h3>{texto('Descripción General del Predio')}</h3>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Medidas y colindancias del predio según')}:</span>
							<span className="avaluo-detalles__valor">{descripcionEscritura}</span>
						</p>
						<br />
						{colindancia1Viento && colindancia1Descripcion && (
							<p>
								<span className="avaluo-detalles__etiqueta">{`Al ${colindancia1Viento?.nombre}`}:</span>
								<span className="avaluo-detalles__valor">{colindancia1Descripcion}</span>
							</p>
						)}
						{colindancia2Viento && colindancia2Descripcion && (
							<p>
								<span className="avaluo-detalles__etiqueta">{`Al ${colindancia2Viento?.nombre}`}:</span>
								<span className="avaluo-detalles__valor">{colindancia2Descripcion}</span>
							</p>
						)}
						{colindancia3Viento && colindancia3Descripcion && (
							<p>
								<span className="avaluo-detalles__etiqueta">{`Al ${colindancia3Viento?.nombre}`}:</span>
								<span className="avaluo-detalles__valor">{colindancia3Descripcion}</span>
							</p>
						)}
						{colindancia4Viento && colindancia4Descripcion && (
							<p>
								<span className="avaluo-detalles__etiqueta">{`Al ${colindancia4Viento?.nombre}`}:</span>
								<span className="avaluo-detalles__valor">{colindancia4Descripcion}</span>
							</p>
						)}
						{colindanciaSeparador && (
							<p>
								<b>{colindanciaSeparador}</b>
							</p>
						)}
						{colindancia5Viento && colindancia5Descripcion && (
							<p>
								<span className="avaluo-detalles__etiqueta">{`Al ${colindancia5Viento?.nombre}`}:</span>
								<span className="avaluo-detalles__valor">{colindancia5Descripcion}</span>
							</p>
						)}
						{colindancia6Viento && colindancia6Descripcion && (
							<p>
								<span className="avaluo-detalles__etiqueta">{`Al ${colindancia6Viento?.nombre}`}:</span>
								<span className="avaluo-detalles__valor">{colindancia6Descripcion}</span>
							</p>
						)}
						{colindancia7Viento && colindancia7Descripcion && (
							<p>
								<span className="avaluo-detalles__etiqueta">{`Al ${colindancia7Viento?.nombre}`}:</span>
								<span className="avaluo-detalles__valor">{colindancia7Descripcion}</span>
							</p>
						)}
						{colindancia8Viento && colindancia8Descripcion && (
							<p>
								<span className="avaluo-detalles__etiqueta">{`Al ${colindancia8Viento?.nombre}`}:</span>
								<span className="avaluo-detalles__valor">{colindancia8Descripcion}</span>
							</p>
						)}
						<br />
					</Col>
					<Col lg="6">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Superficie según escrituras')}:</span>
							<span className="avaluo-detalles__valor">{superficieEscritura ? `${superficieEscritura} ${superficieUnidad?.simbolo}` : ''}</span>
						</p>
					</Col>
					<Col lg="6">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Superficie según catastro')}:</span>
							<span className="avaluo-detalles__valor">{superficieCatastro ? `${superficieCatastro} ${superficieUnidad?.simbolo}` : ''}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Indiviso')}:</span>
							<span className="avaluo-detalles__valor">{indiviso ? `${indiviso} %` : ''}</span>
						</p>
					</Col>
					<Col lg="6">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Uso especifico')}:</span>
							<span className="avaluo-detalles__valor">
								{usoEspecifico ? `${usoEspecifico.nombre}` : ''}
								{usoEspecifico2 ? ` / ${usoEspecifico2.nombre}` : ''}
							</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Unidades rentables')}:</span>
							<span className="avaluo-detalles__valor">{unidadesRentables ? `${unidadesRentables}` : ''}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Destino especifico de las construcciones')}:</span>
							<span className="avaluo-detalles__valor">
								{destinoEspecifico ? `${destinoEspecifico.nombre}` : ''}
								{destinoEspecifico2 ? ` / ${destinoEspecifico2.nombre}` : ''}
							</span>
						</p>
					</Col>
					<Col lg="6">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Edad')}:</span>
							<span className="avaluo-detalles__valor">{edad ? `${edad} años` : ''}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Vida remanente probable')}:</span>
							<span className="avaluo-detalles__valor">{vidaRemanenteProbale ? `${vidaRemanenteProbale} años o más` : ''}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Calidad del proyecto')}:</span>
							<span className="avaluo-detalles__valor">{calidadProyecto ? `${calidadProyecto}` : ''}</span>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h3>{texto('A) Obra negra o Gruesa')}</h3>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Cimientos')}:</span>
							<span className="avaluo-detalles__valor">{cimientos}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Estructura')}:</span>
							<span className="avaluo-detalles__valor">{estructura}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Muros')}:</span>
							<span className="avaluo-detalles__valor">{muro}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Bardas')}:</span>
							<span className="avaluo-detalles__valor">{barda}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Entrepisos')}:</span>
							<span className="avaluo-detalles__valor">{entrepiso}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Techos')}:</span>
							<span className="avaluo-detalles__valor">{techo}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Azoteas')}:</span>
							<span className="avaluo-detalles__valor">{azotea}</span>
						</p>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<hr />
						<h3>{texto('B) Revestimiento y Acabados')}</h3>
					</Col>
				</Row>
				<Row>
					<Col lg="12">
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Aplanados')}:</span>
							<span className="avaluo-detalles__valor">{aplanado}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Plafones')}:</span>
							<span className="avaluo-detalles__valor">{plafon}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Lambrines')}:</span>
							<span className="avaluo-detalles__valor">{lambrin}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Pavimentos Pétreos')}:</span>
							<span className="avaluo-detalles__valor">{pavimentoPetreo}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Zoclo')}:</span>
							<span className="avaluo-detalles__valor">{zoclo}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Pintura')}:</span>
							<span className="avaluo-detalles__valor">{pintura}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Recubrimientos Especiales')}:</span>
							<span className="avaluo-detalles__valor">{recubrimientoEspecial}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Escaleras')}:</span>
							<span className="avaluo-detalles__valor">{escalera}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('C) Carpintería')}:</span>
							<span className="avaluo-detalles__valor">{carpinteria}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('D) Instalación Sanitaria')}:</span>
							<span className="avaluo-detalles__valor">{instalacionSanitaria}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('E) Muebles de Baño')}:</span>
							<span className="avaluo-detalles__valor">{muebleBano}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('Muebles de Cocina')}:</span>
							<span className="avaluo-detalles__valor">{muebleCocina}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('F) Instalación Eléctrica')}:</span>
							<span className="avaluo-detalles__valor">{instalacionElectrica}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('G) Herrería')}:</span>
							<span className="avaluo-detalles__valor">{herreria}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('H) Vidrería')}:</span>
							<span className="avaluo-detalles__valor">{vidrieria}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('I) Cerrajería')}:</span>
							<span className="avaluo-detalles__valor">{cerrajeria}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('J) Fachada')}:</span>
							<span className="avaluo-detalles__valor">{fachada}</span>
						</p>
						<p>
							<span className="avaluo-detalles__etiqueta">{texto('K) Instalaciones Especiales')}:</span>
							<span className="avaluo-detalles__valor">{instalacionEspecial}</span>
						</p>
					</Col>
				</Row>
			</Fragment>
		);
	} else {
		return null;
	}
};

export default AvaluoDetallesElementosConstruccion;
