import Avaluo from 'modelo/Avaluo';
import disenos from './disenos';

const seccion = (avaluo: Avaluo) =>
	new Promise((resolve) => {
		if (avaluo && avaluo.elementosConstruccion) {
			const {
				aplanado,
				azotea,
				barda,
				carpinteria,
				cerrajeria,
				cimientos,
				entrepiso,
				escalera,
				estructura,
				fachada,
				herreria,
				instalacionElectrica,
				instalacionEspecial,
				instalacionSanitaria,
				lambrin,
				muebleBano,
				muebleCocina,
				muro,
				pavimentoPetreo,
				pintura,
				plafon,
				recubrimientoEspecial,
				techo,
				vidrieria,
				zoclo
			} = avaluo.elementosConstruccion;
			const contenido = [
				{
					layout: disenos.sinPaddingArribaAbajo,
					margin: [0, 10, 0, 0],
					style: 'seccion',
					table: {
						body: [
							[
								{
									style: 'seccionTitulo',
									text: 'ELEMENTOS DE LA CONSTRUCCIÓN'
								}
							],
							[
								{
									border: [1, 1, 1, 1],
									layout: {
										...disenos.sinBordes,
										...disenos.sinPadding
									},
									margin: [0, 5, 0, 0],
									style: 'seccion',
									table: {
										body: [
											[
												{
													style: 'seccionEtiqueta',
													text: 'A) Obra Negra o Gruesa:'
												},
												{
													text: ''
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Cimientos:'
												},
												{
													text: cimientos
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Estructura:'
												},
												{
													text: estructura
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Muros:'
												},
												{
													text: muro
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Bardas:'
												},
												{
													text: barda
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Entrepisos:'
												},
												{
													text: entrepiso
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Techos:'
												},
												{
													text: techo
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Azoteas:'
												},
												{
													text: azotea
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'B) Revestimientos y Acabados:'
												},
												{
													text: ''
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Aplanados:'
												},
												{
													text: aplanado
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Plafones:'
												},
												{
													text: plafon
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Lambrines:'
												},
												{
													text: lambrin
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Pavimentos Pétreos:'
												},
												{
													text: pavimentoPetreo
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Zoclo:'
												},
												{
													text: zoclo
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Pintura:'
												},
												{
													text: pintura
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Rec. Especiales:'
												},
												{
													text: recubrimientoEspecial
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Escaleras:'
												},
												{
													text: escalera
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'C) Carpintería:'
												},
												{
													text: carpinteria
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'D) Instalación Sanitaria:'
												},
												{
													text: instalacionSanitaria
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'E) Muebles de Baño:'
												},
												{
													text: muebleBano
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'Mueble de Cocina:'
												},
												{
													text: muebleCocina
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'F) Instalación Eléctrica:'
												},
												{
													text: instalacionElectrica
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'G) Herrería:'
												},
												{
													text: herreria
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'H) Vidriería:'
												},
												{
													text: vidrieria
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'I) Cerrajería:'
												},
												{
													text: cerrajeria
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'J) Fachada:'
												},
												{
													text: fachada
												}
											],
											[
												{
													style: 'seccionEtiqueta',
													text: 'K) Inst. Especiales:'
												},
												{
													text: instalacionEspecial
												}
											]
										],
										heights: [20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20, 20],
										widths: [115, '*']
									}
								}
							]
						],
						widths: ['*']
					}
				}
			];
			resolve(contenido);
		}
	});

export default seccion;
