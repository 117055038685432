import { Col, Row } from 'reactstrap';
import { McCampoFecha, McCampoTexto } from '@mcsoft/formulario';
import constantes from 'configuracion/constantes';
import InformacionTratamientoProtocolario from 'componentes/informacion/InformacionTratamientoProtocolario';
import Perito from 'modelo/Perito';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';
import { validarCorreoElectronico } from '@mcsoft/validaciones';

interface PeritoFormularioProps {
	/**
	 * Objeto con la información del perito*.
	 */
	registro?: Perito;
}

/**
 * Formulario para un perito.
 */
const PeritoFormulario = (props: PeritoFormularioProps) => {
	const { registro } = props;
	const { selectorFechaFormato } = useSelector((state: StateType) => state.configuracionAplicacion);

	/**
	 * Válida el correo electrónico.
	 * - ***valor*** - Valor del correo a validar.
	 */
	const validarFormularioCorreoElectronico = async ({ valor }: { valor: string }) => {
		const valido = validarCorreoElectronico(valor);
		if (!valido) {
			return 'El correo electrónico es inválido.';
		}
	};

	return (
		<Row>
			<Col md="12">
				<Row>
					<h3>{texto('Datos personales')}</h3>
					<Col md="4">
						<McCampoTexto
							campo="tratamientoProtocolario"
							etiqueta={texto('Tratamiento protocolario')}
							id="tratamientoProtocolario"
							informacion={<InformacionTratamientoProtocolario />}
							longitudMaxima={20}
						/>
					</Col>
					<Col md="8">
						<McCampoTexto campo="nombre" etiqueta={texto('Nombre')} id="nombre" longitudMaxima={100} obligatorio />
					</Col>
					<Col md="6">
						<McCampoTexto campo="apellidoPaterno" etiqueta={texto('Apellido paterno')} id="apellidoPaterno" longitudMaxima={100} obligatorio />
					</Col>
					<Col md="6">
						<McCampoTexto campo="apellidoMaterno" etiqueta={texto('Apellido materno')} id="apellidoMaterno" longitudMaxima={100} obligatorio />
					</Col>
					<Col md="6" xl="4">
						<McCampoTexto
							campo="correoElectronico"
							etiqueta={texto('Correo electrónico')}
							funcionValidacionAsincrona={validarFormularioCorreoElectronico}
							iconoIzquierda={constantes.icono.correoElectronico}
							id="correoElectronico"
							longitudMaxima={100}
						/>
					</Col>
					<Col md="6" xl="3">
						<McCampoTexto
							campo="telefono"
							etiqueta={texto('Teléfono')}
							iconoIzquierda={constantes.icono.telefono}
							id="telefono"
							longitudMaxima={10}
							longitudMinima={10}
							longitudNoMostrar
							obligatorio
							tipo="telefono"
						/>
					</Col>
					<hr />
					<h3>{texto('Licencia')}</h3>
					<Col md="4" xl="2">
						<McCampoTexto campo="licenciaNumeroRegistro" etiqueta={texto('No. Registro')} id="licenciaNumeroRegistro" longitudMaxima={10} obligatorio tipo="numero" />
					</Col>
					<Col md="6" xl="3">
						<McCampoFecha
							campo="licenciaVigencia"
							etiqueta={texto('Vigencia')}
							fechaInicial={registro ? registro.licenciaVigencia : undefined}
							formatoFecha={selectorFechaFormato}
							id="licenciaVigencia"
							obligatorio
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default PeritoFormulario;
