import { Col, Row } from 'reactstrap';
import InformacionAvaluoAntecedentes from 'componentes/informacion/InformacionAvaluoAntecedentes';
import InformacionAvaluoCaracteristicas from 'componentes/informacion/InformacionAvaluoCaracteristicas';
import InformacionAvaluoContrucciones from 'componentes/informacion/InformacionAvaluoContrucciones';
import InformacionAvaluoDistribucion from 'componentes/informacion/InformacionAvaluoDistribucion';
import InformacionAvaluoElementosConstruccion from 'componentes/informacion/InformacionAvaluoElementosConstruccion';
import InformacionAvaluoFotos from 'componentes/informacion/InformacionAvaluoFotos';
import InformacionAvaluoGeneral from 'componentes/informacion/InformacionAvaluoGeneral';
import InformacionAvaluoInstalacionesEspeciales from 'componentes/informacion/InformacionAvaluoInstalacionesEspeciales';
import InformacionAvaluoObservaciones from 'componentes/informacion/InformacionAvaluoObservaciones';
import InformacionAvaluoTerrenos from 'componentes/informacion/InformacionAvaluoTerrenos';
import InformacionAvaluoUbicacion from 'componentes/informacion/InformacionAvaluoUbicacion';
import { McSwitchRedondo } from '@mcsoft/formulario';
import { texto } from 'idiomas';

/**
 * Formulario para las opciones de clonado de avalúos.
 */
const AvaluoFormularioClonar = () => (
	<>
		<div className="modal-header border-dark bg-dark">
			<h5 className="modal-title mt-0 text-white">{texto('Clonar Avalúo')}</h5>
		</div>
		<div className="modal-body border-dark">
			<p>{texto('Selecciona las partes del avalúo que deseas copiar al nuevo avalúo')}</p>
		</div>
		<Row className="ms-3">
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosClonarAntecedentes"
					etiqueta={texto('Clonar antecedentes')}
					etiquetaLineal
					id="avaluosClonarAntecedentes"
					informacion={InformacionAvaluoAntecedentes}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosClonarCaracteristicas"
					etiqueta={texto('Clonar características')}
					etiquetaLineal
					id="avaluosClonarCaracteristicas"
					informacion={InformacionAvaluoCaracteristicas}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo campo="avaluosClonarGeneral" etiqueta={texto('Clonar general')} etiquetaLineal id="avaluosClonarGeneral" informacion={InformacionAvaluoGeneral} />
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosClonarDistribucion"
					etiqueta={texto('Clonar distribución')}
					etiquetaLineal
					id="avaluosClonarDistribucion"
					informacion={InformacionAvaluoDistribucion}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosClonarElementosConstruccion"
					etiqueta={texto('Clonar elementos de construcción')}
					etiquetaLineal
					id="avaluosClonarElementosConstruccion"
					informacion={InformacionAvaluoElementosConstruccion}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo campo="avaluosClonarUbicacion" etiqueta={texto('Clonar ubicación')} etiquetaLineal id="avaluosClonarUbicacion" informacion={InformacionAvaluoUbicacion} />
			</Col>
			<Col md="12">
				<McSwitchRedondo campo="avaluosClonarFotos" etiqueta={texto('Clonar fotos')} etiquetaLineal id="avaluosClonarFotos" informacion={InformacionAvaluoFotos} />
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosClonarDetallesTerrenos"
					etiqueta={texto('Clonar detalles de los terrenos')}
					etiquetaLineal
					id="avaluosClonarDetallesTerrenos"
					informacion={InformacionAvaluoTerrenos}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosClonarDetallesConstrucciones"
					etiqueta={texto('Clonar detalles de las construcciones')}
					etiquetaLineal
					id="avaluosClonarDetallesConstrucciones"
					informacion={InformacionAvaluoContrucciones}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosClonarDetallesInstalacionesEspeciales"
					etiqueta={texto('Clonar detalles de las instalaciones especiales')}
					etiquetaLineal
					id="avaluosClonarDetallesInstalacionesEspeciales"
					informacion={InformacionAvaluoInstalacionesEspeciales}
				/>
			</Col>
			<Col md="12">
				<McSwitchRedondo
					campo="avaluosClonarObservaciones"
					etiqueta={texto('Clonar observaciones')}
					etiquetaLineal
					id="avaluosClonarObservaciones"
					informacion={InformacionAvaluoObservaciones}
				/>
			</Col>
		</Row>
	</>
);

export default AvaluoFormularioClonar;
