/* eslint-disable no-unused-vars */
import './style.scss';
import { Card, Row } from 'reactstrap';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import { arrayMoveImmutable } from 'array-move';

const ICONO_EDITAR = 'fa-solid fa-edit';
const ICONO_ELIMINAR = 'fa-solid fa-trash';
const ICONO_MOVER = 'fa-solid fa-bars';
const TEXTO = {
	editar: 'Editar',
	eliminar: 'Eliminar'
};

/**
 * Interfaz para un elemeneto del McGaleriaOrdenableImagen.
 */
export interface McGaleriaOrdenableElemento {
	/**
	 * Descripción de la imagen.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	descripcion?: string;
	/**
	 * Identificador único de la imagen.
	 */
	id: string;
	/**
	 * Título de la imagen.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	titulo?: string;
	/**
	 * URL de la imagen.
	 */
	url: string;
	/**
	 * URL de la miniatura de la imagen.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	urlMiniatura?: string;
}

interface McGaleriaOrdenableProps {
	/**
	 * Evento que se ejecuta cuando se hace click en el botón editar de una imagen.
	 * - ***indice*** - Indice de la imagen.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Nota:** Si no se especifica este evento, la imagen no podrá editarse.
	 */
	eventoEditar?: (indice: number) => void;
	/**
	 * Evento que se ejecuta cuando se hace click en el botón eliminar de una imagen.
	 * - ***indice*** - Indice de la imagen.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Nota:** Si no se especifica este evento, la imagen no podrá eliminarse.
	 */
	eventoEliminar?: (indice: number) => void;
	/**
	 * Evento que se ejecuta cuando se reordena la lista.
	 * - ***listaReordenada*** - Lista de imágenes reordenada.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Nota:** Si no se especifica este evento, la galería no podrá reordenarse.
	 */
	eventoReordenar?: (listaReordenada: Array<McGaleriaOrdenableElemento>) => void;
	/**
	 * Evento que se ejecuta cuando se hace click sobre una imagen.
	 * - ***indice*** - Indice de la imagen.
	 *
	 * > ***Predeterminado:*** *undefined*
	 *
	 * **Nota:** Si no se especifica este evento, la imagen no podrá verse en grande.
	 */
	eventoVerImagen?: (indice: number) => void;
	/**
	 * Altura máxima de la imagen en pixeles.
	 *
	 * > ***Predeterminado:*** *150*
	 */
	alturaMaxima?: number;
	/**
	 * Ancho máximo de la imagen en pixeles.
	 *
	 * > ***Predeterminado:*** *150*
	 */
	anchoMaximo?: number;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón editar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-edit'*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > eventoEditar != undefined
	 */
	iconoEditar?: string;
	/**
	 * Icono *FontAwesome* que se mostrará en el botón eliminar.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-trash'*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > eventoEliminar != undefined
	 */
	iconoEliminar?: string;
	/**
	 * Icono *FontAwesome* que se mostrará en la agarradera que permite mover la imagen.
	 *
	 * > ***Predeterminado:*** *'fa-solid fa-bars'*
	 *
	 * **Atributo Condicional:** Para que este atributo surta efecto deben cumplirse las siguientes condiciones.
	 *
	 * > eventoReordenar != undefined
	 */
	iconoMover?: string;
	/**
	 * Lista de imágenes.
	 */
	lista: Array<McGaleriaOrdenableElemento>;
	/**
	 * Objeto con los textos personalizados del componente.
	 *
	 * > ***Predeterminado:*** *undefined*
	 */
	texto?: McGaleriaOrdenableTexto;
}

export interface McGaleriaOrdenableTexto {
	/**
	 * Texto que se mostrará en el boton editar.
	 *
	 * > ***Predeterminado:*** *'Editar'*
	 */
	editar?: string;
	/**
	 * Texto que se mostrará en el boton eliminar.
	 *
	 * > ***Predeterminado:*** *'Eliminar'*
	 */
	eliminar?: string;
}

const AgarraderaImagen = SortableHandle((props: any) => {
	const { iconoMover } = props;
	return (
		<div className="mc-lista-ordenable__elemento-agarradera">
			<i className={iconoMover}></i>
		</div>
	);
});

const ImagenOrdenable = SortableElement((props: any) => {
	const { alturaMaxima, anchoMaximo, elemento, eventoEditar, eventoEliminar, eventoReordenar, eventoVerImagen, iconoEditar, iconoEliminar, iconoMover, indice, texto } = props;
	return (
		<Card
			className="mc-lista-ordenable__elemento-contenedor"
			style={{
				maxHeight: alturaMaxima + 55,
				maxWidth: anchoMaximo,
				minHeight: 150,
				minWidth: 150
			}}
		>
			{eventoReordenar && <AgarraderaImagen {...{ iconoMover: iconoMover }} />}
			<div
				className="mc-lista-ordenable__elemento-marco"
				onClick={eventoVerImagen ? () => eventoVerImagen(indice) : () => {}}
				style={{
					cursor: eventoVerImagen ? 'zoom-in' : 'default',
					maxHeight: alturaMaxima,
					maxWidth: anchoMaximo
				}}
			>
				<span>
					<img src={elemento.urlMiniatura ? elemento.urlMiniatura : elemento.url} style={{ maxHeight: alturaMaxima, maxWidth: anchoMaximo }} />
				</span>
			</div>
			<div className="btn-group">
				{eventoEditar && (
					<button className="btn btn-outline-info btn-sm mc-lista-ordenable__elemento-boton--izquierdo" onClick={() => eventoEditar(indice)} type="button">
						<i className={iconoEditar}></i> {texto.editar}
					</button>
				)}
				{eventoEliminar && (
					<button className="btn btn-outline-danger btn-sm mc-lista-ordenable__elemento-boton--derecho" onClick={() => eventoEliminar(indice)} type="button">
						<i className={iconoEliminar}></i> {texto.eliminar}
					</button>
				)}
			</div>
		</Card>
	);
});

const ContenedorGaleria = SortableContainer((props: any) => {
	const { children } = props;
	return <Row>{children}</Row>;
});

/**
 * Muestra una galería de imágenes que pueden ser reordenadas, editadas y eliminadas.
 */
const McGaleriaOrdenable = (props: McGaleriaOrdenableProps) => {
	const {
		alturaMaxima = 150,
		anchoMaximo = 150,
		eventoEditar,
		eventoEliminar,
		eventoReordenar,
		eventoVerImagen,
		iconoEditar = ICONO_EDITAR,
		iconoEliminar = ICONO_ELIMINAR,
		iconoMover = ICONO_MOVER,
		lista
	} = props;
	let { texto } = props;
	texto = { ...TEXTO, ...texto };

	const eventoSoltar = ({ newIndex, oldIndex }: { oldIndex: number; newIndex: number }): void => {
		if (eventoReordenar) {
			const listaReordenada = arrayMoveImmutable(lista, oldIndex, newIndex);
			eventoReordenar(listaReordenada);
		}
	};

	return (
		<ContenedorGaleria {...{ axis: 'xy', onSortEnd: eventoSoltar, useDragHandle: true }}>
			{lista.map((elemento, indice) => (
				<ImagenOrdenable
					key={`item-${elemento.id}`}
					{...{
						alturaMaxima: alturaMaxima,
						anchoMaximo: anchoMaximo,
						elemento: elemento,
						eventoEditar: eventoEditar,
						eventoEliminar: eventoEliminar,
						eventoReordenar: eventoReordenar,
						eventoVerImagen: eventoVerImagen,
						iconoEditar: iconoEditar,
						iconoEliminar: iconoEliminar,
						iconoMover: iconoMover,
						index: indice,
						indice: indice,
						texto: texto
					}}
				/>
			))}
		</ContenedorGaleria>
	);
};

export default McGaleriaOrdenable;
