import { Fragment } from 'react';
import { texto } from 'idiomas';

/**
 * Contenido del tooltip de información de las imagenes predeterminadas.
 */
const InformacionImagenPredeterminada = () => (
	<Fragment>
		<div className="text-start">
			<p>{texto('Si no se especifica una URL, la aplicación utilizara la imagen predefinida por el sistema.')}</p>
		</div>
	</Fragment>
);

export default InformacionImagenPredeterminada;
