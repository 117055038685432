export interface CoordenadasDD {
	latitud: number;
	longitud: number;
}

export interface CoordenadasDM {
	latitud: {
		cardinal: string;
		grados: number;
		minutos: number;
	};
	longitud: {
		cardinal: string;
		grados: number;
		minutos: number;
	};
}

export interface CoordenadasDMCadena {
	latitud: string;
	longitud: string;
}

export interface CoordenadasDMS {
	latitud: {
		cardinal: string;
		grados: number;
		minutos: number;
		segundos: number;
	};
	longitud: {
		cardinal: string;
		grados: number;
		minutos: number;
		segundos: number;
	};
}

export interface CoordenadasDMSCadena {
	latitud: string;
	longitud: string;
}

export interface CoordenadasUTM {
	esteX: number;
	huso: number;
	norteY: number;
	zonaSimple: string;
	zonaUTM: string;
}

interface Elipsoide {
	achatamiento: number;
	fecha: number;
	id: number;
	nombre: string;
	semiejeMayor: number;
	semiejeMenor: number;
}

interface ZonaUTM {
	latitudMaxima: number;
	latitudMinima: number;
	zonaSimple: string;
	zonaUTM: string;
}

/**
 * Convierte coordenadas DD (Decimal Degree) a DM (Degree:Minute).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDD.
 */
export const convertirCoordenadasDDaDM = (coordenadas: CoordenadasDD): CoordenadasDM => {
	const { latitud, longitud } = coordenadas;
	const latitudCardinal = latitud < 0 ? 'S' : 'N';
	const latitudAbsoluta = Math.abs(latitud);
	const latitudGrados = Math.floor(latitudAbsoluta);
	const latitudMinutos = (latitudAbsoluta - latitudGrados) * 60;
	const longitudCardinal = longitud < 0 ? 'O' : 'E';
	const longitudAbsoluta = Math.abs(longitud);
	const longitudGrados = Math.floor(longitudAbsoluta);
	const longitudMinutos = (longitudAbsoluta - longitudGrados) * 60;
	return {
		latitud: {
			cardinal: latitudCardinal,
			grados: latitudGrados,
			minutos: Number(latitudMinutos.toFixed(2))
		},
		longitud: {
			cardinal: longitudCardinal,
			grados: longitudGrados,
			minutos: Number(longitudMinutos.toFixed(2))
		}
	};
};

/**
 * Convierte coordenadas DD (Decimal Degree) a DMS (Degree:Minute:Second).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDD.
 */
export const convertirCoordenadasDDaDMS = (coordenadas: CoordenadasDD): CoordenadasDMS => {
	const { latitud, longitud } = coordenadas;
	const latitudCardinal = latitud < 0 ? 'S' : 'N';
	const latitudAbsoluta = Math.abs(latitud);
	const latitudGrados = Math.floor(latitudAbsoluta);
	const latitudMinutosConDecimales = (latitudAbsoluta - latitudGrados) * 60;
	const latitudMinutos = Math.floor(latitudMinutosConDecimales);
	const latitudSegundos = (latitudMinutosConDecimales - latitudMinutos) * 60;
	const longitudCardinal = longitud < 0 ? 'O' : 'E';
	const longitudAbsoluta = Math.abs(longitud);
	const longitudGrados = Math.floor(longitudAbsoluta);
	const longitudMinutosConDecimales = (longitudAbsoluta - longitudGrados) * 60;
	const longitudMinutos = Math.floor(longitudMinutosConDecimales);
	const longitudSegundos = (longitudMinutosConDecimales - longitudMinutos) * 60;
	return {
		latitud: {
			cardinal: latitudCardinal,
			grados: latitudGrados,
			minutos: latitudMinutos,
			segundos: Number(latitudSegundos.toFixed(2))
		},
		longitud: {
			cardinal: longitudCardinal,
			grados: longitudGrados,
			minutos: longitudMinutos,
			segundos: Number(longitudSegundos.toFixed(2))
		}
	};
};

/**
 * Convierte coordenadas DD (Decimal Degree) a UTM (Universal Transverse Mercator).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDD.
 * - ***elipsoideId*** - Id del elipsoide que se utilizará para la conversión.
 *
 * > ***Predeterminado:*** *22*
 *
 * > **Valores Admitidos**
 * > - **1:** Airy 1830.
 * > - **2:** Airy Modificado 1965.
 * > - **3:** Bessel 1841.
 * > - **4:** Clarke 1866.
 * > - **5:** Clarke 1880.
 * > - **6:** Fischer 1960.
 * > - **7:** Fischer 1968.
 * > - **8:** GRS80.
 * > - **9:** Hayford 1909.
 * > - **10:** Helmert 1906.
 * > - **11:** Hough 1960
 * > - **12:** Internacional 1909.
 * > - **13:** Internacional 1924.
 * > - **14:** Krasovsky 1940.
 * > - **15:** Mercury 1960.
 * > - **16:** Mercury Modificado 1968.
 * > - **17:** Nuevo International 1967.
 * > - **18:** Sudamericano 1969.
 * > - **19:** Walbeck 1817.
 * > - **20:** WGS66.
 * > - **21:** WGS72.
 * > - **22:** WGS84.
 */
export const convertirCoordenadasDDaUTM = (coordenadas: CoordenadasDD, elipsoideId = 22): CoordenadasUTM => {
	const coordenadasDMS = convertirCoordenadasDDaDMS(coordenadas);
	return convertirCoordenadasDMSaUTM(coordenadasDMS, elipsoideId);
};

/**
 * Convierte coordenadas DMS (Degree:Minute:Second) a DD (Decimal Degree).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDMS.
 */
export const convertirCoordenadasDMSaDD = (coordenadas: CoordenadasDMS): CoordenadasDD => {
	const { latitud, longitud } = coordenadas;
	let latitudDD = latitud.grados + latitud.minutos / 60 + latitud.segundos / 3600;
	let longitudDD = longitud.grados + longitud.minutos / 60 + longitud.segundos / 3600;
	if (latitud.cardinal === 'S') {
		latitudDD = -1 * latitudDD;
	}
	if (longitud.cardinal === 'O') {
		longitudDD = -1 * longitudDD;
	}
	return {
		latitud: latitudDD,
		longitud: longitudDD
	};
};

/**
 * Convierte coordenadas DMS (Degree:Minute:Second) a DM (Degree:Minute).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDMS.
 */
export const convertirCoordenadasDMSaDM = (coordenadas: CoordenadasDMS): CoordenadasDM => {
	const coordenadasDD = convertirCoordenadasDMSaDD(coordenadas);
	return convertirCoordenadasDDaDM(coordenadasDD);
};

/**
 * Convierte coordenadas DMS (Degree:Minute:Second) a UTM (Universal Transverse Mercator).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDMS.
 * - ***elipsoideId*** - Id del elipsoide que se utilizará para la conversión.
 *
 * > ***Predeterminado:*** *22*
 *
 * > **Valores Admitidos**
 * > - **1:** Airy 1830.
 * > - **2:** Airy Modificado 1965.
 * > - **3:** Bessel 1841.
 * > - **4:** Clarke 1866.
 * > - **5:** Clarke 1880.
 * > - **6:** Fischer 1960.
 * > - **7:** Fischer 1968.
 * > - **8:** GRS80.
 * > - **9:** Hayford 1909.
 * > - **10:** Helmert 1906.
 * > - **11:** Hough 1960
 * > - **12:** Internacional 1909.
 * > - **13:** Internacional 1924.
 * > - **14:** Krasovsky 1940.
 * > - **15:** Mercury 1960.
 * > - **16:** Mercury Modificado 1968.
 * > - **17:** Nuevo International 1967.
 * > - **18:** Sudamericano 1969.
 * > - **19:** Walbeck 1817.
 * > - **20:** WGS66.
 * > - **21:** WGS72.
 * > - **22:** WGS84.
 */
export const convertirCoordenadasDMSaUTM = (coordenadas: CoordenadasDMS, elipsoideId = 22): CoordenadasUTM => {
	const { latitud: latitudDMS, longitud: longitudDMS } = coordenadas;
	const elipsoide = obtenerElipsoide(elipsoideId);
	let esteX = 0;
	let norteY = 0;
	let huso = 0;
	const zonaSimple = latitudDMS.cardinal;
	let longitudSexadecimal = longitudDMS.segundos / 60 / 60 + longitudDMS.minutos / 60 + longitudDMS.grados;
	if (longitudDMS.cardinal === 'O') {
		longitudSexadecimal = -1 * longitudSexadecimal;
	}
	let latitudSexadecimal = latitudDMS.segundos / 60 / 60 + latitudDMS.minutos / 60 + latitudDMS.grados;
	if (latitudDMS.cardinal === 'S') {
		latitudSexadecimal = -1 * latitudSexadecimal;
	}
	const calculoHuso = Math.trunc(longitudSexadecimal / 6 + 31);
	const longitudRadianes = (longitudSexadecimal * Math.PI) / 180;
	const meridianoHuso = 6 * calculoHuso - 183;
	const latitudRadianes = (latitudSexadecimal * Math.PI) / 180;
	const deltaLambda = +longitudRadianes - (meridianoHuso * Math.PI) / 180;
	const A = Math.cos(latitudRadianes) * Math.sin(deltaLambda);
	const Xi = (1 / 2) * Math.log((1 + A) / (1 - A));
	const { semiejeMayor, semiejeMenor } = elipsoide;
	const segundaExcentricidad = Math.sqrt(Math.pow(semiejeMayor, 2) - Math.pow(semiejeMenor, 2)) / semiejeMenor;
	const eCuadrado = Math.pow(segundaExcentricidad, 2);
	const cRadioPolarCurvatura = +Math.pow(semiejeMayor, 2) / semiejeMenor;
	const Ni = (cRadioPolarCurvatura / Math.pow(1 + eCuadrado * Math.pow(Math.cos(latitudRadianes), 2), 1 / 2)) * 0.9996;
	const zeta = (eCuadrado / 2) * Math.pow(Xi, 2) * Math.pow(Math.cos(latitudRadianes), 2);
	esteX = Xi * Ni * (1 + zeta / 3) + 500000;
	const A1 = Math.sin(2 * latitudRadianes);
	const A2 = +A1 * Math.pow(Math.cos(latitudRadianes), 2);
	const J2 = latitudRadianes + A1 / 2;
	const J4 = (3 * J2 + A2) / 4;
	const J6 = (5 * J4 + A2 * Math.pow(Math.cos(latitudRadianes), 2)) / 3;
	const alfa = (3 / 4) * eCuadrado;
	const beta = (5 / 3) * Math.pow(alfa, 2);
	const gamma = (35 / 27) * Math.pow(alfa, 3);
	const eta = Math.atan(Math.tan(latitudRadianes) / Math.cos(deltaLambda)) - latitudRadianes;
	const Bfi = 0.9996 * cRadioPolarCurvatura * (latitudRadianes - alfa * J2 + beta * J4 - gamma * J6);
	norteY = eta * Ni * (1 + zeta) + Bfi;
	if (latitudDMS.cardinal === 'S') {
		norteY = norteY + 10000000;
	}
	huso = Math.trunc(longitudSexadecimal / 6 + 31);
	const zonaUTM = obtenerZonaUTM(latitudDMS.grados, latitudDMS.cardinal);
	return {
		esteX: Number(esteX.toFixed(2)),
		huso,
		norteY: Number(norteY.toFixed(2)),
		zonaSimple,
		zonaUTM
	};
};

/**
 * Convierte coordenadas DM (Degree:Minute) a DD (Decimal Degree).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDM.
 */
export const convertirCoordenadasDMaDD = (coordenadas: CoordenadasDM): CoordenadasDD => {
	const { latitud, longitud } = coordenadas;
	const latitudGrados = latitud.grados;
	const latitudDecimales = latitud.minutos / 60;
	let latitudDD = latitudGrados + latitudDecimales;
	if (latitud.cardinal === 'S') {
		latitudDD = -1 * latitudDD;
	}
	const longitudGrados = longitud.grados;
	const longitudDecimales = longitud.minutos / 60;
	let longitudDD = longitudGrados + longitudDecimales;
	if (longitud.cardinal === 'O') {
		longitudDD = -1 * longitudDD;
	}
	return {
		latitud: latitudDD,
		longitud: longitudDD
	};
};

/**
 * Convierte coordenadas DM (Degree:Minute) a DMS (Degree:Minute:Second).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDM.
 */
export const convertirCoordenadasDMaDMS = (coordenadas: CoordenadasDM): CoordenadasDMS => {
	const coordenadasDD = convertirCoordenadasDMaDD(coordenadas);
	return convertirCoordenadasDDaDMS(coordenadasDD);
};

/**
 * Convierte coordenadas DM (Degree:Minute) a UTM (Universal Transverse Mercator).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDM.
 * - ***elipsoideId*** - Id del elipsoide que se utilizará para la conversión.
 *
 * > ***Predeterminado:*** *22*
 *
 * > **Valores Admitidos**
 * > - **1:** Airy 1830.
 * > - **2:** Airy Modificado 1965.
 * > - **3:** Bessel 1841.
 * > - **4:** Clarke 1866.
 * > - **5:** Clarke 1880.
 * > - **6:** Fischer 1960.
 * > - **7:** Fischer 1968.
 * > - **8:** GRS80.
 * > - **9:** Hayford 1909.
 * > - **10:** Helmert 1906.
 * > - **11:** Hough 1960
 * > - **12:** Internacional 1909.
 * > - **13:** Internacional 1924.
 * > - **14:** Krasovsky 1940.
 * > - **15:** Mercury 1960.
 * > - **16:** Mercury Modificado 1968.
 * > - **17:** Nuevo International 1967.
 * > - **18:** Sudamericano 1969.
 * > - **19:** Walbeck 1817.
 * > - **20:** WGS66.
 * > - **21:** WGS72.
 * > - **22:** WGS84.
 */
export const convertirCoordenadasDMaUTM = (coordenadas: CoordenadasDM, elipsoideId = 22): CoordenadasUTM => {
	const coordenadasDD = convertirCoordenadasDMaDD(coordenadas);
	return convertirCoordenadasDDaUTM(coordenadasDD, elipsoideId);
};

/**
 * Convierte coordenadas UTM (Universal Transverse Mercator) a DD (Decimal Degree).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasUTM.
 * - ***elipsoideId*** - Id del elipsoide que se utilizará para la conversión.
 *
 * > ***Predeterminado:*** *22*
 *
 * > **Valores Admitidos**
 * > - **1:** Airy 1830.
 * > - **2:** Airy Modificado 1965.
 * > - **3:** Bessel 1841.
 * > - **4:** Clarke 1866.
 * > - **5:** Clarke 1880.
 * > - **6:** Fischer 1960.
 * > - **7:** Fischer 1968.
 * > - **8:** GRS80.
 * > - **9:** Hayford 1909.
 * > - **10:** Helmert 1906.
 * > - **11:** Hough 1960
 * > - **12:** Internacional 1909.
 * > - **13:** Internacional 1924.
 * > - **14:** Krasovsky 1940.
 * > - **15:** Mercury 1960.
 * > - **16:** Mercury Modificado 1968.
 * > - **17:** Nuevo International 1967.
 * > - **18:** Sudamericano 1969.
 * > - **19:** Walbeck 1817.
 * > - **20:** WGS66.
 * > - **21:** WGS72.
 * > - **22:** WGS84.
 */
export const convertirCoordenadasUTMaDD = (coordenadas: CoordenadasUTM, elipsoideId = 22): CoordenadasDD => {
	const coordenadasDMS = convertirCoordenadasUTMaDMS(coordenadas, elipsoideId);
	return convertirCoordenadasDMSaDD(coordenadasDMS);
};

/**
 * Convierte coordenadas UTM (Universal Transverse Mercator) a DM (Degree:Minute).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasUTM.
 * - ***elipsoideId*** - Id del elipsoide que se utilizará para la conversión.
 *
 * > ***Predeterminado:*** *22*
 *
 * > **Valores Admitidos**
 * > - **1:** Airy 1830.
 * > - **2:** Airy Modificado 1965.
 * > - **3:** Bessel 1841.
 * > - **4:** Clarke 1866.
 * > - **5:** Clarke 1880.
 * > - **6:** Fischer 1960.
 * > - **7:** Fischer 1968.
 * > - **8:** GRS80.
 * > - **9:** Hayford 1909.
 * > - **10:** Helmert 1906.
 * > - **11:** Hough 1960
 * > - **12:** Internacional 1909.
 * > - **13:** Internacional 1924.
 * > - **14:** Krasovsky 1940.
 * > - **15:** Mercury 1960.
 * > - **16:** Mercury Modificado 1968.
 * > - **17:** Nuevo International 1967.
 * > - **18:** Sudamericano 1969.
 * > - **19:** Walbeck 1817.
 * > - **20:** WGS66.
 * > - **21:** WGS72.
 * > - **22:** WGS84.
 */
export const convertirCoordenadasUTMaDM = (coordenadas: CoordenadasUTM, elipsoideId = 22): CoordenadasDM => {
	const coordenadasDD = convertirCoordenadasUTMaDD(coordenadas, elipsoideId);
	return convertirCoordenadasDDaDM(coordenadasDD);
};

/**
 * Convierte coordenadas UTM (Universal Transverse Mercator) a DMS (Degree:Minute:Second).
 * - ***coordenadas*** - Coordenadas en formato CoordenadasUTM.
 * - ***elipsoideId*** - Id del elipsoide que se utilizará para la conversión.
 *
 * > ***Predeterminado:*** *22*
 *
 * > **Valores Admitidos**
 * > - **1:** Airy 1830.
 * > - **2:** Airy Modificado 1965.
 * > - **3:** Bessel 1841.
 * > - **4:** Clarke 1866.
 * > - **5:** Clarke 1880.
 * > - **6:** Fischer 1960.
 * > - **7:** Fischer 1968.
 * > - **8:** GRS80.
 * > - **9:** Hayford 1909.
 * > - **10:** Helmert 1906.
 * > - **11:** Hough 1960
 * > - **12:** Internacional 1909.
 * > - **13:** Internacional 1924.
 * > - **14:** Krasovsky 1940.
 * > - **15:** Mercury 1960.
 * > - **16:** Mercury Modificado 1968.
 * > - **17:** Nuevo International 1967.
 * > - **18:** Sudamericano 1969.
 * > - **19:** Walbeck 1817.
 * > - **20:** WGS66.
 * > - **21:** WGS72.
 * > - **22:** WGS84.
 */
export const convertirCoordenadasUTMaDMS = (coordenadas: CoordenadasUTM, elipsoideId = 22): CoordenadasDMS => {
	const { esteX, huso, norteY, zonaSimple } = coordenadas;
	const elipsoide = obtenerElipsoide(elipsoideId);
	const { semiejeMayor, semiejeMenor } = elipsoide;
	const segundaExcentricidad = Math.sqrt(Math.pow(semiejeMayor, 2) - Math.pow(semiejeMenor, 2)) / semiejeMenor;
	const eCuadrado = Math.pow(segundaExcentricidad, 2);
	const cRadioPolarCurvatura = +Math.pow(semiejeMayor, 2) / semiejeMenor;
	const auxiliar1 = (3 / 4) * eCuadrado;
	const auxiliar2 = (5 / 3) * Math.pow(auxiliar1, 2);
	const auxiliar3 = (35 / 27) * Math.pow(auxiliar1, 3);
	const yAlSurDelEcuador = zonaSimple === 'S' ? norteY - 10000000 : norteY;
	const auxiliar4 = yAlSurDelEcuador / (6366197.724 * 0.9996);
	const auxiliar5 = Math.sin(2 * auxiliar4);
	const auxiliar6 = auxiliar5 * Math.pow(Math.cos(auxiliar4), 2);
	const auxiliar7 = auxiliar4 + auxiliar5 / 2;
	const auxiliar8 = (3 * auxiliar7 + auxiliar6) / 4;
	const auxiliar9 = (5 * auxiliar8 + auxiliar6 * Math.pow(Math.cos(auxiliar4), 2)) / 3;
	const auxiliar10 = (cRadioPolarCurvatura / Math.pow(1 + eCuadrado * Math.pow(Math.cos(auxiliar4), 2), 1 / 2)) * 0.9996;
	const auxiliar11 = (esteX - 500000) / auxiliar10;
	const auxiliar12 = ((eCuadrado * Math.pow(auxiliar11, 2)) / 2) * Math.pow(Math.cos(auxiliar4), 2);
	const auxiliar13 = 0.9996 * cRadioPolarCurvatura * (auxiliar4 - auxiliar1 * auxiliar7 + auxiliar2 * auxiliar8 - auxiliar3 * auxiliar9);
	const auxiliar14 = (yAlSurDelEcuador - auxiliar13) / auxiliar10;
	const auxiliar15 = auxiliar14 * (1 - auxiliar12) + auxiliar4;
	const auxiliar16 = auxiliar11 * (1 - auxiliar12 / 3);
	const auxiliar17 = (Math.exp(auxiliar16) - Math.exp(-auxiliar16)) / 2;
	const auxiliar18 = Math.atan(auxiliar17 / Math.cos(auxiliar15));
	const auxiliar19 = Math.atan(Math.cos(auxiliar18) * Math.tan(auxiliar15));
	const fiRadianes =
		auxiliar4 +
		(1 + eCuadrado * Math.pow(Math.cos(auxiliar4), 2) - (3 / 2) * eCuadrado * Math.sin(auxiliar4) * Math.cos(auxiliar4) * (auxiliar19 - auxiliar4)) * (auxiliar19 - auxiliar4);
	const meridianoCentral = 6 * huso - 183;
	const longitudSexadecimal = +(auxiliar18 / Math.PI) * 180 + meridianoCentral;
	const longitudGradosAbsolutos = Math.trunc(longitudSexadecimal);
	const longitudMinutosAbsolutos = Math.trunc((longitudSexadecimal - longitudGradosAbsolutos) * 60);
	const longitudSegundosAbsolutos = ((longitudSexadecimal - longitudGradosAbsolutos) * 60 - longitudMinutosAbsolutos) * 60;
	const longitudGradosNegativos = longitudGradosAbsolutos - longitudGradosAbsolutos - longitudGradosAbsolutos;
	const longitudMinutosNegativos = longitudMinutosAbsolutos - longitudMinutosAbsolutos - longitudMinutosAbsolutos;
	const longitudSegundosNegativos = longitudSegundosAbsolutos - longitudSegundosAbsolutos - longitudSegundosAbsolutos;
	const longitudCardinal = longitudSegundosAbsolutos < 0 ? 'O' : 'E';
	const longitudGrados = longitudCardinal === 'O' ? longitudGradosNegativos : longitudGradosAbsolutos;
	const longitudMinutos = longitudCardinal === 'O' ? longitudMinutosNegativos : longitudMinutosAbsolutos;
	const longitudSegundos = longitudCardinal === 'O' ? longitudSegundosNegativos : longitudSegundosAbsolutos;
	const latitudSexadecimal = +(fiRadianes / Math.PI) * 180;
	const latitudGradosAbsolutos = Math.trunc(latitudSexadecimal);
	const latitudMinutosAbsolutos = Math.trunc((latitudSexadecimal - latitudGradosAbsolutos) * 60);
	const latitudSegundosAbsolutos = ((latitudSexadecimal - latitudGradosAbsolutos) * 60 - latitudMinutosAbsolutos) * 60;
	const latitudGradosNegativos = latitudGradosAbsolutos - latitudGradosAbsolutos - latitudGradosAbsolutos;
	const latitudMinutosNegativos = latitudMinutosAbsolutos - latitudMinutosAbsolutos - latitudMinutosAbsolutos;
	const latitudSegundosNegativos = latitudSegundosAbsolutos - latitudSegundosAbsolutos - latitudSegundosAbsolutos;
	const latitudCardinal = latitudSegundosAbsolutos < 0 ? 'S' : 'N';
	const latitudGrados = latitudCardinal === 'S' ? latitudGradosNegativos : latitudGradosAbsolutos;
	const latitudMinutos = latitudCardinal === 'S' ? latitudMinutosNegativos : latitudMinutosAbsolutos;
	const latitudSegundos = latitudCardinal === 'S' ? latitudSegundosNegativos : latitudSegundosAbsolutos;
	return {
		latitud: {
			cardinal: latitudCardinal,
			grados: latitudGrados,
			minutos: latitudMinutos,
			segundos: Number(latitudSegundos.toFixed(2))
		},
		longitud: {
			cardinal: longitudCardinal,
			grados: longitudGrados,
			minutos: longitudMinutos,
			segundos: Number(longitudSegundos.toFixed(2))
		}
	};
};

/**
 * Convierte grados a radianes.
 * - ***grados*** - Los grados a convertir.
 */
export const convertirGradosARadianes = (grados: number) => grados * (Math.PI / 180);

/**
 * Convierte radianes a grados.
 * - ***radianes*** - Los radianes a convertir.
 */
export const convertirRadianesAGrados = (radianes: number) => radianes * (180 / Math.PI);

/**
 * Crea una linea del mapa de Google.
 * - ***arregloCoordenadasDD*** - Arreglo de CoordenadasDD de los vertices de la linea.
 * - ***color?*** - Código hexadecimal del color de la linea.
 * > ***Predeterminado:*** *'3333ff'*
 * - ***grosor?*** - Grosor de la linea.
 * > ***Predeterminado:*** *3*
 * - ***noArrastrable?*** - Indica que la linea no podrá ser arrastrada.
 * > ***Predeterminado:*** *false*
 * - ***noClickeable?*** - Indica que la linea no podrá ser clickeada.
 * > ***Predeterminado:*** *false*
 * - ***noEditable?*** - Indica que la linea no podrá ser editada.
 * > ***Predeterminado:*** *false*
 * - ***zIndex?*** - zIndex en el que se dibujara la linea.
 * > ***Predeterminado:*** *1*
 */
export const crearLinea = ({
	arregloCoordenadasDD,
	color = '3333ff',
	grosor = 3,
	noArrastrable = false,
	noClickeable = false,
	noEditable = false,
	zIndex = 1
}: {
	arregloCoordenadasDD: Array<CoordenadasDD>;
	color?: string;
	grosor?: number;
	noArrastrable?: boolean;
	noClickeable?: boolean;
	noEditable?: boolean;
	zIndex?: number;
}): google.maps.Polyline => {
	const arregloPuntosLatLng: Array<google.maps.LatLngLiteral> = [];
	const arregloPuntos = arregloCoordenadasDD;
	arregloPuntos?.forEach((punto: CoordenadasDD) => {
		arregloPuntosLatLng.push({
			lat: punto.latitud,
			lng: punto.longitud
		});
	});
	const linea = new google.maps.Polyline({
		clickable: !noClickeable,
		draggable: !noArrastrable,
		editable: !noEditable,
		path: arregloPuntosLatLng,
		strokeColor: `#${color}`,
		strokeWeight: grosor,
		zIndex: zIndex
	});
	return linea;
};

/**
 * Crea un marcador del mapa de Google.
 * - ***coordenadasDD*** - CoordenadasDD del marcador.
 * - ***noArrastrable?*** - Indica que el marcador no podrá ser arrastrada.
 * > ***Predeterminado:*** *false*
 * - ***noClickeable?*** - Indica que el marcador no podrá ser clickeada.
 * > ***Predeterminado:*** *false*
 */
export const crearMarcador = ({
	coordenadasDD,
	noArrastrable = false,
	noClickeable = false
}: {
	coordenadasDD: CoordenadasDD;
	noArrastrable?: boolean;
	noClickeable?: boolean;
}): google.maps.Marker => {
	const { latitud, longitud } = coordenadasDD;
	const marcador = new google.maps.Marker({
		clickable: !noClickeable,
		draggable: !noArrastrable,
		position: {
			lat: Number(latitud),
			lng: Number(longitud)
		}
	});
	return marcador;
};

/**
 * Crea un polígono del mapa de Google.
 * - ***arregloCoordenadasDD*** - Arreglo de CoordenadasDD de los vertices de la linea.
 * - ***colorLinea?*** - Código hexadecimal del color del contorno del polígono.
 * > ***Predeterminado:*** *'b0310b'*
 * - ***colorRelleno?*** - Código hexadecimal del color del relleno del polígono.
 * > ***Predeterminado:*** *'b0310b'*
 * - ***grosor?*** - Grosor de la linea del polígono.
 * > ***Predeterminado:*** *3*
 * - ***noArrastrable?*** - Indica que el polígono no podrá ser arrastrada.
 * > ***Predeterminado:*** *false*
 * - ***noClickeable?*** - Indica que el polígono no podrá ser clickeada.
 * > ***Predeterminado:*** *false*
 * - ***noEditable?*** - Indica que el polígono no podrá ser editada.
 * > ***Predeterminado:*** *false*
 * - ***opacidad?*** - Opacidad del relleno del polígono.
 * > ***Predeterminado:*** *0.5*
 *
 * **Nota:** Admite valores decimales entre 0 y 1.
 * - ***zIndex?*** - zIndex en el que se dibujara la linea.
 * > ***Predeterminado:*** *1*
 */
export const crearPoligono = ({
	arregloCoordenadasDD,
	colorLinea = 'b0310b',
	colorRelleno = 'b0310b',
	grosor = 3,
	noArrastrable = false,
	noClickeable = false,
	noEditable = false,
	opacidad = 0.5,
	zIndex = 1
}: {
	arregloCoordenadasDD: Array<CoordenadasDD>;
	colorLinea?: string;
	colorRelleno?: string;
	grosor?: number;
	noArrastrable?: boolean;
	noClickeable?: boolean;
	noEditable?: boolean;
	opacidad?: number;
	zIndex?: number;
}): google.maps.Polygon => {
	const arregloPuntosLatLng: Array<google.maps.LatLngLiteral> = [];
	const arregloPuntos = arregloCoordenadasDD;
	arregloPuntos?.forEach((punto: CoordenadasDD) => {
		arregloPuntosLatLng.push({
			lat: punto.latitud,
			lng: punto.longitud
		});
	});
	const poligono = new google.maps.Polygon({
		clickable: !noClickeable,
		draggable: !noArrastrable,
		editable: !noEditable,
		fillColor: `#${colorRelleno}`,
		fillOpacity: opacidad,
		paths: arregloPuntosLatLng,
		strokeColor: `#${colorLinea}`,
		strokeWeight: grosor,
		zIndex: zIndex
	});
	return poligono;
};

/**
 * Regresa verdadero si el cardinal DMS es valido.
 * - ***cardinalDMS*** - Cardinal DMS a validar.
 */
export const esCardinalDMSValido = (cardinalDMS: string) => ['S', 'O', 'N', 'E'].includes(cardinalDMS);

/**
 * Regresa verdadero si la zona UTM es valida.
 * - ***zonaUTM*** - Zona UTM a validar.
 */
export const esZonaUTMValida = (zonaUTM: string) => {
	const resultado = zonas.filter((zona) => zona.zonaUTM === zonaUTM);
	return resultado.length > 0;
};

/**
 * Obtiene las coordenadas en un objeto CoordenadaDMCadena.
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDM.
 */
export const formatearCoordenadasDMCadena = (coordenadas?: CoordenadasDM): CoordenadasDMCadena => {
	let latitudCadena = '';
	let longitudCadena = '';
	if (coordenadas) {
		const { latitud, longitud } = coordenadas;
		latitudCadena = `${latitud.grados}°${latitud.minutos}'${latitud.cardinal}`;
		longitudCadena = `${longitud.grados}°${longitud.minutos}'${longitud.cardinal}`;
	}
	return {
		latitud: latitudCadena,
		longitud: longitudCadena
	};
};

/**
 * Obtiene las coordenadas en un objeto CoordenadaDM.
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDMCadena.
 */
export const formatearCoordenadasDMObjeto = (coordenadas: CoordenadasDMCadena): CoordenadasDM => {
	const { latitud, longitud } = coordenadas;
	const latitudIndiceGrados = latitud.indexOf('°');
	const latitudGradosCadena = latitud.substring(0, latitudIndiceGrados);
	const latitudIndiceMinutos = latitud.indexOf("'");
	const latitudMinutosCadena = latitud.substring(latitudIndiceGrados + 1, latitudIndiceMinutos);
	const latitudCardinal = latitud.substring(latitudIndiceMinutos + 1);
	const latitudGrados = Number(latitudGradosCadena);
	const latitudMinutos = Number(latitudMinutosCadena);
	const longitudIndiceGrados = longitud.indexOf('°');
	const longitudGradosCadena = longitud.substring(0, longitudIndiceGrados);
	const longitudIndiceMinutos = longitud.indexOf("'");
	const longitudMinutosCadena = longitud.substring(longitudIndiceGrados + 1, longitudIndiceMinutos);
	const longitudCardinal = longitud.substring(longitudIndiceMinutos + 1);
	const longitudGrados = Number(longitudGradosCadena);
	const longitudMinutos = Number(longitudMinutosCadena);
	return {
		latitud: {
			cardinal: latitudCardinal,
			grados: latitudGrados,
			minutos: latitudMinutos
		},
		longitud: {
			cardinal: longitudCardinal,
			grados: longitudGrados,
			minutos: longitudMinutos
		}
	};
};

/**
 * Obtiene las coordenadas en un objeto CoordenadaDMSCadena.
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDMS.
 */
export const formatearCoordenadasDMSCadena = (coordenadas?: CoordenadasDMS): CoordenadasDMSCadena => {
	let latitudCadena = '';
	let longitudCadena = '';
	if (coordenadas) {
		const { latitud, longitud } = coordenadas;
		latitudCadena = `${latitud.grados}°${latitud.minutos}'${latitud.segundos}"${latitud.cardinal}`;
		longitudCadena = `${longitud.grados}°${longitud.minutos}'${longitud.segundos}"${longitud.cardinal}`;
	}
	return {
		latitud: latitudCadena,
		longitud: longitudCadena
	};
};

/**
 * Obtiene las coordenadas en un objeto CoordenadaDMS.
 * - ***coordenadas*** - Coordenadas en formato CoordenadasDMSCadena.
 */
export const formatearCoordenadasDMSObjeto = (coordenadas: CoordenadasDMSCadena): CoordenadasDMS => {
	const { latitud, longitud } = coordenadas;
	const latitudIndiceGrados = latitud.indexOf('°');
	const latitudGradosCadena = latitud.substring(0, latitudIndiceGrados);
	const latitudIndiceMinutos = latitud.indexOf("'");
	const latitudMinutosCadena = latitud.substring(latitudIndiceGrados + 1, latitudIndiceMinutos);
	const latitudIndiceSegundos = latitud.indexOf('"');
	const latitudSegundosCadena = latitud.substring(latitudIndiceMinutos + 1, latitudIndiceSegundos);
	const latitudCardinal = latitud.substring(latitudIndiceSegundos + 1);
	const latitudGrados = Number(latitudGradosCadena);
	const latitudMinutos = Number(latitudMinutosCadena);
	const latitudSegundos = Number(latitudSegundosCadena);
	const longitudIndiceGrados = longitud.indexOf('°');
	const longitudGradosCadena = longitud.substring(0, longitudIndiceGrados);
	const longitudIndiceMinutos = longitud.indexOf("'");
	const longitudMinutosCadena = longitud.substring(longitudIndiceGrados + 1, longitudIndiceMinutos);
	const longitudIndiceSegundos = longitud.indexOf('"');
	const longitudSegundosCadena = longitud.substring(longitudIndiceMinutos + 1, longitudIndiceSegundos);
	const longitudCardinal = longitud.substring(longitudIndiceSegundos + 1);
	const longitudGrados = Number(longitudGradosCadena);
	const longitudMinutos = Number(longitudMinutosCadena);
	const longitudSegundos = Number(longitudSegundosCadena);
	return {
		latitud: {
			cardinal: latitudCardinal,
			grados: latitudGrados,
			minutos: latitudMinutos,
			segundos: latitudSegundos
		},
		longitud: {
			cardinal: longitudCardinal,
			grados: longitudGrados,
			minutos: longitudMinutos,
			segundos: longitudSegundos
		}
	};
};

/**
 * Obtiene las coordenadas UTM en una cadena.
 * - ***coordenadas*** - Coordenadas en formato CoordenadasUTM.
 */
export const formatearCoordenadasUTMCadena = (coordenadas?: CoordenadasUTM): string => {
	let cadena = '';
	if (coordenadas) {
		const { esteX, huso, norteY, zonaUTM } = coordenadas;
		cadena = `${huso}${zonaUTM} ${esteX} m E ${norteY} m N`;
	}
	return cadena;
};

/**
 * Obtiene la url de un mapa estático de Google.
 * - ***alto?*** - Alto en pixeles de la imagen que será generada.
 * > ***Predeterminado:*** *640*
 * - ***ancho?*** - Ancho en pixeles de la imagen que será generada.
 * > ***Predeterminado:*** *640*
 * - ***googleMapsApiClave*** - Clave de la API de Google Maps.
 * - ***googleMapsApiUrl*** - URL de la API de mapas estáticos de Google Maps.
 * - ***latitud*** - Latitud en coordenadas DD que se mostrará en el mapa.
 * - ***longitud*** - Longitud en coordenadas DD que se mostrará en el mapa.
 * - ***poligono?*** - Poligono de Google Maps que se dibujará sobre el mapa.
 * > ***Predeterminado:*** *undefined*
 * - ***tipoMapa?*** - Tipo de mapa de Google Maps.
 * > ***Predeterminado:*** *'roadmap'*
 *
 * > **Valores Admitidos**
 * > - **hybrid:** Mapa hibrido.
 * > - **roadmap:** Mapa con las calles.
 * > - **satellite:** Mapa con imagenes satelitales.
 * > - **terrain:** Mapa con las características del terrono.
 */
export const obtenerImagenMapaUrl = ({
	alto = 640,
	ancho = 640,
	googleMapsApiClave,
	googleMapsApiUrl,
	latitud,
	longitud,
	poligono,
	tipoMapa,
	zoom
}: {
	alto: number;
	ancho: number;
	googleMapsApiClave?: string;
	googleMapsApiUrl?: string;
	latitud?: number;
	longitud?: number;
	poligono?: google.maps.Polygon;
	tipoMapa: 'hybrid' | 'roadmap' | 'satellite' | 'terrain';
	zoom?: number;
}) => {
	let imagenMapaUrl = `${googleMapsApiUrl}?size=${ancho}x${alto}&maptype=${tipoMapa}&zoom=${zoom}&center=${latitud},${longitud}`;
	let parametrosUrlPoligono = '';
	if (poligono) {
		const puntosArreglo: Array<any> = [];
		const puntos = poligono.getPath();
		puntos?.forEach((punto: any) => {
			puntosArreglo.push(punto);
		});
		puntosArreglo?.forEach((arista: any) => {
			parametrosUrlPoligono += '|';
			parametrosUrlPoligono += arista.lat();
			parametrosUrlPoligono += ',';
			parametrosUrlPoligono += arista.lng();
		});
		if (puntosArreglo.length > 0) {
			parametrosUrlPoligono += '|';
			parametrosUrlPoligono += puntosArreglo[0].lat();
			parametrosUrlPoligono += ',';
			parametrosUrlPoligono += puntosArreglo[0].lng();
		}
		const colorRelleno = poligono.get('fillColor');
		const colorLinea = poligono.get('strokeColor');
		const grosorLinea = poligono.get('strokeWeight');
		imagenMapaUrl += '&path=';
		if (typeof colorRelleno != 'undefined') imagenMapaUrl += `fillcolor:${colorRelleno.replace(/#/, '0x')}`;
		if (typeof grosorLinea != 'undefined') imagenMapaUrl += `|weight:${grosorLinea}`;
		else imagenMapaUrl += '|weight:0';
		if (typeof colorLinea != 'undefined') imagenMapaUrl += `|color:${colorLinea.replace(/#/, '0x')}`;
		imagenMapaUrl += parametrosUrlPoligono;
	}
	imagenMapaUrl += '&style=feature:poi|element:labels|visibility:off&style=feature:landscape.man_made|visibility:off';
	imagenMapaUrl += `&key=${googleMapsApiClave}`;
	return imagenMapaUrl;
};

/**
 * Obtiene el arreglo de coordenadas DD de una linea de Google Maps.
 * - ***linea*** - Objeto Polyline de Google Maps.
 */
export const obtenerLineaArregloCoordenadasDD = (linea?: google.maps.Polyline): Array<CoordenadasDD> => {
	const arregloPuntos: Array<CoordenadasDD> = [];
	if (linea) {
		const puntos = linea.getPath();
		puntos?.forEach((punto: any) =>
			arregloPuntos.push({
				latitud: punto.lat(),
				longitud: punto.lng()
			})
		);
	}
	return arregloPuntos;
};

/**
 * Obtiene la longitud de la linea del mapa.
 * - ***linea*** - Linea del mapa.
 */
export const obtenerLineaLongitud = (linea?: google.maps.Polyline): number => {
	let distanciaTotal = 0;
	if (linea) {
		const puntosArreglo: Array<any> = [];
		const puntos = linea.getPath();
		puntos?.forEach((punto: any) => {
			puntosArreglo.push(punto);
		});
		for (let i = 0; i < puntosArreglo.length; i++) {
			if (i + 1 < puntosArreglo.length) {
				const puntoInicial = puntosArreglo[i];
				const puntoFinal = puntosArreglo[i + 1];
				const distancia = google.maps.geometry.spherical.computeDistanceBetween(puntoInicial, puntoFinal);
				distanciaTotal = distanciaTotal + distancia;
			}
		}
	}
	return Number(distanciaTotal.toFixed(2));
};

/**
 * Obtiene el arreglo de coordenadas DD de un polígono de Google Maps.
 * - ***poligono*** - Objeto Polygon de Google Maps.
 */
export const obtenerPoligonoArregloCoordenadasDD = (poligono?: google.maps.Polygon): Array<CoordenadasDD> => {
	const arregloPuntos: Array<CoordenadasDD> = [];
	if (poligono) {
		const puntos = poligono.getPath();
		puntos?.forEach((punto: any) =>
			arregloPuntos.push({
				latitud: punto.lat(),
				longitud: punto.lng()
			})
		);
	}
	return arregloPuntos;
};

/**
 * Obtiene la zona simple de la zona UTM recibida.
 * - ***zonaUTM*** - Zona UTM requerida.
 *
 * > **Valores Admitidos**
 * > - **C:** Zona C.
 * > - **D:** Zona D.
 * > - **E:** Zona E.
 * > - **F:** Zona F.
 * > - **G:** Zona G.
 * > - **H:** Zona H.
 * > - **J:** Zona J.
 * > - **K:** Zona K.
 * > - **L:** Zona L.
 * > - **M:** Zona M.
 * > - **N:** Zona N.
 * > - **P:** Zona P.
 * > - **Q:** Zona Q.
 * > - **R:** Zona R.
 * > - **S:** Zona S.
 * > - **T:** Zona T.
 * > - **U:** Zona U.
 * > - **V:** Zona V.
 * > - **W:** Zona W.
 * > - **X:** Zona X.
 */
export const obtenerZonaSimple = (zonaUTM: string) => {
	const resultados = zonas.filter((zona) => zona.zonaUTM === zonaUTM);
	return resultados[0];
};

/**
 * Obtiene el elipsoide con el id recibido.
 * - ***elipsoideId*** - Id del elipsoide requerido.
 *
 * > **Valores Admitidos**
 * > - **1:** Airy 1830.
 * > - **2:** Airy Modificado 1965.
 * > - **3:** Bessel 1841.
 * > - **4:** Clarke 1866.
 * > - **5:** Clarke 1880.
 * > - **6:** Fischer 1960.
 * > - **7:** Fischer 1968.
 * > - **8:** GRS80.
 * > - **9:** Hayford 1909.
 * > - **10:** Helmert 1906.
 * > - **11:** Hough 1960
 * > - **12:** Internacional 1909.
 * > - **13:** Internacional 1924.
 * > - **14:** Krasovsky 1940.
 * > - **15:** Mercury 1960.
 * > - **16:** Mercury Modificado 1968.
 * > - **17:** Nuevo International 1967.
 * > - **18:** Sudamericano 1969.
 * > - **19:** Walbeck 1817.
 * > - **20:** WGS66.
 * > - **21:** WGS72.
 * > - **22:** WGS84.
 */
const obtenerElipsoide = (elipsoideId: number) => {
	elipsoideId = elipsoideId >= 1 && elipsoideId <= 22 ? Math.floor(elipsoideId) : 22;
	const resultados = elipsoides.filter((elipsoide) => elipsoide.id === elipsoideId);
	return resultados[0];
};

/**
 * Obtiene la zona UTM a partir de los grados.
 * - ***grados*** - Grados.
 * - ***zonaSimple*** - Zona simple.
 *
 * > **Valores Admitidos**
 * > - **N:** Norte.
 * > - **S:** Sur.
 */
export const obtenerZonaUTM = (grados: number, zonaSimple: string) => {
	grados = Math.abs(grados);
	if (zonaSimple === 'S' && grados >= 72) {
		return 'C';
	}
	if (zonaSimple === 'N' && grados >= 72) {
		return 'X';
	}
	for (let i = 0; i < zonas.length; i++) {
		if (grados >= zonas[i].latitudMinima && grados < zonas[i].latitudMaxima && zonaSimple === zonas[i].zonaSimple) {
			return zonas[i].zonaUTM;
		}
	}
	return '';
};

/**
 * Elipsoides utilizados en la conversión de coordenadas UTM (Universal Transverse Mercator).
 */
const elipsoides: Array<Elipsoide> = [
	{
		achatamiento: 0,
		fecha: 1830,
		id: 1,
		nombre: 'Airy 1830',
		semiejeMayor: 6377563.396,
		semiejeMenor: 6356256.91
	},
	{
		achatamiento: 0,
		fecha: 1965,
		id: 2,
		nombre: 'Airy Modificado 1965',
		semiejeMayor: 6377563.396,
		semiejeMenor: 6356034.4479
	},
	{
		achatamiento: 0,
		fecha: 1841,
		id: 3,
		nombre: 'Bessel 1841',
		semiejeMayor: 6377397.155,
		semiejeMenor: 6356078.96284
	},
	{
		achatamiento: 0,
		fecha: 1866,
		id: 4,
		nombre: 'Clarke 1866',
		semiejeMayor: 6378206.4,
		semiejeMenor: 6356583.8
	},
	{
		achatamiento: 0,
		fecha: 1880,
		id: 5,
		nombre: 'Clarke 1880',
		semiejeMayor: 6378249.145,
		semiejeMenor: 6356514.86955
	},
	{
		achatamiento: 0,
		fecha: 1960,
		id: 6,
		nombre: 'Fischer 1960',
		semiejeMayor: 6378166,
		semiejeMenor: 6356784.28
	},
	{
		achatamiento: 0,
		fecha: 1968,
		id: 7,
		nombre: 'Fischer 1968',
		semiejeMayor: 6378150,
		semiejeMenor: 6356768.33
	},
	{
		achatamiento: 0,
		fecha: 1980,
		id: 8,
		nombre: 'GRS80',
		semiejeMayor: 6378137,
		semiejeMenor: 6356752.31414
	},
	{
		achatamiento: 0,
		fecha: 1909,
		id: 9,
		nombre: 'Hayford 1909',
		semiejeMayor: 6378388,
		semiejeMenor: 6356911.94613
	},
	{
		achatamiento: 0,
		fecha: 1906,
		id: 10,
		nombre: 'Helmert 1906',
		semiejeMayor: 6378200,
		semiejeMenor: 6356818.17
	},
	{
		achatamiento: 0,
		fecha: 1960,
		id: 11,
		nombre: 'Hough 1960',
		semiejeMayor: 6378270,
		semiejeMenor: 6356794.343479
	},
	{
		achatamiento: 0,
		fecha: 1909,
		id: 12,
		nombre: 'Internacional 1909',
		semiejeMayor: 6378388,
		semiejeMenor: 6356911.94613
	},
	{
		achatamiento: 0,
		fecha: 1924,
		id: 13,
		nombre: 'Internacional 1924',
		semiejeMayor: 6378388,
		semiejeMenor: 6356911.94613
	},
	{
		achatamiento: 0,
		fecha: 1940,
		id: 14,
		nombre: 'Krasovsky 1940',
		semiejeMayor: 6378245,
		semiejeMenor: 6356863.0188
	},
	{
		achatamiento: 0,
		fecha: 1960,
		id: 15,
		nombre: 'Mercury 1960',
		semiejeMayor: 6378166,
		semiejeMenor: 6356784.283666
	},
	{
		achatamiento: 0,
		fecha: 1968,
		id: 16,
		nombre: 'Mercury Modificado 1968',
		semiejeMayor: 6378150,
		semiejeMenor: 6356768.337303
	},
	{
		achatamiento: 0,
		fecha: 1967,
		id: 17,
		nombre: 'Nuevo International 1967',
		semiejeMayor: 6378157.5,
		semiejeMenor: 6356772.2
	},
	{
		achatamiento: 0,
		fecha: 1969,
		id: 18,
		nombre: 'Sudamericano 1969',
		semiejeMayor: 6378160,
		semiejeMenor: 6356774.72
	},
	{
		achatamiento: 0,
		fecha: 1817,
		id: 19,
		nombre: 'Walbeck 1817',
		semiejeMayor: 6376896,
		semiejeMenor: 6355834.8467
	},
	{
		achatamiento: 0,
		fecha: 1966,
		id: 20,
		nombre: 'WGS66',
		semiejeMayor: 6378145,
		semiejeMenor: 6356759.769356
	},
	{
		achatamiento: 0,
		fecha: 1972,
		id: 21,
		nombre: 'WGS72',
		semiejeMayor: 6378135,
		semiejeMenor: 6356750.519915
	},
	{
		achatamiento: 1 / 298.257223563,
		fecha: 1984,
		id: 22,
		nombre: 'WGS84',
		semiejeMayor: 6378137,
		semiejeMenor: 6356752.31424518
	}
];

/**
 * Zonas utilizadas en la conversión de coordenadas UTM (Universal Transverse Mercator).
 */
const zonas: Array<ZonaUTM> = [
	{
		latitudMaxima: 80,
		latitudMinima: 72,
		zonaSimple: 'S',
		zonaUTM: 'C'
	},
	{
		latitudMaxima: 72,
		latitudMinima: 64,
		zonaSimple: 'S',
		zonaUTM: 'D'
	},
	{
		latitudMaxima: 64,
		latitudMinima: 56,
		zonaSimple: 'S',
		zonaUTM: 'E'
	},
	{
		latitudMaxima: 56,
		latitudMinima: 48,
		zonaSimple: 'S',
		zonaUTM: 'F'
	},
	{
		latitudMaxima: 48,
		latitudMinima: 40,
		zonaSimple: 'S',
		zonaUTM: 'G'
	},
	{
		latitudMaxima: 40,
		latitudMinima: 32,
		zonaSimple: 'S',
		zonaUTM: 'H'
	},
	{
		latitudMaxima: 32,
		latitudMinima: 24,
		zonaSimple: 'S',
		zonaUTM: 'J'
	},
	{
		latitudMaxima: 24,
		latitudMinima: 16,
		zonaSimple: 'S',
		zonaUTM: 'K'
	},
	{
		latitudMaxima: 16,
		latitudMinima: 8,
		zonaSimple: 'S',
		zonaUTM: 'L'
	},
	{
		latitudMaxima: 8,
		latitudMinima: 0,
		zonaSimple: 'S',
		zonaUTM: 'M'
	},
	{
		latitudMaxima: 8,
		latitudMinima: 0,
		zonaSimple: 'N',
		zonaUTM: 'N'
	},
	{
		latitudMaxima: 16,
		latitudMinima: 8,
		zonaSimple: 'N',
		zonaUTM: 'P'
	},
	{
		latitudMaxima: 24,
		latitudMinima: 16,
		zonaSimple: 'N',
		zonaUTM: 'Q'
	},
	{
		latitudMaxima: 32,
		latitudMinima: 24,
		zonaSimple: 'N',
		zonaUTM: 'R'
	},
	{
		latitudMaxima: 40,
		latitudMinima: 32,
		zonaSimple: 'N',
		zonaUTM: 'S'
	},
	{
		latitudMaxima: 48,
		latitudMinima: 40,
		zonaSimple: 'N',
		zonaUTM: 'T'
	},
	{
		latitudMaxima: 56,
		latitudMinima: 48,
		zonaSimple: 'N',
		zonaUTM: 'U'
	},
	{
		latitudMaxima: 64,
		latitudMinima: 56,
		zonaSimple: 'N',
		zonaUTM: 'V'
	},
	{
		latitudMaxima: 72,
		latitudMinima: 64,
		zonaSimple: 'N',
		zonaUTM: 'W'
	},
	{
		latitudMaxima: 84,
		latitudMinima: 72,
		zonaSimple: 'N',
		zonaUTM: 'X'
	}
];
