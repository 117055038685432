import { actualizarUsuarioRol, crearUsuarioRol, obtenerUsuarioRolPorId } from 'servicios/api/usuariosRoles';
import { Card, CardBody, CardFooter, Col, Row } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useEsSeccionCreacion, useEsSeccionEdicion } from 'hooks/useSeccion';
import { useHistory, useParams } from 'react-router';
import BarraHerramientas from 'componentes/tema-comun/pagina/BarraHerramientas';
import Breadcrumb from 'componentes/tema-comun/pagina/Breadcrumb';
import constantes from 'configuracion/constantes';
import Contenedor from 'componentes/tema-comun/pagina/Contenedor';
import { Form } from 'react-final-form';
import mcLogger from '@mcsoft/logger';
import mcNotificaciones from 'util/mc-utils/mc-notificaciones';
import { procesarError } from '@mcsoft/api';
import RolFormulario from 'componentes/roles/RolFormulario';
import RolFormularioPermisos from 'componentes/roles/RolFormularioPermisos';
import { setPantallaCargaMostrarAction } from 'store/actions';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { tienePermiso } from 'util/mc-utils/mc-autenticacion';

const NOMBRE_CLASE = 'paginas/app/roles/RolDetallesEditar';

/**
 * En esta página se pueden editar los detalles de un rol.
 */
const Pagina = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const esSeccionCreacion = useEsSeccionCreacion();
	const esSeccionEdicion = useEsSeccionEdicion();
	const { location } = history;
	const { id } = useParams<any>();
	const { auth0, usuario } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;
	const { criterio, orden, ordenamiento, pagina, registrosPorPagina } = useSelector((state: StateType) => state.parametrosPaginacion.usuarioRoles);
	const [tituloSeccion, setTituloSeccion] = useState<string>('');
	const [rutaSalida, setRutaSalida] = useState<string>(`${constantes.ruta.appAdministracionRolesDetalles}/${id}`);
	const [enlaces, setEnlaces] = useState<any>([]);
	const [registro, setRegistro] = useState<any>();
	const [formulario, setFormulario] = useState<any>({});

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, []);

	useEffect(() => {
		obtenerVariablesDeSeccion();
		obtenerRegistro();
	}, [location.pathname]);

	/**
	 * Redirecciona a la ruta de salida.
	 */
	const eventoCancelar = () => {
		const nombreMetodo = 'eventoCancelar';
		mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
		history.push(rutaSalida);
	};

	/**
	 * Guarda la información del registro.
	 * - ***valores*** - Valores a guardar.
	 */
	const eventoGuardar = async (valores: Record<string, any>) => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Guardando la información del rol...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				const valoresTemporales = { ...valores };
				registro.nombre = valoresTemporales.nombre;
				registro.descripcion = valoresTemporales.descripcion;
				delete valoresTemporales.nombre;
				delete valoresTemporales.descripcion;
				const permisos = [];
				for (const propiedad in valoresTemporales) {
					if (valoresTemporales[propiedad]) {
						permisos.push(propiedad);
					}
				}
				registro.permisos = permisos;
				await crearUsuarioRol({ auth0AccessToken, valores: registro });
				mcLogger.log({ mensaje: `Rol creado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Rol creado con éxito.`) });
			}
			if (esSeccionEdicion()) {
				const valoresTemporales = { ...valores };
				delete valoresTemporales.descripcion;
				delete valoresTemporales.fechaCreacion;
				delete valoresTemporales.fechaModificacion;
				delete valoresTemporales.id;
				delete valoresTemporales.nombre;
				const permisos = [];
				for (const propiedad in valoresTemporales) {
					if (valoresTemporales[propiedad]) {
						permisos.push(propiedad);
					}
				}
				const nuevoRegistro = {
					descripcion: valores.descripcion,
					fechaCreacion: valores.fechaCreacion,
					fechaModificacion: valores.fechaModificacion,
					id: valores.id,
					nombre: valores.nombre,
					permisos: permisos
				};
				await actualizarUsuarioRol({ auth0AccessToken, id: nuevoRegistro.id, valores: nuevoRegistro });
				mcLogger.log({ mensaje: `Rol actualizado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
				mcNotificaciones.exito({ mensaje: texto(`Rol actualizado con éxito.`) });
			}
			dispatch(setPantallaCargaMostrarAction(false));
			mcLogger.log({ mensaje: `Redireccionando a la ruta:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: rutaSalida });
			history.push(rutaSalida);
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al guardar el rol:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene el registro.
	 */
	const obtenerRegistro = async () => {
		const nombreMetodo = 'obtenerRegistro';
		try {
			mcLogger.log({ mensaje: `Inicializando formulario...`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(true));
			if (esSeccionCreacion()) {
				setRegistro({});
				setFormulario({});
			}
			if (esSeccionEdicion()) {
				const respuesta = await obtenerUsuarioRolPorId({ auth0AccessToken, id });
				let { datos } = respuesta;
				const permisosObjeto: any = {};
				datos.permisos.forEach((permiso: string) => {
					permisosObjeto[permiso] = true;
				});
				delete datos.permisos;
				datos = { ...datos, ...permisosObjeto };
				setFormulario(datos);
			}
			mcLogger.log({ mensaje: `Formulario inicializado con éxito.`, nombreArchivo: NOMBRE_CLASE, nombreMetodo });
			dispatch(setPantallaCargaMostrarAction(false));
		} catch (error: any) {
			const mcError = procesarError(error);
			mcLogger.error({ mensaje: `Error al inicializar formulario:`, nombreArchivo: NOMBRE_CLASE, nombreMetodo, objetoExtra: mcError.descripcion });
			mcNotificaciones.error({ mensaje: mcError.descripcion, titulo: mcError.nombre });
			dispatch(setPantallaCargaMostrarAction(false));
		}
	};

	/**
	 * Obtiene los valores de las variables que cambian en función de la sección.
	 */
	const obtenerVariablesDeSeccion = () => {
		if (esSeccionCreacion()) {
			setTituloSeccion(texto('Crear rol'));
			const rutaSalida = `${constantes.ruta.appAdministracionRolesLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalida, titulo: texto('Lista de roles') }
			]);
		}
		if (esSeccionEdicion()) {
			setTituloSeccion(texto('Editar detalles del rol'));
			const rutaSalida = `${constantes.ruta.appAdministracionRolesDetalles}/${id}`;
			const rutaSalidaLista = `${constantes.ruta.appAdministracionRolesLista}?pagina=${pagina}&registrosPorPagina=${registrosPorPagina}&ordenamiento=${ordenamiento}&orden=${orden}&criterio=${criterio}`;
			setRutaSalida(rutaSalida);
			setEnlaces([
				{ ruta: constantes.ruta.appInicio, titulo: texto('Inicio') },
				{ ruta: rutaSalidaLista, titulo: texto('Lista de roles') },
				{ ruta: rutaSalida, titulo: texto('Detalles del rol') }
			]);
		}
	};

	return (
		<Contenedor>
			<Breadcrumb enlaces={enlaces} icono={constantes.icono.rol} titulo={tituloSeccion} />
			<Form
				initialValues={formulario}
				onSubmit={eventoGuardar}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Card>
							<CardBody>
								<Row>
									<Col md="12">
										{esSeccionCreacion() && (
											<RolFormulario detallesEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesCrear, usuario })} registro={registro} />
										)}
										{esSeccionEdicion() && (
											<RolFormulario detallesEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarDetalles, usuario })} registro={registro} />
										)}
										<h5 className="mt-3">
											<i className={constantes.icono.usuarioPermisos}></i> {texto('Lista de permisos')}
										</h5>
										<RolFormularioPermisos
											permisosAplicacionEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosAplicacion, usuario })}
											permisosAvaluosEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosAvaluos, usuario })}
											permisosCatalogosEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosCatalogos, usuario })}
											permisosNotificacionesEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosNotificaciones, usuario })}
											permisosPeritosEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosPeritos, usuario })}
											permisosRolesEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosRoles, usuario })}
											permisosUsuariosEditables={tienePermiso({ permiso: constantes.permiso.appAdministracionRolesEditarPermisosUsuarios, usuario })}
										/>
									</Col>
								</Row>
							</CardBody>
							<CardFooter>
								<BarraHerramientas>
									<button className="btn btn-danger" id="botonCancelar" onClick={eventoCancelar} type="button">
										<i className={constantes.icono.atras}></i> {texto('Cancelar')}
									</button>
									<button className="btn btn-success" id="botonGuardar" type="submit">
										<i className={constantes.icono.guardar}></i> {texto('Guardar')}
									</button>
								</BarraHerramientas>
							</CardFooter>
						</Card>
					</form>
				)}
			/>
		</Contenedor>
	);
};

export default Pagina;
