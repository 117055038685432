import { Col, Row } from 'reactstrap';
import { McCampoSelector, McCampoTexto } from '@mcsoft/formulario';
import { StateType } from 'store';
import { texto } from 'idiomas';
import { useSelector } from 'react-redux';
import { validarListaOpcionesDisponibilidad } from 'servicios/api/listaOpciones';

/**
 * Formulario para un elemento de construcción.
 */
const ElementoConstruccionFormulario = () => {
	const { auth0 } = useSelector((state: StateType) => state.sesion);
	const { auth0AccessToken } = auth0;

	/**
	 * Válida el nombre del elemento de construcción.
	 * - ***valor*** - Valor del campo a validar.
	 * - ***valores*** - Valores del formulario.
	 */
	const validarFormularioNombre = async ({ valor, valores }: { valor: string; valores?: any }) => {
		const disponible = await validarListaOpcionesDisponibilidad({ auth0AccessToken, campo: 'nombre', id: valores.id, tipo: valores.tipo, valor });
		if (!disponible) {
			return 'Ya existe un elemento de construcción con ese nombre';
		}
	};

	return (
		<Row>
			<Col md="12">
				<Row>
					<Col md="6">
						<McCampoTexto campo="nombre" etiqueta={texto('Nombre')} funcionValidacionAsincrona={validarFormularioNombre} id="campoNombre" longitudMaxima={100} obligatorio />
					</Col>
					<Col md="6">
						<McCampoSelector
							campo="tipo"
							etiqueta={texto('Tipo')}
							id="campoTipo"
							obligatorio
							opciones={[
								{ nombre: 'Aplanado', valor: 'aplanado' },
								{ nombre: 'Azotea', valor: 'azotea' },
								{ nombre: 'Barda', valor: 'barda' },
								{ nombre: 'Carpintería', valor: 'carpinteria' },
								{ nombre: 'Cerrajería', valor: 'cerrajeria' },
								{ nombre: 'Cimientos', valor: 'cimientos' },
								{ nombre: 'Entrepiso', valor: 'entrepiso' },
								{ nombre: 'Escalera', valor: 'escalera' },
								{ nombre: 'Estructura', valor: 'estructura' },
								{ nombre: 'Fachada', valor: 'fachada' },
								{ nombre: 'Herrería', valor: 'herreria' },
								{ nombre: 'Instalación Eléctrica', valor: 'instalacionElectrica' },
								{ nombre: 'Instalación Especial', valor: 'instalacionEspecial' },
								{ nombre: 'Instalación Sanitaria', valor: 'instalacionSanitaria' },
								{ nombre: 'Lambrín', valor: 'lambrin' },
								{ nombre: 'Mueble de Baño', valor: 'muebleBano' },
								{ nombre: 'Mueble de Cocina', valor: 'muebleCocina' },
								{ nombre: 'Muro', valor: 'muro' },
								{ nombre: 'Pavimento Pétreo', valor: 'pavimentoPetreo' },
								{ nombre: 'Pintura', valor: 'pintura' },
								{ nombre: 'Plafón', valor: 'plafon' },
								{ nombre: 'Recubrimiento Especial', valor: 'recubrimientoEspecial' },
								{ nombre: 'Techo', valor: 'techo' },
								{ nombre: 'Vidriería', valor: 'vidrieria' },
								{ nombre: 'Zoclo', valor: 'zoclo' }
							]}
						/>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default ElementoConstruccionFormulario;
