import { Col, Row } from 'reactstrap';
import { McCampoTexto, McSwitchCuadrado } from '@mcsoft/formulario';
import constantes from 'configuracion/constantes';
import { texto } from 'idiomas';

/**
 * Formulario para la configuración general del sistema.
 */
const SistemaFormularioGeneral = () => (
	<Row>
		<Col lg="12">
			<Row>
				<Col lg="12">
					<h3>
						<i className={constantes.icono.configuracionSistemaGeneral}></i> {texto('General')}
					</h3>
					<p>{texto('Aquí se configuran los ajustes generales del sistema.')}</p>
				</Col>
			</Row>
			<hr />
			<Row>
				<Col lg="12">
					<h3>Entorno: {obtenerEntorno()}</h3>
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="nombreAplicacion"
						etiqueta={texto('Nombre de la aplicación')}
						id="nombreAplicacion"
						informacion={texto('El nombre de la aplicación se mostrará en el pie de página y en la pestaña del navegador.')}
						longitudMaxima={50}
						obligatorio
					/>
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="versionAplicacion"
						etiqueta={texto('Versión de la aplicación')}
						id="versionAplicacion"
						informacion={texto('La versión de la aplicación se mostrará en el pie de página.')}
						longitudMaxima={10}
					/>
				</Col>
				<Col lg="4">
					<McCampoTexto
						campo="copyright"
						etiqueta={texto('Copyright')}
						id="copyright"
						informacion={texto('Derechos de autor que se mostrará en el pie de página.')}
						longitudMaxima={50}
					/>
				</Col>
				<Col lg="4">
					<McSwitchCuadrado
						campo="multilenguaje"
						etiqueta={texto('Multilenguaje')}
						id="multilenguaje"
						informacion={texto('Permite al usuario cambiar el idioma de la aplicación.')}
						textoApagado={texto('No')}
						textoEncendido={texto('Sí')}
					/>
				</Col>
				<Col lg="4">
					<McSwitchCuadrado
						campo="busquedaHabilitada"
						etiqueta={texto('Habilitar Busqueda')}
						id="busquedaHabilitada"
						informacion={texto('Permite al usuario utilizar el buscador de la aplicación.')}
						textoApagado={texto('No')}
						textoEncendido={texto('Sí')}
					/>
				</Col>
			</Row>
		</Col>
	</Row>
);

const obtenerEntorno = () => {
	let color = '';
	let estatusNombre = '';
	switch (process.env.REACT_APP_ENV) {
		case 'production':
			color = 'danger';
			estatusNombre = 'PRODUCCIÓN';
			break;
		case 'development':
			color = 'warning';
			estatusNombre = 'DESARROLLO';
			break;
		default:
			color = 'primary';
			estatusNombre = 'DESCONOCIDO';
			break;
	}
	return <span className={`badge rounded-pill bg-${color}`}>{estatusNombre}</span>;
};

export default SistemaFormularioGeneral;
