/* eslint-disable no-unused-vars */

/**
 * Indica si la variable recibida tiene un valor (No es *null*, *undefined* ni cadena vacía).
 * - ***valor*** - Valor a analizar.
 */
export const esEntornoDesarrollo = (): boolean => process.env.NODE_ENV === 'development';

/**
 * Indica si la variable recibida tiene un valor (No es *null*, *undefined* ni cadena vacía).
 * - ***valor*** - Valor a analizar.
 */
export const tieneValor = (valor: any): boolean => {
	if (valor === undefined) {
		return false;
	}
	if (valor === null) {
		return false;
	}
	if (valor === '') {
		return false;
	}
	return true;
};

/**
 * Valida si la cadena recibida cumple el formato de un correo electrónico.
 * - ***cadena*** - Cadena a validar.
 */
export const validarCorreoElectronico = (cadena: string): boolean => {
	// Expresión regular estándar oficial RFC 5322 (valida en el 99.99% de los emails existentes).
	const expresionRegular = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/;
	if (cadena !== undefined && cadena !== null && cadena !== '') {
		const expresionRegularInstancia = new RegExp(expresionRegular);
		if (!expresionRegularInstancia.test(cadena)) {
			return false;
		}
	}
	return true;
};

/**
 * Indica si la cadena recibida cumple con el patrón de la expresión regular.
 * - ***cadena*** - Cadena a validar.
 * - ***expresionRegular*** - Expresión regular para validar la cadena.
 */
export const validarExpresionRegular = ({ cadena, expresionRegular }: { cadena: string; expresionRegular: string }): boolean => {
	if (cadena !== undefined && cadena !== null && cadena !== '') {
		const expresionRegularInstancia = new RegExp(expresionRegular);
		if (!expresionRegularInstancia.test(cadena)) {
			return false;
		}
	}
	return true;
};

/**
 * Válida un formulario de domicilio de manera síncrona y regresa sus errores.
 * - ***domicilio*** - Valores del formulario de domicilio a validar *React Final Form*.
 * - ***obligatorio*** - Indica si el domicilio es obligatorio.
 * - ***texto*** - Funcion de localizacion que recibe un texto y lo traduce.
 */
export const validarFormularioDomicilio = ({
	domicilio,
	obligatorio = false,
	texto
}: {
	domicilio: Record<string, any>;
	obligatorio?: boolean;
	texto: (cadena: string) => string;
}): any => {
	let errores: any = {};
	if (domicilio) {
		if (obligatorio) {
			if (!tieneValor(domicilio.calle)) {
				errores.calle = texto('Obligatorio');
			}
			if (!tieneValor(domicilio.numeroExterior)) {
				errores.numeroExterior = texto('Obligatorio');
			}
			if (!tieneValor(domicilio.colonia)) {
				errores.colonia = texto('Obligatorio');
			}
			if (!tieneValor(domicilio.codigoPostal)) {
				errores.codigoPostal = texto('Obligatorio');
			}
			if (!tieneValor(domicilio.municipioId)) {
				errores.municipioId = texto('Obligatorio');
			}
			if (!tieneValor(domicilio.municipioSeleccion)) {
				errores.municipioSeleccion = texto('Obligatorio');
			}
			if (!tieneValor(domicilio.estadoId)) {
				errores.estadoId = texto('Obligatorio');
			}
			if (!tieneValor(domicilio.estadoSeleccion)) {
				errores.estadoSeleccion = texto('Obligatorio');
			}
			if (!tieneValor(domicilio.paisId)) {
				errores.paisId = texto('Obligatorio');
			}
			if (!tieneValor(domicilio.paisSeleccion)) {
				errores.paisSeleccion = texto('Obligatorio');
			}
		} else if (
			tieneValor(domicilio.calle) ||
			tieneValor(domicilio.numeroExterior) ||
			tieneValor(domicilio.numeroInterior) ||
			tieneValor(domicilio.colonia) ||
			tieneValor(domicilio.codigoPostal) ||
			tieneValor(domicilio.municipioId) ||
			tieneValor(domicilio.estadoId) ||
			tieneValor(domicilio.paisId)
		) {
			if (!tieneValor(domicilio.calle)) {
				errores.calle = texto('Debes completar los datos mínimos');
			}
			if (!tieneValor(domicilio.numeroExterior)) {
				errores.numeroExterior = texto('Debes completar los datos mínimos');
			}
			if (!tieneValor(domicilio.colonia)) {
				errores.colonia = texto('Debes completar los datos mínimos');
			}
			if (!tieneValor(domicilio.codigoPostal)) {
				errores.codigoPostal = texto('Debes completar los datos mínimos');
			}
			if (!tieneValor(domicilio.municipioId)) {
				errores.municipioId = texto('Debes completar los datos mínimos');
			}
			if (!tieneValor(domicilio.municipioSeleccion)) {
				errores.municipioSeleccion = texto('Debes completar los datos mínimos');
			}
			if (!tieneValor(domicilio.estadoId)) {
				errores.estadoId = texto('Debes completar los datos mínimos');
			}
			if (!tieneValor(domicilio.estadoSeleccion)) {
				errores.estadoSeleccion = texto('Debes completar los datos mínimos');
			}
			if (!tieneValor(domicilio.paisId)) {
				errores.paisId = texto('Debes completar los datos mínimos');
			}
			if (!tieneValor(domicilio.paisSeleccion)) {
				errores.paisSeleccion = texto('Debes completar los datos mínimos');
			}
		}
	} else if (obligatorio) {
		errores = {
			calle: texto('Obligatorio'),
			codigoPostal: texto('Obligatorio'),
			colonia: texto('Obligatorio'),
			estadoId: texto('Obligatorio'),
			municipioId: texto('Obligatorio'),
			numeroExterior: texto('Obligatorio'),
			paisId: texto('Obligatorio')
		};
	}
	return errores;
};

/**
 * Valida si la cadena recibida cumple el formato de un teléfono.
 * - ***cadena*** - Cadena a validar.
 */
export const validarTelefono = (cadena: string): boolean => {
	const expresionRegular = /^[0-9]{10}$/;
	if (cadena !== undefined && cadena !== null && cadena !== '') {
		const expresionRegularInstancia = new RegExp(expresionRegular);
		if (!expresionRegularInstancia.test(cadena)) {
			return false;
		}
	}
	return true;
};
