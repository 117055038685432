/* eslint-disable sort-keys */
export default {
	estatus: 'incompleto',
	fechaCreacion: '',
	fechaModificacion: '',
	folio: 0,
	id: '',
	usuarioId: '',
	valorTotal: '',
	antecedentes: {
		avaluoId: null,
		claveCatastral: null,
		cuentaPredial: null,
		fechaAvaluo: null,
		id: null,
		nombrePropietario: null,
		nombreSolicitante: null,
		numeroRecaudadora: null,
		perito: null,
		peritoId: null,
		peritoSeleccion: null,
		regimenPropiedadId: null,
		tipoInmuebleId: null,
		ubicacionPredio: null
	},
	caracteristicas: {
		avaluoId: null,
		clasificacionZonaId: null,
		coeficienteOcupacion: null,
		coeficienteUtilizacion: null,
		densidadConstruccion: null,
		id: null
	},
	construcciones: {
		avaluoId: '',
		formularioDetalle: {
			detalleAjusteFactor: '',
			detalleAjusteMotivoId: '',
			detalleBloque: '',
			detalleCalidadId: null,
			detalleCodigo: '',
			detalleCodigoClasificacion: '',
			detalleEdad: '',
			detalleEstadoConservacionId: null,
			detalleIndiviso: '',
			detalleSuperficie: '',
			detalleSuperficieUnidadId: '',
			detalleTipoId: null,
			detalleValorUnitario: ''
		},
		id: '',
		indiviso: '',
		superficieTotal: '',
		superficieUnidadId: null,
		valor: '',
		valorTotal: '',
		detalles: [],
		superficieUnidad: null
	},
	construccionesDominantes: {
		avaluoId: '',
		descripcion: '',
		detalles: {},
		id: ''
	},
	distribucion: {
		avaluoId: '',
		descripcion: '',
		detalles: {},
		id: ''
	},
	domicilio: {
		avaluoId: '',
		calle: '',
		calleTipoId: null,
		codigoPostal: '',
		colonia: '',
		condominio: '',
		estadoId: null,
		fraccionamiento: '',
		id: '',
		lote: '',
		manzana: '',
		municipioId: null,
		numeroExterior: '',
		numeroInterior: '',
		paisId: null,
		unidadPrivativa: '',
		calleTipo: null
	},
	elementosConstruccion: {
		aplanado: '',
		avaluoId: '',
		azotea: '',
		barda: '',
		carpinteria: '',
		cerrajeria: '',
		cimientos: '',
		detalles: {
			aplanado: {},
			azotea: {},
			barda: {},
			carpinteria: {},
			cerrajeria: {},
			cimientos: {},
			entrepiso: {},
			escalera: {},
			estructura: {},
			fachada: {},
			herreria: {},
			instalacionElectrica: {},
			instalacionEspecial: {},
			instalacionSanitaria: {},
			lambrin: {},
			muebleBano: {},
			muebleCocina: {},
			muro: {},
			pavimentoPetreo: {},
			pintura: {},
			plafon: {},
			recubrimientoEspecial: {},
			techo: {},
			vidrieria: {},
			zoclo: {}
		},
		entrepiso: '',
		escalera: '',
		estructura: '',
		fachada: '',
		herreria: '',
		id: '',
		instalacionElectrica: '',
		instalacionEspecial: '',
		instalacionSanitaria: '',
		lambrin: '',
		muebleBano: '',
		muebleCocina: '',
		muro: '',
		pavimentoPetreo: '',
		pintura: '',
		plafon: '',
		recubrimientoEspecial: '',
		techo: '',
		vidrieria: '',
		zoclo: ''
	},
	escritura: {
		avaluoId: null,
		descripcion: null,
		estadoId: null,
		fecha: null,
		id: null,
		municipioId: null,
		notarioNombre: null,
		numero: null,
		numeroNotaria: null
	},
	fotos: [],
	instalacionesEspeciales: {
		avaluoId: '',
		formularioDetalle: {
			detalleAjusteFactor: '',
			detalleAjusteMotivoId: '',
			detalleBloque: '',
			detalleCalidadId: null,
			detalleCodigo: '',
			detalleCodigoClasificacion: '',
			detalleEdad: '',
			detalleEstadoConservacionId: null,
			detalleIndiviso: '',
			detalleSuperficie: '',
			detalleSuperficieUnidadId: '',
			detalleTipoId: null,
			detalleValorUnitario: ''
		},
		id: '',
		indiviso: '',
		superficieTotal: '',
		superficieUnidadId: null,
		valor: '',
		valorTotal: '',
		detalles: [],
		superficieUnidad: null
	},
	medidas: {
		avaluoId: '',
		calidadProyecto: '',
		colindancia1Descripcion: '',
		colindancia1VientoId: null,
		colindancia2Descripcion: '',
		colindancia2VientoId: null,
		colindancia3Descripcion: '',
		colindancia3VientoId: null,
		colindancia4Descripcion: '',
		colindancia4VientoId: null,
		colindancia5Descripcion: '',
		colindancia5VientoId: null,
		colindancia6Descripcion: '',
		colindancia6VientoId: null,
		colindancia7Descripcion: '',
		colindancia7VientoId: null,
		colindancia8Descripcion: '',
		colindancia8VientoId: null,
		colindanciaSeparador: '',
		destinoEspecifico2Id: null,
		destinoEspecificoId: null,
		edad: '',
		id: '',
		indiviso: '',
		superficieCatastro: '',
		superficieEscritura: '',
		superficieReal: '',
		superficieUnidadId: null,
		unidadesRentables: '',
		usoEspecifico2Id: null,
		usoEspecificoId: null,
		vidaRemanenteProbale: ''
	},
	observaciones: {
		avaluoId: '',
		descripcion: '',
		id: '',
		valoresReferidos: []
	},
	serviciosMunicipales: {
		avaluoId: '',
		descripcion: '',
		detalles: {},
		id: ''
	},
	terreno: {
		avaluoId: '',
		formularioDetalle: {
			detalleAjusteFactor: '',
			detalleAjusteMotivoId: '',
			detalleFraccion: '',
			detalleIndiviso: '',
			detalleSuperficie: '',
			detalleSuperficieUnidadId: '',
			detalleValorNeto: '',
			detalleValorResultante: '',
			detalleValorUnitario: ''
		},
		id: '',
		indiviso: '',
		superficieTotal: '',
		superficieUnidadId: null,
		valor: '',
		valorTotal: '',
		detalles: [],
		superficieUnidad: null
	},
	ubicacion: {
		avaluoId: '',
		contornoCoordenadasDdArreglo: '',
		coordenadasDdLatitud: '',
		coordenadasDdLongitud: '',
		coordenadasDmsLatitudCardinal: '',
		coordenadasDmsLatitudGrados: '',
		coordenadasDmsLatitudMinutos: '',
		coordenadasDmsLatitudSegundos: '',
		coordenadasDmsLongitudCardinal: '',
		coordenadasDmsLongitudGrados: '',
		coordenadasDmsLongitudMinutos: '',
		coordenadasDmsLongitudSegundos: '',
		coordenadasUtmEsteX: '',
		coordenadasUtmHuso: '',
		coordenadasUtmNorteY: '',
		coordenadasUtmZona: '',
		croquisImagenNombre: '',
		distanciaEsquina: '',
		distanciaEsquinaCalle: '',
		distanciaEsquinaCoordenadasDdArreglo: '',
		id: '',
		mapaImagenGoogleMapsApi: '',
		mapaImagenNombre: '',
		mapaZoom: ''
	},
	usuario: {
		apellidoMaterno: null,
		apellidoPaterno: null,
		auth0Id: '',
		correoElectronico: '',
		fechaCreacion: '',
		fechaModificacion: '',
		fotoPerfil: '',
		id: '',
		nombre: '',
		nombreUsuario: '',
		rolId: '',
		telefono: null,
		urlFacebook: null,
		urlInstagram: null,
		urlLinkedIn: null,
		urlTwitter: null,
		urlYouTube: null
	}
};
